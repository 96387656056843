import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, MenuItem, Typography, Switch } from '@mui/material';
import { reduxForm, getFormValues, Field } from 'redux-form';
import { connect } from 'react-redux';
import ReviewFormItem from './ReviewFormItem';
import SelectInput from '../../common/form/SelectInput';
import { combineValidators, isRequired } from 'revalidate';
import { useHistory, useLocation } from 'react-router-dom';
import {
  fetchProgramForReview,
  updateAdminReview,
} from '../../store/actions/reviewActions/reviewActions';
import Button from '@mui/material/Button';
import TextInput from '../../common/form/TextInput';
import TextArea from '../../common/form/TextArea';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
// import Switch from '../../common/form/Switch';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#171725',
  },
  text: {
    fontFamily: 'Poppins',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#696974',
  },
  button: {
    marginLeft: '1rem',
  },
}));

const validate = combineValidators({
  extraCurricularCommunityActivityValue: isRequired({
    message: 'Value is required',
  }),
  scholarshipAmount: isRequired({
    message: 'Value is required',
  }),
  academicPerformanceValue: isRequired({ message: 'Value is required' }),
  essayQualityValue: isRequired({ message: 'Value is required' }),
  recommendationQualityValue: isRequired({ message: 'Value is required' }),
  chosenInstitution: isRequired({ message: 'Value is required' }),
});

const actions = {
  updateAdminReview,
  fetchProgramForReview,
};

const mapStateToProps = (state, ownProps) => {
  let personalInformation = [];
  let scholarshipInformation = [];
  let institutions = [];
  let placement2 = [];

  console.log(ownProps);

  if (
    state.review.reviewApplication &&
    state.review.reviewApplication.length > 0
  ) {
    personalInformation =
      state.review.reviewApplication[0].personalInformationFormDetails;
    scholarshipInformation =
      state.review.reviewApplication[0].scholarshipFormDetails;
    institutions = state.review.reviewApplication[0].institutions;
    placement2 = state.review.reviewApplication[0].chosenInstitution2;
  }
  let disabledForm = false;

  if (
    ownProps.program &&
    (ownProps.program.programStage === 'pre-application' ||
      ownProps.program.programStage === 'in-progress')
  )
    disabledForm = true;

  return {
    personalInformation,
    scholarshipInformation,
    institutions,
    placement2,
    formValues: getFormValues('adminReviewProgram')(state),
    initialValues: disabledForm ? {} : state.review.reviewApplication[0],
    disabledForm,
  };
};

const AdminReview = ({
  profile,
  fetchProgramForReview,
  program,
  reload,
  formValues,
  personalInformation,
  scholarshipInformation,
  institutions,
  handleSubmit,
  placement2,
  updateAdminReview,
  currentForm,
  handleToggleGrantScholarship,
  handleToggleGrantSponsorship,
  disabledForm,
}) => {
  const [required, setRequired] = useState(true);
  const [togglePlacemnet, settogglePlacemnet] = useState(false);
  console.log(togglePlacemnet, 'togglePlacemnet');
  const [grantSponsorship, setGrantSponsorship] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  console.log(program, '111111program');
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get('id');

  useEffect(() => {
    if (program?.programId) fetchProgramForReview(id, program);
  }, [program.programId, reload]);

  useEffect(() => {
    if (Object.keys(program).length) {
      setGrantSponsorship(program?.grantSponsorship || false);
    }
  }, [program]);
  useEffect(() => {
    if (program?.chosenInstitution2) {
      settogglePlacemnet(true);
    }
  }, [program?.chosenInstitution2]);
  console.log(placement2, 'placement2');
  const extraCommunityActivity =
    formValues?.extraCurricularCommunityActivityValue
      ? formValues?.extraCurricularCommunityActivityValue
      : 0;
  const academicPerformance = formValues?.academicPerformanceValue
    ? formValues?.academicPerformanceValue
    : 0;
  const essayQuality = formValues?.essayQualityValue
    ? formValues?.essayQualityValue
    : 0;
  const recommendationQuality = formValues?.recommendationQualityValue
    ? formValues?.recommendationQualityValue
    : 0;

  const totalScore =
    extraCommunityActivity +
    academicPerformance +
    essayQuality +
    recommendationQuality;
  const averageScore =
    (extraCommunityActivity +
      academicPerformance +
      essayQuality +
      recommendationQuality) /
    4;

  const handleAdminReview = async (values, isReviewed) => {
    let programStage = 'denied';
    if (isReviewed) {
      programStage = 'reviewed';
    } else {
      programStage = program.programStage;
    }
    if (!togglePlacemnet) {
      delete values.chosenInstitution2;
    }
    console.log(values, 'values');

    await updateAdminReview(
      program.id,
      values,
      totalScore,
      averageScore,
      programStage,
      currentForm,
      program.programDetails?.programCost,
      togglePlacemnet
    );
    history.goBack();
  };

  return (
    <Grid
      container
      justifyContent={'center'}
      direction={'column'}
      style={{ padding: '2em' }}
    >
      <form
        autoComplete={'off'}
        onSubmit={handleSubmit((values) => handleAdminReview(values, true))}
      >
        <Grid item>
          <Typography className={classes.title}>Review Formsss</Typography>
        </Grid>

        <ReviewFormItem
          title={' Extra Curriculum/ Community Involvement'}
          fieldName={'extraCurricularCommunityActivityValue'}
          fieldLabel={'Value'}
          description={
            'Applicant is involved in various activities including sports, academic clubs, as well as meaningful social groups'
          }
          required={required}
          disabled={
            program.notificationDateMet ||
            (program.reviewerId !== profile.uid &&
              program.reviewStatus !== 'reviewed') ||
            disabledForm
          }
        />
        <ReviewFormItem
          title={'Academic Performance'}
          fieldName={'academicPerformanceValue'}
          fieldLabel={'Value'}
          description={
            'Applicant demonstrates solid Academic credentials including GPA (average of 3.0), Honors, and/or AP course work'
          }
          required={required}
          disabled={
            program.notificationDateMet ||
            (program.reviewerId !== profile.uid &&
              program.reviewStatus !== 'reviewed') ||
            disabledForm
          }
        />
        <ReviewFormItem
          title={'Essay Quality'}
          fieldName={'essayQualityValue'}
          fieldLabel={'Value'}
          description={
            'Applicant clearly presented their response to essay question(s) in a precise, coherent, and grammatically correct manner'
          }
          required={required}
          disabled={
            program.notificationDateMet ||
            (program.reviewerId !== profile.uid &&
              program.reviewStatus !== 'reviewed') ||
            disabledForm
          }
        />
        <ReviewFormItem
          title={'Recommendation Quality'}
          fieldName={'recommendationQualityValue'}
          fieldLabel={'Value'}
          description={
            'Applicant recommendation(s) represent a cross section of observers who provide candid examples of the applicants character, academic performance and leadership capability and or potential'
          }
          required={required}
          disabled={
            program.notificationDateMet ||
            (program.reviewerId !== profile.uid &&
              program.reviewStatus !== 'reviewed') ||
            disabledForm
          }
        />

        {/*TOTAL SCORE*/}
        <Grid item style={{ marginTop: '2rem' }}>
          <Grid item container>
            <Grid item md={8} style={{ paddingRight: '1.5em' }}>
              <Typography className={classes.title}>Total Score:</Typography>
            </Grid>
            <Grid item md={4}>
              <Typography className={classes.text}>{totalScore}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {/*AVERAGE SCORE*/}
        {/* <Grid item style={{ marginTop: '0.2em' }}>
          <Grid item container>
            <Grid item md={8} style={{ paddingRight: '1.5em' }}>
              <Typography className={classes.title}>Average Score:</Typography>
            </Grid>
            <Grid item md={4}>
              <Typography className={classes.text}>{averageScore}</Typography>
            </Grid>
          </Grid>
        </Grid> */}

        {/*APPLICANTS BACKGROUND*/}
        <Grid item style={{ marginTop: '0.2em' }}>
          <Grid item container>
            <Grid item md={8} style={{ paddingRight: '1.5em' }}>
              <Typography className={classes.title}>
                Applicants background:
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography className={classes.text}>
                {personalInformation?.ethnicBackground}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/*Qualify For Scholarship*/}
        <Grid item style={{ marginTop: '0.2em' }}>
          <Grid item container>
            <Grid item md={8} style={{ paddingRight: '1.5em' }}>
              <Typography className={classes.title}>
                Qualify For Scholarship:
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography className={classes.text}>
                {scholarshipInformation?.qualifyForScholarship ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/*Qualify For Scholarship*/}
        <Grid item style={{ marginTop: '0.2em' }}>
          <Grid item container alignItems="center">
            <Grid item md={8} style={{ paddingRight: '1.5em' }}>
              <Typography className={classes.title}>
                Grant Scholarship:
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Switch
                color="primary"
                checked={program.grantScholarship ? true : false}
                onChange={async (event, checked) => {
                  await handleToggleGrantScholarship(program.id, checked);
                }}
                disabled={disabledForm}
              />
            </Grid>
          </Grid>
        </Grid>

        {/*SCHOLARSHIP AMOUNT*/}
        {program.grantScholarship && (
          <Grid item style={{ marginTop: '2.5em' }}>
            <Grid
              item
              container
              className={classes.fieldContainer}
              alignItems={'center'}
            >
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <Grid item container direction={'column'}>
                  <Grid item>
                    <Typography className={classes.title}>
                      What is the amount of the scholarship being awarded?
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ paddingRight: '1.5em' }}
              >
                <Grid item>
                  <Grid item container direction={'column'}>
                    <Grid item className={classes.fieldWrapper}>
                      <Field
                        name={'scholarshipAmount'}
                        component={TextInput}
                        type={'text'}
                        variant={'outlined'}
                        inputAdornment={'$'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/*Grant For Sponsorship*/}
        <Grid item style={{ marginTop: '0.2em' }}>
          <Grid item container alignItems="center">
            <Grid item md={8} style={{ paddingRight: '1.5em' }}>
              <Typography className={classes.title}>
                Grant Sponsorship:
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Switch
                color="primary"
                checked={grantSponsorship}
                onChange={async (event, checked) => {
                  await handleToggleGrantSponsorship(program.id, checked);
                }}
                disabled={disabledForm}
              />
            </Grid>
          </Grid>
        </Grid>

        {/*Sponsorship Amount*/}
        {program?.grantSponsorship && (
          <Grid item style={{ marginTop: '2.5em' }}>
            <Grid
              item
              container
              className={classes.fieldContainer}
              alignItems={'center'}
            >
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <Grid item container direction={'column'}>
                  <Grid item>
                    <Typography className={classes.title}>
                      Sponsorship Amount
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ paddingRight: '1.5em' }}
              >
                <Grid item>
                  <Grid item container direction={'column'}>
                    <Grid item className={classes.fieldWrapper}>
                      <Field
                        name={'sponsorshipAmount'}
                        component={TextInput}
                        type={'text'}
                        variant={'outlined'}
                        inputAdornment={'$'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/*INSTITUTIONS*/}
        {institutions.length > 0 && (
          <Grid item style={{ marginTop: '2.5em' }}>
            <Grid
              item
              container
              className={classes.fieldContainer}
              alignItems={'center'}
            >
              {program?.programDetails?.dualProgram && (
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  {program.programDetails.dualProgram && !togglePlacemnet && (
                    <Button onClick={() => settogglePlacemnet(true)}>
                      <AddIcon />
                    </Button>
                  )}
                </Grid>
              )}

              <Grid item lg={7} md={7} sm={7} xs={7}>
                <Grid item container direction={'column'}>
                  <Grid item>
                    <Typography className={classes.title}>
                      Placement 1
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={4}
                xs={4}
                className={classes.fieldWrapper}
              >
                <Field
                  name={'chosenInstitution'}
                  label={'Institution'}
                  component={SelectInput}
                  type={'text'}
                  variant={'outlined'}
                  disabled={disabledForm}
                >
                  <MenuItem value={'noInstitution'}>No Institution</MenuItem>
                  {institutions.map((institution) => (
                    <MenuItem key={institution.id} value={institution.id}>
                      {institution.institutionName}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* INSTITUTIONS for Dual Program Start */}
        {institutions.length > 0 && (
          <>
            {togglePlacemnet && (
              <Grid item style={{ marginTop: '2.5em' }}>
                <Grid
                  item
                  container
                  className={classes.fieldContainer}
                  alignItems={'center'}
                >
                  <Grid item lg={1} md={1} sm={1} xs={1}>
                    <Button onClick={() => settogglePlacemnet(false)}>
                      <CloseIcon />
                    </Button>
                  </Grid>
                  <Grid item lg={7} md={7} sm={7} xs={7}>
                    <Grid item container direction={'column'}>
                      <Grid item>
                        <Typography className={classes.title}>
                          Placement 2
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                    className={classes.fieldWrapper}
                  >
                    <Field
                      name={'chosenInstitution2'}
                      label={'Institution'}
                      component={SelectInput}
                      type={'text'}
                      variant={'outlined'}
                      disabled={disabledForm}
                    >
                      <MenuItem value={'noInstitution'}>
                        No Institution
                      </MenuItem>
                      {institutions.map((institution) => (
                        <MenuItem key={institution.id} value={institution.id}>
                          {institution.institutionName}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}

        {/* INSTITUTIONS for Dual Program End */}

        {/*COMMENTS*/}
        {/*COMMENTS TITLE*/}
        <Grid item style={{ marginTop: '2.5em' }}>
          <Grid
            item
            container
            className={classes.fieldContainer}
            alignItems={'center'}
          >
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <Grid item container direction={'column'}>
                <Grid item>
                  <Typography className={classes.title}>Comments</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/*COMMENT BX*/}
        <Grid item>
          <Grid
            item
            container
            className={classes.fieldContainer}
            alignItems={'center'}
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid item>
                <Grid item container direction={'column'}>
                  <Grid item className={classes.fieldWrapper}>
                    <Field
                      name={'comments'}
                      component={TextArea}
                      rows={5}
                      type={'text'}
                      variant={'outlined'}
                      disabled={disabledForm}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{ marginTop: '1.5em' }}>
          <Grid item container>
            <Grid item style={{ marginLeft: 'auto' }}>
              <Button
                variant={'outlined'}
                color={'primary'}
                className={classes.button}
                disabled={
                  program.programStage === 'complete' ||
                  program.programStage === 'withdrawn'
                }
                onClick={() => {
                  new Promise((resolve) => {
                    setRequired(false);
                    resolve();
                  }).then(() => {
                    handleSubmit((values) =>
                      handleAdminReview(values, false)
                    )();
                  });
                }}
              >
                Update
              </Button>
              {!program.notificationDateMet && (
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  className={classes.button}
                  type={'submit'}
                  onClick={() => {
                    new Promise((resolve) => {
                      setRequired(false);
                      resolve();
                    }).then(() => {
                      handleSubmit((values) =>
                        handleAdminReview(values, true)
                      )();
                    });
                  }}
                  disabled={
                    program.programStage === 'complete' ||
                    program.programStage === 'admitted' ||
                    program.programStage === 'withdrawn' ||
                    program.applicationAccepted
                  }
                >
                  Submit Review
                </Button>
              )}
              <Button
                variant={'outlined'}
                color={'error'}
                className={classes.button}
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({ form: 'adminReviewProgram', enableReinitialize: true })(
    AdminReview
  )
);
