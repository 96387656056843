// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   FormControl,
//   Grid,
//   InputLabel,
//   MenuItem,
//   Select,
//   Typography,
// } from '@mui/material';
// import React, { useEffect, useState } from 'react';
// import { connect } from 'react-redux';
// import { Field, reduxForm } from 'redux-form';
// import TextInput from '../../common/form/TextInput';
// import TextEditor from '../../common/TextEditor/TextEditor';
// import TextInputForEmail from '../../common/form/TextInputForEmail';
// import { initialize } from 'redux-form';
// import {
//   fetchTemplate,
//   updateTemplate,
// } from '../../store/actions/adminActions/settingsActions/settingsActions';

// const actions = {
//   fetchTemplate,
//   updateTemplate,
// };
// const mapStateToProps = (state, ownProps) => {
//   console.log(state, 'statess');
//   let template = {};
//   let templateDescription = '';

//   if (state.adminSettings.template && state.adminSettings.template.length > 0) {
//     template = state.adminSettings.template[0];
//     templateDescription = state.adminSettings.template[0].html;
//   }

//   // if (ownProps.toValue) {
//   //   return {
//   //     initialValues: {
//   //       to: ownProps.toValue,
//   //     },
//   //   };
//   // }

//   return {
//     // initialValues: {
//     //   to: ownProps?.toValue,
//     //   subject: template?.templateSubject || '', // Adjust property name as needed
//     // },
//     subject: template?.templateSubject || '',

//     template,
//     templateDescription,
//   };
// };

// // useEffect(() => {
// //   if () {
// //     fetchTemplate(id);
// //   }

// //   setEditorValue(template.html);
// //   fetchTemplate(id);
// //   setEditorValue(templateDescription);
// // }, [id, fetchTemplate, templateDescription]);

// const SendEmailRequestDialog = ({
//   isOpen,
//   toValue,
//   templates,
//   closeDialog,
//   setDialog,
//   submitting,
//   handleSubmit,
//   handleSendEmail,
//   editorValue,
//   setEditorValue,
//   fetchTemplate,
//   template,
//   setToValue,
//   templateDescription,
// }) => {
//   const [selectedTemplate, setSelectedTemplate] = useState('');
//   const [initialTemplateSubject, setInitialTemplateSubject] = useState('');
//   console.log(initialTemplateSubject, 'initialTemplateSubject');
//   console.log(templates, 'templates');
//   const myfunction = async (event) => {
//     const selectedTemplateId = event?.target?.value;
//     console.log(selectedTemplateId, 'selectedTemplateId');
//     // setSelectedTemplate(event?.target?.value);
//     setSelectedTemplate(selectedTemplateId);
//     await fetchTemplate(selectedTemplateId);
//     const selectedTemplateObject = templates?.find(
//       (template) => template.id === selectedTemplateId
//     );
//     const initialSubject = selectedTemplateObject?.templateSubject || '';
//     const templateSubject = initialSubject;
//     initialize('sendEmail', { templateSubject });
//     setInitialTemplateSubject(initialSubject);

//     // fetchTemplate(event?.target?.value);
//     setEditorValue(template?.html);

//     setEditorValue(templateDescription);

//     console.log(templateDescription, 'mansi');
//   };
//   useEffect(() => {
//     myfunction();
//     setEditorValue(template?.html || '');
//   }, [selectedTemplate, fetchTemplate, templateDescription]);
//   const myfun = () => {
//     setInitialTemplateSubject('');
//     // fetchTemplate(event?.target?.value);
//     setEditorValue('');

//     setEditorValue('');
//     setToValue('');
//     setDialog(false);
//   };

//   return (
//     <Dialog
//       open={isOpen}
//       onClose={closeDialog}
//       maxWidth="md"
//       disableEnforceFocus
//     >
//       {console.log(toValue, 'to')}
//       <form onSubmit={handleSubmit(handleSendEmail)}>
//         <DialogTitle>Send Email</DialogTitle>
//         {/* <FormControl
//           fullWidth
//           style={{ paddingTop: '5px' }}
//           sx={{ m: 1, minWidth: 80 }}
//         >
//           <InputLabel id="demo-simple-select-label">Select Template</InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             value={selectedTemplate}
//             label="Age"
//             onChange={(event) => myfunction(event)}
//           >
//             {templates?.map((i, j) => (
//               <MenuItem key={i.id} value={i?.id}>
//                 {i.templateName}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl> */}
//         {/* <Select
//             value={selectedTemplate}
//             label="Age"
//             onChange={(event) => {
//               setSelectedTemplate(event.target.value);
//             }}
//           >
//             {templates.map((i, j) => (
//               <MenuItem key={i.id} value={i?.templateName}>
//                 {i.templateName}
//               </MenuItem>
//             ))}
//             Send Emails
//           </Select> */}
//         <DialogContent style={{ paddingTop: '5px' }}>
//           <Field
//             name={'to'}
//             label={'To'}
//             component={TextInputForEmail}
//             // toValue={toValue}
//             type={'text'}
//             variant={'outlined'}
//           />
//           <Field
//             name={'bcc'}
//             label={'Bcc'}
//             // component="input"
//             component={TextInputForEmail}
//             toValue={toValue}
//             type="email"
//             variant={'outlined'}
//             placeholder={'Bcc'}
//             style={{
//               padding: '8.5px 14px',
//               width: '100%',
//               boxSizing: 'border-box',
//               borderRadius: '4px',
//               borderColor: '#0000003b',
//               height: '39.97px',
//               outline: 'none',
//               marginTop: '1rem',
//             }}
//           />
//           {/* <Field
//                name={'subject'}

//               label={'Subject'}
//               component={TextInput}
//               type={'text'}
//               variant={'outlined'}
//               style={{ marginTop: '1rem' }}
//             /> */}
//           <Field
//             name={'subject'}
//             label={'Subject'}
//             component={TextInput}
//             type={'text'}
//             variant={'outlined'}
//             initialValue={initialTemplateSubject}
//           />
//           <Grid item style={{ marginTop: '1rem' }}>
//             <Typography variant={'body1'}>Content</Typography>
//             <TextEditor
//               editorValue={editorValue}
//               handleChange={setEditorValue}
//             />
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={myfun} color="secondary" disabled={submitting}>
//             Close
//           </Button>
//           <Button color={'primary'} type={'submit'} disabled={submitting}>
//             Send
//           </Button>
//         </DialogActions>
//       </form>
//     </Dialog>
//   );
// };

// export default connect(
//   mapStateToProps,
//   actions,
//   null
// )(
//   reduxForm({ form: 'sendEmail', enableReinitialize: true })(
//     SendEmailRequestDialog
//   )
// );
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../common/form/TextInput';
import TextEditor from '../../common/TextEditor/TextEditor';
import TextInputForEmail from '../../common/form/TextInputForEmail';
import { initialize } from 'redux-form';
import {
  fetchTemplate,
  updateTemplate,
} from '../../store/actions/adminActions/settingsActions/settingsActions';

const actions = {
  fetchTemplate,
  updateTemplate,
};
const mapStateToProps = (state, ownProps) => {
  console.log(state, 'statess');
  let template = {};
  let templateDescription = '';

  if (state.adminSettings.template && state.adminSettings.template.length > 0) {
    template = state.adminSettings.template[0];
    templateDescription = state.adminSettings.template[0].html;
  }

  // if (ownProps.toValue) {
  //   return {
  //     initialValues: {
  //       to: ownProps.toValue,
  //     },
  //   };
  // }

  return {
    // initialValues: {
    //   to: ownProps?.toValue,
    //   subject: template?.templateSubject || '', // Adjust property name as needed
    // },
    subject: template?.templateSubject || '',

    template,
    templateDescription,
  };
};

// useEffect(() => {
//   if () {
//     fetchTemplate(id);
//   }

//   setEditorValue(template.html);
//   fetchTemplate(id);
//   setEditorValue(templateDescription);
// }, [id, fetchTemplate, templateDescription]);

const SendEmailRequestDialog = ({
  isOpen,
  toValue,
  templates,
  closeDialog,
  setDialog,
  submitting,
  handleSubmit,
  handleSendEmail,
  editorValue,
  setEditorValue,
  fetchTemplate,
  template,
  setToValue,
  templateDescription,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [initialTemplateSubject, setInitialTemplateSubject] = useState('');
  console.log(initialTemplateSubject, 'initialTemplateSubject');
  console.log(templates, 'templates');
  const myfunction = async (event) => {
    const selectedTemplateId = event?.target?.value;
    console.log(selectedTemplateId, 'selectedTemplateId');
    // setSelectedTemplate(event?.target?.value);
    setSelectedTemplate(selectedTemplateId);
    await fetchTemplate(selectedTemplateId);
    const selectedTemplateObject = templates?.find(
      (template) => template.id === selectedTemplateId
    );
    const initialSubject = selectedTemplateObject?.templateSubject || '';
    const templateSubject = initialSubject;
    initialize('sendEmail', { templateSubject });
    setInitialTemplateSubject(initialSubject);

    // fetchTemplate(event?.target?.value);
    setEditorValue(template?.html);

    setEditorValue(templateDescription);

    console.log(templateDescription, 'mansi');
  };
  useEffect(() => {
    myfunction();
    setEditorValue(template?.html || '');
  }, [selectedTemplate, fetchTemplate, templateDescription]);
  const myfun = () => {
    setInitialTemplateSubject('');
    // fetchTemplate(event?.target?.value);
    setEditorValue('');

    setEditorValue('');
    setToValue('');
    setDialog(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      maxWidth="md"
      disableEnforceFocus
    >
      {console.log(toValue, 'to')}
      <form onSubmit={handleSubmit(handleSendEmail)}>
        <DialogTitle>Send Email</DialogTitle>
        {/* <FormControl
          fullWidth
          style={{ paddingTop: '5px' }}
          sx={{ m: 1, minWidth: 80 }}
        >
          <InputLabel id="demo-simple-select-label">Select Template</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedTemplate}
            label="Age"
            onChange={(event) => myfunction(event)}
          >
            {templates?.map((i, j) => (
              <MenuItem key={i.id} value={i?.id}>
                {i.templateName}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        {/* <Select
            value={selectedTemplate}
            label="Age"
            onChange={(event) => {
              setSelectedTemplate(event.target.value);
            }}
          >
            {templates.map((i, j) => (
              <MenuItem key={i.id} value={i?.templateName}>
                {i.templateName}
              </MenuItem>
            ))}
            Send Emails
          </Select> */}
        <DialogContent>
          <Field
            name={'to'}
            label={'To'}
            component={TextInputForEmail}
            // toValue={toValue}
            type={'text'}
            variant={'outlined'}
          />
          <Field
            name={'bcc'}
            label={'Bcc'}
            // component="input"
            component={TextInputForEmail}
            toValue={toValue}
            type="email"
            variant={'outlined'}
            placeholder={'Bcc'}
            style={{
              // padding: '8.5px 14px',
              width: '100%',
              boxSizing: 'border-box',
              borderRadius: '4px',
              borderColor: '#0000003b',
              height: '39.97px',
              outline: 'none',
              marginTop: '1rem',
            }}
          />
          {/* <Field
               name={'subject'}

              label={'Subject'}
              component={TextInput}
              type={'text'}
              variant={'outlined'}
              style={{ marginTop: '1rem' }}
            /> */}
          <Field
            name={'subject'}
            label={'Subject'}
            style={{
              padding: '1.5px 0px',
              width: '100%',
              boxSizing: 'border-box',
              borderRadius: '4px',
              borderColor: '#0000003b',
              height: '39.97px',
              outline: 'none',
              marginTop: '1rem',
            }}
            component={TextInput}
            type={'text'}
            variant={'outlined'}
            initialValue={initialTemplateSubject}
          />
          <Grid item style={{ marginTop: '1rem' }}>
            <Typography variant={'body1'}>Content</Typography>
            <TextEditor
              editorValue={editorValue}
              handleChange={setEditorValue}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={myfun} color="secondary" disabled={submitting}>
            Close
          </Button>
          <Button color={'primary'} type={'submit'} disabled={submitting}>
            Send
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default connect(
  mapStateToProps,
  actions,
  null
)(
  reduxForm({ form: 'sendEmail', enableReinitialize: true })(
    SendEmailRequestDialog
  )
);
