import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Grid } from "@mui/material";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { reduxForm } from "redux-form";

import { connect } from "react-redux";
import { combineValidators, isRequired } from "revalidate";
import TextInputForm from "../../forms/TextInputForm";
import SelectInputForm from "../../forms/SelectInputForm";
import { useHistory } from "react-router-dom";
import { adminCreateApplicant } from "../../../store/actions/adminActions/applicantActions/adminApplicantActions";
import { fetchAllAffiliates } from "../../../store/actions/adminActions/affiliateActions/adminAffiliateActions";
import { formValueSelector } from "redux-form";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
  fieldContainer: {
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
  },
  questionText: {
    fontWeight: 600,
    textAlign: "right",
    paddingRight: "1em",
  },
}));
const actions = {
  adminCreateApplicant,
  fetchAllAffiliates,
};

const mapStateToProps = (state) => {
  let affiliates = [];

  if (
    state.adminAffiliates.allAffiliates &&
    state.adminAffiliates.allAffiliates.length > 0
  ) {
    affiliates = state.adminAffiliates.allAffiliates;
  }

  const questionSelector = formValueSelector("newApplicantForm");
  const fieldValues = questionSelector(state, "affiliateId");

  return {
    affiliates,
    fieldValues,
  };
};

const validate = combineValidators({
  firstName: isRequired({ message: "First Name is required" }),
  lastName: isRequired({ message: "Last Name is required" }),
  // middleInitial: isRequired({ message: "Middle Initial is required" }),
  email: isRequired({ message: "Email is required" }),
  // mobileNumber: isRequired({ message: "Mobile Number is required" }),
  // dob: isRequired({ message: "DOB is required" }),
  // gender: isRequired({ message: "Gender is required" }),
  // ethnicBackground: isRequired({ message: "Ethnic Background is required" }),
  // citizenshipStatus: isRequired({ message: "Citizenship Status is required" }),
  // address1: isRequired({ message: "Address 1 is required" }),
  // city: isRequired({ message: "City is required" }),
  // state: isRequired({ message: "State is required" }),
  // zipCode: isRequired({ message: "Zip Code is required" }),
  applicantAccountType: isRequired({ message: "Account Type is required" }),
  affiliateCode: isRequired({ message: "Affiliate Code is required" }),
});

const AdminNewApplicantForm = ({
  adminCreateApplicant,
  createApplicant,
  closeForm,
  handleSubmit,
  submitting,
  affiliates,
  fetchAllAffiliates,
  fieldValues,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [error, setError] = useState(null);

  const history = useHistory();

  useEffect(() => {
    fetchAllAffiliates();
  }, [fetchAllAffiliates]);

  const handleCreateApplicant = async (values) => {
    let createApplicant = await adminCreateApplicant(values);

    if (
      createApplicant.data ===
      "The email address is already in use by another account."
    ) {
      setError(createApplicant.data);
      return;
    }
    if (createApplicant.data === "The email address is improperly formatted.") {
      setError(createApplicant.data);
      return;
    }

    history.push("/admin/applicants");
  };

  return (
    <form autoComplete={"off"} onSubmit={handleSubmit(handleCreateApplicant)}>
      {/*INPUT CONTAINER*/}
      <Grid item container style={{ marginTop: "2em" }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {/*NAME*/}
          <TextInputForm fieldName={"firstName"} question={"First Name:"} />
          <TextInputForm fieldName={"lastName"} question={"Last Name:"} />

          <TextInputForm
            fieldName={"middleInitial"}
            question={"Middle Initial:"}
          />

          <TextInputForm fieldName={"email"} question={"Email:"} />
          {/*MOBILE NUMBER*/}
          {/*<TextInputForm*/}
          {/*  fieldName={"mobileNumber"}*/}
          {/*  question={"Mobile Number:"}*/}
          {/*/>*/}
          {/*/!*HOUSE PHONE NUMBER*!/*/}
          {/*<TextInputForm*/}
          {/*  fieldName={"housePhoneNumber"}*/}
          {/*  question={"House Phone Number:"}*/}
          {/*/>*/}

          {/*/!*DOB*!/*/}
          {/*<Grid*/}
          {/*  item*/}
          {/*  container*/}
          {/*  className={classes.fieldContainer}*/}
          {/*  alignItems={"center"}*/}
          {/*>*/}
          {/*  <Grid item lg={2} md={2} sm={2} xs={2}>*/}
          {/*    <Grid item container direction={"column"}>*/}
          {/*      <Grid item>*/}
          {/*        <Typography*/}
          {/*          variant={"body1"}*/}
          {/*          className={classes.questionText}*/}
          {/*        >*/}
          {/*          Date of Birth*/}
          {/*        </Typography>*/}
          {/*      </Grid>*/}
          {/*    </Grid>*/}
          {/*  </Grid>*/}
          {/*  <Grid item lg={10} md={10} sm={10} xs={10}>*/}
          {/*    <Grid item>*/}
          {/*      <Grid item container direction={"column"}>*/}
          {/*        <Grid item className={classes.fieldWrapper}>*/}
          {/*          <Field*/}
          {/*            name={"dob"}*/}
          {/*            component={KeyboardDateInput}*/}
          {/*            type={"text"}*/}
          {/*            variant={"outlined"}*/}
          {/*          />*/}
          {/*        </Grid>*/}
          {/*      </Grid>*/}
          {/*    </Grid>*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}

          {/*/!*GENDER*!/*/}
          {/*<SelectInputForm*/}
          {/*  fieldName={"gender"}*/}
          {/*  question={"Gender"}*/}
          {/*  onChange={null}*/}
          {/*  options={[*/}
          {/*    { value: "" },*/}
          {/*    { value: "Male" },*/}
          {/*    { value: "Female" },*/}
          {/*    { value: "Prefer not to Disclose" },*/}
          {/*  ]}*/}
          {/*/>*/}

          {/*/!*ETHNIC BACKGROUND*!/*/}

          {/*<SelectInputForm*/}
          {/*  fieldName={"ethnicBackground"}*/}
          {/*  question={"Ethnic background"}*/}
          {/*  onChange={null}*/}
          {/*  options={[*/}
          {/*    { value: "" },*/}
          {/*    { value: "Asian American/Pacific Islander" },*/}
          {/*    { value: "Black/African American" },*/}
          {/*    { value: "Caucasian" },*/}
          {/*    { value: "Latino" },*/}
          {/*    { value: "Multi-racial" },*/}
          {/*    { value: "Native American" },*/}
          {/*    { value: "Other" },*/}
          {/*    { value: "Prefer not to answer" },*/}
          {/*  ]}*/}
          {/*/>*/}

          {/*/!*CITIZENSHIP STATUS*!/*/}

          {/*<SelectInputForm*/}
          {/*  fieldName={"citizenshipStatus"}*/}
          {/*  question={"Citizenship status"}*/}
          {/*  onChange={null}*/}
          {/*  options={[*/}
          {/*    { value: "" },*/}
          {/*    { value: "US Citizen" },*/}
          {/*    { value: "US Permanent resident" },*/}
          {/*    { value: "Dual US Citizen" },*/}
          {/*  ]}*/}
          {/*/>*/}

          {/*/!*ADDRESS*!/*/}
          {/*<TextInputForm fieldName={"address1"} question={"Address 1:"} />*/}
          {/*<TextInputForm fieldName={"address2"} question={"Address 2:"} />*/}
          {/*<TextInputForm fieldName={"city"} question={"City:"} />*/}
          {/*<Grid*/}
          {/*  item*/}
          {/*  container*/}
          {/*  className={classes.fieldContainer}*/}
          {/*  alignItems={"center"}*/}
          {/*>*/}
          {/*  <Grid item lg={2} md={2} sm={2} xs={2}>*/}
          {/*    <Grid item container direction={"column"}>*/}
          {/*      <Grid item>*/}
          {/*        <Typography*/}
          {/*          variant={"body1"}*/}
          {/*          className={classes.questionText}*/}
          {/*        >*/}
          {/*          State*/}
          {/*        </Typography>*/}
          {/*      </Grid>*/}
          {/*    </Grid>*/}
          {/*  </Grid>*/}
          {/*  <Grid item lg={10} md={10} sm={10} xs={10}>*/}
          {/*    <Grid item style={{ width: "50%" }}>*/}
          {/*      <Grid item container direction={"column"}>*/}
          {/*        <Grid item className={classes.fieldWrapper}>*/}
          {/*          <Field*/}
          {/*            name={"state"}*/}
          {/*            component={SelectInput}*/}
          {/*            type={"text"}*/}
          {/*            variant={"outlined"}*/}
          {/*          >*/}
          {/*            {states.map((state) => (*/}
          {/*              <MenuItem key={state.value} value={state.text}>*/}
          {/*                {state.text}*/}
          {/*              </MenuItem>*/}
          {/*            ))}*/}
          {/*          </Field>*/}
          {/*        </Grid>*/}
          {/*      </Grid>*/}
          {/*    </Grid>*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
          {/*<TextInputForm fieldName={"zipCode"} question={"Zip Code:"} />*/}

          <SelectInputForm
            fieldName={"applicantAccountType"}
            question={"Applicant Type"}
            onChange={null}
            options={[
              { value: "" },
              { value: "Alumni" },
              { value: "New Applicant" },
            ]}
          />

          <SelectInputForm
            fieldName={"affiliateId"}
            question={"Affiliate"}
            onChange={null}
            options={[
              {
                value: "noAffiliate",
                text: "No Affiliate",
              },
              ...affiliates.map((affiliate) => {
                return {
                  value: affiliate.id,
                  text: affiliate.affiliateName,
                };
              }),
            ]}
            text
          />

          {/*AFFILIATES CODE*/}
          {fieldValues && fieldValues !== "noAffiliate" && (
            <TextInputForm
              fieldName={"affiliateCode"}
              question={"Affiliate Code"}
            />
          )}

          {error && (
            <Grid item style={{ marginTop: "2em" }}>
              <Typography
                variant={"subtitle1"}
                style={{
                  color: theme.palette.error.main,
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                {error}
              </Typography>
            </Grid>
          )}

          <Grid item container style={{ marginTop: "0.5em" }}>
            <Grid item style={{ marginLeft: "auto" }}>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Create
              </Button>
            </Grid>
            <Grid item>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/admin/applicants/")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "newApplicantForm",
    validate,
  })(AdminNewApplicantForm)
);
