import { createReducer } from '../../../../common/util/reducerUtil';
import { FETCH_REQUEST_INFORMATION } from '../../../constants/adminConstants/programConstants/adminProgramConstants';
const initialState = {
  allTransactions: [],
  users: [],
  allRequestedInfo: [],
};
const getAllRequestedInfo = (state, payload) => {
  console.log(payload, 'payload');
  return {
    ...state,
    allRequestedInfo: payload,
  };
};
export default createReducer(initialState, {
  [FETCH_REQUEST_INFORMATION]: getAllRequestedInfo,
});
