import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, MenuItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Field } from "redux-form";
import SelectInput from "../../common/form/SelectInput";

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  fieldWrapper: {
    paddingTop: "0.4em",
    paddingBottom: "0.4em",
  },
  questionText: {
    color: "#696974",
    fontWeight: "600",
    fontFamily: "Roboto",
    fontSize: "16px",
    letter: "0.2px",
    paddingRight: "1em",
  },
}));

const SelectInputFormOnChangeYesNo = ({
  question,
  fieldName,
  onChange,
  options,
}) => {
  const classes = useStyles();
  // const theme = useTheme();
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid
      item
      container
      className={classes.fieldContainer}
      alignItems={"center"}
    >
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Grid item container direction={"column"}>
          <Grid item>
            <Typography variant={"body1"} className={classes.questionText}>
              {question}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Grid item>
          <Grid item container direction={"column"}>
            <Grid item className={classes.fieldWrapper}>
              <Field
                name={fieldName}
                component={SelectInput}
                type={"text"}
                variant={"outlined"}
                onChange={(val, preVal) => {
                  onChange(preVal);
                }}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectInputFormOnChangeYesNo;
