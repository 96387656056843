import React, { Fragment, useEffect, useRef, useState } from 'react';
import { change } from 'redux-form';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import Paper from '@mui/material/Paper';

import { connect } from 'react-redux';
import {
  formValueSelector,
  getFormValues,
  isInvalid,
  reduxForm,
} from 'redux-form';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  submitShortResponsesForm,
  updateShortResponsesForm,
} from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import { combineReducers } from 'redux';
import { combineValidators } from 'revalidate';
import { isError, words } from 'lodash';
import { closeDialog } from '../../../../../store/actions/dialogActions/dialogActions';
import toast, { Toaster } from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: '0.4em',
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
    },
  },

  continueButton: {
    marginLeft: '0.4em',
    backgroundColor: '#1976d2',
    color: 'white !Important',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
      //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    },
  },
  customDisabled: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'red', // Change this to the desired color
    },
  },
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  formName: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '72px',
    letter: '0.2px',
  },
  formText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  formQuestionText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  questionText: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
    marginBottom: '16px',
    lineHeight: '24px',
    paddingRight: '1em',
  },
  question: {
    width: '100%',
    paddingRight: '5%',
    marginBotton: '3%',
  },
}));

const actions = {
  submitShortResponsesForm,
  updateShortResponsesForm,
};

const mapStateToProps = (state) => {
  const questionSelector = formValueSelector('shortResponsesForm');
  const fieldValues = questionSelector(state, 'id', 'bestFit');
  const invalid = isInvalid('shortResponsesForm');
  return {
    fieldValues,
    invalid,
    initialValues:
      state.applicantPrograms.registeredProgram[0].shortResponsesFormDetails,
    currentValues: getFormValues('shortResponsesForm')(state),
  };
};

const ShortResponsesForm = ({
  fieldValues,
  invalid,
  registeredProgramId,
  program,
  profile,
  submitShortResponsesForm,
  updateShortResponsesForm,
  handleCloseForm,
  handleSetForm,
  handleSubmit,
  // error,
  dispatch,
  submitting,
  currentValues,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [wordCounts, setWordCounts] = useState({
    wordCount: 1,
  });
  console.log(currentValues, 'currentValues');
  console.log(invalid, 'invalid');
  const [formError, setFormError] = useState('');
  const [multiselect, setmultiselect] = useState([]);
  const [shotReponseValues, setShotReponseValues] = useState([]);
  const [newObject, setnewObject] = useState({});
  const [additionalQuestions, setAdditionalQuestions] = useState([]);
  const [checkBoxValues, setCheckBoxValues] = useState([]);
  const [message, setMesssage] = useState('');
  const [error, setError] = useState(false);
  console.log(error, 'error');

  // const [isAtLeastOneCheckboxChecked, setIsAtLeastOneCheckboxChecked] =
  //   useState(false);

  // Update isAtLeastOneCheckboxChecked whenever checkBoxValues change
  // useEffect(() => {
  //   setIsAtLeastOneCheckboxChecked(checkBoxValues.length > 0);
  // }, []);

  // useEffect(() => {
  //   setAdditionalQuestions(
  //     program?.additionalQuestions.filter((item) => !item.hasOwnProperty("id"))
  //   );
  // }, [program && program.additionalQuestions]);
  useEffect(() => {
    setAdditionalQuestions(program?.additionalQuestions);
  }, [program && program.additionalQuestions]);
  useState(() => {
    setMesssage(
      program?.additionalQuestions?.filter((item) => item.select === 'plainTex')
    );
  }, [program && program.additionalQuestions]);
  console.log(message, 'message');
  const initialFieldValues = useRef(currentValues);
  useEffect(() => {
    // Update initialFieldValues when currentValues changes
    initialFieldValues.current = currentValues;
  }, [currentValues]);
  useEffect(() => {
    if (currentValues !== undefined) {
      const a = Object.keys(currentValues);
      const b = Object.values(currentValues);
      a.map((item, index) => {
        setShotReponseValues((el) => [
          ...el,
          {
            key: item,
            value: b[index],
          },
        ]);
      });
    }
  }, [currentValues]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const validate = combineValidators({});
  const [open, setOpen] = useState(false);

  const removeDuplicated = (inputData) => {
    const uniqueKeysMap = {}; // Object to track unique keys and their last values
    const deduplicatedArray = inputData.reduceRight((accumulator, item) => {
      if (!uniqueKeysMap[item.key]) {
        uniqueKeysMap[item.key] = item.value; // Store the last value for the key
        accumulator.unshift(item); // Add the item to the beginning of the array
      }
      return accumulator;
    }, []);
    return deduplicatedArray;
  };

  const programsAllThings = [
    'applicationInterests',
    'personalInformationForm',
    'familyInformationForm',
    'academicInformationForm',
    // 'recommendationsRequired',
    'recommendersForm',
    'extracurricularForm',
    'covidProtocolForm',
    'resumeForm',
    'transcriptForm',
    'shortResponsesForm',
    'scholarshipForm',
    'taxForm',
    'additionalDocumentsForm',
    'confirmationForm',
  ];

  const programAllThingsValue = {
    applicationInterests: 'applicationInterests',
    personalInformationForm: 'personalInformationForm',
    familyInformationForm: 'familyInformationForm',
    academicInformationForm: 'academicInformationForm',
    // recommendationsRequired: 'recommendationsRequired',
    recommendersForm: 'recommendersForm',
    extracurricularForm: 'extracurricularForm',
    covidProtocolForm: 'covidProtocolForm',
    resumeForm: 'resumeForm',
    transcriptForm: 'transcriptForm',
    shortResponsesForm: 'shortResponsesForm',
    scholarshipForm: 'scholarshipForm',
    taxForm: 'taxForm',
    additionalDocumentsForm: 'additionalDocumentsForm',
    confirmationForm: 'confirmationForm',
  };

  let dataToCheck = programsAllThings
    .filter((item) => program[item])
    .map((item) => programAllThingsValue[item]);
  console.log(program?.additionalQuestions?.length, 'man');

  const handleFormSubmit = async (values) => {
    values = {};

    const deduplicatedArray = removeDuplicated(shotReponseValues);
    var object = deduplicatedArray.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.value }),
      {}
    );
    // setIsAtLeastOneCheckboxChecked(checkBoxValues.length > 0);
    // if (isAtLeastOneCheckboxChecked) {
    //   // Perform your form submission logic here
    //   console.log('Form submitted successfully!');
    // } else {
    //   alert('Please select at least one checkbox before submitting.');
    // }
    // if (checkBoxValues.length === 0) {
    //   setFormError('Please select at least one option.');
    // } else {
    //   // Form submission logic here (e.g., submit the form data)
    //   // Reset the error message
    //   setFormError('');
    // }

    console.log({ object });
    delete object.TotalshortResponsesFormCompleted;
    if (
      Object.keys(object).length ==
      program?.additionalQuestions?.filter((item) => item.select !== 'plainTex')
        .length
    ) {
      object.TotalshortResponsesFormCompleted = 'true';
    }
    if (
      Object.keys(object).length <
      program?.additionalQuestions?.filter((item) => item.select !== 'plainTex')
        .length
    ) {
      object.TotalshortResponsesFormCompleted = 'false';
    }
    values = object;
    console.log(
      program?.additionalQuestions?.filter((item) => item.select !== 'plainTex')
        .length,
      'ljk'
    );
    console.log(Object.keys(object).length, 'obj');

    try {
      await submitShortResponsesForm(registeredProgramId, values);
      handleCloseForm();

      const indexOfPersonalInfoForm = dataToCheck.indexOf('shortResponsesForm');
      if (
        indexOfPersonalInfoForm !== -1 &&
        indexOfPersonalInfoForm < dataToCheck.length - 1
      ) {
        const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
        console.log(nextForm, 'nextForm');
        handleSetForm(nextForm);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(closeDialog());
  };
  const handleFormSave = async (values) => {
    console.log(values, 'lk');
    values = {};

    const deduplicatedArray = removeDuplicated(shotReponseValues);
    console.log(deduplicatedArray, 'deduplicatedArray');
    setShotReponseValues(deduplicatedArray);
    var object = deduplicatedArray.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.value }),
      {}
    );
    console.log(object, 'ojkl');

    function removeEmptyStringKeys(obj) {
      for (const key in obj) {
        if (
          obj.hasOwnProperty(key) &&
          typeof obj[key] === 'string' &&
          obj[key].trim() === ''
        ) {
          delete obj[key];
        }
      }
    }
    removeEmptyStringKeys(object);

    console.log(Object.keys(object).length, 'mab');

    values = object;

   // console.log(values, 'shyam');

   // console.log(values, 'ramssss');

    if (Object.keys(object).length > 0) {
      try {
        await updateShortResponsesForm(registeredProgramId, values);
        handleCloseForm();
      } catch (error) {
        console.log(error);
      }
    } else {
      // Object is empty, handle accordingly (e.g., show a message)
      console.log('Object is empty, not calling updateShortResponsesForm');
    }
  };
  const notify = () => toast.success('Progress Autosaved.');

  const handleFormAutoSave = async (values) => {
    values = {};
    const currentShotReponseValues = [...shotReponseValues];
    const deduplicatedArray = removeDuplicated(currentShotReponseValues);

    // Set the new object and calculate values
    var object = deduplicatedArray.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.value }),
      {}
    );
    console.log(object, 'ojkl');

    function removeEmptyStringKeys(obj) {
      for (const key in obj) {
        if (
          obj.hasOwnProperty(key) &&
          typeof obj[key] === 'string' &&
          obj[key].trim() === ''
        ) {
          delete obj[key];
        }
      }
    }
    removeEmptyStringKeys(object);

    values = object;
    if (Object.keys(object).length > 0) {
      try {
        // Move setShotReponseValues here
        setShotReponseValues(deduplicatedArray);

        await updateShortResponsesForm(registeredProgramId, values);
        // handleCloseForm();
        notify();
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log('Object is empty, not calling updateShortResponsesForm');
    }
  };
  const saveInterval = 5 * 60 * 1000;
  useEffect(() => {
    const saveFormInterval = setInterval(() => {
      if (error === false) {
        handleFormAutoSave(currentValues);
      }
      // setOpen(true);
    }, saveInterval);

    // Clear the interval when the component is unmounted
    return () => clearInterval(saveFormInterval);
  }, [currentValues]);
  // useEffect(() => {
  //   handleFormSave(currentValues);
  // }, [currentValues]);
  console.log(currentValues, 'currentValues');
  const calculateWordCount = (text, max) => {
    console.log(max, 'max');
    console.log(text, 'text');
    const words = text.trim().split(/\s+/);
    console.log('words', words.length);
    const wordCount = words.count;
    if (words.length > max) {
      setError(true);
    }
    if (words.length <= max) {
      setError(false);
    }
    console.log('wordCount', wordCount);
    setWordCounts((prevCounts) => ({
      ...prevCounts,
      wordCount,
    }));
    console.log(wordCount, 'wordCount');
  };

  const setUpcheckboxValues = (question) => {
    if (question?.question != undefined) {
      setShotReponseValues((el) => [
        ...el,
        {
          key: question?.question,
          value: checkBoxValues.join(','),
        },
      ]);
    }
  };
  console.log(additionalQuestions, 'additionalQuestions');

  useEffect(() => {
    if (currentValues !== undefined) {
      const checkboxValues = [];
      additionalQuestions.forEach((question) => {
        if (question.select === 'checkbox') {
          question.options.forEach((option) => {
            const isChecked = currentValues[question.question]
              ?.split(',')
              .includes(option.optionLabel);
            if (isChecked) {
              checkboxValues.push(option.optionLabel);
            }
          });
        }
      });
      setCheckBoxValues(checkboxValues);
    }
  }, [currentValues]);

  useEffect(() => {
    if (checkBoxValues) {
      setUpcheckboxValues();
    }
  }, [checkBoxValues]);
  const handleFieldChange = (field, value) => {
    setnewObject({
      key: field,
      value: value,
    });
    // Dispatch a change action to update the Redux Form state
    dispatch(change('shortResponsesForm', field, value));
    setShotReponseValues((el) => [
      ...el,
      {
        key: field,
        value: value,
      },
    ]);
  };
  return (
    <Fragment>
      <Toaster />

      <form autoComplete={'off'} onSubmit={handleSubmit(handleFormSubmit)}>
        {/*QUESTIONS*/}
        {additionalQuestions && additionalQuestions?.length > 0 && (
          <>
            <Typography className={classes.formName}>
              Short Responses and Essay
            </Typography>

            {additionalQuestions?.map((question, index) => (
              <Grid key={index} container>
                <Grid item md={12}>
                  <Grid item container direction={'container'}>
                    <Paper
                      elevation={3}
                      style={{
                        marginTop: '16px',
                        padding: '8px 10px',
                        width: '100%',
                        borderRadius: '8px',
                      }}
                    >
                      <Grid item>
                        <div
                          style={{
                            // display: "flex",
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                            //lineHeight:"24px",
                            paddingBottom: '15px',
                          }}
                        >
                          <div className={classes.question}>
                            {question?.maxLength !== undefined ? (
                              <span className={classes.questionText}>
                                {question.question +
                                  ` (Must be ${question?.maxLength} words or less)`}
                              </span>
                            ) : question?.select == 'plainTex' ? (
                              <span className={classes.questionText}>
                                {question.question}
                              </span>
                            ) : (
                              <span className={classes?.questionText}>
                                {question.question}
                              </span>
                            )}
                          </div>
                          {question?.select === 'inputTex' && (
                            <>
                              <TextareaAutosize
                                variant="outlined"
                                minRows={20}
                                required
                                inputProps={{
                                  maxLength:
                                    wordCounts?.wordCount ==
                                    Number(question?.maxLength) + 1
                                      ? Number(question?.maxLength) + 1
                                      : undefined,
                                }}
                                maxLength={
                                  wordCounts?.wordCount ==
                                  Number(question?.maxLength) + 1
                                    ? Number(question?.maxLength) + 1
                                    : undefined
                                }
                                multiline={true}
                                rows={6}
                                defaultValue={
                                  currentValues !== undefined
                                    ? currentValues[question?.question]
                                    : ''
                                }
                                // onPaste={(e) => {
                                //   e.preventDefault(); // Prevent default paste behavior
                                //   const pastedText =
                                //     e.clipboardData.getData('text/plain');
                                //   const maxLength = Number(question?.maxLength);
                                //   // Split pasted text into words
                                //   const words = pastedText
                                //     .split(/\s+/)
                                //     .filter(Boolean);
                                //   // Take only the first words that fit within the length limit
                                //   const truncatedText = words
                                //     .slice(0, maxLength)
                                //     .join(' ');
                                //   // Update textarea value with truncated text
                                //   e.target.value = truncatedText;
                                //   // Trigger the onChange event manually to update state and perform other actions
                                //   e.target.dispatchEvent(
                                //     new Event('input', { bubbles: true })
                                //   );
                                // }}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  console.log(inputValue, 'inputValue');
                                  // if (
                                  //   inputValue.split(/\s+/).filter(Boolean)
                                  //     .length <=
                                  //   Number(question?.maxLength) + 1
                                  // ) {
                                  setnewObject({
                                    key: question?.question,
                                    value: inputValue,
                                  });
                                  calculateWordCount(
                                    inputValue,
                                    question?.maxLength
                                  );
                                  // }
                                  handleFieldChange(
                                    question?.question,
                                    inputValue
                                  );
                                }}
                                onBlur={() => {
                                  setShotReponseValues((el) => [
                                    ...el,
                                    newObject,
                                  ]);
                                }}
                                style={{
                                  width: '100%',
                                  //marginBottom: "3%",
                                  // marginTop: "16px",
                                  padding: '8px 8px 8px 0', //resize:"both"
                                }}
                              />
                              <div>
                                <div style={{ color: 'red' }}>
                                  {error &&
                                    `Answer Must be ${question?.maxLength} words or less`}
                                </div>
                              </div>
                            </>
                          )}

                          {question?.select === 'plainTex' && (
                            <>
                              <div className="">{question?.plaintex}</div>
                            </>
                          )}
                          {question?.select === 'singleSelect' && (
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              required
                              defaultValue={
                                currentValues !== undefined
                                  ? currentValues[question?.question]
                                  : ''
                              }
                              onChange={(e) => {
                                setShotReponseValues((el) => [
                                  ...el,
                                  {
                                    key: question?.question,
                                    value: e.target.value,
                                  },
                                ]);
                              }}
                              style={{
                                width: '100%',
                                marginTop: '16px',
                                marginBottom: '3%',
                                padding: '8px 8px 8px 0',
                              }}
                            >
                              {question?.options?.map((item) => {
                                return (
                                  <MenuItem value={item?.optionLabel}>
                                    {item?.optionLabel}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                          {question?.select === 'Multiselect' && (
                            <Select
                              labelId="demo-multiple-name-label"
                              required
                              id="demo-multiple-name"
                              defaultValue={
                                currentValues &&
                                question?.question &&
                                Object.keys(currentValues).includes(
                                  question?.question
                                ) &&
                                currentValues[question?.question]
                                  ? currentValues[question?.question]?.split(
                                      ','
                                    )
                                  : multiselect
                              }
                              multiple
                              style={{
                                width: '100%',
                                marginTop: '16px',
                                marginBottom: '3%',
                                padding: '8px 8px 8px 0',
                              }}
                              onBlur={(e) => {
                                let values = e.target.value;
                                setShotReponseValues((el) => [
                                  ...el,
                                  {
                                    key: question?.question,
                                    value: values.join(','),
                                  },
                                ]);
                              }}
                              input={<OutlinedInput label="Name" />}
                            >
                              {question &&
                                question?.options?.map((item) => (
                                  <MenuItem
                                    key={item?.optionLabel}
                                    value={item?.optionLabel}
                                  >
                                    {item?.optionLabel}
                                  </MenuItem>
                                ))}
                            </Select>
                          )}
                          {question?.select === 'checkbox' && (
                            <div>
                              <FormGroup required={true}>
                                {question?.options?.map((item, index) => {
                                  return (
                                    <FormControlLabel
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          let values = e.target.value;
                                          console.log(values, 'checked');
                                          setCheckBoxValues((el) => [
                                            ...el,
                                            values,
                                          ]);
                                        } else if (!e.target.checked) {
                                          setCheckBoxValues((prev) =>
                                            prev.filter(
                                              (fruit) =>
                                                fruit !== e.target.value
                                            )
                                          );
                                        }
                                      }}
                                      value={item?.optionLabel}
                                      // required
                                      control={
                                        <Checkbox
                                          defaultChecked={
                                            currentValues !== undefined &&
                                            currentValues[question?.question]
                                              ?.split(',')
                                              .includes(item?.optionLabel)
                                          }
                                          style={{ display: 'flex' }}
                                          onBlur={() => {
                                            setUpcheckboxValues(question);
                                          }}
                                        />
                                      }
                                      label={item?.optionLabel}
                                    />
                                  );
                                })}
                              </FormGroup>
                              {/* {isAtLeastOneCheckboxChecked ? 'please' : 'jjj'} */}
                              {/* {formError && (
                                <div
                                  className="error-message"
                                  style={{ color: 'red' }}
                                >
                                  {formError}
                                </div>
                              )} */}
                            </div>
                          )}
                          {question?.select === 'radioButton' && (
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue={
                                currentValues !== undefined
                                  ? currentValues[question?.question]
                                  : ''
                              }
                              name="radio-buttons-group"
                              onBlur={(e) => {
                                setShotReponseValues((el) => [
                                  ...el,
                                  {
                                    key: question?.question,
                                    value: e.target.value,
                                  },
                                ]);
                              }}
                            >
                              {question?.options?.map((item) => {
                                return (
                                  <FormControlLabel
                                    value={item?.optionLabel}
                                    control={<Radio required={true} />}
                                    label={item?.optionLabel}
                                    defaultChecked={
                                      currentValues !== undefined &&
                                      currentValues[question?.question] ===
                                        item?.optionLabel
                                        ? true
                                        : false
                                    }
                                  />
                                );
                              })}
                            </RadioGroup>
                          )}
                        </div>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </>
        )}

        {/*BUTTONS*/}
        <Grid item style={{ marginTop: '1.5em' }}>
          {invalid && (
            <Grid item container style={{ marginBottom: '0.5em' }}>
              <Grid item style={{ marginLeft: 'auto' }}>
                <Typography
                  variant={'subtitle1'}
                  style={{ color: theme.palette.error.main, fontWeight: 300 }}
                >
                  Fill out all required fields.
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid item container>
            <Grid item style={{ marginLeft: 'auto' }}>
              <Button
                variant={'outlined'}
                color={'primary'}
                className={classes.button}
                onClick={() => handleFormSave(currentValues)}
                disabled={submitting || error}
              >
                Save for Later
              </Button>
              <Button
                variant={'outlined'}
                color={'primary'}
                className={error ? classes.button : classes.continueButton}
                type={'submit'}
                disabled={submitting || error}
              >
                Continue{' '}
                <span>
                  <strong> &#8594;</strong>
                </span>
              </Button>
              <Button
                variant={'outlined'}
                color={'secondary'}
                className={classes.button}
                onClick={() => handleCloseForm()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: 'shortResponsesForm',
    enableReinitialize: true,
  })(ShortResponsesForm)
);
