import { createReducer } from "../../../../common/util/reducerUtil";
import {
    FETCH_ADMIN_AND_REVIEWER,
    FETCH_ALL_ADMINS_AND_REVIEWERS,
    FETCH_ALL_TEMPLATES, FETCH_SIGNNOW_SETTINGS, FETCH_TEMPLATE, FETCH_VIEW_CAROUSEL_SETTINGS, FETCH_NEWS_ANNOUNCEMENTS_DETAILS
} from "../../../constants/adminConstants/settingsConstants/settingsConstants";


const initialState = {
    allAdminsAndReviewers: [],
    adminAndReviewer: [],
    allTemplates: [],
    template: [],
    signNow: []
};

const getAllAdminsAndReviewers = (state, payload) => {
    return {
        ...state,
        allAdminsAndReviewers: payload.allAdminsAndReviewers,
    };
};

const getAdminAndReviewer = (state, payload) => {
    return {
        ...state,
        adminAndReviewer: payload.adminAndReviewer,
    };
};

const getAllTemplates = (state, payload) => {
    return {
        ...state,
        allTemplates: payload.allTemplates,
    };
};

const getTemplate = (state, payload) => {
    return {
        ...state,
        template: payload.template,
    };
};

const getSignNow = (state, payload) => {
    return {
        ...state,
        signNow: payload.signNow,
    };
};

const getViewCarouselSetting = (state, payload) => {
    return {
        ...state,
        settings: payload.settings,
    };
};

const getNewsAndAnnouncementsDetails = (state, payload) => {
    return {
        ...state,
        settings: payload.newsDetails,
    };
};


export default createReducer(initialState, {
    [FETCH_ALL_ADMINS_AND_REVIEWERS]: getAllAdminsAndReviewers,
    [FETCH_ADMIN_AND_REVIEWER]: getAdminAndReviewer,
    [FETCH_ALL_TEMPLATES]: getAllTemplates,
    [FETCH_TEMPLATE]: getTemplate,
    [FETCH_SIGNNOW_SETTINGS]: getSignNow,
    [FETCH_VIEW_CAROUSEL_SETTINGS]: getViewCarouselSetting,
    [FETCH_NEWS_ANNOUNCEMENTS_DETAILS]: getNewsAndAnnouncementsDetails,
});
