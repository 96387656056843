import { createReducer } from "../../../common/util/reducerUtil";
import { ADDITIONAL_DOCUMENT } from "../../constants/additionalDocumentConstant.js/additionalDocumentConstant";

const initialState = {
  myValue: 'false',
};

const myValueReducer = (state =initialState , payload,action) => {
    console.log(action,"payload")
    switch (action.type) {
        case 'SET_MY_VALUE':
          return { ...state, myValue: action.payload };
        default:
          return state;
      }
};

export default createReducer(initialState, {
  ['SET_MY_VALUE']: myValueReducer,
});
