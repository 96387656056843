import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Dialog, DialogContent, Grid, Typography } from '@mui/material';
import {
  saveScholarConsentForm,
  deleteScholarConsentFile,
  // getScholarConsentForm,
} from '../../../../../../store/actions/applicantActions/programActions/applicantApprovedActions';
import Button from '@mui/material/Button';
import axios from '../../../../../../config/axios';
import { connect, useDispatch } from 'react-redux';
import './ScholarConcentForm.css';
import { useMemo } from 'react';
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from '@mui/x-data-grid-premium';
import { format, parseISO } from 'date-fns';
import { closeDialog } from '../../../../../../store/actions/dialogActions/dialogActions';
import toast, { Toaster } from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  questionText: {
    fontWeight: 600,
    paddingRight: '1em',
  },
  button: {
    marginLeft: '0.4em',
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
    },
  },

  continueButton: {
    marginLeft: '0.4em',
    backgroundColor: '#1976d2',
    color: 'white !Important',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
      //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    },
  },
  headerBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}));
const programsAllThings = [
  'scholarConsentForm',
  'governmentIdForm',
  'paymentForm',
  'travelInformationForm',
  'studentQuestionnaireForm',
  'downloadProgramPacketForm',
  'requiredDocumentationForm',
];
const programAllThingsValue = {
  scholarConsentForm: 'scholarConsentForm',
  governmentIdForm: 'governmentIdForm',
  paymentForm: 'paymentForm',
  travelInformationForm: 'travelInformationForm',
  studentQuestionnaireForm: 'studentQuestionnaireForm',
  downloadProgramPacketForm: 'downloadProgramPacketForm',
  requiredDocumentationForm: 'requiredDocumentationForm',
};
const programsAllThingsWithFilter = [
  'scholarConsentForm',
  'governmentIdForm',
  'paymentForm',
  // "travelInformationForm",
  'studentQuestionnaireForm',
  'downloadProgramPacketForm',
  // "requiredDocumentationForm"
];
const programAllThingsValueWithFilter = {
  scholarConsentForm: 'scholarConsentForm',
  governmentIdForm: 'governmentIdForm',
  paymentForm: 'paymentForm',
  // travelInformationForm: "travelInformationForm",
  studentQuestionnaireForm: 'studentQuestionnaireForm',
  downloadProgramPacketForm: 'downloadProgramPacketForm',
  // requiredDocumentationForm: "requiredDocumentationForm"
};

const fileRows = [
  {
    id: 'scholarParentConsent',
    name: 'Parent Consent Form',
    due: 'Due Today',
    required: true,
  },
  {
    id: 'scholarConsent',
    name: 'Lead Acceptance Form',
    due: 'Due Today',
    required: true,
  },
];

const ScholarConsentForm = ({
  registeredProgramId,
  registeredProgram,
  program,
  handleCloseForm,
  getScholarConsentForm,
  disabled,
  handleSetForm,
  saveScholarConsentForm,
  deleteScholarConsentFile,
}) => {
  const classes = useStyles();
  const [files, setFiles] = useState({});
  console.log(files, 'files');
  const [fileRowsNew, setfileRowsNew] = useState([]);
  console.log(fileRowsNew, 'fileRowsNew');
  const [downloadFiles, setDownloadFiles] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      'e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
    );
  }, []);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    dispatch(closeDialog());
  };
  let dataToCheck = programsAllThings
    .filter((item) => program[item])
    .map((item) => programAllThingsValue[item]);

  let dataToCheckWithFilter = programsAllThingsWithFilter
    .filter((item) => program[item])
    .map((item) => programAllThingsValueWithFilter[item]);

  const dueDate = program?.depositDeadline
    ? format(parseISO(program?.depositDeadline), 'MMM do y')
    : null;

  useEffect(() => {
    if (registeredProgram.scholarConsentFormDetails) {
      setFiles(registeredProgram.scholarConsentFormDetails);
    }
    if (program?.acceptanceParentConsentDocuments) {
      return program?.acceptanceParentConsentDocuments?.map((item) => {
        setfileRowsNew((el) => [
          ...el,
          {
            id: item?.file?.fileName,
            url: item?.file?.fileURL,
            name: item?.title,
            // due: "Due Today",
            due: dueDate,
            required: true,
          },
        ]);
        setDownloadFiles((el) => [
          ...el,
          {
            id: item?.file?.fileName,
            download: item?.file?.fileURL,
          },
        ]);
      });
    }
  }, []);

  const memoizedColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Document Name',
        flex: 2,
        sortable: false,
        headerClassName: classes.headerBackground,
        renderHeader: (params) => (
          <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
            {params.field}
          </Typography>
        ),
      },
      {
        field: 'due',
        headerName: 'Due',
        sortable: false,
        flex: 1,
        headerClassName: classes.headerBackground,
        renderHeader: (params) => (
          <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
            {params.field}
          </Typography>
        ),
      },
      {
        field: 'download',
        headerName: 'Download',
        sortable: false,
        flex: 1,
        headerClassName: classes.headerBackground,
        renderHeader: (params) => (
          <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
            {params.field}
          </Typography>
        ),
        renderCell: (params) => {
          if (!Object.keys(files).includes(params.id))
            console.log('params', params);
          return (
            <Button
              color="primary"
              component="label"
              size="small"
              onClick={() => {
                window.open(params.row.url, 'New Tiel');
              }}
            >
              Download
            </Button>
          );
        },
      },
      {
        field: 'upload',
        headerName: 'Upload',
        sortable: false,
        headerClassName: classes.headerBackground,
        renderHeader: (params) => (
          <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
            {params.field}
          </Typography>
        ),
        renderCell: (params) => {
          if (!Object.keys(files).includes(params.id))
            return (
              <Button color="primary" component="label" size="small">
                Upload
                <input
                  type="file"
                  hidden
                  onChange={(event) => {
                    setFiles((prevFiles) => ({
                      ...prevFiles,
                      [params.id]: event.target.files,
                    }));
                    fileRowsNew.map((item) => {
                      if (item.id === params.row.id) {
                        item.upload = event?.target?.files[0]?.name;
                      }
                    });
                    // setfileRowsNew(fileRowsNew);
                  }}
                />
              </Button>
            );
          return params.value ? (
            <>{params.value}</>
          ) : (
            <span style={{ color: '#3DD598' }}>UPLOADED</span>
          );
        },
      },
      {
        field: 'delete',
        headerName: 'Delete',
        sortable: false,
        headerClassName: classes.headerBackground,
        renderHeader: (params) => (
          <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
            {params.field}
          </Typography>
        ),
        renderCell: (params) => {
          if (Object.keys(files).includes(params.id))
            return (
              <Button
                color="secondary"
                component="label"
                size="small"
                onClick={() => {
                  if (files[params.id][0]?.fileURL)
                    deleteScholarConsentFile(
                      registeredProgramId,
                      params.id,
                      files[params.id]
                    );
                  setFiles((prevFiles) => {
                    const newFiles = {
                      ...prevFiles,
                    };
                    delete newFiles[params.id];
                    return newFiles;
                  });
                }}
              >
                Delete
              </Button>
            );
          return <></>;
        },
      },
    ],
    [files, deleteScholarConsentFile, registeredProgramId]
  );
  const notify = () => toast.success('Progress Autosaved.');

  const saveInterval = 5 * 60 * 1000;
  useEffect(() => {
    // ... other code ...

    // Create an interval to call saveGovernmentIdForm every 5 seconds
    const intervalId = setInterval(() => {
      if (Object.keys(files).length > 0) {
        saveScholarConsentForm(registeredProgramId, files, false);
        notify();
      }
    }, saveInterval);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  });
  return (
    <Fragment>
      <Toaster />

      <Typography
        variant={'h6'}
        style={{ fontWeight: 600, marginTop: '1em', marginBottom: '1em' }}
      >
        LEAD Acceptance Forms
      </Typography>
      <DataGridPremium
        autoHeight
        hideFooter
        disableSelectionOnClick
        disableColumnMenu
        columns={memoizedColumns}
        rows={fileRowsNew}
        components={{ Toolbar: GridToolbar }}
      />
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        style={{ marginTop: '1.5rem' }}
      >
        <Button
          variant={'outlined'}
          color={'primary'}
          className={classes.button}
          onClick={() => {
            saveScholarConsentForm(registeredProgramId, files, false);
            handleCloseForm();
          }}
        >
          Save for Later
        </Button>
        <Button
          variant={'outlined'}
          color={'primary'}
          className={classes.continueButton}
          // disabled={
          //   fileRows.filter((file) => file.required).length !==
          //   Object.keys(files).length
          // }
          disabled={
            fileRowsNew.filter((file) => file.required).length !==
            Object.keys(files).length
          }
          onClick={async () => {
            await saveScholarConsentForm(registeredProgramId, files, true);
            const indexOfPersonalInfoForm =
              dataToCheck.indexOf('scholarConsentForm');
            if (
              indexOfPersonalInfoForm !== -1 &&
              indexOfPersonalInfoForm < dataToCheck.length - 1
            ) {
              const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
              const nextForms = dataToCheck[indexOfPersonalInfoForm + 2];

              console.log(nextForms, 'nextForms');
              console.log(nextForm, 'nextForm');

              if (nextForm == 'paymentForm' && program.freeProgram) {
                handleSetForm(nextForms);
              }
              if (nextForm == 'paymentForm' && !program.freeProgram) {
                handleSetForm(nextForm);
              } else {
                handleSetForm(nextForm);
              }
            }
            // handleSetForm("governmentIdForm");
          }}
        >
          Continue{' '}
          <span>
            <strong> &#8594;</strong>
          </span>
        </Button>
        <Button
          variant={'outlined'}
          color={'secondary'}
          className={classes.button}
          onClick={() => handleCloseForm()}
        >
          Cancel
        </Button>
      </Grid>
      <Dialog
        maxWidth="md"
        // sx={{
        //   '& .MuiDialog-paper': {
        //     marginTop: '-22px',
        //     // Adjust top margin according to your preference
        //     maxHeight: 'calc(100% - 96px)', // Adjust height to leave space for title and actions
        //   },
        // }}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          {/* Your existing content here */}
          <div>Progress Autosave</div>
          {/* Add the rest of your content here */}
          {/* ... */}
          {/* Buttons to close the modal */}
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.button}
            style={{ float: 'right', width: 'fit-content' }}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

const actions = {
  saveScholarConsentForm,
  deleteScholarConsentFile,
  // getScholarConsentForm,
};

export default connect(null, actions)(ScholarConsentForm);
