import React from "react";
import { useTheme } from "@mui/styles";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import { connect } from "react-redux";
import { closeDialog } from "../../store/actions/dialogActions/dialogActions";
import { unassignReviewer } from "../../store/actions/adminActions/dashboardActions/dashboardActions";

const actions = {
  unassignReviewer,
  closeDialog,
};

const UnassignReviewerDialog = ({
  unassignReviewer,
  closeDialog,
  review,
  handleSubmit,
  onReload
}) => {
  const theme = useTheme();

  const handleUnassignReviewer = async (values) => {
    await unassignReviewer(review.applicantUid).then(()=>onReload());

    closeDialog();
  };

  const handleClose = () => {
    closeDialog();
  };
  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">Unassign Reviewer</DialogTitle>

      <DialogActions>
        <Button
          onClick={() => closeDialog()}
          style={{ color: theme.palette.error.main }}
        >
          Close
        </Button>
        <Button color={"primary"} onClick={() => handleUnassignReviewer()}>
          Unassign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(null, actions)(UnassignReviewerDialog);
