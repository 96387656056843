import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../common/form/TextInput';
import TextEditor from '../../common/TextEditor/TextEditor';
import TextInputForEmail from '../../common/form/TextInputForEmail';

const mapStateToProps = (state, ownProps) => {
  if (ownProps.toValue) {
    return {
      initialValues: {
        to: ownProps.toValue,
      },
    };
  }
};

const SendEmailDialogs = ({
  isOpen,
  toValue,
  closeDialog,
  submitting,
  handleSubmit,
  handleSendEmail,
  editorValue,
  setEditorValue,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      maxWidth="md"
      disableEnforceFocus
    >
      {console.log(toValue, 'to')}
      <form onSubmit={handleSubmit(handleSendEmail)}>
        <DialogTitle>Send Email</DialogTitle>
        <DialogContent style={{ paddingTop: '5px' }}>
          <Field
            name={'to'}
            label={'To'}
            component={TextInputForEmail}
            toValue={toValue}
            type={'text'}
            variant={'outlined'}
          />
          <Field
            name={'bcc'}
            label={'Bcc'}
            component="input"
            type="email"
            variant={'outlined'}
            placeholder={'Bcc'}
            style={{
              padding: '8.5px 14px',
              width: '100%',
              boxSizing: 'border-box',
              borderRadius: '4px',
              borderColor: '#0000003b',
              height: '39.97px',
              outline: 'none',
              marginTop: '1rem',
            }}
          />
          <Field
            name={'subject'}
            label={'Subject'}
            component={TextInput}
            type={'text'}
            variant={'outlined'}
            style={{ marginTop: '1rem' }}
          />
          <Grid item style={{ marginTop: '1rem' }}>
            <Typography variant={'body1'}>Content</Typography>
            <TextEditor
              editorValue={editorValue}
              handleChange={setEditorValue}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="secondary" disabled={submitting}>
            Close
          </Button>
          <Button color={'primary'} type={'submit'} disabled={submitting}>
            Send
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default connect(
  mapStateToProps,
  null
)(reduxForm({ form: 'sendEmail' })(SendEmailDialogs));
