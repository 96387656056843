import React from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "white",
    height: "155px",
    width: "100%",
    padding: "20px 24px",
    borderRadius: "8px",
    cursor: "pointer",
    boxShadow:
      "rgb(95 116 141 / 3%) 0px 2px 1px -1px, rgb(95 116 141 / 4%) 0px 1px 1px 0px, rgb(95 116 141 / 8%) 0px 1px 3px 0px",

    // paddingLeft: '24px',
    // paddingRight: '24px',
  },
  boxTitle: {
    color: "#171725",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
    letter: "0.1px",
  },
  boxValue: {
    color: "#171725",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "28px",
    lineHeight: "42px",
    letter: "0.12px",
  },
}));

const SidebarBox = ({ title, value, dollar, color, column, click }) => {
  const matches = useMediaQuery("(max-width:803px)");
  const classes = useStyles();

  const history = useHistory();

  return (
    <Grid
      item
      md={column}
      style={{
        overflow: "",
        flex: 1,
      }}
    >
      <Grid
        item
        container
        direction={"column"}
        justifyContent="space-between"
        className={classes.container}
        onClick={() => click && history.push(click)}
      >
        <Grid item>
          <Typography className={classes.boxTitle}>{title}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.boxValue}>
            {dollar ? "$" : null}
            {Math.round(value * 100) / 100}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SidebarBox;
