import { createReducer } from "../../../../common/util/reducerUtil";
import {
    FETCH_ALL_APPLICANT_LOGS
} from "../../../constants/adminConstants/logsConstants/logsConstants";


const initialState = {
    allApplicantLogs: [],
};

const getAllApplicantLogs = (state, payload) => {
    return {
        ...state,
        allApplicantLogs: payload.allApplicantLogs,
    };
};

export default createReducer(initialState, {
    [FETCH_ALL_APPLICANT_LOGS]: getAllApplicantLogs,
});
