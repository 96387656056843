import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from '../asyncActions/asyncActions';
import {
  sendRecommenderInvite,
  submitAcademicInformationForm,
  submitAdditionalDocumentsForm,
  submitExtracurricularActivitiesForm,
  submitFamilyInformationForm,
  submitPersonalInformationForm,
  submitRecommenderForm,
  submitResumeForm,
  submitScholarshipForm,
  submitShortResponsesForm,
  submitTaxForm,
  submitTranscriptForm,
} from '../applicantActions/programActions/applicantApplicationActions';

import axios from '../../../config/axios';
import { getUnixTime } from 'date-fns';
import { fetchAllReviewedApplications } from '../adminActions/dashboardActions/dashboardActions';

export const completeApplicationChecklist = (id, profile) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    try {
      dispatch(asyncActionStart());

      const scholarshipValues = {
        applyForAScholarship: 'Yes',
        receiveFreeOrReducedLunch: 'Yes',
        familySalaryLessThan: 'Yes',
        catastrophicEvent: 'Yes',
      };

      await dispatch(submitScholarshipForm(id, scholarshipValues));

      const taxFormQuery = firestore
        .collection('registeredPrograms')
        .doc(`${id}`)
        .collection('taxForms');
      await taxFormQuery.add({
        fileURL: 'https://s.hdnux.com/photos/74/21/35/15800191/7/rawImage.jpg',
        fileName: 'testTaxForm.jpg',
        originalFileName: 'testTaxForm.jpg',
      });
      await dispatch(submitTaxForm(id, true));

      const personalInformationValues = {
        address1: 'Test Addr 1',
        address2: 'Test Addr 2',
        afterGraduation: 'No',
        americanWithDisabilitiesAct: 'No',
        citizenshipStatus: 'US Permanent resident',
        city: 'Test City',
        countryOfOrigin: 'UK',
        dob: '2021-10-21T14:27:00.000Z',
        email: 'dave@davelx.co.uk',
        ethnicBackground: 'Caucasian',
        firstName: 'Auto',
        gender: 'Male',
        housePhoneNumber: '3236207662',
        lastName: 'Complete',
        middleInitial: null,
        mobileNumber: '3236207662',
        nativeFamilyLanguage: 'English',
        otherCountryOfCitizenship: 'UK',
        permanentResidentNumber: '666666',
        state: 'Alabama',
        zipCode: '37221',
      };

      await dispatch(
        submitPersonalInformationForm(
          id,
          profile.uid,
          personalInformationValues
        )
      );

      const familyValues = {
        namesOfParentsYouLiveWith: 'Parent Name',
        numberOfSiblings: '1',
        parentGuardianAddress1Number1: 'Parent Addr 1',
        parentGuardianAddress2Number1: 'Parent Addr 2',
        parentGuardianCityNumber1: 'Parent City',
        parentGuardianDoYouLiveWithParentGuardianNumber1: 'No',
        parentGuardianEducationLevelNumber1: 'High School Graduate',
        parentGuardianEmailNumber1: 'dave@codingdave.dev',
        parentGuardianEmployerNumber1: 'Parent Employer',
        parentGuardianHomeMobileNumberNumber1: '3236207662',
        parentGuardianNameNumber1: 'Parent 1',
        parentGuardianOccupationTitleNumber1: 'Parent Occupation',
        parentGuardianRelationshipToApplicantNumber1: 'Mother',
        parentGuardianStateNumber1: 'Alabama',
        parentGuardianTitleNumber1: 'Mr',
        parentGuardianZipCodeNumber1: '37221',
        parentsMaritalStatus: 'Married',
        siblingsAge: '20',
      };
      await dispatch(submitFamilyInformationForm(id, familyValues));

      const academicInformationValues = {
        emailAddressOfCounselorAdvisor: 'dave@davelx.co.uk',
        graduationMonthYear: '10/21',
        highSchoolCity: 'Academic City',
        highSchoolName: 'Academic High School',
        highSchoolState: 'Alabama',
        nameOfCounselorAdvisor: 'Adacemic Advisor',
      };
      await dispatch(
        submitAcademicInformationForm(id, academicInformationValues)
      );

      const extracurricularValues = {
        leadershipRole: 'Office 1',
        responsibility: 'Responsibility 1',
        additionalLeadershipRoles: [
          {
            leadershipRole: 'Office 2',
            responsibility: 'Responsibility 2',
          },
        ],
        activity: 'Activity 1',
        involvement: 'Involvement 1',
        additionalSchoolCommunityActivities: [
          {
            involvement: 'Involvement 2',
            activity: 'Activity 2',
          },
        ],
        familyMemberName: 'Family Name 1',
        howDidYouHearAbout: 'LEAD',
        standardizedScore: '10',
        standardizedTests: 'Yes',
      };
      await dispatch(
        submitExtracurricularActivitiesForm(id, extracurricularValues)
      );

      const resumeFormQuery = firestore
        .collection('registeredPrograms')
        .doc(`${id}`)
        .collection('resumeForm');
      await resumeFormQuery.add({
        fileURL:
          'https://binaries.templates.cdn.office.net/support/templates/en-us/lw00002110_quantized.png',
        fileName: 'testResumeForm.png',
        originalFileName: 'testResumeForm.png',
      });
      await dispatch(submitResumeForm(id, true));

      const transscriptFormQuery = firestore
        .collection('registeredPrograms')
        .doc(`${id}`)
        .collection('transcriptForm');
      await transscriptFormQuery.add({
        fileURL:
          'https://www.transcriptmaker.com/wp-content/uploads/2019/03/transcript5-1-724x1024.png',
        fileName: 'testTransscriptForm.png',
        originalFileName: 'testTransscriptForm.png',
      });
      await dispatch(submitTranscriptForm(id, true));

      // ADDITIONAL DOCUMENTS
      const additionalDocsFormQuery = firestore
        .collection('registeredPrograms')
        .doc(`${id}`)
        .collection('additionalDocumentsForm');
      await additionalDocsFormQuery.add({
        fileURL:
          'https://cdn.pixabay.com/photo/2013/07/13/11/36/documents-158461_1280.png',
        fileName: 'testDocumentsForm.png',
        originalFileName: 'testDocumentsForm.png',
      });
      await dispatch(submitAdditionalDocumentsForm(id, true));

      // SHORT RESPONSES
      const responsesValues = {
        articleLink: 'https://www.nytimes.com/section/us',
        definitionOfBusiness: 'Definiation of Business',
        deviseARecommendation: 'Recommend a solution',
        favouriteQuote: 'Favourite Quote',
        hopeToGain: 'I hope to Gain',
        howQuoteInspiresYou: 'This quote inspires me',
      };
      await dispatch(submitShortResponsesForm(id, responsesValues));

      // COUNSELOR RECOMMENDER
      const counselorValues = {
        recommenderTitle: 'Counselor Title',
        recommenderFirstName: 'Counselor First Name',
        recommenderLastName: 'Counselor Last Name',
        recommenderEmailAddress: 'davelx@icloud.com',
      };
      await dispatch(
        sendRecommenderInvite(profile, id, counselorValues, 'counselor')
      );

      // PERSONAL RECOMMENDER
      const personalValues = {
        recommenderTitle: 'Personal Title',
        recommenderFirstName: 'Personal First Name',
        recommenderLastName: 'Personal Last Name',
        recommenderEmailAddress: 'davelx@icloud.com',
      };
      await dispatch(
        sendRecommenderInvite(profile, id, personalValues, 'personal')
      );

      // ACADEMIC RECOMMENDER
      const academicValues = {
        recommenderTitle: 'Academic Title',
        recommenderFirstName: 'Academic First Name',
        recommenderLastName: 'Academic Last Name',
        recommenderEmailAddress: 'davelx@icloud.com',
      };
      await dispatch(
        sendRecommenderInvite(profile, id, academicValues, 'academic')
      );

      // // RECOMMENDER FORM
      await dispatch(submitRecommenderForm(id, true));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

// // NOTIFICATION DATE MET
export const notificationDateMet = (id, programId, programStage) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      let status = '';
      let templateCategory = '';

      if (programStage === 'approved') {
        status = 'Approved';
        templateCategory = 'applicationAcceptance';
      }

      if (programStage === 'waitlisted') {
        status = 'Waitlistd';
        templateCategory = 'applicationWaitlistNotification';
        // templateCategory = 'applicationAcceptance'
      }

      if (programStage === 'denied') {
        status = 'Waitlistd';
        templateCategory = 'applicationWaitlistNotification';
      }

      await axios.get('/notificationDateMet/sendNotification', {
        params: {
          id: programId,
          status: status,
          templateCategory: templateCategory,
        },
      });
      if (programStage === 'approved') {
        await registeredProgramQuery.update({
          programStage: 'approved',
          notificationDateMet: true,
        });
      }
      if (programStage === 'waitlisted') {
        await registeredProgramQuery.update({
          programStage: 'waitlisted',
          notificationDateMet: true,
        });
      }
      if (programStage === 'denied') {
        await registeredProgramQuery.update({
          programStage: 'denied',
          notificationDateMet: true,
        });
      }

      dispatch(fetchAllReviewedApplications());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

// NOTIFICATION DATE UNMET
export const notificationDateUnMet = (id, programId, programStage) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      // await axios.get(
      //     "/notificationDateMet/sendNotification",
      //     {
      //         params: {
      //             id: programId,
      //             programStage: programStage
      //         },
      //     }
      // );
      if (programStage === 'approved') {
        await registeredProgramQuery.update({
          programStage: 'approved',
          notificationDateMet: false,
        });
      }
      if (programStage === 'waitlisted') {
        await registeredProgramQuery.update({
          programStage: 'waitlisted',
          notificationDateMet: false,
        });
      }
      if (programStage === 'denied') {
        await registeredProgramQuery.update({
          programStage: 'denied',
          notificationDateMet: false,
        });
      }

      dispatch(fetchAllReviewedApplications());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

// APPROVED CHECKLIST COMPLETE
export const completeApprovedChecklist = (id, profile) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    try {
      dispatch(asyncActionStart());

      // GRANT ACCEPTANCE FORM
      const updateProgramQuery = firestore
        .collection('registeredPrograms')
        .doc(`${id}`);

      const registeredProgram = await updateProgramQuery.get();

      await updateProgramQuery.update({
        grantAcceptanceFormCompleted: true,
        logs: [
          ...registeredProgram.data()?.logs,
          {
            event: 'Acceptance Forms Completed',
            date: getUnixTime(new Date()),
          },
        ],
      });

      // SCHOLAR CONSENT FORM
      await updateProgramQuery.update({ scholarConsentFormCompleted: true });

      // GOVERNMENT ID
      const taxFormQuery = firestore
        .collection('registeredPrograms')
        .doc(`${id}`)
        .collection('governmentIdForm');
      await taxFormQuery.add({
        fileURL:
          'https://images.squarespace-cdn.com/content/v1/579a4997b3db2bf1700961ba/1559684640203-C06X7KYPD4LUUFMPXXH0/mn-adult-dl.jpg',
        fileName: 'governmentId.jpg',
        originalFileName: 'governmentId.jpg',
      });
      // await dispatch(submitGovernmentIdForm(id, true))

      // PAYMENT 1
      let editiedAt = getUnixTime(new Date());
      await updateProgramQuery.update({
        editiedAt: editiedAt,
        payment1Received: true,
      });
      // PAYMENT 2
      await updateProgramQuery.update({
        editiedAt: editiedAt,
        payment2Received: true,
      });

      // TRAVEL FORM
      const registeredProgramQuery = firestore
        .collection('registeredPrograms')
        .doc(`${id}`);

      const travelInformationFormQuery = firestore
        .collection('registeredPrograms')
        .doc(`${id}`)
        .collection('travelInformationForm');

      const travelValues = {
        address1: 'Test Addr 1',
        address2: 'Test Addr 2',
        afterGraduation: 'No',
        americanWithDisabilitiesAct: 'No',
        arrivalArrivalCity: 'ORD',
        arrivalArrivalDate: '10/21/2021',
        arrivalArrivingBy: 'Flight',
        arrivalDepartureCity: 'BNA',
        citizenshipStatus: 'US Permanent resident',
        city: 'Test City',
        comments: 'Comments',
        countryOfOrigin: 'UK',
        covidInjection1: '10/2021',
        covidInjection2: '10/2021',
        covidVaccine: 'Yes',
        departureArrivalCity: 'BNA',
        departureArrivalDate: '10/22/2021',
        departureArrivingBy: 'Flight',
        departureDepartureCity: 'ORD',
        dob: '2021-10-21T14:27:00.000Z',
        email: 'dave@davelx.co.uk',
        ethnicBackground: 'Caucasian',
        firstName: 'Auto',
        gender: 'Male',
        housePhoneNumber: '3236207662',
        id: 'uBiy1kEFIAfWxkZoG39F',
        lastName: 'Complete',
        middleInitial: null,
        mobileNumber: '3236207662',
        nativeFamilyLanguage: 'English',
        otherCountryOfCitizenship: 'UK',
        parentGuardianEmergencyContactName1: 'Travel Name 1',
        parentGuardianEmergencyContactName2: 'Travel Name 2',
        parentGuardianEmergencyContactNumber1: 'Travel Contact 1',
        parentGuardianEmergencyContactNumber2: 'Travel Contact 2',
        permanentResidentNumber: '666666',
        state: 'Alabama',
        zipCode: '37221',
      };

      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        travelInformationFormCompleted: true,
      });

      await travelInformationFormQuery.add({
        ...travelValues,
      });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
