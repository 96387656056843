import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Tooltip
} from "@mui/material";
import {
  format,
  fromUnixTime
} from "date-fns";
import RestoreIcon from '@mui/icons-material/Restore';
import Sidebar from "../../../ui/sidebar/Sidebar";
import {
  fetchAllArchivedApplications
} from "../../../store/actions/adminActions/archivedActions/archivedActions";
import { connect } from "react-redux";
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import RefreshIcon from '@mui/icons-material/Refresh';
import { escapeRegExp } from "../../../common/tableUtils";
import { makeStyles } from "@mui/styles";
import { restoreProgram } from "../../../store/actions/applicantActions/programActions/applicantProgramActions";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#171725",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight: "24px",
    letter: "0.1px",
  },
  status: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .succeeded": {
      background: "rgb(215, 247, 194)",
      color: "rgb(5, 105, 13)",
      borderRadius: 4,
      padding: "1px 6px",
    },
    "& .failed": {
      background: "rgb(255, 231, 242)",
      color: "rgb(179, 6, 61)",
      borderRadius: 4,
      padding: "1px 6px",
    },
    "& .canceled": {
      background: "rgb(235, 238, 241)",
      color: "rgb(84, 90, 105)",
      borderRadius: 4,
      padding: "1px 6px",
    },
  },
}));

const actions = {
  fetchAllArchivedApplications,
  restoreProgram
};

const mapStateToProps = (state) => {
  let allArchivedApplicants = [];

  if (
    state.adminArchived.allArchivedApplicants &&
    state.adminArchived.allArchivedApplicants.length > 0
  ) {
    allArchivedApplicants = state.adminArchived.allArchivedApplicants;
  }

  return {
    allArchivedApplicants
  };
};

const Archived = ({
  fetchAllArchivedApplications, 
  restoreProgram,
  allArchivedApplicants
}) => {
  const classes = useStyles();

  const [filteredRows, setFilteredRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [reload, setReload] = useState(false);

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      "e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    );
  }, []);

  useEffect(()=>{
    fetchAllArchivedApplications();
  },[fetchAllArchivedApplications, reload])

  useEffect(()=>{
    if (searchText) {
      setSearchText(searchText);
      const searchRegex = new RegExp(escapeRegExp(searchText), "i");
      const arr = allArchivedApplicants.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      const filtered = arr.map((applicant, index) => {
        return {
          ...applicant,
          serialNo: index + 1,
        };
      });
      setFilteredRows(filtered);
    }else{
      const filtered = allArchivedApplicants.map((applicant, index) => {
        return {
          ...applicant,
          serialNo: index + 1,
        };
      });
      setFilteredRows(filtered);
    }
  },[allArchivedApplicants])

  const memoizedColumns = useMemo(() => {
    return [
      { field: "serialNo", headerName: "Id", width: 60 },
      {
        field: "fullName",
        headerName: "Name",
        width: 160,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "programName",
        headerName: "Program",
        width: 220,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "programStage",
        headerName: "Program Stage",
        width: 120,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "createdAt",
        headerName: "Created At",
        width: 220,
        valueGetter: (param) => param.value ? format(fromUnixTime(param.value), "EEE MMM do y HH:mm:ss") : "N/A",
      },
      {
        field: "archiveComment",
        headerName: "Reason",
        width: 350,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "restore",
        headerName: "Restore",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
        renderCell: (param) => (
          <Tooltip title="Restore">
            <RestoreIcon
              onClick={(e) => {
                e.stopPropagation();
                restoreProgram(param?.row?.id).then(() => {
                  setReload(true);
                });
              }}
            />
          </Tooltip>
        ),
      },
    ];
  }, []);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filtered = allArchivedApplicants.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };

  return (
    <Grid container>
      <Grid item md={2}>
        <Sidebar />
      </Grid>
      <Grid
        item
        md={10}
        style={{
          backgroundColor: "#FAFAFB",
          paddingLeft: "41px",
          paddingRight: "41px",
        }}
      >
        <Grid item container direction={"column"}>
          <Grid
            item
            container
            style={{ marginTop: "20px" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className={classes.title}>Archived</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setReload((val) => (val = !val))}
            >
              Refresh
              <RefreshIcon style={{ float: "right", cursor: "pointer" }} />
            </Button>
          </Grid>
          <Grid item style={{ marginTop: "2em" }}>
            <Box height={684} width="100%">
              <DataGridPremium
                getRowId={(row) => row.id}
                columns={memoizedColumns}
                rows={filteredRows}
                disableSelectionOnClick
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50]}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    value: searchText,
                    clearSearch: () => requestSearch(""),
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(Archived);
