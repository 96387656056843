export const FETCH_ALL_PROGRAMS = 'FETCH_ALL_PROGRAMS';
export const FETCH_ADMIN_PROGRAM = 'FETCH_ADMIN_PROGRAM';
export const FETCH_REQUEST_INFORMATION = 'FETCH_REQUEST_INFORMATION';

export const CLEAR_ADMIN_PROGRAM = 'CLEAR_ADMIN_PROGRAM';

export const UPDATE_PROGRAM_DESCRIPTION = 'UPDATE_PROGRAM_DESCRIPTION';

export const FETCH_ADMIN_PROGRAM_DASHBOARD_VALUES =
  'FETCH_ADMIN_PROGRAM_DASHBOARD_VALUES';
export const FETCH_ALL_PROGRAM_COMPLETED_APPLICATIONS =
  'FETCH_ALL_PROGRAM_COMPLETED_APPLICATIONS';
export const FETCH_ALL_PROGRAM_REVIEWED_APPLICATIONS =
  'FETCH_ALL_PROGRAM_REVIEWED_APPLICATIONS';
export const FETCH_ADMIN_PROGRAM_DASHBOARD_VALUES_DIRECTORY =
  'FETCH_ADMIN_PROGRAM_DASHBOARD_VALUES_DIRECTORY';
export const FETCH_ALL_PROGRAM_COMPLETED_APPLICATIONS_DIRECTORY =
  'FETCH_ALL_PROGRAM_COMPLETED_APPLICATIONS_DIRECTORY';
export const FETCH_ALL_PROGRAM_REVIEWED_APPLICATIONS_DIRECTORY =
  'FETCH_ALL_PROGRAM_REVIEWED_APPLICATIONS_DIRECTORY';
