import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import ReviewProgramItem from "./item/ReviewProgramItem";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  answerText: {
    fontWeight: 600,
  },
}));

const PaymentFormReview = ({ paymentForm }) => {
  const classes = useStyles();
  // const theme = useTheme();
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      direction={"column"}
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
    >
      <Grid item>
        <Typography className={classes.title}>Payment Form</Typography>
      </Grid>

      <ReviewProgramItem
        question={"Deposit Received"}
        value={paymentForm?.payment1Received ? "Yes" : "No"}
      />
      <ReviewProgramItem
        question={"Deposit Amount"}
        value={"$"+ paymentForm?.payment1Amount }
      />
      <ReviewProgramItem
        question={"Final Payment Received"}
        value={paymentForm?.payment2Received ? "Yes" : "No"}
      />
      <ReviewProgramItem
        question={"Final Payment Amount"}
        value={"$" + paymentForm?.payment2Amount}
      />
       <ReviewProgramItem
        question={"Paid In Full"}
        value={ !! paymentForm?.paymentAmount? "Yes": "No"}
      />
      <ReviewProgramItem
        question={"Payment Total"}
        value={`$${
          paymentForm?.paymentAmount
            ? paymentForm?.paymentAmount
            : paymentForm?.payment1Amount + paymentForm?.payment2Amount
        }`}
      />
    </Grid>
  );
};

export default PaymentFormReview;
