import React, { useState, useEffect, useMemo, useRef } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  Tooltip,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import ArchiveIcon from "@mui/icons-material/Archive";
import axios from "../../../config/axios";
import DialogContentText from "@mui/material/DialogContentText";
//import Tooltip from '@mui/material/Tooltip';
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../../../store/actions/asyncActions/asyncActions";
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { format, fromUnixTime } from "date-fns";
import { escapeRegExp } from "../../../common/tableUtils";
import { Replay } from "@mui/icons-material";
import { archiveProgram } from "../../../store/actions/applicantActions/programActions/applicantProgramActions";
import {
  fetchDashboardValues,
  addFilterModel,
  clearFilterModels,
} from "../../../store/actions/adminActions/dashboardActions/dashboardActions";
import {
  handleDeletehandleProgramRollback,
  handleProgramRollback,
  updateProgramStage,
} from "../../../store/actions/reviewActions/reviewActions";
import {
  PermIdentity,
  FormatListBulleted,
  AssessmentOutlined,
  CloudDownloadOutlined,
} from "@mui/icons-material";
import TextArea from "../../../common/form/TextArea";
import { Field, reduxForm } from "redux-form";
import { combineValidators, isRequired } from "revalidate";
import { generateZipFromCloud } from "../../../common/util/generateZip";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
  },
  toolbarContainer: {
    justifyContent: "space-between",
  },
  flex3: {
    flex: 3,
  },
}));

/*const columnGroupingModel = [
  {
    groupId: "internal_info",
    headerName: "Internal Information",
    description: "",
    children: [
      { field: "firstName" },
      { field: "lastName" },
      { field: "fullName" },
      { field: "programStage" },
      { field: "progress" },
      { field: "progressPercentage" },
      { field: "affiliateName" },
      { field: "affiliateCode" },
      { field: "createdAt" },
      { field: "editiedAt" },
      { field: "submissionDate" },
      { field: "applicationAcceptedAt" },
      { field: "completedAt" },
      { field: "withdrawnAt" },
      { field: "assignedInstitute" },
    ],
  },
  {
    groupId: "personal_info",
    headerName: "Personal Information",
    description: "",
    children: [
      { field: "dob" },
      { field: "gender" },
      { field: "mobileNumber" },
      { field: "housePhoneNumber" },
      { field: "email" },
      { field: "address" },
      { field: "city" },
      { field: "state" },
      { field: "zipCode" },
      { field: "ethnicBackground" },
    ],
  },
  {
    groupId: "Covid_info",
    headerName: "Covid Protocol",
    description: "",
    children: [
      { field: "vaccinated" },
      { field: "covidDose1" },
      { field: "covidDose2" },
      { field: "covidBooster" },
    ],
  },
  {
    groupId: "area_of_interest",
    headerName: "Application Interests/Comments",
    description: "",
    children: [{ field: "areaOfInterest" }, { field: "applicantComments" }],
  },
  {
    groupId: "scholarship",
    headerName: "Scholarship Form",
    description: "",
    children: [
      { field: "freeLunch" },
      { field: "familySalaryBelow" },
      { field: "familySalaryGreater" },
    ],
  },
];*/

const validate = combineValidators({
  filterName: isRequired({ message: "Required" }),
});

const mapStateToProps = (state) => {
  let filters = {};
  let governmentIds = [];

  if (state.adminDashboard.filters) {
    filters = state.adminDashboard.filters;
  }

  return {
    filters,
    profile: state.firebase.profile,
  };
};

const DashboardApplicantsTable = ({
  program,
  applicants,
  fetchDashboardValues,
  handleProgramRollback,
  handleDeletehandleProgramRollback,

  handleSubmit,
  submitting,
  addFilterModel,
  clearFilterModels,
  filters,
  showFilterComponents,
  updateProgramStage,
  withdrawButton,
  checkboxSelection,
  selectionModel,
  setSelectionModel,
  profile,
  onSelectionModelChange,
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
  reloadApplicants,
  archiveProgram,
}) => {
  console.log(applicants, "applicantsTable");
  const [applicantsNew, setApplicantsNew] = useState([]);
  const fixFilteredRowsForFreeProgram = () => {
    let a = applicants?.map((item) => {
      if (item.applicationFeePaid && !item.applicationCompleted) {
        item["programStage"] = "in-progress";
        setApplicantsNew(applicants);
        // setApplicantsNew((el) => [...el, item]);
      } else {
        setApplicantsNew(applicants);

        // setApplicantsNew((el) => [...el,item]);
      }
    });
  };
  console.log(applicantsNew, "applicantsNew");
  useEffect(() => {
    if (applicants !== undefined) {
      fixFilteredRowsForFreeProgram();
    }
  }, [applicants]);

  const matches = useMediaQuery("(min-width:530px)");
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState(applicantsNew);
  const [filteredRows, setFilteredRows] = useState(applicantsNew);
  console.log(filteredRows, "filteredRows");
  const [filterModel, setFilterModel] = useState();
  const [sortModel, setSortModel] = useState();
  const [dialog, setDialog] = useState(false);
  const [archiveComment, setArchiveComment] = useState("");
  const [selectedRegisteredProgramId, setSelectedRegisteredProgramId] =
    useState("");
  const [open, setOpen] = useState(false);
  const [, setRender] = useState(false);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [openWithrawnDialog, setOpenWithrawnDialog] = useState(false);
  const [selectedApplicantId, setSelectedApplicantId] = useState("");
  const [declineComment, setDeclineComment] = useState("");
  const grid1 = useRef(null);
  const grid2 = useRef(null);

  const classes = useStyles();
  const history = useHistory();

  console.log(filteredRows, "filteredRows");

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      "e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    );
  }, []);

  const programsAllThings = [
    "scholarshipForm",
    "taxForm",
    "personalInformationForm",
    "familyInformationForm",
    "academicInformationForm",
    "recommendationsRequired",
    "extracurricularForm",
    "covidProtocolForm",
    "resumeForm",
    "transcriptForm",
    "shortResponsesForm",
    "additionalDocumentsForm",
    "confirmationForm",
  ];

  const programAllThingsValue = {
    scholarshipForm: "scholarshipFormCompleted",
    taxForm: "taxFormsUploaded",
    personalInformationForm: "personalInformationFormCompleted",
    familyInformationForm: "familyInformationFormCompleted",
    academicInformationForm: "academicInformationFormCompleted",
    recommendationsRequired: "recommendersFormCompleted",
    extracurricularForm: "extracurricularFormCompleted",
    covidProtocolForm: "covidProtocolFormCompleted",
    resumeForm: "resumeFormCompleted",
    transcriptForm: "transcriptFormCompleted",
    shortResponsesForm: "shortResponsesFormCompleted",
    additionalDocumentsForm: "additionalDocumentsFormCompleted",
    confirmationForm: "confirmationFormCompleted",
  };

  let dataToCheck = [];
  let a = programsAllThings?.map((item) => {
    if (program[item]) {
      let c = dataToCheck?.push(programAllThingsValue[item]);
    }
  });

  useEffect(() => {
    const arr = applicantsNew.map((applicant, index) => {
      const checklist1 = [
        //  applicant.applicationFeePaid,
        applicant.scholarshipFormCompleted,
        applicant.taxFormsUploaded,
        applicant.personalInformationFormCompleted,
        applicant.familyInformationFormCompleted,
        applicant.academicInformationFormCompleted,
        applicant.extracurricularFormCompleted,
        applicant.resumeFormCompleted,
        applicant.transcriptFormCompleted,
        applicant.additionalDocumentsFormCompleted,
        applicant.shortResponsesFormCompleted,
        applicant.confirmationFormCompleted,
        applicant.covidProtocolFormCompleted,
      ];
      if (
        applicant.programDetails.recommendationsRequired &&
        applicant.programDetails.recommendationsRequired > 0
      ) {
        checklist1.push(applicant.recommendersFormCompleted);
      }
      const checklist2 = [
        applicant.governmentIdFormUploaded,
        applicant.payment1Received && applicant.payment2Received,
        applicant.requiredDocumentationFormUploaded,
        applicant.requiredDocumentationFormUploaded,
        applicant.studentQuestionnaireFormCompleted,
      ];

      if (applicant.programDetails.travelFormRequired) {
        checklist2.unshift(applicant.scholarConsentFormCompleted);
        if (
          !(
            applicant.institutionDetails && applicant.institutionDetails.virtual
          )
        ) {
          checklist2.push(applicant.travelInformationFormCompleted);
        }
      } else {
        checklist2.unshift(applicant.grantAcceptanceFormCompleted);
      }

      return {
        ...applicant,
        uid: applicant.id,
        id: index + 1,
        serialNo: index + 1,
        grade: applicant.userInformation?.gradeLevel,
        gender:
          applicant.userInformation?.gender ||
          applicant.personalInformationFormDetails?.gender,
        dob:
          (applicant.userInformation?.dob &&
            format(new Date(applicant.userInformation?.dob), "MM/dd/yyyy")) ||
          (applicant.personalInformationFormDetails?.dob &&
            format(
              new Date(applicant.personalInformationFormDetails?.dob),
              "MM/dd/yyyy"
            )),

        mobileNumber:
          applicant.userInformation?.mobileNumber ||
          applicant.personalInformationFormDetails?.mobileNumber,
        ethnicBackground:
          applicant.userInformation?.ethnicBackground ||
          applicant.personalInformationFormDetails?.ethnicBackground,
        housePhoneNumber:
          applicant.personalInformationFormDetails?.housePhoneNumber,
        email:
          applicant.userInformation?.email ||
          applicant.personalInformationFormDetails?.email,
        city:
          applicant.userInformation?.city ||
          applicant.personalInformationFormDetails?.city,
        state:
          applicant?.userInformation?.state ||
          applicant.personalInformationFormDetails?.state,
        address:
          (applicant.userInformation?.address1 || "") +
            (applicant.userInformation?.address2 || "") ||
          (applicant.personalInformationFormDetails?.address1 || "") +
            (applicant.personalInformationFormDetails?.address2 || ""),
        highSchoolName:
          applicant.academicInformationFormDetails?.highSchoolName,
        highSchoolCity:
          applicant.academicInformationFormDetails?.highSchoolCity,
        highSchoolState:
          applicant.academicInformationFormDetails?.highSchoolState,
        gpa: applicant.academicInformationFormDetails?.cumulativeGPA,
        takenSAT: applicant.academicInformationFormDetails?.standardizedTests,
        satScore: applicant.academicInformationFormDetails?.standardizedScore
          ? applicant.academicInformationFormDetails?.standardizedScore
          : applicant.academicInformationFormDetails?.whenWillYouTakeSAT,
        counselorName:
          applicant.academicInformationFormDetails?.nameOfCounselorAdvisor,
        counselorEmail:
          applicant.academicInformationFormDetails
            ?.emailAddressOfCounselorAdvisor,
        applicantComments: applicant.applicationComments,
        createdAt: format(fromUnixTime(applicant.createdAt), "MM/dd/yyyy"),
        editiedAt: format(fromUnixTime(applicant.editiedAt), "MM/dd/yyyy"),
        reviewStatus: applicant.reviewStatus,
        reviewerUpdated: applicant.reviewerUpdated
          ? format(fromUnixTime(applicant.reviewerUpdated), "MM/dd/yyyy")
          : "",

        progress: checklist1.every(Boolean)
          ? `${checklist2.filter((item) => item === true).length}/${
              dataToCheck?.length + 1
            }`
          : `${checklist1.filter((item) => item === true).length}/${
              dataToCheck?.length
            }`,

        progressPercentage: checklist1.every(Boolean)
          ? `${Math.ceil(
              (checklist2.filter((item) => item).length / checklist2.length) *
                100
            )}%`
          : `${Math.ceil(
              (checklist1.filter((item) => item).length / checklist1.length) *
                100
            )}%`,

        ...applicant.familyInformationFormDetails,
        parent1Address:
          (applicant.familyInformationFormDetails
            ?.parentGuardianAddress1Number1 || "") +
          (applicant.familyInformationFormDetails
            ?.parentGuardianAddress2Number1 || ""),
        parent2Address:
          (applicant.familyInformationFormDetails
            ?.parentGuardianAddress1Number2 || "") +
          (applicant.familyInformationFormDetails
            ?.parentGuardianAddress2Number2 || ""),

        parentCity:
          applicant.familyInformationFormDetails?.parentGuardianCityNumber1 ||
          "",
        parentState:
          applicant.familyInformationFormDetails?.parentGuardianStateNumber1 ||
          "",

        reviewerName: applicant.reviewerDetails?.fullName,
        assignedInstitute: applicant.institutionDetails?.institutionName,
        payment1Amount: applicant.payment1Details?.paymentFee,
        paidInFull: !!applicant?.paymentAmount ? "Yes" : "No",
        initialBalance:
          applicant.programDetails?.programCost - applicant.scholarshipAmount,
        balancePaid: applicant?.paymentAmount
          ? applicant?.paymentAmount
          : applicant?.payment1Amount + applicant?.payment2Amount,
        depositReceived: applicant.payment1Received ? `Paid` : "Un-paid",
        depositAmount: applicant.payment1Amount || "0",
        finalPaymentReceived: applicant.payment2Received ? `Paid` : "Un-paid",
        finalPaymentAmount: applicant.payment2Amount || "0",
        sponsorshipName: applicant.sponsorshipName,
        taxFormsUploaded: applicant.taxFormsUploaded ? "Yes" : "No",
        scholarConsentFormDetailsCount:
          applicant?.scholarConsentFormDetails &&
          Object.keys(applicant.scholarConsentFormDetails).length,
        governmentIdsCount:
          applicant?.governmentIds &&
          Object.keys(applicant.governmentIds).length
            ? applicant.governmentIds?.governmentId?.length +
              applicant.governmentIds?.headshot?.length
            : 0,
        requiredDocumentsCount:
          applicant?.requiredDocuments &&
          Object.keys(applicant.requiredDocuments).length,
        // taxFiles: (applicants?.taxForms.exist() ?  "Yes": "No"),
        //? Object.keys(applicants?.taxForms).length
        //: [],*/
        payment2Amount: applicant.payment2Details?.paymentFee,
        payment2Received:
          applicant.payment2Details?.paymentDate &&
          format(
            fromUnixTime(applicant.payment2Details.paymentDate),
            "MM/dd/yyyy"
          ),
        // counselorRecommendationSent: applicant.counselorRecommenderFormSent
        //   ? applicant.counselorRecommenderDetails?.recommenderEmailAddress
        //   : 'No',
        counselorRecommendationSent: applicant?.counselorsRecommenderFormSent
          ? true
          : false,
        // personalRecommendationSent: applicant.personalRecommenderFormSent
        //   ? applicant.personalRecommenderDetails?.recommenderEmailAddress
        //   : 'No',
        personalRecommendationSent: applicant?.personalsRecommenderFormSent
          ? true
          : false,
        // academicRecommendationSent: applicant.academicRecommenderFormSent
        //   ? applicant.academicRecommenderDetails?.recommenderEmailAddress
        //   : 'No',

        academicRecommendationSent: applicant?.academicsRecommenderFormSent
          ? true
          : false,
        applyScholarship:
          applicant.scholarshipFormDetails?.applyForAScholarship,
        qualifyForScholarship: applicant.scholarshipFormDetails
          ?.qualifyForScholarship
          ? "Yes"
          : "No",
        freeLunch: applicant.scholarshipFormDetails?.receiveFreeOrReducedLunch,
        familySalaryBelow:
          applicant.scholarshipFormDetails?.familySalaryLessThan,
        familySalaryGreater:
          applicant.scholarshipFormDetails?.familySalaryGreaterThan,
        catastrophicEvent: applicant.scholarshipFormDetails?.catastrophicEvent,

        counselorRecommendationReceived:
          applicant.counselorRecommenderFormCompleted ? "Yes" : "No",
        personalRecommendationReceived:
          applicant.personalRecommenderFormCompleted ? "Yes" : "No",
        academicRecommendationReceived:
          applicant.academicRecommenderFormCompleted ? "Yes" : "No",
        recommendationFormsComplete: applicant.recommendersFormCompleted
          ? "Yes"
          : "No" || "None",

        areaOfInterestCompleted: applicant.applicationCommentsSet
          ? "Yes"
          : "No" || "-",
        scholarshipFormCompleted: applicant.scholarshipFormCompleted
          ? "Yes"
          : "No" || "-",
        taxFormCompleted: applicant.taxFormsUploaded ? "Yes" : "No" || "-",
        personalInformationCompleted: applicant.personalInformationFormCompleted
          ? "Yes"
          : "No" || "-",
        familyInformationCompleted: applicant.familyInformationFormCompleted
          ? "Yes"
          : "No" || "-",
        academicInformationCompleted: applicant.academicInformationFormCompleted
          ? "Yes"
          : "No" || "-",
        recommendationFormsComplete: applicant.recommendersFormCompleted
          ? "Yes"
          : "No" || "-",
        extracurricularCompleted: applicant.extracurricularFormCompleted
          ? "Yes"
          : "No" || "-",
        covidCompleted: applicant.covidProtocolFormDetails?.covidVaccine
          ? "Yes"
          : "No" || "-",
        shortResponseCompleted: applicant.shortResponsesFormCompleted
          ? "Yes"
          : "No" || "-",
        resumeCompleted: applicant.resumeFormCompleted ? "Yes" : "No" || "-",
        transcriptCompleted: applicant.transcriptFormCompleted
          ? "Yes"
          : "No" || "-",
        additionalDocCompleted: applicant.additionalDocumentsFormCompleted
          ? "Yes"
          : "No" || "-",
        confirmationCompleted: applicant.confirmationFormCompleted
          ? "Yes"
          : "No" || "-",
        hdt: applicant.extracurricularFormDetails?.howDidYouHearAbout,
        pleaseSpecify: applicant.extracurricularFormDetails?.pleaseSpecify,
        arrivalDate: applicant.travelInformation?.arrivalArrivalDate,
        departureDate: applicant.travelInformation?.departureArrivalDate,
        arrivalArriveBy: applicant.travelInformation?.arrivalArrivingBy,
        departureArriveBy: applicant.travelInformation?.departureArrivingBy,
        institutionCity: applicant.travelInformation?.arrivalArrivalCity,
        applicantDepartureCity:
          applicant.travelInformation?.arrivalDepartureCity,
        applicantReturnCity: applicant.travelInformation?.departureArrivalCity,
        travelComment: applicant.travelInformation?.comments,
        emergencyContact1Name:
          applicant.travelInformation?.parentGuardianEmergencyContactName1,
        emergencyContact2Name:
          applicant.travelInformation?.parentGuardianEmergencyContactName2,
        emergencyContact1Number:
          applicant.travelInformation?.parentGuardianEmergencyContactNumber1,
        emergencyContact2Number:
          applicant.travelInformation?.parentGuardianEmergencyContactNumber2,
        frequentFlyer1Airline: applicant.travelInformation?.frequentAirline1,
        frequentFlyer1Number:
          applicant.travelInformation?.frequentAirlineNumber1,
        frequentFlyer2Airline: applicant.travelInformation?.frequentAirline2,
        frequentFlyer2Number:
          applicant.travelInformation?.frequentAirlineNumber2,
        vaccinated: applicant.covidProtocolFormDetails?.covidVaccine
          ? "Yes"
          : "No",
        covidDose1: applicant.covidProtocolFormDetails?.covidInjection1,
        covidDose2: applicant.covidProtocolFormDetails?.covidInjection2,
        covidBooster: applicant.covidProtocolFormDetails?.boosterShot,
        programName: applicant.programDetails?.programName,
        submissionDate:
          applicant.confirmationFormDetails?.confirmationDate &&
          format(
            new Date(applicant.confirmationFormDetails?.confirmationDate),
            "MM/dd/yyyy"
          ),
        reviewerAssignedAt:
          applicant.reviewerAssignedAt &&
          format(fromUnixTime(applicant.reviewerAssignedAt), "MM/dd/yyyy"),
        reviewerSubmittedAt:
          applicant.reviewerResponse?.reviewedOn &&
          format(
            new Date(applicant.reviewerResponse?.reviewedOn),
            "MM/dd/yyyy"
          ),
        applicationAcceptedAt:
          applicant.applicationAcceptedAt &&
          format(fromUnixTime(applicant.applicationAcceptedAt), "MM/dd/yyyy"),
        withdrawnAt:
          applicant.withdrawnAt &&
          format(fromUnixTime(applicant.withdrawnAt), "MM/dd/yyyy"),
        completedAt:
          applicant.completedAt &&
          format(fromUnixTime(applicant.completedAt), "MM/dd/yyyy"),
        disability: applicant.studentQuestionnaireFormDetails?.disability
          ? Object.keys(applicant.studentQuestionnaireFormDetails.disability)
          : [],
        workPreference: applicant.studentQuestionnaireFormDetails
          ?.workPreference
          ? Object.keys(
              applicant.studentQuestionnaireFormDetails.workPreference
            )
          : [],
        hobbies: applicant.studentQuestionnaireFormDetails?.doInSpareTime
          ? Object.keys(applicant.studentQuestionnaireFormDetails.doInSpareTime)
          : [],
        zipCode:
          applicant.userInformation?.zipCode ||
          applicant.personalInformationFormDetails?.zipCode ||
          applicant.studentQuestionnaireFormDetails?.zipCode ||
          "",
        parentsZipCode1:
          applicant.familyInformationFormDetails
            ?.parentGuardianZipCodeNumber1 || "",
        parentsZipCode2:
          applicant.familyInformationFormDetails
            ?.parentGuardianZipCodeNumber2 || "",
        additionalCommentsOrAccommodations:
          applicant.studentQuestionnaireFormDetails
            ?.additionalCommentsOrAccommodations || "",
        allergiesOrRestrictions:
          applicant.studentQuestionnaireFormDetails?.allergiesOrRestrictions ||
          "",
        careerGoal: applicant.studentQuestionnaireFormDetails?.careerGoal || "",
        collegeMajorGoal:
          applicant.studentQuestionnaireFormDetails?.collegeMajorGoal || "",
        collegesApplyingTo:
          applicant.studentQuestionnaireFormDetails?.collegesApplyingTo || "",
        advanceMathematicsCourseTaken:
          applicant.studentQuestionnaireFormDetails
            ?.advanceMathematicsCourseTaken || "",
        economicsCourse:
          applicant.studentQuestionnaireFormDetails?.economicsCourse || "",
        experienceInComputers:
          applicant.studentQuestionnaireFormDetails?.experienceInComputers ||
          "",
        involvementInAffinityStudentOrganisations:
          applicant.studentQuestionnaireFormDetails
            ?.involvementInAffinityStudentOrganisations || "",
        musicILike: applicant.studentQuestionnaireFormDetails?.musicILike || "",
        talents: applicant.studentQuestionnaireFormDetails?.talents || "",
        sports: applicant.studentQuestionnaireFormDetails?.sports || "",
        phobia: applicant.studentQuestionnaireFormDetails?.phobia || "",
        favouriteFood:
          applicant.studentQuestionnaireFormDetails?.favouriteFood || "",
        roommateConsideration:
          applicant.studentQuestionnaireFormDetails?.roommateConsideration ||
          "",
        roleModels: applicant.studentQuestionnaireFormDetails?.roleModels || "",
        countriesBeenTo:
          applicant.studentQuestionnaireFormDetails?.countriesBeenTo || "",
        workPreference:
          applicant.studentQuestionnaireFormDetails?.workPreference || "",
        greatestStrength:
          applicant.studentQuestionnaireFormDetails?.greatestStrength || "",
        greatestWeakness:
          applicant.studentQuestionnaireFormDetails?.greatestWeakness || "",
        ownBuisness:
          applicant.studentQuestionnaireFormDetails?.ownBuisness || "",
        interestForLead:
          applicant.studentQuestionnaireFormDetails?.interestForLead || "",
        optIn: applicant.sendSmsConsent ? "Yes" : "No",
        poloSize:
          applicant.userInformation?.poloSize ||
          applicant.personalInformationFormDetails?.poloSize ||
          applicant.studentQuestionnaireFormDetails?.poloSize,
      };
    });
    console.log(arr, "arrss");
    setRows(arr);
    if (searchText) {
      const searchRegex = new RegExp(escapeRegExp(searchText), "i");
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
    }
  }, [applicantsNew]);

  useEffect(() => {
    const search = history.location.search;
    if (search.includes("?institute")) {
      setRender((prevRender) => !prevRender);
    }
  }, [history, filteredRows]);

  useEffect(() => {
    if (filter) {
      setFilterModel(filters[filter]);
    } else {
      setFilterModel(null);
    }
  }, [filter, filters]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filtered = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };

  const getPaymentLink = async (
    price,
    paymentName,
    applicantUid,
    applicantId,
    fullName,
    programId,
    type,
    processingFeeAmount
  ) => {
    const response = await axios.post("stripeFunctions/payments/create", {
      items: [
        {
          price: price,
          name: paymentName,
          description: `A $${price} payment amount is required. 
                    Payments made by credit card will
                    incur an additional $ ${
                      processingFeeAmount || 2.9
                    } processing fee.`,
        },
      ],
      registeredProgramId: applicantUid,
      type: type,
      applicantId: applicantId,
      applicantName: fullName,
      programId: programId,
    });
    console.log(response, "response");
    return response;
  };

  const handleUpdateProgramStage = async (
    id,
    programStage,
    applicationValue,
    declineComment
  ) => {
    await updateProgramStage(
      id,
      programStage,
      applicationValue,
      declineComment
    );
    // fetchAllReviewedApplications();
    // fetchDashboardValues();
    reloadApplicants();
  };

  const memoizedColumns = useMemo(() => {
    const handleRollback = async (id) => {
      console.log(id, "ids");
      // await handleDeletehandleProgramRollback(id);
      //  await handleProgramRollback(id).then((res) => {
      //    history.push(`/admin/dashboard/applicant/${id}`);
      //  });
      await handleProgramRollback(id);
      await fetchDashboardValues();
    };

    return [
      { field: "serialNo", headerName: "Id", width: 80 },
      {
        field: "actions",
        headerName: "Links",
        width: 220,
        renderCell: (params) => (
          <>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                history.push(
                  `/admin/applicant/view?id=${params.row.applicantId}`
                );
              }}
              color="primary"
            >
              <PermIdentity />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                history.push(
                  `/admin/dashboard/applicant/${params.row.applicantUid}`
                );
              }}
              color="primary"
            >
              <FormatListBulleted />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                console.log("params", params);
                history.push(
                  `/admin/reviewProgram?id=${params?.row?.applicantUid}`
                );
              }}
              color="primary"
              disabled={params.row.programStage === "pre-application"}
            >
              <AssessmentOutlined />
            </IconButton>
            <Tooltip title="Download all required documents">
              <IconButton
                color="primary"
                onClick={() => {
                  const urls = [];
                  Object.keys(params.row.requiredDocuments).forEach((doc) => {
                    if (Array.isArray(params.row.requiredDocuments[doc])) {
                      params.row.requiredDocuments[doc].forEach((item) =>
                        urls.push(item.fileURL)
                      );
                    } else {
                      urls.push(params.row.requiredDocuments[doc].fileURL);
                    }
                  });
                  generateZipFromCloud(urls, "Required Documents");
                }}
                disabled={
                  !params?.row?.requiredDocumentationFormUploaded ||
                  (params?.row?.requiredDocuments &&
                    Object.keys(params.row.requiredDocuments).length === 0)
                }
              >
                <CloudDownloadOutlined />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
      {
        field: "archiveStage",
        headerName: "Archive",
        width: 80,
        valueGetter: (param) => param.value || "-",
        hide: !profile.isAdmin,
        renderCell: (param) => (
          <Tooltip title="Archive">
            <ArchiveIcon
              sx={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedRegisteredProgramId(param?.row?.applicantUid);
                setOpen(true);
              }}
            />
          </Tooltip>
        ),
      },
      {
        field: "links",
        headerName: "Payment Links",
        width: 220,
        renderCell: (params) => {
          if (!params.value)
            return (
              <Button
                onClick={async (e) => {
                  e.stopPropagation();
                  console.log(filteredRows, "manish");

                  try {
                    const {
                      payment1Amount,
                      payment1Received,
                      payment2Received,
                      applicantUid,
                      applicantId,
                      fullName,
                      programId,
                      programDetails,
                      scholarshipAmount,
                    } = params?.row;

                    const processingFeeAmount = (
                      ((Number(programDetails?.programCost) -
                        Number(scholarshipAmount || 0)) *
                        2.9) /
                      100
                    ).toFixed(2);

                    let depositPaymentResponse = {};
                    let fullPaymentResponse = {};
                    let finalPaymentResponse = {};

                    let noDueLeft = false;

                    asyncActionStart();
                    if (!payment1Received && !payment2Received) {
                      depositPaymentResponse = await getPaymentLink(
                        (
                          (Number(programDetails?.programCost) -
                            Number(scholarshipAmount || 0)) /
                          2
                        ).toFixed(2),
                        "Deposit",
                        applicantUid,
                        applicantId,
                        fullName,
                        programId,
                        "payment1",
                        processingFeeAmount
                      );
                      fullPaymentResponse = await getPaymentLink(
                        (
                          Number(programDetails?.programCost) -
                          Number(scholarshipAmount || 0)
                        ).toFixed(2),
                        "Pay Full Balance",
                        applicantUid,
                        applicantId,
                        fullName,
                        programId,
                        "paymentBalance",
                        processingFeeAmount
                      );
                    } else if (!payment1Received || !payment2Received) {
                      finalPaymentResponse = await getPaymentLink(
                        (
                          Number(programDetails?.programCost) -
                          Number(scholarshipAmount || 0) -
                          Number(payment1Amount || 0) +
                          Number(processingFeeAmount / 2)
                        ).toFixed(2),
                        "Final Payment",
                        applicantUid,
                        applicantId,
                        fullName,
                        programId,
                        "payment2",
                        processingFeeAmount / 2
                      );
                      console.log(finalPaymentResponse, "finalPaymentResponse");
                    } else {
                      noDueLeft = true;
                    }
                    filteredRows.forEach((item) => {
                      console.log(item, "item");
                      if (item.applicantUid === params.row.applicantUid) {
                        item.links = {
                          depositPaymentUrl:
                            depositPaymentResponse?.data?.url || "",
                          fullPaymentUrl: fullPaymentResponse?.data?.url || "",
                          finalPaymentUrl:
                            finalPaymentResponse?.data?.url || "",
                          noDueLeft: noDueLeft,
                        };
                      }
                    });

                     setFilteredRows(filteredRows);
                    asyncActionFinish();
                  } catch (error) {
                    asyncActionError();
                    console.log(error);
                  }
                }}
                color="primary"
                disabled={
                  params?.row?.payment1Received && params?.row?.payment2Received
                }
              >
                Get Payment Links
              </Button>
            );
          return !params?.value?.noDueLeft ? (
            <>
              {params?.value?.depositPaymentUrl && (
                <a
                  href={params?.value?.depositPaymentUrl}
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                  style={{ cursor: "pointer" }}
                >
                  Deposit
                </a>
              )}
              {params?.value?.fullPaymentUrl && (
                <a
                  href={params?.value?.fullPaymentUrl}
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                  style={{ marginLeft: "1rem", cursor: "pointer" }}
                >
                  Pay Full Balance
                </a>
              )}
              {params?.value?.finalPaymentUrl && (
                <a
                  href={params?.value?.finalPaymentUrl}
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                  style={{ marginLeft: "1rem", cursor: "pointer" }}
                >
                  Final Payment
                </a>
              )}
            </>
          ) : (
            <span
              style={{
                color: "rgb(5 105 13)",
                backgroundColor: "rgb(215, 247, 194)",
              }}
            >
              No dues left
            </span>
          );
        },
      },
      {
        field: "fullName",
        headerName: "Name",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "firstName",
        headerName: "First Name",
        width: 140,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "lastName",
        headerName: "Last Name",
        width: 140,
        valueGetter: (param) => param.value || "-",
      },

      /*  {
        field: "programName",
        headerName: "Program Name",
        width: 160,
        valueGetter: (param) => param.value || "-",
      },*/

      {
        field: "programStage",
        headerName: "Program Stage",
        width: 140,
        align: "center",
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //approved = orange, admitted = blue, complete = green, reviewed = pink, withdrawn = red, denied = purple, default = gray in-progress = lavender
              borderColor:
                param.value === "approved"
                  ? "#E38E21"
                  : param.value === "admitted"
                  ? "#00799B"
                  : param.value === "complete"
                  ? "#4C9B4F"
                  : param.value === "reviewed"
                  ? "#DC4C88"
                  : param.value === "withdrawn"
                  ? "#FC5A5A"
                  : param.value === "in-progress"
                  ? "#c195bd"
                  : param.value === "denied"
                  ? "#7D609C"
                  : param.value === "under-review"
                  ? "#acac13"
                  : "#5B5B5B",

              color:
                param.value === "approved"
                  ? "#E38E21"
                  : param.value === "admitted"
                  ? "#00799B"
                  : param.value === "complete"
                  ? "#4C9B4F"
                  : param.value === "reviewed"
                  ? "#DC4C88"
                  : param.value === "withdrawn"
                  ? "#FC5A5A"
                  : param.value === "in-progress"
                  ? "#c195bd"
                  : param.value === "denied"
                  ? "#7D609C"
                  : param.value === "under-review"
                  ? "#acac13"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {/* {console.log(param?.row?.programDetails?.applicationFee, 656554654)}
            {console.log(param?.row?.programStage, 4654554)} */}
            {param.value}
          </div>
        ),
      },

      {
        field: "program",
        headerName: "Update Stage",
        width: 140,
        valueGetter: (param) => param.value || "-",
        hide: !profile.isAdmin,
        renderCell: (param) => (
          <Box display="flex" justifyContent="center" width="100%">
            <FormControl fullWidth size="small">
              {/* <InputLabel>Application Stage</InputLabel>*/}
              <Select
                value={param.row.programStage}
                onClick={(e) => e.stopPropagation()}
                onChange={(event) => {
                  setSelectedApplicantId(param.row.applicantUid);
                  if (
                    event.target.value === "approved" ||
                    event.target.value === "complete"
                  ) {
                    handleUpdateProgramStage(
                      param.row.applicantUid,
                      event.target.value,
                      true
                    );
                  } else {
                    if (event.target.value === "withdrawn") {
                      setOpenWithrawnDialog(true);
                    } else {
                      handleUpdateProgramStage(
                        param.row.applicantUid,
                        event.target.value,
                        false
                      );
                    }
                  }
                }}
                disabled={
                  param.row.programStage === "in-progress" ||
                  param.row.programStage === "under-review" ||
                  param.row.programStage === "pre-application"
                }
              >
                {param.row.programStage !== "withdrawn" && (
                  <MenuItem
                    disabled={
                      param.row.programStage === "admitted" ||
                      param.row.programStage === "complete"
                    }
                    value="approved"
                  >
                    approved
                  </MenuItem>
                )}
                {param.row.programStage !== "withdrawn" && (
                  <MenuItem
                    disabled={
                      param.row.programStage === "admitted" ||
                      param.row.programStage === "complete"
                    }
                    value="denied"
                  >
                    denied
                  </MenuItem>
                )}
                {param.row.programStage !== "withdrawn" && (
                  <MenuItem
                    disabled={
                      param.row.programStage === "admitted" ||
                      param.row.programStage === "complete"
                    }
                    value="waitlisted"
                  >
                    waitlisted
                  </MenuItem>
                )}
                {param.row.programStage !== "withdrawn" && (
                  <MenuItem value="withdrawn">withdrawn</MenuItem>
                )}
                {param.row.programStage === "withdrawn" && (
                  <MenuItem
                    disabled={param.row.previousStage ? false : true}
                    value="restore"
                  >
                    restore
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
        ),
      },
      {
        field: "",
        headerName: "Roll Back",
        width: 180,
        hide: !profile.isAdmin,
        renderCell: (params) => (
          <>
            <Tooltip title="Rollback: Application must be Under Review and applicant not notified or reviewed">
              {/*ROLLBACK*/}
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRollback(params.row.applicantUid);
                }}
                disabled={
                  params.row.notificationDateMet ||
                  params.row.programStage !== "under-review" ||
                  params.row.reviewStatus === "reviewed"
                }
              >
                <Replay />
              </IconButton>
            </Tooltip>
            {withdrawButton && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleUpdateProgramStage(params.row.uid, "withdrawn", false);
                }}
                disabled={params.row.programStage === "withdrawn"}
              >
                Withdraw
              </Button>
            )}
          </>
        ),
      },
      {
        field: "progress",
        headerName: "Progress",
        width: 120,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "progressPercentage",
        headerName: "Progress %",
        width: 130,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "sponsorshipName",
        headerName: "Sponsorship Name",
        width: 160,
        valueGetter: (param) => param.value || "None",
      },
      {
        field: "sponsorshipAmount",
        headerName: "Sponsorship Amount",
        width: 140,
        renderCell: (param) =>
          param.value ? <span>${param.value}</span> : "-",
      },
      {
        field: "affiliateName",
        headerName: "Affiliate Name",
        width: 160,
        valueGetter: (param) => param.value || "None",
      },
      {
        field: "promoCode",
        headerName: "Affiliate Code",
        width: 140,
        valueGetter: (param) => param.value || "None",
      },
      {
        field: "createdAt",
        headerName: "Start Date",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "editiedAt",
        headerName: "Last Updated",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "submissionDate",
        headerName: "Submission Date",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "applicationAcceptedAt",
        headerName: "Acceptance Date",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "completedAt",
        headerName: "Acceptance Completed Date",
        width: 240,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "withdrawnAt",
        headerName: "Withdrawn Date",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "grade",
        headerName: "Grade Level",
        width: 120,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "dob",
        headerName: "Birth Date",
        width: 120,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "gender",
        headerName: "Gender",
        width: 100,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "optIn",
        headerName: "SMS Opt In",
        width: 100,
        valueGetter: (param) => param.value,
      },
      {
        field: "mobileNumber",
        headerName: "Mobile No",
        width: 120,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "housePhoneNumber",
        headerName: "House No",
        width: 120,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "email",
        headerName: "Email",
        width: 220,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "address",
        headerName: "Address",
        width: 140,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "city",
        headerName: "City",
        width: 100,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "state",
        headerName: "State",
        width: 100,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "zipCode",
        headerName: "Zip Code",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "ethnicBackground",
        headerName: "Nationality",
        width: 140,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "poloSize",
        headerName: "Polo Size",
        width: 140,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "parentCity",
        headerName: "Parent City",
        width: 140,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "parentState",
        headerName: "Parent State",
        width: 140,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "assignedInstitute",
        headerName: "Placement",
        width: 180,
        valueGetter: (param) => param.value || "Unassigned",
      },
      {
        field: "vaccinated",
        headerName: "Vaccinated",
        width: 220,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "covidDose1",
        headerName: "Covid Dose 1",
        width: 220,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "covidDose2",
        headerName: "Covid Dose 2",
        width: 220,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "covidBooster",
        headerName: "Covid Booster",
        width: 220,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "areaOfInterest",
        headerName: "Interest",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "applicantComments",
        headerName: "Applicant Comments",
        width: 200,
        valueGetter: (param) => param.value || "-",
        renderCell: (params) => (
          <Tooltip title={<Typography>{params.value}</Typography>} arrow>
            <Typography>{params.value}</Typography>
          </Tooltip>
        ),
      },
      {
        field: "freeLunch",
        headerName: "Free/Reduced Lunch",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "familySalaryBelow",
        headerName: "Family Salary Below",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "familySalaryGreater",
        headerName: "Family Salary Greater",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "catastrophicEvent",
        headerName: "Catastrophic Event",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "applyScholarship",
        headerName: "Applied for Scholarship",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "qualifyForScholarship",
        headerName: "Qualify for Scholarship",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "scholarshipAmount",
        headerName: "Scholarship Awarded",
        width: 200,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "taxFormsUploaded",
        headerName: "Tax Form Uploaded",
        width: 200,
        valueGetter: (param) => param.value || "-",
      },
      /* {
        field: "taxFiles",
        headerName: "Files Uploaded",
        width: 200,
        valueGetter: (param) => param.value || "-",
      },
      */
      {
        field: "highSchoolName",
        headerName: "High School",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "highSchoolCity",
        headerName: "School City",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "highSchoolState",
        headerName: "School State",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "graduation",
        headerName: "Graduation",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "gpa",
        headerName: "GPA",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "counselorName",
        headerName: "Counselor Name",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "counselorEmail",
        headerName: "Counselor Email",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "takenSAT",
        headerName: "Taken SAT",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "satScore",
        headerName: "SAT Score or Date",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },

      // {
      //   field: 'checklist1',
      //   headerName: 'Checklist 1 Completion',
      //   width: 200,
      // },
      // {
      //   field: 'checklist2',
      //   headerName: 'Checklist 2 Completion',
      //   width: 200,
      // },
      {
        field: "parentGuardianNameNumber1",
        headerName: "Parent 1 Name",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "parentGuardianEmailNumber1",
        headerName: "Parent 1 Email",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "parentGuardianHomeMobileNumberNumber1",
        headerName: "Parent 1 Mobile Number",
        width: 220,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "parent1Address",
        headerName: "Parent 1 Address",
        width: 200,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "parentsZipCode1",
        headerName: "Parents Zip Code 1",
        width: 220,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "parentGuardianNameNumber2",
        headerName: "Parent 2 Name",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "parentGuardianEmailNumber2",
        headerName: "Parent 2 Email",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "parentGuardianHomeMobileNumberNumber2",
        headerName: "Parent 2 Mobile Number",
        width: 220,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "parent2Address",
        headerName: "Parent 2 Address",
        width: 200,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "parentsZipCode2",
        headerName: "Parents Zip Code 2",
        width: 220,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "reviewStatus",
        headerName: "Review Status",
        width: 200,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "reviewerName",
        headerName: "Reviewer Name",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "reviewerAssignedAt",
        headerName: "Reviewer Assigned Date ",
        width: 220,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "reviewerSubmittedAt",
        headerName: "Reviewer Submission Date ",
        width: 240,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "reviewerUpdated",
        headerName: "Reviewer Last Modified",
        width: 220,
        valueGetter: (param) => param.value || "-",
      },

      {
        field: "reviewTotalScore",
        headerName: "Score",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },

      //---------------------
      {
        field: "areaOfInterestCompleted",
        headerName: "Educational Interest Completed",
        width: 200,
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "scholarshipFormCompleted",
        headerName: "Scholarship Form Completed",
        width: 180,
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "taxFormCompleted",
        headerName: "Tax Form Completed",
        width: 220,
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "personalInformationCompleted",
        headerName: "Personal Information Completed",
        width: 200,
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "familyInformationCompleted",
        headerName: "Family Information Form Completed",
        width: 180,
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "academicInformationCompleted",
        headerName: "Academic Information Form Completed",
        width: 220,
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "recommendationFormsComplete",
        headerName: "Recommendations Completed",
        width: 180,
        overflow: "auto",
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },

      {
        field: "counselorRecommendationSent",
        headerName: "Counselor Rec Sent",
        width: 180,
        overflow: "auto",
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "counselorRecommendationReceived",
        headerName: "Counselor Rec Received",
        width: 220,
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "personalRecommendationSent",
        headerName: "Personal Rec Sent",
        width: 180,
        overflow: "auto",
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "personalRecommendationReceived",
        headerName: "Personal Rec Received",
        width: 200,
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "academicRecommendationSent",
        headerName: "Academic Rec Sent",
        width: 180,
        overflow: "auto",
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "academicRecommendationReceived",
        headerName: "Academic Rec Received",
        width: 220,
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "extracurricularCompleted",
        headerName: "Extracurricular Form Completed",
        width: 280,
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "covidCompleted",
        headerName: "Covid Form Completed",
        width: 280,
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "shortResponseCompleted",
        headerName: "Short Responses Completed",
        width: 220,
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "resumeCompleted",
        headerName: "Resume Form Completed",
        width: 220,
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "transcriptCompleted",
        headerName: "Transcript Form Completed",
        width: 280,
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "additionalDocCompleted",
        headerName: "Additional Documents Completed",
        width: 220,
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "confirmationCompleted",
        headerName: "Confirmation Form Completed",
        width: 220,
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Yes"
                  ? "#4C9B4F"
                  : param.value === "No"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      //----------------------
      {
        field: "acceptanceFormSentDate",
        headerName: "Acceptance Form Sent Date",
        width: 280,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "acceptanceFormCompletionDate",
        headerName: "Acceptance Form Completion Date",
        width: 280,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "parentConsentSentDate",
        headerName: "Parent Consent Sent Date",
        width: 220,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "parentConsentCompletionDate",
        headerName: "Parent Consent Completion Date",
        width: 280,
        valueGetter: (param) => param.value || "-",
      },
      /* {
         field: 'paymentType',
         headerName: 'Payment Type',
         width: 180,
         valueGetter: (param) => param.value || '-',
       },*/
      {
        field: "initialBalance",
        headerName: "Initial Balance",
        width: 140,
        renderCell: (param) =>
          param.value ? <span>${param.value}</span> : "-",
      },
      {
        field: "balancePaid",
        headerName: "Balance Paid",
        width: 140,
        renderCell: (param) =>
          param.value ? <span>${param.value}</span> : "-",
      },
      {
        field: "paidInFull",
        headerName: "Paid In Full",
        width: 140,
        renderCell: (param) => (param.value ? <span>{param.value}</span> : "-"),
      },

      {
        field: "depositReceived",
        headerName: "Deposit Received",
        width: 140,
        align: "center",
        valueGetter: (param) => param.value || "-",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === "Paid"
                  ? "#4C9B4F"
                  : param.value === "Un-Paid"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Paid"
                  ? "#4C9B4F"
                  : param.value === "Un-Paid"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "depositAmount",
        headerName: "Deposit Amount",
        width: 140,
        renderCell: (param) =>
          param.value ? <span>${param.value}</span> : "-",
      },

      {
        field: "finalPaymentReceived",
        headerName: "Final Payment Received",
        valueGetter: (param) => param.value || "-",
        width: 140,
        align: "center",
        renderCell: (param) => (
          <div
            style={{
              //paid = green, Unpaid = gray
              borderColor:
                param.value === "Paid"
                  ? "#4C9B4F"
                  : param.value === "Un-Paid"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              color:
                param.value === "Paid"
                  ? "#4C9B4F"
                  : param.value === "Un-Paid"
                  ? "#5B5B5B"
                  : "#5B5B5B",
              borderRadius: "4rem",
              border: "2px",
              width: "55px",
              borderStyle: "solid",
              paddingLeft: "8px",
              paddingRight: "8px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: "finalPaymentAmount",
        headerName: "Final Payment Amount",
        width: 140,
        renderCell: (param) =>
          param.value ? <span>${param.value}</span> : "-",
      },

      {
        field: "hdt",
        headerName: "How Did They Hear About LEAD",
        width: 260,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "pleaseSpecify",
        headerName: "How Did They Hear About LEAD specification",
        width: 260,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "arrivalArriveBy",
        headerName: "Arrival Method",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "departureArriveBy",
        headerName: "Departure Method",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "institutionCity",
        headerName: "Institution City",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "applicantDepartureCity",
        headerName: "Applicant Departure City",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "applicantReturnCity",
        headerName: "Applicant Return City",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "arrivalDate",
        headerName: "Arrival Date",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "departureDate",
        headerName: "Departure Date",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "travelComment",
        headerName: "Travel Comment",
        width: 260,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "emergencyContact1Name",
        headerName: "Emergency Contact 1 Name",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "emergencyContact1Number",
        headerName: "Emergency Contact 1 Number",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "emergencyContact2Name",
        headerName: "Emergency Contact 2 Name",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "emergencyContact2Number",
        headerName: "Emergency Contact 2 Number",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "frequentFlyer1Airline",
        headerName: "Frequent Flyer Airline 1",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "frequentFlyer1Number",
        headerName: "Frequent Flyer Number 1",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "frequentFlyer2Airline",
        headerName: "Frequent Flyer Airline 2",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "frequentFlyer2Number",
        headerName: "Frequent Flyer Number 2",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "disability",
        headerName: "Disability",
        width: 180,
        valueGetter: (param) => (param.value?.length > 0 ? param.value : "-"),
      },
      {
        field: "workPreference",
        headerName: "Work Preference",
        width: 180,
        valueGetter: (param) => (param.value?.length > 0 ? param.value : "-"),
      },
      {
        field: "hobbies",
        headerName: "Hobbies",
        width: 180,
        valueGetter: (param) => (param.value?.length > 0 ? param.value : "-"),
      },
      {
        field: "additionalCommentsOrAccommodations",
        headerName: "Additional Comments Or Accommodations",
        width: 260,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "allergiesOrRestrictions",
        headerName: "Allergies Or Restrictions",
        width: 220,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "careerGoal",
        headerName: "Career Goal",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "collegeMajorGoal",
        headerName: "College Major Goal",
        width: 220,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "collegesApplyingTo",
        headerName: "Colleges Applying To",
        width: 220,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "advanceMathematicsCourseTaken",
        headerName: "Mathematocs Course Taken",
        width: 240,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "economicsCourse",
        headerName: "Economics Course",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "experienceInComputers",
        headerName: "Experience In  Computers",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "involvementInAffinityStudentOrganisations",
        headerName: "Involvement In Affinity Student Organisations",
        width: 280,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "musicILike",
        headerName: "Music",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "talents",
        headerName: "Talents",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "sports",
        headerName: "Sports",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "phobia",
        headerName: "Phobia",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "favouriteFood",
        headerName: "Favorite Food",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "roommateConsideration",
        headerName: "Roommate Consideration",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "roleModels",
        headerName: "Role Models",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "countriesBeenTo",
        headerName: "Countries Been To",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },

      {
        field: "greatestStrength",
        headerName: "Greatest Strength",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "greatestWeakness",
        headerName: "Greatest Weakness",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "ownBuisness",
        headerName: "Own Business",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "interestForLead",
        headerName: "Interest For Lead",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "taxFilesUploaded",
        headerName: "Tax Files Uploaded",
        width: 180,
        valueGetter: (param) => param.value || "-",
      },
      {
        field: "comments",
        headerName: "Admin Comments",
        width: 200,
        valueGetter: (param) => param.value || "-",
        renderCell: (params) => (
          <Tooltip title={<Typography>{params.value}</Typography>} arrow>
            <Typography>{params.value}</Typography>
          </Tooltip>
        ),
      },

      {
        field: "scholarConsentFormDetailsCount",
        headerName: "Acceptance Form Count",
        width: 180,
        valueGetter: (param) => param.value || 0,
      },
      {
        field: "governmentIdsCount",
        headerName: "Gov ID Headshot Count",
        width: 180,
        valueGetter: (param) => param.value || 0,
      },
      {
        field: "requiredDocumentsCount",
        headerName: "Required Doc Count",
        width: 180,
        valueGetter: (param) => param.value || 0,
      },
    ];
  }, [history,filteredRows,  fetchDashboardValues, handleProgramRollback]);

  const handleAddFilterModel = async (values) => {
    await addFilterModel(values.filterName, filterModel);
    setDialog(false);
    setFilterModel(null);
  };

  return (
    <Box
      height={688}
      width="100%"
      display="flex"
      style={{
        width: `${matches ? "100%" : "100%"}`,
        overflow: `${matches ? " " : "auto"}`,
      }}
    >
      <Dialog open={openWithrawnDialog}>
        <DialogContent>
          <DialogContentText>Please add a reason to withdraw</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setDeclineComment(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenWithrawnDialog(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleUpdateProgramStage(
                selectedApplicantId,
                "withdrawn",
                false,
                declineComment
              );
              setOpenWithrawnDialog(false);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
        <DialogTitle>Please add a reason to archive</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setArchiveComment(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              archiveProgram(selectedRegisteredProgramId, archiveComment).then(
                () => reloadApplicants()
              );
              setOpen(false);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialog} onClose={() => setDialog(false)} maxWidth="md">
        <form onSubmit={handleSubmit(handleAddFilterModel)}>
          <DialogTitle>Add Filter Model</DialogTitle>
          <DialogContent>
            <Field
              name={"filterName"}
              label={"Filter Name"}
              component={TextArea}
              type={"text"}
              variant={"outlined"}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDialog(false)}
              color="secondary"
              disabled={submitting}
            >
              Close
            </Button>
            <Button color={"primary"} type={"submit"} disabled={submitting}>
              Add Filter
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <DataGridPremium
        ref={grid2}
        // columnGroupingModel={columnGroupingModel}
        // experimentalFeatures={{ columnGrouping: true }}
        columns={memoizedColumns}
        rows={filteredRows}
        disableSelectionOnClick
        checkboxSelection={checkboxSelection}
        page={page}
        onPageChange={(params) => setPage(params.page)}
        onPageSizeChange={(params) => setPageSize(params.pageSize)}
        rowsPerPageOptions={[10, 25, 50]}
        onSelectionModelChange={(selectedIds) => {
          onSelectionModelChange(selectedIds);
        }}
        onRowClick={(param) => {
          history.push(`/admin/reviewProgram?id=${param.row.applicantUid}`);
        }}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            value: searchText,
            clearSearch: () => requestSearch(""),
          },
        }}
        sortModel={sortModel}
        onSortModelChange={(params) => setSortModel(params.sortModel)}
        initialState={{ pinnedColumns: { left: ["fullName"] } }}
      />
    </Box>
  );
};

export default connect(mapStateToProps, {
  fetchDashboardValues,
  handleProgramRollback,
  addFilterModel,
  handleDeletehandleProgramRollback,
  clearFilterModels,
  updateProgramStage,
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
  archiveProgram,
})(reduxForm({ form: "adminNote", validate })(DashboardApplicantsTable));
