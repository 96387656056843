import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Typography, Button, IconButton } from '@mui/material';
import {
  uploadRequiredDocumentForm,
  deleteRequiredDocumentForm,
  submitRequiredDocumentForm,
} from '../../../../../../store/actions/applicantActions/programActions/applicantApprovedActions';
import { connect } from 'react-redux';
import DropzoneInput from '../../../../../dropzone/DropzoneInput';
import { makeStyles } from '@mui/styles';
import { format, parseISO } from 'date-fns';
import {
  DataGridPremium,
  GridToolbar,
  LicenseInfo,
} from '@mui/x-data-grid-premium';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Cancel } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import './RequiredDocumentationForm.css';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  questionText: {
    fontWeight: 600,
    paddingRight: '1em',
  },
  button: {
    marginLeft: '0.4em',
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
    },
  },

  continueButton: {
    marginLeft: '0.4em',
    backgroundColor: '#1976d2',
    color: 'white !Important',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
      //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    },
  },
}));

const RequiredDocumentationForm = ({
  registeredProgramId,
  registeredProgram,
  program,
  profile,
  handleCloseForm,
  handleSetForm,
  resendGrantAcceptanceForm,
  uploadRequiredDocumentForm,
  deleteRequiredDocumentForm,
  submitRequiredDocumentForm,
  onReload,
}) => {
  const [error, setError] = useState('');
  const classes = useStyles();
  const [docs, setDocs] = useState([]);
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState({});
  const [fileRowsNew, setFileRowsNew] = useState([]);
  const Maxsize = 140000;
  const Minsize = 10000;
  useEffect(() => {
    const arr = [];
    const arrIndex = [];
    program?.acceptanceRequiredDocuments?.forEach((doc, index) => {
      if (arr.indexOf(doc.title) < 0) {
        if (
          doc.institution === 'default' ||
          doc.institution === registeredProgram.chosenInstitution
        ) {
          arr.push(doc.title);
          arrIndex.push(index);
        }
      }
    });
    const acceptanceRequiredDocuments =
      program?.acceptanceRequiredDocuments?.map((obj) => {
        let uploadedDocument;

        if (
          registeredProgram?.requiredDocuments &&
          Object.keys(registeredProgram?.requiredDocuments).length
        ) {
          const keys = Object.keys(registeredProgram?.requiredDocuments);

          keys.map((key) => {
            if (key === 'additionalDocuments') {
              const additionalDocuments =
                registeredProgram?.requiredDocuments[key];
              const filteredRows = additionalDocuments.map((x, i) => {
                return {
                  ...x,
                  id: i + 1,
                  name: x?.originalFileName || '',
                };
              });
              setFileRowsNew([...filteredRows]);
            }

            if (key === obj?.title)
              uploadedDocument = registeredProgram?.requiredDocuments[key];
          });
        }

        return { ...obj, upload: uploadedDocument?.originalFileName || '' };
      });

    setDocs(arrIndex.map((index) => acceptanceRequiredDocuments[index]));
  }, [program.acceptanceRequiredDocuments, registeredProgram, program]);

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      'e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
    );
  }, []);

  const submitForm = async () => {
    const filteredDocs = program?.acceptanceRequiredDocuments?.filter(
      (doc) =>
        registeredProgram?.requiredDocuments &&
        (doc.institution === 'default' ||
          doc.institution === registeredProgram?.chosenInstitution) &&
        Object.keys(registeredProgram?.requiredDocuments).includes(doc.title)
    );

    if (docs.length === filteredDocs.length) {
      await submitRequiredDocumentForm(registeredProgramId);
      handleCloseForm();
    } else {
      setError('All Documents are required');
    }
  };

  const memoizedColumns = useMemo(
    () => [
      {
        field: 'title',
        headerName: 'Document Name',
        flex: 2,
        sortable: false,
        headerClassName: classes.headerBackground,
        renderHeader: (params) => (
          <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
            {params.field}
          </Typography>
        ),
      },
      {
        field: 'download',
        headerName: 'Download',
        sortable: false,
        headerClassName: classes.headerBackground,
        width: 160,
        renderHeader: (params) => (
          <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
            {params.field}
          </Typography>
        ),
        renderCell: (params) => {
          return (
            <Button
              color="primary"
              component="label"
              size="small"
              onClick={() => window.open(params.row.file.fileURL, '_blank')}
            >
              Download
            </Button>
          );
        },
      },
      {
        field: 'upload',
        headerName: 'Upload',
        sortable: false,
        headerClassName: classes.headerBackground,
        renderHeader: (params) => (
          <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
            {params.field}
          </Typography>
        ),
        renderCell: (params) => {
          if (
            !(
              registeredProgram?.requiredDocuments &&
              registeredProgram.requiredDocuments[params.row.title]
            )
          )
            return (
              <Button color="primary" component="label" size="small">
                Upload
                <input
                  type="file"
                  hidden
                  onChange={async (event) => {
                    params.field = event?.target?.files[0]?.name;
                    const filteredDocs =
                      program.acceptanceRequiredDocuments.filter(
                        (doc) =>
                          registeredProgram?.requiredDocuments &&
                          (doc.institution === 'default' ||
                            doc.institution ===
                              registeredProgram.chosenInstitution) &&
                          Object.keys(
                            registeredProgram.requiredDocuments
                          ).includes(doc.title)
                      );

                    await uploadRequiredDocumentForm(
                      registeredProgramId,
                      event.target.files[0],
                      params.row.title,
                      false,
                      docs.length <= filteredDocs.length + 1
                    ).then(() => onReload());
                  }}
                />
              </Button>
            );
          return params.value;
        },
      },
      {
        field: 'delete',
        headerName: 'Delete',
        sortable: false,
        headerClassName: classes.headerBackground,
        renderHeader: (params) => (
          <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
            {params.field}
          </Typography>
        ),
        renderCell: (params) => {
          if (
            registeredProgram?.requiredDocuments &&
            registeredProgram.requiredDocuments[params.row.title]
          )
            return (
              <Button
                color="secondary"
                component="label"
                size="small"
                onClick={() => {
                  deleteRequiredDocumentForm(
                    registeredProgramId,
                    registeredProgram.requiredDocuments[params.row.title]
                      .fileName,
                    params.row.title
                  );
                }}
              >
                Delete
              </Button>
            );
          return <></>;
        },
      },
    ],
    [
      registeredProgramId,
      deleteRequiredDocumentForm,
      registeredProgram.requiredDocuments,
    ]
  );

  const memoizedAdditionalDocumentsColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 2,
        sortable: false,
        headerClassName: classes.headerBackground,
        renderHeader: (params) => (
          <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
            {params.field}
          </Typography>
        ),
      },
      {
        field: 'delete',
        headerName: 'Delete',
        sortable: false,
        headerClassName: classes.headerBackground,
        renderHeader: (params) => (
          <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
            {params.field}
          </Typography>
        ),
        renderCell: (params) => {
          if (params.id) {
            return (
              <Button
                color="secondary"
                component="label"
                size="small"
                onClick={() => {
                  deleteRequiredDocumentForm(
                    registeredProgramId,
                    params?.row?.fileName,
                    params?.row?.originalFileName,
                    params?.row
                  );
                }}
              >
                Delete
              </Button>
            );
          }
          return <></>;
        },
      },
    ],
    [fileRowsNew, registeredProgramId]
  );

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          Please upload all documents first!
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpen(false)}
            sx={{ ml: 1 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Alert>
      </Snackbar>
      <Typography
        variant={'h6'}
        style={{
          fontWeight: 600,
          marginTop: '1em',
          marginBottom: '1em',
          marginLeft: '15px',
        }}
      >
        Required Pre-Arrival Forms
      </Typography>

      <Grid
        item
        container
        direction={'column'}
        style={{ padding: '2em', gap: '1em' }}
      >
        <Typography
          variant={'h6'}
          style={{ fontWeight: 600, marginTop: '1em', marginBottom: '1em' }}
        >
          PLEASE READ ALL MATERIALS CAREFULLY AND RETURN REQUIRED ITEMS PROMPTLY
          (NO LATER THAN{' '}
          {program?.packetDueDate
            ? format(parseISO(program?.packetDueDate), 'MMM do y')
            : null}{' '}
          ).
        </Typography>
        <Typography
          variant={'body'}
          style={{ marginTop: '1em', marginBottom: '1em' }}
        >
          Review the checklist and submit required information to assist in your
          preparation for the Program and to inform you about what is expected
          of all Scholars.
        </Typography>

        <Typography
          variant={'body'}
          style={{ fontWeight: 600, marginTop: '1em' }}
        >
          CHECKLIST OF DOCUMENTS – To be reviewed by Scholar with
          Parent/Guardian. Please download each document by clicking the
          download icon and sign it. Once signed, please upload the document
          next to the associated file for each required document. Use the
          "Additional Documents" section to upload supporting documents such as
          medical insurance card.
        </Typography>
        <Typography
          variant={'body'}
          style={{ marginTop: '1em', marginBottom: '1em' }}
        >
          Note: Some information requested may duplicate what is being submitted
          to LEAD National. Please respond to all requests from both entities.
        </Typography>
      </Grid>

      <Grid
        item
        container
        direction={'column'}
        style={{ padding: '2em', gap: '1em', paddingLeft: '4em' }}
      >
        <DataGridPremium
          autoHeight
          hideFooter
          disableSelectionOnClick
          disableColumnMenu
          columns={memoizedColumns}
          rows={docs.map((doc, index) => ({
            id: index,
            ...doc,
          }))}
          components={{ Toolbar: GridToolbar }}
        />
        {/* {docs.map((doc) => {
          return (
            <Grid item container alignItems="center">
              <Typography className={classes.questionText}>
                Document Title:
              </Typography>
              <Typography style={{ marginRight: "1em" }}>
                {doc.title}
              </Typography>
              <IconButton
                color="primary"
                size="small"
                style={{ marginRight: "1em" }}
                onClick={() => window.open(doc.file.fileURL, "_blank")}
              >
                <CloudDownload />
              </IconButton>
              {registeredProgram.requiredDocuments &&
              registeredProgram.requiredDocuments[doc.title] ? (
                <Grid item>
                  <Grid item container alignItems="center">
                    <Typography className={classes.formText}>
                      {
                        registeredProgram.requiredDocuments[doc.title]
                          .originalFileName
                      }
                    </Typography>
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        deleteRequiredDocumentForm(
                          registeredProgramId,
                          registeredProgram.requiredDocuments[doc.title]
                            .fileName,
                          doc.title
                        );
                      }}
                    >
                      <Cancel />
                    </IconButton>
                  </Grid>
                </Grid>
              ) : (
                <Grid item>
                  <DropzoneInput
                    accept=".pdf"
                    buttonOnly
                    setFiles={(file) => {
                      uploadRequiredDocumentForm(
                        registeredProgramId,
                        file[0],
                        doc.title
                      );
                    }}
                  />
                </Grid>
              )}
            </Grid>
          );
        })} */}
        <br></br>
        <Typography variant="h6" style={{ fontWeight: 600 }}>
          Additional Documents
        </Typography>
        <DataGridPremium
          autoHeight
          hideFooter
          columns={memoizedAdditionalDocumentsColumns}
          rows={fileRowsNew}
          components={{ Toolbar: GridToolbar }}
        />
        <br />
        <Typography variant="body" style={{ fontWeight: 600 }}>
          Please upload a copy of your insurance card in this section.{' '}
        </Typography>
        <Typography variant="body">
          {' '}
          Also, use this section to upload your Government issued ID. An
          acceptable Gov ID includes license, permit, or passport. If you do not
          have one of these items, then a copy of your birth certificate along
          with a headshot will be required.
        </Typography>
        <Button
          variant={'outlined'}
          color={'primary'}
          className={classes.button}
          component="label"
          size="small"
          style={{ width: 'fit-content', margin: '0 auto' }}
        >
          <AddIcon style={{ fontSize: '1rem' }} />
          Add document
          <input
            type="file"
            hidden
            onChange={async (event) => {
              await uploadRequiredDocumentForm(
                registeredProgramId,
                event.target.files[0],
                '',
                true
              );
            }}
          />
        </Button>
        {error && (
          <Grid item>
            <Typography color="error">{error}</Typography>
          </Grid>
        )}
        {/*BUTTONS*/}
        <Grid item className="required-document-form-buttons">
          <Button
            variant={'outlined'}
            color={'primary'}
            className={classes.button}
            // type={"submit"}
            onClick={() => {
              if (
                registeredProgram?.finalSubmissionCompleted &&
                registeredProgram?.requiredDocumentationFormUploaded &&
                program?.acceptanceRequiredDocuments?.length !==
                  Object.keys(registeredProgram?.requiredDocuments)?.length
              ) {
                setOpen(true);
                return;
              }
              handleCloseForm();
            }}
          >
            Save for Later
          </Button>
          {!registeredProgram?.finalSubmissionCompleted && (
            <Button
              variant={'outlined'}
              color={'primary'}
              className={classes.continueButton}
              // type={"submit"}
              onClick={submitForm}
            >
              Continue{' '}
              <span>
                <strong> &#8594;</strong>
              </span>
            </Button>
          )}

          <Button
            variant={'outlined'}
            color={'secondary'}
            className={classes.button}
            onClick={() => {
              if (
                registeredProgram?.finalSubmissionCompleted &&
                registeredProgram?.requiredDocumentationFormUploaded &&
                program?.acceptanceRequiredDocuments?.length !==
                  Object.keys(registeredProgram?.requiredDocuments)?.length
              ) {
                setOpen(true);
                return;
              }
              handleCloseForm();
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const actions = {
  uploadRequiredDocumentForm,
  deleteRequiredDocumentForm,
  submitRequiredDocumentForm,
};

export default connect(null, actions)(RequiredDocumentationForm);
