import React, { useState, useEffect, useMemo, useRef } from "react";
import { Button, Box } from "@mui/material";
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from "@mui/x-data-grid-premium";

import { openDialog } from "../../../store/actions/dialogActions/dialogActions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { CustomToolbar, escapeRegExp } from "../../../common/tableUtils";
import { format, fromUnixTime } from "date-fns";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  // ADD STYLES HERE
  flex3: {
    flex: 3,
  },
  buttonColor: {
    padding: "5px 23px 5px 23px",
    borderRadius: "10px",
  },
}));

const NeedsReviewTable = ({
  reviewStatus,
  rows: reviewApplications,
  openDialog,
  profile,
  onReload
}) => {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [filterModel, setFilterModel] = useState();
  const [sortModel, setSortModel] = useState();
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const grid1 = useRef(null);
  const grid2 = useRef(null);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      "e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    );
  }, []);

  console.log("reviewApplications", reviewApplications);

  useEffect(() => {
    const arr = reviewApplications
      .filter((application) => application.reviewStatus === reviewStatus)
      .map((application, index) => ({
        ...application,
        serialNo: index + 1,
        programName: application.programDetails?.programName,
        reviewer: application.reviewerDetails?.fullName,
        qualifyForScholarship: application.scholarshipFormDetails
          ?.qualifyForScholarship
          ? "Yes"
          : "No",
        submissionDate:
          application.confirmationFormDetails?.confirmationDate &&
          format(
            new Date(application.confirmationFormDetails?.confirmationDate),
            "MM/dd/yyyy"
          ),
        reviewerAssignedAt:
          application.reviewerAssignedAt &&
          format(fromUnixTime(application.reviewerAssignedAt), "MM/dd/yyyy"),
        reviewerSubmittedAt:
          application.reviewerResponse?.reviewedOn &&
          format(
            new Date(application.reviewerResponse?.reviewedOn),
            "MM/dd/yyyy"
          ),
      }));
    setRows(arr);
    if (searchText) {
      const searchRegex = new RegExp(escapeRegExp(searchText), "i");
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
    }
  }, [reviewApplications, reviewStatus]);

  console.log("reviewStatus", reviewStatus);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filtered = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };

  const memoizedColumns = useMemo(() => {
    return [
   
      {
        field: "fullName",
        headerName: "Name",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
      },
     /* {
        field: "programName",
        headerName: "Program",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
      },*/
      {
        field: "submissionDate",
        headerName: "Submission Date",
        width: 160,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "qualifyForScholarship",
        headerName: "Qualify for Scholarship",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
      },

      {
        field: "reviewer",
        headerName: "Reviewer",
        width: 180,
        hide: reviewStatus !== "assigned",
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "reviewerAssignedAt",
        headerName: "Reviewer Assigned Date ",
        width: 220,
        hide: reviewStatus !== "assigned",
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "reviewerSubmittedAt",
        headerName: "Reviewer Submission Date ",
        width: 220,
        hide: reviewStatus !== "assigned" || profile.isReviewer,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "affiliateName",
        headerName: "Affiliate Name",
        width: 160,
        hide: !profile.isAdmin,
        valueGetter: (param) => param.value || "None",
      },
      {
        field: "affiliateCode",
        headerName: "Affiliate Code",
        width: 140,
        hide: !profile.isAdmin,
        valueGetter: (param) => param.value || "None",
      },
      {
        field: "",
        headerName: "Actions",
        width: 280,
        valueGetter: (param) => param.value || "N/A",
        renderCell: (param) => (
          <Box display="flex" justifyContent="space-evenly" width="100%">
            {param.row.reviewerId === profile.uid && (
              <Button
                variant="contained"
                // color="#35BA35"
                size="small"
                onClick={() =>
                  history.push(
                    `/${
                      profile.isAdmin ? "admin" : "reviewer"
                    }/reviewProgram?id=${
                      profile.isAdmin ? param.row.applicantUid : param.row.id
                    }`
                  )
                }
                className={classes.buttonColor}
                style={{
                  marginRight: "0.5em",
                  background: "#35BA35",
                  color: "white",
                }}
              >
                Review
              </Button>
            )}
            {profile.isAdmin && (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() =>
                    openDialog("AssignReviewerDialog", {
                      review: param.row,
                      onReload
                    })
                  }
                  className={classes.buttonColor}
                  style={{ marginRight: "0.5em" }}
                >
                  {param.row.reviewStatus === "assigned"
                    ? "Reassign"
                    : "Assign"}
                </Button>
                {param.row.reviewStatus === "assigned" && (
                  <Button
                    variant="outlined"
                    size="small"
                    // color="secondary"
                    onClick={() =>
                      openDialog("UnassignReviewerDialog", {
                        review: param.row,
                        onReload
                      })
                    }
                    className={classes.buttonColor}
                    style={{ color: "#0079A9" }}
                  >
                    Unassign
                  </Button>
                )}
              </>
            )}
          </Box>
        ),
      },
    ];
  }, [
    history,
    openDialog,
    profile.isAdmin,
    profile.isReviewer,
    profile.uid,
    reviewStatus,
  ]);

  return (
    <Box height={320} width="100%" display="flex">
      <DataGridPremium
        ref={grid2}
        className={classes.flex3}
        columns={memoizedColumns}
        rows={filteredRows}
        disableSelectionOnClick
        // disableColumnMenu
        rowsPerPageOptions={[]}
        page={page}
        onPageChange={(params) => setPage(params.page)}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            value: searchText,
            clearSearch: () => requestSearch(""),
            hideExport: profile.isReviewer,
          },
        }}
        filterModel={filterModel}
        onFilterModelChange={(params) => setFilterModel(params.filterModel)}
        sortModel={sortModel}
        onSortModelChange={(params) => setSortModel(params.sortModel)}
        initialState={{ pinnedColumns: { left: ["fullName"] } }}
      />
    </Box>
  );
};

const actions = {
  openDialog,
};

export default connect(null, actions)(NeedsReviewTable);
