import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import ReviewProgramItem from "./item/ReviewProgramItem";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  answerText: {
    fontWeight: 600,
  },
}));

const AcademicInformationFormReview = ({ academicInformation }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction={"column"}
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
    >
      <Grid item>
        <Typography className={classes.title}>
          Academic Information Form
        </Typography>
      </Grid>
      {/*LAST NAME*/}
      <ReviewProgramItem
        question={"High School Name:"}
        value={academicInformation?.highSchoolName}
      />
      <ReviewProgramItem
        question={"High School City:"}
        value={academicInformation?.highSchoolCity}
      />
      <ReviewProgramItem
        question={"High School State:"}
        value={academicInformation?.highSchoolState}
      />
      <ReviewProgramItem
        question={"Graduation Month/Year:"}
        value={academicInformation?.graduationMonthYear}
      />

      <ReviewProgramItem
        question={"Name of Counselor/Advisor:"}
        value={academicInformation?.nameOfCounselorAdvisor}
      />
      <ReviewProgramItem
        question={"Counselor/Advisor Email Address:"}
        value={academicInformation?.emailAddressOfCounselorAdvisor}
      />
    </Grid>
  );
};

export default AcademicInformationFormReview;
