import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  image: {
    // width: '100%',
    borderRadius: "20px",
    marginBottom: "40px",
    // [theme.breakpoints.down("sm")]: {
    //     width: "100%",
    //     height: '100%'

    // },
  },

  heading: {
    color: "#171725",
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "36px",
    letterSpacing: "0.1px",
    marginBottom: "40px",
  },
  text: {
    color: "#696974",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "0.1px",
  },

  button: {
    color: "#4CA94F",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "0.1px",
    cursor: "pointer",
  },
  img1: {
    marginTop: "0x",
  },
  img2: {
    marginTop: "40px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },
  img3: {
    marginTop: "80px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },
  img4: {
    marginTop: "120px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },
}));

const ScholarProgramsItem = ({ img, heading, text, link, imgNumber }) => {
  const classes = useStyles();
  // const theme = useTheme();
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  return (
    <Grid
      item
      lg={3}
      md={3}
      sm={6}
      xs={12}
      style={{ paddingLeft: "40px", paddingRight: "40px" }}
    >
      {imgNumber === 0 && (
        <Grid item container direction={"column"} className={classes.img1}>
          <Grid item container justifyContent="center" alignItems="center">
            <img className={classes.image} src={img} alt="" />
          </Grid>

          <Grid item>
            <Typography className={classes.heading}>{heading}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.text}>{text}</Typography>
          </Grid>
          <Grid item style={{ marginTop: "16px" }}>
            <Typography
              onClick={() =>
                history.push(
                  `/program?categoryId=${link}&filter=true&category=${heading}`
                )
              }
              className={classes.button}
            ></Typography>
          </Grid>
        </Grid>
      )}

      {imgNumber === 1 && (
        <Grid item container direction={"column"} className={classes.img2}>
          <Grid item container justifyContent="center" alignItems="center">
            <img className={classes.image} src={img} alt="" />
          </Grid>

          <Grid item>
            <Typography className={classes.heading}>{heading}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.text}>{text}</Typography>
          </Grid>
          <Grid item style={{ marginTop: "16px" }}>
            <Typography
              onClick={() =>
                history.push(`/program?categoryId=${link}&filter=true`)
              }
              className={classes.button}
            ></Typography>
          </Grid>
        </Grid>
      )}
      {imgNumber === 2 && (
        <Grid item container direction={"column"} className={classes.img3}>
          <Grid item container justifyContent="center" alignItems="center">
            <img className={classes.image} src={img} alt="" />
          </Grid>

          <Grid item>
            <Typography className={classes.heading}>{heading}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.text}>{text}</Typography>
          </Grid>
          <Grid item style={{ marginTop: "16px" }}>
            <Typography
              onClick={() =>
                history.push(`/program?categoryId=${link}&filter=true`)
              }
              className={classes.button}
            ></Typography>
          </Grid>
        </Grid>
      )}

      {imgNumber === 3 && (
        <Grid item container direction={"column"} className={classes.img4}>
          <Grid item container justifyContent="center" alignItems="center">
            <img className={classes.image} src={img} alt="" />
          </Grid>

          <Grid item>
            <Typography className={classes.heading}>{heading}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.text}>{text}</Typography>
          </Grid>
          <Grid item style={{ marginTop: "16px" }}>
            <Typography
              onClick={() =>
                history.push(`/program?categoryId=${link}&filter=true`)
              }
              className={classes.button}
            >
              Learn More >
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ScholarProgramsItem;
