import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import { reduxForm, getFormValues, Field } from "redux-form";
import { connect } from "react-redux";
import ReviewFormItem from "./ReviewFormItem";
import { combineValidators, isRequired } from "revalidate";
import { useHistory } from "react-router-dom";
import { updateReview } from "../../store/actions/reviewActions/reviewActions";
import Button from "@mui/material/Button";
import TextArea from "../../common/form/TextArea";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#171725",
  },
  text: {
    fontFamily: "Poppins",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#696974",
  },
  button: {
    marginLeft: "1rem",
  },
}));

const validate = combineValidators({
  extraCurricularCommunityActivityValue: isRequired({
    message: "Value is required",
  }),
  academicPerformanceValue: isRequired({ message: "Value is required" }),
  essayQualityValue: isRequired({ message: "Value is required" }),
  recommendationQualityValue: isRequired({ message: "Value is required" }),
});

const actions = {
  updateReview,
};

const mapStateToProps = (state) => {
  let personalInformation = [];
  let initialState = {};

  if (
    state.review.reviewApplication &&
    state.review.reviewApplication.length > 0
  ) {
    personalInformation =
      state.review.reviewApplication[0].personalInformationFormDetails;
    initialState = {
      essayQualityValue: state.review.reviewApplication[0]?.essayQualityValue,
      extraCurricularCommunityActivityValue:
        state.review.reviewApplication[0]
          ?.extraCurricularCommunityActivityValue,
      academicPerformanceValue:
        state.review.reviewApplication[0]?.academicPerformanceValue,
      recommendationQualityValue:
        state.review.reviewApplication[0]?.recommendationQualityValue,
      comments: state.review.reviewApplication[0]?.comments,
    };
  }

  return {
    personalInformation,
    formValues: getFormValues("reviewProgram")(state),
    initialValues: initialState,
  };
};

const ReviewForm = ({
  profile,
  program,
  formValues,
  personalInformation,
  handleSubmit,
  updateReview,
  currentForm,
}) => {
  const [required, setRequired] = useState(true);
  const classes = useStyles();

  const history = useHistory();

  const extraCommunityActivity =
    formValues?.extraCurricularCommunityActivityValue
      ? formValues?.extraCurricularCommunityActivityValue
      : 0;
  const academicPerformance = formValues?.academicPerformanceValue
    ? formValues?.academicPerformanceValue
    : 0;
  const essayQuality = formValues?.essayQualityValue
    ? formValues?.essayQualityValue
    : 0;
  const recommendationQuality = formValues?.recommendationQualityValue
    ? formValues?.recommendationQualityValue
    : 0;

  const totalScore =
    extraCommunityActivity +
    academicPerformance +
    essayQuality +
    recommendationQuality;
  const averageScore =
    (extraCommunityActivity +
      academicPerformance +
      essayQuality +
      recommendationQuality) /
    4;

  const handleReview = async (values, isReviewed, reviewedBy) => {
    await updateReview(
      program.id,
      values,
      totalScore,
      averageScore,
      isReviewed,
      currentForm,
      profile.fullName
    );
    history.push("/reviewer/dashboard");
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      direction={"column"}
      style={{ padding: "2em" }}
    >
      <form
        autoComplete={"off"}
        onSubmit={handleSubmit((values) => handleReview(values, true))}
      >
        <Grid item>
          <Typography className={classes.title}>Review Form</Typography>
        </Grid>

        <ReviewFormItem
          title={" Extra Curriculum/ Community Involvement"}
          fieldName={"extraCurricularCommunityActivityValue"}
          fieldLabel={"Value"}
          description={
            "Applicant is involved in various activities including sports, academic clubs, as well as meaningful social groups"
          }
          required={required}
        />
        <ReviewFormItem
          title={"Academic Performance"}
          fieldName={"academicPerformanceValue"}
          fieldLabel={"Value"}
          description={
            "Applicant demonstrates solid Academic credentials including GPA (average of 3.0), Honors, and/or AP course work"
          }
          required={required}
        />
        <ReviewFormItem
          title={"Essay Quality"}
          fieldName={"essayQualityValue"}
          fieldLabel={"Value"}
          description={
            "Applicant clearly presented their response to essay question(s) in a precise, coherent, and grammatically correct manner"
          }
          required={required}
        />
        <ReviewFormItem
          title={"Recommendation Quality"}
          fieldName={"recommendationQualityValue"}
          fieldLabel={"Value"}
          description={
            "Applicant recommendation(s) represent a cross section of observers who provide candid examples of the applicants character, academic performance and leadership capability and or potential"
          }
          required={required}
        />

        {/*TOTAL SCORE*/}
        <Grid item style={{ marginTop: "2rem" }}>
          <Grid item container>
            <Grid item md={8} style={{ paddingRight: "1.5em" }}>
              <Typography classes={classes.title}>Total Score:</Typography>
            </Grid>
            <Grid item md={4}>
              <Typography className={classes.text}>{totalScore}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {/*AVERAGE SCORE*/}
        {/* <Grid item style={{ marginTop: '0.2em' }}>
          <Grid item container>
            <Grid item md={8} style={{ paddingRight: '1.5em' }}>
              <Typography className={classes.title}>Average Score:</Typography>
            </Grid>
            <Grid item md={4}>
              <Typography className={classes.text}>{averageScore}</Typography>
            </Grid>
          </Grid>
        </Grid> */}

        {/*APPLICANTS BACKGROUND*/}
        <Grid item style={{ marginTop: "0.2em" }}>
          <Grid item container>
            <Grid item md={8} style={{ paddingRight: "1.5em" }}>
              <Typography className={classes.title}>
                Applicants background:
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography className={classes.text}>
                {personalInformation?.ethnicBackground}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/*COMMENTS*/}
        {/*COMMENTS TITLE*/}
        <Grid item style={{ marginTop: "2.5em" }}>
          <Grid
            item
            container
            className={classes.fieldContainer}
            alignItems={"center"}
          >
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <Grid item container direction={"column"}>
                <Grid item>
                  <Typography className={classes.title}>Comments</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/*COMMENT BX*/}
        <Grid item>
          <Grid
            item
            container
            className={classes.fieldContainer}
            alignItems={"center"}
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid item>
                <Grid item container direction={"column"}>
                  <Grid item className={classes.fieldWrapper}>
                    <Field
                      name={"comments"}
                      component={TextArea}
                      rows={5}
                      type={"text"}
                      variant={"outlined"}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{ marginTop: "1em" }}>
          <Grid item container>
            <Grid item style={{ marginLeft: "auto", paddingRight: "1em" }}>
              <Button
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                disabled={program.applicationAccepted}
                onClick={() => {
                  new Promise((resolve) => {
                    setRequired(false);
                    resolve();
                  }).then(() => {
                    handleSubmit((values) => handleReview(values, false))();
                  });
                }}
              >
                Save
              </Button>
              <Button
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={
                  program.applicationAccepted || program.reviewerResponse
                }
              >
                Submit Review
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "reviewProgram", enableReinitialize: true })(ReviewForm));
