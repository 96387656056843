import React, { useState, useEffect, useMemo, useRef } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/material";
import { escapeRegExp } from "../../../common/tableUtils";
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { reduxForm } from "redux-form";
import { combineValidators, isRequired } from "revalidate";

const validate = combineValidators({
  filterName: isRequired({ message: "Required" }),
});

const mapStateToProps = (state) => {
  let filters = {};

  if (state.adminDashboard.filters) {
    filters = state.adminDashboard.filters;
  }

  return {
    filters,
    profile: state.firebase.profile,
  };
};

const TravelFormApplicantsTable = ({
  applicants,
  fetchDashboardValues,
  handleProgramRollback,
  addFilterModel,
  filters,
  checkboxSelection,
  onSelectionModelChange,
}) => {
  const matches = useMediaQuery("(min-width:530px)");
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState(applicants);
  const [filteredRows, setFilteredRows] = useState(applicants);
  const [filterModel, setFilterModel] = useState();
  const [sortModel, setSortModel] = useState();
  const [, setRender] = useState(false);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const grid1 = useRef(null);
  const grid2 = useRef(null);

  const history = useHistory();

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      "e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    );
  }, []);

  useEffect(() => {
    const arr = applicants.map((applicant, index) => {
      return {
        ...applicant,
        serialNo: index + 1,
      };
    });
    setRows(arr);
    if (searchText) {
      const searchRegex = new RegExp(escapeRegExp(searchText), "i");
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
    }
  }, [applicants]);

  useEffect(() => {
    const search = history.location.search;
    if (search.includes("?institute")) {
      setRender((prevRender) => !prevRender);
    }
  }, [history, filteredRows]);

  useEffect(() => {
    if (filter) {
      setFilterModel(filters[filter]);
    } else {
      setFilterModel(null);
    }
  }, [filter, filters]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filtered = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };

  const memoizedColumns = useMemo(() => {
    return [
      { field: "serialNo", headerName: "Id", width: 80 },
      {
        field: "firstName",
        headerName: "First Name",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "lastName",
        headerName: "Last Name",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "middleInitial",
        headerName: "Middle Initial",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "dob",
        headerName: "Date Of Birth",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "mobileNumber",
        headerName: "Mobile Number",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "email",
        headerName: "Email",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "parentGuardianEmergencyContactName1",
        headerName: "Parent/Guardian - Emergency Contact Name",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "parentGuardianEmergencyContactNumber1",
        headerName: "Parent/Guardian - Emergency Contact Number",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "parentGuardianEmergencyContactName2",
        headerName: "Parent/Guardian #2 - Emergency Contact Name",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "parentGuardianEmergencyContactNumber2",
        headerName: "Parent/Guardian #2 - Emergency Contact Number",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "frequentAirline1",
        headerName: "Frequent Flyer Airline 1",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "frequentAirlineNumber1",
        headerName: "Frequent Flyer Number 1",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "arrivalArrivingBy",
        headerName: "(Arrival) Arriving By",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "arrivalDepartureCity",
        headerName: "(Arrival) Departure City",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "arrivalArrivalDate",
        headerName: "(Arrival) Arrival Date",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "arrivalArrivalCity",
        headerName: "(Arrival) Arrival City",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "departureArrivingBy",
        headerName: "(Departure) Arriving By",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "departureDepartureCity",
        headerName: "(Departure) Departure City",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "departureArrivalDate",
        headerName: "(Departure) Departure Date",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "departureArrivalCity",
        headerName: "(Departure) Arrival City",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "institutionName",
        headerName: "Assigned Institution",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "comments",
        headerName: "Additional Comments",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "vaccineCardUrl",
        headerName: "Vaccine Card Link",
        width: 80,
        renderCell: (params) => {
          if (params.value) {
            return (
              <a target="_blank" href={params.value}>
                Download
              </a>
            );
          }
          return "N/A";
        },
      },
    ];
  }, [history, fetchDashboardValues, handleProgramRollback]);

  return (
    <Box
      height={688}
      width="100%"
      display="flex"
      style={{
        width: `${matches ? "100%" : "100%"}`,
        overflow: `${matches ? " " : "auto"}`,
      }}
    >
      <DataGridPremium
        ref={grid2}
        // columnGroupingModel={columnGroupingModel}
        // experimentalFeatures={{ columnGrouping: true }}
        columns={memoizedColumns}
        rows={filteredRows}
        disableSelectionOnClick
        checkboxSelection={checkboxSelection}
        page={page}
        onPageChange={(params) => setPage(params.page)}
        onPageSizeChange={(params) => setPageSize(params.pageSize)}
        rowsPerPageOptions={[10, 25, 50]}
        onSelectionModelChange={(selectedIds) => {
          onSelectionModelChange(selectedIds);
        }}
        onRowClick={(param) => {
          history.push(`/admin/reviewProgram?id=${param.row.id}`);
        }}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            value: searchText,
            clearSearch: () => requestSearch(""),
          },
        }}
        sortModel={sortModel}
        onSortModelChange={(params) => setSortModel(params.sortModel)}
        initialState={{ pinnedColumns: { left: ["fullName"] } }}
      />
    </Box>
  );
};

export default connect(
  mapStateToProps,
  null
)(reduxForm({ form: "adminNote", validate })(TravelFormApplicantsTable));
