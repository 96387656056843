import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import {
  fetchRecommenderApplicant,
  sendRecommenderResponse,
} from "../../store/actions/recommenderActions/recommenderActions";
import Typography from "@mui/material/Typography";
import TextAreaForm from "../../ui/forms/TextAreaForm";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: "0.4em",
  },
}));

const actions = {
  fetchRecommenderApplicant,
  sendRecommenderResponse,
};

const mapStateToProps = (state) => {
  let applicant = {};

  if (
    state.recommender.recommenderApplicant &&
    state.recommender.recommenderApplicant.length > 0
  ) {
    applicant = state.recommender.recommenderApplicant[0];
  }

  return {
    applicant,
  };
};

const RecommenderReview = ({
  fetchRecommenderApplicant,
  sendRecommenderResponse,
  applicant,
  handleSubmit,

  submitting,
}) => {
  const classes = useStyles();

  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get("id");
  const recommenderType = queryParams.get("type");

  useEffect(() => {
    fetchRecommenderApplicant(id);
  }, [fetchRecommenderApplicant, id]);

  const handleFormSubmit = async (values) => {
    try {
      await sendRecommenderResponse(id, values, recommenderType);
      history.push("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid container justifyContent={"center"} direction={"column"}>
      <Grid item>
        <Typography variant={"h6"}>{recommenderType} Recommendation</Typography>
      </Grid>

      <Grid item>
        <Typography variant={"h6"}>
          Applicant Name: {applicant.firstName + " " + applicant.lastName}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant={"h6"}>
          Applicant Email: {applicant.email}
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant={"h6"}>
          Program Name: {applicant.programDetails?.programName}
        </Typography>
      </Grid>

      <Grid item>
        <form autoComplete={"off"} onSubmit={handleSubmit(handleFormSubmit)}>
          <TextAreaForm
            fieldName={"recommendersResponse"}
            question={"Applicant Review"}
          />

          <Grid item style={{ marginTop: "1.5em" }}>
            <Grid item container>
              <Grid item style={{ marginLeft: "auto" }}>
                <Button
                  variant={"outlined"}
                  color={"primary"}
                  className={classes.button}
                  type={"submit"}
                  disabled={submitting}
                >
                  Send
                </Button>

                <Button
                  variant={"outlined"}
                  color={"secondary"}
                  className={classes.button}
                  onClick={() => history.push("/")}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "recommenderReviewForm",
    enableReinitialize: true,
    // validate,
  })(RecommenderReview)
);
