import React, { useEffect, useMemo, useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import Sidebar from "../../../../ui/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { format, parseISO } from "date-fns";
import {
  disableTemplate,
  enableTemplate,
  fetchAllTemplates,
  deleteTemplate,
} from "../../../../store/actions/adminActions/settingsActions/settingsActions";
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { CustomToolbar, escapeRegExp } from "../../../../common/tableUtils";
import { Delete } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#171725",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight: "24px",
    letter: "0.1px",
  },
}));

const actions = {
  fetchAllTemplates,
  disableTemplate,
  enableTemplate,
  deleteTemplate,
};

const mapStateToProps = (state) => {
  let templates = [];

  if (
    state.adminSettings.allTemplates &&
    state.adminSettings.allTemplates.length > 0
  ) {
    templates = state.adminSettings.allTemplates;
  }

  return {
    templates,
  };
};

const AdminTemplates = ({
  fetchAllTemplates,
  disableTemplate,
  enableTemplate,
  deleteTemplate,
  templates,
}) => {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [templateId, setTemplateId] = useState(null);

  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      "e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    );
  }, []);

  useEffect(() => {
    fetchAllTemplates();
  }, [fetchAllTemplates]);

  useEffect(() => {
    const arr = templates.map((template, index) => ({
      ...template,
      serialNo: index + 1,
      createdAt:
        template.createdAt &&
        format(parseISO(template.createdAt), "EEE MMM do y"),
    }));
    setRows(arr);
    if (searchText) {
      setSearchText(searchText);
      const searchRegex = new RegExp(escapeRegExp(searchText), "i");
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
    }
  }, [templates]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filtered = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };

  const memoizedColumns = useMemo(() => {
    return [
      {
        field: "serialNo",
        headerName: "Id",
        width: 80,
        renderCell: (param) => (
          <span
            style={
              param.row.templateActive
                ? { color: theme.palette.success.main }
                : { color: theme.palette.error.main }
            }
          >
            {param.value}
          </span>
        ),
      },
      {
        field: "templateName",
        headerName: "Template Name",
        flex: 2,
        valueGetter: (param) => param.value || "N/A",
        renderCell: (param) => (
          <span
            style={
              param.row.templateActive
                ? { color: theme.palette.success.main }
                : { color: theme.palette.error.main }
            }
          >
            {param.value}
          </span>
        ),
      }, {
        field: "templateSubject",
        headerName: "Subject",
        flex: 2,
        valueGetter: (param) => param.value || "N/A",
        renderCell: (param) => (
          <span
            style={
              param.row.templateActive
                ? { color: theme.palette.success.main }
                : { color: theme.palette.error.main }
            }
          >
            {param.value}
          </span>
        ),
      },
      {
        field: "templateCategory",
        headerName: "Template Type",
        flex: 1.5,
        valueGetter: (param) => param.value || "N/A",
        renderCell: (param) => (
          <span
            style={
              param.row.templateActive
                ? { color: theme.palette.success.main }
                : { color: theme.palette.error.main }
            }
          >
            {param.value}
          </span>
        ),
      },
      {
        field: "createdAt",
        headerName: "Created At",
        flex: 1,
        valueGetter: (param) => param.value || "N/A",
        renderCell: (param) => (
          <span
            style={
              param.row.templateActive
                ? { color: theme.palette.success.main }
                : { color: theme.palette.error.main }
            }
          >
            {param.value}
          </span>
        ),
      },
      {
        field: "",
        headerName: "Actions",
        flex: 1.3,
        renderCell: (param) => (
          <Box display="flex" justifyContent="space-evenly" width="100%">
            <Button
              style={{
                marginLeft: "0.5em",
                backgroundColor: theme.palette.info.main,
                color: "white",
              }}
              variant={"contained"}
              size={"small"}
              onClick={() =>
                history.push(
                  `/admin/settings/templates/edit?id=${param.row.id}`
                )
              }
            >
              Edit
            </Button>
            {param.row.templateActive && (
              <Button
                style={{
                  marginLeft: "0.5em",
                  backgroundColor: theme.palette.error.main,
                  color: "white",
                }}
                variant={"contained"}
                size={"small"}
                onClick={() => disableTemplate(param.row.id)}
              >
                Disable
              </Button>
            )}
            {!param.row.templateActive && (
              <Button
                style={{
                  marginLeft: "0.5em",
                  backgroundColor: "green",
                  color: "white",
                }}
                variant={"contained"}
                size={"small"}
                onClick={() =>
                  enableTemplate(param.row.id, param.row.templateCategory)
                }
              >
                Enable
              </Button>
            )}
            <IconButton
              color="secondary"
              size="small"
              onClick={() => setTemplateId(param.row.id)}
            >
              <Delete />
            </IconButton>
          </Box>
        ),
      },
    ];
  }, [disableTemplate, enableTemplate, history]);

  const handleDeleteProgram = () => {
    deleteTemplate(templateId);
    setTemplateId(null);
  };

  return (
    <Grid container>
      <Dialog
        open={templateId ? true : false}
        onClose={() => setTemplateId(null)}
      >
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you wish to delete this template?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTemplateId(null)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteProgram} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item md={2}>
        <Sidebar />
      </Grid>
      <Grid
        item
        md={10}
        style={{
          backgroundColor: "#FAFAFB",
          paddingLeft: "41px",
          paddingRight: "41px",
        }}
      >
        <Grid item container direction={"column"} style={{ margin: "2em 0" }}>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className={classes.title}>Email Templates</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/admin/settings/templates/new")}
            >
              Create Template
            </Button>
          </Grid>

          <Grid item style={{ marginTop: "1em" }}>
            <Box height={420} width="100%">
              <DataGridPremium
                columns={memoizedColumns}
                rows={filteredRows}
                disableSelectionOnClick
                pageSize={5}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    hideExport: true,
                    value: searchText,
                    clearSearch: () => requestSearch(""),
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(AdminTemplates);
