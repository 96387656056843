import { createReducer } from "../../../../common/util/reducerUtil";
import {
    FETCH_ALL_INSTITUTIONS,
    FETCH_INSTITUTION
} from "../../../constants/adminConstants/institutionConstants/adminInstitutionConstants";




const initialState = {
    allInstitutions: [],
    institution: [],
};

const getAllInstitutions = (state, payload) => {
    return {
        ...state,
        allInstitutions: payload.institutions,
    };
};

const getInstitution = (state, payload) => {
    return {
        ...state,
        institution: payload.institution,
    };
};




export default createReducer(initialState, {
    [FETCH_ALL_INSTITUTIONS]: getAllInstitutions,
    [FETCH_INSTITUTION]: getInstitution,

});
