import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/analytics';

const firebaseConfigProduction = {
    apiKey: `${process.env.REACT_APP_API_KEY}`,
    authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
    projectId: `${process.env.REACT_APP_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_APP_ID}`,
    measurementId: `${process.env.REACT_APP_MEASUREMENT_ID}`,
};

const firebaseConfigDevelopment = {
    apiKey: `${process.env.REACT_APP_API_KEY_DEV}`,
    authDomain: `${process.env.REACT_APP_AUTH_DOMAIN_DEV}`,
    projectId: `${process.env.REACT_APP_PROJECT_ID_DEV}`,
    storageBucket: `${process.env.REACT_APP_STORAGE_BUCKET_DEV}`,
    messagingSenderId: `${process.env.REACT_APP_MESSAGING_SENDER_ID_DEV}`,
    appId: `${process.env.REACT_APP_APP_ID_DEV}`,
    measurementId: `${process.env.REACT_APP_MEASUREMENT_ID_DEV}`,
};

// const firebaseConfig = {
//     apiKey: `${process.env.REACT_APP_API_KEY}`,
//     authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
//     databaseURL: '',
//     projectId: `${process.env.REACT_APP_PROJECT_ID}`,
//     storageBucket: `${process.env.REACT_APP_STORAGE_BUCKET}`,
//     messagingSenderId: `${process.env.REACT_APP_MESSAGING_SENDER_ID}`,
//     appId: `${process.env.REACT_APP_APP_ID}`
// };

// firebase.initializeApp(firebaseConfig)
firebase.initializeApp(
    process.env.REACT_APP_ENVIRONMENT === 'production'
        ? firebaseConfigProduction
        : firebaseConfigDevelopment
);
firebase.firestore();
firebase.storage();

export const analytics = firebase.analytics();

export default firebase;
