import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import ReviewProgramItem from "./item/ReviewProgramItem";
import axios from "../../config/axios";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  answerText: {
    fontWeight: 600,
  },
}));

const ScholarConsentFormReview = ({ registeredProgram }) => {
  const classes = useStyles();

  const [showLink, setShowLink] = useState(false);
  const [formLink, setFormLink] = useState("");

  useEffect(() => {
    if (registeredProgram) {
      axios
        .get("/documentLinkFunctions/documentDownloadLink", {
          params: {
            docId: registeredProgram.scholarConsentFormDocNo,
          },
        })
        .then((res) => {
          setFormLink(res.data.link);
          setShowLink(true);
          // console.log(res.data.link)
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // {
    //   registeredProgram.scholarConsentFormCompleted &&
    //     axios
    //       .get("/documentLinkFunctions/documentDownloadLink", {
    //         params: {
    //           docId: registeredProgram.scholarConsentFormDocNo,
    //         },
    //       })
    //       .then((res) => {
    //         setFormLink(res.data.link);
    //         setShowLink(true);
    //         // console.log(res.data.link)
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    // }
  }, [registeredProgram]);

  return (
    <Grid
      container
      direction={"column"}
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
    >
      {/*RECOMMENDATIONS*/}
      <Grid item>
        <Typography className={classes.title}>Scholar Consent</Typography>
      </Grid>

      <ReviewProgramItem
        question={"SignNow Link"}
        value={formLink ? formLink : "No Link"}
        showLink={showLink}
        link={true}
      />
    </Grid>
  );
};

export default ScholarConsentFormReview;
