import React, { Fragment, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PaymentOption from './PaymentOption';
import Payment1Form from './Payment1Form';
import Payment2Form from './Payment2Form';
import PaymentBalanceForm from './PaymentBalanceForm';
import { format, parseISO, subYears } from 'date-fns';
import { useEffect } from 'react';
import './PaymentForm.css'

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: '0.4em',
    backgroundColor: '#479ff4',
    color: '#ffffff',
    outline: 'transparent',
    '&:hover': {
      backgroundColor: '#388ddf' || '#479ff4',
      // transform: "scale(0.98)",
      /* Scaling button to 0.98 to its original size */
      // boxShadow: "3px 2px 22px 1px rgba(0, 0, 0, 0.24)",
    },
  },
}));

const PaymentForm = ({
  program,
  registeredProgram,
  registeredProgramId,
  handleCloseForm,
  handleSetForm,
  profile,
  processingFee,
}) => {
  const classes = useStyles();

  const earlyRefundDate = new Date(program?.paymentDeadline);

  const newEarlyRefundDate = earlyRefundDate.setMonth(
    earlyRefundDate.getMonth() - 1
  );
  const [isPaymentWaived, setIsPaymentWaived] = useState(false);

  useEffect(() => {
    setIsPaymentWaived(
      program?.programCost === registeredProgram?.scholarshipAmount ||
        program?.programCost === registeredProgram?.sponsorshipAmount ||
        Number(program?.programCost || 0) ===
          Number(registeredProgram?.scholarshipAmount || 0) +
            Number(registeredProgram?.sponsorshipAmount || 0)
    );
  }, [registeredProgram]);

  const processingFeeAmount = (
    ((Number(program?.programCost) -
      (Number(registeredProgram?.scholarshipAmount || 0) +
        Number(registeredProgram?.sponsorshipAmount || 0))) *
      2.9) /
    100
  ).toFixed(2);

  return (
    <Fragment>
      <Grid item container direction={'column'}>
        <Grid style={{ marginLeft: '15px', marginRight: '10px' }}>
          <Grid item>
            <Typography
              variant={'h6'}
              style={{ fontWeight: 600, marginTop: '1em', marginBottom: '1em' }}
            >
              Payment Information
            </Typography>
          </Grid>
          <Grid item container alignItems={'center'}>
            <Grid item md={12}>
              <Typography variant={'body1'} style={{ fontWeight: 800 }}>
                
              </Typography>
              <Grid item>
                <Typography
                  variant={'h7'}
                  style={{
                    fontWeight: 800,
                    marginTop: '1em',
                    marginBottom: '1em',
                  }}
                >
                  PAYMENT OPTIONS
                </Typography>
                <Grid item className='payment-options-first'>
                  <br></br>
                  {program?.earlyAdmissions == true && (
                    <>
                      <Typography
                        variant={'h8'}
                        style={{
                          fontWeight: 800,
                          marginTop: '1em',
                          marginBottom: '1em',
                        }}
                      >
                        EARLY ACCEPTANCE:
                      </Typography>
                      <p>
                        <br></br> **You will be responsible to pay at least a
                        50% deposit by{' '}
                        {program.earlyAdmittanceDepositDeadline &&
                          format(
                            new Date(program?.earlyAdmittanceDepositDeadline),
                            'MMM dd, yyyy'
                          )}{' '}
                        to hold the scholar's placement, followed by the balance
                        due on{' '}
                        {program.earlyAdmittanceFinalPaymentDeadline &&
                          format(
                            new Date(
                              program?.earlyAdmittanceFinalPaymentDeadline
                            ),
                            'MMM dd, yyyy'
                          )}{' '}
                        by 11:59 pm PST. The deposit is nonrefundable.
                      </p>
                      <br></br>
                      <p>
                        {' '}
                        **The Balance of all Program fees for the{' '}
                        {program?.programName} must be PAID IN FULL on or before{' '}
                        {program.earlyAdmittanceFinalPaymentDeadline &&
                          format(
                            new Date(
                              program?.earlyAdmittanceFinalPaymentDeadline
                            ),
                            'MMM dd, yyyy'
                          )}{' '}
                        by 11:59 pm PST.
                      </p>
                    </>
                  )}

                  <Typography
                    variant={'h8'}
                    style={{
                      fontWeight: 800,
                      marginTop: '1em',
                      marginBottom: '1em',
                    }}
                  >
                    <br></br>
                    GENERAL ACCEPTANCE
                  </Typography>
                  <p>
                    <br></br> **You will be responsible to pay at least a 50%
                    deposit by{' '}
                    {format(new Date(program?.depositDeadline), 'MMM dd, yyyy')}{' '}
                    to hold the scholar's placement, followed by the balance due
                    on{' '}
                    {format(new Date(program?.paymentDeadline), 'MMM dd, yyyy')}{' '}
                    at 11:59pm PST. The deposit is nonrefundable.
                  </p>
                  <br></br>
                  <p>
                    {' '}
                    **The Balance of all Program fees for the{' '}
                    {program?.programName} must be PAID IN FULL on or before{' '}
                    {format(new Date(program?.paymentDeadline), 'MMM dd, yyyy')}{' '}
                    by 11:59 pm PST.
                  </p>
                  <br></br>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              variant={'h7'}
              style={{ fontWeight: 800, marginTop: '1em', marginBottom: '1em' }}
            >
              PAYMENT AND REFUND POLICY
            </Typography>
          </Grid>

          <Grid item container alignItems={'center'}>
            <Grid item md={12}>
              <Typography variant={'body1'}>
                <br></br>
                <p>
                  {' '}
                  PROGRAM FEES: As part of the program fees, tuition includes
                  the entire virtual learning experience and the residential
                  immersive experience. LEAD will provide domestic flight
                  arrangements for the scholars to their respective institution,
                  along with transfers to and from campus. This also includes
                  flights from Puerto Rico. Accommodations and meals are also
                  inclusive of the programming fees for the two weeks in
                  residence.
                </p>
                <br></br>
              </Typography>
            </Grid>
          </Grid>

          <Grid item>
            <Typography
              variant={'h7'}
              style={{ fontWeight: 800, marginTop: '1em', marginBottom: '1em' }}
            >
              PAYMENT METHODS
            </Typography>
          </Grid>

          <Grid item container alignItems={'center'}>
            <Grid item md={12}>
              <Typography variant={'body1'}>
               
                <br></br>
                <p>
                Scholars <b>WILL NOT</b> be allowed to attend the program unless their program fee is paid in full. It is critical     that we receive your payment on time so that we have adequate time to arrange your arrival and departures of flights, assign you a room in the residence halls, and attend to the many other important details that will be required.
                </p>
                <br></br>
                <p style={{ color: '#891010', fontWeight: 600 }}>
                Credit Card Payments can be made by any major credit card or debit card. Payments made by credit card / debit will incur anadditional {processingFee || 2.9}% processing fee․{' '} through our secure online application.{' '}
                </p>
              
                <br></br>
                 <div style={{marginLeft:'40px'}} >
               <p style={{fontWeight:'600'}} ><b>Two payment options:</b></p>
               <br></br>
                <p>
               
                1 – Payment for the {' '}  {program.programName} {' '}  will be accepted in FULL for {' '} ${program.programCost} {' '} on  {format(new Date(program?.depositDeadline), 'MMM dd, yyyy')}, on the deposit date as stated on your Application package.
                </p>
                <br></br>
                <p>
                2 - We require a non-refundable deposit of 50% of the {' '} ${program.programCost} {' '} towards your program fees due on or before {' '} {format(new Date(program?.depositDeadline), 'MMM dd, yyyy')}, to hold your placement; followed by the Balance DUE on or before {' '}{format(new Date(program?.paymentDeadline), 'MMM dd, yyyy')} {' '} at 11:59 PM PT.
 
                  </p></div>
              </Typography>
            </Grid>
          </Grid>
{/*
          <Grid item>
            <Typography
              variant={'h7'}
              style={{ fontWeight: 800, marginTop: '1em', marginBottom: '1em' }}
            >
              REFUND SCHEDULE
            </Typography>
          </Grid>


          <Grid item container alignItems={'center'}>
            <Grid item md={12}>
              <Typography variant={'body1'}>
                <br></br>
                <p>
                  {' '}
                  Withdrawal from a program is effective on the date that
                  written notifications is received by LEAD. LEAD will accept
                  notification by email to ldrake@leadprogram.org. Scholars
                  expelled from the program for inappropriate behavior will not
                  be given any refunds. Reasons for expulsion from the program
                  are referenced in the LEAD Scholar Consent and Pledge Form
                  which is included with the acceptance documents and agreed
                  upon by the scholar and/or legal guardian.
                </p>
              </Typography>
              <Typography
                variant={'body1'}
                style={{
                  fontWeight: 800,
                  paddingLeft: '40px',
                  marginTop: '1em',
                  marginBottom: '1em',
                }}
                className='early-admissions-scholars'
              >
                <p>
                  Early Admissions Scholars (applications submitted ON or BEFORE{' '}
                  {program?.earlyAdmittanceDeadline &&
                    format(
                      new Date(program?.earlyAdmittanceDeadline),
                      'MMMM dd, yyyy'
                    )}
                  ) :
                </p>
              </Typography>
              <Typography
                style={{
                  paddingLeft: '60px',
                  marginTop: '1em',
                  marginBottom: '1em',
                }}
              >
                <p>
                  {/*format(
                    newEarlyRefundDate,
                    "MMM dd, yyyy"
                  )
                  April 1, 2023 - Withdrawal requests received by this date are
                  eligible for a 50% tuition refund. Withdrawal requests
                  received after this date are not eligible for a refund.
                </p>
                <br></br>
              </Typography>
              <Typography
                variant={'body1'}
                style={{
                  fontWeight: 800,
                  paddingLeft: '40px',
                  marginTop: '1em',
                  marginBottom: '1em',
                }}
              >
                <p>
                  Regular Admissions Scholars (applications submitted AFTER{' '}
                  {program?.earlyAdmittanceDeadline &&
                    format(
                      new Date(program?.earlyAdmittanceDeadline),
                      'MMMM dd, yyyy'
                    )}
                  ):
                </p>
              </Typography>
              <Typography
                style={{
                  paddingLeft: '60px',
                  marginTop: '1em',
                  marginBottom: '1em',
                }}
              >
                <p>
                  {format(
                    new Date(program?.paymentDeadline) - 1.382e9,
                    'MMM dd, yyyy'
                  )}{' '}
                  - Withdrawal requests received by this date are eligible for a
                  50% tuition refund. Withdrawal requests received after this
                  date are not eligible for a refund.
                </p>
                <br></br>
              </Typography> 
              <Typography>
                <p>
                  By selecting a payment option, you are confirming you have
                  read, understand and agree to the terms mentioned above.{' '}
                </p>
                <br></br>
              </Typography>
            </Grid>
          </Grid>
          */}

          <Grid item style={{marginTop:'1em'}}>
            <Typography
              variant={'h7'}
              style={{ fontWeight: 800, marginTop: '1em', marginBottom: '1em' }}
            >
              WITHDRAWAL POLICY
            </Typography>
          </Grid>
          <Grid item container alignItems={'center'}>
            <Grid item md={12}>
              <Typography variant={'body1'}>
                <br></br>
             <p style={{fontWeight:'600', color:'#891010'}}><b> General Admission – All Deposits and administrative charges of Program fees are nonrefundable.</b></p>
             <br></br>
             <p>
              Withdrawal from a program is effective on the date that written notification is received by LEADing for Life. A written notification of withdrawal shall be sent to the Program Operations Director, Ms. Twana Walker, at  <b>twalker@leadprogram.org</b></p>
              <br></br>
              <p>
              Scholars expelled from the program for misconduct will not be given any refunds. Reasons for expulsion from the program arereferenced in the LEADing for Life Scholar Consent and Student Conduct Code Form which is included with the acceptancedocuments and agreed upon by the scholar and/or parent, legal guardian.</p>
                
                </Typography>
              </Grid>
            </Grid>
            <Grid item style={{marginTop:'1em'}}>
            <Typography
              variant={'h7'}
              style={{ fontWeight: 800, marginTop: '1em', marginBottom: '1em' }}
            >
              SPONSORED PROGRAM CANCELLATION/MODIFICATIONS
            </Typography>
          </Grid>
          <Grid item container alignItems={'center'}>
            <Grid item md={12}>
              <Typography variant={'body1'}>
                <br></br>
             <p> LEADing for Life and institutions reserve the right to make changes, modifications or substitutions to the program in cases of changes at host site locations or in the interest of the program and its participants.</p>
              <br></br>
          
                </Typography>
              </Grid>
            </Grid>
          <p>
                  If you need assistance with the payment process you may
                  contact the LEAD National Office at <b>info@leadprogram․org</b>
                </p>
                <br></br>
                <br></br>
          <Grid item container alignItems={'center'}>
            <Grid item md={3}>
              <Typography variant={'body1'} style={{ fontWeight: 600 }}>
                Program Fee:
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant={'body1'}>
                ${Number(program?.programCost).toFixed(2)}
              </Typography>
            </Grid>
          </Grid>

          {/*SCHOLAR AMOUNT*/}
          <Grid item container alignItems={'center'}>
            <Grid item md={3}>
              <Typography variant={'body1'} style={{ fontWeight: 600 }}>
                Scholarship Amount:
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant={'body1'}>
                $
                {(
                  Number(registeredProgram?.scholarshipAmount || 0) +
                  Number(registeredProgram?.sponsorshipAmount || 0)
                ).toFixed(2)}
              </Typography>
            </Grid>
          </Grid>

          {/*Processing Fee*/}
          <Grid item container alignItems={'center'}>
            <Grid item md={3}>
              <Typography variant={'body1'} style={{ fontWeight: 600 }}>
                Processing Fee:
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant={'body1'}>{processingFee}%</Typography>
            </Grid>
          </Grid>

          {/*OUTSTANDING AMOUNT*/}
          {registeredProgram?.scholarshipFormDetails &&
            registeredProgram.scholarshipFormDetails.applyForAScholarship ===
              'Yes' && (
              <Grid item container alignItems={'center'}>
                <Grid item md={3}>
                  <Typography variant={'body1'} style={{ fontWeight: 600 }}>
                    Outstanding Amount including fee:
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant={'body1'} style={{ fontWeight: 600 }}>
                    $
                    {registeredProgram?.payment1Amount
                      ? (
                          Number(program?.programCost || 0) -
                          (Number(registeredProgram?.scholarshipAmount || 0) +
                            Number(registeredProgram?.sponsorshipAmount || 0)) -
                          (registeredProgram?.paymentAmount
                            ? Number(registeredProgram?.paymentAmount || 0)
                            : Number('0')) -
                          (registeredProgram?.payment1Amount
                            ? Number(registeredProgram?.payment1Amount || 0)
                            : Number('0')) +
                          Number(processingFeeAmount || 0)
                        ).toFixed(2)
                      : Number(program?.programCost || 0) -
                        (Number(registeredProgram?.scholarshipAmount || 0) +
                          Number(registeredProgram?.sponsorshipAmount || 0)) +
                        Number(processingFeeAmount || 0)}
                  </Typography>
                </Grid>
              </Grid>
            )}

          {/*PAYMENT OPTIONS*/}
          {program.programCost !== registeredProgram.scholarshipAmount && (
            <Grid item style={{ marginTop: '1.5em' }}>
              <Typography variant={'body1'} style={{ fontWeight: 600 }}>
                Select Payment Option Below:
              </Typography>
            </Grid>
          )}

          {isPaymentWaived && (
            <Grid item style={{ marginTop: '1.5em' }}>
              <Typography variant={'body1'} style={{ fontWeight: 600 }}>
                No Payment Required
              </Typography>
            </Grid>
          )}

          {program.programCost !== registeredProgram.scholarshipAmount && (
            <Fragment>
              <Grid
                item
                container
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <PaymentOption
                  type="payment1"
                  paymentName={'Deposit'}
                  split={true}
                  paymentReceived={registeredProgram.payment1Received}
                  registeredProgramId={registeredProgramId}
                  registeredProgram={registeredProgram}
                  program={program}
                  processingFeeAmount={processingFeeAmount}
                  isPaymentWaived={isPaymentWaived}
                />
                <PaymentOption
                  type="payment2"
                  paymentName={'Final Payment'}
                  split={true}
                  paymentReceived={registeredProgram.payment2Received}
                  registeredProgramId={registeredProgramId}
                  registeredProgram={registeredProgram}
                  program={program}
                  disabled={!registeredProgram.payment1Received}
                  processingFeeAmount={processingFeeAmount}
                  isPaymentWaived={isPaymentWaived}
                />
                {!registeredProgram.payment1Received &&
                  !registeredProgram.payment2Received && (
                    <PaymentOption
                      type="paymentBalance"
                      paymentName={'Pay Full Balance'}
                      split={false}
                      paymentReceived={
                        registeredProgram.payment1Received &&
                        registeredProgram.payment2Received
                      }
                      registeredProgramId={registeredProgramId}
                      registeredProgram={registeredProgram}
                      program={program}
                      processingFeeAmount={processingFeeAmount}
                      isPaymentWaived={isPaymentWaived}
                    />
                  )}
              </Grid>

              {/*PAYMENT FORM 1*/}
              {/* {paymentForm1 && (
                            <Payment1Form registeredProgramId={registeredProgramId} registeredProgram={registeredProgram} program={program} profile={profile} programCost={program?.programCost} scholarshipAmount={registeredProgram?.scholarshipAmount} handleCloseForm={handleCloseForm}/>
                        )}
                        {paymentForm2 && (
                            <Payment2Form registeredProgramId={registeredProgramId} registeredProgram={registeredProgram} program={program} profile={profile} programCost={program?.programCost} scholarshipAmount={registeredProgram?.scholarshipAmount} handleCloseForm={handleCloseForm}/>
                        )}
                        {paymentFormBalance && (
                            <PaymentBalanceForm registeredProgramId={registeredProgramId} registeredProgram={registeredProgram} program={program} profile={profile} programCost={program?.programCost} scholarshipAmount={registeredProgram?.scholarshipAmount} handleCloseForm={handleCloseForm}/>
                        )} */}
            </Fragment>
          )}
        </Grid>

        <Grid item container style={{ marginTop: '1em' }}>
          <Grid item className='save-for-later-payment-form'>
            <Button
              variant={'outlined'}
              color={'primary'}
              className={classes.button}
              // type={"submit"}
              // disabled={submitting}
              onClick={() => handleCloseForm()}
            >
              Save for Later
            </Button>

            {registeredProgram.payment1Received &&
              registeredProgram.payment2Received && (
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  className={classes.button}
                  // type={"submit"}
                  // disabled={submitting}
                  onClick={() => {
                    handleCloseForm();
                    handleSetForm('travelInformationForm');
                  }}
                >
                  Continue{' '}
                  <span>
                    <strong> &#8594;</strong>
                  </span>
                </Button>
              )}

            <Button
              variant={'outlined'}
              color={'secondary'}
              className={classes.button}
              onClick={() => handleCloseForm()}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default PaymentForm;
