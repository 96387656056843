import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Grid, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import TaxFormUpload from './TaxFormUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { connect } from 'react-redux';
import {
  deleteTaxForm,
  submitTaxForm,
} from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  formName: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '72px',
    letter: '0.2px',
  },
  formText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  formQuestionText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  button: {
    marginLeft: '0.4em',
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
    },
  },

  continueButton: {
    marginLeft: '0.4em',
    backgroundColor: '#1976d2',
    color: 'white !Important',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
      //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    },
  },
}));

const actions = {
  deleteTaxForm,
  submitTaxForm,
};

const TaxForm = ({
  registeredProgramId,
  registeredProgram,
  deleteTaxForm,
  submitTaxForm,
  handleCloseForm,
  handleSetForm,
  submitting,
  program,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [exit, setExit] = useState(false);

  const programsAllThings = [
    'applicationInterests',
    'personalInformationForm',
    'familyInformationForm',
    'academicInformationForm',
    // 'recommendationsRequired',
    'recommendersForm',
    'extracurricularForm',
    'covidProtocolForm',
    'resumeForm',
    'transcriptForm',
    'shortResponsesForm',
    'scholarshipForm',
    'taxForm',
    'additionalDocumentsForm',
    'confirmationForm',
  ];

  const programAllThingsValue = {
    applicationInterests: 'applicationInterests',
    personalInformationForm: 'personalInformationForm',
    familyInformationForm: 'familyInformationForm',
    academicInformationForm: 'academicInformationForm',
    // recommendationsRequired: 'recommendationsRequired',
    recommendersForm: 'recommendersForm',
    extracurricularForm: 'extracurricularForm',
    covidProtocolForm: 'covidProtocolForm',
    resumeForm: 'resumeForm',
    transcriptForm: 'transcriptForm',
    shortResponsesForm: 'shortResponsesForm',
    scholarshipForm: 'scholarshipForm',
    taxForm: 'taxForm',
    additionalDocumentsForm: 'additionalDocumentsForm',
    confirmationForm: 'confirmationForm',
  };

  let dataToCheck = programsAllThings
    .filter((item) => program[item])
    .map((item) => programAllThingsValue[item]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (exit) handleCloseForm();
  }, [exit, handleCloseForm]);

  const handleDeleteTaxForm = async (fileName) => {
    setDisabled(true);
    try {
      await deleteTaxForm(registeredProgramId, fileName);
      setDisabled(false);
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };

  const handleSubmitTaxForms = async () => {
    setDisabled(true);
    try {
      if (registeredProgram.taxForms?.length > 0) {
        await submitTaxForm(registeredProgramId, true);
        handleCloseForm();
        setDisabled(false);
        const indexOfPersonalInfoForm = dataToCheck.indexOf('taxForm');
        if (
          indexOfPersonalInfoForm !== -1 &&
          indexOfPersonalInfoForm < dataToCheck.length - 1
        ) {
          const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
          handleSetForm(nextForm);
        }
      } else {
        setError('Tax Form is required.');
        setDisabled(false);
      }
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };

  return (
    <Grid item container direction={'column'}>
      <Grid item>
        <Grid item container direction={'column'}>
          <Grid item>
            <Typography className={classes.formText}>
              Upload copies of your family's Federal Tax returns - (Only if you
              are applying for a scholarship)
            </Typography>
            <Typography
              className={classes.formText}
              style={{ marginTop: '5px' }}
            >
              The following tax forms are accepted for the scholarship section
              of the application. Please only upload federal tax return forms,{' '}
              <b>
                (IRS W-2, IRS Form 1099, IRS Form 1040, IRS Form 1040A and IRS
                Form 1040EZ).
              </b>{' '}
              You are NOT required to provide your full tax return packet.
            </Typography>

            <Typography
              className={classes.formText}
              style={{ marginTop: '5px' }}
            >
              <b> NOTE: For your security, please hide/cover your SSN.</b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/*TAX FORM UPLOAD*/}
      <TaxFormUpload registeredProgramId={registeredProgramId} />
      {error && (
        <Typography variant="h6" style={{ color: 'red' }}>
          {error}
        </Typography>
      )}

      {/*CURRENT FORMS*/}

      <Grid
        item
        container
        direction={'column'}
        className={classes.fieldContainer}
        // alignItems={"center"}
      >
        <Grid item>
          <Typography
            className={classes.formText}
            style={{ paddingTop: '10px' }}
          >
            <span style={{ fontWeight: 'bold' }}>
              Please make sure to press UPLOAD Button.
            </span>
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.formQuestionText}>
            Uploaded Forms:
          </Typography>
        </Grid>
        <Grid item>
          <Grid item container direction={'column'}>
            {registeredProgram.taxForms?.map((item) => (
              <Grid item key={item.fileName}>
                <Grid item container alignItems={'center'}>
                  <Grid item>
                    <Typography variant={'body1'}>
                      {item.originalFileName}
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: '1em' }}>
                    <IconButton
                      disabled={disabled}
                      aria-label="delete file"
                      onClick={() => handleDeleteTaxForm(item.fileName)}
                    >
                      <DeleteIcon style={{ color: theme.palette.error.main }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      {/*BUTTONS*/}
      <Grid item style={{ marginLeft: 'auto' }}>
        <Button
          variant={'outlined'}
          color={'primary'}
          className={classes.button}
          type={'submit'}
          disabled={submitting}
          onClick={() => setExit(true)}
        >
          Save for Later
        </Button>

        <Button
          variant={'outlined'}
          color={'primary'}
          className={classes.continueButton}
          type={'submit'}
          onClick={() => handleSubmitTaxForms()}
          disabled={disabled}
        >
          Continue{' '}
          <span>
            <strong> &#8594;</strong>
          </span>
        </Button>

        <Button
          variant={'outlined'}
          color={'secondary'}
          className={classes.button}
          onClick={() => handleCloseForm()}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default connect(null, actions)(TaxForm);
