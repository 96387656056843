import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../../asyncActions/asyncActions";

import {
  fromUnixTime
} from "date-fns";

import {
  FETCH_ALL_APPLICANT_LOGS
} from "../../../constants/adminConstants/logsConstants/logsConstants";

export const fetchAllApplicantLogs = () => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramsQuery = firestore
      .collection("registeredPrograms")
      .where("archive", "in", [true,false]);

    try {
      dispatch(asyncActionStart());
      let query = await registeredProgramsQuery.get();
      let applicantLogs = [];
      let allApplicantLogs = [];

      for (let i = 0; i < query.docs.length; i++) {
        if (query.docs[i].data()?.logs?.length) {
          const programsQuery = firestore
            .collection("programs")
            .doc(`${query.docs[i].data()?.programId}`);

          const programs = await programsQuery.get();

          const logs = query.docs[i].data()?.logs.map((x) => ({
            ...query.docs[i].data(),
            ...x,
            eventName: x.event,
            programName: programs?.data()?.programName,
          }));

          applicantLogs.push(...logs);
        }
      }

      const logsData = applicantLogs
        .sort((a, b) => {
          return (
            new Date(fromUnixTime(b.date)) - new Date(fromUnixTime(a.date))
          );
        })
        .map((x, index) => ({
          ...x,
          id: index + 1,
        }));


      allApplicantLogs.push(...logsData);

      dispatch({
        type: FETCH_ALL_APPLICANT_LOGS,
        payload: { allApplicantLogs },
      });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
