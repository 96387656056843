import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import toast, { Toaster } from 'react-hot-toast';
import { Alert, Dialog, DialogContent, Grid, Stack } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import {
  reduxForm,
  formValueSelector,
  isInvalid,
  getFormValues,
} from 'redux-form';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { combineValidators, isRequired } from 'revalidate';
import ScholarshipAdditionalQuestionsForm from './ScholarshipAdditionalQuestionsForm';
import SelectInputFormOnChangeTrueFalse from '../../../../forms/SelectInputFormOnChangeTrueFalse';
import SelectInputFormOnChangeYesNo from '../../../../forms/SelectInputFormOnChangeYesNo';
import TextAreaForm from '../../../../forms/TextAreaForm';
import {
  submitScholarshipForm,
  updateScholarshipForm,
} from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import './scholarShip.css';
import { closeDialog } from '../../../../../store/actions/dialogActions/dialogActions';
const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: '0.4em',
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
    },
  },

  continueButton: {
    marginLeft: '0.4em',
    backgroundColor: '#1976d2',
    color: 'white !Important',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
      //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    },
  },
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  // questionText: {
  //   fontWeight: 600,
  //   textAlign: "right",
  //   paddingRight: "1em",
  // },
}));

const validate = combineValidators({
  applyForAScholarship: isRequired({ message: 'Required' }),
  receiveFreeOrReducedLunch: isRequired({ message: 'Required' }),
  familySalaryLessThan: isRequired({ message: 'Required' }),
  catastrophicEvent: isRequired({ message: 'Required' }),
  sponsoringConsent: (value) => {
    if (!value) return 'Required';
  },
  scholarLastName: isRequired({ message: 'Required' }),
  scholarFirstName: isRequired({ message: 'Required' }),
  scholarEmail: isRequired({ message: 'Required' }),
  scholarPhoneNumber: isRequired({ message: 'Required' }),
  familyIncome2018: isRequired({ message: 'Required' }),
  familyIncome2019: isRequired({ message: 'Required' }),
  untaxedIncome2019: isRequired({ message: 'Required' }),
  familyMemberInCollege: isRequired({ message: 'Required' }),
  expectedFamilyIncome2020: isRequired({ message: 'Required' }),
  monthlyMortgageRent: isRequired({ message: 'Required' }),
  monthlyUtilities: isRequired({ message: 'Required' }),
  monthlyAutoInsurance: isRequired({ message: 'Required' }),
  monthlyLifeInsurance: isRequired({ message: 'Required' }),
  monthlyHomeInsurance: isRequired({ message: 'Required' }),
  monthlyFood: isRequired({ message: 'Required' }),
  monthlyTransportation: isRequired({ message: 'Required' }),
  medicalExpenses: isRequired({ message: 'Required' }),
  autoLoanDebt: isRequired({ message: 'Required' }),
  homeEquityLoanDebt: isRequired({ message: 'Required' }),
  additionalFamilyDebt: isRequired({ message: 'Required' }),
  monthlyChildrensExpense: isRequired({ message: 'Required' }),
  assistantExplanation: isRequired({ message: 'Required' }),
  estimatedContributionAmount: isRequired({ message: 'Required' }),
  understandRequiredDocuments: (value) => {
    if (!value) return 'Required';
  },
  documentsTrueAndCorrect: (value) => {
    if (!value) return 'Required';
  },
  parentGuardianName: isRequired({ message: 'Required' }),
  scholarshipFormDate: isRequired({ message: 'Required' }),
});

const actions = {
  submitScholarshipForm,
  updateScholarshipForm,
};

const mapStateToProps = (state) => {
  const questionSelector = formValueSelector('scholarshipSelection');
  const fieldValues = questionSelector(
    state,
    'id',
    'applyForAScholarship',
    'receiveFreeOrReducedLunch',
    'familySalaryLessThan',
    'catastrophicEvent',
    'additionalFamilyDebt',
    'monthlyChildrensExpense'
  );
  const invalid = isInvalid('scholarshipSelection');

  return {
    fieldValues,
    invalid,
    initialValues: state.applicantPrograms.registeredProgram[0]
      .scholarshipFormDetails || {
      scholarLastName: state.firebase.profile.lastName,
      scholarFirstName: state.firebase.profile.firstName,
      scholarEmail: state.firebase.profile.email,
      scholarPhoneNumber: state.firebase.profile.phoneNumber
        ? state.firebase.profile.phoneNumber
        : '',
    },
    currentValues: getFormValues('scholarshipSelection')(state),
  };
};

const ScholarshipSelectionForm = ({
  fieldValues,
  invalid,
  registeredProgramId,
  submitScholarshipForm,
  updateScholarshipForm,
  handleCloseForm,
  handleSetForm,
  handleSubmit,
  program,
  // error,
  submitting,
  formTextClass,
  formQuestionClass,
  initialValues,
  change,
  currentValues,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [applyForScholarship, setApplyForScholarship] = useState();
  const [receiveFreeLunch, setReceiveFreeLunch] = useState('');
  const [familySalaryGreater, setFamilySalaryGreater] = useState('');
  const [catastrophicEvent, setCatastrophicEvent] = useState('');
  const [qualifyForScholarship, setQualifyForScholarship] = useState(false);
  const [toContinue, setToContinue] = useState(true);
  console.log(qualifyForScholarship, 'qualifyForScholarship');
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (initialValues.applyForAScholarship === 'Yes')
      setApplyForScholarship(true);
  }, []);
  const handleClose = () => {
    setOpen(false);
    dispatch(closeDialog());
  };
  useEffect(() => {
    if (initialValues.receiveFreeOrReducedLunch)
      setReceiveFreeLunch(initialValues.receiveFreeOrReducedLunch);
    if (initialValues.familySalaryLessThan)
      setFamilySalaryGreater(initialValues.familySalaryLessThan);
    if (initialValues.catastrophicEvent)
      setCatastrophicEvent(initialValues.catastrophicEvent);
  }, [initialValues]);

  useEffect(() => {
    if (
      receiveFreeLunch === 'Yes' &&
      familySalaryGreater === 'Yes' &&
      catastrophicEvent === 'No'
    ) {
      setQualifyForScholarship(true);
    }
    if (
      receiveFreeLunch === 'No' &&
      familySalaryGreater === 'Yes' &&
      catastrophicEvent === 'No'
    ) {
      setQualifyForScholarship(true);
    }
    if (
      receiveFreeLunch === 'Yes' &&
      familySalaryGreater === 'No' &&
      catastrophicEvent === 'Yes'
    ) {
      setQualifyForScholarship(true);
    }
    if (
      receiveFreeLunch === 'No' &&
      familySalaryGreater === 'No' &&
      catastrophicEvent === 'Yes'
    ) {
      setQualifyForScholarship(true);
    }
    if (
      receiveFreeLunch === 'No' &&
      familySalaryGreater === 'Yes' &&
      catastrophicEvent === 'Yes'
    ) {
      setQualifyForScholarship(true);
    }
    if (
      receiveFreeLunch === 'Yes' &&
      familySalaryGreater === 'Yes' &&
      catastrophicEvent === 'Yes'
    ) {
      setQualifyForScholarship(true);
    }
    if (
      receiveFreeLunch === 'Yes' &&
      familySalaryGreater === 'No' &&
      catastrophicEvent === 'No'
    ) {
      setQualifyForScholarship(false);
    }
    if (
      receiveFreeLunch === 'No' &&
      familySalaryGreater === 'No' &&
      catastrophicEvent === 'No'
    ) {
      setQualifyForScholarship(false);
    }
    if (!receiveFreeLunch || !familySalaryGreater || !catastrophicEvent)
      setQualifyForScholarship(false);
  }, [receiveFreeLunch, familySalaryGreater, catastrophicEvent]);

  useEffect(() => {
    if (applyForScholarship === false && change) {
      change('receiveFreeOrReducedLunch', '');
      setReceiveFreeLunch('');
      change('familySalaryLessThan', '');
      setFamilySalaryGreater('');
      change('catastrophicEvent', '');
      setCatastrophicEvent('');
      change('scholarshipAdditionalComments', '');
      change('sponsoringConsent', false);
      change('scholarLastName', '');
      change('scholarFirstName', '');
      change('scholarEmail', '');
      change('scholarPhoneNumber', '');
      change('familyIncome2018', '');
      change('familyIncome2019', '');
      change('untaxedIncome2019', '');
      change('familyMemberInCollege', '');
      change('expectedFamilyIncome2020', '');
      change('monthlyMortgageRent', '');
      change('monthlyUtilities', '');
      change('monthlyAutoInsurance', '');
      change('monthlyLifeInsurance', '');
      change('monthlyHomeInsurance', '');
      change('monthlyTransportation', '');
      change('medicalExpenses', '');
      change('homeEquityLoanDebt', '');
      change('additionalFamilyDebt', '');
      change('additionalDebt', '');
      change('monthlyChildrensExpense', '');
      change('additionalExpense', '');
      change('assistantExplanation', '');
      change('estimatedContributionAmount', '');
      change('understandRequiredDocuments', false);
      change('documentsTrueAndCorrect', false);
      change('parentGuardianName', '');
      change('scholarshipFormDate', '');
      change('monthlyFood', '');
      change('autoLoanDebt', '');
    }
  }, [applyForScholarship, change]);

  const programsAllThings = [
    'applicationInterests',
    'personalInformationForm',
    'familyInformationForm',
    'academicInformationForm',
    // 'recommendationsRequired',
    'recommendersForm',
    'extracurricularForm',
    'covidProtocolForm',
    'resumeForm',
    'transcriptForm',
    'shortResponsesForm',
    'scholarshipForm',
    'taxForm',
    'additionalDocumentsForm',
    'confirmationForm',
  ];

  const programAllThingsValue = {
    applicationInterests: 'applicationInterests',
    personalInformationForm: 'personalInformationForm',
    familyInformationForm: 'familyInformationForm',
    academicInformationForm: 'academicInformationForm',
    // recommendationsRequired: 'recommendationsRequired',
    recommendersForm: 'recommendersForm',
    extracurricularForm: 'extracurricularForm',
    covidProtocolForm: 'covidProtocolForm',
    resumeForm: 'resumeForm',
    transcriptForm: 'transcriptForm',
    shortResponsesForm: 'shortResponsesForm',
    scholarshipForm: 'scholarshipForm',
    taxForm: 'taxForm',
    additionalDocumentsForm: 'additionalDocumentsForm',
    confirmationForm: 'confirmationForm',
  };

  let dataToCheck = programsAllThings
    .filter((item) => program[item])
    .map((item) => programAllThingsValue[item]);

  const handleFormSubmit = async (values) => {
    let continueNow = true;
    if (
      values?.monthlyChildrensExpense === 'Yes' &&
      values?.additionalExpense?.length <= 0
    ) {
      continueNow = false;
    }
    if (
      values?.additionalFamilyDebt === 'Yes' &&
      (values?.additionalDebt?.length <= 0 ||
        values?.additionalDebt == undefined)
    ) {
      continueNow = false;
    }

    if (
      values?.monthlyChildrensExpense === 'Yes' &&
      values?.additionalExpense?.length > 0 &&
      Object.keys(values?.additionalExpense?.[0])?.length == 2 &&
      (values?.additionalExpense?.[0]?.amount == '' ||
        values?.additionalExpense?.[0]?.description == '')
    ) {
      continueNow = false;
    }

    if (
      values?.monthlyChildrensExpense === 'Yes' &&
      values?.additionalExpense?.length > 0 &&
      Object.keys(values?.additionalExpense?.[0])?.length != 2
    ) {
      continueNow = false;
    }

    if (
      values?.additionalFamilyDebt === 'Yes' &&
      values?.additionalDebt?.length > 0 &&
      Object.keys(values?.additionalDebt?.[0])?.length != 2
    ) {
      continueNow = false;
    }

    if (
      values?.additionalFamilyDebt === 'Yes' &&
      values?.additionalDebt?.length > 0 &&
      Object.keys(values?.additionalDebt?.[0])?.length == 2 &&
      (values?.additionalDebt?.[0]?.amount == '' ||
        values?.additionalDebt?.[0]?.description == '')
    ) {
      continueNow = false;
    }

    if (continueNow) {
      try {
        await submitScholarshipForm(registeredProgramId, values);
        handleCloseForm();
        if (fieldValues.applyForAScholarship === 'Yes') {
          if (qualifyForScholarship) {
            handleSetForm('taxForm');
          } else {
            const indexOfPersonalInfoForm =
              dataToCheck.indexOf('scholarshipForm');
            console.log(indexOfPersonalInfoForm, 'indexOfPersonalInfoForm');
            if (
              indexOfPersonalInfoForm !== -1 &&
              indexOfPersonalInfoForm < dataToCheck.length - 1
            ) {
              const nextForm = dataToCheck[indexOfPersonalInfoForm + 2];
              handleSetForm(nextForm);
            }
          }
        }
        if (fieldValues.applyForAScholarship === 'No') {
          const indexOfPersonalInfoForm =
            dataToCheck.indexOf('scholarshipForm');
          console.log(indexOfPersonalInfoForm, 'indexOfPersonalInfoForm');
          if (
            indexOfPersonalInfoForm !== -1 &&
            indexOfPersonalInfoForm < dataToCheck.length - 1
          ) {
            const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
            console.log(nextForm, 'nextForm');
            handleSetForm(nextForm);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const notify = () => toast.success('Progress Autosaved.');
  const handleFormSave = async (values) => {
    try {
      const indexOfPersonalInfoForm = dataToCheck.indexOf('scholarshipForm');

      await updateScholarshipForm(registeredProgramId, values);
      const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];

      handleCloseForm();
    } catch (error) {
      console.log(error);
    }
  };
  const handleFormAutoSave = async (values) => {
    try {
      const indexOfPersonalInfoForm = dataToCheck.indexOf('scholarshipForm');

      await updateScholarshipForm(registeredProgramId, values);
      const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
      notify();
      // handleCloseForm();
    } catch (error) {
      console.log(error);
    }
  };

  const saveInterval = 5 * 60 * 1000;
  useEffect(() => {
    const saveFormInterval = setInterval(() => {
      handleFormAutoSave(currentValues);
      // setOpen(true);
    }, saveInterval);
    return () => clearInterval(saveFormInterval);
  }, [currentValues]);

  return (
    <Grid
      container
      // justify={"center"}
      style={{ marginTop: '1.5em' }}
    >
      <Toaster />
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <form autoComplete={'off'} onSubmit={handleSubmit(handleFormSubmit)}>
          <Grid item container direction={'column'}>
            <Grid item>
              {/*APPLY FOR SCHOLARSHIP FIELD*/}
              <SelectInputFormOnChangeTrueFalse
                fieldName={'applyForAScholarship'}
                question={'Do you wish to apply for a scholarship?'}
                onChange={setApplyForScholarship}
                options={[{ value: '' }, { value: 'Yes' }, { value: 'No' }]}
              />

              {/*FREE LUNCH*/}
              {applyForScholarship && (
                <SelectInputFormOnChangeYesNo
                  fieldName={'receiveFreeOrReducedLunch'}
                  question={'Do you receive free or reduced lunch?'}
                  onChange={setReceiveFreeLunch}
                  options={[{ value: '' }, { value: 'Yes' }, { value: 'No' }]}
                />
              )}

              {/*FAMILY SALARY*/}
              {applyForScholarship && (
                <SelectInputFormOnChangeYesNo
                  fieldName={'familySalaryLessThan'}
                  question={'Is your family salary below $75,000 a year?'}
                  onChange={setFamilySalaryGreater}
                  options={[{ value: '' }, { value: 'Yes' }, { value: 'No' }]}
                />
              )}

              {/*CATASTROPHIC EVENT*/}
              {applyForScholarship && (
                <SelectInputFormOnChangeYesNo
                  fieldName={'catastrophicEvent'}
                  question={
                    'Have you had a catastrophic event in the last 12 months, divorce, job loss, terminal illness or death?'
                  }
                  onChange={setCatastrophicEvent}
                  options={[{ value: '' }, { value: 'Yes' }, { value: 'No' }]}
                />
              )}

              {/*SCHOLARSHIP NOTICE*/}
              {/*{receiveFreeLunch === "No" &&*/}
              {/*  familySalaryGreater === "No" &&*/}
              {/*  catastrophicEvent === "No" && (*/}
              {/*    <Grid item container className={classes.fieldContainer}>*/}
              {/*      <Grid item>*/}
              {/*        <Typography*/}
              {/*          variant={"body1"}*/}
              {/*          style={{*/}
              {/*            color: theme.palette.error.main,*/}
              {/*            fontWeight: 600,*/}
              {/*          }}*/}
              {/*        >*/}
              {/*          You do not qualify for a scholarship*/}
              {/*        </Typography>*/}
              {/*      </Grid>*/}
              {/*    </Grid>*/}
              {/*  )}*/}
              {applyForScholarship && !qualifyForScholarship && (
                <Grid item container className={classes.fieldContainer}>
                  <Grid item>
                    <Typography
                      variant={'body1'}
                      style={{
                        color: theme.palette.error.main,
                        fontWeight: 600,
                      }}
                    >
                      You do not qualify for a scholarship
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {/*ADDITIONAL COMMENTS*/}
              {applyForScholarship && (
                <TextAreaForm
                  fieldName={'scholarshipAdditionalComments'}
                  question={'Please Provide Explanation'}
                  required={catastrophicEvent === 'Yes' ? true : false}
                />
              )}

              {applyForScholarship && qualifyForScholarship && (
                <ScholarshipAdditionalQuestionsForm
                  fieldValues={fieldValues}
                  program={program}
                />
              )}
            </Grid>

            {/*BUTTONS*/}
            {invalid && (
              <Grid item container style={{ marginBottom: '0.5em' }}>
                <Grid item style={{ marginLeft: 'auto' }}>
                  <Typography
                    variant={'subtitle1'}
                    style={{
                      color: theme.palette.error.main,
                      fontWeight: 300,
                    }}
                  >
                    Fill out all required fields.
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid item style={{ marginLeft: 'auto' }}>
              <Button
                variant={'outlined'}
                color={'primary'}
                className={classes.button}
                onClick={() => handleFormSave(currentValues)}
              >
                Save for Later
              </Button>

              <Button
                variant={'outlined'}
                color={'primary'}
                className={classes.continueButton}
                type={'submit'}
                disabled={submitting}
              >
                Continue{' '}
                <span>
                  <strong> &#8594;</strong>
                </span>
              </Button>

              <Button
                variant={'outlined'}
                color={'secondary'}
                className={classes.button}
                onClick={() => handleCloseForm()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: 'scholarshipSelection',
    enableReinitialize: true,
    validate,
  })(ScholarshipSelectionForm)
);
