import React, { useEffect } from "react";
import { useTheme } from "@mui/styles";
import { Grid } from "@mui/material";
import Sidebar from "../../../../ui/sidebar/Sidebar";
import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { fetchInstitution } from "../../../../store/actions/adminActions/institutionActions/adminInstitutionActions";
import Avatar from "@mui/material/Avatar";

const actions = {
  fetchInstitution,
};

const mapStateToProps = (state) => {
  let institution = [];
  let programDetails = [];

  if (
    state.adminInstitutions.institution &&
    state.adminInstitutions.institution.length > 0
  ) {
    institution = state.adminInstitutions.institution[0];
    programDetails = state.adminInstitutions.institution[0].programDetails;
  }

  return {
    institution,
    programDetails,
  };
};

const ViewInstitution = ({ fetchInstitution, institution, programDetails }) => {
  const theme = useTheme();

  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get("id");

  useEffect(() => {
    fetchInstitution(id);
  }, [fetchInstitution, id]);

  return (
    <Grid container>
      <Grid item md={2}>
        <Sidebar />
      </Grid>

      <Grid item md={10} style={{ paddingLeft: "1.5em" }}>
        <Grid item container direction={"column"}>
          <Grid item>
            <Typography variant={"h4"}>
              {institution.institutionName ? institution.institutionName : ""}
            </Typography>
          </Grid>

          <Grid item style={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              style={{
                color: "white",
                backgroundColor: theme.palette.info.main,
              }}
              onClick={() =>
                history.push(`/admin/settings/institutions/edit?id=${id}`)
              }
            >
              Edit
            </Button>
            <Button
              style={{ marginLeft: "0.5em" }}
              variant="contained"
              color="primary"
              onClick={() => history.push("/admin/settings/institutions/")}
            >
              Back To Institutions
            </Button>
          </Grid>

          <Grid item style={{ marginTop: "2em" }}>
            <Grid item container direction={"column"}>
              {/*LOGO*/}
              <Grid item container alignItems={"center"}>
                <Grid item md={2}>
                  <Typography
                    variant={"body1"}
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    Logo:
                  </Typography>
                </Grid>
                <Grid item md={10} style={{ paddingLeft: "1em" }}>
                  <Avatar
                    variant={"square"}
                    src={institution.institutionImageURL}
                    style={{ width: "160px", height: "160px" }}
                  />
                </Grid>
              </Grid>
              {/*NAME*/}
              <Grid
                item
                container
                alignItems={"center"}
                style={{ marginTop: "1.5em" }}
              >
                <Grid item md={2}>
                  <Typography
                    variant={"body1"}
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    Institution Name:
                  </Typography>
                </Grid>
                <Grid item md={10} style={{ paddingLeft: "1em" }}>
                  <Typography variant={"body1"}>
                    {institution.institutionName
                      ? institution.institutionName
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
              {/*ADDRESS 1*/}
              <Grid item container alignItems={"center"}>
                <Grid item md={2}>
                  <Typography
                    variant={"body1"}
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    Address 1:
                  </Typography>
                </Grid>
                <Grid item md={10} style={{ paddingLeft: "1em" }}>
                  <Typography variant={"body1"}>
                    {institution.institutionAddress1
                      ? institution.institutionAddress1
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
              {/*ADDRESS 2*/}
              {institution.institutionAddress2 && (
                <Grid item container alignItems={"center"}>
                  <Grid item md={2}>
                    <Typography
                      variant={"body1"}
                      style={{ textAlign: "right", fontWeight: "bold" }}
                    >
                      Address 2:
                    </Typography>
                  </Grid>
                  <Grid item md={10} style={{ paddingLeft: "1em" }}>
                    <Typography variant={"body1"}>
                      {institution.institutionAddress2
                        ? institution.institutionAddress2
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {/*CITY*/}
              <Grid item container alignItems={"center"}>
                <Grid item md={2}>
                  <Typography
                    variant={"body1"}
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    City:
                  </Typography>
                </Grid>
                <Grid item md={10} style={{ paddingLeft: "1em" }}>
                  <Typography variant={"body1"}>
                    {institution.institutionCity
                      ? institution.institutionCity
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
              {/*STATE*/}
              <Grid item container alignItems={"center"}>
                <Grid item md={2}>
                  <Typography
                    variant={"body1"}
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    State:
                  </Typography>
                </Grid>
                <Grid item md={10} style={{ paddingLeft: "1em" }}>
                  <Typography variant={"body1"}>
                    {institution.institutionState
                      ? institution.institutionState
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
              {/*ZIP*/}
              <Grid item container alignItems={"center"}>
                <Grid item md={2}>
                  <Typography
                    variant={"body1"}
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    Zip Code:
                  </Typography>
                </Grid>
                <Grid item md={10} style={{ paddingLeft: "1em" }}>
                  <Typography variant={"body1"}>
                    {institution.institutionZipCode
                      ? institution.institutionZipCode
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
              {/*PHONE*/}
              {institution.institutionPhone && (
                <Grid item container alignItems={"center"}>
                  <Grid item md={2}>
                    <Typography
                      variant={"body1"}
                      style={{ textAlign: "right", fontWeight: "bold" }}
                    >
                      Phone:
                    </Typography>
                  </Grid>
                  <Grid item md={10} style={{ paddingLeft: "1em" }}>
                    <Typography variant={"body1"}>
                      {institution.institutionPhone
                        ? institution.institutionPhone
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {/*EMAIL*/}
              {institution.institutionEmail && (
                <Grid item container alignItems={"center"}>
                  <Grid item md={2}>
                    <Typography
                      variant={"body1"}
                      style={{ textAlign: "right", fontWeight: "bold" }}
                    >
                      Email:
                    </Typography>
                  </Grid>
                  <Grid item md={10} style={{ paddingLeft: "1em" }}>
                    <Typography variant={"body1"}>
                      {institution.institutionEmail
                        ? institution.institutionEmail
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {/*WEB*/}
              {institution.institutionWeb && (
                <Grid item container alignItems={"center"}>
                  <Grid item md={2}>
                    <Typography
                      variant={"body1"}
                      style={{ textAlign: "right", fontWeight: "bold" }}
                    >
                      Web:
                    </Typography>
                  </Grid>
                  <Grid item md={10} style={{ paddingLeft: "1em" }}>
                    <Typography variant={"body1"}>
                      {institution.institutionWeb
                        ? institution.institutionWeb
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {/*COHORT SIZE*/}
              {institution.institutionCohort && (
                <Grid item container alignItems={"center"}>
                  <Grid item md={2}>
                    <Typography
                      variant={"body1"}
                      style={{ textAlign: "right", fontWeight: "bold" }}
                    >
                      Cohort Size:
                    </Typography>
                  </Grid>
                  <Grid item md={10} style={{ paddingLeft: "1em" }}>
                    <Typography variant={"body1"}>
                      {institution.institutionCohort
                        ? institution.institutionCohort
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {/*NOTES*/}
              {institution.institutionNotes && (
                <Grid item container alignItems={"center"}>
                  <Grid item md={2}>
                    <Typography
                      variant={"body1"}
                      style={{ textAlign: "right", fontWeight: "bold" }}
                    >
                      Notes:
                    </Typography>
                  </Grid>
                  <Grid item md={10} style={{ paddingLeft: "1em" }}>
                    <Typography variant={"body1"}>
                      {institution.institutionNotes
                        ? institution.institutionNotes
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/*PROGRAMS*/}
          <Grid item>
            <Typography variant={"h5"}>Associated Programs</Typography>
          </Grid>
          <Grid item style={{ marginTop: "1em" }}>
            <Grid item container direction={"column"}>
              {programDetails.map((program) => (
                <Grid
                  item
                  container
                  alignItems={"center"}
                  key={program.id}
                  style={{ marginBottom: "0.5em" }}
                >
                  <Grid item>
                    <Typography variant={"body1"}>
                      {program.programName ? program.programName : ""}
                    </Typography>
                  </Grid>
                  <Grid item style={{ paddingLeft: "1.5em" }}>
                    <Typography
                      variant={"body1"}
                      style={{ cursor: "pointer" }}
                      color={"primary"}
                      onClick={() =>
                        history.push(
                          `/admin/program/dashboard?id=${program.id}`
                        )
                      }
                    >
                      View
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(ViewInstitution);
