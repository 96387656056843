import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import {
  formValueSelector,
  getFormValues,
  isInvalid,
  reduxForm,
} from 'redux-form';

import { combineValidators, isRequired } from 'revalidate';

import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import TextInputForm from '../../../../forms/TextInputForm';
import SelectInputForm from '../../../../forms/SelectInputForm';

import StateLookup from '../../../../forms/formLookups/StateLookup';
import {
  submitAcademicInformationForm,
  updateAcademicInformationForm,
} from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import { closeDialog } from '../../../../../store/actions/dialogActions/dialogActions';
import toast, { Toaster } from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: '0.4em',
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
    },
  },

  continueButton: {
    marginLeft: '0.4em',
    backgroundColor: '#1976d2',
    color: 'white !Important',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
      //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    },
  },

  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  formName: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '72px',
    letter: '0.2px',
  },
  formText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  formQuestionText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
}));

const validate = combineValidators({
  highSchoolName: isRequired({ message: 'Required' }),
  highSchoolCity: isRequired({ message: 'Required' }),
  highSchoolState: isRequired({ message: 'Required' }),
  graduationMonthYear: isRequired({ message: 'Required' }),
  nameOfCounselorAdvisor: isRequired({ message: 'Required' }),
  emailAddressOfCounselorAdvisor: isRequired({ message: 'Required' }),
  standardizedTests: isRequired({ message: 'Required' }),
  cumulativeGPA: isRequired({ message: 'Required' }),
});
const arr = {
  highSchoolName: '',
  highSchoolCity: '',
  highSchoolState: '',
  graduationMonthYear: '',
  nameOfCounselorAdvisor: '',
  emailAddressOfCounselorAdvisor: '',
  standardizedTests: '',
  cumulativeGPA: '',
};
const actions = {
  submitAcademicInformationForm,
  updateAcademicInformationForm,
};

const mapStateToProps = (state) => {
  const questionSelector = formValueSelector('academicInformationForm');
  const fieldValues = questionSelector(
    state,
    'id',
    'highSchoolName',
    'standardizedTests'
  );
  const invalid = isInvalid('academicInformationForm');
  return {
    fieldValues,
    invalid,
    initialValues:
      state.applicantPrograms.registeredProgram[0]
        .academicInformationFormDetails,
    currentValues: getFormValues('academicInformationForm')(state),
  };
};

const AcademicInformationForm = ({
  fieldValues,
  invalid,
  registeredProgramId,
  profile,
  submitAcademicInformationForm,
  updateAcademicInformationForm,
  handleCloseForm,
  handleSetForm,
  handleSubmit,
  submitting,
  currentValues,
  program,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const states = StateLookup.states;
  const [open, setOpen] = useState(false);

  const [standardizedScore, setStandardizedScore] = useState(false);
  const [whenWillYouTakeSAT, setWhenWillYouTakeSAT] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setStandardizedScore(
      fieldValues.standardizedTests === 'Yes' ? true : false
    );
    setWhenWillYouTakeSAT(
      fieldValues.standardizedTests === 'No' ? true : false
    );
  }, [fieldValues]);
  const programsAllThings = [
    'applicationInterests',
    'personalInformationForm',
    'familyInformationForm',
    'academicInformationForm',
    // 'recommendationsRequired',
    'recommendersForm',
    'extracurricularForm',
    'covidProtocolForm',
    'resumeForm',
    'transcriptForm',
    'shortResponsesForm',
    'scholarshipForm',
    'taxForm',
    'additionalDocumentsForm',
    'confirmationForm',
  ];

  const programAllThingsValue = {
    applicationInterests: 'applicationInterests',
    personalInformationForm: 'personalInformationForm',
    familyInformationForm: 'familyInformationForm',
    academicInformationForm: 'academicInformationForm',
    // recommendationsRequired: 'recommendationsRequired',
    recommendersForm: 'recommendersForm',
    extracurricularForm: 'extracurricularForm',
    covidProtocolForm: 'covidProtocolForm',
    resumeForm: 'resumeForm',
    transcriptForm: 'transcriptForm',
    shortResponsesForm: 'shortResponsesForm',
    scholarshipForm: 'scholarshipForm',
    taxForm: 'taxForm',
    additionalDocumentsForm: 'additionalDocumentsForm',
    confirmationForm: 'confirmationForm',
  };
  let dataToCheck = programsAllThings
    .filter((item) => program[item])
    .map((item) => programAllThingsValue[item]);
  const dispatch = useDispatch();

  console.log(dataToCheck, 'dataToCheck');
  const handleClose = () => {
    setOpen(false);
    dispatch(closeDialog());
  };
  const handleFormSubmit = async (values) => {
    try {
      await submitAcademicInformationForm(registeredProgramId, values);
      handleCloseForm();

      const indexOfPersonalInfoForm = dataToCheck.indexOf(
        'academicInformationForm'
      );
      if (
        indexOfPersonalInfoForm !== -1 &&
        indexOfPersonalInfoForm < dataToCheck.length - 1
      ) {
        const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
        handleSetForm(nextForm);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormSave = async (values) => {
    if (values == undefined) {
      return '';
    }
    function hasAllKeys(arr, values) {
      for (const key in arr) {
        if (arr?.hasOwnProperty(key) && !values?.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    }
    // Check if all keys from object A exist in object B
    const conditionMet = hasAllKeys(arr, values);
    if (conditionMet) {
      values.allRequiredDocumentFilled = true;
    }
    if (!conditionMet) {
      values.allRequiredDocumentFilled = false;
    }

    try {
      await updateAcademicInformationForm(registeredProgramId, values);
      handleCloseForm();
    } catch (error) {
      console.log(error);
    }
  };
  const notify = () => toast.success('Progress Autosaved.');

  const handleFormAutoSave = async (values) => {
    if (values == undefined) {
      return '';
    }
    function hasAllKeys(arr, values) {
      for (const key in arr) {
        if (arr?.hasOwnProperty(key) && !values?.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    }
    // Check if all keys from object A exist in object B
    const conditionMet = hasAllKeys(arr, values);
    if (conditionMet) {
      values.allRequiredDocumentFilled = true;
    }
    if (!conditionMet) {
      values.allRequiredDocumentFilled = false;
    }

    try {
      await updateAcademicInformationForm(registeredProgramId, values);
      // handleCloseForm();
      notify();
    } catch (error) {
      console.log(error);
    }
  };
  const saveInterval = 5 * 60 * 1000;
  useEffect(() => {
    const saveFormInterval = setInterval(() => {
      handleFormAutoSave(currentValues);
    }, saveInterval);
    return () => clearInterval(saveFormInterval);
  }, [currentValues]);
  return (
    <Fragment>
      <Toaster />

      <form autoComplete={'off'} onSubmit={handleSubmit(handleFormSubmit)}>
        {/*ACADEMIC INFORMATION*/}
        <Fragment>
          <Typography className={classes.formName}>
            Academic Information
          </Typography>

          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid item container direction={'column'}>
                <Grid item>
                  {/*HIGH SCHOOL NAME*/}
                  <TextInputForm
                    fieldName={'highSchoolName'}
                    question={'High School Name:'}
                  />
                  {/*HIGH SCHOOL CITY*/}
                  <TextInputForm
                    fieldName={'highSchoolCity'}
                    question={'High School City:'}
                  />
                  {/*HIGH SCHOOL STATE*/}
                  <SelectInputForm
                    fieldName={'highSchoolState'}
                    question={'High School State:'}
                    options={states}
                    text={true}
                  />

                  {/*GRANDUTION YEAR*/}
                  <TextInputForm
                    fieldName={'graduationMonthYear'}
                    question={'Graduation Month/Year:'}
                  />

                  {/*cumulative GPA*/}
                  <TextInputForm
                    fieldName={'cumulativeGPA'}
                    question={'What is your cumulative GPA?'}
                  />

                  {/*ADVISOR NAME*/}
                  <TextInputForm
                    fieldName={'nameOfCounselorAdvisor'}
                    question={'Name of Counselor/Advisor:'}
                  />
                  {/*ADVISOR EMAIL ADDRESS*/}
                  <TextInputForm
                    fieldName={'emailAddressOfCounselorAdvisor'}
                    question={'Counselor/Advisor Email Address:'}
                  />
                  <SelectInputForm
                    fieldName={'standardizedTests'}
                    question={
                      'Have you taken any of the standardized tests (PSAT, SAT or ACT)? You will be required to upload a copy of your College Board SAT score'
                    }
                    options={[{ value: '' }, { value: 'Yes' }, { value: 'No' }]}
                  />
                  {standardizedScore && (
                    <>
                      <Typography className={classes.formText}>
                        We use the test scores only as an extra assessment for
                        evaluation. Applicants can and should still complete the
                        application without the standardized test scores.
                      </Typography>
                      <TextInputForm
                        fieldName={'standardizedScore'}
                        question={'Score:'}
                      />
                    </>
                  )}
                  {whenWillYouTakeSAT && (
                    <>
                      <Typography className={classes.formText}>
                        We use the test scores only as an extra assessment for
                        evaluation. Applicants can and should still complete the
                        application without the standardized test scores.
                      </Typography>
                      <TextInputForm
                        fieldName={'whenWillYouTakeSAT'}
                        question={'When will you take SAT/ACT?:'}
                        placeholder={true}
                        placeholderText={'MM/YYYY'}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>

        {/*BUTTONS*/}
        <Grid item style={{ marginTop: '1.5em' }}>
          {invalid && (
            <Grid item container style={{ marginBottom: '0.5em' }}>
              <Grid item style={{ marginLeft: 'auto' }}>
                <Typography
                  variant={'subtitle1'}
                  style={{
                    color: theme.palette.error.main,
                    fontWeight: 300,
                  }}
                >
                  Fill out all required fields.
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid item container>
            <Grid item style={{ marginLeft: 'auto' }}>
              {/*<Button*/}
              {/*  variant={"outlined"}*/}
              {/*  color={"primary"}*/}
              {/*  className={classes.button}*/}
              {/*  type={"submit"}*/}
              {/*  disabled={submitting}*/}
              {/*>*/}
              {/*  Save And Next*/}
              {/*</Button>*/}

              <Button
                variant={'outlined'}
                color={'primary'}
                className={classes.button}
                onClick={() => handleFormSave(currentValues)}
              >
                Save for Later
              </Button>

              <Button
                variant={'outlined'}
                color={'primary'}
                className={classes.continueButton}
                type={'submit'}
                disabled={submitting}
              >
                Continue{' '}
                <span>
                  <strong> &#8594;</strong>
                </span>
              </Button>

              <Button
                variant={'outlined'}
                color={'secondary'}
                className={classes.button}
                onClick={() => handleCloseForm()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: 'academicInformationForm',
    enableReinitialize: true,
    validate,
  })(AcademicInformationForm)
);
