import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
    fontWeight: 300,
  },
}));

const TextInput = ({
  input,
  label,
  type,
  toValue,
  variant,
  chooseValue,
  required,
  inputAdornment,
  showinput,
  setShowinput,
  disabled,
  placeholder,
  placeholderText,
  meta: { touched, error },
  ...props
}) => {
  const classes = useStyles();
  // useEffect(() => {
  //   if (input.value === "Other") {
  //     chooseValue(true);
  //   } else {
  //     chooseValue(true);
  //   }
  // }, [input.value == "Other"]);
  // console.log(chooseValue, input.value, "show");

  return (
    <Fragment>
      {console.log(touched, error, 'manish')}
      <TextField
        {...input}
        // value={toValue}

        // defaultValue={value}
        placeholder={placeholder ? placeholderText : null}
        required={required}
        label={label}
        type={type}
        variant={variant}
        fullWidth
        size={'small'}
        disabled={disabled}
        autoComplete
        InputProps={
          inputAdornment
            ? {
                startAdornment: (
                  <InputAdornment position="start">
                    {inputAdornment}
                  </InputAdornment>
                ),
              }
            : null
        }
        inputProps={{
          autocomplete: 'new-password',
          form: {
            autocomplete: 'on',
          },
        }}
        {...props}
      />
      {touched && error && (
        <Typography variant={'subtitle1'} className={classes.error}>
          {error}
        </Typography>
      )}
    </Fragment>
  );
};

export default TextInput;
