import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from '../../assets/footer/LEAD2.svg';
import Typography from '@mui/material/Typography';
import './footer.css';

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    padding: '3%',
    // marginTop: '2rem',
    background: 'linear-gradient(225.34deg, #EA4D97 0%, #0079A9 101.17%)',
  },
  image: {
    width: '181.27px',
    height: '181.27px',
    marginRight: '18%',
    [theme.breakpoints.down('md')]: {
      width: '110px !important',
    },
  },
  address: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '28px',
    letter: '0.2px',
    color: '#FFFFFF',
  },
  menu: {
    color: 'rgba(255,255,255, 0.5)',
  },
  subMenu: {
    gap: '1rem',
    marginTop: '3rem',
    color: theme.palette.common.white,
  },
  emailIssue: {
    color: '#fff',
    marginLeft: '25px',
  },
}));

// const footerRoutes = [
//     {
//         name: 'Pages',
//         items: [
//             {
//                 name: 'Templates',
//             },
//             {
//                 name: 'Price',
//             },
//             {
//                 name: 'Help',
//             },
//             {
//                 name: 'Contact',
//             },
//         ],
//     },
//     {
//         name: 'Demos',
//         items: [
//             {
//                 name: 'Dark theme',
//             },
//             {
//                 name: 'Light theme',
//             },
//             {
//                 name: 'Classic theme',
//             },
//         ],
//     },
//     {
//         name: 'Resources',
//         items: [
//             {
//                 name: 'Documentation',
//             },
//         ],
//     },
// ];

const footerRoutes = [];

const Footer = () => {
  const matches = useMediaQuery('(min-width:530px)');

  const classes = useStyles();
  return (
    <Grid
      item
      container
      className={classes.footerContainer}
      alignItems="center"
      justifyContent="space-evenly"
    >
      <Grid
        item
        container
        sm={5}
        wrap="nowrap"
        alignItems="center"
        className="bottom-section"
      >
        <img
          className={classes.image}
          src={logo}
          alt=""
          style={{ width: '110px !Important' }}
        />
        <Grid item container direction={'column'} className="bottom-part">
          <Grid item>
            <Typography className={classes.address}>
              1075 Peachtree St NE, Suite 2510
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.address}>
              Atlanta, GA 30309
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.address}>
              Phone: (404) 334-3474
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.address}>
              Fax: (215) 753-2495
            </Typography>
          </Grid>
          <Grid>
            <Typography className={classes.button}>
              <a href="https://www.leadprogram.org/">www.leadprogram.org</a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={5}>
        <Grid container wrap="nowrap">
          {footerRoutes.map((route, i) => (
            <Grid
              key={route.name}
              className={classes.menu}
              container
              direction="column"
            >
              <Typography>{route.name}</Typography>
              <Grid className={classes.subMenu}>
                {route.items.map((subRoute) => (
                  <Typography key={subRoute.name}>{subRoute.name}</Typography>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid className="techsupport">
        <Typography className={classes.emailIssue}>
          If you experience any technical issues, you may email{' '}
          <a target="_blank" href="mailto:techsupport@leadprogram.org">
            techsupport@leadprogram.org
          </a>
        </Typography>
      </Grid>
      {/*<Grid item className={classes.footerBarContainer} alignItems={'center'}>*/}
      {/*       </Grid>*/}
    </Grid>
  );
};

export default Footer;
