import React, { useEffect, useMemo, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { format, fromUnixTime } from "date-fns";
import { CustomToolbar, escapeRegExp } from "../../common/tableUtils";
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  item: {
    marginTop: "1rem",
  },
  heading: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  text: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#696974",
  },
  button: {
    marginLeft: "1rem",
  },
}));

const ActivityLogs = ({ logs }) => {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchText, setSearchText] = useState("");

  const classes = useStyles();

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      "e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    );
  }, []);

  useEffect(() => {
    const arr = logs
      ? logs
          .map((log, index) => ({
            ...log,
            serialNo: index + 1,
            id: index + 1,
            date:
              log.date && format(fromUnixTime(log.date), "EEE MMM do y h:mm a"),
          }))
          .sort((a, b) => {
            return (
              new Date(fromUnixTime(b.date)) - new Date(fromUnixTime(a.date))
            );
          })
      : [];
    setRows(arr);
    if (searchText) {
      setSearchText(searchText);
      const searchRegex = new RegExp(escapeRegExp(searchText), "i");
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
    }
  }, [logs]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filtered = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };

  const memoizedColumns = useMemo(() => {
    return [
      {
        field: "serialNo",
        headerName: "Id",
        flex: 1,
      },
      {
        field: "date",
        headerName: "Date",
        flex: 2,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "event",
        headerName: "Details",
        flex: 3,
        valueGetter: (param) => param.value || "N/A",
      },
    ];
  }, []);

  return (
    <Grid container direction="column">
      <Grid className={classes.item} item>
        <Typography className={classes.title}>Activity Logs</Typography>
      </Grid>
      <Grid className={classes.item} item>
        <Box height={320} width="100%" style={{ marginTop: "2em" }}>
          <DataGridPremium
            columns={memoizedColumns}
            rows={filteredRows}
            disableSelectionOnClick
            disableColumnMenu
            rowsPerPageOptions={[]}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                value: searchText,
                clearSearch: () => requestSearch(""),
              },
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ActivityLogs;
