import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Grid, MenuItem } from "@mui/material";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Field, reduxForm } from "redux-form";

import { connect } from "react-redux";
import { combineValidators, isRequired } from "revalidate";
import TextInputForm from "../../forms/TextInputForm";
import KeyboardDateInput from "../../../common/form/KeyboardDateInput";
import SelectInputForm from "../../forms/SelectInputForm";
import StateLookup from "../../forms/formLookups/StateLookup";
import SelectInput from "../../../common/form/SelectInput";
import { useHistory, useLocation } from "react-router-dom";
import { adminUpdateUserProfile } from "../../../store/actions/adminActions/applicantActions/adminApplicantActions";
import { fetchAllAffiliates } from "../../../store/actions/adminActions/affiliateActions/adminAffiliateActions";
import { formValueSelector } from "redux-form";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
  fieldContainer: {
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  fieldWrapper: {
    paddingTop: "0.4em",
    paddingBottom: "0.4em",
  },
  questionText: {
    color: "#696974",
    fontWeight: "600",
    fontFamily: "Roboto",
    fontSize: "16px",
    letter: "0.2px",
    paddingRight: "1em",
  },
}));

const validate = combineValidators({
  firstName: isRequired({ message: "First Name is required" }),
  lastName: isRequired({ message: "Last Name is required" }),
  email: isRequired({ message: "Email is required" }),
  // middleInitial: isRequired({ message: "Middle Initial is required" }),
  mobileNumber: isRequired({ message: "Mobile Number is required" }),
  dob: isRequired({ message: "DOB is required" }),
  gender: isRequired({ message: "Gender is required" }),
  ethnicBackground: isRequired({ message: "Ethnic Background is required" }),
  citizenshipStatus: isRequired({ message: "Citzenship Status is required" }),
  address1: isRequired({ message: "Address 1 is required" }),
  city: isRequired({ message: "City is required" }),
  state: isRequired({ message: "State is required" }),
  zipCode: isRequired({ message: "Zip Code is required" }),
  applicantAccountType: isRequired({ message: "Account Type is required" }),
  affiliateCode: isRequired({ message: "Affiliate Code is required" }),
});

const actions = {
  adminUpdateUserProfile,
  fetchAllAffiliates,
};

const mapStateToProps = (state) => {
  let affiliates = [];

  if (
    state.adminAffiliates.allAffiliates &&
    state.adminAffiliates.allAffiliates.length > 0
  ) {
    affiliates = state.adminAffiliates.allAffiliates;
  }

  const questionSelector = formValueSelector("editApplicantProfile");
  const fieldValues = questionSelector(state, "affiliateId");

  return {
    initialValues: state.adminApplicants.applicant[0],
    affiliates,
    fieldValues,
  };
};

const AdminEditApplicantProfile = ({
  adminUpdateUserProfile,
  updateProfile,
  closeForm,
  handleSubmit,
  submitting,
  affiliates,
  error,
  fetchAllAffiliates,
  fieldValues,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();

  const states = StateLookup.states;

  useEffect(() => {
    fetchAllAffiliates();
  }, [fetchAllAffiliates]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get("id");

  const handleUpdateProfile = async (values) => {
    await adminUpdateUserProfile(id, values);
    history.push("/admin/applicants");
  };

  return (
    <form autoComplete={"off"} onSubmit={handleSubmit(handleUpdateProfile)}>
      {/*INPUT CONTAINER*/}
      <Grid item container style={{ marginTop: "2em" }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {/*NAME*/}
          <TextInputForm fieldName={"firstName"} question={"First Name:"} />
          <TextInputForm fieldName={"lastName"} question={"Last Name:"} />

          <TextInputForm
            fieldName={"middleInitial"}
            question={"Middle Initial:"}
          />

          <TextInputForm fieldName={"email"} question={"Email:"} />
          {/*MOBILE NUMBER*/}
          <TextInputForm
            fieldName={"mobileNumber"}
            question={"Mobile Number:"}
          />
          {/*HOUSE PHONE NUMBER*/}
          <TextInputForm
            fieldName={"housePhoneNumber"}
            question={"House Phone Number:"}
          />

          {/*DOB*/}
          <Grid
            item
            container
            className={classes.fieldContainer}
            alignItems={"center"}
          >
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Grid item container direction={"column"}>
                <Grid item>
                  <Typography
                    variant={"body1"}
                    className={classes.questionText}
                  >
                    Date of Birth
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Field
                name={"dob"}
                component={KeyboardDateInput}
                type={"text"}
                variant={"outlined"}
              />
            </Grid>
          </Grid>

          {/*GENDER*/}
          <SelectInputForm
            fieldName={"gender"}
            question={"Gender"}
            onChange={null}
            options={[
              { value: "" },
              { value: "Male" },
              { value: "Female" },
              {value:'Non-binary'},
            /*  { value: "Prefer not to Disclose" },*/
            ]}
          />

          {/*ETHNIC BACKGROUND*/}

          <SelectInputForm
            fieldName={"ethnicBackground"}
            question={"Ethnic background"}
            onChange={null}
            options={[
              { value: "" },
              { value: "Asian American/Pacific Islander" },
              { value: "Black/African American" },
              { value: "Caucasian" },
              { value: "Latino" },
              { value: "Multi-racial" },
              { value: "Native American" },
              { value: "Other" },
              // { value: "Prefer not to answer" },
            ]}
          />

          {/*CITIZENSHIP STATUS*/}

          <SelectInputForm
            fieldName={"citizenshipStatus"}
            question={"Citizenship status"}
            onChange={null}
            options={[
              { value: "" },
              { value: "US Citizen" },
              { value: "US Permanent resident" },
              { value: "Dual US Citizen" },
            ]}
          />

          {/*ADDRESS*/}
          <TextInputForm fieldName={"address1"} question={"Address 1:"} />
          <TextInputForm fieldName={"address2"} question={"Address 2:"} />
          <TextInputForm fieldName={"city"} question={"City:"} />
          <Grid
            item
            container
            className={classes.fieldContainer}
            alignItems={"center"}
          >
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Grid item container direction={"column"}>
                <Grid item>
                  <Typography
                    variant={"body1"}
                    className={classes.questionText}
                  >
                    State
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Field
                name={"state"}
                component={SelectInput}
                type={"text"}
                variant={"outlined"}
              >
                {states.map((state) => (
                  <MenuItem key={state.value} value={state.text}>
                    {state.text}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>

          <TextInputForm fieldName={"zipCode"} question={"Zip Code:"} />

          <SelectInputForm
            fieldName={"applicantAccountType"}
            question={"Applicant Type"}
            onChange={null}
            options={[
              { value: "" },
              { value: "Alumni" },
              { value: "New Applicant" },
            ]}
          />

          <SelectInputForm
            fieldName={"affiliateId"}
            question={"Affiliate"}
            onChange={null}
            options={[
              {
                value: "noAffiliate",
                text: "No Affiliate",
              },
              ...affiliates.map((affiliate) => {
                return {
                  value: affiliate.id,
                  text: affiliate.affiliateName,
                };
              }),
            ]}
            text
          />

          {/*AFFILIATES CODE*/}
          {fieldValues && fieldValues !== "noAffiliate" && (
            <TextInputForm
              fieldName={"affiliateCode"}
              question={"Affiliate Code"}
            />
          )}

          {error && (
            <Grid item style={{ marginTop: "2em" }}>
              <Typography
                variant={"subtitle1"}
                style={{
                  color: theme.palette.error.main,
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                {error}
              </Typography>
            </Grid>
          )}

          <Grid item container style={{ marginTop: "0.5em" }}>
            <Grid item style={{ marginLeft: "auto" }}>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Update
              </Button>
            </Grid>
            <Grid item>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/admin/applicants/")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "editApplicantProfile",
    enableReinitialize: true,
    validate,
  })(AdminEditApplicantProfile)
);
