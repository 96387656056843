import React, { useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Grid } from "@mui/material";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { formValueSelector, reduxForm } from "redux-form";

import { connect } from "react-redux";
import { combineValidators, isRequired } from "revalidate";
import TextInputForm from "../../forms/TextInputForm";
import SelectInputForm from "../../forms/SelectInputForm";
import { useHistory, useLocation } from "react-router-dom";
import {
  updateAdminAndReviewer,
  updateAdminAndReviewerAuthEmail,
} from "../../../store/actions/adminActions/settingsActions/settingsActions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
  fieldContainer: {
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
  },
  questionText: {
    fontWeight: 600,
    textAlign: "right",
    paddingRight: "1em",
  },
}));

const validate = combineValidators({
  firstName: isRequired({ message: "First Name is required" }),
  lastName: isRequired({ message: "Last Name is required" }),
  email: isRequired({ message: "Email is required" }),
  accountType: isRequired({ message: "Account Type is required" }),
  accountCapacity: isRequired({ message: "Capacity is required" }),
});

const actions = {
  updateAdminAndReviewerAuthEmail,
  updateAdminAndReviewer,
};

const mapStateToProps = (state) => {
  let programs = [];

  const questionSelector = formValueSelector("editApplicantProfile");
  const accountType = questionSelector(state, "accountType");

  if (
    state.adminPrograms.allPrograms &&
    state.adminPrograms.allPrograms.length > 0
  ) {
    programs = state.adminPrograms.allPrograms;
  }

  return {
    programs,
    accountType,
    initialValues: {
      ...state.adminSettings.adminAndReviewer[0],
      programsAssigned: state.adminSettings.adminAndReviewer[0]
        ?.programsAssigned
        ? state.adminSettings.adminAndReviewer[0].programsAssigned
        : [],
    },
  };
};

const AdminEditUserForm = ({
  updateAdminAndReviewerAuthEmail,
  updateAdminAndReviewer,
  handleSubmit,
  submitting,
  programs,
  accountType,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();

  const [error, setError] = useState(null);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get("id");

  const handleUpdateAdminAndReviewer = async (values) => {
    setError("");
    let updateUserQuery = await updateAdminAndReviewerAuthEmail(
      id,
      values.email
    );

    if (updateUserQuery.data === "User Auth Email Updated") {
      await updateAdminAndReviewer(id, values);
      history.push("/admin/settings/users");
    }
    if (
      updateUserQuery.data.message ===
      "The email address is improperly formatted."
    ) {
      setError(updateUserQuery.data.message);
    }

    if (
      updateUserQuery.data.message ===
      "The email address is already in use by another account."
    ) {
      setError(updateUserQuery.data.message);
    }
  };

  return (
    <form
      autoComplete={"off"}
      onSubmit={handleSubmit(handleUpdateAdminAndReviewer)}
    >
      {/*INPUT CONTAINER*/}
      <Grid item container style={{ marginTop: "2em" }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {/*NAME*/}
          <TextInputForm fieldName={"firstName"} question={"First Name:"} />
          <TextInputForm fieldName={"lastName"} question={"Last Name:"} />

          <TextInputForm
            fieldName={"middleInitial"}
            question={"Middle Initial:"}
          />

          <TextInputForm fieldName={"email"} question={"Email:"} />

          <SelectInputForm
            fieldName={"accountType"}
            question={"Account Type"}
            onChange={null}
            value={"Reviewer"}
            options={[
              { value: "" },
              { value: "Reviewer" },
              { value: "Admin" },
              { value: "AdminViewer" },
            ]}
          />

          {accountType && accountType === "AdminViewer" && (
            <SelectInputForm
              fieldName={"programsAssigned"}
              question={"Programs Assigned"}
              onChange={null}
              options={programs.map((program) => ({
                text: program.programName,
                value: program.id,
              }))}
              multiple
              text
            />
          )}

          <SelectInputForm
            fieldName={"accountCapacity"}
            question={"Capacity"}
            onChange={null}
            options={[
              { value: "" },
              { value: 0 },
              { value: 1 },
              { value: 2 },
              { value: 3 },
              { value: 4 },
              { value: 5 },
              { value: 10 },
              { value: 15 },
              { value: 20 },
              { value: 25 },
              { value: 30 },
            ]}
          />

          {error && (
            <Grid item style={{ marginTop: "2em" }}>
              <Typography
                variant={"subtitle1"}
                style={{
                  color: theme.palette.error.main,
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                {error}
              </Typography>
            </Grid>
          )}

          <Grid item container style={{ marginTop: "0.5em" }}>
            <Grid item style={{ marginLeft: "auto" }}>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Update
              </Button>
            </Grid>
            <Grid item>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/admin/settings/users/")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "editApplicantProfile",
    enableReinitialize: true,
    validate,
  })(AdminEditUserForm)
);
