import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import ScholarshipSelectionForm from './ScholarshipSelectionForm';
import './scholarShip.css';

const useStyles = makeStyles((theme) => ({
  formText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  formQuestionText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
}));

const ScholarshipForm = ({
  registeredProgramId,
  program,
  profile,
  handleCloseForm,
  handleSetForm,
}) => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid item container direction={'column'}>
      <Grid item>
        <Grid item container direction={'column'}>
          <Grid item>
            <Typography className={classes.formText}>
              A limited number of scholarships are available for applicants with
              financial need. In order to be considered for a scholarship,
              applicants must meet the requirements to complete the scholarship
              forms on the application.
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: '1em' }}>
            <Typography className={classes.formText}>
              As part of the application process there is an opportunity to
              answer important questions that have been designated qualifiers
              for scholarship consideration. These questions relate to (a)
              whether or not the applicant is eligible and is receiving a
              subsidy for reduced school lunch and (b) is your household income
              below $75,000.00. (c) whether your family experienced a hardship
              (ie. divorce, job loss, terminal illness or death){' '}
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: '1em' }}>
            <Typography className={classes.formText}>
              On the online application, you will only be able to complete the
              Scholarship form if you meet the requirements. If you qualify,
              instructions on how to apply for a scholarship include attaching
              the last two years of the parents tax returns.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/*SCHOLARSHIP FORM*/}
      <ScholarshipSelectionForm
        formTextClass={classes.formText}
        formQuestionClass={classes.formQuestionText}
        registeredProgramId={registeredProgramId}
        handleCloseForm={handleCloseForm}
        handleSetForm={handleSetForm}
        program={program}
      />
    </Grid>
  );
};

export default ScholarshipForm;
