import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@mui/material';
import ReviewProgramItem from './item/ReviewProgramItem';
import axios from '../../config/axios';
import Iframe from 'react-iframe';
import { ExpandMore } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    letterSpacing: '0.1px',
    color: '#171725',
  },
  answerText: {
    fontWeight: 600,
  },
}));

const PersonalRecommendationsFormReview = ({
  recommendersForm,
  recommendersResponseForm,
  registeredProgram,
}) => {
  const classes = useStyles();

  const [showLink, setShowLink] = useState(false);
  const [formLink, setFormLink] = useState('');
  const [post, setPost] = useState([]);
  console.log(formLink, 'formLink');
  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  /* useEffect(() => {
    if (registeredProgram) {
      console.log(registeredProgram.counselorRecommenderFormDocNo, 'DOCID');
    axios.get(`https://api.signnow.com/document/?${registeredProgram.counselorRecommenderFormDocNo}/download?type=zip`).then((data) => {
      console.log(data, 'CDATA');
      setPost(data?.data);
    });
  }
  }, []);*/
  useEffect(() => {
    //documentAttachLinkFunctions/documentAttachLink
    {registeredProgram.counselorRecommenderFormCompleted && ( 
  
      axios
        .get('/documentLinkFunctions/documentDownloadLink', {
          // .get('documentAttachLinkFunctions/documentAttachLink', {
          params: {
            docId: registeredProgram.counselorRecommenderFormDocNo,
          },
        })

        .then((res) => {
          setFormLink(res.data.link);
          console.log(res.data, 'counsler');
          setShowLink(true);
        })
        .catch((err) => {
          console.log(err);
        })

     // console.log(registeredProgram.counselorRecommenderFormDocNo, 'DOCID');
      /*
        axios.get(`https://api.signnow.com/document/${registeredProgram.counselorRecommenderFormDocNo}/download?type=zip`,{headers}).then((data) => {
          console.log(data, 'CDATA');
          setPost(data?.data);
        });*/
  )}
    // {registeredProgram.counselorRecommenderFormCompleted && (
    //     axios.get('/documentLinkFunctions/documentDownloadLink', {
    //         params: {
    //             docId: registeredProgram.counselorRecommenderFormDocNo
    //         }
    //     }).then((res) => {
    //         setFormLink(res.data.link)
    //         setShowLink(true)
    //     }).catch(err => {
    //         console.log(err)
    //     })
    // )}
  }, [registeredProgram?.counselorRecommenderFormDocNo]);
  return (
    <Grid
      container
      direction={'column'}
      style={{ paddingTop: '0.5em', paddingBottom: '0.5em' }}
    >
      <Box>
        <Accordion style={{ borderTop: 
  registeredProgram.counselorRecommenderFormCompleted ? '3px solid #35BA35' :
  registeredProgram.counselorRecommenderFormSent ? '3px solid #FF9900' : '3px solid #CCC'
}}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            style={{ marginTop: '20px' }}
          >
            {/*RECOMMENDATIONS*/}
            <Grid item>
              <Typography className={classes.title}>
                Counselor Recommendation
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            style={{ backgroundColor: '#FAFAFB', flexDirection: 'column' }}
          >
            {/*RECOMMENDER INFO*/}
            {recommendersForm.map((recommender) => (
              registeredProgram.counselorRecommenderFormSent ? (
              <ReviewProgramItem
                key={recommender.id}
                question={'Recommender Information'}
                value={
                  registeredProgram.counselorRecommenderDetails
                    .recommenderTitle +
                  ' ' +
                  registeredProgram.counselorRecommenderDetails
                    .recommenderFirstName +
                  ' ' +
                  registeredProgram.counselorRecommenderDetails
                    .recommenderLastName
                }
                value2={
                  registeredProgram.counselorRecommenderDetails
                    .recommenderEmailAddress
                }
                renderCell={() => (
                  <Grid style={{}}>
                    {/* Your custom render cell content */}
                  
                    <Grid><Typography  variant={'h6'} className={classes.title} style={{color:'#696974', marginBottom:'5px'}}>Recommenders Information </Typography>
                    </Grid>
                    <Grid>
                     <b> Name: </b> { registeredProgram.counselorRecommenderDetails
                    .recommenderTitle +
                  ' ' +
                  registeredProgram.counselorRecommenderDetails
                    .recommenderFirstName +
                  ' ' +
                  registeredProgram.counselorRecommenderDetails
                    .recommenderLastName}
                    </Grid>
                    <Grid>
                    <b> Email:</b> {registeredProgram.counselorRecommenderDetails
                    .recommenderEmailAddress}
                    </Grid>
                  </Grid>
                    )}
              />
              ):  null
            ))}   {/*RESPONSES
            <Grid item>
              <Typography className={classes.title}>Document Link</Typography>
            </Grid>
*/}
{registeredProgram.counselorRecommenderFormCompleted ? (

         
          <><ReviewProgramItem
              question={'SignNow Link'}
               value={formLink}
              showLink={showLink}
              link={true}
              /*  value={
          <a href={formLink} target={'_blank'} rel={'noopener noreferrer'}>
            Open in New Tab
          </a>
        }*/
             
            />

          {/*  {post.map((item, i) => {
              return (
                <div key={i}>
                 // <p>{item?.name}</p>
           // <a href={formLink}>Download attachment</a>
                  {console.log(item, 'ITEM')}
                </div>
              );
            })}
*/}
         
      <Iframe
        url={'https://app.signnow.com/webapp/document/' + registeredProgram?.counselorRecommenderFormDocNo}
        width="100%"
        height="800px"
        id=""
        className=""
        display="block"
        position="relative"
      /></>
      ):  "Counselor Recommendation not recieved"
          }
          </AccordionDetails>
        </Accordion>
      </Box>
    </Grid>
  );
};

export default PersonalRecommendationsFormReview;
