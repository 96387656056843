import { ASYNC_ACTION_ERROR, ASYNC_ACTION_FINISH, ASYNC_ACTION_START } from "../../constants/asyncConstants/asyncConstants";
import { createReducer } from "../../../common/util/reducerUtil";

const initialState = {
    loading: false,
    loadingArr: [],
    elementName: null
};

const asyncActionStarted = (state, payload) => {
    const newArr = state.loadingArr;
    newArr.push(true);
    return {
        ...state,
        loading: true,
        loadingArr: newArr,
        elementName: payload
    };
};

const asyncActionFinished = (state) => {
    const newArr = state.loadingArr;
    newArr.pop();
    return {
        ...state,
        loading: newArr.length > 0 ? true : false,
        loadingArr: newArr,
        elementName: null
    };
};

const asyncActionError = state => {
    const newArr = state.loadingArr;
    newArr.pop();
    return {
        ...state,
        loading: newArr.length > 0 ? true : false,
        loadingArr: newArr,
        elementName: null
    };
};

export default createReducer(initialState, {
    [ASYNC_ACTION_START]: asyncActionStarted,
    [ASYNC_ACTION_FINISH]: asyncActionFinished,
    [ASYNC_ACTION_ERROR]: asyncActionError
})
