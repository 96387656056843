import React, { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid, Box } from '@mui/material';
import Sidebar from '../../../ui/sidebar/Sidebar';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import DashboardApplicantsTable from '../../../ui/admin/dashboard/DashboardApplicantsTable';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { reduxForm } from 'redux-form';
import { combineValidators } from 'revalidate';
import { isRequired } from 'revalidate';
import { sendGenericEmail } from '../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import SendEmailDialog from '../../../dialogs/admin/SendEmailDialog';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchProgramDashboardValues } from '../../../store/actions/adminActions/programActions/adminProgramActions';
import SendEmailDialogs from '../../../dialogs/admin/SendEmailDialogs';
const mapStateToProps = (state) => {
  let program = {};
  let applicants = [];

  if (
    state.adminPrograms.adminProgram &&
    state.adminPrograms.adminProgram.length > 0
  ) {
    program = state.adminPrograms.adminProgram[0];
  }

  if (state.adminPrograms.programDashboardValues) {
    if (state.adminPrograms.programDashboardValues.tableData) {
      if (
        state.adminPrograms.programDashboardValues.tableData.progressApplicants
          .length > 0
      ) {
        applicants =
          state.adminPrograms.programDashboardValues.tableData
            ?.progressApplicants;
      }
    }
  }

  return {
    program,
    applicants,
  };
};

const validate = combineValidators({
  subject: isRequired({ message: 'Required' }),
  content: isRequired({ message: 'Required' }),
  to: isRequired({ message: 'Required' }),
});

const AdminViewProgramDashboardInProgressApplicants = ({
  program,
  applicants,
  submitting,
  handleSubmit,
  sendGenericEmail,
  change,
  profile,
  fetchProgramDashboardValues,
}) => {
  const matches = useMediaQuery('(min-width:480px)');
  const history = useHistory();
  const [selectionModel, setSelectionModel] = useState([]);
  const [editorValue, setEditorValue] = useState('');
  const [dialog, setDialog] = useState(false);
  const [selectedApplicantRowIds, setSelectedApplicantRowIds] = useState([]);
  const [reload, setReload] = useState(false);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let queryParams = useQuery();
  const id = queryParams.get('id');
  const handleSelectionModelChange = (selectedIds) => {
    setSelectedApplicantRowIds(selectedIds);
  };
  const handleReloadApplicants = async () => {
    await fetchProgramDashboardValues(id);
    setReload((prev) => (prev = !prev));
  };
  useEffect(() => {
    handleReloadApplicants();
  }, []);

  useEffect(() => {
    const emails = selectionModel
      .map((id) => {
        if (applicants[id - 1]) return applicants[id - 1].email;
        return '';
      })
      .filter((email) => email && email);
    let updatedTo = '';
    emails.forEach((email) => (updatedTo += `${email},`));
    change('to', updatedTo);
  }, [selectionModel, applicants]);

  const handleSendEmail = async (values) => {
    const emails = [];
    if (values.to)
      values.to.split(',').forEach((email) => email && emails.push(email));
    if (emails.length > 0) {
      await sendGenericEmail(
        values.subject,
        editorValue,
        emails,
        profile.fullName
      );
    }
    setDialog(false);
  };

  return (
    <Grid container>
      <Grid item md={2} style={{ width: `${matches ? '' : '100%'}` }}>
        <Sidebar />
      </Grid>
      <Grid item md={10} style={{ paddingLeft: '1.5em' }}>
        <Grid item container direction={'column'}>
          <Grid item>
            <Typography variant={'h4'}>
              {program.programName ? `${program.programName}` : ''}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ margin: '0.5em 0' }}
          >
            <Typography variant={'h4'}>Total In Progress Applicants</Typography>
            <Box display="flex" gridGap={16}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setDialog(true)}
              >
                Send Email
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => history.push('/admin/dashboard/emailLogs')}
              >
                Email History
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  history.push(`/admin/program/dashboard?id=${program.id}`)
                }
              >
                Program Dashboard
              </Button>
            </Box>
          </Grid>
          <DashboardApplicantsTable
            program={program}
            applicants={applicants}
            checkboxSelection
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            onSelectionModelChange={handleSelectionModelChange}
            reloadApplicants={handleReloadApplicants}
          />
          <SendEmailDialogs
            isOpen={dialog}
            closeDialog={() => setDialog(false)}
            handleSubmit={handleSubmit}
            submitting={submitting}
            handleSendEmail={handleSendEmail}
            editorValue={editorValue}
            setEditorValue={setEditorValue}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, {
  sendGenericEmail,
  fetchProgramDashboardValues,
})(
  reduxForm({ form: 'sendEmail', validate })(
    AdminViewProgramDashboardInProgressApplicants
  )
);
