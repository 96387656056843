import React, { Fragment, useState } from "react";
import { makeStyles } from "@mui/styles";
import TextInput from "../../../common/form/TextInput";
import { Field, reduxForm } from "redux-form";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import { connect } from "react-redux";
import {
  login,
  resetPassword,
} from "../../../store/actions/authActions/authActions";
import Typography from "@mui/material/Typography";

const actions = {
  login,
  resetPassword,
};

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    paddingLeft: "0.7em",
    paddingRight: "0.7em",
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 500,
  },
  button: {
    width: '100%',
    height: '38px',
    borderRadius: '10px',
    backgroundColor: '#35BA35',
    color: 'white',
    fontWeight: 700,
    borderRadius: "50.25rem",
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#35BA35',
      color: 'white',
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: 1,
      fontSize:"12px",
    },
  },
}));

const LoginForm = ({
  login,
  resetPassword,
  handleSubmit,
  error,
  submitting,
}) => {
  const classes = useStyles();

  const [forgotPassword, setForgotPassword] = useState(false);

  return (
    <Fragment>
      {!forgotPassword && (
        <form autoComplete={"off"} onSubmit={handleSubmit(login)}>
          <Grid
            container
            direction={"column"}
            className={classes.loginContainer}
          >
            <Grid item style={{ marginTop: "1em" }}>
              <Field
                label={"Email"}
                name={"email"}
                type={"text"}
                variant={"outlined"}
                component={TextInput}
              />
            </Grid>

            <Grid item style={{ marginTop: "1.5em" }}>
              <Field
                label={"Password"}
                name={"password"}
                type={"password"}
                variant={"outlined"}
                component={TextInput}
              />
            </Grid>

            {error && (
              <Grid item style={{ marginTop: "0.5em" }}>
                <Typography variant={"subtitle1"} className={classes.error}>
                  {error}
                </Typography>
              </Grid>
            )}

            <Grid item style={{ marginTop: "1.2em", marginBottom: "1em" }}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                type={"submit"}
                disabled={submitting}
                className={classes.button}
              >
                Login
              </Button>
            </Grid>
            <Grid item>
              <Grid item container direction={"column"} alignItems={"center"}>
                <Grid item>
                  <Typography
                    variant={"caption"}
                    onClick={() => setForgotPassword(true)}
                    style={{ cursor: "pointer" }}
                  >
                    Forgot Password?
                  </Typography>
                </Grid>
                
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
      {forgotPassword && (
        <form autoComplete={"off"} onSubmit={handleSubmit(resetPassword)}>
          <Grid
            container
            direction={"column"}
            className={classes.loginContainer}
          >
            <Grid item style={{ marginTop: "1em" }}>
              <Field
                label={"Email"}
                name={"email"}
                type={"text"}
                variant={"outlined"}
                component={TextInput}
              />
            </Grid>

            {error && (
              <Grid item style={{ marginTop: "0.5em" }}>
                <Typography variant={"subtitle1"} className={classes.error}>
                  {error}
                </Typography>
              </Grid>
            )}

            <Grid item style={{ marginTop: "1.2em", marginBottom: "1em" }}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                type={"submit"}
                disabled={submitting}
              >
                Reset Password
              </Button>
            </Grid>
            <Grid item>
              <Grid item container direction={"column"} alignItems={"center"}>
                <Grid item>
                  <Typography
                    variant={"caption"}
                    onClick={() => setForgotPassword(false)}
                    style={{ cursor: "pointer" }}
                  >
                    Login?
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Fragment>
  );
};

export default connect(
  null,
  actions
)(reduxForm({ form: "loginForm" })(LoginForm));
