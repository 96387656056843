import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Grid,
  Button,
  Box,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import Sidebar from '../../../ui/sidebar/Sidebar';
import Typography from '@mui/material/Typography';
import SidebarBox from '../../../ui/sidebar/SidebarBox';
import TextInput from '../../../common/form/TextInput';
import Chart from 'react-google-charts';
import NeedsReviewTable from '../../../ui/admin/dashboard/NeedsReviewTable';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  fetchAdminProgram,
  fetchAllProgramCompletedApplications,
  // fetchAllProgramReviewedApplications,
  fetchProgramDashboardValues,
  closeAdminProgram,
  deleteAdminProgram,
  duplicateAdminProgram,
} from '../../../store/actions/adminActions/programActions/adminProgramActions';
import ReviewedTable from '../../../ui/admin/dashboard/ReviewedTable';
import ScholarshipTable from '../../../ui/admin/dashboard/ScholarshipTable';
import WithdrawnTable from '../../../ui/admin/dashboard/WithdrawnTable';
import { useHistory } from 'react-router-dom';
import {
  updateProgramStage,
  updateChosenInstitution,
  updateChosenInstitution2,
  handleProgramRollback,
} from '../../../store/actions/reviewActions/reviewActions';
import {
  toggleEarlyAdmision,
  toggleGrantScholarship,
  toggleGrantSponsorship,
  updateScholarshipAmount,
  updateSponsorshipAmount,
} from '../../../store/actions/adminActions/dashboardActions/dashboardActions';
import { fetchAllApplications } from '../../../store/actions/adminActions/institutionActions/adminInstitutionActions';
import { fromUnixTime } from 'date-fns';
import { sendApplicationNotificationApproved } from '../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import { ExpandMore } from '@mui/icons-material';
import { Field, reduxForm } from 'redux-form';
import { combineValidators, isRequired } from 'revalidate';
import DashboardRevenueTable from '../../../ui/admin/dashboard/DashboardRevenueTable';
import SponsorshipTable from '../../../ui/admin/dashboard/SponsorshipTable';

const useStyles = makeStyles((theme) => ({
  stateContainer: {
    backgroundColor: 'white',
    borderRadius: '8px',
    cursor: 'pointer',
    boxShadow:
      'rgb(95 116 141 / 3%) 0px 2px 1px -1px, rgb(95 116 141 / 4%) 0px 1px 1px 0px, rgb(95 116 141 / 8%) 0px 1px 3px 0px',
    paddingTop: '28px',
    paddingBottom: '28px',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  programName: {
    color: '#171725',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '36px',
    letter: '0.1px',
  },
  title: {
    color: '#171725',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '16px',
    lineHeight: '24px',
    letter: '0.1px',
    marginBottom: '1rem',
  },
  stateTitle: {
    color: '#44444F',
    fontWeight: '500',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
    letter: '0.1px',
  },
  stateItem: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
    letter: '0.1px',
  },
}));

const actions = {
  fetchAdminProgram,
  // fetchAllApplications,
  fetchProgramDashboardValues,
  fetchAllProgramCompletedApplications,
  // fetchAllProgramReviewedApplications,
  closeAdminProgram,
  deleteAdminProgram,
  updateProgramStage,
  updateChosenInstitution,
  updateChosenInstitution2,
  handleProgramRollback,
  toggleGrantScholarship,
  toggleGrantSponsorship,
  toggleEarlyAdmision,
  updateScholarshipAmount,
  updateSponsorshipAmount,
  duplicateAdminProgram,
  sendApplicationNotificationApproved,
};

const mapStateToProps = (state) => {
  let program = {};
  let programCompletedApplications = [];
  let programReviewedApplications = [];
  let programDashboardValues = {};
  let programGeographyValues = [];
  let scholarshipApplications = [];
  let sponsorshipApplications = [];
  let withdrawnApplications = [];
  let revenue = [];
  let sponsorhips = [];
  let institutions = [];
  console.log(state, 'state');
  if (
    state.adminPrograms.adminProgram &&
    state.adminPrograms.adminProgram.length > 0
  ) {
    program = state.adminPrograms.adminProgram[0];
  }

  if (
    state.adminPrograms.allProgramCompletedApplications &&
    state.adminPrograms.allProgramCompletedApplications.length > 0
  ) {
    programCompletedApplications =
      state.adminPrograms.allProgramCompletedApplications;
  }
  if (
    state.adminPrograms.allProgramReviewedApplications &&
    state.adminPrograms.allProgramReviewedApplications.length > 0
  ) {
    programReviewedApplications =
      state.adminPrograms.allProgramReviewedApplications;
  }

  if (state.adminPrograms.programDashboardValues) {
    programDashboardValues = state.adminPrograms.programDashboardValues;

    if (programDashboardValues?.institutions?.length) {
      institutions = programDashboardValues.institutions;
    }

    if (
      state.adminPrograms.programDashboardValues.applicantGeographyValues &&
      state.adminPrograms.programDashboardValues.applicantGeographyValues
        .length > 0
    ) {
      programGeographyValues =
        state.adminPrograms.programDashboardValues.applicantGeographyValues;
    }
  }

  if (state.adminPrograms.programDashboardValues.tableData) {
    if (
      state.adminPrograms.programDashboardValues.tableData
        .scholarshipApplications
    ) {
      scholarshipApplications =
        state.adminPrograms.programDashboardValues.tableData
          .scholarshipApplications;
    }
    if (
      state.adminPrograms.programDashboardValues.tableData
        .sponsorshipApplications
    ) {
      sponsorshipApplications =
        state.adminPrograms.programDashboardValues.tableData
          .sponsorshipApplications;
    }
    if (state.adminPrograms.programDashboardValues.tableData.withdrawn) {
      withdrawnApplications =
        state.adminPrograms.programDashboardValues.tableData.withdrawn;
    }
    if (state.adminPrograms.programDashboardValues.tableData.sponsorhips) {
      sponsorhips =
        state.adminPrograms.programDashboardValues.tableData.sponsorhips;
    }
  }

  if (state.adminPrograms.programDashboardValues) {
    if (state.adminPrograms.programDashboardValues.tableData) {
      if (
        state.adminPrograms.programDashboardValues.tableData.revenue.length > 0
      ) {
        revenue = state.adminPrograms.programDashboardValues.tableData?.revenue;
      }
    }
  }
  console.log(institutions, 'poi');

  return {
    program,
    programCompletedApplications,
    programReviewedApplications,
    programDashboardValues,
    programGeographyValues,
    profile: state.firebase.profile,
    scholarshipApplications,
    sponsorshipApplications,
    withdrawnApplications,
    revenue,
    sponsorhips,
    institutions,
  };
};

const validate = combineValidators({
  scholarshipRejectReason: isRequired({ message: 'Required' }),
});

const AdminViewProgramDashboard = ({
  fetchAdminProgram,
  // fetchAllApplications,

  fetchProgramDashboardValues,
  fetchAllProgramCompletedApplications,
  // fetchAllProgramReviewedApplications,
  program,
  programCompletedApplications,
  programReviewedApplications,
  programDashboardValues,
  programGeographyValues,
  profile,
  scholarshipApplications,
  sponsorshipApplications,
  withdrawnApplications,
  closeAdminProgram,
  deleteAdminProgram,
  updateProgramStage,
  updateChosenInstitution,
  updateChosenInstitution2,
  handleProgramRollback,
  toggleGrantScholarship,
  toggleGrantSponsorship,
  toggleEarlyAdmision,
  updateScholarshipAmount,
  updateSponsorshipAmount,
  duplicateAdminProgram,
  sendApplicationNotificationApproved,
  handleSubmit,
  submitting,
  revenue,
  sponsorhips,
  institutions,
}) => {
  const matchesSide = useMediaQuery('(min-width:480px)');
  const classes = useStyles();
  const theme = useTheme();
  console.log(updateChosenInstitution, 'updateChosenInstitution');
  const history = useHistory();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [programId, setProgramId] = useState(null);
  const [scholarshipRejectId, setScholarshiprejectId] = useState(null);
  const [earlyAdmissionRejectId, setearlyAdmissionRejectId] = useState(null);

  const [refreshTable, setRefreshTable] = useState(false);
  console.log({ programDashboardValues });
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get('id');
  console.log({ withdrawnApplications });
  console.log(program, 'adminprogram');
  useEffect(() => {
    fetchAdminProgram(id);
    fetchProgramDashboardValues(id);
    fetchAllProgramCompletedApplications(id);
    // fetchAllProgramReviewedApplications(id);
    // fetchAllApplications();
  }, [
    fetchAdminProgram,
    fetchProgramDashboardValues,
    fetchAllProgramCompletedApplications,
    // fetchAllProgramReviewedApplications,
    // fetchAllApplications,
    id,
    isReload,
  ]);

  useEffect(() => {
    fetchProgramDashboardValues(id);
    // fetchAllApplications();
  }, [fetchProgramDashboardValues, refreshTable]);

  const handleDeleteProgram = async () => {
    await deleteAdminProgram(programId);
    setDialogOpen(false);
    history.push('/admin/program');
  };
  // console.log(fetchAllApplications, 'fetchAllApplications');
  const handleUpdateProgramStage = async (
    uid,
    programStage,
    applicationValue,
    declineComment = null
  ) => {
    await updateProgramStage(
      uid,
      programStage,
      applicationValue,
      declineComment
    );
    setTimeout(() => fetchProgramDashboardValues(id), 100);
  };

  const handleUpdateChosenInstitution = async (uid, chosenInstitution) => {
    console.log(uid, chosenInstitution);
    await updateChosenInstitution(uid, chosenInstitution);
    setTimeout(() => fetchProgramDashboardValues(id), 100);
  };
  const handleUpdateChosenInstitution2 = async (uid, chosenInstitution) => {
    console.log(uid, chosenInstitution);
    await updateChosenInstitution2(uid, chosenInstitution);
    setTimeout(() => fetchProgramDashboardValues(id), 100);
  };

  const handleRollback = async (uid) => {
    await handleProgramRollback(uid);
    setTimeout(() => fetchProgramDashboardValues(id), 100);
  };

  const handleToggleGrantScholarship = async (uid, value) => {
    if (value) {
      await toggleGrantScholarship(uid, value);
      setTimeout(() => fetchProgramDashboardValues(id), 100);
    } else {
      setScholarshiprejectId(uid);
    }
  };

  const handleToggleGrantSponsorship = async (
    uid,
    value,
    sponsorshipName = null
  ) => {
    await toggleGrantSponsorship(uid, value, sponsorshipName);
    setTimeout(() => fetchProgramDashboardValues(id), 100);
  };

  const handleToggleEarlyAdmission = async (uid, value) => {
    if (value === false || value) {
      await toggleEarlyAdmision(uid, value);
      setTimeout(() => fetchProgramDashboardValues(id), 100);
    } else {
      setearlyAdmissionRejectId(uid);
    }
  };

  const handleUpdateScholarshipAmount = async (uid, value) => {
    await updateScholarshipAmount(uid, value);
    setTimeout(() => fetchProgramDashboardValues(id), 100);
  };

  const handleUpdateSponsorshipAmount = async (uid, value) => {
    await updateSponsorshipAmount(uid, value);
    setTimeout(() => fetchProgramDashboardValues(id), 100);
  };

  const handleApplicationNotificationApproved = async (uid) => {
    await sendApplicationNotificationApproved(uid);
  };

  const handleRejectScholarship = async (values) => {
    await toggleGrantScholarship(
      scholarshipRejectId,
      false,
      values.scholarshipRejectReason
    );
    setTimeout(() => fetchProgramDashboardValues(id), 100);
    setScholarshiprejectId(null);
  };

  const handleReload = () => {
    setIsReload((prev) => (prev = !prev));
  };
  console.log(
    fetchAllProgramCompletedApplications,
    'fetchAllProgramCompletedApplications'
  );

  return (
    <Grid container>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle id="alert-dialog-title">{'Warning'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you wish to delete program?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteProgram()}
            style={{ color: theme.palette.error.main }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={scholarshipRejectId ? true : false}
        onClose={() => setScholarshiprejectId(null)}
        maxWidth="md"
      >
        <form onSubmit={handleSubmit(handleRejectScholarship)}>
          <DialogTitle>Reason for denial of scholarship</DialogTitle>
          <DialogContent>
            <Field
              name={'scholarshipRejectReason'}
              label={'Reason for denial of scholarship'}
              component={TextInput}
              type={'text'}
              variant={'outlined'}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setScholarshiprejectId(null)}
              color="secondary"
              disabled={submitting}
            >
              Close
            </Button>
            <Button color={'primary'} type={'submit'} disabled={submitting}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Grid item md={2} style={{ width: `${matchesSide ? '' : '100%'}` }}>
        <Sidebar />
      </Grid>

      <Grid
        item
        md={10}
        style={{
          //backgroundColor: '#FAFAFB',
          backgroundColor: '#F3F4F9',
          paddingLeft: '41px',
          paddingRight: '41px',
        }}
      >
        <Grid item container direction={'column'} style={{ margin: '2em 0' }}>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ paddingLeft: '20px', marginBottom: 20 }}
          >
            <Typography className={classes.programName}>
              {program.programName
                ? `${program.programName} Dashboard`
                : 'Welcome'}
            </Typography>
            <Box display="flex" justifyContent="space-evenly">
              {profile.isAdmin && (
                <>
                  <Button
                    style={{
                      marginLeft: '0.5em',
                      backgroundColor: theme.palette.info.main,
                      color: 'white',
                    }}
                    disableElevation
                    variant={'contained'}
                    size={'small'}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`/admin/program/edit?id=${id}`);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => duplicateAdminProgram(id)}
                    style={{
                      marginLeft: '0.5em',
                      color: theme.palette.error.main,
                      borderColor: theme.palette.error.main,
                    }}
                    variant="outlined"
                    size="small"
                  >
                    Duplicate Program
                  </Button>
                  <Button
                    style={{
                      marginLeft: '0.5em',
                      backgroundColor: theme.palette.error.main,
                      color: 'white',
                    }}
                    disableElevation
                    variant={'contained'}
                    size={'small'}
                    onClick={(e) => {
                      e.stopPropagation();
                      setProgramId(id);
                      setDialogOpen(true);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    style={{
                      marginLeft: '0.5em',
                      backgroundColor: theme.palette.error.main,
                      color: 'white',
                    }}
                    disableElevation
                    variant={'contained'}
                    size={'small'}
                    onClick={(e) => {
                      e.stopPropagation();
                      closeAdminProgram(id);
                    }}
                  >
                    Close Program
                  </Button>
                </>
              )}
            </Box>
          </Grid>

          {/*BOXES*/}
          <Grid item>
            <Grid item container style={{ gap: '1.5rem' }}>
              <SidebarBox
                title={'Master Lists'}
                value={
                  programDashboardValues
                    ? programDashboardValues.allApplicants
                    : 0
                }
                color={'#EC4E98'}
                click={`/admin/program/dashboard/applicants?id=${program.id}`}
              />
              <SidebarBox
                title={'Early Admissions'}
                value={
                  programDashboardValues
                    ? programDashboardValues?.tableData?.allApplicants?.filter(
                        (applicant) => {
                          return (
                            applicant.earlyAdmission &&
                            applicant.confirmationFormDetails
                          );
                        }
                      ).length
                    : 0
                }
                color={'#EC4E98'}
                click={`/admin/program/dashboard/earlyApplicants?id=${program.id}`}
              />
              <SidebarBox
                title={'Application Fee Paid'}
                value={
                  programDashboardValues?.totalApplicants
                    ? programDashboardValues?.totalApplicants
                    : 0
                }
                color={'#EC4E98'}
                click={`/admin/program/dashboard/totalApplicants?id=${program.id}`}
              />
              <SidebarBox
                title={'Total Accepted Applicants'}
                value={
                  programDashboardValues?.applicationsApproved
                    ? programDashboardValues?.applicationsApproved
                    : 0
                }
                color={'#0778AA'}
                click={`/admin/program/dashboard/totalAcceptedApplicants?id=${program.id}`}
              />
              <SidebarBox
                title={'Completed Application Checklist'}
                value={
                  programDashboardValues?.submitted
                    ? programDashboardValues?.submitted
                    : 0
                }
                color={'#49AA50'}
                click={`/admin/program/dashboard/submittedApplicants?id=${program.id}`}
              />
              <SidebarBox
                title={'Completed Acceptance Checklist'}
                value={
                  programDashboardValues?.admitted
                    ? programDashboardValues?.admitted
                    : 0
                }
                color={'#49AA50'}
                click={`/admin/program/dashboard/admittedApplicants?id=${program.id}`}
              />
              {/* <SidebarBox
                title={'Awaiting Payment'}
                value={
                  programDashboardValues.awaitingPayment
                    ? programDashboardValues.awaitingPayment
                    : 0
                }
                color={'#49AA50'}
                click={`/admin/program/dashboard/awaitingPayment?id=${program.id}`}
              /> */}
              <SidebarBox
                title={'Completed Scholars'}
                value={
                  programDashboardValues?.tableData?.applicants
                    ? programDashboardValues?.tableData?.applicants?.filter(
                        (applicant) => applicant?.programStage === 'complete'
                      ).length
                    : 0
                }
                color={'#49AA50'}
                click={`/admin/program/dashboard/completedPayment?id=${program.id}`}
              />
              <SidebarBox
                title={'Total Revenue'}
                value={
                  programDashboardValues?.totalRevenue
                    ? programDashboardValues?.totalRevenue
                    : 0
                }
                color={'#F39E21'}
                dollar
                click={`/admin/program/dashboard/totalRevenue?id=${program.id}`}
              />
            </Grid>
          </Grid>

          <Box style={{ marginTop: 20 }}>
            <Accordion
              elevation={0}
              style={{
                boxShadow:
                  'rgb(95 116 141 / 3%) 0px 2px 1px -1px, rgb(95 116 141 / 4%) 0px 1px 1px 0px, rgb(95 116 141 / 8%) 0px 1px 3px 0px',
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography className={classes.title}>Analytics</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: '#FAFAFB' }}>
                {/*APPROVED BOXES*/}
                <Grid item container style={{ gap: '1.5rem' }}>
                  {/* <SidebarBox
                    title={'Approved'}
                    value={
                      programDashboardValues.applicationsApproved
                        ? programDashboardValues.applicationsApproved
                        : 0
                    }
                    color={'#49AA50'}
                    click={`/admin/program/dashboard/totalAcceptedApplicants?id=${program.id}`}
                  /> */}
                  <SidebarBox
                    title={'Reviewed'}
                    value={
                      programDashboardValues?.applicationsReviewed
                        ? programDashboardValues?.applicationsReviewed
                        : 0
                    }
                    color={'#49AA50'}
                    click={`/admin/program/dashboard/reviewedApplicants?id=${program.id}`}
                  />
                  <SidebarBox
                    title={'Not Notified'}
                    value={
                      programDashboardValues?.notNotified
                        ? programDashboardValues?.notNotified
                        : 0
                    }
                    color={'#49AA50'}
                    click={`/admin/program/dashboard/notNotifiedApplicants?id=${program.id}`}
                  />
                  <SidebarBox
                    title={'Not Placed'}
                    value={
                      programDashboardValues?.notPlaces
                        ? programDashboardValues?.notPlaces
                        : 0
                    }
                    color={'#49AA50'}
                    click={`/admin/program/dashboard/notPlacesApplicants?id=${program.id}`}
                  />
                  <SidebarBox
                    title={'In Progress'}
                    value={
                      programDashboardValues?.inProgress
                        ? programDashboardValues?.inProgress
                        : 0
                    }
                    color={'#49AA50'}
                    click={`/admin/program/dashboard/inProgressApplicants?id=${program.id}`}
                  />
                  <SidebarBox
                    title={'Admitted'}
                    value={
                      programDashboardValues?.admitted
                        ? programDashboardValues?.admitted
                        : 0
                    }
                    color={'#49AA50'}
                    click={`/admin/program/dashboard/admittedApplicants?id=${program.id}`}
                  />
                  <SidebarBox
                    title={'Denied'}
                    value={
                      programDashboardValues?.applicationsDenied
                        ? programDashboardValues?.applicationsDenied
                        : 0
                    }
                    color={'#49AA50'}
                    click={`/admin/program/dashboard/deniedApplicants?id=${program.id}`}
                  />
                  <SidebarBox
                    title={'Waitlisted'}
                    value={
                      programDashboardValues?.waitlisted
                        ? programDashboardValues?.waitlisted
                        : 0
                    }
                    color={'#49AA50'}
                    click={`/admin/program/dashboard/waitlistedApplicants?id=${program.id}`}
                  />
                  <SidebarBox
                    title={'Withdrawn'}
                    value={
                      programDashboardValues?.withdrawn
                        ? programDashboardValues?.withdrawn
                        : 0
                    }
                    color={'#49AA50'}
                    click={`/admin/program/dashboard/withdrawnApplicants?id=${program.id}`}
                  />
                  <SidebarBox
                    title={'Awaiting Deposit'}
                    value={
                      programDashboardValues?.awaitingPayment
                        ? programDashboardValues?.awaitingPayment
                        : 0
                    }
                    color={'#49AA50'}
                    click={`/admin/program/dashboard/awaitingPayment?id=${program.id}`}
                  />
                  <SidebarBox
                    title={'Awaiting Final Payment'}
                    value={
                      programDashboardValues?.awaitingFinalPayment
                        ? programDashboardValues?.awaitingFinalPayment
                        : 0
                    }
                    color={'#49AA50'}
                    click={`/admin/program/dashboard/awaitingFinalPayment?id=${program.id}`}
                  />
                  <SidebarBox
                    title={'Payment Complete'}
                    value={
                      programDashboardValues?.paidInFull
                        ? programDashboardValues?.paidInFull
                        : 0
                    }
                    color={'#49AA50'}
                    click={`/admin/program/dashboard/paidInFull?id=${program.id}`}
                  />
                  <SidebarBox
                    title={'Total Scholarship'}
                    value={
                      programDashboardValues?.tableData?.scholarshipsApplied
                        .length
                        ? programDashboardValues?.tableData?.scholarshipsApplied
                            .length
                        : 0
                    }
                    color={'#49AA50'}
                    click={`/admin/program/dashboard/scholarshipApplicants?id=${program.id}`}
                  />
                  <SidebarBox
                    title={'Accepted Scholarship'}
                    value={
                      programDashboardValues?.tableData?.admitted.length
                        ? programDashboardValues.tableData.admitted.filter(
                            (applicant) => applicant?.grantScholarship
                          ).length
                        : 0
                    }
                    color={'#49AA50'}
                    click={`/admin/program/dashboard/scholarshipAcceptedApplicants?id=${program.id}`}
                  />
                  <SidebarBox
                    title={'Declined Scholarship'}
                    value={
                      programDashboardValues?.tableData?.withdrawn.length
                        ? programDashboardValues.tableData.withdrawn.filter(
                            (applicant) => applicant.grantScholarship
                          ).length
                        : 0
                    }
                    color={'#49AA50'}
                    click={`/admin/program/dashboard/scholarshipDeclinedApplicants?id=${program.id}`}
                  />
                  <SidebarBox
                    title={'Sponsorship Granted'}
                    value={
                      programDashboardValues?.tableData?.sponsorshipApplications
                        .length || 0
                    }
                    color={'#49AA50'}
                    click={`/admin/program/dashboard/sponsorshipGrantedApplicants?id=${program.id}`}
                  />
                  <SidebarBox
                    title={'Travel Form'}
                    value={
                      programDashboardValues?.tableData?.travelFormApplicants
                        .length || 0
                    }
                    color={'#49AA50'}
                    click={`/admin/program/dashboard/travelInformationApplicants?id=${program.id}`}
                  />
                  <SidebarBox
                    title={'Student Questionnaire'}
                    value={
                      programDashboardValues?.tableData
                        ?.studentQuestionnaireApplicants?.length || 0
                    }
                    color={'#49AA50'}
                    click={`/admin/program/dashboard/studentQuestionnaireApplicants?id=${program.id}`}
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              elevation={0}
              style={{
                boxShadow:
                  'rgb(95 116 141 / 3%) 0px 2px 1px -1px, rgb(95 116 141 / 4%) 0px 1px 1px 0px, rgb(95 116 141 / 8%) 0px 1px 3px 0px',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                style={{ marginTop: '20px' }}
              >
                <Typography className={classes.title}>Charts</Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ backgroundColor: '#FAFAFB', flexDirection: 'column' }}
              >
                <Grid
                  container
                  direction={'column'}
                  className={classes.stateContainer}
                >
                  {/* <Grid item>
                <Typography className={classes.title}>
                  State of applicants
                </Typography>
              </Grid> */}

                  <Grid item style={{ marginTop: '26px' }}>
                    <Grid container>
                      <Grid item lg={3}>
                        <Typography className={classes.stateTitle}>
                          State
                        </Typography>
                      </Grid>
                      <Grid item lg={3}>
                        <Typography className={classes.stateTitle}>
                          Number of applicants
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      style={{ paddingTop: '13px', paddingBottom: '13px' }}
                    >
                      {programGeographyValues.map((state) => (
                        <>
                          <Grid item lg={3}>
                            <Typography className={classes.stateItem}>
                              {state.state}
                            </Typography>
                          </Grid>
                          <Grid item lg={3}>
                            <Typography className={classes.stateItem}>
                              {state.count}
                            </Typography>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={classes.stateContainer}
                  style={{ marginTop: '20px' }}
                >
                  {/*PIE CHART*/}
                  <Grid item lg={4}>
                    <Grid container direction={'column'}>
                      <Grid item>
                        <Typography className={classes.title}>
                          Gender Ratio
                        </Typography>
                      </Grid>
                      <Grid item style={{ marginTop: '10px' }}>
                        <Chart
                          width={'100%'}
                          height={'300px'}
                          chartType="PieChart"
                          loader={<div>Loading Chart</div>}
                          data={
                            programDashboardValues.applicantGenderValues
                              ? programDashboardValues?.applicantGenderValues
                              : [['Gender', 'Ratio']]
                          }
                          // options={{
                          //   title: "Gender Ratios",
                          // }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={8} style={{ paddingRight: '10px' }}>
                    <Grid container direction={'column'}>
                      <Grid item>
                        <Typography className={classes.title}>
                          Nationality
                        </Typography>
                      </Grid>
                      <Grid item style={{ marginTop: '10px' }}>
                        <Chart
                          width={'100%'}
                          height={'300px'}
                          chartType="Bar"
                          loader={<div>Loading Chart</div>}
                          data={
                            programDashboardValues.applicantNationalityValues
                              ? programDashboardValues.applicantNationalityValues
                              : [['Nationality', 'Applicants']]
                          }
                          // options={{
                          //   chart: {
                          //     title: "Nationality",
                          //   },
                          // }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={classes.stateContainer}
                  style={{ marginTop: '20px' }}
                >
                  <Grid item lg={12} style={{ paddingRight: '10px' }}>
                    <Grid container direction={'column'}>
                      <Grid item>
                        <Typography className={classes.title}>
                          Institutions
                        </Typography>
                      </Grid>
                      <Grid item style={{ marginTop: '10px' }}>
                        <Chart
                          width={'100%'}
                          height={'300px'}
                          chartType="Bar"
                          loader={<div>Loading Chart</div>}
                          data={
                            programDashboardValues?.institutionValues
                              ? programDashboardValues.institutionValues
                              : [['Institutions', 'Applicants']]
                          }
                          chartEvents={[
                            {
                              eventName: 'select',
                              callback({ chartWrapper }) {
                                const chart = chartWrapper
                                  .getChart()
                                  .getSelection();
                                const row = chart[0].row;
                                const institute =
                                  programDashboardValues.institutionValues[
                                    row + 1
                                  ][0];
                                history.push(
                                  `/admin/program/dashboard/applicants?institute=${institute}`
                                );
                              },
                            },
                          ]}
                          // options={{
                          //   chart: {
                          //     title: "Nationality",
                          //   },
                          // }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box>
            <Accordion
              elevation={0}
              style={{
                boxShadow:
                  'rgb(95 116 141 / 3%) 0px 2px 1px -1px, rgb(95 116 141 / 4%) 0px 1px 1px 0px, rgb(95 116 141 / 8%) 0px 1px 3px 0px',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                style={{ marginTop: '20px' }}
              >
                <Typography className={classes.title}>
                  Application Fee Revenue
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: '#FAFAFB' }}>
                <DashboardRevenueTable
                  revenue={revenue.filter(
                    (item) => item.paymentType === 'applicationFee'
                  )}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              elevation={0}
              style={{
                boxShadow:
                  'rgb(95 116 141 / 3%) 0px 2px 1px -1px, rgb(95 116 141 / 4%) 0px 1px 1px 0px, rgb(95 116 141 / 8%) 0px 1px 3px 0px',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                style={{ marginTop: '20px' }}
              >
                <Typography className={classes.title}>
                  Program Cost Revenue
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ backgroundColor: '#FAFAFB', flexDirection: 'column' }}
              >
                <DashboardRevenueTable
                  revenue={revenue.filter(
                    (item) => item.paymentType !== 'applicationFee'
                  )}
                />
              </AccordionDetails>
            </Accordion>
          </Box>

          {/*REVIEW*/}
          <Grid item>
            <Box>
              <Accordion
                elevation={0}
                defaultExpanded
                style={{
                  boxShadow:
                    'rgb(95 116 141 / 3%) 0px 2px 1px -1px, rgb(95 116 141 / 4%) 0px 1px 1px 0px, rgb(95 116 141 / 8%) 0px 1px 3px 0px',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  style={{ marginTop: '20px' }}
                >
                  <Typography className={classes.title}>
                    Needs Review (Assigned)
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: '#FAFAFB' }}>
                  <Tooltip title="Refresh">
                    <RefreshIcon
                      style={{ float: 'right', cursor: 'pointer' }}
                      onClick={() => setRefreshTable((prev) => (prev = !prev))}
                    />
                  </Tooltip>
                  {/*NEEDS REVIEW - ASSIGNED*/}
                  <NeedsReviewTable
                    rows={programCompletedApplications}
                    title={'Needs Review (Assigned)'}
                    reviewStatus={'assigned'}
                    profile={profile}
                    onReload={() => handleReload()}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>

          {/*NEEDS REVIEWER - Unassigned*/}
          <Grid item>
            <Box>
              <Accordion
                elevation={0}
                defaultExpanded
                style={{
                  boxShadow:
                    'rgb(95 116 141 / 3%) 0px 2px 1px -1px, rgb(95 116 141 / 4%) 0px 1px 1px 0px, rgb(95 116 141 / 8%) 0px 1px 3px 0px',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  style={{ marginTop: '20px' }}
                >
                  <Typography className={classes.title}>
                    Needs Review (Unassigned)
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: '#FAFAFB' }}>
                  <Tooltip title="Refresh">
                    <RefreshIcon
                      style={{ float: 'right', cursor: 'pointer' }}
                      onClick={() => setRefreshTable((prev) => (prev = !prev))}
                    />
                  </Tooltip>
                  {/*NEEDS REVIEW - ASSIGNED*/}
                  <NeedsReviewTable
                    rows={programCompletedApplications}
                    title={'Needs Review (Assigned)'}
                    reviewStatus={'unassigned'}
                    profile={profile}
                    onReload={() => handleReload()}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>

          {/*Scholarship*/}
          <Grid item>
            <Grid
              container
              direction={'column'}
              className={classes.stateContainer}
              style={{ marginTop: '20px' }}
            >
              <Grid item>
                <Tooltip title="Refresh">
                  <RefreshIcon
                    style={{ float: 'right', cursor: 'pointer' }}
                    onClick={() => setRefreshTable((prev) => (prev = !prev))}
                  />
                </Tooltip>
                <Typography className={classes.title}>
                  Awaiting Scholarship
                </Typography>
              </Grid>

              <ScholarshipTable
                scholarshipApplications={scholarshipApplications
                  .filter((application) => !application.scholarshipAmount)
                  .sort((a, b) => {
                    return (
                      new Date(fromUnixTime(b.reviewedOn)) -
                      new Date(fromUnixTime(a.reviewedOn))
                    );
                  })}
                title={'Awaiting'}
                profile={profile}
                handleUpdateProgramStage={handleUpdateProgramStage}
                handleToggleGrantScholarship={handleToggleGrantScholarship}
                handleUpdateScholarshipAmount={handleUpdateScholarshipAmount}
              />
            </Grid>
          </Grid>

          {/*Scholarship*/}
          <Grid item>
            <Box>
              <Accordion
                elevation={0}
                defaultExpanded
                style={{
                  boxShadow:
                    'rgb(95 116 141 / 3%) 0px 2px 1px -1px, rgb(95 116 141 / 4%) 0px 1px 1px 0px, rgb(95 116 141 / 8%) 0px 1px 3px 0px',
                  justifyContent: 'space-between',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  style={{ marginTop: '20px' }}
                >
                  <Typography className={classes.title}>
                    Scholarship Granted: $
                    {scholarshipApplications
                      .filter((application) => application.scholarshipAmount)
                      .reduce((accumulator, object) => {
                        return accumulator + parseInt(object.scholarshipAmount);
                      }, 0)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Tooltip title="Refresh">
                    <RefreshIcon
                      style={{ float: 'right', cursor: 'pointer' }}
                      onClick={() => setRefreshTable((prev) => (prev = !prev))}
                    />
                  </Tooltip>
                  <ScholarshipTable
                    scholarshipApplications={scholarshipApplications
                      .filter((application) => application.scholarshipAmount)
                      .sort((a, b) => {
                        return (
                          new Date(fromUnixTime(b.reviewerUpdated)) -
                          new Date(fromUnixTime(a.reviewerUpdated))
                        );
                      })}
                    profile={profile}
                    handleUpdateProgramStage={handleUpdateProgramStage}
                    handleToggleGrantScholarship={handleToggleGrantScholarship}
                    handleUpdateScholarshipAmount={
                      handleUpdateScholarshipAmount
                    }
                    handleApplicationNotificationApproved={
                      handleApplicationNotificationApproved
                    }
                    handleToggleGrantSponsorship={handleToggleGrantSponsorship}
                    handleUpdateChosenInstitution={
                      handleUpdateChosenInstitution
                    }
                    institutions={institutions}
                    sponsorhips={sponsorhips}
                    notifyButton
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          {/*Sponsorship*/}
          <Grid item>
            <Box>
              <Accordion
                elevation={0}
                defaultExpanded
                style={{
                  boxShadow:
                    'rgb(95 116 141 / 3%) 0px 2px 1px -1px, rgb(95 116 141 / 4%) 0px 1px 1px 0px, rgb(95 116 141 / 8%) 0px 1px 3px 0px',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  style={{ marginTop: '20px' }}
                >
                  <Typography className={classes.title}>Sponsorship</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: '#FAFAFB' }}>
                  <Tooltip title="Refresh">
                    <RefreshIcon
                      style={{ float: 'right', cursor: 'pointer' }}
                      onClick={() => setRefreshTable((prev) => (prev = !prev))}
                    />
                  </Tooltip>
                  <SponsorshipTable
                    sponsorshipApplications={sponsorshipApplications
                      .filter((application) => application.scholarshipAmount)
                      .sort((a, b) => {
                        return (
                          new Date(fromUnixTime(b.reviewerUpdated)) -
                          new Date(fromUnixTime(a.reviewerUpdated))
                        );
                      })}
                    profile={profile}
                    handleUpdateProgramStage={handleUpdateProgramStage}
                    handleToggleGrantSponsorship={handleToggleGrantSponsorship}
                    handleUpdateSponsorshipAmount={
                      handleUpdateSponsorshipAmount
                    }
                    handleApplicationNotificationApproved={
                      handleApplicationNotificationApproved
                    }
                    handleUpdateChosenInstitution={
                      handleUpdateChosenInstitution
                    }
                    institutions={institutions}
                    notifyButton
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>

          {/*Withdrawn*/}
          <Grid item>
            <Box>
              <Accordion
                elevation={0}
                style={{
                  boxShadow:
                    'rgb(95 116 141 / 3%) 0px 2px 1px -1px, rgb(95 116 141 / 4%) 0px 1px 1px 0px, rgb(95 116 141 / 8%) 0px 1px 3px 0px',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  style={{ marginTop: '20px' }}
                >
                  <Typography className={classes.title}>Withdrawn</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: '#FAFAFB' }}>
                  <Tooltip title="Refresh">
                    <RefreshIcon
                      style={{ float: 'right', cursor: 'pointer' }}
                      onClick={() => setRefreshTable((prev) => (prev = !prev))}
                    />
                  </Tooltip>
                  {/*WithdrawnTable*/}
                  <WithdrawnTable
                    withdrawnApplications={withdrawnApplications}
                    profile={profile}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>

          {/*EARLY ADMISSION*/}
          {program.earlyAdmissions && (
            <Grid item>
              <Grid
                container
                direction={'column'}
                className={classes.stateContainer}
                style={{ marginTop: '20px' }}
              >
                <Grid item>
                  <Tooltip title="Refresh">
                    <RefreshIcon
                      style={{ float: 'right', cursor: 'pointer' }}
                      onClick={() => setRefreshTable((prev) => (prev = !prev))}
                    />
                  </Tooltip>
                  <Typography className={classes.title}>
                    Early Admission
                  </Typography>
                </Grid>

                <ReviewedTable
                  rows={
                    // programDashboardValues.tableData?.allApplicants?.filter(
                    programReviewedApplications?.filter((applicant) => {
                      return (
                        applicant.earlyAdmission &&
                        applicant.confirmationFormDetails
                      );
                    }) || []
                  }
                  title={'Early '}
                  profile={profile}
                  program={program}
                  // approve={approve}
                  handleUpdateProgramStage={handleUpdateProgramStage}
                  handleProgramRollback={handleRollback}
                  handleToggleGrantScholarship={handleToggleGrantScholarship}
                  handleToggleEarlyAdmission={handleToggleEarlyAdmission}
                  handleApplicationNotificationApproved={
                    handleApplicationNotificationApproved
                  }
                  handleUpdateChosenInstitution={handleUpdateChosenInstitution}
                  institutions={institutions}
                />
              </Grid>
            </Grid>
          )}

          {/*REVIEWED*/}
          <Grid item>
            <Grid
              container
              direction={'column'}
              className={classes.stateContainer}
              style={{ marginTop: '20px' }}
            >
              <Grid item>
                <Tooltip title="Refresh">
                  <RefreshIcon
                    style={{ float: 'right', cursor: 'pointer' }}
                    onClick={() => setRefreshTable((prev) => (prev = !prev))}
                  />
                </Tooltip>
                <Typography className={classes.title}>Reviewed</Typography>
              </Grid>

              <ReviewedTable
                rows={programReviewedApplications}
                title={'Reviewed'}
                profile={profile}
                program={program}
                // approve={approve}
                handleUpdateProgramStage={handleUpdateProgramStage}
                handleProgramRollback={handleRollback}
                handleToggleGrantScholarship={handleToggleGrantScholarship}
                handleToggleGrantSponsorship={handleToggleGrantSponsorship}
                handleToggleEarlyAdmission={handleToggleEarlyAdmission}
                handleApplicationNotificationApproved={
                  handleApplicationNotificationApproved
                }
                handleUpdateChosenInstitution2={handleUpdateChosenInstitution2}
                handleUpdateChosenInstitution={handleUpdateChosenInstitution}
                sponsorhips={sponsorhips}
                institutions={institutions}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({ form: 'scholarshipRejection', validate })(
    AdminViewProgramDashboard
  )
);
