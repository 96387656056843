import React from "react";

//import Dialog from "@mui/material/Dialog";
//import DialogTitle from "@mui/material/DialogTitle";
import { closeDialog } from "../../store/actions/dialogActions/dialogActions";
import { connect } from "react-redux";
import LoginForm from "../../ui/forms/auth/LoginForm";
import Grid from "@mui/material/Grid";
//import DialogContentText from "@mui/material/DialogContentText";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: '400px',
    margin: '20px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
   button: {
    width: '120px',
    height: '38px',
    borderRadius: '10px',
    backgroundColor: '#35BA35',
    color: 'white',
    fontWeight: 700,
    borderRadius: "50.25rem",
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#35BA35',
      color: 'white',
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: 1,
      fontSize:"12px",
    },
  },
}));

const actions = {
  closeDialog,
};

const LoginDialog = ({ closeDialog, alert }) => {
  const classes = useStyles();
  const handleClose = () => {
    closeDialog();
  };
  return (
    <Dialog open={true} onClose={handleClose} classes={{ paper: classes.dialogPaper }}>
      <Grid container direction={"column"} style={{ padding: "10px" }}>
        <Grid item>
          <DialogTitle align={"center"} style={{ fontFamily: "Poppins" }}>
            Login
          </DialogTitle>
        </Grid>
        {alert && (
          <Grid item>
              <DialogContent className={classes.dialogContent}>
            <DialogContentText style={{ fontSize: "0.9em" }} align={"center"}>
              {alert}
            </DialogContentText>
                </DialogContent>
          </Grid>
        )}
        <Grid item>
        <DialogContent className={classes.dialogContent}>
          <LoginForm />
          </DialogContent>
        </Grid>
  
      </Grid>
    </Dialog>
  );
};

export default connect(null, actions)(LoginDialog);
