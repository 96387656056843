import React, { useState, useEffect } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { Grid, Typography, Button, Paper, Box } from "@mui/material";
import Sidebar from "../../../ui/sidebar/Sidebar";
import { connect } from "react-redux";
import DashboardRevenueTable from "../../../ui/admin/dashboard/DashboardRevenueTable";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1.5em",
    backgroundColor: "rgb(250, 250, 251)",
  },
  heading: {
    marginBottom: "3rem",
  },
  tableContainer: {
    padding: "1rem",
    gap: "1rem",
  },
  applicationsLength: {
    marginLeft: "1rem",
  },
}));

const mapStateToProps = (state) => {
  let program = {};
  let revenue = [];

  if (
    state.adminPrograms.adminProgram &&
    state.adminPrograms.adminProgram.length > 0
  ) {
    program = state.adminPrograms.adminProgram[0];
  }

  if (state.adminPrograms.programDashboardValues) {
    if (state.adminPrograms.programDashboardValues.tableData) {
      if (
        state.adminPrograms.programDashboardValues.tableData.revenue.length > 0
      ) {
        revenue = state.adminPrograms.programDashboardValues.tableData?.revenue;
      }
    }
  }

  return {
    program,
    revenue,
  };
};

const AdminViewProgramDashboardRevenue = ({ program, revenue }) => {
  const matches = useMediaQuery("(min-width:480px)");
  const [totalRevenue, setTotalrevenue] = useState(0);
  const [applicationFeeRevenue, setApplicationFeeRevenue] = useState(0);
  const [programCostRevenue, setProgramCostRevenue] = useState(0);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    let amount = 0;
    let feeAmount = 0;
    let costAmount = 0;
    revenue.forEach((item) => {
      amount += parseFloat(item.paymentFee);
      if (item.paymentType === "applicationFee") {
        feeAmount += parseFloat(item.paymentFee);
      } else {
        costAmount += parseFloat(item.paymentFee);
      }
    });
    setTotalrevenue(amount);
    setApplicationFeeRevenue(feeAmount);
    setProgramCostRevenue(costAmount);
  }, [revenue]);

  return (
    <Grid container>
      <Grid item md={2} style={{ width: `${matches ? "" : "100%"}` }}>
        <Sidebar />
      </Grid>
      <Grid className={classes.container} item md={10}>
        {program.programName && (
          <Typography className={classes.heading} variant="h5">
            {program.programName}
          </Typography>
        )}
        <Paper elevation={0}>
          <Grid className={classes.tableContainer} container direction="column">
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex">
                <Typography variant="h6">Total Revenue</Typography>
                <Typography className={classes.applicationsLength} variant="h6">
                  ${Math.round((totalRevenue + Number.EPSILON) * 100) / 100}
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  history.push(`/admin/program/dashboard?id=${program.id}`)
                }
              >
                Program Dashboard
              </Button>
            </Grid>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex">
                <Typography variant="h6">Application Fee Revenue</Typography>
                <Typography className={classes.applicationsLength} variant="h6">
                  $
                  {Math.round((applicationFeeRevenue + Number.EPSILON) * 100) /
                    100}
                </Typography>
              </Box>
            </Grid>
            <DashboardRevenueTable
              revenue={revenue.filter(
                (item) => item.paymentType === "applicationFee"
              )}
            />
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex">
                <Typography variant="h6">Program Cost Revenue</Typography>
                <Typography className={classes.applicationsLength} variant="h6">
                  $
                  {Math.round((programCostRevenue + Number.EPSILON) * 100) /
                    100}
                </Typography>
              </Box>
            </Grid>
            <DashboardRevenueTable
              revenue={revenue.filter(
                (item) => item.paymentType !== "applicationFee"
              )}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps)(AdminViewProgramDashboardRevenue);
