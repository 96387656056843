import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Field } from 'redux-form';
import TextInputForm from '../../../../forms/TextInputForm';
import ScholarshipAdditionalDebtForm from './ScholarshipAdditionalDebtForm';
import ScholarshipChildrensExpensesForm from './ScholarshipChildrensExpensesForm';
import CheckBoxForm from '../../../../forms/CheckBoxForm';
import SelectInputFormOnChangeTrueFalse from '../../../../forms/SelectInputFormOnChangeTrueFalse';
import TextAreaForm from '../../../../forms/TextAreaForm';
import { format, parseISO, subYears } from 'date-fns';
import DatePickerInput from '../../../../../common/form/DatePickerInput';
import './scholarShip.css';
import CheckBoxForms from '../../../../forms/CheckboxForms';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: '0.4em',
  },
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldContainers: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  questionText: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  // sectionTitle: {
  //   color: '#000',
  //   fontWeight: '600 !Important',
  //   fontFamily: 'Roboto',
  //   fontSize: '16px',
  //   letter: '0.2px',
  //   backgroundColor: '#a7caed',
  //   width: '-webkit-fill-available',
  //   boxShadow: '3px 3px 8px #888888',
  //   padding: '3px 10px',
  // },
}));

const ScholarshipAdditionalQuestionsForm = ({ fieldValues, program }) => {
  const classes = useStyles();

  const [additionalFamilyDebt, setAdditionalFamilyDebt] = useState(false);
  const [monthlyChildrensExpenses, setMonthlyChildrensExpenses] =
    useState(false);

  // const year = format(new Date(), 'yyyy')
  const prevYear = format(subYears(new Date(), 2), 'yyyy');
  //const currentYear = format(new Date(), 'yyyy');
  const currentYear = format(subYears(new Date(), 1), 'yyyy');

  useEffect(() => {
    setAdditionalFamilyDebt(
      fieldValues.additionalFamilyDebt === 'Yes' ? true : false
    );
    setMonthlyChildrensExpenses(
      fieldValues.monthlyChildrensExpense === 'Yes' ? true : false
    );
    fieldValues.additionalFamilyDebt === 'Yes'
      ? localStorage.setItem('familydebt', true)
      : localStorage.setItem('familydebt', false);
    fieldValues.monthlyChildrensExpense === 'Yes'
      ? localStorage.setItem('childrenExpenses', true)
      : localStorage.setItem('childrenExpenses', false);
  }, [fieldValues.additionalFamilyDebt, fieldValues.monthlyChildrensExpense]);

  return (
    <Fragment>
      {/*SPONSOR CONSENT*/}
      <Grid item container className={classes.fieldContainer}>
        <CheckBoxForm
          info={
            'I give my consent to the sponsoring organization considering this application to use the information provided herein for the purpose of scholarship consideration.'
          }
          fieldName={'sponsoringConsent'}
        />
      </Grid>

      {/*LAST NAME*/}
      <TextInputForm
        question={'Scholar Last Name:'}
        fieldName={'scholarLastName'}
      />

      {/*FIRST NAME*/}
      <TextInputForm
        question={'Scholar First Name:'}
        fieldName={'scholarFirstName'}
      />

      {/*EMAIL*/}
      <TextInputForm question={'Email:'} fieldName={'scholarEmail'} />

      {/*PHONE NUMBER*/}
      <TextInputForm
        question={'Phone Number:'}
        fieldName={'scholarPhoneNumber'}
      />

      {/*PARENT GUARDIAN NOTE*/}
      <Grid
        item
        container
        className={classes.fieldContainer}
        alignItems={'center'}
        style={{ marginTop: '2em' }}
      >
        <Grid item>
          <Typography className={classes.questionText}>
            Your parents/guardians will need to help you complete this section.
            Please provide the following information from your family’s 2022 -
            2023 IRS tax information:
          </Typography>
        </Grid>
      </Grid>

      {/*FAMILY INCOME SECTION*/}

      <Grid
        item
        container
        className={classes.fieldContainer}
        alignItems={'center'}
        style={{ marginTop: '2em' }}
      >
        <Grid item className={classes.questionText}>
          <Typography>Family Income</Typography>
        </Grid>
      </Grid>

      {/*FAMILY INCOME*/}
      <TextInputForm
        question={'What was the annual family income for 2022?'}
        fieldName={'familyIncome2018'}
      />
      <TextInputForm
        question={'What was the annual family income for 2023?'}
        fieldName={'familyIncome2019'}
      />
      <TextInputForm
        question={
          'What was the 2022 untaxed income & benefits (i.e: Social Security, Disability, etc.):'
        }
        fieldName={'untaxedIncome2019'}
      />
      <TextInputForm
        question={
          'Total number of family members in college as of September 2023:'
        }
        fieldName={'familyMemberInCollege'}
      />
      <TextInputForm
        question={'What is the expected annual family income for 2024?'}
        fieldName={'expectedFamilyIncome2020'}
      />

      {/*EXPENSES SECTION*/}
      <Grid
        item
        container
        className={classes.fieldContainer}
        alignItems={'center'}
        style={{ marginTop: '2em' }}
      >
        <Grid item className={classes.questionText}>
          <Typography variant="h6"> Family Expenses</Typography>
        </Grid>
      </Grid>

      {/*EXPENSES*/}
      <TextInputForm
        question={'Monthly Mortgage/Rent:'}
        fieldName={'monthlyMortgageRent'}
      />
      <TextInputForm
        question={'Monthly Utilities:'}
        fieldName={'monthlyUtilities'}
      />
      <TextInputForm
        question={'Monthly Auto Insurance:'}
        fieldName={'monthlyAutoInsurance'}
      />

      <TextInputForm
        question={'Monthly Life Insurance:'}
        fieldName={'monthlyLifeInsurance'}
      />
      <TextInputForm
        question={'Monthly Home Insurance:'}
        fieldName={'monthlyHomeInsurance'}
      />
      <TextInputForm question={'Monthly Food:'} fieldName={'monthlyFood'} />
      <TextInputForm
        question={'Monthly Transportation:'}
        fieldName={'monthlyTransportation'}
      />
      <TextInputForm
        question={'Medical Expenses:'}
        fieldName={'medicalExpenses'}
      />
      <TextInputForm question={'Auto Loan Debt:'} fieldName={'autoLoanDebt'} />
      <TextInputForm
        question={'Home Equity Loan Debt:'}
        fieldName={'homeEquityLoanDebt'}
      />

      {/*ADDITIONAL DEBT TO DECLARE*/}
      <SelectInputFormOnChangeTrueFalse
        fieldName={'additionalFamilyDebt'}
        question={'Does your family have other debt to declare?'}
        onChange={setAdditionalFamilyDebt}
        options={[{ value: '' }, { value: 'Yes' }, { value: 'No' }]}
      />

      {/*ADDITIONAL DEBT FORM*/}
      {additionalFamilyDebt && (
        <ScholarshipAdditionalDebtForm required={additionalFamilyDebt} />
      )}

      {/*MONTHLY CHILDRENS EXPENSES*/}
      <Grid
        item
        container
        className={classes.fieldContainer}
        alignItems={'center'}
        style={{ marginTop: '2em' }}
      >
        <Grid item className={classes.questionText}>
          <Typography variant="h6">Monthly Children's Expenses</Typography>
        </Grid>
      </Grid>

      {/*ADDITIONAL DEBT TO DECLARE*/}
      <SelectInputFormOnChangeTrueFalse
        fieldName={'monthlyChildrensExpense'}
        question={`Do you have monthly Children's expenses?`}
        onChange={setMonthlyChildrensExpenses}
        options={[{ value: '' }, { value: 'Yes' }, { value: 'No' }]}
      />

      {/*CHILDRENS EXPENSES FORM*/}
      {monthlyChildrensExpenses && (
        <ScholarshipChildrensExpensesForm required={monthlyChildrensExpenses} />
      )}

      {/*NONPROFIT NOTE*/}
      <Grid
        item
        container
        className={classes.fieldContainer}
        alignItems={'center'}
        style={{ marginTop: '2em' }}
      >
        <Grid item>
          <Typography className={classes.questionText}>
            As a nonprofit, our scholarship funds are limited, we base awards on
            financial eligibility. Please provide a detailed description of why
            you require assistance from LEAD.
          </Typography>
        </Grid>
      </Grid>

      {/*Explanation*/}
      <TextAreaForm
        question={'Explanation:'}
        fieldName={'assistantExplanation'}
      />

      {/*EXTIMATED CONTRIBUTION AMOUNT*/}
      <TextInputForm
        question={
          'Estimated amount you are able to contribute toward the cost of the program:'
        }
        fieldName={'estimatedContributionAmount'}
      />

      {/*FAMILY TAX RETURNS NOTE*/}
      <Grid
        item
        container
        className={classes.fieldContainer}
        alignItems={'center'}
        style={{ marginTop: '2em' }}
      >
        <Grid item>
          <Typography className={classes.questionText}>
            You will be asked in a separate task on your Application Check list
            to attach copies of your family's {prevYear}/{currentYear} Federal
            Tax Returns (including IRS W-2, IRS Form 1099, IRS Form 1040, IRS Form 1040A and IRS Form 1040EZfor {prevYear}/
            {currentYear}).
          </Typography>
        </Grid>
      </Grid>

      {/*TAX CHECK BOXES*/}
      <CheckBoxForm
        fieldName={'understandRequiredDocuments'}
        info={`I understand that all required documents to apply for scholarship must be completed before submitting the Application by the Deadline by ${
          program.applicationDeadline
            ? format(parseISO(program?.applicationDeadline), 'EEE MMM do y')
            : null
        } at 11:59pm EST`}
      />
      <CheckBoxForms
        style={{ marginTop: '2em' }}
        // className={classes.fieldContainers}
        fieldName={'documentsTrueAndCorrect'}
        info={
          'I certify that all the information provided by me on this form is true and correct.'
        }
      />

      {/*PARENTS NAME*/}
      <TextInputForm
        question={'Parent/Guardian Name:'}
        fieldName={'parentGuardianName'}
      />

      {/*DATE*/}
      <Grid
        item
        container
        className={classes.fieldContainer}
        alignItems={'center'}
      >
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <Grid item container direction={'column'}>
            <Grid item>
              <Typography className={classes.questionText}>Date</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <Grid item>
            <Grid item container direction={'column'}>
              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={'scholarshipFormDate'}
                  component={DatePickerInput}
                  type={'text'}
                  inputVariant={'outlined'}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ScholarshipAdditionalQuestionsForm;
