import React, { useState } from "react";
import { Grid, Paper, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
const useStyles = makeStyles({
  carouselContainer: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  carouselTrack: {
    display: "flex",
    transition: "transform 0.3s ease-in-out",
    transform: (props) =>
      `translateX(-${props.currentIndex * props.slideWidth}px)`, // Slide the track based on currentIndex and slideWidth,
      margin: '0 30px'
  },
  carouselItem: {
    marginRight: "1rem",
    minWidth: 0,
    width: "33.33%",
  },

  arrowButton: {
    backgroundColor: "#a0a0a0 !important",
    color: "#fff !important",
    pointerEvents: "auto !important",

    "&:hover": {
      opacity: "1 !important",
    },
  },
});

const CustomCarousel = ({ itemsToShow, children }) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, React.Children.count(children) - itemsToShow)
    );
  };

  const visibleChildren = React.Children.toArray(children).slice(
    currentIndex,
    currentIndex + itemsToShow
  );

  return (
    <div className={classes.carouselContainer}>
      <IconButton
        className={`${classes.arrowButton}`}
        onClick={handlePrevClick}
        disabled={currentIndex === 0}
      >
        <ChevronLeftIcon />
      </IconButton>
      <div className={classes.carouselTrack}>
        {visibleChildren.map((child, index) => (
          <div key={index} className={classes.carouselItem}>
            {child}
          </div>
        ))}
      </div>
      <IconButton
        className={`${classes.arrowButton}`}
        onClick={handleNextClick}
        disabled={currentIndex + itemsToShow >= React.Children.count(children)}
      >
        <ChevronRightIcon />
      </IconButton>
    </div>
  );
};

export default CustomCarousel;
