import React, { useState, useEffect, useMemo } from "react";
import { Box, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { format, fromUnixTime } from "date-fns";
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { CustomToolbar, escapeRegExp } from "../../../common/tableUtils";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({}));

const DashboardRevenueTable = ({ revenue }) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchText, setSearchText] = useState("");

  const history = useHistory();

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      "e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    );
  }, []);

  useEffect(() => {
    const arr = revenue
      .sort((a, b) => {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.paymentDate) - new Date(a.paymentDate);
      })
      .map((x, index) => ({
        ...x,
        serialNo: index + 1,
        paymentDate: format(fromUnixTime(x.paymentDate), "MM/dd/yyyy"),
      }));
    setRows(arr);
    if (searchText) {
      setSearchText(searchText);
      const searchRegex = new RegExp(escapeRegExp(searchText), "i");
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
    }
  }, [revenue]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filtered = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };

  const memoizedColumns = useMemo(() => {
    return [
      {
        field: "serialNo",
        headerName: "Id",
        width: 100,
      },
      {
        field: "applicantName",
        headerName: "Applicant Name",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "paymentType",
        headerName: "Revenue Type",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
        renderCell: (param) => (
          <>
            {param.value === "applicationFee" && "Application Fee"}
            {param.value === "payment1" && "Deposit"}
            {param.value === "payment2" && "Final"}
            {param.value === "paymentBalance" && "Paid in Full"}
          </>
        ),
      },
      {
        field: "paymentDate",
        headerName: "Transaction Date",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "applicantId",
        headerName: "Applicant",
        width: 120,
        sortable: false,
        valueGetter: (param) => param.value || "N/A",
        renderCell: (param) => (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() =>
              history.push(`/admin/applicant/view?id=${param.value}`)
            }
          >
            View
          </Button>
        ),
      },
      {
        field: "paymentFee",
        headerName: "Amount",
        width: 120,
        valueGetter: (param) => param.value || "N/A",
        renderCell: (param) => `$${param.value}`,
      },
      {
        field: "cardLast4",
        headerName: "Card Last 4 Digits",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
      },
    ];
  }, [history]);

  return (
    <Box height={400} width="100%">
      <DataGridPremium
        className={classes.gridRoot}
        columns={memoizedColumns}
        rows={filteredRows}
        disableSelectionOnClick
        pageSize={5}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            value: searchText,
            clearSearch: () => requestSearch(""),
          },
        }}
      />
    </Box>
  );
};

export default DashboardRevenueTable;
