import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { format, parseISO, subYears } from "date-fns";

const useStyles = makeStyles((theme) => ({
  textBlock: {
    marginTop: "0.8em",
  },
}));

const mapStateToProps = (state) => {
  const institutionDetails =
    state.applicantPrograms.registeredProgram[0]?.institutionDetails;
  return {
    institutionDetails,
  };
};

const TravelInformationDetails = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent={"center"}
      direction={"column"}
      style={{ marginLeft: "15px" }}
    >
      <Grid item>
        <Typography variant={"body2"}>
          The TRAVEL FORM must be completed by everyone!!! The <b>LEADing for Life Team prepares ALL flight reservations </b> and will provide final itineraries. No changes will be accepted after travel has been finalized.
        </Typography>
   { /*    <Typography variant={"body2"}>
          The TRAVEL FORM must be completed by everyone!!! 
        </Typography>*/}
      </Grid>

      <Grid item className={classes.textBlock}>
        <Typography variant={"body2"}>
          The program fee for All LEADing for Life programs regardless of which campus the
          scholar attends and the length of the program includes program
          attendance, room & board (housing and meals on campus) and all program
          activities. In addition, this years fees also include DOMESTIC airfare
          ONLY as well as transportation to and from the airport to the
          respective university. This also includes those traveling from Puerto
          Rico.
        </Typography>
      </Grid>

      <Grid item className={classes.textBlock}>
        <Typography variant={"body2"}>
          ***There will be NO early arrival or late departure dates!***
        </Typography>
      </Grid>
      <Grid item className={classes.textBlock}>
        <Typography variant={"body2"}>
          For Scholars traveling by AIRPLANE, ALL arrival flights will only be
          on the FIRST day the program begins and in time for dormitory check in
          and program Introduction. ALL departure flights will be on the LAST
          day of your program and leave by the specified time in program packet.
          LEAD staff will meet scholars at the appropriate air terminal and will
          coordinate transportation from the airport for scholars to/from
          campus.
        </Typography>
      </Grid>
      <Grid item className={classes.textBlock}>
        <Typography variant={"body2"}>
          LEAD Scholars traveling by AUTOMOBILE should plan to arrive on campus
          before the start time on date of arrival and depart by the specific
          time on the last day of your LEAD Program. Drop-off and pickup
          locations will be included in the scholar program packet.
        </Typography>
      </Grid>
     
      <Grid item className={classes.textBlock}>
        <Typography variant={"body2"}>
          Please email any questions regarding travel to info@leadprogram․org
        </Typography>
      </Grid>
      {/*<Grid item className={classes.textBlock}>
                <Typography
                    variant={"body2"}

                >
                   <p>
                          <b>Placement: {institutionDetails?.institutionName}</b>
                        </p>
                <p>
                          <b>Arrival Date: {institutionDetails?.arrivalDate ? format(
                            parseISO(institutionDetails?.arrivalDate),
                            "EEE MMM do y"
                          )
                          : null}
                          </b>
                        </p>
                        <p>
                          <b>Departure Date: {institutionDetails?.departureDate ? format(
                            parseISO(institutionDetails?.departureDate),
                            "EEE MMM do y"
                          )
                          : null}
                          </b>
                        </p>
                        </Typography>
                          </Grid>*/}
    </Grid>
  );
};

export default TravelInformationDetails;
