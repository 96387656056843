import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
//import Radio from '@mui/joy/Radio';
import List from '@mui/material/List';
import { Grid, TextField, Typography } from '@mui/material';
import { Field, FieldArray } from 'redux-form';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import { FormGroup, Input, ListItem, ListItemText } from '@mui/material';
// import { v4 as uuidv4 } from 'uuid';

// Components
import TextInputFieldArray from '../../../../common/form/TextInputFieldArray';
import DeleteGarbage from '../../../../assets/dashboard/delete_garbage.png';

const useStyles = makeStyles((theme) => ({
  questionText: {
    fontWeight: '600',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
    color: 'black',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  selectForm: {
    paddingTop: '0.4em',
  },
  addOption: {
    color: '#202124',
  },
}));

const ResponseQuestions = ({
  expanded,
  multiSelectDropDown,
  setMultiSelectDropDown,
  selectedValues,
  setSelectedValues,
}) => {
  const classes = useStyles();

  const changeMultiSelectValuesOnChangeOfSelectedValue = (index) => {
    let changedArray = [];
    if (multiSelectDropDown && multiSelectDropDown?.length > 0) {
      let a = multiSelectDropDown
        ?.filter((el) => el.index !== index)
        ?.map((item) => {
          return changedArray?.push(item);
        });
    }
    setMultiSelectDropDown(changedArray);
  };

  // Multiple DropSwons
  const addMultiSelectDropDownHandler = (index) => {
    if (selectedValues[index] !== 'inputTex') {
      const newCheckbox = {
        index: index,
        id: multiSelectDropDown?.length + 1,
        optionLabel: `option`,
      };
      setMultiSelectDropDown([...multiSelectDropDown, newCheckbox]);
    }
  };

  const handleRemoveMultiSelect = (multiselectId) => {
    const updatedCheckboxes = multiSelectDropDown?.filter(
      (option) => option.id !== multiselectId
    );
    setMultiSelectDropDown(updatedCheckboxes);
  };

  const handleLabelChangeMultiSelect = (checkboxId, newLabel, idx) => {
    const updatedCheckboxes = multiSelectDropDown?.map((multiselect) =>
      multiselect.id === checkboxId && multiselect.index === idx
        ? { ...multiselect, optionLabel: newLabel }
        : multiselect
    );
    setMultiSelectDropDown(updatedCheckboxes);
  };

  const handleOptionOnDeletion = (data, indexToDelete) => {
    const newMultiSelectDown = data.reduce((accumulator, item) => {
      if (item.index === indexToDelete) {
        // Skip the item to delete
        return accumulator;
      } else if (item.index > indexToDelete) {
        // Decrement the index for items after the deleted index
        accumulator.push({
          id: item.id,
          index: item.index - 1,
          optionLabel: item.optionLabel,
        });
      } else {
        // Keep items before the deleted index as is
        accumulator.push(item);
      }
      return accumulator;
    }, []);

    setMultiSelectDropDown(newMultiSelectDown);
  };

  const [addFeilds, setaddFeilds] = useState([]);
  const [nestedInputs, setNestedInputs] = useState([]);
  const handleAddFields = () => {
    setNestedInputs([...nestedInputs, '']);
  };

  const handleDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;
    let tempData = Array.from(selectedValues);
    let [source_data] = tempData.splice(source.index, 1);
    tempData.splice(destination.index, 0, source_data);
    setSelectedValues(tempData);
  };

  console.log(multiSelectDropDown, 656594436225);
  console.log(selectedValues, 6565465545456);

  console.log(nestedInputs, 'nestedInputs');

  const debtFields = ({ fields, meta: { error, submitFailed } }) => (
    <Fragment>
      <Grid
        item
        container
        className={classes.fieldContainer}
        alignItems={'center'}
      >
        <Grid item>
          <IconButton
            aria-label="add debt"
            onClick={() => {
              fields.push({ name: 'check', id: '0' });
            }}
            // onClick={handleAddFields}
          >
            <AddCircleIcon color={'primary'} />
          </IconButton>
        </Grid>

        <Grid item>
          <Typography variant={'subtitle1'}>Add Question</Typography>
        </Grid>
      </Grid>

      <DragDropContext onDragEnd={(result) => handleDragEnd(result, fields)}>
        <Droppable droppableId="droppable-1">
          {(provider) => (
            <div
              className="text-capitalize"
              ref={provider.innerRef}
              {...provider.droppableProps}
            >
              {fields?.map((additionalDebt, index) => (
                <>
                  <Draggable
                    key={index?.toString()}
                    draggableId={index?.toString()}
                    index={index}
                  >
                    {(provider) => (
                      <div
                        {...provider.draggableProps}
                        ref={provider.innerRef}
                        {...provider.dragHandleProps}
                      >
                        <Grid
                          item
                          container
                          style={{
                            marginTop: '1.5em',
                            marginBottom: '3px',
                            justifyContent: 'space-between',
                          }}
                          alignItems={'center'}
                        >
                          <div>
                            <Typography className={classes.questionText}>
                              <i> Question #{index + 1}</i>
                            </Typography>
                          </div>
                          {selectedValues[index]?.select === 'inputTex' && (
                            <div style={{ marginLeft: '276px' }}>
                              <Typography className={classes.questionText}>
                                <i>Max Word Count</i>
                              </Typography>
                            </div>
                          )}
                          <div></div>
                          {/*BLANK*/}
                          <div></div>
                          {/*BLANK*/}
                        </Grid>

                        <div style={{ display: 'flex' }}>
                          <Field
                            style={{ width: '60%' }}
                            name={`${additionalDebt}.question`}
                            label={'Additional Response Question'}
                            component={TextInputFieldArray}
                            type={'text'}
                            variant={'outlined'}
                          />

                          {selectedValues[index]?.select === 'inputTex' && (
                            <Field
                              style={{ width: '20%', marginLeft: '25px' }}
                              name={`${additionalDebt}.maxLength`}
                              component={TextInputFieldArray}
                              type={'number'}
                              variant={'outlined'}
                            />
                          )}

                          <Field
                            style={{
                              width: '25%',
                              marginLeft: '25px',
                              border: '1px solid #c4c4c4',
                              borderRadius: '4px',
                              paddingLeft: '2px',
                            }}
                            name={`${additionalDebt}.select`}
                            component="select"
                            value={selectedValues[index]?.select}
                            onChange={(e) => {
                              const updatedSelectedValues = [...selectedValues];
                              updatedSelectedValues[index] = {
                                select: e.target.value,
                              };
                              setSelectedValues(updatedSelectedValues);
                              changeMultiSelectValuesOnChangeOfSelectedValue(
                                index
                              );
                            }}
                          >
                            <option disabled value="">
                              Select
                            </option>
                            <option value={'inputTex'}>Paragraph</option>
                            <option value={'Multiselect'}>
                              Multi Select Drop down
                            </option>
                            <option value={'singleSelect'}>
                              Single Select Drop down
                            </option>
                            <option value={'checkbox'}>Check Box</option>
                            <option value={'radioButton'}>Radio Button</option>
                            <option value={'plainTex'}>Label</option>
                          </Field>

                          <IconButton
                            aria-label="delete debt"
                            onClick={() => {
                              fields.remove(index);
                              const updatedSelectedValues = [...selectedValues];
                              updatedSelectedValues.splice(index, 1);
                              setSelectedValues(updatedSelectedValues);
                              if (multiSelectDropDown?.length > 0) {
                                handleOptionOnDeletion(
                                  multiSelectDropDown,
                                  index
                                );
                              }
                            }}
                            // onClick={() => fields.remove(index)}
                          >
                            <img src={DeleteGarbage} alt="delete_icon" />
                          </IconButton>
                        </div>

                        <div style={{ width: '100%' }}>
                          {selectedValues[index]?.select === 'inputTex' && (
                            <TextField
                              name={`${additionalDebt}.inputtext`}
                              id="standard-basic"
                              label="Input Text"
                              variant="standard"
                              fullWidth
                              disabled
                            />
                          )}
                          {selectedValues[index]?.select === 'plainTex' && (
                            <Field
                              style={{ width: '100%', marginTop: '12px' }}
                              // name='message'
                              name={`${additionalDebt}.plaintex`}
                              label={'Description'}
                              component={TextInputFieldArray}
                              type={'text'}
                              variant={'outlined'}
                            />
                          )}

                          {selectedValues[index]?.select === 'Multiselect' && (
                            <Grid spacing={4}>
                              <FormGroup>
                                {multiSelectDropDown
                                  ?.filter((item) => item.index === index)
                                  ?.map((multiselect, idx) => (
                                    <Grid
                                      item
                                      key={multiselect.id}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '50px',
                                        width: '100%',
                                      }}
                                    >
                                      <List style={{ width: '82%' }}>
                                        <ListItem>
                                          <span style={{ marginRight: '15px' }}>
                                            •
                                          </span>
                                          <Input
                                            name={`${additionalDebt}.multiSelectoption`}
                                            fullWidth
                                            id={multiselect.id}
                                            type="text"
                                            defaultValue={
                                              multiselect.optionLabel
                                            }
                                            onBlur={(e) => {
                                              const newLabel = e.target.value;
                                              handleLabelChangeMultiSelect(
                                                multiselect.id,
                                                newLabel,
                                                multiselect.index
                                              );
                                            }}
                                            className="multi"
                                            style={{
                                              borderBottom: '1px solid #202124',
                                              outline: 'none',
                                              cursor: 'text',
                                              width: '100%',
                                            }}
                                          />
                                        </ListItem>
                                      </List>

                                      <CloseIcon
                                        style={{
                                          color: '#202124',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                          handleRemoveMultiSelect(
                                            multiselect.id
                                          )
                                        }
                                      />
                                    </Grid>
                                  ))}
                              </FormGroup>

                              <ListItem
                                onClick={() =>
                                  addMultiSelectDropDownHandler(index)
                                }
                              >
                                <AddIcon style={{ marginRight: '15px' }} />
                                <ListItemText primary="Add Option" />
                              </ListItem>
                            </Grid>
                          )}

                          {selectedValues[index]?.select === 'singleSelect' && (
                            <Grid spacing={4}>
                              <FormGroup>
                                {multiSelectDropDown
                                  ?.filter((item) => item.index === index)
                                  ?.map((multiselect, idx) => (
                                    <Grid
                                      item
                                      key={multiselect.id}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '50px',
                                        width: '100%',
                                      }}
                                    >
                                      <List style={{ width: '82%' }}>
                                        <ListItem>
                                          <span style={{ marginRight: '15px' }}>
                                            •
                                          </span>
                                          <Input
                                            name={`${additionalDebt}.multiSelectoption`}
                                            id={multiselect.id}
                                            type="text"
                                            defaultValue={
                                              multiselect.optionLabel
                                            }
                                            fullWidth
                                            onBlur={(e) => {
                                              const newLabel = e.target.value;
                                              handleLabelChangeMultiSelect(
                                                multiselect.id,
                                                newLabel,
                                                multiselect.index
                                              );
                                            }}
                                            className="multi"
                                            style={{
                                              width: '100%',
                                              borderBottom: '1px solid #202124',
                                              outline: 'none',
                                              cursor: 'text',
                                            }}
                                          />
                                        </ListItem>
                                      </List>

                                      <CloseIcon
                                        style={{
                                          color: '#202124',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                          handleRemoveMultiSelect(
                                            multiselect.id
                                          )
                                        }
                                      />
                                    </Grid>
                                  ))}
                              </FormGroup>

                              <ListItem
                                onClick={() =>
                                  addMultiSelectDropDownHandler(index)
                                }
                              >
                                <AddIcon style={{ marginRight: '15px' }} />
                                <ListItemText primary="Add Option" />
                              </ListItem>
                            </Grid>
                          )}

                          {selectedValues[index]?.select === 'radioButton' && (
                            <Grid spacing={4}>
                              <FormGroup>
                                {multiSelectDropDown
                                  ?.filter((item) => item.index === index)
                                  ?.map((multiselect, idx) => (
                                    <Grid
                                      item
                                      key={multiselect.id}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '50px',
                                        width: '100%',
                                      }}
                                    >
                                      <List style={{ width: '82%' }}>
                                        <ListItem>
                                          <span style={{ marginRight: '15px' }}>
                                            •
                                          </span>
                                          <Input
                                            name={`${additionalDebt}.multiSelectoption`}
                                            id={multiselect.id}
                                            type="text"
                                            defaultValue={
                                              multiselect.optionLabel
                                            }
                                            fullWidth
                                            onBlur={(e) => {
                                              const newLabel = e.target.value;
                                              handleLabelChangeMultiSelect(
                                                multiselect.id,
                                                newLabel,
                                                multiselect.index
                                              );
                                            }}
                                            className="multi"
                                            style={{
                                              width: '100%',
                                              borderBottom: '1px solid #202124',
                                              outline: 'none',
                                              cursor: 'text',
                                            }}
                                          />
                                        </ListItem>
                                      </List>

                                      <CloseIcon
                                        style={{
                                          color: '#202124',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                          handleRemoveMultiSelect(
                                            multiselect.id
                                          )
                                        }
                                      />
                                    </Grid>
                                  ))}
                              </FormGroup>

                              <ListItem
                                onClick={() =>
                                  addMultiSelectDropDownHandler(index)
                                }
                              >
                                <AddIcon style={{ marginRight: '15px' }} />
                                <ListItemText primary="Add Option" />
                              </ListItem>
                            </Grid>
                          )}

                          {selectedValues[index]?.select === 'checkbox' && (
                            <Grid spacing={4}>
                              <FormGroup>
                                {multiSelectDropDown
                                  ?.filter((item) => item.index === index)
                                  ?.map((multiselect, idx) => (
                                    <Grid
                                      item
                                      key={multiselect.id}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '50px',
                                        width: '100%',
                                      }}
                                    >
                                      {console.log(multiselect, 'multiselect')}
                                      <List style={{ width: '82%' }}>
                                        <ListItem>
                                          <span style={{ marginRight: '15px' }}>
                                            •
                                          </span>
                                          <Input
                                            name={`${additionalDebt}.multiSelectoption`}
                                            id={multiselect.id}
                                            type="text"
                                            defaultValue={
                                              multiselect.optionLabel
                                            }
                                            fullWidth
                                            onBlur={(e) => {
                                              const newLabel = e.target.value;
                                              handleLabelChangeMultiSelect(
                                                multiselect.id,
                                                newLabel,
                                                multiselect.index
                                              );
                                            }}
                                            className="multi"
                                            style={{
                                              width: '100%',
                                              borderBottom: '1px solid #202124',
                                              outline: 'none',
                                              cursor: 'text',
                                            }}
                                          />
                                        </ListItem>
                                      </List>

                                      <CloseIcon
                                        style={{
                                          color: '#202124',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                          handleRemoveMultiSelect(
                                            multiselect.id
                                          )
                                        }
                                      />
                                    </Grid>
                                  ))}
                              </FormGroup>

                              <ListItem
                                onClick={() =>
                                  addMultiSelectDropDownHandler(index)
                                }
                              >
                                <AddIcon style={{ marginRight: '15px' }} />
                                <ListItemText primary="Add Option" />
                              </ListItem>
                            </Grid>
                          )}
                        </div>

                        {/*ACTION BUTTONS*/}
                        <Grid
                          item
                          container
                          className={classes.fieldContainer}
                          alignItems={'center'}
                          justifyContent={'center'}
                        ></Grid>
                      </div>
                    )}
                  </Draggable>
                  {console.log(additionalDebt, 'additionalDebt')}
                </>
              ))}
              {provider.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Fragment>
  );

  return (
    <Grid container justifyContent={'center'} direction={'column'}>
      <FieldArray name="additionalQuestions" component={debtFields} />
    </Grid>
  );
};

export default ResponseQuestions;
