import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Dialog, DialogContent, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import axios from '../../../../../../config/axios';
import './GrantAcceptanceForm.css';
import {
  saveGrantAcceptanceForm,
  deleteGrantAcceptanceFile,
} from '../../../../../../store/actions/applicantActions/programActions/applicantApprovedActions';
import { connect, useDispatch } from 'react-redux';
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from '@mui/x-data-grid-premium';
import { useMemo } from 'react';
import { closeDialog } from '../../../../../../store/actions/dialogActions/dialogActions';
import toast, { Toaster } from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  questionText: {
    fontWeight: 600,
    paddingRight: '1em',
  },
  button: {
    marginLeft: '0.4em',
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
    },
  },

  continueButton: {
    marginLeft: '0.4em',
    backgroundColor: '#1976d2',
    color: 'white !Important',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
      //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    },
  },
}));

const fileRows = [
  {
    id: 'photoIdFront',
    name: 'Photo ID - Drivers License or Govt ID (FRONT)',
    due: 'Due Today',
    required: true,
  },
  {
    id: 'photoIdBack',
    name: 'Photo ID - Drivers License or Govt ID (BACK)',
    due: 'Due Today',
    required: true,
  },
  {
    id: 'proofOfIncome',
    name: 'Proof of Income - (2) Pay Stubs',
    due: 'Due Today',
    required: true,
  },
  {
    id: 'aditionalProofOfIncome',
    name: 'Additional Proof of Income',
    due: 'Optional',
    required: false,
  },
  {
    id: 'bankStatement',
    name: 'Bank Statements - (2) Months',
    due: 'Due Today',
    required: true,
  },
  {
    id: 'proofOfRentersInsurance',
    name: 'Proof of Renters Insurance',
    due: 'Due at Move In',
    required: false,
  },
  {
    id: 'additionalBankStatement',
    name: 'Additional Bank Statement',
    due: 'Optional',
    required: false,
  },
];

const GrantAcceptanceForm = ({
  registeredProgramId,
  registeredProgram,
  program,
  handleCloseForm,
  handleSetForm,
  saveGrantAcceptanceForm,
  deleteGrantAcceptanceFile,
}) => {
  const classes = useStyles();
  const [files, setFiles] = useState({});

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      'e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
    );
  }, []);
  const dispatch = useDispatch();

  console.log(program, 'oiuy');
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    dispatch(closeDialog());
  };
  useEffect(() => {
    if (registeredProgram.grantAcceptanceFormDetails) {
      setFiles(registeredProgram.grantAcceptanceFormDetails);
    }
  }, [registeredProgram.grantAcceptanceFormDetails]);

  const memoizedColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Document Name',
        flex: 2,
        sortable: false,
      },
      {
        field: 'due',
        headerName: 'Due',
        sortable: false,
        flex: 1,
      },
      {
        field: 'upload',
        headerName: 'Upload',
        sortable: false,
        renderCell: (params) => {
          if (!Object.keys(files).includes(params.id))
            return (
              <Button color="primary" component="label" size="small">
                Upload
                <input
                  type="file"
                  hidden
                  onChange={(event) =>
                    setFiles((prevFiles) => ({
                      ...prevFiles,
                      [params.id]: event.target.files,
                    }))
                  }
                />
              </Button>
            );
          return <></>;
        },
      },
      {
        field: 'delete',
        headerName: 'Delete',
        sortable: false,
        renderCell: (params) => {
          if (Object.keys(files).includes(params.id))
            return (
              <Button
                color="secondary"
                component="label"
                size="small"
                onClick={() => {
                  if (files[params.id][0].fileURL)
                    deleteGrantAcceptanceFile(
                      registeredProgramId,
                      params.id,
                      files[params.id]
                    );
                  setFiles((prevFiles) => {
                    const newFiles = {
                      ...prevFiles,
                    };
                    delete newFiles[params.id];
                    return newFiles;
                  });
                }}
              >
                Delete
              </Button>
            );
          return <></>;
        },
      },
    ],
    [files]
  );
  const saveInterval = 5 * 60 * 1000;
  const notify = () => toast.success('Progress Autosaved.');

  useEffect(() => {
    // ... other code ...

    // Create an interval to call saveGovernmentIdForm every 5 seconds
    const intervalId = setInterval(() => {
      if (Object.keys(files).length > 0) {
        saveGrantAcceptanceForm(registeredProgramId, files, false);
        notify();
      }
    }, saveInterval);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  });
  return (
    <Fragment>
      <Toaster />

      <Typography
        variant={'h6'}
        style={{ fontWeight: 600, marginTop: '1em', marginBottom: '1em' }}
      >
        LEAD Acceptance Forms
      </Typography>
      <DataGridPremium
        autoHeight
        hideFooter
        disableSelectionOnClick
        disableColumnMenu
        columns={memoizedColumns}
        rows={fileRows}
        components={{ Toolbar: GridToolbar }}
      />
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        style={{ marginTop: '1.5rem' }}
      >
        <Button
          variant={'outlined'}
          color={'secondary'}
          className={classes.button}
          onClick={() => handleCloseForm()}
        >
          Cancel
        </Button>
        <Button
          variant={'outlined'}
          color={'primary'}
          className={classes.button}
          onClick={() => {
            saveGrantAcceptanceForm(registeredProgramId, files, false);
            handleCloseForm();
          }}
        >
          Save for Later
        </Button>
        <Button
          variant={'outlined'}
          color={'primary'}
          className={classes.continueButton}
          disabled={
            fileRows.filter((file) => file.required).length !==
            Object.keys(files).length
          }
          onClick={() => {
            saveGrantAcceptanceForm(registeredProgramId, files, true);
            handleSetForm('scholarConsentForm');
          }}
        >
          Continue{' '}
          <span>
            <strong> &#8594;</strong>
          </span>
        </Button>
      </Grid>
    </Fragment>
  );
};

const actions = {
  saveGrantAcceptanceForm,
  deleteGrantAcceptanceFile,
};

export default connect(null, actions)(GrantAcceptanceForm);
