/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import ReviewProgramItem from "./item/ReviewProgramItem";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  answerText: {
    fontWeight: 600,
  },
  break:{
    borderColor: "red",
    borderWidth: "3px",
    border: "5px"
  },
}));

const ShortResponsesFormReview = ({
  shortResponsesForm,
  registeredProgram,
}) => {
  const classes = useStyles();
  let checkObjectKeys = [];
  
  const [newQuestionSeq, setNewQuestionSeq] = useState([]);
  if (registeredProgram && registeredProgram?.shortResponsesFormDetails) {
    const orderedMap = new Map(
      Object.entries(registeredProgram.shortResponsesFormDetails)
    );
    for (const [key, value] of orderedMap) {
      checkObjectKeys.push(key);
    }
  }

  const changeSequence = () => {
    let newQuestion = [];
    let a = registeredProgram?.programDetails?.additionalQuestions?.map(
      (parentItems) => {
        let b = checkObjectKeys?.map((childItems) => {
          if (parentItems?.question == childItems) {
            let c = newQuestion?.push(childItems);
          }
        });
      }
    );
    return newQuestion;
  };

  useEffect(() => {
    if (checkObjectKeys?.length > 0) {
      const c = changeSequence();
      if (
        newQuestionSeq?.length !=
        registeredProgram?.programDetails?.additionalQuestions?.length
      ) {
        setNewQuestionSeq(c);
      }
    }
  }, [checkObjectKeys]);

  return (
    <Grid
      container
      direction={"column"}
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}>
      <Grid item>
        <Typography className={classes.title}> Short Responses </Typography>
      </Grid>

      {newQuestionSeq &&
        newQuestionSeq?.map((question, index) => (
          <><ReviewProgramItem
            question={"Q: " + question}
            value={shortResponsesForm?.[question]}
            flexColumn /><Grid item style={{ borderBottom: "1px solid" }}>

            </Grid></>
        ))}
     
    </Grid>
  );
};

export default ShortResponsesFormReview;
