import React from 'react';

export const SettingsIcon = () => {
    return (
        <svg width={20} height={23} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 14.779c2.022 0 3.66-1.626 3.66-3.631 0-2.006-1.638-3.632-3.66-3.632-2.022 0-3.66 1.626-3.66 3.632 0 2.005 1.638 3.631 3.66 3.631Zm0-2c-.908 0-1.645-.73-1.645-1.631 0-.902.737-1.632 1.645-1.632s1.645.73 1.645 1.632c0 .9-.737 1.631-1.645 1.631Z"
                fill="#92929D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.094 2.487A2.964 2.964 0 0 1 10 .147c1.404 0 2.616.976 2.906 2.34l.056.265a8.974 8.974 0 0 1 2.888 1.652l.257-.084a2.98 2.98 0 0 1 3.495 1.327 2.93 2.93 0 0 1-.589 3.667l-.202.18a8.816 8.816 0 0 1-.002 3.306l.204.181a2.93 2.93 0 0 1 .59 3.667 2.98 2.98 0 0 1-3.496 1.326l-.258-.083a9.006 9.006 0 0 1-2.887 1.653l-.056.264A2.964 2.964 0 0 1 10 22.148a2.964 2.964 0 0 1-2.906-2.34l-.056-.265A8.973 8.973 0 0 1 4.15 17.89l-.257.084a2.98 2.98 0 0 1-3.495-1.328 2.93 2.93 0 0 1 .589-3.666l.202-.18a8.817 8.817 0 0 1 .001-3.306l-.203-.181a2.93 2.93 0 0 1-.59-3.667A2.98 2.98 0 0 1 3.894 4.32l.258.084a9.009 9.009 0 0 1 2.887-1.653l.056-.264ZM10 2.147c-.45 0-.84.314-.933.752l-.178.837c-.078.367-.355.66-.72.758a6.993 6.993 0 0 0-3.061 1.754 1.014 1.014 0 0 1-1.022.24l-.82-.267a.957.957 0 0 0-1.122.426.94.94 0 0 0 .189 1.178l.644.572c.28.25.397.636.301.999a6.833 6.833 0 0 0-.001 3.504.995.995 0 0 1-.302.999l-.642.571a.94.94 0 0 0-.19 1.178.957.957 0 0 0 1.123.426l.82-.266c.359-.117.754-.024 1.023.24a6.954 6.954 0 0 0 3.06 1.75c.364.1.642.392.72.759l.178.84a.952.952 0 0 0 .933.75c.45 0 .84-.313.933-.75l.178-.838c.077-.367.355-.66.72-.758a6.99 6.99 0 0 0 3.061-1.754 1.014 1.014 0 0 1 1.022-.24l.82.267a.957.957 0 0 0 1.122-.427.94.94 0 0 0-.189-1.177l-.644-.572a.995.995 0 0 1-.301-.999 6.833 6.833 0 0 0 .001-3.504.995.995 0 0 1 .302-1l.642-.57a.94.94 0 0 0 .19-1.178.957.957 0 0 0-1.123-.426l-.82.266c-.359.117-.754.024-1.023-.24a6.954 6.954 0 0 0-3.06-1.75 1.003 1.003 0 0 1-.72-.76l-.178-.838A.952.952 0 0 0 10 2.147Z"
                fill="#92929D"
            />
        </svg>
    );
};
