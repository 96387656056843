import React, { useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Grid } from "@mui/material";

import { connect } from "react-redux";
import { fetchAllCategories } from "../store/actions/adminActions/categoryActions/adminCategoryActions";
import leadHeader from "../assets/landingPage/leadHeader2023.jpg";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  headlineContainer: {
    height: "804px",
    left: "-2px",
    top: "96px",
    background: "linear-gradient(225.34deg, #65A65A 0%, #3178A5 101.17%)",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  headlineText: {
    color: "white",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "48px",
    lineHeight: "67.58px",
    letter: "0.2px",
  },
  headlineSubText: {
    color: "white",
    fontWeight: "400",
    fontFamily: "Roboto",
    fontSize: "18px",
    lineHeight: "28px",
    letter: "0.1px",
  },

  image26: {
    marginTop: "54px",
    borderRadius: "10px",
    width: "60%",
    height: "203px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  image27: {
    marginLeft: "100px",
    marginTop: "-60px",
    borderRadius: "10px",
    width: "60%",
    height: "323px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0px",
    },
  },
  image261: {
    marginTop: "-50px",
    borderRadius: "10px",

    width: "60%",
    height: "250px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  programsHeaderContainer: {
    marginTop: "0px",
  },
  bodyContainer: {
    // marginLeft: '30px',
    // marginRight: '30px'
  },

  photosContainer: {
    marginTop: "0px",
    backgroundColor: "rgba(120, 90, 164, .15)",
  },

  scholarProgramsHeader: {
    color: "#171725",
    fontFamily: "Poppins",
    fontSize: "36px",
    fontWeight: "600",
    lineHeight: "54px",
    letterSpacing: "0.14999991655349731px",
    textAlign: "center",
    paddingTop: "40px",
  },
  programsContainer: {
    marginTop: "44px",
    marginBottom: "40px",
    paddingLeft: "2em",
    paddingRight: "2em",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1em",
      paddingRight: "1em",
    },
  },
  infoContainer: {
    marginTop: "169px",
    height: "922px",
    background: "linear-gradient(225.34deg, #DD13DD 0%, #1323DD 101.17%)",
    mixBlendMode: "normal",
  },

  newsContainer: {
    marginTop: "44px",
    paddingLeft: "5em",
    // paddingRight: '2em',
    marginBottom: "80px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1em",
      paddingRight: "1em",
    },
  },
  button: {
    width: "260px",
    height: "58px",
    fontSize: "30px",
    fontWeight: 600,
    borderRadius: "10px",
    backgroundColor: "#35BA35",
    color: "white",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#35BA35",
      color: "white",
    },
  },

  newsCard: {
    width: "366px",
  },
  pageHeader: {
    width: "100%",
    height: "731px",
  },
}));

const actions = {
  fetchAllCategories,
};

const mapStateToProps = (state) => {
  let categories = [];

  if (
    state.adminCategories.allCategories &&
    state.adminCategories.allCategories.length > 0
  ) {
    categories = state.adminCategories.allCategories;
  }

  return {
    categories,
  };
};

const Maintenance = ({ fetchAllCategories, categories }) => {
  const matches = useMediaQuery("(min-width:480px)");
  //   const matchesMax = useMediaQuery("(min-width:564px)");
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    fetchAllCategories();
  }, [fetchAllCategories]);

  return (
    <Grid container direction={"column"}>
      {/*HEADLINE CONTAINER*/}
      <Grid
        item
        style={{ position: "relative" }}
        className={classes.headlineImage}
      >
        <img className={classes.pageHeader} src={leadHeader} alt="LEAD Logo" />
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(Maintenance);
