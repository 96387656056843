import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import Sidebar from "../../../ui/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import NeedsReviewTable from "../../../ui/admin/dashboard/NeedsReviewTable";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  fetchAdminProgram,
  fetchAllProgramCompletedApplications,
  fetchAllProgramReviewedApplications,
  fetchProgramDashboardValues,
} from "../../../store/actions/adminActions/programActions/adminProgramActions";
import ReviewedTable from "../../../ui/admin/dashboard/ReviewedTable";

const actions = {
  fetchAdminProgram,
  fetchProgramDashboardValues,
  fetchAllProgramCompletedApplications,
  fetchAllProgramReviewedApplications,
};

const mapStateToProps = (state) => {
  let program = {};
  let programCompletedApplications = [];
  let programReviewedApplications = [];

  if (
    state.adminPrograms.adminProgram &&
    state.adminPrograms.adminProgram.length > 0
  ) {
    program = state.adminPrograms.adminProgram[0];
  }

  if (
    state.adminPrograms.allProgramCompletedApplications &&
    state.adminPrograms.allProgramCompletedApplications.length > 0
  ) {
    programCompletedApplications =
      state.adminPrograms.allProgramCompletedApplications.filter(
        (array) => array.reviewerId === state.firebase.profile.uid
      );
  }
  if (
    state.adminPrograms.allProgramReviewedApplications &&
    state.adminPrograms.allProgramReviewedApplications.length > 0
  ) {
    programReviewedApplications =
      state.adminPrograms.allProgramReviewedApplications.filter(
        (array) => array.reviewerId === state.firebase.profile.uid
      );
  }

  return {
    program,
    programCompletedApplications,
    programReviewedApplications,
    profile: state.firebase.profile,
    loading: state.loading.loading,
  };
};

const ReviewerViewProgramDashboard = ({
  fetchAdminProgram,
  fetchProgramDashboardValues,
  fetchAllProgramCompletedApplications,
  fetchAllProgramReviewedApplications,
  program,
  programCompletedApplications,
  programReviewedApplications,
  profile,
  loading,
}) => {
  // const classes = useStyles();
  // const theme = useTheme();
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get("id");

  useEffect(() => {
    fetchAdminProgram(id);
    fetchProgramDashboardValues(id);
    fetchAllProgramCompletedApplications(id);
    fetchAllProgramReviewedApplications(id);
  }, [
    fetchAdminProgram,
    fetchProgramDashboardValues,
    fetchAllProgramCompletedApplications,
    fetchAllProgramReviewedApplications,
    id,
  ]);

  return (
    <Grid container>
      <Grid item md={2}>
        <Sidebar />
      </Grid>

      <Grid item md={10} style={{ paddingLeft: "1.5em" }}>
        <Grid item container direction={"column"}>
          <Grid item>
            <Typography variant={"h4"}>
              {program.programName
                ? `${program.programName} Dashboard`
                : "Welcome"}
            </Typography>
          </Grid>

          {/*BOXES*/}

          {/*BAR/PIE CHARTS*/}

          {/*APPROVED BOXES*/}

          {/*REVIEW/NATIONALITY*/}
          <Grid item style={{ marginTop: "1.5em" }}>
            <Grid item container>
              <Grid item md={12} style={{ paddingRight: "2em" }}>
                {/*NEEDS REVIEW - ASSIGNED*/}
                <NeedsReviewTable
                  rows={programCompletedApplications}
                  title={"Needs Review (Assigned)"}
                  reviewStatus={"assigned"}
                  profile={profile}
                />

                {/*REVIEWED TABLE*/}
                <ReviewedTable
                  rows={programReviewedApplications}
                  title={"Reviewed"}
                  profile={profile}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(ReviewerViewProgramDashboard);
