import React, { useEffect, useMemo, useState } from "react";
import { Grid, Typography, Button, Paper, Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Sidebar from "../../../ui/sidebar/Sidebar";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchAllEmails } from "../../../store/actions/adminActions/dashboardActions/dashboardActions";
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { makeStyles } from "@mui/styles";
import { format, fromUnixTime } from "date-fns";
import { escapeRegExp } from "lodash";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1.5em",
    backgroundColor: "rgb(250, 250, 251)",
  },
  heading: {
    marginBottom: "3rem",
  },
  tableContainer: {
    padding: "1rem",
    gap: "1rem",
  },
  applicationsLength: {
    marginLeft: "1rem",
  },
}));

const mapStateToProps = (state) => {
  let emails = [];

  if (state.adminDashboard.emails && state.adminDashboard.emails.length > 0) {
    emails = state.adminDashboard.emails;
  }

  return {
    emails,
    profile: state.firebase.profile,
  };
};

const EmailLogs = ({ emails, fetchAllEmails }) => {
  const matches = useMediaQuery("(min-width:480px)");
  const history = useHistory();
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      "e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    );
  }, []);

  useEffect(() => {
    fetchAllEmails();
  }, [fetchAllEmails]);

  const memoizedColumns = useMemo(() => {
    return [
      {
        field: "serialNo",
        headerName: "Id",
      },
      {
        field: "subject",
        headerName: "Subject",
        flex: 1,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "content",
        headerName: "Content",
        flex: 1,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "sentBy",
        headerName: "Sent By",
        flex: 1,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "sentAt",
        headerName: "Sent At",
        flex: 1,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "emails",
        headerName: "Sent To",
        flex: 2,
        valueGetter: (param) => param.value || "N/A",
      },
    ];
  }, []);

  return (
    <Grid container>
      <Grid item md={2} style={{ width: `${matches ? "" : "100%"}` }}>
        <Sidebar />
      </Grid>
      <Grid className={classes.container} item md={10}>
        <Typography className={classes.heading} variant="h5">
          Dashboard
        </Typography>
        <Paper elevation={0}>
          <Grid className={classes.tableContainer} container direction="column">
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex">
                <Typography variant="h6">Total Emails:-</Typography>
                <Typography className={classes.applicationsLength} variant="h6">
                  {emails.length}
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push("/admin/dashboard")}
              >
                Dashboard
              </Button>
            </Grid>
            <Box height={320} width="100%">
              <DataGridPremium
                columns={memoizedColumns}
                rows={emails.map((email, index) => ({
                  serialNo: index + 1,
                  ...email,
                  sentAt:
                    email.sentAt &&
                    format(fromUnixTime(email.sentAt), "MM/dd/yyyy"),
                }))}
                onCellClick={(params) => {
                  history.push(`/admin/dashboard/email/edit?id=${params?.id}`);
                  console.log("e.target.value", params);
                }}
                disableColumnMenu
                rowsPerPageOptions={[]}
                components={{ Toolbar: GridToolbar }}
              />
            </Box>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, {
  fetchAllEmails,
})(EmailLogs);
