import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import ReviewProgramItem from "./item/ReviewProgramItem";
import { format, parseISO } from 'date-fns';
const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  answerText: {
    fontWeight: 600,
  },
}));

const ConfirmationFormReview = ({ confirmationForm }) => {
  const classes = useStyles();
  // const theme = useTheme();
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      direction={"column"}
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
    >
      <Grid item>
        <Typography className={classes.title}>Confirmation Form</Typography>
      </Grid>

      <ReviewProgramItem
        question={
          "I certify that all information provided as part of this application is true and correct to the best of my knowledge, and I understand that once submitted, I can not make any changes or return to this application. "
        }
        value={confirmationForm?.iCertifyAllInformation ? "Yes" : "No"}
      />
      <ReviewProgramItem
        question={"Signature"}
        value={confirmationForm?.signature}
        
      />
      

     <ReviewProgramItem
         question={"Date"} 
         value={ 
           confirmationForm?.confirmationDate
             ? format( 
                 parseISO(confirmationForm?.confirmationDate), 
                 "EEE MMM do y" 
               ) 
             : "" 
         } 
       /> 
    </Grid>
  );
};

export default ConfirmationFormReview;
