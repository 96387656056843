const grades = [
  {
    id: 1,
    fieldName: '9thGrade',
    value: '9th Grade'
  },
  {
    id: 2,
    fieldName: '10thGrade',
    value: '10th Grade'
  },
  {
    id: 3,
    fieldName: '11thGrade',
    value: '11th Grade'
  },
  {
    id: 4,
    fieldName: '12thGrade',
    value: '12th Grade'
  },
  {
    id: 5,
    fieldName: 'collegeFreshman',
    value: 'College Freshman',
  },
  {
    id: 6,
    fieldName: 'collegeSophomore',
    value: 'College Sophomore'
  },
  {
    id: 7,
    fieldName: 'collegeJunior',
    value: 'College Junior'
  },
  {
    id: 8,
    fieldName: 'collegeSenior',
    value: 'College Senior'
  },
  {
    id: 9,
    fieldName: 'collegeGraduate',
    value: 'College Graduate'
  }

]

const leftGrades = [
  {
    id: 1,
    fieldName: '9thGrade',
    value: '9th Grade'
  },
  {
    id: 2,
    fieldName: '10thGrade',
    value: '10th Grade'
  },
  {
    id: 3,
    fieldName: '11thGrade',
    value: '11th Grade'
  },
  {
    id: 4,
    fieldName: '12thGrade',
    value: '12th Grade'
  },

]

const centerGrades = [

  {
    id: 5,
    fieldName: 'collegeFreshman',
    value: 'College Freshman'
  },
  {
    id: 6,
    fieldName: 'collegeSophomore',
    value: 'College Sophomore'
  },
  {
    id: 7,
    fieldName: 'collegeJunior',
    value: 'College Junior'
  },
  {
    id: 8,
    fieldName: 'collegeSenior',
    value: 'College Senior'
  },


]

const rightGrades = [

  {
    id: 9,
    fieldName: 'collegeGraduate',
    value: 'College Graduate'
  },

]

const checklist1 = [
  {
    id: 0,
    applicationStage: "Application Fee",
    formName: "applicationFeeSection",
  },
  {
    id: 14,
    applicationStage: "Educational Interest",
    formName: "applicationInterests",
  },
  {
    id: 2,
    applicationStage: "Scholarship Form",
    formName: "scholarshipForm",
  },
  {
    id: 3,
    applicationStage: `Tax Form's Uploaded`,
    formName: "taxForm",
  },
  {
    id: 4,
    applicationStage: `Personal Information Form`,
    formName: "personalInformationForm",
  },
  {
    id: 5,
    applicationStage: `Family Information Form`,
    formName: "familyInformationForm",
  },
  {
    id: 6,
    applicationStage: `Academic Information`,
    formName: "academicInformationForm",
  },
  {
    id: 12,
    applicationStage: `Recommendations`,
    formName: "recommendersForm",
  },
  {
    id: 7,
    applicationStage: `Extracurricular, Community Activities and Employment Information`,
    formName: "extracurricularForm",
  },
  {
    id: 13,
    applicationStage: `Covid Protocol`,
    formName: "covidProtocolForm",
  },
  {
    id: 8,
    applicationStage: `Resume`,
    formName: "resumeForm",
  },
  {
    id: 9,
    applicationStage: `Transcript and Standardized Test Score`,
    formName: "transcriptForm",
  },
  {
    id: 11,
    applicationStage: `Short Responses and Essay`,
    formName: "shortResponsesForm",
  },
  {
    id: 10,
    applicationStage: `Additional Documents`,
    formName: "additionalDocumentsForm",
  },
  {
    id: 15,
    applicationStage: `Confirmation`,
    formName: "confirmationForm",
  },
];

const checklist2 = [
  {
    id: 1,
    applicationStage: "LEAD Acceptance Forms",
    formName: "scholarConsentForm",
  },
  {
    id: 2,
    applicationStage: "Government ID & Headshot",
    formName: "governmentIdForm",
  },
  {
    id: 3,
    applicationStage: "Payment",
    formName: "paymentForm",
  },
  {
    id: 6,
    applicationStage: "Travel Form",
    formName: "travelInformationForm",
  },
  {
    id: 5,
    applicationStage: "Student Questionnaire",
    formName: "studentQuestionnaireForm",
  },
  {
    id: 7,
    applicationStage: "Download Program Packet",
    formName: "downloadProgramPacketForm",
  },
  {
    id: 4,
    applicationStage: "Required Pre-Arrival Forms",
    formName: "requiredDocumentationForm",
  },
];
const checklistWithoutPayment2 = [
  {
    id: 1,
    applicationStage: "LEAD Acceptance Forms",
    formName: "scholarConsentForm",
  },
  {
    id: 2,
    applicationStage: "Government ID & Headshot",
    formName: "governmentIdForm",
  },

  {
    id: 6,
    applicationStage: "Travel Form",
    formName: "travelInformationForm",
  },
  {
    id: 5,
    applicationStage: "Student Questionnaire",
    formName: "studentQuestionnaireForm",
  },
  {
    id: 7,
    applicationStage: "Download Program Packet",
    formName: "downloadProgramPacketForm",
  },
  {
    id: 4,
    applicationStage: "Required Pre-Arrival Forms",
    formName: "requiredDocumentationForm",
  },
]
export default {
  grades,
  leftGrades,
  centerGrades,
  rightGrades,
  checklist1,
  checklist2,
  checklistWithoutPayment2
}
