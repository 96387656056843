import { createReducer } from '../../../../common/util/reducerUtil';
import {
  FETCH_ALL_PROGRAMS,
  FETCH_ALL_APPLICANT_REGISTERED_PROGRAMS,
  FETCH_PROGRAM,
  FETCH_REGISTERED_PROGRAM,
} from '../../../constants/applicantConstants/programConstants/applicantProgramConstants';

const initialState = {
  allPrograms: [],
  program: [],
  registeredProgram: [],
  allRegisteredPrograms: [],
};

const getAllPrograms = (state, payload) => {
  return {
    ...state,
    allPrograms: payload.programs,
  };
};

const getProgram = (state, payload) => {
///  console.log(payload, 'payloadMissing');
  return {
    ...state,
    program: payload.program,
  };
};

const getRegisteredProgram = (state, payload) => {
 /// console.log(payload, 'Registered');

  return {
    ...state,
    registeredProgram: payload.registeredProgram,
  };
};

const getAllRegisteredPrograms = (state, payload) => {
///  console.log(payload, 'AllRegistered');

  return {
    ...state,
    allRegisteredPrograms: payload.allRegisteredPrograms,
  };
};

export default createReducer(initialState, {
  [FETCH_ALL_PROGRAMS]: getAllPrograms,
  [FETCH_PROGRAM]: getProgram,
  [FETCH_REGISTERED_PROGRAM]: getRegisteredProgram,
  [FETCH_ALL_APPLICANT_REGISTERED_PROGRAMS]: getAllRegisteredPrograms,
});
