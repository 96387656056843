import React from 'react';

export const ApplicantsIcon = () => {
    return (
        <svg width={22} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 6.47c-1.657 0-3-1.43-3-3.192C8 1.516 9.343.087 11 .087s3 1.429 3 3.191c0 1.763-1.343 3.191-3 3.191Zm0-2.128c.552 0 1-.476 1-1.064 0-.587-.448-1.064-1-1.064s-1 .477-1 1.064c0 .588.448 1.064 1 1.064Zm-1 2.127h2c1.657 0 3 1.43 3 3.192v1.064c0 1.762-1.343 3.19-3 3.19h-2c-1.657 0-3-1.428-3-3.19V9.66c0-1.763 1.343-3.192 3-3.192Zm0 2.128c-.552 0-1 .476-1 1.064v1.064c0 .587.448 1.063 1 1.063h2c.552 0 1-.476 1-1.063V9.66c0-.588-.448-1.064-1-1.064h-2Zm8 7.446c-1.657 0-3-1.428-3-3.191s1.343-3.191 3-3.191 3 1.428 3 3.191c0 1.762-1.343 3.191-3 3.191Zm0-2.127c.552 0 1-.476 1-1.064 0-.588-.448-1.064-1-1.064s-1 .476-1 1.064c0 .588.448 1.064 1 1.064Zm-1 2.127h2c1.657 0 3 1.43 3 3.192v1.063c0 1.763-1.343 3.192-3 3.192h-2c-1.657 0-3-1.43-3-3.192v-1.063c0-1.763 1.343-3.192 3-3.192Zm0 2.128c-.552 0-1 .476-1 1.064v1.063c0 .588.448 1.064 1 1.064h2c.552 0 1-.476 1-1.064v-1.063c0-.588-.448-1.064-1-1.064h-2ZM4 16.043c-1.657 0-3-1.428-3-3.191s1.343-3.191 3-3.191 3 1.428 3 3.191c0 1.762-1.343 3.191-3 3.191Zm0-2.127c.552 0 1-.476 1-1.064 0-.588-.448-1.064-1-1.064s-1 .476-1 1.064c0 .588.448 1.064 1 1.064Zm-1 2.127h2c1.657 0 3 1.43 3 3.192v1.063c0 1.763-1.343 3.192-3 3.192H3c-1.657 0-3-1.43-3-3.192v-1.063c0-1.763 1.343-3.192 3-3.192Zm0 2.128c-.552 0-1 .476-1 1.064v1.063c0 .588.448 1.064 1 1.064h2c.552 0 1-.476 1-1.064v-1.063c0-.588-.448-1.064-1-1.064H3Z"
                fill="#92929D"
            />
        </svg>
    );
};
