import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { red } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  dropzone: {
    border: "2px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "5px",
    width: "100%",
    height: "100%",
    cursor: "pointer",
    outline: "none",
    padding: 12,
  },
  text: {
    textAlign: "center",
  },
  icon: {
    fontSize: "3em",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
    },
  },
}));

const DropzoneInput = ({
  setFiles,
  multiple,
  accept,
  buttonOnly,
  Maxsize,
  Minsize,
  error,
  setError,
  icon,
}) => {
  const classes = useStyles();
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      );
    },
    [setFiles]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    maxSize: Maxsize,
    minSize: Minsize,
    multiple: multiple ? multiple : false,
    accept: accept ? accept : ".pdf, .jpg, .jpeg, .png, .mp4, .mov",
    noClick: true,
    noKeyboard: true,
  });

  return (
    <Grid
      item
      container
      // alignItems={"center"}
      // justifyContent={"space-evenly"}
      // direction="column"
      {...getRootProps()}
      className={!buttonOnly && classes.dropzone}
    >
      <input {...getInputProps()} />
      {!buttonOnly && (
        <>
          <CloudUploadIcon
            className={classes.icon}
            style={{
              margin: "auto",
            }}
          />
          {/*
          
          
          {error && (
            <div
              style={{ display: "flex", justifyContent: "end", color: "red" }}
            >
              <span>* </span>
              File required
            </div>
          )} */}

          <Typography
            className={classes.text}
            variant="caption"
            sx={{ paddingY: 1 }}
          >
            Drag 'n' drop some files here, or click to select files
          </Typography>
        </>
      )}

      {icon ? (
        <IconButton color="primary" onClick={open}>
          <CloudUploadIcon />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={open}
          style={{ margin: "auto" }}
          sx={{ marginBottom: 1, lineHeight: "initial" }}
          size="small"
        >
          Upload
        </Button>
      )}

      {/* <p style={{ color: "red", padding: 5, margin: 0, fontSize: 14 }}>
          {errors}
        </p> */}
    </Grid>
  );
};

export default DropzoneInput;
