import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../../asyncActions/asyncActions";
import cuid from "cuid";
import {
  FETCH_AFFILIATE,
  FETCH_ALL_AFFILIATES,
} from "../../../constants/adminConstants/affiliateConstants/adminAffiliateConstants";
import { format, parseISO } from "date-fns";

export const fetchAllAffiliates = () => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const affiliatesQuery = firestore
      .collection("affiliates")
      .orderBy("affiliateName", "asc");

    const programsQuery = firestore.collection("registeredPrograms");
    const userInquiry = firestore.collection("users");

    try {
      dispatch(asyncActionStart());
      let query = await affiliatesQuery.get();

      let affiliates = [];

      for (let i = 0; i < query.docs.length; i++) {
        let promoCodeCountQuery = await programsQuery
          .where("promoCode", "==", `${query.docs[i].data().affiliateCode}`)
          .get();

        let userName = await userInquiry
          .doc(`${query.docs[i].data().addedBy}`)
          .get();

        let affiliate = {
          ...query.docs[i].data(),
          id: query.docs[i].id,
          numberOfUses: promoCodeCountQuery.docs.length,
          addedByName: userName.data().fullName,
        };
        affiliates.push(affiliate);
      }

      dispatch({
        type: FETCH_ALL_AFFILIATES,
        payload: { affiliates },
      });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const fetchAffiliate = (id) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const affiliatesQuery = firestore.collection("affiliates").doc(`${id}`);
    const registeredProgramsQuery = firestore.collection("registeredPrograms");
    // const userInquiry = firestore.collection("users");

    try {
      dispatch(asyncActionStart());
      let query = await affiliatesQuery.get();

      const programsQuery = firestore
        .collection("programs")
        .where(
          firebase.firestore.FieldPath.documentId(),
          "in",
          query.data().programsAffiliated
        );

      const programs = await programsQuery.get();

      let affiliate = [];
      if (query.exists) {
        let promoCodeCountQuery = await registeredProgramsQuery
          .where("promoCode", "==", `${query.data().affiliateCode}`)
          .get();

        let affiliateInfo = {
          ...query.data(),
          numberOfUses: promoCodeCountQuery.docs.length,
          id: query.id,
          programsAffiliatedName: programs.docs.map(
            (doc) => doc.data().programName
          ),
        };
        affiliate.push(affiliateInfo);
      }

      dispatch({
        type: FETCH_AFFILIATE,
        payload: { affiliate },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const addNewAffiliate = (values) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const firebaseQuery = firebase.auth().currentUser;

    const affiliatesQuery = firestore.collection("affiliates");

    try {
      dispatch(asyncActionStart());

      const affiliateName = values.affiliateName.replace(/\s/g, "");
      const randomNumber = cuid();
      const affiliateCode = (
        affiliateName.substring(0, 3) +
        randomNumber.slice(randomNumber.length - 4)
      ).toLocaleUpperCase();

      await affiliatesQuery.add({
        ...values,
        affiliateCode: affiliateCode,
        affiliateCodeExpiryDate: values?.affiliateCodeExpiry
          ? format(parseISO(values.affiliateCodeExpiry), "MM/dd/yyyy")
          : "",
        addedBy: firebaseQuery.uid,
        createdAt: new Date().toISOString(),
      });

      dispatch(fetchAllAffiliates());
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateAffiliate = (id, values) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    // const firebase = getFirebase();
    const firestore = getFirestore();

    const affiliatesQuery = firestore.collection("affiliates").doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      await affiliatesQuery.update({
        ...values,
        affiliateCodeExpiryDate: values?.affiliateCodeExpiry
          ? format(parseISO(values.affiliateCodeExpiry), "MM/dd/yyyy")
          : "",
        createdAt: new Date().toISOString(),
      });

      const query = await affiliatesQuery.get();

      const registeredProgramQuery = firestore
        .collection("registeredPrograms")
        .where("promoCode", "==", `${query?.data()?.affiliateCode}`);

      const registeredPrograms = await registeredProgramQuery.get();

      for (let i = 0; i < registeredPrograms.docs.length; i++) {
        if (values?.scholarshipWaiver) {
          firestore
            .collection("registeredPrograms")
            .doc(`${registeredPrograms.docs[i].id}`)
            .update({
              scholarshipFormCompleted: true,
              taxFormsUploaded: true,
              scholarshipFormDetails:
                registeredPrograms.docs[i].data()?.scholarshipFormDetails &&
                Object.keys(
                  registeredPrograms.docs[i].data()?.scholarshipFormDetails
                ).length
                  ? { ...registeredPrograms.docs[i].data()?.scholarshipFormDetails }
                  : {
                      applyForAScholarship: "No",
                      documentsTrueAndCorrect: false,
                      qualifyForScholarship: false,
                      scholarEmail: "",
                      scholarFirstName: "",
                      scholarLastName: "",
                      scholarPhoneNumber: "",
                      sponsoringConsent: false,
                      understandRequiredDocuments: false,
                    },
            });
        } else {
          firestore
            .collection("registeredPrograms")
            .doc(`${registeredPrograms.docs[i].id}`)
            .update({
              scholarshipFormCompleted: false,
              taxFormsUploaded: false,
            });
        }
      }

      dispatch(fetchAllAffiliates());
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const deleteAffiliate = (id) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    // const firebase = getFirebase();
    const firestore = getFirestore();

    const affiliatesQuery = firestore.collection("affiliates").doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      await affiliatesQuery.delete();

      dispatch(fetchAllAffiliates());
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateAffiliateField = (id, key, value) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    // const firebase = getFirebase();
    const firestore = getFirestore();

    const affiliatesQuery = firestore.collection("affiliates").doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      await affiliatesQuery.update({
        [key]: value,
      });

      dispatch(fetchAllAffiliates());
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
