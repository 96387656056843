import React, { useEffect, useState, useMemo } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Box,
} from "@mui/material";
import Sidebar from "../../../ui/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import {
  clearAdminProgram,
  closeAdminProgram,
  deleteAdminProgram,
  fetchAllPrograms,
} from "../../../store/actions/adminActions/programActions/adminProgramActions";
import { connect } from "react-redux";
import { format, parseISO } from "date-fns";
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { CustomToolbar, escapeRegExp } from "../../../common/tableUtils";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#171725",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight: "24px",
    letter: "0.1px",
  },
}));

const actions = {
  fetchAllPrograms,
  deleteAdminProgram,
  clearAdminProgram,
  closeAdminProgram,
};

const mapStateToProps = (state) => {
  let programs = [];

  if (
    state.adminPrograms.allPrograms &&
    state.adminPrograms.allPrograms.length > 0
  ) {
    programs = state.adminPrograms.allPrograms;
  }

  return {
    programs,
    profile: state.firebase.profile,
  };
};

const AdminPrograms = ({
  fetchAllPrograms,
  closeAdminProgram,
  deleteAdminProgram,
  clearAdminProgram,
  programs,
  profile,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [programId, setProgramId] = useState(null);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchText, setSearchText] = useState("");

  const history = useHistory();

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      "e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    );
  }, []);

  useEffect(() => {
    clearAdminProgram();
    fetchAllPrograms();
  }, [fetchAllPrograms, clearAdminProgram]);

  useEffect(() => {
    const arr = programs.map((program, index) => ({
      ...program,
      serialNo: index + 1,
      applicationDeadline:
        program.applicationDeadline &&
        format(parseISO(program?.applicationDeadline), "EEE MMM do y"),
      programStartDate:
        program.programStartDate &&
        format(parseISO(program?.programStartDate), "EEE MMM do y"),
      programEndDate:
        program.programEndDate &&
        format(parseISO(program?.programEndDate), "EEE MMM do y"),
    }));
    setRows(arr);
    if (searchText) {
      setSearchText(searchText);
      const searchRegex = new RegExp(escapeRegExp(searchText), "i");
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
    }
  }, [programs]);

  const handleDeleteProgram = () => {
    deleteAdminProgram(programId);
    setDialogOpen(false);
  };

  const handleCloseProgram = (id) => {
    closeAdminProgram(id);
  };

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filtered = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };

  const memoizedColumns = useMemo(() => {
    return [
      { field: "serialNo", headerName: "Id", width: 100 },
      { field: "programName", headerName: "Program Name", width: 180 },
      {
        field: "applicationDeadline",
        headerName: "Application Deadline",
        width: 180,
      },
      {
        field: "programStartDate",
        headerName: "Program Start Date",
        width: 180,
      },
      {
        field: "programEndDate",
        headerName: "Program End Date",
        width: 180,
      },
      { field: "addedByName", headerName: "Added By", width: 180 },
      {
        field: "",
        headerName: "Actions",
        width: 320,
        renderCell: (param) => (
          <Box display="flex" justifyContent="space-evenly" width="100%">
            {profile.isAdmin && (
              <>
                <Button
                  style={{
                    marginLeft: "0.5em",
                    backgroundColor: theme.palette.info.main,
                    color: "white",
                  }}
                  variant={"contained"}
                  size={"small"}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/admin/program/edit?id=${param.id}`);
                  }}
                >
                  Edit
                </Button>
                <Button
                  style={{
                    marginLeft: "0.5em",
                    backgroundColor: theme.palette.error.main,
                    color: "white",
                  }}
                  variant={"contained"}
                  size={"small"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setProgramId(param.id);
                    setDialogOpen(true);
                  }}
                >
                  Delete
                </Button>
                <Button
                  style={{
                    marginLeft: "0.5em",
                    backgroundColor: theme.palette.error.main,
                    color: "white",
                  }}
                  variant={"contained"}
                  size={"small"}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCloseProgram(param.id);
                  }}
                >
                  Close Program
                </Button>
              </>
            )}
          </Box>
        ),
      },
    ];
  }, [handleCloseProgram, profile.isAdmin, theme, history]);

  return (
    <>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you wish to delete program?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteProgram()}
            style={{ color: theme.palette.error.main }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item md={2}>
          <Sidebar />
        </Grid>

        <Grid
          item
          md={10}
          style={{
            backgroundColor: "#FAFAFB",
            paddingLeft: "41px",
            paddingRight: "41px",
          }}
        >
          <Grid item container direction={"column"}>
            <Grid
              item
              container
              style={{ marginTop: "20px" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography className={classes.title}>Programs</Typography>
              {profile.isAdmin && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push("/admin/program/new")}
                >
                  Create Program
                </Button>
              )}
            </Grid>

            <Grid item style={{ marginTop: "2em" }}>
              <Box height={320} width="100%">
                <DataGridPremium
                  columns={memoizedColumns}
                  rows={filteredRows}
                  disableSelectionOnClick
                  disableColumnMenu
                  rowsPerPageOptions={[]}
                  onRowClick={(param) => {
                    history.push(
                      `/${
                        profile.isAdmin ? "admin" : "reviewer"
                      }/program/dashboard?id=${param.id}`
                    );
                  }}
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      value: searchText,
                      clearSearch: () => requestSearch(""),
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default connect(mapStateToProps, actions)(AdminPrograms);
