import React from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Grid, MenuItem, Typography } from "@mui/material";
import { Field, FieldArray } from "redux-form";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import TextInputFieldArray from "../../../../common/form/TextInputFieldArray";

import SelectInput from "../../../../common/form/SelectInput";
import { useLocation } from "react-router-dom";
import RichTextArea from "../../../../common/form/RichTextArea";

const useStyles = makeStyles((theme) => ({
  questionText: {
    color: "#696974",
    fontWeight: "600",
    fontFamily: "Roboto",
    fontSize: "16px",
    letter: "0.2px",
  },
  fieldWrapper: {
    paddingTop: "0.4em",
    paddingBottom: "0.4em",
  },
}));

const ProgramPacket = ({ institutions }) => {
  const classes = useStyles();
  const theme = useTheme();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get("id");

  // const amountRequired = (value) => (value ? undefined : "Required");
  // const descriptionRequired = (value) => (value ? undefined : "Required");

  const programPacketFields = ({
    fields,
    meta: { error, submitFailed },
    ...props
  }) => (
    <>
      <Grid
        item
        container
        className={classes.fieldContainer}
        alignItems={"center"}
      >
        <Grid item>
          <IconButton
            onClick={() => {
              fields.push({});
            }}
          >
            <AddCircleIcon color={"primary"} />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant={"subtitle1"}>Add Program Packet</Typography>
        </Grid>
      </Grid>
      {fields.map((programPacket, index) => (
        <Grid key={index} style={{ margin: "16px 0px" }}>
          <div style={{ display: "flex", gap: 16 }}>
            <Field
              name={`${programPacket}.title`}
              label={"Page title"}
              component={TextInputFieldArray}
              type={"text"}
              variant={"outlined"}
            />
            <Field
              name={`${programPacket}.institution`}
              component={SelectInput}
              label={"Institution"}
              type={"text"}
              variant={"outlined"}
            >
              <MenuItem value="default">default</MenuItem>
              {institutions
                .filter((institution) =>
                  id ? institution.programs.includes(id) : true
                )
                .map((institution) => (
                  <MenuItem key={institution.id} value={institution.id}>
                    {institution.institutionName}
                  </MenuItem>
                ))}
            </Field>
            <IconButton
              aria-label="delete debt"
              onClick={() => fields.remove(index)}
            >
              <DeleteIcon style={{ color: theme.palette.error.main }} />
            </IconButton>
          </div>
          <Grid>
            <RichTextArea name={`${programPacket}.packet`} />
          </Grid>
        </Grid>
      ))}
    </>
  );
  return (
    <Grid container justifyContent={"center"} direction={"column"}>
      <FieldArray name="programPacket" component={programPacketFields} />
    </Grid>
  );
};

export default ProgramPacket;
