import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Field } from "redux-form";
import TextInput from "../../common/form/TextInput";

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
  },
  fieldWrapper: {
    paddingTop: "0.4em",
    paddingBottom: "0.4em",
  },
  questionText: {
    color: "#696974",
    fontWeight: "600",
    fontFamily: "Roboto",
    fontSize: "16px",
    letter: "0.2px",
    paddingRight: "1em",
  },
}));

const TextInputForm = ({
  question,
  fieldName,
  placeholder,
  placeholderText,
  disabled,
  required,
}) => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      className={classes.fieldContainer}
      alignItems={"center"}
    >
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Grid item container direction={"column"}>
          <Grid item>
            <Typography variant={"body1"} className={classes.questionText}>
              {question}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Grid item>
          <Grid item container direction={"column"}>
            <Grid item className={classes.fieldWrapper}>
              <Field
                name={fieldName}
                component={TextInput}
                type={"text"}
                variant={"outlined"}
                placeholder={placeholder}
                placeholderText={placeholderText}
                disabled={disabled}
                required={required}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TextInputForm;
