import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, MenuItem, Typography } from "@mui/material";
import { Field } from "redux-form";
import SelectInput from "../../common/form/SelectInput";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#171725",
  },
  description: {
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#696974",
  },
}));

const requiredValidator = (value) => (value ? undefined : "Value is required");

const ReviewFormItem = ({
  title,
  description,
  fieldName,
  fieldLabel,
  disabled,
  required,
}) => {
  const classes = useStyles();
  // const theme = useTheme();
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid item style={{ marginTop: "20px" }}>
      <Grid item container>
        <Grid item md={8}>
          <Grid item container direction={"column"}>
            <Grid item>
              <Typography className={classes.title}>{title}</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.description}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={4}
          style={{ paddingLeft: "1.5em", paddingTop: "0.5rem" }}
        >
          <Grid item className={classes.fieldWrapper}>
            <Field
              name={fieldName}
              label={fieldLabel}
              component={SelectInput}
              type={"text"}
              variant={"outlined"}
              disabled={disabled}
              validate={required && [requiredValidator]}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
            </Field>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReviewFormItem;
