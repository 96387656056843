import React from "react";
import { useTheme } from "@mui/styles";
import { Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Sidebar from "../../../ui/sidebar/Sidebar";
import Typography from "@mui/material/Typography";

import { useHistory } from "react-router-dom";

import Button from "@mui/material/Button";

import AdminNewApplicantForm from "../../../ui/admin/applicants/AdminNewApplicantForm";

const AdminNewApplicant = ({
  adminCreateApplicant,
  loading,
  handleSubmit,
  submitting,
}) => {
  const matches = useMediaQuery("(min-width:480px)");
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const history = useHistory();

  return (
    <Grid container>
      <Grid item md={2} style={{ width: `${matches ? "" : "100%"}` }}>
        <Sidebar />
      </Grid>

      <Grid item md={10} style={{ paddingLeft: "1.5em" }}>
        <Grid item container direction={"column"}>
          <Grid item>
            <Typography variant={"h4"}>New Applicant</Typography>
          </Grid>

          {/*BUTTONS*/}
          <Grid item style={{ marginLeft: "auto" }}>
            <Button
              style={{ marginLeft: "0.5em" }}
              variant="contained"
              color="primary"
              onClick={() => history.push("/admin/applicants/")}
            >
              Back To Applicants
            </Button>
          </Grid>

          <Grid container style={{ marginTop: "2em", marginBottom: "3em" }}>
            {/*RIGHT CONTAINER*/}
            <Grid
              item
              lg={9}
              md={6}
              sm={12}
              xs={12}
              style={
                matchesSM ? { marginTop: "1.5em", paddingLeft: "2em" } : null
              }
            >
              <Grid item>
                <AdminNewApplicantForm />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminNewApplicant;
