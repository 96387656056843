import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Badge, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import Card from '@mui/material/Card';
import RegisteredProgramsTable from '../../../ui/applicantDashboard/RegisteredProgramsTable';
import { fetchApplicantRegisteredPrograms } from '../../../store/actions/applicantActions/programActions/applicantProgramActions';
import {
  fetchApplicantApplicationCountdown,
  fetchApplicantRecentApplication,
} from '../../../store/actions/applicantActions/dashboardActions/applicantDashboardActions';
import ApplicantFAQ from '../faq/ApplicantFAQ.js';

import EditIcon from '@mui/icons-material/Edit';

import circles from '../../../assets/dashboard/circles.png';
import applicantHeader from '../../../assets/dashboard/LFL 2.jpg';

import Iframe from 'react-iframe';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { fetchInstitution } from '../../../store/actions/adminActions/institutionActions/adminInstitutionActions.js';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f2f2f2',
  },

  tile: {
    height: '400px',
    overflow: 'auto',
    paddingBottom: '30px',
    paddingLeft: '10px !Important',
    paddingRight: '10px',
    paddingTop: '0px !Important',
    backgroundColor: '#fff',
    boxShadow: '0 5px 10px rgba(0,0,0,.07)',
    borderRadius: '40px',
    margin: '0 10px',
    [theme.breakpoints.between('xs', 'md')]: {
      marginBottom: '30px',
      // textAlign: "center !Important",
      maxWidth: '100% !Important',
    },
  },
  tileContainer: {
    width: '100% !important',
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'center',
    // left: '50px',
    // position: 'relative',
    //  top: '45%',
    [theme.breakpoints.between('xs', 'md')]: {
      position: 'unset',
      textAlign: 'unset !important',
    },
  },

  headerImage: {
    width: '100%',
    height: '350px',
    objectFit: 'cover',
    //  position:"absolute",
    [theme.breakpoints.down('sm')]: {
      height: '500px',
    },
  },
  headerContainer: {},

  profilePic: {
    marginBottom: '10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center !Important',
    },
  },

  avatarSub: {
    alignItems: 'center',
    textAlign: 'center !important',
    [theme.breakpoints.down('sm')]: {
      marginRight: 'auto',
      marginLeft: 'auto',
      width: '100% !important',
    },
  },
  avatar: {
    width: '170px',
    height: '170px',
    [theme.breakpoints.between('xs', 'md')]: {
      width: '150px',
      height: '150px',
    },
  },
  editButton: {
    backgroundColor: 'white',
  },
  wrapper: {
    marginTop: '38px',
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.between('xs', 'md')]: {
      textAlign: 'center !Important',
      paddingLeft: '20px !Important',
      paddingRight: '20px !Important',
    },
  },
  blockTitle: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    color: '#696974',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  blockSubTitle: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '276x',
    color: '#181818',
  },
  blockSubText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#891010',
    marginBottom: '20px',
  },
  blockText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '276x',
    color: '#92929D',
  },

  button: {
    borderRadius: '10px',
    backgroundColor: '#35BA35',
    color: 'white',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#35BA35',
      color: 'white',
    },
  },
}));

const actions = {
  fetchApplicantRegisteredPrograms,
  fetchApplicantRecentApplication,
  fetchApplicantApplicationCountdown,
  fetchInstitution,
};

const mapStateToProps = (state) => {
  let registeredPrograms = [];
  let recentApplication = {};
  let applicationCountdown = {};
  let institution = [];

  if (
    state.applicantPrograms.allRegisteredPrograms &&
    state.applicantPrograms.allRegisteredPrograms.length > 0
  ) {
    registeredPrograms = state.applicantPrograms.allRegisteredPrograms;
  }
  if (
    state.adminInstitutions.institution &&
    state.adminInstitutions.institution.length > 0
  ) {
    institution = state.adminInstitutions.institution[0];
    // programDetails = state.adminInstitutions.institution[0].programDetails;
  }

  if (state.applicantDashboard) {
    recentApplication = state.applicantDashboard.recentApplication;
    applicationCountdown = state.applicantDashboard.applicationCountdown;
  }
  return {
    registeredPrograms: state.applicantPrograms.allRegisteredPrograms,
    recentApplication: state.applicantDashboard.recentApplication,
    applicationCountdown: state.applicantDashboard.applicationCountdown,
    profile: state.firebase.profile,
    institution,
    maintenanceMode: state.applicationSettings.maintenanceMode,
  };
};

const ApplicantDashboard = ({
  profile,
  fetchApplicantRegisteredPrograms,
  fetchApplicantRecentApplication,
  fetchApplicantApplicationCountdown,
  registeredPrograms,
  recentApplication,
  fetchInstitution,
  institution,
  registeredProgram,
  applicationCountdown,
  maintenanceMode,
}) => {
  const classes = useStyles();

  const history = useHistory();
  console.log('registeredPrograms', registeredPrograms)
  console.table('Registered ', recentApplication);
  console.log('institution', institution)
  useEffect(() => {
    fetchApplicantRegisteredPrograms(profile.uid);
    fetchApplicantRecentApplication(profile.uid);
    fetchApplicantApplicationCountdown(profile.uid);
  }, [
    profile,
    fetchApplicantRegisteredPrograms,
    fetchApplicantRecentApplication,
    fetchApplicantApplicationCountdown,
  ]);
  /* useEffect(() => {
     fetchInstitution(recentApplication?.applicationDetails?.chosenInstitution2);
   }, [recentApplication?.applicationDetails?.chosenInstitution2]);*/
  return maintenanceMode ? (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: '2rem' }}
    >
      <Typography className={classes.blockTitle}>
        Website is under maintenance.
      </Typography>
    </Grid>
  ) : (
    <Grid container direction={'column'}>
      <Grid item>
        <img className={classes.headerImage} src={applicantHeader} alt="" />
      </Grid>
      <Grid
        item
        alignItems="center"
        justifyContent="center"
        sx={{ width: '100%' }}
        className={classes.tileContainer}
      >
        <Grid
          item
          direction={'row'}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          className={classes.tileContainer}
        >
          {/*} <Grid  item xs={12} sm={12} md={5} className={classes.tile}>
      <Grid container className={classes.wrapper}>
      <ApplicantFAQ />
       </Grid>
       </Grid>*/}

          <Grid item xs={12} sm={12} md={5} className={classes.tile}>
            <Grid container direction={'column'} className={classes.wrapper}>
              <Grid item lg={6} className={classes.profilePic}>
                <Badge
                  overlap={'circular'}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  badgeContent={
                    <IconButton
                      className={classes.editButton}
                      onClick={() =>
                        history.push(
                          `/applicant/profile/view?id=${profile.uid}`
                        )
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  }
                >
                  <Avatar
                    className={classes.avatar}
                    src={profile.profileImageURL}
                  />
                </Badge>
              </Grid>
              <Grid item lg={6}>
                <Grid
                  container
                  direction={'column'}
                  className={classes.avatarSub}
                >
                  <Grid item>
                    <Typography className={classes.blockSubTitle}>
                      Welcome {profile?.fullName},
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      style={{ marginTop: '1rem', textAlign: 'center' }}
                    >
                      {profile?.city}, {profile?.state}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ textAlign: 'center' }}>
                      {profile?.gradeLevel}
                    </Typography>
                  </Grid>

                  {/*<Grid item style={{ marginTop: "17px" }}>
              <Typography className={classes.blockText}>
                {profile?.biography
                  ? profile?.biography.slice(0, 200) +
                    (profile?.biography.length > 200 ? "..." : "")
                  : ""}
              </Typography>
            </Grid>*/}
                  <Grid item style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button
                      variant={'contained'}
                      style={{ width: '200px' }}
                      className={classes.button}
                      onClick={() =>
                        history.push(
                          `/applicant/profile/view?id=${profile.uid}`
                        )
                      }
                    >
                      Edit Profile
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.tile}>
            {registeredPrograms.length === 0 && (
              <Grid container className={classes.wrapper}>
                <Grid container direction={'column'}>
                  <Grid item>
                    <Typography className={classes.blockTitle}>
                      No Registered Programs
                    </Typography>
                  </Grid>

                  <Grid item style={{ marginTop: '34px' }}>
                    <Button
                      variant={'contained'}
                      className={classes.button}
                      onClick={() => history.push(`/program`)}
                    >
                      Apply Now!
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {registeredPrograms.length > 0 && (
              <Grid
                container
                className={classes.wrapper}
                style={{ paddingBottom: '2rem' }}
              >
                <Grid container direction={'column'}>
                  <Grid item>
                    <Typography className={classes.blockTitle}>
                      Recent Application
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginTop: '16px' }}>
                    <Typography className={classes.blockSubTitle}>
                      {recentApplication?.programDetails?.programName}
                    </Typography>
                  </Grid>

                  <br />
                  <Grid item>
                    <Typography className={classes.blockSubText}>
                      Stage:{' '}
                      {recentApplication?.applicationDetails?.programStage ===
                        'pre-application' && ' Pre-Application'}
                      {recentApplication?.applicationDetails?.programStage ===
                        'in-progress' && ' In Progress'}
                      {recentApplication?.applicationDetails?.programStage ===
                        'under-review' &&
                        !recentApplication?.applicationDetails
                          ?.notificationDateMet &&
                        ' Under Review'}
                      {recentApplication?.applicationDetails?.programStage ===
                        'reviewed' &&
                        !recentApplication?.applicationDetails
                          ?.notificationDateMet &&
                        ' Under Review'}
                      {recentApplication?.applicationDetails?.programStage ===
                        'withdrawn' &&
                        recentApplication?.applicationDetails
                          ?.notificationDateMet &&
                        'Withdrawn'}
                      {recentApplication?.applicationDetails?.programStage ===
                        'withdrawn' &&
                        !recentApplication?.applicationDetails
                          ?.notificationDateMet &&
                        'Under Review'}
                      {recentApplication?.applicationDetails?.programStage ===
                        'waitlisted' && (
                          <Fragment>
                            {recentApplication?.applicationDetails
                              ?.notificationDateMet && 'Waitlisted'}
                            {!recentApplication?.applicationDetails
                              ?.notificationDateMet && 'Under Review'}
                          </Fragment>
                        )}
                      {recentApplication?.applicationDetails?.programStage ===
                        'denied' && (
                          <Fragment>
                            {recentApplication?.applicationDetails
                              ?.notificationDateMet && 'Denied'}
                            {!recentApplication?.applicationDetails
                              ?.notificationDateMet && 'Under Review'}
                          </Fragment>
                        )}
                      {recentApplication?.applicationDetails?.programStage ===
                        'approved' && (
                          <Fragment>
                            {recentApplication?.applicationDetails
                              ?.notificationDateMet && 'Approved'}
                            {!recentApplication?.applicationDetails
                              ?.notificationDateMet && 'Under Review'}
                          </Fragment>
                        )}
                      {recentApplication?.applicationDetails?.programStage ===
                        'admitted' && ' Admitted'}
                      {recentApplication?.applicationDetails?.programStage ===
                        'payment1' && ' Payment 1 Pending'}
                      {recentApplication?.applicationDetails?.programStage ===
                        'payment2' && ' Payment 2 Pending'}
                      {recentApplication?.applicationDetails?.programStage ===
                        'complete' && ' Complete'}
                      {recentApplication?.applicationDetails?.programStage ===
                        'feedback' && ' Feedback'}
                    </Typography>
                  </Grid>

                  <Grid item>
                    {recentApplication.applicationDetails
                      ?.applicationAccepted &&
                      recentApplication.applicationDetails
                        ?.applicationApproved ? (
                      <>
                        <Grid item style={{ marginTop: '1em' }}>
                          <Typography
                            className={classes.blockText}
                            variant={'h4'}
                          >
                            {recentApplication?.programDetails
                              ?.customCongratulations ? (
                              <div
                                className={classes.text}
                                dangerouslySetInnerHTML={{
                                  __html: recentApplication?.programDetails
                                    ?.customCongratulationsText ? (
                                    `${recentApplication?.programDetails?.customCongratulationsText.substring(
                                      0,
                                      recentApplication?.programDetails
                                        ?.customCongratulationsText?.length
                                    )}`
                                  ) : (
                                    <p>Congratulations!</p>
                                  ),
                                }}
                              />
                            ) : (
                              <>
                                <p>
                                  On behalf of LEADership Education and
                                  Development, we are delighted to offer you
                                  admission to the{' '}
                                  {recentApplication.programDetails.programName}
                                  . You were selected based upon your academic
                                  performance, essay, recommendations, and
                                  extracurricular activities. Your acceptance
                                  was also based on our belief that you will
                                  offer a unique perspective to your cohort and
                                  will add to our talented group of diverse
                                  scholars.
                                </p>
                                <br></br>
                                <p>
                                  Your LEAD assignment will begin on{' '}
                                  {recentApplication.programDetails
                                    .programStartDate
                                    ? format(
                                      parseISO(
                                        recentApplication.programDetails
                                          ?.programStartDate
                                      ),
                                      'EEE MMM do y'
                                    )
                                    : null}
                                  . Please confirm your acceptance by choosing
                                  to ACCEPT or DECLINE. If you intend to accept
                                  your appointment, you must also complete the
                                  entire Acceptance Checklist if applicable.
                                </p>
                                <br></br>
                                <p>
                                  We remain excited about the Global Summer
                                  Learning Institute 2023 and are truly looking
                                  forward to welcoming you into the LEAD
                                  community!
                                </p>
                              </>
                            )}
                          </Typography>
                        </Grid>
                        <Grid item style={{ marginTop: '1em' }}>
                          <Typography
                            className={classes.blockText}
                            variant={'body1'}
                          >
                         

                            {recentApplication.programDetails
                              ?.depositDeadline && (
                                <>
                                  <br></br>
                                  <p style={{ color: '#891010' }}>
                                    <b>
                                      Deposit Deadline:{' '}
                                      {recentApplication.programDetails
                                        ?.depositDeadline
                                        ? format(
                                          parseISO(
                                            recentApplication.programDetails
                                              ?.depositDeadline
                                          ),
                                          'EEE MMM do y'
                                        )
                                        : null}
                                    </b>
                                  </p>
                                </>
                              )}

                            {recentApplication.programDetails
                              ?.paymentDeadline && (
                                <>
                                  <p style={{ color: '#891010' }}>
                                    <b>
                                      Final Payment Deadline:{' '}
                                      {recentApplication.programDetails
                                        ?.paymentDeadline
                                        ? format(
                                          parseISO(
                                            recentApplication.programDetails
                                              ?.paymentDeadline
                                          ),
                                          'EEE MMM do y'
                                        )
                                        : null}
                                    </b>
                                  </p>
                                </>
                              )}
                              <br></br>
                            <p>
                              {recentApplication.institutionDetails
                                ?.institutionName && (
                                  <b>
                                    Placement 1:{' '}
                                    {
                                      recentApplication.institutionDetails
                                        ?.institutionName
                                    }
                                  </b>
                                )}
                            </p>
                            <p>
                              {recentApplication.institutionDetails
                                ?.arrivalDate && (
                                  <b>
                                    Arrival Date:{' '}
                                    {recentApplication.institutionDetails
                                      ?.arrivalDate
                                      ? format(
                                        parseISO(
                                          recentApplication.institutionDetails
                                            ?.arrivalDate
                                        ),
                                        'EEE MMM do y'
                                      )
                                      : null}
                                  </b>
                                )}
                            </p>
                            <p>
                              {recentApplication.institutionDetails
                                ?.departureDate && (
                                  <b>
                                    Departure Date:{' '}
                                    {recentApplication.institutionDetails
                                      ?.departureDate
                                      ? format(
                                        parseISO(
                                          recentApplication.institutionDetails
                                            ?.departureDate
                                        ),
                                        'EEE MMM do y'
                                      )
                                      : null}
                                  </b>
                                )}
                            </p>

                            {recentApplication.applicationDetails
                              .scholarshipAmount > 0 && (
                                <>
                                  <br></br>
                                  <p>
                                    <b>
                                      Scholarship Granted: $
                                      {Number(
                                        recentApplication.applicationDetails
                                          .scholarshipAmount || 0
                                      ) +
                                        Number(
                                          recentApplication.applicationDetails
                                            ?.sponsorshipAmount || 0
                                        )}
                                    </b>
                                  </p>
                                </>
                              )}
                          

                            {recentApplication.institutionDetails
                              ?.institutionNotes && (
                                <>
                                  <br></br>
                                  <p>
                                    <b>Additional Information:</b>
                                  </p>
                                  <p>
                                    {
                                      recentApplication.institutionDetails
                                        ?.institutionNotes
                                    }
                                  </p>
                                </>
                              )}
                          </Typography>
                        </Grid>
                        {recentApplication?.institutionDetails2?.institutionName && (
                          <div style={{ marginTop: "1em" }}>
                            <hr />
                          </div>
                        )}
                        {recentApplication?.institutionDetails2?.institutionName && (
                          <Grid item>
                            <Typography
                              className={classes.blockText}
                              variant={'body1'}
                            >
                              <br></br>
                              <p>
                                {recentApplication?.institutionDetails2
                                  ?.institutionName && (
                                    <b>
                                      Placement 2:{' '}
                                      {
                                        recentApplication?.institutionDetails2
                                          ?.institutionName
                                      }
                                    </b>
                                  )}
                              </p>
                              <p>
                                {recentApplication.institutionDetails2
                                  ?.arrivalDate && (

                                    <b>
                                      Arrival Date:{' '}
                                      {recentApplication.institutionDetails2
                                        ?.arrivalDate
                                        ? format(
                                          parseISO(
                                            recentApplication.institutionDetails2
                                              ?.arrivalDate
                                          ),
                                          'EEE MMM do y'
                                        )
                                        : null}
                                    </b>
                                  )}
                              </p>
                              <p>
                                {
                                  recentApplication.institutionDetails2
                                    ?.departureDate && (

                                    <b>
                                      Departure Date:{' '}
                                      {recentApplication.institutionDetails2
                                        ?.departureDate
                                        ? format(
                                          parseISO(
                                            recentApplication.institutionDetails2
                                              ?.departureDate
                                          ),
                                          'EEE MMM do y'
                                        )
                                        : null}
                                    </b>
                                  )
                                }
                              </p>


                              {recentApplication.institutionDetails2
                                ?.institutionNotes && (
                                  <>
                                    <br></br>
                                    <p>
                                      <b>Additional Information:</b>
                                    </p>
                                    <p>
                                      {
                                        recentApplication.institutionDetails2
                                          ?.institutionNotes
                                      }
                                    </p>
                                  </>
                                )}
                            </Typography>
                          </Grid>
                        )}
                      </>
                    ) : (
                      <Typography className={classes.blockText}>
                        {recentApplication.programDetails?.programSummary
                          ? recentApplication.programDetails?.programSummary?.slice(
                            0,
                            500
                          ) +
                          (recentApplication.programDetails?.programSummary
                            ?.length > 500
                            ? '...'
                            : '')
                          : ''}
                      </Typography>
                    )}
                  </Grid>
                  <br></br>
                  <Grid item style={{ marginTop: '34px' }}>
                    <Button
                      variant={'contained'}
                      className={classes.button}
                      onClick={() =>
                        history.push(
                          `/program/edit?id=${recentApplication.applicationDetails?.id}`
                        )
                      }
                    >
                      {/* {recentApplication.applicationDetails?.applicationCompleted &&
                recentApplication.applicationDetails?.notificationDateMet
                  ? "Continue Acceptance Checklist"
                  : "Continue Application"} */}
                      {recentApplication.applicationDetails
                        ?.applicationCompleted &&
                        recentApplication.applicationDetails?.notificationDateMet
                        ? 'Continue Application'
                        : 'Continue Application'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <div style={{ backgroundColor: '#f2f2f2' }} />

      {registeredPrograms && (
        <Grid
          item
          direction={'column'}
          className={classes.wrapper}
          style={{
            minHeight: '351px',
            paddingRight: '80px',
            paddingLeft: '80px',
          }}
        >
          <RegisteredProgramsTable
            institute={institution?.institutionName}
            registeredPrograms={registeredPrograms}
          />
        </Grid>
      )}

      {/*<Grid item>*/}
      {/*    {profile && profile.firstName && (*/}
      {/*        <Typography variant={'h1'}>{profile?.firstName}'s Dashboard</Typography>*/}
      {/*    )}*/}
      {/*</Grid>*/}

      {/*<Grid item >*/}
      {/*    <Grid item container>*/}
      {/*        <Grid item md={4}>*/}
      {/*            <ProfileBlock profile={profile}/>*/}
      {/*        </Grid>*/}

      {/*        <Grid item md={4} >*/}
      {/*            <RecentApplication recentApplication={recentApplication} registeredPrograms={registeredPrograms}/>*/}
      {/*        </Grid>*/}

      {/*        <Grid item md={4} >*/}
      {/*            <ProgramStatus applicationCountdown={applicationCountdown}/>*/}
      {/*        </Grid>*/}
      {/*    </Grid>*/}
      {/*</Grid>*/}

      {/*<Grid item style={{marginTop: '2em'}}>*/}
      {/*    <Grid item container direction={'column'}>*/}
      {/*        <Grid item>*/}
      {/*            <Typography variant={'h4'}>Registered Programs</Typography>*/}
      {/*        </Grid>*/}
      {/*        <Grid item style={{marginTop: '1em'}}>*/}
      {/*            <RegisteredProgramsTable registeredPrograms={registeredPrograms}/>*/}
      {/*        </Grid>*/}
      {/*    </Grid>*/}
      {/*</Grid>*/}
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(ApplicantDashboard);
