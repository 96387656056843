import React, { Fragment, useEffect, useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";

import Sidebar from "../../../../ui/sidebar/Sidebar";
import {
  deleteAdminAndReviewer,
  disableAdminAndReviewer,
  enableAdminAndReviewer,
  fetchAllAdminsAndReviewers,
} from "../../../../store/actions/adminActions/settingsActions/settingsActions";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#171725",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight: "24px",
    letter: "0.1px",
  },
}));

const actions = {
  fetchAllAdminsAndReviewers,
  deleteAdminAndReviewer,
  disableAdminAndReviewer,
  enableAdminAndReviewer,
};

const mapStateToProps = (state) => {
  let users = [];

  if (
    state.adminSettings.allAdminsAndReviewers &&
    state.adminSettings.allAdminsAndReviewers.length > 0
  ) {
    users = state.adminSettings.allAdminsAndReviewers;
  }

  return {
    users,
  };
};

const AdminUsers = ({
  fetchAllAdminsAndReviewers,
  users,
  deleteAdminAndReviewer,
  disableAdminAndReviewer,
  enableAdminAndReviewer,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [dialogOpen, setDialogOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userToggle, setUserToggle] = useState(false);

  const history = useHistory();

  useEffect(() => {
    fetchAllAdminsAndReviewers();
    if (userToggle) {
      fetchAllAdminsAndReviewers();
    }
  }, [fetchAllAdminsAndReviewers, userToggle]);

  const handleDisableUser = async (id) => {
    setUserToggle(true);
    disableAdminAndReviewer(id);
    let disableUserQuery = await disableAdminAndReviewer(id);
    if (disableUserQuery.data === "User Disabled") {
      // fetchAllAdminsAndReviewers()
    }
  };
  const handleEnableUser = async (id) => {
    enableAdminAndReviewer(id);
    let enableUserQuery = await enableAdminAndReviewer(id);
    if (enableUserQuery.data === "User Enabled") {
      // fetchAllAdminsAndReviewers()
    }
  };

  const handleDeleteUser = async () => {
    let deleteUserQuery = await deleteAdminAndReviewer(userId);

    if (deleteUserQuery.data === "Deleted User") {
      // fetchAllAdminsAndReviewers()
      setDialogOpen(false);
      history.push("/admin/settings/users");
    }
  };

  return (
    <Fragment>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you wish to delete user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteUser()}
            style={{ color: theme.palette.error.main }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container>
        <Grid item md={2}>
          <Sidebar />
        </Grid>

        <Grid
          item
          md={10}
          style={{
            backgroundColor: "#FAFAFB",
            paddingLeft: "41px",
            paddingRight: "41px",
          }}
        >
          <Grid item container direction={"column"}>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: "20px" }}
            >
              <Typography className={classes.title}>Users</Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push("/admin/settings/users/new")}
              >
                Create User
              </Button>
            </Grid>
            <Grid item style={{ margin: "2em 0" }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>User Name</TableCell>
                      <TableCell>Email Address</TableCell>
                      <TableCell>Account Type</TableCell>
                      <TableCell>Account Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {users.map((user) => (
                      <TableRow key={user.id}>
                        <TableCell component="th" scope="row">
                          {user.fullName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {user.email}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {user.isAdmin && "Admin"}
                          {user.isReviewer && "Reviewer"}
                          {user.isAdminViewer && "AdminViewer"}
                        </TableCell>
                        <TableCell
                          style={
                            user.disabled
                              ? { color: theme.palette.error.main }
                              : { color: theme.palette.primary.main }
                          }
                        >
                          {user.disabled ? "Disabled" : "Active"}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant={"contained"}
                            color={"primary"}
                            size={"small"}
                            onClick={() =>
                              history.push(
                                `/admin/settings/users/view?id=${user.id}`
                              )
                            }
                          >
                            View
                          </Button>
                          <Button
                            style={{
                              marginLeft: "0.5em",
                              backgroundColor: theme.palette.info.main,
                              color: "white",
                            }}
                            variant={"contained"}
                            size={"small"}
                            onClick={() =>
                              history.push(
                                `/admin/settings/users/edit?id=${user.id}`
                              )
                            }
                          >
                            Edit
                          </Button>
                          <Button
                            style={
                              user.disabled
                                ? {
                                    marginLeft: "0.5em",
                                    backgroundColor: theme.palette.primary.main,
                                    color: "white",
                                  }
                                : {
                                    marginLeft: "0.5em",
                                    backgroundColor: theme.palette.error.main,
                                    color: "white",
                                  }
                            }
                            variant={"contained"}
                            size={"small"}
                            onClick={() =>
                              user.disabled
                                ? handleEnableUser(user.id)
                                : handleDisableUser(user.id)
                            }
                          >
                            {user.disabled ? "Enable" : "Disable"}
                          </Button>
                          <Button
                            style={{
                              marginLeft: "0.5em",
                              backgroundColor: theme.palette.error.main,
                              color: "white",
                            }}
                            variant={"contained"}
                            size={"small"}
                            onClick={() => {
                              setUserId(user.id);
                              setDialogOpen(true);
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default connect(mapStateToProps, actions)(AdminUsers);
