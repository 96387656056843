import React, { useState, useEffect, useMemo, useRef } from "react";
import { Box, Button, Switch, FormControl, Select, MenuItem } from "@mui/material";
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { CustomToolbar, escapeRegExp } from "../../../common/tableUtils";
import { useHistory } from "react-router-dom";
import { format, fromUnixTime } from "date-fns";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  viewBtn: {
    backgroundColor: "#35BA35",
    color: theme.palette.common.white,
    "&.MuiButton-containedPrimary:hover": {
      backgroundColor: "#35BA35",
      color: theme.palette.common.white,
    },
  },
  flex3: {
    flex: 3,
  },
}));

const SponsorshipTable = ({
  sponsorshipApplications,
  profile,
  handleUpdateProgramStage,
  handleToggleGrantSponsorship,
  handleUpdateSponsorshipAmount,
  notifyButton,
  handleApplicationNotificationApproved,
  handleUpdateChosenInstitution,
  institutions
}) => {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filterModel, setFilterModel] = useState();
  const [sortModel, setSortModel] = useState();
  const [page, setPage] = useState(0);
  const grid1 = useRef(null);
  const grid2 = useRef(null);

  const classes = useStyles();

  const history = useHistory();

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      "e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    );
  }, []);

  useEffect(() => {
    const arr = sponsorshipApplications.map((application, index) => ({
      ...application,
      id: application.applicantUid,
      serialNo: index + 1,
      programName: application.programDetails?.programName,
      reviewerName: application.reviewerDetails?.fullName,
      submissionDate:
        application.confirmationFormDetails?.confirmationDate &&
        format(
          new Date(application.confirmationFormDetails?.confirmationDate),
          "MM/dd/yyyy"
        ),
      reviewerAssignedAt:
        application.reviewerAssignedAt &&
        format(fromUnixTime(application.reviewerAssignedAt), "MM/dd/yyyy"),
      reviewerSubmittedAt:
        application.reviewerResponse?.reviewedOn &&
        format(
          new Date(application.reviewerResponse?.reviewedOn),
          "MM/dd/yyyy"
        ),
      qualifyForScholarship: application.scholarshipFormDetails
        ?.qualifyForScholarship
        ? "Yes"
        : "No",
      notifiedAt:
        application.notifiedAt &&
        format(fromUnixTime(application.notifiedAt), "MM/dd/yyyy"),
      institutionName: application.institutionDetails?.institutionName,
      //catastrophicEvent:
      // familySalaryLess:
    }));
    setRows(arr);
    if (searchText) {
      const searchRegex = new RegExp(escapeRegExp(searchText), "i");
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
    }
  }, [sponsorshipApplications]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filtered = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };

  const memoizedColumns = useMemo(() => {
    return [
     
      {
        field: "fullName",
        headerName: "Name",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
      },
     /* {
        field: "programName",
        headerName: "Program",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
      },*/
      {
        field: "programStage",
        headerName: "Stage",
        width: 120,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "submissionDate",
        headerName: "Submission Date",
        width: 160,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "reviewerName",
        headerName: "Reviewer",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "reviewerAssignedAt",
        headerName: "Reviewer Assigned Date ",
        width: 220,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "reviewerSubmittedAt",
        headerName: "Reviewer Submission Date ",
        width: 220,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "sponsorshipName",
        headerName: "Sponsorship Name",
        width: 140,
        hide: !profile.isAdmin,
        valueGetter: (param) => param.value || "None",
      },
      {
        field: "sponsorshipAmount",
        headerName: "Amount",
        width: 100,
        valueGetter: (param) =>
          parseInt(param.value) >= 0 ? param.value : "N/A",
        editable: true,
      },
      {
        field: "affiliateName",
        headerName: "Affiliate Name",
        width: 160,
        hide: !profile.isAdmin,
        valueGetter: (param) => param.value || "None",
      },
      {
        field: "affiliateCode",
        headerName: "Affiliate Code",
        width: 140,
        hide: !profile.isAdmin,
        valueGetter: (param) => param.value || "None",
      },
      {
        field: "institutionName",
        headerName: "Institution",
        width: 180,
        valueGetter: (param) => param.value || "Unassigned",
        renderCell: (param) => (
          <Box display="flex" justifyContent="center" width="100%">
            <FormControl fullWidth size="small">
              <Select
                value={param.row?.institutionDetails?.id}
                onClick={(e) => e.stopPropagation()}
                onChange={(event) => {
                  handleUpdateChosenInstitution(
                    param.id,
                    event.target.value,
                  );
                }}
                disabled={param.row.programStage === "complete"}
              >
               <MenuItem value={"noInstitution"}>No Institution </MenuItem>
                  {institutions && institutions?.length > 0 && institutions.map((institution) => (
                    <MenuItem key={institution.id} value={institution.id}>
                      {institution.institutionName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        ),
      },
      {
        field: "notifiedAt",
        headerName: "Notified At",
        width: 140,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "grantSponsorship",
        headerName: "Grant Sponsorship",
        width: 180,
        hide: !profile.isAdmin,
        renderCell: (param) => (
          <Box display="flex" justifyContent="space-around" width="100%">
            <Switch
              onChange={(e, checked) => {
                e.stopPropagation();
                handleToggleGrantSponsorship(param.row.applicantUid, checked);
              }}
              checked={param.value}
              onClick={(e) => e.stopPropagation()}
              color="primary"
            />
          </Box>
        ),
      },
      {
        field: "",
        headerName: "Actions",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
        renderCell: (params) => (
          <Box display="flex" justifyContent="space-evenly" width="100%">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                history.push(
                  `/admin/reviewProgram?id=${params.row.applicantUid}`
                );
              }}
              className={classes.viewBtn}
              variant="contained"
            >
              View
            </Button>
            {/*Notify*/}
            {profile.isAdmin && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleApplicationNotificationApproved(params.id);
                }}
                style={{ marginRight: "0.5em" }}
                disabled={
                  !(
                    params.row.programStage === "approved" ||
                    params.row.programStage === "waitlisted"
                  )
                }
              >
                Notify
              </Button>
            )}
          </Box>
        ),
      },
    ];
  }, [handleToggleGrantSponsorship, history, profile.isAdmin]);

  return (
    <Box height={320} width="100%" display="flex">
      <DataGridPremium
        ref={grid2}
        className={classes.flex3}
        columns={memoizedColumns}
        rows={filteredRows}
        disableSelectionOnClick
        // disableColumnMenu
        rowsPerPageOptions={[]}
        page={page}
        onPageChange={(params) => setPage(params.page)}
        // onEditRowModelChange={(e) => console.log(e)}
        onEditCellPropsChange={(params) => {
          if (profile.isAdmin && params.props.value > 0){
            handleUpdateSponsorshipAmount(params.id, params.props.value);
          }
        }}
        // onRowClick={(param) => {
        //     history.push(`/admin/reviewProgram?id=${param.row.applicantUid}`);
        // }}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(""),
          },
        }}
        filterModel={filterModel}
        onFilterModelChange={(params) => setFilterModel(params.filterModel)}
        sortModel={sortModel}
        onSortModelChange={(params) => setSortModel(params.sortModel)}
        initialState={{ pinnedColumns: { left: ["fullName"] } }}
      />
    </Box>
  );
};

export default SponsorshipTable;
