import { Button, Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Field, reduxForm } from "redux-form";
import React, { useEffect, useState } from "react";
import Sidebar from "../../../ui/sidebar/Sidebar";
import { connect } from "react-redux";
import {
  disableTemplate,
  enableTemplate,
  fetchAllTemplates,
  deleteTemplate,
} from "../../../store/actions/adminActions/settingsActions/settingsActions";
import { useHistory, useLocation } from "react-router-dom";
import TextInput from "../../../common/form/TextInput";
import TextEditor from "../../../common/TextEditor/TextEditor";
import { fetchAllEmails } from "../../../store/actions/adminActions/dashboardActions/dashboardActions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
}));

const actions = {
  fetchAllTemplates,
  disableTemplate,
  enableTemplate,
  deleteTemplate,
};

const mapStateToProps = (state) => {
  let emails = [];

  if (state.adminDashboard.emails && state.adminDashboard.emails.length > 0) {
    emails = state.adminDashboard.emails;
  }

  return {
    emails,
    profile: state.firebase.profile,
  };
};

const EditEmailLogs = ({ loading, submitting, emails, fetchAllEmails }) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const classes = useStyles();
  const history = useHistory();

  const [emailDetail, setEmailDetail] = useState({
    subject: "",
    sentBy: "",
    sentTo: [],
  });

  const [emailContent, setEmailContent] = useState();

  console.log("search", searchParams.get("id"));

  useEffect(() => {
    fetchAllEmails();
  }, [fetchAllEmails]);

  console.log("emails", emails);

  const [editorValue, setEditorValue] = useState("Manas");

  const filterSingleEmail = (id) => {
    return emails
      ?.filter((items) => items?.id == id)
      .map((item) => {
        setEmailDetail({
          subject: item?.subject,
          sentBy: item?.sentBy,
          sentTo: item?.emails?.[0],
        });
        setEmailContent(item?.content);
      });
  };

  useEffect(() => {
    if (emails) {
      filterSingleEmail(searchParams.get("id"));
    }
  }, [emails]);

  console.log("emailDetail", emailDetail);

  return (
    <Grid container>
      <Grid item md={2}>
        <Sidebar />
      </Grid>
      <Grid item md={10} style={{ paddingLeft: "1.5em" }}>
        <Grid item container direction={"column"}>
          <Grid item>
            <Typography variant={"h4"}>Edit Email</Typography>
          </Grid>

          <form
            autoComplete={"off"}
            onSubmit={(e) => {
              e.stopPropagation();
              console.log("e.target.value", e.target.value);
            }}
          >
            {/*INPUT CONTAINER*/}
            <Grid item container style={{ marginTop: "2em" }}>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <Grid item>
                  <Grid item container>
                    <Grid item style={{ marginLeft: "auto" }}>
                      <Button
                        size={"small"}
                        variant={"outlined"}
                        color={"secondary"}
                        className={classes.button}
                        onClick={() =>
                          history.push("/admin/dashboard/emailLogs")
                        }
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.fieldWrapper}>
                  <TextField
                    fullWidth
                    name={"emailSubject"}
                    type="text"
                    label="Email Subject"
                    value={emailDetail?.subject}
                    variant="outlined"
                    size="small"
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item className={classes.fieldWrapper}>
                  <TextField
                    fullWidth
                    name={"emailSentBy"}
                    label={"Email Sent By"}
                    type="text"
                    value={emailDetail?.sentBy}
                    variant="outlined"
                    size="small"
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item className={classes.fieldWrapper}>
                  <TextField
                    fullWidth
                    name={"emailSentTo"}
                    label={"Email Sent To"}
                    type="text"
                    value={emailDetail?.sentTo}
                    variant="outlined"
                    size="small"
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container style={{ marginTop: "2em" }}>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                {!loading && <TextEditor editorValue={emailContent} />}
              </Grid>
            </Grid>
            <Grid item container style={{ marginTop: "2em" }}>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <Grid item>
                  <Grid item container>
                    <Grid item style={{ marginLeft: "auto" }}>
                      <Button
                        size={"small"}
                        variant={"outlined"}
                        color={"secondary"}
                        className={classes.button}
                        onClick={() =>
                          history.push("/admin/dashboard/emailLogs")
                        }
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, { fetchAllEmails })(
  reduxForm({ form: "editTemplate", enableReinitialize: true })(EditEmailLogs)
);
