import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import ReactDragListView from "react-drag-listview";
import { useSelector } from "react-redux";

const PacketOrder = ({ change, formName }) => {
  const fieldValues = useSelector((state) => {
    let values = {};
    if (state.form[formName] && state.form[formName].values) {
      values = state.form[formName].values;
    }
    return values;
  });

  useEffect(() => {
    const order = [];
    if (fieldValues.programPacket?.length > 0) {
      fieldValues.programPacket.forEach((packet) =>
        order.push({
          title: packet.title,
        })
      );
    }
    if (fieldValues.programPacketDocuments?.length > 0) {
      fieldValues.programPacketDocuments.forEach((doc) =>
        order.push({
          title: doc.title,
        })
      );
    }
    change("packetOrder", order);
  }, [fieldValues.programPacket, fieldValues.programPacketDocuments]);

  return fieldValues.packetOrder?.length > 0 ? (
    <>
      <Divider style={{ margin: "12px 0px" }} />
      <Grid container direction="column">
        <Typography variant="h6">Packet Order</Typography>
        <ReactDragListView
          handleSelector=".MuiListItem-root"
          nodeSelector=".MuiListItem-root"
          onDragEnd={(fromIndex, toIndex) => {
            const data = [...fieldValues.packetOrder];
            const item = data.splice(fromIndex, 1)[0];
            data.splice(toIndex, 0, item);
            change("packetOrder", data);
          }}
        >
          <List>
            {fieldValues.packetOrder.map((packet) => (
              <ListItem key={packet.title}>
                <ListItemIcon>
                  <Menu />
                </ListItemIcon>
                <ListItemText primary={packet.title} />
              </ListItem>
            ))}
          </List>
        </ReactDragListView>
      </Grid>
    </>
  ) : (
    <></>
  );
};

export default PacketOrder;
