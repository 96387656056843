export const FETCH_ALL_ADMINS_AND_REVIEWERS = 'FETCH_ALL_ADMINS_AND_REVIEWERS'
export const FETCH_ADMIN_AND_REVIEWER = 'FETCH_ADMIN_AND_REVIEWER'


export const FETCH_ALL_TEMPLATES = 'FETCH_ALL_TEMPLATES'
export const FETCH_TEMPLATE = 'FETCH_TEMPLATE'


export const FETCH_SIGNNOW_SETTINGS = 'FETCH_SIGNNOW_SETTINGS'

export const FETCH_VIEW_CAROUSEL_SETTINGS = 'FETCH_VIEW_CAROUSEL_SETTINGS'
export const FETCH_NEWS_ANNOUNCEMENTS_DETAILS = 'FETCH_NEWS_ANNOUNCEMENTS_DETAILS'