import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";

import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { fetchNewsAndAnnouncementDetails } from "../store/actions/adminActions/settingsActions/settingsActions";

const useStyles = makeStyles((theme) => ({
  innerContainer: {
    backgroundColor: "#FAFAFB",
    paddingLeft: "41px",
    paddingRight: "41px",
  },
  title: {
    fontWeight: 600,
    marginTop: "1em",
    marginBottom: "1em",
    letterSpacing: "2px",
    fontSize: "30px",
  },
  heading: {
    marginTop: "1em",
    marginBottom: "1em",
    fontWeight: "600",
  },
  text: {
    fontSize: "17px",
    color: "#46464d",
  },
  image: {
    padding: "6px",
    height: "250px",
    cursor: "pointer",
  },
}));

const mapStateToProps = (state) => {
  let newsDetails = {};

  if (
    state.adminSettings.settings &&
    Object.keys(state.adminSettings.settings).length
  ) {
    newsDetails = state.adminSettings.settings;
  }

  return {
    newsDetails,
  };
};

const actions = {
  fetchNewsAndAnnouncementDetails,
};

const NewsOrAnnouncementDetails = ({
  fetchNewsAndAnnouncementDetails,
  newsDetails,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let queryParams = useQuery();
  const id = queryParams.get("id");

  useEffect(() => {
    fetchNewsAndAnnouncementDetails(id);
  }, [fetchNewsAndAnnouncementDetails, id]);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <img
          src={newsDetails?.imageUrl}
          alt=""
          className={classes.image}
          style={{ height: "25rem", width: "25rem" }}
        />
      </Dialog>

      <Grid
        container
        style={{ marginBottom: "5rem", justifyContent: "center" }}
      >
        <Grid item md={10} className={classes.innerContainer}>
          <Grid item container direction={"column"}>
            <Typography variant={"h3"} className={classes.title}>
              NEWS AND ANNOUNCEMENTS
            </Typography>

            <Grid item>
              <Grid item container alignItems="center">
                <Grid item md={8} style={{ paddingRight: "65px" }}>
                  <Typography variant={"h6"} className={classes.heading}>
                    {newsDetails?.heading}
                  </Typography>
                  <Typography variant={"subtitle1"} className={classes.text}>
                    {newsDetails?.details}
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <img
                    src={newsDetails?.imageUrl}
                    alt=""
                    className={classes.image}
                    onClick={() => setOpen(true)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default connect(mapStateToProps, actions)(NewsOrAnnouncementDetails);
