import React, { useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Box,
  Switch,
  IconButton,
} from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useHistory } from 'react-router-dom';
import {
  Create as CreateIcon,
  School as SchoolIcon,
  Email as EmailIcon,
  List as ListIcon,
  Group as GroupIcon,
  MonetizationOnOutlined,
  
  PlaylistAddRounded,
} from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from '@mui/icons-material/Home';
import {
  ApplicantsIcon,
  OverViewIcon,
  ProgramsIcon,
  SettingsIcon,
  DropDownIcon,
} from './icons';
import { connect } from 'react-redux';
import { updateMaintenanceMode } from '../../store/actions/applicationSettingsActions/applicationSettingsActions';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  // ADD STYLES HERE
  subMenuWrapper: {
    justifyContent: 'flex-end',
  },
  subMenuInnerWrapper: {
    width: '90%',
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  active: {
    color: '#EA4D97',
    borderLeft: '3px solid #EA4D97',
    '& svg path': {
      fill: '#EA4D97',
    },
  },
  heading: {
    paddingLeft: '1.5rem',
    margin: '1.5rem 0',
    color: '#92929D',
  },
  secondaryIcon: {
    marginLeft: 12,
  },
  logo: {
    width: '90px',
    height: '90px',
    cursor: 'pointer',
    margin: '10px 0 10px 25px',
  },
}));

const mapStateToProps = (state) => {
  let programs = [];

  if (
    state.adminPrograms.allPrograms &&
    state.adminPrograms.allPrograms.length > 0
  ) {
    programs = state.adminPrograms.allPrograms;
  }

  return {
    profile: state.firebase.profile,
    maintenanceMode: state.applicationSettings.maintenanceMode,
    programs,
  };
};

const mapActionToProps = {
  updateMaintenanceMode,
};

const adminRoutes = [
  {
    name: 'Overview',
    to: '/admin/dashboard',
    icon: <OverViewIcon />,
    items: [],
  },
  {
    name: 'Program Dashboards',
    to: '/admin/dashboard',
    icon: <ProgramsIcon />,
    items: [],
    secondaryTo: '/admin/program/new',
    secondaryIcon: <PlaylistAddRounded />,
  },
  {
    name: 'Registered Users',
    to: '/admin/applicants',
    icon: <ApplicantsIcon />,
    items: [],
  },
  {
    name: 'Transactions',
    to: '/admin/transactions',
    icon: <MonetizationOnOutlined htmlColor="#92929D" />,
    items: [],
  },
  {
    name: 'Requested Info',
    to: '/admin/requestedInfo',
    icon: <InfoIcon
    htmlColor="#92929D" />,
    items: [],
  },
  {
    name: 'Archived',
    to: '/admin/archived',
    icon: <ArchiveIcon />,
    items: [],
  },
  {
    name: 'Logs',
    to: '/admin/logs',
    icon: <ListAltIcon />,
    items: [],
  },
  {
    name: 'Directory Table',
    to: '/admin/directoryTable',
    icon: <ListAltIcon />,
    items: [],
  },
  {
    name: 'Settings',
    icon: <SettingsIcon />,
    items: [
      {
        name: 'Affiliates',
        to: '/admin/settings/affiliates',
        icon: <SchoolIcon />,
      },
      {
        name: 'Categories',
        to: '/admin/settings/categories',
        icon: <CreateIcon />,
      },
      {
        name: 'Email Templates',
        to: '/admin/settings/templates',
        icon: <EmailIcon />,
      },
      {
        name: 'Institutions',
        to: '/admin/settings/institutions',
        icon: <SchoolIcon />,
      },
      {
        name: 'Sign Now',
        to: '/admin/settings/signNow',
        icon: <CreateIcon />,
      },
      {
        name: 'Home Page',
        to: '/admin/settings/home',
        icon: <HomeIcon />,
      },
      {
        name: 'Users',
        to: '/admin/settings/users',
        icon: <GroupIcon />,
      },
    ],
  },
];

const adminViewerRoutes = [
  {
    name: 'Overview',
    to: '/admin/dashboard',
    icon: <OverViewIcon />,
    items: [],
  },
  {
    name: 'Program Dashboards',
    to: '/admin/dashboard',
    icon: <ProgramsIcon />,
    items: [],
    secondaryTo: '/admin/program/new',
    secondaryIcon: <PlaylistAddRounded />,
  },
];

const reviewerRoutes = [
  {
    id: 0,
    name: 'Programs',
    link: '/reviewer/program',
    icon: <ListIcon />,
    admin: true,
  },
  // {
  //   id: 1,
  //   name: "Applicants",
  //   link: "/reviewer/applicants",
  //   icon: <PeopleIcon />,
  //   admin: true,
  // },
];

const hasChildren = (item) => {
  const { items: children } = item;
  if (children === undefined) {
    return false;
  }
  if (children.constructor !== Array) {
    return false;
  }
  if (children.length === 0) {
    return false;
  }
  return true;
};

const Sidebar = ({
  profile,
  maintenanceMode,
  updateMaintenanceMode,
  programs,
}) => {
  const matches = useMediaQuery('(min-width:768px)');
  const matchesToggle = useMediaQuery('(min-width:960px)');
  const classes = useStyles();
  const [toggle, setToggle] = useState(false);
  const [checked, setChecked] = useState(false);
  const [routes, setRoutes] = useState(adminRoutes);
  const [, setReRender] = useState(false);

  useEffect(() => {
    setChecked(maintenanceMode);
  }, [maintenanceMode]);

  useEffect(() => {
    let dynamicRoutes = [];
    if (profile.isAdmin) {
      dynamicRoutes = adminRoutes;
    }
    if (profile.isAdminViewer) {
      dynamicRoutes = adminViewerRoutes;
    }
    dynamicRoutes[1].items = [];
    programs.forEach((program) => {
      dynamicRoutes[1].items.push({
        name: program.programName,
        to: `/${
          profile.isAdmin || profile.isAdminViewer ? 'admin' : 'reviewer'
        }/program/dashboard?id=${program.id}`,
      });
    });
    setRoutes(dynamicRoutes);
    setReRender((prev) => !prev);
  }, [programs, profile.isAdmin, profile.isAdminViewer]);

  return (
    <Grid>
      <Box
        style={{
          padding: `${matches ? '' : '0 0 0 20px'}`,
          justifyContent: `${matches ? 'space-evenly' : 'space-between'}`,
        }}
        // display="flex"
        display="none"
        // justifyContent="space-evenly"
        alignItems="center"
      >
        <Typography
        // style={{
        //   justifyContent: `${matches ? "" : "space-between"}`,
        // }}
        >
          Maintenance Mode
        </Typography>
        <Switch
          checked={checked}
          onChange={(event, checked) => {
            setChecked(checked);
            updateMaintenanceMode(checked);
          }}
        />
      </Box>
      <Typography
        onClick={() => {
          setToggle(!toggle);
        }}
        className={classes.heading}
      ></Typography>

      {matchesToggle ? (
        <Grid container direction="column">
          {(profile.isAdmin || profile.isAdminViewer) &&
            routes.map((item, key) => <MenuItem key={key} item={item} />)}
          {profile.isReviewer &&
            reviewerRoutes.map((item, key) => (
              <MenuItem key={key} item={item} />
            ))}
        </Grid>
      ) : !toggle ? (
        <Grid container direction="column">
          {(profile.isAdmin || profile.isAdminViewer) &&
            routes.map((item, key) => <MenuItem key={key} item={item} />)}
          {profile.isReviewer &&
            reviewerRoutes.map((item, key) => (
              <MenuItem key={key} item={item} />
            ))}
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  );
};

const MenuItem = ({ item }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};

const SingleLevel = ({ item }) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <ListItem
      className={history.location.pathname === item.to ? classes.active : ''}
      button
      onClick={() => history.push(item.to)}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.name} />
      {item.secondaryIcon && (
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            item.secondaryTo && history.push(item.secondaryTo);
          }}
          className={classes.secondaryIcon}
          size="small"
        >
          {item.secondaryIcon}
        </IconButton>
      )}
    </ListItem>
  );
};

const MultiLevel = ({ item }) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.name} />
        {open ? <DropDownIcon className={classes.rotate} /> : <DropDownIcon />}
        {item.secondaryIcon && (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              item.secondaryTo && history.push(item.secondaryTo);
            }}
            className={classes.secondaryIcon}
            size="small"
          >
            {item.secondaryIcon}
          </IconButton>
        )}
      </ListItem>
      <Collapse
        classes={{
          wrapper: classes.subMenuWrapper,
          wrapperInner: classes.subMenuInnerWrapper,
        }}
        in={open}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {children.map((child, key) => (
            <MenuItem key={key} item={child} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default connect(mapStateToProps, mapActionToProps)(Sidebar);
