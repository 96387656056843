import React from "react";
import { Grid } from "@mui/material";
import { Field } from "redux-form";
import CheckboxInput from "../../common/form/CheckboxInput";
import Typography from "@mui/material/Typography";

const CheckBoxProgramGrades = ({ fieldName, info }) => {
  return (
    <Grid item>
      <Grid item container alignItems={"center"}>
        <Grid item style={{ color: "primary" }}>
          <Field
            name={fieldName}
            component={CheckboxInput}
            variant={"outlined"}
          />
        </Grid>
        <Grid item>
          <Typography variant={"caption"}>{info}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CheckBoxProgramGrades;
