import { createReducer } from "../../../../common/util/reducerUtil";
import {
    FETCH_ALL_CATEGORIES,
    FETCH_CATEGORY
} from "../../../constants/adminConstants/categoryConstants/adminInstitutionConstants";





const initialState = {
    allCategories: [],
    category: [],
};

const getAllCategories = (state, payload) => {
    return {
        ...state,
        allCategories: payload.categories,
    };
};

const getCategory = (state, payload) => {
    return {
        ...state,
        category: payload.category,
    };
};




export default createReducer(initialState, {
    [FETCH_ALL_CATEGORIES]: getAllCategories,
    [FETCH_CATEGORY]: getCategory,

});
