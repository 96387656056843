import React, { Fragment, useEffect, useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Sidebar from "../../../../ui/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import {
  deleteInstitution,
  fetchAllInstitutions,
} from "../../../../store/actions/adminActions/institutionActions/adminInstitutionActions";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#171725",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight: "24px",
    letter: "0.1px",
  },
}));

const actions = {
  fetchAllInstitutions,
  deleteInstitution,
};

const mapStateToProps = (state) => {
  let institutions = [];

  if (
    state.adminInstitutions.allInstitutions &&
    state.adminInstitutions.allInstitutions.length > 0
  ) {
    institutions = state.adminInstitutions.allInstitutions;
  }

  return {
    institutions,
  };
};

const AdminInstitutions = ({
  fetchAllInstitutions,
  deleteInstitution,
  institutions,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [institutionId, setInstitutionId] = useState(null);

  const history = useHistory();

  useEffect(() => {
    fetchAllInstitutions();
  }, [fetchAllInstitutions]);

  const handleDeleteInstitution = () => {
    deleteInstitution(institutionId);
    setDialogOpen(false);
  };

  return (
    <Fragment>
      {/*DELETE DIALOG*/}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you wish to delete institution?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteInstitution()}
            style={{ color: theme.palette.error.main }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container>
        <Grid item md={2}>
          <Sidebar />
        </Grid>

        <Grid
          item
          md={10}
          style={{
            backgroundColor: "#FAFAFB",
            paddingLeft: "41px",
            paddingRight: "41px",
          }}
        >
          <Grid item container direction={"column"}>
            <Grid item style={{ marginTop: "20px" }}>
              <Typography className={classes.title}>Institutions</Typography>
            </Grid>

            <Grid item style={{ marginLeft: "auto" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push("/admin/settings/institutions/new")}
              >
                Create Institution
              </Button>
            </Grid>

            <Grid item style={{ marginTop: "2em" }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Institution Name</TableCell>
                      <TableCell># of Programs</TableCell>
                      <TableCell>Added By</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {institutions.map((institution) => (
                      <TableRow key={institution.id}>
                        <TableCell component="th" scope="row">
                          {institution.institutionName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {institution.institutionPrograms
                            ? institution.institutionPrograms.length
                            : 0}
                        </TableCell>
                        <TableCell>{institution.addedByName}</TableCell>
                        <TableCell>
                          <Button
                            variant={"contained"}
                            color={"primary"}
                            size={"small"}
                            onClick={() =>
                              history.push(
                                `/admin/settings/institutions/view?id=${institution.id}`
                              )
                            }
                          >
                            View
                          </Button>
                          <Button
                            style={{
                              marginLeft: "0.5em",
                              backgroundColor: theme.palette.info.main,
                              color: "white",
                            }}
                            variant={"contained"}
                            size={"small"}
                            onClick={() =>
                              history.push(
                                `/admin/settings/institutions/edit?id=${institution.id}`
                              )
                            }
                          >
                            Edit
                          </Button>

                          <Button
                            style={{
                              marginLeft: "0.5em",
                              backgroundColor: theme.palette.error.main,
                              color: "white",
                            }}
                            variant={"contained"}
                            size={"small"}
                            onClick={() => {
                              setInstitutionId(institution.id);
                              setDialogOpen(true);
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default connect(mapStateToProps, actions)(AdminInstitutions);
