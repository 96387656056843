import React, { Fragment, useEffect, useState } from 'react';
import { Typography, Button, IconButton, Grid } from '@mui/material';
import { connect } from 'react-redux';
import {
  Field,
  formValueSelector,
  getFormValues,
  isInvalid,
  reduxForm,
} from 'redux-form';
import { combineValidators, isRequired } from 'revalidate';
import TextInputForm from '../../../../../forms/TextInputForm';
import KeyboardDateInput from '../../../../../../common/form/KeyboardDateInput';
import SelectInputForm from '../../../../../forms/SelectInputForm';
import TravelInformationDetails from './TravelInformationDetails';
import TextAreaForm from '../../../../../forms/TextAreaForm';
import {
  submitTravelInformationForm,
  updateTravelInformationForm,
} from '../../../../../../store/actions/applicantActions/programActions/applicantApprovedActions';
import { AddBox } from '@mui/icons-material';
import VaccineCardUpload from './VaccineCardUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { format, parseISO } from 'date-fns';
import { deleteVaccineCard } from '../../../../../../store/actions/applicantActions/programActions/applicantApprovedActions';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import './TravelInformationForm.css';
import toast, { Toaster } from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: '0.4em',
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
    },
  },

  continueButton: {
    marginLeft: '0.4em',
    backgroundColor: '#1976d2',
    color: 'white !Important',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
      //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    },
  },
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  questionText: {
    fontWeight: 600,
    color: '#696974',
    fontSize: '16px',
    //textAlign: "right",
    paddingRight: '1em',
  },
  questionTextArea: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
    paddingRight: '1em',
    marginTop: '1.5em',
  },
}));

const validate = combineValidators({
  lastName: isRequired({ message: 'Required' }),
  firstName: isRequired({ message: 'Required' }),
  // dob: isRequired({ message: 'Required' }),
  email: isRequired({ message: 'Required' }),
  mobileNumber: isRequired({ message: 'Required' }),
  parentGuardianEmergencyContactName1: isRequired({ message: 'Required' }),
  parentGuardianEmergencyContactNumber1: isRequired({ message: 'Required' }),
  arrivalArrivingBy: isRequired({ message: 'Required' }),
  arrivalDepartureCity: isRequired({ message: 'Required' }),
  arrivalArrivalDate: isRequired({ message: 'Required' }),
  arrivalArrivalCity: isRequired({ message: 'Required' }),
  departureArrivingBy: isRequired({ message: 'Required' }),
  departureDepartureCity: isRequired({ message: 'Required' }),
  departureArrivalDate: isRequired({ message: 'Required' }),
  departureArrivalCity: isRequired({ message: 'Required' }),
  covidVaccine: isRequired({ message: 'Required' }),
  covidInjection1: isRequired({ message: 'Required' }),
  covidInjection2: isRequired({ message: 'Required' }),
});

const actions = {
  submitTravelInformationForm,
  deleteVaccineCard,
  updateTravelInformationForm,
};

const mapStateToProps = (state) => {
  const questionSelector = formValueSelector('travelInformationForm');
  console.log(state, 'state');
  const fieldValues = questionSelector(
    state,
    'id',
    'arrivalDepartureCity',
    'arrivalArrivingBy',
    'departureDepartureCity',
    'departureArrivalCity',
    'covidInjection1',
    'parentGuardianEmergencyContactNumber1'
  );
  const initialValues = state.applicantPrograms.registeredProgram[0]
    ?.travelInformation
    ? state.applicantPrograms.registeredProgram[0]?.travelInformation
    : {
        lastName: state.firebase.profile.lastName,
        firstName: state.firebase.profile.firstName,
        middleInitial: state.firebase.profile.middleInitial
          ? state.firebase.profile.middleInitial
          : null,
        dob: state.firebase.profile.dob,
        email: state.firebase.profile.email,
        mobileNumber: state.firebase.profile.mobileNumber,
      };
  const invalid = isInvalid('travelInformationForm');

  const institutionDetails =
    state.applicantPrograms.registeredProgram[0]?.institutionDetails;
  console.log(institutionDetails, 'institutionDetails');
  const vaccineCard = state.applicantPrograms.registeredProgram[0]?.vaccineCard;
  return {
    fieldValues,
    invalid,
    initialValues: {
      ...initialValues,
      // arrivalArrivalDate: institutionDetails?.arrivalDate
      //   ? format(parseISO(institutionDetails?.arrivalDate), 'EEE MMM do y')
      //   : null,
      // arrivalArrivalCity: institutionDetails?.institutionCity || null,
      // departureDepartureCity: institutionDetails?.institutionCity || null,
      // departureArrivalDate: institutionDetails?.departureDate
      //   ? format(parseISO(institutionDetails?.departureDate), 'EEE MMM do y')
      //   : null,
    },
    currentValues: getFormValues('travelInformationForm')(state),
    vaccineCard,
  };
};

const TravelInformationForm = ({
  fieldValues,
  registeredProgramId,
  profile,
  submitTravelInformationForm,
  updateTravelInformationForm,
  vaccineCard,
  handleCloseForm,
  handleSetForm,
  handleSubmit,
  error,
  submitting,
  deleteVaccineCard,
  registeredProgram,
  program,
  currentValues,
}) => {
  const [freqAirlineFieldsNo, setFreqAirlineFieldsNo] = useState(1);
  const [disabled, setDisabled] = useState(false);
  const [fileError, setFileError] = useState('');
  const [exit, setExit] = useState(false);
  const classes = useStyles();
  console.log(currentValues, 'currentValues');
  const renderAirlineFields = (noOfFileds) => {
    const fields = [];
    for (let i = 0; i < noOfFileds; i++) {
      fields.push(
        <>
          <SelectInputForm
            fieldName={`frequentAirline${i + 1}`}
            question={`Frequent Flyer Airline ${i + 1}`}
            options={[
              { value: '' },
              { value: 'Delta' },
              { value: 'Southwest' },
              { value: 'American' },
              { value: 'United' },
            ]}
          />
          <TextInputForm
            fieldName={`frequentAirlineNumber${i + 1}`}
            question={`Frequent Flyer Number ${i + 1}`}
          />
        </>
      );
    }
    return fields;
  };

  const handleDeleteVaccineCard = async (docId, fileName) => {
    setDisabled(true);
    try {
      await deleteVaccineCard(registeredProgramId, docId, fileName);
      setDisabled(false);
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };
  const handleFormSave = async (values) => {
    try {
      console.log(registeredProgramId, values, 'ppppp');
      await updateTravelInformationForm(registeredProgramId, values);
      handleCloseForm();
    } catch (error) {
      console.log(error);
    }
  };
  const notify = () => toast.success('Progress Autosaved.');

  const handleFormAutoSave = async (values) => {
    try {
      console.log(registeredProgramId, values, 'ppppp');
      await updateTravelInformationForm(registeredProgramId, values);
      notify();
      // handleCloseForm();
    } catch (error) {
      console.log(error);
    }
  };
  const saveInterval = 5 * 60 * 1000; // 5 minutes in milliseconds

  useEffect(() => {
    const saveFormInterval = setInterval(() => {
      handleFormAutoSave(currentValues);
      // setOpen(true);
    }, saveInterval);

    // Clear the interval when the component is unmounted
    return () => clearInterval(saveFormInterval);
  }, [currentValues]); // Run the effect whenever currentValues changes
  const handleFormSubmit = async (values) => {
    if (!vaccineCard) {
      setFileError('Please upload vaccine card.');
      return;
    }
    try {
      console.log(registeredProgramId, values, 'qqqq');

      await submitTravelInformationForm(registeredProgramId, values);
      handleCloseForm();
      if (!exit) handleSetForm('requiredDocumentationForm');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Toaster />

      <form autoComplete={'off'} onSubmit={handleSubmit(handleFormSubmit)}>
        {/*TRAVEL INFORMATION*/}
        <Fragment>
          <Typography
            variant={'h6'}
            style={{
              fontWeight: 600,
              marginTop: '1em',
              marginBottom: '1em',
              marginLeft: '15px',
            }}
          >
            Travel Information
          </Typography>

          {/*TRAVEL FORM NOTES*/}
          <TravelInformationDetails />

          <Grid
            container
            style={{
              padding: '2em',
              marginTop: '1em',
            }}
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid item container direction={'column'}>
                <Grid item style={{ marginTop: '1.5em' }}>
                  <Typography variant={'h5'}>
                    <b>
                      Placement:{' '}
                      {registeredProgram.institutionDetails?.institutionName}
                    </b>
                  </Typography>
                </Grid>

                <Grid item>
                  {/*NAME*/}
                  <TextInputForm
                    fieldName={'firstName'}
                    question={'First Name:'}
                  />
                  <TextInputForm
                    fieldName={'lastName'}
                    question={'Last Name:'}
                  />

                  <TextInputForm
                    fieldName={'middleInitial'}
                    question={'Middle Initial:'}
                  />

                  {/*DOB*/}
                  {/* <Grid
                    item
                    container
                    className={classes.fieldContainer}
                    alignItems={'center'}
                  >
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Grid item container direction={'column'}>
                        <Grid item>
                          <Typography
                            // variant={"body1"}
                            className={classes.questionText}
                          >
                            Date of Birth
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Grid item>
                        <Grid item container direction={'column'}>
                          <Grid item className={classes.fieldWrapper}>
                            <Field
                              name={'dob'}
                              component={KeyboardDateInput}
                              type={'text'}
                              variant={'outlined'}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid> */}

                  {/*MOBILE NUMBER*/}
                  <TextInputForm
                    fieldName={'mobileNumber'}
                    question={'Mobile Number:'}
                  />

                  {/*EMAIL*/}
                  <TextInputForm fieldName={'email'} question={'Email:'} />

                  {/*EMERGENCY CONTACT #1*/}
                  <TextInputForm
                    fieldName={'parentGuardianEmergencyContactName1'}
                    question={'Parent/Guardian - Emergency Contact Name:'}
                  />
                  <TextInputForm
                    fieldName={'parentGuardianEmergencyContactNumber1'}
                    question={'Parent/Guardian - Emergency Contact Number:'}
                  />

                  {/*EMERGENCY CONTACT #2*/}
                  <TextInputForm
                    fieldName={'parentGuardianEmergencyContactName2'}
                    question={'Parent/Guardian #2 - Emergency Contact Name:'}
                  />
                  <TextInputForm
                    fieldName={'parentGuardianEmergencyContactNumber2'}
                    question={'Parent/Guardian #2 - Emergency Contact Number:'}
                  />

                  {renderAirlineFields(freqAirlineFieldsNo)}

                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <AddBox
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        setFreqAirlineFieldsNo(freqAirlineFieldsNo + 1)
                      }
                    />
                    <Typography variant="h6">Add Additional Airline</Typography>
                  </Grid>

                  {/*ARRIVAL*/}
                  <Typography
                    variant={'h6'}
                    style={{
                      fontWeight: 600,
                      marginTop: '1em',
                      marginBottom: '1em',
                    }}
                  >
                    Travel Information (Arrival - To Institution)
                  </Typography>

                  <SelectInputForm
                    fieldName={'arrivalArrivingBy'}
                    question={'Arriving By'}
                    options={[
                      { value: '' },
                      { value: 'Flight' },
                      { value: 'Train' },
                      { value: 'Car' },
                    ]}
                  />

                  <TextInputForm
                    fieldName={'arrivalDepartureCity'}
                    question={'Departure City:'}
                  />
                  <TextInputForm
                    fieldName={'arrivalArrivalDate'}
                    question={'Arrival Date:'}
                    placeholder={true}
                    placeholderText={'MM/DD/YYYY'}
                  />
                  <TextInputForm
                    fieldName={'arrivalArrivalCity'}
                    question={'Arrival City:'}
                  />

                  {/*DEPARTURE*/}
                  <Typography
                    variant={'h6'}
                    style={{
                      fontWeight: 600,
                      marginTop: '1em',
                      marginBottom: '1em',
                    }}
                  >
                    Travel Information (Departure - Return To Home)
                  </Typography>
                  <SelectInputForm
                    fieldName={'departureArrivingBy'}
                    question={'Arriving By'}
                    options={[
                      { value: '' },
                      { value: 'Flight' },
                      { value: 'Train' },
                      { value: 'Car' },
                    ]}
                  />

                  <TextInputForm
                    fieldName={'departureDepartureCity'}
                    question={'Departure City:'}
                  />
                  <TextInputForm
                    fieldName={'departureArrivalDate'}
                    question={'Departure Date:'}
                    placeholder={true}
                    placeholderText={'MM/DD/YYYY'}
                  />
                  <TextInputForm
                    fieldName={'departureArrivalCity'}
                    question={'Arrival City:'}
                  />

                  {/*COMMENTS*/}
                  <TextAreaForm
                    fieldName={'comments'}
                    question={
                      'Additional comments regarding the need for any specific travel accommodations'
                    }
                  />

                  <Grid container>
                    <Grid xs={6}>
                      <Typography className={classes.questionTextArea}>
                        Vaccine Card Upload
                      </Typography>
                    </Grid>
                    <Grid xs={6}>
                      {vaccineCard ? (
                        <Grid
                          item
                          style={{ marginTop: '14px', marginRight: '20px' }}
                        >
                          <Grid item container alignItems={'center'}>
                            <Grid item>
                              <Typography variant={'body1'}>
                                {vaccineCard.originalFileName}
                              </Typography>
                            </Grid>
                            <Grid item style={{ marginLeft: '1em' }}>
                              <IconButton
                                disabled={disabled}
                                aria-label="delete file"
                                onClick={() =>
                                  handleDeleteVaccineCard(
                                    vaccineCard.id,
                                    vaccineCard.fileName
                                  )
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <VaccineCardUpload
                          registeredProgramId={registeredProgramId}
                        />
                      )}
                      {fileError && (
                        <Typography style={{ color: 'red' }}>
                          {fileError}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>

        {/*BUTTONS*/}
        <Grid item style={{ marginTop: '1.5em' }}>
          {/*{invalid && (*/}
          {/*  <Grid item container style={{ marginBottom: "0.5em" }}>*/}
          {/*    <Grid item style={{ marginLeft: "auto" }}>*/}
          {/*      <Typography*/}
          {/*        variant={"subtitle1"}*/}
          {/*        style={{ color: theme.palette.error.main, fontWeight: 300 }}*/}
          {/*      >*/}
          {/*        Fill out all required fields.*/}
          {/*      </Typography>*/}
          {/*    </Grid>*/}
          {/*  </Grid>*/}
          {/*)}*/}

          <Grid item container>
            <Grid
              item
              style={{ marginLeft: 'auto' }}
              className="travel-form-buttons"
            >
              <Button
                variant={'outlined'}
                color={'primary'}
                className={classes.button}
                // type={'submit'}
                // disabled={submitting}
                // onClick={() => setExit(true)}
                onClick={() => handleFormSave(currentValues)}
              >
                {/*{fieldValues.id ? "Save And Exit" : "Save And Next"}*/}
                Save for Later
              </Button>
              {!registeredProgram?.finalSubmissionCompleted && (
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  className={classes.continueButton}
                  type={'submit'}
                  disabled={submitting}
                >
                  {/*{fieldValues.id ? "Save And Exit" : "Save And Next"}*/}
                  Continue{' '}
                  <span>
                    <strong> &#8594;</strong>
                  </span>
                </Button>
              )}

              <Button
                variant={'outlined'}
                color={'secondary'}
                className={classes.button}
                onClick={() => handleCloseForm()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: 'travelInformationForm',
    enableReinitialize: true,
    validate,
  })(TravelInformationForm)
);
