import React, { Fragment } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Grid, MenuItem, Typography } from "@mui/material";
import { Field, FieldArray } from "redux-form";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SelectInput from "../../../../common/form/SelectInput";

const useStyles = makeStyles((theme) => ({
  questionText: {
    fontWeight: 600,
    paddingRight: "1em",
  },
  fieldWrapper: {
    paddingTop: "0.4em",
    paddingBottom: "0.4em",
  },
}));

const InstitutionProgramForm = ({ programs }) => {
  const classes = useStyles();
  const theme = useTheme();

  const programFields = ({ fields, meta: { error, submitFailed } }) => (
    <Fragment>
      {fields.length < 1 && (
        <Grid
          item
          container
          className={classes.fieldContainer}
          alignItems={"center"}
        >
          <Grid item lg={10} md={10} sm={10} xs={10}>
            <Grid item container alignItems={"center"}>
              <Grid item>
                <IconButton
                  aria-label="add program"
                  onClick={() => {
                    fields.push({});
                  }}
                >
                  <AddCircleIcon color={"primary"} />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant={"subtitle1"}>Add Program</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {fields.map((program, index) => (
        <Fragment key={index}>
          <Grid
            item
            container
            style={{ marginTop: "1em" }}
            alignItems={"center"}
          >
            <Grid item lg={2} md={2} sm={2} xs={2}>
              <Grid item container direction={"column"}>
                <Grid item>
                  <Typography
                    variant={"body1"}
                    className={classes.questionText}
                  >
                    Program
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={10} md={10} sm={10} xs={10}>
              <Grid item>
                <Grid item container direction={"column"}>
                  <Grid item className={classes.fieldWrapper}>
                    <Field
                      name={`${program}.programId`}
                      label={"Program"}
                      component={SelectInput}
                      type={"text"}
                      variant={"outlined"}
                      // validate={programName}
                    >
                      {programs.map((program) => (
                        <MenuItem key={program.id} value={program.id}>
                          {program.programName}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/*ACTION BUTTONS*/}
          <Grid
            item
            container
            className={classes.fieldContainer}
            alignItems={"center"}
          >
            <Grid item lg={2} md={2} sm={2} xs={2}>
              {/*BLANK*/}
            </Grid>
            <Grid item lg={10} md={10} sm={10} xs={10}>
              <Grid item container>
                <Grid item>
                  <Grid item container alignItems={"center"}>
                    <Grid item>
                      <IconButton
                        aria-label="add program"
                        onClick={() => {
                          fields.push({});
                        }}
                      >
                        <AddCircleIcon color={"primary"} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Typography variant={"subtitle1"}>Add program</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid item container alignItems={"center"}>
                    <Grid item>
                      <IconButton
                        aria-label="delete program"
                        onClick={() => fields.remove(index)}
                      >
                        <CancelIcon
                          style={{ color: theme.palette.error.main }}
                        />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Typography variant={"subtitle1"}>
                        Remove program
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      ))}
    </Fragment>
  );
  return (
    <Grid container justifyContent={"center"} direction={"column"}>
      <FieldArray name="institutionPrograms" component={programFields} />
    </Grid>
  );
};

export default InstitutionProgramForm;
