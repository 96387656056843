import { createReducer } from '../../../common/util/reducerUtil';
import { FETCH_APPLICATION_SETTINGS } from '../../constants/applicationSettingsConstant/applicationSettingsConstant';

const initialState = {};

const getApplicationSettings = (state, payload) => {
    return {
        ...payload.applicationSettings,
    };
};

export default createReducer(initialState, {
    [FETCH_APPLICATION_SETTINGS]: getApplicationSettings,
});
