import React, { Fragment, useEffect } from "react";
import { useTheme } from "@mui/styles";
import { Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Sidebar from "../../../../ui/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

import { fetchAdminAndReviewer } from "../../../../store/actions/adminActions/settingsActions/settingsActions";

// const useStyles = makeStyles((theme) => ({
//   button: {
//     marginLeft: "0.4em",
//   },
//   fieldWrapper: {
//     paddingTop: "0.4em",
//     paddingBottom: "0.4em",
//   },
//   icon: {
//     fontSize: "3em",
//     color: theme.palette.common.textGrey,
//   },
// }));

const actions = {
  fetchAdminAndReviewer,
};

const mapStateToProps = (state) => {
  let user = [];

  if (
    state.adminSettings.adminAndReviewer &&
    state.adminSettings.adminAndReviewer.length > 0
  ) {
    user = state.adminSettings.adminAndReviewer[0];
  }

  return {
    user,
  };
};

const AdminViewUser = ({
  fetchAdminAndReviewer,
  user,
  handleSubmit,
  // error,
  submitting,
}) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get("id");

  useEffect(() => {
    fetchAdminAndReviewer(id);
  }, [fetchAdminAndReviewer, id]);

  return (
    <Grid container>
      <Grid item md={2}>
        <Sidebar />
      </Grid>

      <Grid item md={10} style={{ paddingLeft: "1.5em" }}>
        <Grid item container direction={"column"}>
          <Grid item>
            <Typography variant={"h4"}>
              {user.fullName ? user.fullName : ""} Profile
            </Typography>
          </Grid>

          {/*BUTTONS*/}
          <Grid item style={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              style={{
                color: "white",
                backgroundColor: theme.palette.info.main,
              }}
              onClick={() =>
                history.push(`/admin/settings/users/edit?id=${id}`)
              }
            >
              Edit
            </Button>
            <Button
              style={{ marginLeft: "0.5em" }}
              variant="contained"
              color="primary"
              onClick={() => history.push("/admin/settings/users")}
            >
              Back To Users
            </Button>
          </Grid>

          <Grid container style={{ marginTop: "2em", marginBottom: "3em" }}>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <Grid item container direction={"column"} alignItems={"center"}>
                <Grid item style={{ paddingLeft: "2em", paddingRight: "2em" }}>
                  <Avatar
                    src={user.profileImageURL ? user.profileImageURL : null}
                    style={{ width: "240px", height: "240px" }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/*RIGHT CONTAINER*/}
            <Grid
              item
              lg={9}
              md={6}
              sm={12}
              xs={12}
              style={
                matchesSM ? { marginTop: "1.5em", paddingLeft: "2em" } : null
              }
            >
              <Grid item container direction={"column"}>
                <Fragment>
                  {/*FULL NAME*/}
                  <Grid
                    item
                    container
                    alignItems={"center"}
                    style={{ marginTop: "0.5em" }}
                  >
                    <Grid item md={2}>
                      <Typography
                        variant={"body1"}
                        style={{ fontWeight: "bold" }}
                      >
                        Full Name:
                      </Typography>
                    </Grid>
                    <Grid item md={10}>
                      <Typography variant={"body1"}>
                        {user.middleInitial
                          ? user.firstName +
                            " " +
                            user?.middleInitial +
                            " " +
                            user.lastName
                          : user.firstName + " " + user.lastName}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/*User TYPE*/}
                  <Grid
                    item
                    container
                    alignItems={"center"}
                    style={{ marginTop: "0.5em" }}
                  >
                    <Grid item md={2}>
                      <Typography
                        variant={"body1"}
                        style={{ fontWeight: "bold" }}
                      >
                        Account Type:
                      </Typography>
                    </Grid>
                    <Grid item md={10}>
                      <Typography variant={"body1"}>
                        {user.isAdmin && "Admin"}
                        {user.isReviewer && "Reviewer"}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/*EMAIL*/}
                  <Grid
                    item
                    container
                    alignItems={"center"}
                    style={{ marginTop: "0.5em" }}
                  >
                    <Grid item md={2}>
                      <Typography
                        variant={"body1"}
                        style={{ fontWeight: "bold" }}
                      >
                        Email:
                      </Typography>
                    </Grid>
                    <Grid item md={10}>
                      <Typography variant={"body1"}>{user.email}</Typography>
                    </Grid>
                  </Grid>

                  {/*MOBILE NUMBER*/}
                  {/*<Grid*/}
                  {/*    item*/}
                  {/*    container*/}
                  {/*    alignItems={"center"}*/}
                  {/*    style={{ marginTop: "0.5em" }}*/}
                  {/*>*/}
                  {/*    <Grid item md={2}>*/}
                  {/*        <Typography*/}
                  {/*            variant={"body1"}*/}
                  {/*            style={{ fontWeight: "bold" }}*/}
                  {/*        >*/}
                  {/*            Mobile Number:*/}
                  {/*        </Typography>*/}
                  {/*    </Grid>*/}
                  {/*    <Grid item md={10}>*/}
                  {/*        <Typography variant={"body1"}>*/}
                  {/*            {user.mobileNumber}*/}
                  {/*        </Typography>*/}
                  {/*    </Grid>*/}
                  {/*</Grid>*/}

                  {/*HOUSE NUMBER*/}
                  {/*<Grid*/}
                  {/*    item*/}
                  {/*    container*/}
                  {/*    alignItems={"center"}*/}
                  {/*    style={{ marginTop: "0.5em" }}*/}
                  {/*>*/}
                  {/*    <Grid item md={2}>*/}
                  {/*        <Typography*/}
                  {/*            variant={"body1"}*/}
                  {/*            style={{ fontWeight: "bold" }}*/}
                  {/*        >*/}
                  {/*            House Number:*/}
                  {/*        </Typography>*/}
                  {/*    </Grid>*/}
                  {/*    <Grid item md={10}>*/}
                  {/*        <Typography variant={"body1"}>*/}
                  {/*            {applicant.housePhoneNumber}*/}
                  {/*        </Typography>*/}
                  {/*    </Grid>*/}
                  {/*</Grid>*/}

                  {/*DATE OF BIRTH*/}
                  {/*<Grid*/}
                  {/*    item*/}
                  {/*    container*/}
                  {/*    alignItems={"center"}*/}
                  {/*    style={{ marginTop: "0.5em" }}*/}
                  {/*>*/}
                  {/*    <Grid item md={2}>*/}
                  {/*        <Typography*/}
                  {/*            variant={"body1"}*/}
                  {/*            style={{ fontWeight: "bold" }}*/}
                  {/*        >*/}
                  {/*            DOB:*/}
                  {/*        </Typography>*/}
                  {/*    </Grid>*/}
                  {/*    <Grid item md={10}>*/}
                  {/*        <Typography variant={"body1"}>*/}
                  {/*            {applicant.dob*/}
                  {/*                ? format(parseISO(applicant?.dob), "MMM do y")*/}
                  {/*                : ""}*/}
                  {/*        </Typography>*/}
                  {/*    </Grid>*/}
                  {/*</Grid>*/}

                  {/*GENDER*/}
                  {/*<Grid*/}
                  {/*    item*/}
                  {/*    container*/}
                  {/*    alignItems={"center"}*/}
                  {/*    style={{ marginTop: "0.5em" }}*/}
                  {/*>*/}
                  {/*    <Grid item md={2}>*/}
                  {/*        <Typography*/}
                  {/*            variant={"body1"}*/}
                  {/*            style={{ fontWeight: "bold" }}*/}
                  {/*        >*/}
                  {/*            Gender:*/}
                  {/*        </Typography>*/}
                  {/*    </Grid>*/}
                  {/*    <Grid item md={10}>*/}
                  {/*        <Typography variant={"body1"}>*/}
                  {/*            {applicant.gender}*/}
                  {/*        </Typography>*/}
                  {/*    </Grid>*/}
                  {/*</Grid>*/}

                  {/*ETHNIC BACKGROUND*/}
                  {/*<Grid*/}
                  {/*    item*/}
                  {/*    container*/}
                  {/*    alignItems={"center"}*/}
                  {/*    style={{ marginTop: "0.5em" }}*/}
                  {/*>*/}
                  {/*    <Grid item md={2}>*/}
                  {/*        <Typography*/}
                  {/*            variant={"body1"}*/}
                  {/*            style={{ fontWeight: "bold" }}*/}
                  {/*        >*/}
                  {/*            Ethnic Background:*/}
                  {/*        </Typography>*/}
                  {/*    </Grid>*/}
                  {/*    <Grid item md={10}>*/}
                  {/*        <Typography variant={"body1"}>*/}
                  {/*            {applicant.ethnicBackground}*/}
                  {/*        </Typography>*/}
                  {/*    </Grid>*/}
                  {/*</Grid>*/}

                  {/*CITIZENSHIP STATUS*/}
                  {/*<Grid*/}
                  {/*    item*/}
                  {/*    container*/}
                  {/*    alignItems={"center"}*/}
                  {/*    style={{ marginTop: "0.5em" }}*/}
                  {/*>*/}
                  {/*    <Grid item md={2}>*/}
                  {/*        <Typography*/}
                  {/*            variant={"body1"}*/}
                  {/*            style={{ fontWeight: "bold" }}*/}
                  {/*        >*/}
                  {/*            Citizenship Status:*/}
                  {/*        </Typography>*/}
                  {/*    </Grid>*/}
                  {/*    <Grid item md={10}>*/}
                  {/*        <Typography variant={"body1"}>*/}
                  {/*            {applicant.citizenshipStatus}*/}
                  {/*        </Typography>*/}
                  {/*    </Grid>*/}
                  {/*</Grid>*/}

                  {/*ADDRESS*/}
                  {/*<Grid item container style={{ marginTop: "0.5em" }}>*/}
                  {/*    <Grid item md={2}>*/}
                  {/*        <Typography*/}
                  {/*            variant={"body1"}*/}
                  {/*            style={{ fontWeight: "bold" }}*/}
                  {/*        >*/}
                  {/*            Address:*/}
                  {/*        </Typography>*/}
                  {/*    </Grid>*/}
                  {/*    <Grid item md={10}>*/}
                  {/*        <Typography variant={"body1"}>*/}
                  {/*            {applicant.address1},<br />*/}
                  {/*            {applicant.address2 ? applicant.address2 + "," : null} <br/>*/}
                  {/*            {applicant.city}, {applicant.state},<br />*/}
                  {/*            {applicant.zipCode}*/}
                  {/*        </Typography>*/}
                  {/*    </Grid>*/}
                  {/*</Grid>*/}

                  {/*BIOGRAPHY*/}
                  {/*<Grid item container style={{ marginTop: "1.5em" }}>*/}
                  {/*    <Grid item md={2}>*/}
                  {/*        <Typography*/}
                  {/*            variant={"body1"}*/}
                  {/*            style={{ fontWeight: "bold" }}*/}
                  {/*        >*/}
                  {/*            Biography:*/}
                  {/*        </Typography>*/}
                  {/*    </Grid>*/}
                  {/*    <Grid item md={10}>*/}
                  {/*        <Typography variant={"body1"}>*/}
                  {/*            {applicant.description}*/}
                  {/*        </Typography>*/}
                  {/*    </Grid>*/}
                  {/*</Grid>*/}
                </Fragment>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(AdminViewUser);
