import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../asyncActions/asyncActions";
import { FETCH_RECOMMENDER_APPLICANT } from "../../constants/recommenderConstants/recommenderConstants";
import axios from "../../../config/axios";
import { fetchRegisteredProgram } from "../applicantActions/programActions/applicantProgramActions";

export const fetchRecommenderApplicant = (id) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection("registeredPrograms")
      .doc(`${id}`);
    const programQuery = firestore.collection("programs");

    try {
      dispatch(asyncActionStart());
      let query = await registeredProgramQuery.get();

      let applicant = [];

      if (query.exists) {
        let pgmQuery = await programQuery
          .doc(`${query.data().programId}`)
          .get();

        let programDetails = {
          id: pgmQuery.id,
          ...pgmQuery.data(),
        };

        let user = {
          id: query.id,
          firstName: query.data().firstName,
          lastName: query.data().lastName,
          email: query.data().email,
          programDetails: programDetails,
        };
        applicant.push(user);
      }

      if (!query.exists) {
        dispatch(asyncActionFinish());
        return;
      }

      dispatch({
        type: FETCH_RECOMMENDER_APPLICANT,
        payload: { applicant },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const sendRecommenderResponse = (id, values, type) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection("registeredPrograms")
      .doc(`${id}`);

    const recommendersFormQuery = firestore
      .collection("registeredPrograms")
      .doc(`${id}`)
      .collection("recommendersForm");

    const recommendersResponseFormQuery = firestore
      .collection("registeredPrograms")
      .doc(`${id}`)
      .collection("recommendersResponseForm");

    let recommenderType = "";
    if (type === "Counselor") {
      recommenderType = "counselor";
    }
    if (type === "Personal") {
      recommenderType = "personal";
    }

    if (type === "Academic") {
      recommenderType = "academic";
    }
    let applicantFirstName = "";
    let applicantLastName = "";
    let applicantEmail = "";

    try {
      dispatch(asyncActionStart());

      let registeredProgram = await registeredProgramQuery.get();
      let recommenderQuery = await recommendersFormQuery.get();

      if (registeredProgram.exists) {
        applicantFirstName = registeredProgram.data().firstName;
        applicantLastName = registeredProgram.data().lastName;
        applicantEmail = registeredProgram.data().email;
      }

      let recommenders = [];


      for (let i = 0; i < recommenderQuery.docs.length; i++) {
        let recommender = {
          ...recommenderQuery.docs[i].data(),
          id: recommenderQuery.docs[i].id,
        };
        recommenders.push(recommender);
      }

      const recommender = recommenders.filter(
        (recommender) => recommender.type === recommenderType
      );

      if (type === "Counselor") {
        await axios.get(
          "/applicantRecommenderFunctions/sendRecommenderResponse",
          {
            params: {
              id: id,
              applicantFirstName,
              applicantLastName,
              applicantEmail,
              recommenderFirstName: recommender[0].recommenderFirstName,
              recommenderLastName: recommender[0].recommenderLastName,
              recommenderEmailAddress: recommender[0].recommenderEmailAddress,
              type,
            },
          }
        );
        await axios.get(
          "/applicantRecommenderFunctions/sendApplicantResponse",
          {
            params: {
              id: id,
              applicantFirstName,
              applicantLastName,
              applicantEmail,
              recommenderFirstName: recommender[0].recommenderFirstName,
              recommenderLastName: recommender[0].recommenderLastName,
              recommenderEmailAddress: recommender[0].recommenderEmailAddress,
              type,
            },
          }
        );

        let recommendersFormCompleted = false

        if (registeredProgram.data().personalRecommenderResponseReceived && registeredProgram.data().academicRecommenderResponseReceived) {
          recommendersFormCompleted = true

          await axios.get(
            "/applicantApplicationFunctions/sendCompleteApplicationReminder",
            {
              params: {
                id: id,
                applicantFirstName,
                applicantLastName,
                applicantEmail,
              },
            }
          );
        }

        await registeredProgramQuery.update({
          counselorRecommenderResponseReceived: true,
          recommendersFormCompleted: recommendersFormCompleted,
          counselorRecommenderResponse: {
            type: recommenderType,
            recommenderFirstName: recommender[0].recommenderFirstName,
            recommenderLastName: recommender[0].recommenderLastName,
            recommenderEmailAddress: recommender[0].recommenderEmailAddress,
            ...values,
          }
        });
      }
      if (type === "Personal") {
        await axios.get(
          "/applicantRecommenderFunctions/sendRecommenderResponse",
          {
            params: {
              id: id,
              applicantFirstName,
              applicantLastName,
              applicantEmail,
              recommenderFirstName: recommender[0].recommenderFirstName,
              recommenderLastName: recommender[0].recommenderLastName,
              recommenderEmailAddress: recommender[0].recommenderEmailAddress,
              type,
            },
          }
        );
        await axios.get(
          "/applicantRecommenderFunctions/sendApplicantResponse",
          {
            params: {
              id: id,
              applicantFirstName,
              applicantLastName,
              applicantEmail,
              recommenderFirstName: recommender[0].recommenderFirstName,
              recommenderLastName: recommender[0].recommenderLastName,
              recommenderEmailAddress: recommender[0].recommenderEmailAddress,
              type,
            },
          }
        );

        let recommendersFormCompleted = false

        if (registeredProgram.data().counselorRecommenderResponseReceived && registeredProgram.data().academicRecommenderResponseReceived) {
          recommendersFormCompleted = true

          await axios.get(
            "/applicantApplicationFunctions/sendCompleteApplicationReminder",
            {
              params: {
                id: id,
                applicantFirstName,
                applicantLastName,
                applicantEmail,
              },
            }
          );

        }

        await registeredProgramQuery.update({
          personalRecommenderResponseReceived: true,
          recommendersFormCompleted: recommendersFormCompleted,
          personalRecommenderResponse: {
            type: recommenderType,
            recommenderFirstName: recommender[0].recommenderFirstName,
            recommenderLastName: recommender[0].recommenderLastName,
            recommenderEmailAddress: recommender[0].recommenderEmailAddress,
            ...values,
          }
        });
      }
      if (type === "Academic") {
        await axios.get(
          "/applicantRecommenderFunctions/sendRecommenderResponse",
          {
            params: {
              id: id,
              applicantFirstName,
              applicantLastName,
              applicantEmail,
              recommenderFirstName: recommender[0].recommenderFirstName,
              recommenderLastName: recommender[0].recommenderLastName,
              recommenderEmailAddress: recommender[0].recommenderEmailAddress,
              type,
            },
          }
        );
        await axios.get(
          "/applicantRecommenderFunctions/sendApplicantResponse",
          {
            params: {
              id: id,
              applicantFirstName,
              applicantLastName,
              applicantEmail,
              recommenderFirstName: recommender[0].recommenderFirstName,
              recommenderLastName: recommender[0].recommenderLastName,
              recommenderEmailAddress: recommender[0].recommenderEmailAddress,
              type,
            },
          }
        );

        let recommendersFormCompleted = false

        if (registeredProgram.data().personalRecommenderResponseReceived && registeredProgram.data().counselorRecommenderResponseReceived) {
          recommendersFormCompleted = true

          await axios.get(
            "/applicantApplicationFunctions/sendCompleteApplicationReminder",
            {
              params: {
                id: id,
                applicantFirstName,
                applicantLastName,
                applicantEmail,
              },
            }
          );
        }

        await registeredProgramQuery.update({
          academicRecommenderResponseReceived: true,
          recommendersFormCompleted: recommendersFormCompleted,
          academicRecommenderResponse: {
            type: recommenderType,
            recommenderFirstName: recommender[0].recommenderFirstName,
            recommenderLastName: recommender[0].recommenderLastName,
            recommenderEmailAddress: recommender[0].recommenderEmailAddress,
            ...values,
          }
        });
      }

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
