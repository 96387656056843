import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";

import NeedsReviewTable from "../../../ui/admin/dashboard/NeedsReviewTable";

import { connect } from "react-redux";

import {
  fetchAllReviewerCompletedApplications,
  fetchAllReviewerReviewedApplications,
} from "../../../store/actions/reviewerActions/dashboardActions/dashboardActions";

const actions = {
  fetchAllReviewerCompletedApplications,
  fetchAllReviewerReviewedApplications,
};

const mapStateToProps = (state) => {
  let completedApplications = [];
  let reviewedApplications = [];

  if (
    state.reviewerDashboard.allCompletedApplications &&
    state.reviewerDashboard.allCompletedApplications.length > 0
  ) {
    completedApplications = state.reviewerDashboard.allCompletedApplications;
  }
  if (
    state.reviewerDashboard.allReviewedApplications &&
    state.reviewerDashboard.allReviewedApplications.length > 0
  ) {
    reviewedApplications = state.reviewerDashboard.allReviewedApplications;
  }

  return {
    completedApplications,
    reviewedApplications,
    profile: state.firebase.profile,
  };
};

const ReviewerDashboard = ({
  fetchAllReviewerCompletedApplications,
  fetchAllReviewerReviewedApplications,
  completedApplications,
  reviewedApplications,
  profile,
}) => {
  useEffect(() => {
    fetchAllReviewerCompletedApplications(profile.uid);
    fetchAllReviewerReviewedApplications(profile.uid);
  }, [
    fetchAllReviewerCompletedApplications,
    fetchAllReviewerReviewedApplications,
    profile.uid,
  ]);

  return (
    <Grid container>
      <Grid item md={2}>
        {/*<Sidebar />*/}
      </Grid>

      <Grid item md={10} style={{ padding: "2em" }}>
        <Grid item container direction={"column"}>
          <Grid item>
            <Typography variant={"h4"}>
              Welcome {profile.fullName ? profile.fullName : ""}
            </Typography>
          </Grid>

          <Grid item style={{ marginTop: "1.5em" }}>
            <Grid item container>
              <Grid item md={12} style={{ paddingRight: "2em" }}>
                {/*NEEDS REVIEW - ASSIGNED*/}
                <NeedsReviewTable
                  rows={completedApplications}
                  title={"Needs Review"}
                  reviewStatus={"assigned"}
                  profile={profile}
                />

                {/*REVIEWED TABLE*/}
                {/*<ReviewedTable*/}
                {/*  rows={reviewedApplications}*/}
                {/*  title={"Reviewed"}*/}
                {/*  profile={profile}*/}
                {/*  approve={approve}*/}
                {/*/>*/}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(ReviewerDashboard);
