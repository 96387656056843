import React, { Fragment, useEffect, useState } from 'react';
import {
  Grid,
  useTheme,
  Typography,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
} from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import {
  formValueSelector,
  getFormValues,
  isInvalid,
  reduxForm,
} from 'redux-form';
import { combineValidators, isRequired } from 'revalidate';
import TextInputForm from '../../../../forms/TextInputForm';
import SelectInputForm from '../../../../forms/SelectInputForm';
import StateLookup from '../../../../forms/formLookups/StateLookup';
import {
  submitFamilyInformationForm,
  updateFamilyInformationForm,
} from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import { makeStyles } from '@mui/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { closeDialog } from '../../../../../store/actions/dialogActions/dialogActions';
import toast, { Toaster } from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: '0.4em',
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
    },
  },

  continueButton: {
    marginLeft: '0.4em',
    backgroundColor: '#1976d2',
    color: 'white !Important',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
      //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    },
  },
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  formName: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '72px',
    letter: '0.2px',
  },
  formText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  formQuestionText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
}));

const validate = combineValidators({
  parentsMaritalStatus: isRequired({ message: 'Required' }),
  numberOfSiblings: isRequired({ message: 'Required' }),

  parentGuardianNameNumber1: isRequired({ message: 'Required' }),
  parentGuardianRelationshipToApplicantNumber1: isRequired({
    message: 'Required',
  }),
  parentGuardianDoYouLiveWithParentGuardianNumber1: isRequired({
    message: 'Required',
  }),
  parentGuardianAddress1Number1: isRequired({ message: 'Required' }),
  // parentGuardianAddress2Number1: isRequired({ message: "Required" }),
  parentGuardianCityNumber1: isRequired({ message: 'Required' }),
  parentGuardianStateNumber1: (value) => {
    if (!value) return 'Required';
  },
  parentGuardianZipCodeNumber1: isRequired({ message: 'Required' }),
  parentGuardianHomeMobileNumberNumber1: isRequired({ message: 'Required' }),
  parentGuardianEmailNumber1: isRequired({ message: 'Required' }),
  parentGuardianEmployerNumber1: isRequired({ message: 'Required' }),
  parentGuardianOccupationTitleNumber1: isRequired({ message: 'Required' }),
  parentGuardianEducationLevelNumber1: isRequired({ message: 'Required' }),
  // primaryParentContact: isRequired({ message: "Required" }),
});
const arr = {
  parentsMaritalStatus: '',
  namesOfParentsYouLiveWith: '',
  numberOfSiblings: '',
  siblingsAge: '',
  parentGuardianNameNumber1: '',
  parentGuardianRelationshipToApplicantNumber1: '',
  parentGuardianDoYouLiveWithParentGuardianNumber1: '',
  parentGuardianAddress1Number1: '',
  parentGuardianStateNumber1: '',
  parentGuardianEducationLevelNumber1: '',
  parentGuardianOccupationTitleNumber1: '',
  parentGuardianEmployerNumber1: '',
  parentGuardianEmailNumber1: '',
  parentGuardianHomeMobileNumberNumber1: '',
  parentGuardianZipCodeNumber1: '',
};

const actions = {
  submitFamilyInformationForm,
  updateFamilyInformationForm,
};

const mapStateToProps = (state) => {
  const questionSelector = formValueSelector('familyInformationForm');
  const fieldValues = questionSelector(
    state,
    'id',
    'parentsMaritalStatus',
    'parentGuardianAddress1Number1',
    'parentGuardianAddress2Number1',
    'parentGuardianCityNumber1',
    'parentGuardianStateNumber1',
    'parentGuardianZipCodeNumber1',
    'parentGuardianHomeMobileNumberNumber1',
    'parentGuardianHomeMobileNumberNumber2'
  );
  const invalid = isInvalid('familyInformationForm');
  return {
    fieldValues,
    invalid,
    initialValues: state.applicantPrograms.registeredProgram[0]
      .familyInformationFormDetails || {
      parentGuardianNameNumber1:
        (state.firebase.profile.parentFirstName || '') +
        ' ' +
        (state.firebase.profile.parentLastName || ''),
    },
    currentValues: getFormValues('familyInformationForm')(state),
  };
};

const normalizePhoneNumber = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;
  if (cvLength < 4) return currentValue;
  if (cvLength < 7)
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
    3,
    6
  )}-${currentValue.slice(6, 10)}`;
};

const FamilyInformationForm = ({
  fieldValues,
  invalid,
  registeredProgramId,
  profile,
  submitFamilyInformationForm,
  updateFamilyInformationForm,
  handleCloseForm,
  handleSetForm,
  handleSubmit,
  submitting,
  change,
  currentValues,
  program,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const states = StateLookup.states;

  const [parent2Same, setParent2Same] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleClose = () => {
    setOpen(false);
    dispatch(closeDialog());
  };
  useEffect(() => {
    const {
      parentGuardianAddress1Number1,
      parentGuardianAddress2Number1,
      parentGuardianCityNumber1,
      parentGuardianStateNumber1,
      parentGuardianZipCodeNumber1,
    } = fieldValues;
    if (parent2Same) {
      change('parentGuardianAddress1Number2', parentGuardianAddress1Number1);
      change('parentGuardianAddress2Number2', parentGuardianAddress2Number1);
      change('parentGuardianCityNumber2', parentGuardianCityNumber1);
      change('parentGuardianStateNumber2', parentGuardianStateNumber1);
      change('parentGuardianZipCodeNumber2', parentGuardianZipCodeNumber1);
    }
  }, [parent2Same, fieldValues, change]);

  useEffect(() => {
    change(
      'parentGuardianHomeMobileNumberNumber1',
      normalizePhoneNumber(fieldValues.parentGuardianHomeMobileNumberNumber1)
    );
  }, [fieldValues.parentGuardianHomeMobileNumberNumber1, change]);

  useEffect(() => {
    change(
      'parentGuardianHomeMobileNumberNumber2',
      normalizePhoneNumber(fieldValues.parentGuardianHomeMobileNumberNumber2)
    );
  }, [fieldValues.parentGuardianHomeMobileNumberNumber2, change]);

  const programsAllThings = [
    'applicationInterests',
    'personalInformationForm',
    'familyInformationForm',
    'academicInformationForm',
    // 'recommendationsRequired',
    'recommendersForm',
    'extracurricularForm',
    'covidProtocolForm',
    'resumeForm',
    'transcriptForm',
    'shortResponsesForm',
    'scholarshipForm',
    'taxForm',
    'additionalDocumentsForm',
    'confirmationForm',
  ];

  const programAllThingsValue = {
    applicationInterests: 'applicationInterests',
    personalInformationForm: 'personalInformationForm',
    familyInformationForm: 'familyInformationForm',
    academicInformationForm: 'academicInformationForm',
    // recommendationsRequired: 'recommendationsRequired',
    recommendersForm: 'recommendersForm',
    extracurricularForm: 'extracurricularForm',
    covidProtocolForm: 'covidProtocolForm',
    resumeForm: 'resumeForm',
    transcriptForm: 'transcriptForm',
    shortResponsesForm: 'shortResponsesForm',
    scholarshipForm: 'scholarshipForm',
    taxForm: 'taxForm',
    additionalDocumentsForm: 'additionalDocumentsForm',
    confirmationForm: 'confirmationForm',
  };

  let dataToCheck = programsAllThings
    .filter((item) => program[item])
    .map((item) => programAllThingsValue[item]);

  const handleFormSubmit = async (values) => {
    try {
      await submitFamilyInformationForm(registeredProgramId, values);
      handleCloseForm();
      const indexOfPersonalInfoForm = dataToCheck.indexOf(
        'familyInformationForm'
      );
      if (
        indexOfPersonalInfoForm !== -1 &&
        indexOfPersonalInfoForm < dataToCheck.length - 1
      ) {
        const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
        handleSetForm(nextForm);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const notify = () => toast.success('Progress Autosaved.');

  const handleFormSave = async (values) => {
    function hasAllKeys(arr, values) {
      for (const key in arr) {
        if (arr.hasOwnProperty(key)) {
          if (!values.hasOwnProperty(key) || values[key] === '') {
            return false;
          }
        }
      }
      return true;
    }

    // Check if all keys from object A exist in object B
    const conditionMet = hasAllKeys(arr, values);
    if (conditionMet) {
      values.allRequiredDocumentFilled = true;
    }
    if (!conditionMet) {
      values.allRequiredDocumentFilled = false;
    }
    console.log(values, 'lkj');
    try {
      await updateFamilyInformationForm(registeredProgramId, values);
      handleCloseForm();
    } catch (error) {
      console.log(error);
    }
  };
  const handleFormAutoSave = async (values) => {
    function hasAllKeys(arr, values) {
      for (const key in arr) {
        if (arr.hasOwnProperty(key)) {
          if (!values.hasOwnProperty(key) || values[key] === '') {
            return false;
          }
        }
      }
      return true;
    }

    // Check if all keys from object A exist in object B
    const conditionMet = hasAllKeys(arr, values);
    if (conditionMet) {
      values.allRequiredDocumentFilled = true;
    }
    if (!conditionMet) {
      values.allRequiredDocumentFilled = false;
    }
    console.log(values, 'lkj');
    try {
      await updateFamilyInformationForm(registeredProgramId, values);
      // handleCloseForm();
      notify();
    } catch (error) {
      console.log(error);
    }
  };
  const saveInterval = 5 * 60 * 1000;
  useEffect(() => {
    const saveFormInterval = setInterval(() => {
      handleFormAutoSave(currentValues);
    }, saveInterval);
    return () => clearInterval(saveFormInterval);
  }, [currentValues]);
  return (
    <Fragment>
      <Toaster />

      <form autoComplete={'off'} onSubmit={handleSubmit(handleFormSubmit)}>
        {/*FAMILY INFORMATION*/}
        <Fragment>
          <Typography className={classes.formName}>
            Family Information
          </Typography>

          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid item container direction={'column'}>
                <Grid item>
                  {/*PARENTS MARITAL STATUS*/}
                  <SelectInputForm
                    fieldName={'parentsMaritalStatus'}
                    question={"Your Parent's Marital Status:"}
                    options={[
                      { value: '' },
                      { value: 'Divorced' },
                      { value: 'Married' },
                      { value: 'Separated' },
                      { value: 'Single' },
                      { value: 'Widowed' },
                    ]}
                  />

                  {/*NUMBER OF SIBINGS*/}
                  <TextInputForm
                    fieldName={'numberOfSiblings'}
                    question={'Number of Siblings:'}
                  />
                  {/*SIBLINGS AGE*/}
                  <TextInputForm
                    fieldName={'siblingsAge'}
                    question={
                      'Sibling(s) Name(s) & Age(s) - (seperated by a comma (,)):'
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>

        {/*PARENTS GUARDIAN OTHER #1*/}

        <Fragment>
          <Typography className={classes.formName}>
            Parent/ Legal Guardian
          </Typography>

          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid item container direction={'column'}>
                <Grid item>
                  {/*TITLE*/}
                  <TextInputForm
                    fieldName={'parentGuardianTitleNumber1'}
                    question={'Title:'}
                  />

                  {/*NAME*/}
                  <TextInputForm
                    fieldName={'parentGuardianNameNumber1'}
                    question={'Parent Full Name:'}
                  />
                  {/*RELATIONSHIP TO APPLICANT*/}
                  <TextInputForm
                    fieldName={'parentGuardianRelationshipToApplicantNumber1'}
                    question={'Relationship to the applicant (you):'}
                  />
                  {/*DO YOU LIVE WITH PARENT GUARDIAN*/}
                  <SelectInputForm
                    fieldName={
                      'parentGuardianDoYouLiveWithParentGuardianNumber1'
                    }
                    question={'Do you live with this Parent/Guardian?'}
                    options={[{ value: '' }, { value: 'Yes' }, { value: 'No' }]}
                  />
                  {/*ADDRESS 1*/}
                  <TextInputForm
                    fieldName={'parentGuardianAddress1Number1'}
                    question={'Address 1:'}
                  />
                  {/*ADDRESS 2*/}
                  <TextInputForm
                    fieldName={'parentGuardianAddress2Number1'}
                    question={'Address 2:'}
                  />
                  {/*CITY*/}
                  <TextInputForm
                    fieldName={'parentGuardianCityNumber1'}
                    question={'City:'}
                  />
                  {/*STATE*/}
                  <SelectInputForm
                    fieldName={'parentGuardianStateNumber1'}
                    question={'State:'}
                    options={states}
                    text={true}
                  />
                  {/*ZIP*/}
                  <TextInputForm
                    fieldName={'parentGuardianZipCodeNumber1'}
                    question={'Zip Code:'}
                  />
                  {/*HOME MOBILE NUMBER*/}
                  <TextInputForm
                    fieldName={'parentGuardianHomeMobileNumberNumber1'}
                    question={'Home or Mobile Number:'}
                  />

                  {/*EMAIL ADDRESS*/}
                  <TextInputForm
                    fieldName={'parentGuardianEmailNumber1'}
                    question={'Parent/Guardian Email Address:'}
                  />
                  {/*EMPLOYER*/}
                  <TextInputForm
                    fieldName={'parentGuardianEmployerNumber1'}
                    question={'Employer:'}
                  />
                  {/*OCCUPATION/TITLE*/}
                  <TextInputForm
                    fieldName={'parentGuardianOccupationTitleNumber1'}
                    question={'Occupation/Title:'}
                  />
                  {/*EDUCATION LEVEL*/}
                  <SelectInputForm
                    fieldName={'parentGuardianEducationLevelNumber1'}
                    question={'Highest Education Level:'}
                    options={[
                      { value: '' },
                      { value: '8th Grade or less' },
                      { value: 'Some High School' },
                      { value: 'High School Graduate' },
                      { value: 'GED' },
                      { value: 'Some College' },
                      { value: "Bachelor's Degree" },
                      { value: "Master's Degree" },
                      { value: 'Professional Degree (JD, MD)' },
                      { value: 'Doctorate Degree' },
                    ]}
                  />
                  {/*OCCUPATION/TITLE*/}
                  {/* <TextInputForm
                    fieldName={'primaryParentContact'}
                    question={'Please provide the contact information of the primary parent that will be responsible to sign any edocuments'}
                  /> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>

        {/*PARENTS GUARDIAN OTHER #2*/}

        <Fragment>
          <Grid container direction={'column'}>
            <Typography className={classes.formName}>
              Additional Parent/ Legal Guardian
            </Typography>

            <FormControlLabel
              value="Address same as above"
              control={
                <Checkbox
                  checked={parent2Same}
                  onChange={(event, checked) => setParent2Same(checked)}
                  size="small"
                  color="primary"
                />
              }
              label="Address same as above"
              labelPlacement="end"
            />
          </Grid>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid item container direction={'column'}>
                <Grid item>
                  {/*TITLE*/}
                  <TextInputForm
                    fieldName={'parentGuardianTitleNumber2'}
                    question={'Title:'}
                  />

                  {/*NAME*/}
                  <TextInputForm
                    fieldName={'parentGuardianNameNumber2'}
                    question={'Parent Full Name:'}
                  />
                  {/*RELATIONSHIP TO APPLICANT*/}
                  <TextInputForm
                    fieldName={'parentGuardianRelationshipToApplicantNumber2'}
                    question={'Relationship to the applicant (you):'}
                  />
                  {/*DO YOU LIVE WITH PARENT GUARDIAN*/}
                  <SelectInputForm
                    fieldName={
                      'parentGuardianDoYouLiveWithParentGuardianNumber2'
                    }
                    question={'Do you live with this Parent/Guardian?'}
                    options={[{ value: '' }, { value: 'Yes' }, { value: 'No' }]}
                  />
                  {/*ADDRESS 1*/}
                  <TextInputForm
                    fieldName={'parentGuardianAddress1Number2'}
                    question={'Address 1:'}
                  />
                  {/*ADDRESS 2*/}
                  <TextInputForm
                    fieldName={'parentGuardianAddress2Number2'}
                    question={'Address 2:'}
                  />
                  {/*CITY*/}
                  <TextInputForm
                    fieldName={'parentGuardianCityNumber2'}
                    question={'City:'}
                  />
                  {/*STATE*/}
                  <SelectInputForm
                    fieldName={'parentGuardianStateNumber2'}
                    question={'State:'}
                    options={states}
                    text={true}
                  />
                  {/*ZIP*/}
                  <TextInputForm
                    fieldName={'parentGuardianZipCodeNumber2'}
                    question={'Zip Code:'}
                  />
                  {/*HOME MOBILE NUMBER*/}
                  <TextInputForm
                    fieldName={'parentGuardianHomeMobileNumberNumber2'}
                    question={'Home or Mobile Number:'}
                  />

                  {/*EMAIL ADDRESS*/}
                  <TextInputForm
                    fieldName={'parentGuardianEmailNumber2'}
                    question={'Parent/Guardian Email Address:'}
                  />
                  {/*EMPLOYER*/}
                  <TextInputForm
                    fieldName={'parentGuardianEmployerNumber2'}
                    question={'Employer:'}
                  />
                  {/*OCCUPATION/TITLE*/}
                  <TextInputForm
                    fieldName={'parentGuardianOccupationTitleNumber2'}
                    question={'Occupation/Title:'}
                  />
                  {/*EDUCATION LEVEL*/}
                  <SelectInputForm
                    fieldName={'parentGuardianEducationLevelNumber2'}
                    question={'Highest Education Level:'}
                    options={[
                      { value: '' },
                      { value: '8th Grade or less' },
                      { value: 'Some High School' },
                      { value: 'High School Graduate' },
                      { value: 'GED' },
                      { value: 'Some College' },
                      { value: "Bachelor's Degree" },
                      { value: "Master's Degree" },
                      { value: 'Professional Degree (JD, MD)' },
                      { value: 'Doctorate Degree' },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>

        {/*BUTTONS*/}
        <Grid item style={{ marginTop: '1.5em' }}>
          {invalid && (
            <Grid item container style={{ marginBottom: '0.5em' }}>
              <Grid item style={{ marginLeft: 'auto' }}>
                <Typography
                  variant={'subtitle1'}
                  style={{ color: theme.palette.error.main, fontWeight: 300 }}
                >
                  Fill out all required fields.
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid item container>
            <Grid item style={{ marginLeft: 'auto' }}>
              <Button
                variant={'outlined'}
                color={'primary'}
                className={classes.button}
                onClick={() => handleFormSave(currentValues)}
              >
                Save for Later
              </Button>

              <Button
                variant={'outlined'}
                color={'primary'}
                className={classes.continueButton}
                type={'submit'}
                disabled={submitting}
              >
                Continue{' '}
                <span>
                  <strong> &#8594;</strong>
                </span>
              </Button>

              <Button
                variant={'outlined'}
                color={'secondary'}
                className={classes.button}
                onClick={() => handleCloseForm()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: 'familyInformationForm',
    enableReinitialize: true,
    validate,
  })(FamilyInformationForm)
);
