import React, { Fragment } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import ReviewProgramItem from "./item/ReviewProgramItem";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  answerText: {
    fontWeight: 600,
  },
}));

const FamilyInformationFormReview = ({ familyInformation }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction={"column"}
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
    >
      <Grid item>
        <Typography className={classes.title}>
          Family Information Form
        </Typography>
      </Grid>
      {/*LAST NAME*/}
      <ReviewProgramItem
        question={"Your Parent's Marital Status:"}
        value={familyInformation?.parentsMaritalStatus}
      />
      <ReviewProgramItem
        question={
          "Name(s) of the Parent(s) or Legal Guardian(s) with whom you live:"
        }
        value={familyInformation?.namesOfParentsYouLiveWith}
      />
      <ReviewProgramItem
        question={"Number of Siblings:"}
        value={familyInformation?.numberOfSiblings}
      />
      <ReviewProgramItem
        question={"Sibling(s) Age(s):"}
        value={familyInformation?.siblingsAge}
      />

      <Grid item style={{ marginTop: "1rem" }}>
        <Typography variant={"h6"} className={classes.title}>
          Parent/Guardian/Other #1
        </Typography>
      </Grid>
      <ReviewProgramItem
        question={"Title:"}
        value={familyInformation?.parentGuardianTitleNumber1}
      />
      <ReviewProgramItem
        question={"Name:"}
        value={familyInformation?.parentGuardianNameNumber1}
      />
      <ReviewProgramItem
        question={"Relationship to the applicant (you):"}
        value={familyInformation?.parentGuardianRelationshipToApplicantNumber1}
      />
      <ReviewProgramItem
        question={"Do you live with this Parent/Guardian?"}
        value={
          familyInformation?.parentGuardianDoYouLiveWithParentGuardianNumber1
        }
      />
      <ReviewProgramItem
        question={"Address 1:"}
        value={familyInformation?.parentGuardianAddress1Number1}
      />
      <ReviewProgramItem
        question={"Address 2:"}
        value={familyInformation?.parentGuardianAddress2Number1}
      />
      <ReviewProgramItem
        question={"City:"}
        value={familyInformation?.parentGuardianCityNumber1}
      />
      <ReviewProgramItem
        question={"State:"}
        value={familyInformation?.parentGuardianStateNumber1}
      />
      <ReviewProgramItem
        question={"Zip Code:"}
        value={familyInformation?.parentGuardianZipCodeNumber1}
      />
      <ReviewProgramItem
        question={"Home or Mobile Number"}
        value={familyInformation?.parentGuardianHomeMobileNumberNumber1}
      />
      <ReviewProgramItem
        question={"Guardian Email Address:"}
        value={familyInformation?.parentGuardianEmailNumber1}
      />
      <ReviewProgramItem
        question={"Employer:"}
        value={familyInformation?.parentGuardianEmployerNumber1}
      />
      <ReviewProgramItem
        question={"Occupation/Title:"}
        value={familyInformation?.parentGuardianOccupationTitleNumber1}
      />
      <ReviewProgramItem
        question={"Highest Education Level:"}
        value={familyInformation?.parentGuardianEducationLevelNumber1}
      />
      {/* <ReviewProgramItem
        question={"Please provide the contact information of the primary parent that will be responsible to sign any edocuments"}
        value={familyInformation?.primaryParentContact}
      /> */}

      {/*PARENT GUARDIAN 2*/}
      {familyInformation?.parentGuardianTitleNumber2 && (
        <Fragment>
          <Grid item>
            <Typography className={classes.title}>
              Parent/Guardian/Other #2
            </Typography>
          </Grid>
          <ReviewProgramItem
            question={"Title:"}
            value={familyInformation?.parentGuardianTitleNumber2}
          />
          <ReviewProgramItem
            question={"Name:"}
            value={familyInformation?.parentGuardianNameNumber2}
          />
          <ReviewProgramItem
            question={"Relationship to the applicant (you):"}
            value={
              familyInformation?.parentGuardianRelationshipToApplicantNumber2
            }
          />
          <ReviewProgramItem
            question={"Do you live with this Parent/Guardian?"}
            value={
              familyInformation?.parentGuardianDoYouLiveWithParentGuardianNumber2
            }
          />
          <ReviewProgramItem
            question={"Address 1:"}
            value={familyInformation?.parentGuardianAddress1Number2}
          />
          <ReviewProgramItem
            question={"Address 2:"}
            value={familyInformation?.parentGuardianAddress2Number2}
          />
          <ReviewProgramItem
            question={"City:"}
            value={familyInformation?.parentGuardianCityNumber2}
          />
          <ReviewProgramItem
            question={"State:"}
            value={familyInformation?.parentGuardianStateNumber2}
          />
          <ReviewProgramItem
            question={"Zip Code:"}
            value={familyInformation?.parentGuardianZipCodeNumber2}
          />
          <ReviewProgramItem
            question={"Home or Mobile Number"}
            value={familyInformation?.parentGuardianHomeMobileNumberNumber2}
          />
          <ReviewProgramItem
            question={"Guardian Email Address:"}
            value={familyInformation?.parentGuardianEmailNumber2}
          />
          <ReviewProgramItem
            question={"Employer:"}
            value={familyInformation?.parentGuardianEmployerNumber2}
          />
          <ReviewProgramItem
            question={"Occupation/Title:"}
            value={familyInformation?.parentGuardianOccupationTitleNumber2}
          />
          <ReviewProgramItem
            question={"Highest Education Level:"}
            value={familyInformation?.parentGuardianEducationLevelNumber2}
          />
        </Fragment>
      )}
    </Grid>
  );
};

export default FamilyInformationFormReview;
