import React, { useEffect, useState, useMemo } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Box,
  Switch,
} from '@mui/material';
import Sidebar from '../../../ui/sidebar/Sidebar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import {
  clearAdminProgram,
  closeAdminProgram,
  deleteAdminProgram,
  fetchAllPrograms,
  duplicateAdminProgram,
  toggleHideProgram,
  toggleHideProgramForStudent,
} from '../../../store/actions/adminActions/programActions/adminProgramActions';
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns';
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from '@mui/x-data-grid-premium';
import { CustomToolbar, escapeRegExp } from '../../../common/tableUtils';

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#171725',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '24px',
    letter: '0.1px',
  },
}));

const actions = {
  fetchAllPrograms,
  deleteAdminProgram,
  clearAdminProgram,
  closeAdminProgram,
  duplicateAdminProgram,
  toggleHideProgram,
  toggleHideProgramForStudent,
};

const mapStateToProps = (state) => {
  let programs = [];
  if (
    state.adminPrograms.allPrograms &&
    state.adminPrograms.allPrograms.length > 0
  ) {
    programs = state.adminPrograms.allPrograms;
  }

  return {
    programs,
    profile: state.firebase.profile,
  };
};

const AdminDashboard = ({
  fetchAllPrograms,
  closeAdminProgram,
  deleteAdminProgram,
  clearAdminProgram,
  duplicateAdminProgram,
  toggleHideProgram,
  toggleHideProgramForStudent,
  programs,
  profile,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  /// here we can add the ADDITIONALDOCUMENTFORMREQUIRED field//
  // const newProgram = programs.map((obj) => ({
  //   ...obj,
  //   additionalDocumentsFormRequired: obj.additionalDocumentsFormRequired
  //     ? true
  //     : false,
  // }));
  // console.log(newProgram, "new");

  const [programId, setProgramId] = useState(null);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchText, setSearchText] = useState('');

  const history = useHistory();

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      'e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
    );
  }, []);

  useEffect(() => {
    clearAdminProgram();
    fetchAllPrograms();
  }, [fetchAllPrograms, clearAdminProgram]);

  useEffect(() => {
    const grades = {
      '9thGrade': '9th Grade',
      '10thGrade': '10th Grade',
      '11thGrade': '11th Grade',
      '12thGrade': '12th Grade',
      collegeFreshman: 'College Freshman',
      collegeSophomore: 'College Sophomore',
      collegeJunior: 'College Junior',
      collegeSenior: 'College Senior',
      collegeGraduate: 'College Graduate',
    };

    const arr = programs.map((program, index) => ({
      ...program,
      serialNo: index + 1,
      applicationOpen:
        program.applicationOpen &&
        format(parseISO(program.applicationOpen), 'EEE MMM do y'),
      applicationDeadline:
        program.applicationDeadline &&
        format(parseISO(program.applicationDeadline), 'EEE MMM do y'),
      programStartDate:
        program.programStartDate &&
        format(parseISO(program.programStartDate), 'EEE MMM do y'),
      programEndDate:
        program.programEndDate &&
        format(parseISO(program.programEndDate), 'EEE MMM do y'),
      programRosterDate:
        program.programRosterDate &&
        format(parseISO(program.programRosterDate), 'EEE MMM do y'),
      notificationDate:
        program.notificationDate &&
        format(parseISO(program.notificationDate), 'EEE MMM do y'),
      depositDeadline:
        program.depositDeadline &&
        format(parseISO(program.depositDeadline), 'EEE MMM do y'),
      paymentDeadline:
        program.paymentDeadline &&
        format(parseISO(program.paymentDeadline), 'EEE MMM do y'),
      depositGrace:
        program.depositGrace &&
        format(parseISO(program.depositGrace), 'EEE MMM do y'),
      paymentGrace:
        program.paymentGrace &&
        format(parseISO(program.paymentGrace), 'EEE MMM do y'),
      packetDueDate:
        program.packetDueDate &&
        format(parseISO(program.packetDueDate), 'EEE MMM do y'),
      grades: Object.keys(grades)
        .map((grade) => (program[grade] ? grades[grade] : ''))
        .filter((grade) => grade),
    }));
    setRows(arr);
    if (searchText) {
      setSearchText(searchText);
      const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
    }
  }, [programs]);

  const handleDeleteProgram = () => {
    deleteAdminProgram(programId);
    setProgramId(null);
  };

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filtered = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };

  const memoizedColumns = useMemo(() => {
    return [
      // { field: "serialNo", headerName: "Id", width: 100 },

      {
        field: 'programName',
        headerName: 'Program Title',
        width: 280,
        overflow: 'auto',
        valueGetter: (param) => param.value || '-',
      },
      {
        field: 'grades',
        headerName: 'Grade Level',
        width: 180,
        valueGetter: (param) => param.value || '-',
      },
      {
        field: 'applicationFee',
        headerName: 'App Fee',
        width: 100,
        overflow: 'auto',
        valueGetter: (param) => parseFloat(param.value) || '-',
        renderCell: (param) => `$${param.value}`,
      },
      {
        field: 'programCost',
        headerName: 'Program Cost',
        width: 120,
        valueGetter: (param) => {
          if (param.row.freeProgram) return 'Free';
          return parseFloat(param.value) || '-';
        },
        renderCell: (param) => `$${param.value}`,
      },
      {
        field: 'recommendationsRequired',
        headerName: 'Recommendations Required',
        width: 100,
        overflow: 'auto',
        overflowWrap: 'break-word',
      },
      {
        field: 'applicationOpen',
        headerName: 'Application Open',
        width: 180,
        valueGetter: (param) => param.value || '-',
      },
      {
        field: 'applicationDeadline',
        headerName: 'Application Deadline',
        width: 180,
        valueGetter: (param) => param.value || '-',
      },
      {
        field: 'programStartDate',
        headerName: 'Program Start',
        width: 180,
        valueGetter: (param) => param.value || '-',
      },
      {
        field: 'programEndDate',
        headerName: 'Program End',
        width: 180,
        valueGetter: (param) => param.value || '-',
      },
      {
        field: 'programRosterDate',
        headerName: 'Program Roster',
        width: 180,
        valueGetter: (param) => param.value || '-',
      },
      {
        field: 'notificationDate',
        headerName: 'Notification Date',
        width: 180,
        valueGetter: (param) => param.value || '-',
      },
      {
        field: 'depositDeadline',
        headerName: 'Deposit Deadline',
        width: 180,
        valueGetter: (param) => param.value || '-',
      },
      {
        field: 'paymentDeadline',
        headerName: 'Payment Deadline',
        width: 180,
        valueGetter: (param) => param.value || '-',
      },
      {
        field: 'depositGrace',
        headerName: 'Deposit Grace',
        width: 180,
        valueGetter: (param) => param.value || '-',
      },
      {
        field: 'paymentGrace',
        headerName: 'Payment Grace',
        width: 180,
        valueGetter: (param) => param.value || '-',
      },
      {
        field: 'packetDueDate',
        headerName: 'Packet Due',
        width: 180,
        valueGetter: (param) => param.value || '-',
      },

      {
        field: '',
        headerName: 'Actions',
        width: 360,
        renderCell: (param) => (
          <Box display="flex" justifyContent="space-evenly" width="100%">
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/admin/program/edit?id=${param.id}`);
              }}
              disabled={!profile.isAdmin}
            >
              Edit
            </Button>
            <Button
              // style={{
              //   backgroundColor: theme.palette.error.main,
              //   color: 'white',
              // }}
              variant="contained"
              size="small"
              color="secondary"
              onClick={(e) => {
                e.stopPropagation();
                setProgramId(param.id);
              }}
              disabled={!profile.isAdmin}
            >
              Delete
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                duplicateAdminProgram(param.id);
              }}
              // style={{
              //   color: theme.palette.error.main,
              //   borderColor: theme.palette.error.main,
              // }}
              variant="outlined"
              size="small"
              color="secondary"
              disabled={!profile.isAdmin}
            >
              Duplicate
            </Button>
          </Box>
        ),
      },

      {
        field: 'hideProgram',
        headerName: 'Hide Program',
        width: 120,
        renderCell: (param) => (
          <Box display="flex" justifyContent="space-around" width="100%">
            <Switch
              onChange={(e, checked) => {
                e.stopPropagation();
                toggleHideProgram(param.id, checked);
              }}
              checked={param.value}
              onClick={(e) => e.stopPropagation()}
              color="primary"
              disabled={!profile.isAdmin}
            />
          </Box>
        ),
      },
      {
        field: 'hidefromStudent',
        headerName: 'Hide From Student',
        width: 120,
        renderCell: (param) => (
          <Box display="flex" justifyContent="space-around" width="100%">
            {console.log('param', param)}
            <Switch
              onChange={(e, checked) => {
                e.stopPropagation();
                toggleHideProgramForStudent(param.id, checked);
              }}
              checked={param.value}
              onClick={(e) => e.stopPropagation()}
              color="primary"
              //disabled={!profile.isAdmin}
            />
          </Box>
        ),
      },
    ];
  }, [
    duplicateAdminProgram,
    history,
    profile.isAdmin,
    theme.palette.error.main,
  ]);

  return (
    <>
      <Dialog
        open={programId ? true : false}
        onClose={() => setProgramId(null)}
      >
        <DialogTitle id="alert-dialog-title">{'Warning'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you wish to delete program?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setProgramId(null)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteProgram()}
            style={{ color: theme.palette.error.main }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item md={2}>
          <Sidebar />
        </Grid>

        <Grid
          item
          md={10}
          style={{
            //backgroundColor: '#FAFAFB',
            // backgroundColor: '#ececee',
            backgroundColor: '#F3F4F9',
            paddingLeft: '41px',
            paddingRight: '41px',
          }}
        >
          <Grid
            item
            container
            direction={'column'}
            style={{
              margin: '2em 0',
              padding: '24px',
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              boxShadow:
                'rgb(95 116 141 / 3%) 0px 2px 1px -1px, rgb(95 116 141 / 4%) 0px 1px 1px 0px, rgb(95 116 141 / 8%) 0px 1px 3px 0px',
            }}
          >
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography className={classes.title}>
                Programs: {programs.length}
              </Typography>
              {profile.isAdmin && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push('/admin/program/new')}
                >
                  Add Program
                </Button>
              )}
            </Grid>

            <Grid item style={{ marginTop: '1em' }}>
              <Box height={420} width="100%">
                <DataGridPremium
                  columns={memoizedColumns}
                  rows={filteredRows}
                  disableSelectionOnClick
                  disableColumnMenu
                  pageSize={5}
                  rowsPerPageOptions={[]}
                  onRowClick={(param) => {
                    history.push(
                      `/${
                        profile.isAdmin || profile.isAdminViewer
                          ? 'admin'
                          : 'reviewer'
                      }/program/dashboard?id=${param.id}`
                    );
                  }}
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      value: searchText,
                      clearSearch: () => requestSearch(''),
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default connect(mapStateToProps, actions)(AdminDashboard);
