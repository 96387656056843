import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import './ApplicantFAQ.css';
import { Container, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import acceptanceChecklist from '../../../assets/faqs/acceptance-checklist.png';
import acceptanceChecklistSubmitButton from '../../../assets/faqs/acceptance-checklist-submit.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#E7E7E7',
  },
  innerContainer: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  faqContainer: {
    padding: '1rem',
    marginBottom: '1rem',
    border: '1px solid #ccc',
    '& h3': {
      paddingBottom: '0.5rem',
      marginBottom: '0.5rem',
    },
    '& + &': {
      marginTop: '1rem',
    },
  },
  faqAnswer: {
    marginTop: '1rem',
    lineHeight: '1.5',
  },
}));

const ApplicantFAQ = () => {
  const classes = useStyles();

  const [faqs, setFaqs] = useState([
    {
      question: 'How to submit application?',
      answer: `<p>
      <strong>Step 1)</strong> Ensure all sections of the acceptance checklist are marked complete.
      <img src=${acceptanceChecklist}>
    </p>
    
    <p>
      <strong>Step 2)</strong> Click submit button at the bottom of checklist.
      <img src=${acceptanceChecklistSubmitButton}>
    </p>
    
    <p>
      <strong>Step 3)</strong> You’re all set! You are still able to update the travel form, student questionnaire and pre-arrival forms until the date indicated on the checklist.
    </p>
    `,
      images: [acceptanceChecklist, acceptanceChecklistSubmitButton],
    },
    {
      question: 'Why am I not seeing the latest changes on my portal?',
      answer: `<p>If you are not seeing the latest changes on your portal, it could be due to your browser caching older versions of the website. Clearing your browser cache can help resolve this issue. Please follow the instructions below based on your browser:</p>

      <ul>
        <li>
          <strong>Google Chrome:</strong>
          <ul>
            <li>Press Ctrl + Shift + Delete on Windows/Linux or Command + Shift + Delete on Mac to open the Clear browsing data menu.</li>
            <li>Select "Cached images and files" or "Cached images and files" option.</li>
            <li>Choose the desired time range or select "All time" to clear the entire cache.</li>
            <li>Click Clear data to remove the cached files.</li>
          </ul>
        </li>
        <li>
          <strong>Mozilla Firefox:</strong>
          <ul>
            <li>Press Ctrl + Shift + Delete on Windows/Linux or Command + Shift + Delete on Mac to open the Clear All History menu.</li>
            <li>Select "Cache" or "Cached Web Content" option.</li>
            <li>Choose the desired time range or select "Everything" to clear the entire cache.</li>
            <li>Click Clear Now to remove the cached files.</li>
          </ul>
        </li>
        <li>
          <strong>Safari:</strong>
          <ul>
            <li>Go to the Safari menu and click on Preferences.</li>
            <li>Select the Privacy tab.</li>
            <li>Click on Manage Website Data.</li>
            <li>Search for your portal's domain name or URL in the search bar.</li>
            <li>Select the website and click on Remove or Remove All to clear the cache.</li>
          </ul>
        </li>
        <li>
          <strong>Microsoft Edge:</strong>
          <ul>
            <li>Press Ctrl + Shift + Delete to open the Clear browsing data menu.</li>
            <li>Select "Cached images and files" option.</li>
            <li>Choose the desired time range or select "All time" to clear the entire cache.</li>
            <li>Click Clear to remove the cached files.</li>
          </ul>
        </li>
        <li>
          <strong>Internet Explorer:</strong>
          <ul>
            <li>Press Ctrl + Shift + Delete to open the Delete Browsing History menu.</li>
            <li>Select "Temporary Internet files and website files" option.</li>
            <li>Click Delete to clear the cache.</li>
          </ul>
        </li>
      </ul>
      
      <p>After clearing your browser cache, refresh the portal page, and you should see the latest changes reflected.</p>
      
      <p>If you continue to experience issues or have any other questions, please contact our support team for further assistance.</p>
      
      <p>Feel free to customize this message according to your specific portal and website details.<p>      
      `,
    },
  ]);

  const FAQ = ({ question, answer }) => {
    const [showAnswer, setShowAnswer] = useState(false);

    const toggleAnswer = () => {
      setShowAnswer(!showAnswer);
    };

    return (
      <div className={classes.faqContainer}>
        <h3 onClick={toggleAnswer}>
          {question}{' '}
          {showAnswer ? (
            <KeyboardArrowDownIcon style={{ float: 'right' }} />
          ) : (
            <KeyboardArrowRightIcon style={{ float: 'right' }} />
          )}
        </h3>
        {showAnswer && (
          <div>
            <div
              className={classes.faqAnswer}
              style={{ marginTop: '1rem' }}
              dangerouslySetInnerHTML={{ __html: answer }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <Container className={classes.innerContainer}>
      <Grid container justifyContent="space-between">
        <Grid xs={12} style={{ marginBottom: '5rem' }}>
          <Typography variant="h4">Frequently Asked Questions (FAQ)</Typography>
        </Grid>

        <Grid item style={{ width: '80%' }}>
          {faqs.map((faq, index) => (
            <FAQ key={index} question={faq.question} answer={faq.answer} />
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ApplicantFAQ;
