import React, { useState, useEffect, useMemo, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from '../../../config/axios';
import DialogContentText from '@mui/material/DialogContentText';
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from '../../../store/actions/asyncActions/asyncActions';
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from '@mui/x-data-grid-premium';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { escapeRegExp } from '../../../common/tableUtils';
import { archiveProgram } from '../../../store/actions/applicantActions/programActions/applicantProgramActions';
import {
  fetchDashboardValues,
  addFilterModel,
  clearFilterModels,
} from '../../../store/actions/adminActions/dashboardActions/dashboardActions';
import {
  handleDeletehandleProgramRollback,
  handleProgramRollback,
  updateProgramStage,
} from '../../../store/actions/reviewActions/reviewActions';

import TextArea from '../../../common/form/TextArea';
import { Field, reduxForm } from 'redux-form';
import { combineValidators, isRequired } from 'revalidate';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  },
  toolbarContainer: {
    justifyContent: 'space-between',
  },
  flex3: {
    flex: 3,
  },
}));


const validate = combineValidators({
  filterName: isRequired({ message: 'Required' }),
});

const mapStateToProps = (state) => {
  let filters = {};

  if (state.adminDashboard.filters) {
    filters = state.adminDashboard.filters;
  }

  return {
    filters,
    profile: state.firebase.profile,
  };
};

const DashboarsRequestInfoTable = ({
  applicants,
  fetchDashboardValues,
  handleProgramRollback,
  handleSubmit,
  submitting,
  addFilterModel,
  filters,
  updateProgramStage,
  checkboxSelection,
  onSelectionModelChange,
  reloadApplicants,
  archiveProgram,
}) => {
  const [applicantsNew, setApplicantsNew] = useState([]);
  const fixFilteredRowsForFreeProgram = () => {
    let a = applicants?.map((item) => {
      if (item.applicationFeePaid && !item.applicationCompleted) {
        item['programStage'] = 'in-progress';
        setApplicantsNew((el) => [...el, item]);
      } else {
        setApplicantsNew((el) => [...el, item]);
      }
    });
  };

  useEffect(() => {
    if (applicants !== undefined) {
      fixFilteredRowsForFreeProgram();
    }
  }, [applicants]);

  const matches = useMediaQuery('(min-width:530px)');
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState(applicantsNew);
  const [filteredRows, setFilteredRows] = useState(applicantsNew);
  const [filterModel, setFilterModel] = useState();
  const [sortModel, setSortModel] = useState();
  const [dialog, setDialog] = useState(false);
  const [archiveComment, setArchiveComment] = useState('');
  const [selectedRegisteredProgramId, setSelectedRegisteredProgramId] =
    useState('');
  const [open, setOpen] = useState(false);
  const [, setRender] = useState(false);
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [openWithrawnDialog, setOpenWithrawnDialog] = useState(false);
  const [selectedApplicantId, setSelectedApplicantId] = useState('');
  const [declineComment, setDeclineComment] = useState('');
  const grid2 = useRef(null);

  const history = useHistory();


  useEffect(() => {
    LicenseInfo.setLicenseKey(
      'e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
    );
  }, []);
  useEffect(() => {
    const arr = applicants.map((requestedInformation, index) => {
      return {
        ...requestedInformation,
        id: index + 1,
        serialNo: index + 1,
      gradYear: new Date(requestedInformation.gradYear).toLocaleDateString('en-us', { year:"numeric", month:"short"})
      };
    });

    setRows(arr);

    if (searchText) {
      setSearchText(searchText);
      const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
    }
  }, [applicants, searchText, page, pageSize]);
  useEffect(() => {
    const search = history.location.search;
    if (search.includes('?institute')) {
      setRender((prevRender) => !prevRender);
    }
  }, [history, filteredRows]);

  useEffect(() => {
    if (filter) {
      setFilterModel(filters[filter]);
    } else {
      setFilterModel(null);
    }
  }, [filter, filters]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filtered = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };

  const handleUpdateProgramStage = async (
    id,
    programStage,
    applicationValue,
    declineComment
  ) => {
    await updateProgramStage(
      id,
      programStage,
      applicationValue,
      declineComment
    );
    reloadApplicants();
  };

  const memoizedColumns = useMemo(() => {
    return [
      {
        field: 'serialNo',
        headerName: 'Id',
        width: 100,
      },

      {
        field: 'firstName',
        headerName: 'firstName',
        width: 160,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'lastName',
        headerName: 'lastName',
        width: 160,
        // renderCell: (cell) => `$${cell.value}`,
        valueGetter: (param) => param.value || 'N/A',
      },

      {
        field: 'email',
        headerName: 'Email',
        width: 260,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'state',

        headerName: 'State',
        width: 160,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'gradYear',
        headerName: 'Expected Graduation',
        width: 160,

        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'programInterested',
        headerName: 'Program Interested',
        width: 160,

        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'mobileNumber',
        headerName: 'Phone',
        width: 160,

        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        width: 180,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'highSchool',
        headerName: 'High School',
        width: 180,
        valueGetter: (param) => param.value || 'N/A',
      },
    ];
  }, [history, fetchDashboardValues, handleProgramRollback]);

  const handleAddFilterModel = async (values) => {
    await addFilterModel(values.filterName, filterModel);
    setDialog(false);
    setFilterModel(null);
  };

  return (
    <Box
      height={688}
      width="100%"
      display="flex"
      style={{
        width: `${matches ? '100%' : '100%'}`,
        overflow: `${matches ? ' ' : 'auto'}`,
      }}
    >
      <Dialog open={openWithrawnDialog}>
        <DialogContent>
          <DialogContentText>Please add a reason to withdraw</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setDeclineComment(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenWithrawnDialog(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleUpdateProgramStage(
                selectedApplicantId,
                'withdrawn',
                false,
                declineComment
              );
              setOpenWithrawnDialog(false);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
        <DialogTitle>Please add a reason to archive</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setArchiveComment(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              archiveProgram(selectedRegisteredProgramId, archiveComment).then(
                () => reloadApplicants()
              );
              setOpen(false);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialog} onClose={() => setDialog(false)} maxWidth="md">
        <form onSubmit={handleSubmit(handleAddFilterModel)}>
          <DialogTitle>Add Filter Model</DialogTitle>
          <DialogContent>
            <Field
              name={'filterName'}
              label={'Filter Name'}
              component={TextArea}
              type={'text'}
              variant={'outlined'}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDialog(false)}
              color="secondary"
              disabled={submitting}
            >
              Close
            </Button>
            <Button color={'primary'} type={'submit'} disabled={submitting}>
              Add Filter
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <DataGridPremium
        ref={grid2}
        // columnGroupingModel={columnGroupingModel}
        // experimentalFeatures={{ columnGrouping: true }}
        columns={memoizedColumns}
        rows={filteredRows}
        disableSelectionOnClick
        checkboxSelection={checkboxSelection}
        page={page}
        onPageChange={(params) => setPage(params.page)}
        onPageSizeChange={(params) => setPageSize(params.pageSize)}
        rowsPerPageOptions={[10, 25, 50]}
        onSelectionModelChange={(selectedIds) => {
          onSelectionModelChange(selectedIds);
        }}
        onRowClick={(param) => {
          history.push(`/admin/reviewProgram?id=${param.row.applicantUid}`);
        }}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            value: searchText,
            clearSearch: () => requestSearch(''),
          },
        }}
        sortModel={sortModel}
        onSortModelChange={(params) => setSortModel(params.sortModel)}
        initialState={{ pinnedColumns: { left: ['fullName'] } }}
      />
    </Box>
  );
};

export default connect(mapStateToProps, {
  fetchDashboardValues,
  handleProgramRollback,
  addFilterModel,
  handleDeletehandleProgramRollback,
  clearFilterModels,
  updateProgramStage,
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
  archiveProgram,
})(reduxForm({ form: 'adminNote', validate })(DashboarsRequestInfoTable));
