import React, { Fragment, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { Switch, Tooltip } from '@mui/material';
import './checkboxInput.css';

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
    fontWeight: 300,
    position: 'absolute',
    margin: '31px 0 0',
  },
}));

const CheckboxInputs = ({
  input,
  label,
  value,
  checkboxClass,
  checkboxLabelClass,
  registerUser,
  fieldValues,
  meta: { touched, error },
  size,
  color,
  disabled,
  program,
  check,
  setCheck,
  fieldName,
  onSwitchChange,
  onCheckboxChange,
}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  console.log(value, 'value');
  console.log(fieldValues?.freeProgram, 'LK');
  console.log(fieldName, 'fieldName');

  const handleChange = () => {
    setChecked(!checked);
    onSwitchChange(!checked);
  };
  useEffect(() => {
    if (program?.additionalDocumentsFormRequired) {
      setChecked(true);
    }
  }, []);
  // const handleChange = () => {
  //   const newValue = !input.value; // Toggle the value
  //   input.onChange(newValue); // Update the form field value
  //   onCheckboxChange(newValue);
  //   // setChecked(!checked);
  //   //  // Pass the value to the parent component
  //   // onCheckboxChange(!checked)
  // };

  return (
    <Fragment>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={input.value ? true : false}
              // checked ={checked}
              onChange={fieldName !== 'confirmationForm' && input.onChange}
              // onChange={handleChange}
              color={color ? color : 'primary'}
              size={size ? size : 'small'}
              className={checkboxClass}
              disabled={fieldValues?.freeProgram && fieldName == 'paymentForm'}
            />
          }
          className={checkboxLabelClass}
          label={label}
        />
      </div>

      {touched && error && (
        <Typography variant={'subtitle1'} className={classes.error}>
          {error}
        </Typography>
      )}
      <div>
        {input.name == 'additionalDocumentsForm' && input.value == true ? (
          <Tooltip title="Make Additional Documents Required" arrow>
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  name="mySwitch"
                  color="primary" // You can change the color to 'secondary', 'default', etc.
                />
              }
              label="Make Additional Documentation Required"
              // This is the label you want to add
            />
          </Tooltip>
        ) : (
          ''
        )}
      </div>
    </Fragment>
  );
};

export default CheckboxInputs;
