import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { connect } from 'react-redux';

import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import { submitRecommenderForm } from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import RecommendersInvitationForm from './RecommendersInvitationForm';
import { format, fromUnixTime } from 'date-fns';
import { fetchRegisteredProgram } from '../../../../../store/actions/applicantActions/programActions/applicantProgramActions';
import { Field } from 'redux-form';
import SelectInput from '../../../../../common/form/SelectInput';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: '0.4em',
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
    },
  },

  continueButton: {
    marginLeft: '0.4em',
    backgroundColor: '#1976d2',
    color: 'white !Important',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
      //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    },
  },
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  formName: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '72px',
    letter: '0.2px',
  },
  formText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  formTextBold: {
    color: '#891010',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize: '18px',
    letter: '0.2px',
    marginTop: '10px',
  },
  formQuestionText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  text: {
    fontWeight: 600,
    paddingRight: '1em',
  },
}));

const actions = {
  fetchRegisteredProgram,
  submitRecommenderForm,
};

const RecommendersForm = ({
  registeredProgramId,
  registeredProgram,
  profile,
  submitRecommenderForm,
  handleCloseForm,
  handleSubmit,
  handleSetForm,
  submitting,
  program,
  fetchRegisteredProgram,
}) => {
  console.log(registeredProgram, 'hgtyuj');
  const classes = useStyles();
  // const theme = useTheme();
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [exit, setExit] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  console.log(program, 'programRecommenders');
  useEffect(() => {
    if (registeredProgram?.personalInformationFormCompleted !== true) {
      handleCloseForm();
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSelectedValue('personal'); // Replace 'option2' with your desired default value
  }, []);

  useEffect(() => {
    if (
      program.recommendationsRequired !== null &&
      program.recommendationsRequired !== undefined &&
      program.recommendationsRequired === 0
    ) {
      submitRecommenderForm(registeredProgramId, true);
      handleCloseForm();
      handleSetForm('extracurricularForm');
    }
  }, [program]);
  console.log(program, 'program');

  useEffect(() => {
    if (exit) handleCloseForm();
  }, [exit, handleCloseForm]);

  const programsAllThings = [
    'applicationInterests',
    'personalInformationForm',
    'familyInformationForm',
    'academicInformationForm',
    // 'recommendationsRequired',
    'recommendersForm',
    'extracurricularForm',
    'covidProtocolForm',
    'resumeForm',
    'transcriptForm',
    'shortResponsesForm',
    'scholarshipForm',
    'taxForm',
    'additionalDocumentsForm',
    'confirmationForm',
  ];

  const programAllThingsValue = {
    applicationInterests: 'applicationInterests',
    personalInformationForm: 'personalInformationForm',
    familyInformationForm: 'familyInformationForm',
    academicInformationForm: 'academicInformationForm',
    // recommendationsRequired: 'recommendationsRequired',
    recommendersForm: 'recommendersForm',
    extracurricularForm: 'extracurricularForm',
    covidProtocolForm: 'covidProtocolForm',
    resumeForm: 'resumeForm',
    transcriptForm: 'transcriptForm',
    shortResponsesForm: 'shortResponsesForm',
    scholarshipForm: 'scholarshipForm',
    taxForm: 'taxForm',
    additionalDocumentsForm: 'additionalDocumentsForm',
    confirmationForm: 'confirmationForm',
  };

  let dataToCheck = programsAllThings
    .filter((item) => program[item])
    .map((item) => programAllThingsValue[item]);

  const handleFormSubmit = async () => {
    const program = await fetchRegisteredProgram(registeredProgramId);
   // console.log(program[0], 'ssssssss');
    if (program[0].recommendersFormCompleted) {
      try {
        await submitRecommenderForm(registeredProgramId, true);
        handleCloseForm();
        if (!exit) {
          const indexOfPersonalInfoForm =
            dataToCheck.indexOf('recommendersForm');
          if (
            indexOfPersonalInfoForm !== -1 &&
            indexOfPersonalInfoForm < dataToCheck.length - 1
          ) {
            const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
            handleSetForm(nextForm);
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await submitRecommenderForm(registeredProgramId, false);
        handleCloseForm();
        console.log(exit);
        if (!exit) {
          const indexOfPersonalInfoForm =
            dataToCheck.indexOf('recommendersForm');
          if (
            indexOfPersonalInfoForm !== -1 &&
            indexOfPersonalInfoForm < dataToCheck.length - 1
          ) {
            const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
            handleSetForm(nextForm);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  // const handleFormSave = async (values) => {
  //   console.log(values, 'valll');
  //   await updateStudentQuestionnaireForm(registeredProgramId, values);
  //   handleCloseForm();
  // };
  const saveInterval = 5 * 60 * 1000; // 5 minutes in milliseconds

  // useEffect(() => {
  //   const saveFormInterval = setInterval(() => {
  //     handleFormSave(currentValues);
  //   }, saveInterval);

  //   // Clear the interval when the component is unmounted
  //   return () => clearInterval(saveFormInterval);
  // }, [currentValues]);
  return (
    <Fragment>
      <Fragment>
        <Typography className={classes.formName}>Recommendations</Typography>

        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid item container direction={'column'}>
              <Grid item>
                {/* <Typography className={classes.formText}>
                  LEAD requires each applicant to submit at least{' '}
                  {program.recommendationsRequired} recommendation(s). You may
                  select from three recommendation options: an Academic
                  Recommendation Form (completed by an academic teacher); a
                  Personal Reference Form (from a non-academic person, coach or
                  employer, but excludes family members); or an Education Form
                  (from school counselor or advisor).
                </Typography>*/}
                <Typography className={classes.formText}>
                  LEAD requires each applicant to submit at least{' '}
                  {program.recommendationsRequired} recommendation(s). You may
                  select from: school counselor or advisor, an academic teacher,
                  or a non-academic person, coach or employer, but excludes
                  family members.
                </Typography>
              </Grid>

              <Grid item style={{ marginTop: '1.5em' }}>
                <Typography className={classes.formText}>
                  Select the type of recommendation request you would like to
                  send, enter the contact information of your recommender below
                  and then click “Send Invitation.”
                  <br></br>
                  <br></br>
                  NOTE: If you need to edit or remove an individual, simply
                  change their information and click “Send Invitation” again,
                  the new recommender will be notified. Please note, once the
                  recommendation is received, you will not be able to remove the
                  recommender from your application.
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.formTextBold}>
                  It is the responsibility of the student to follow up for
                  completion. All recommendations must be received before{' '}
                  {/* {format(
                    fromUnixTime(program.applicationDeadlineDate),
                    "MM/dd/yyyy"
                  )} */}
                </Typography>
                <br />

                {/*  <Typography className={classes.formTextBold}>
                  Recommedation requests expire after 30 days, it is the
                  responsibility of the student to follow up for completion.
                </Typography>*/}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
      {/*COUNSELOR RECOMMENDER*/}
      <Typography className={classes.formName}>
        Recommendation Form Request
      </Typography>
      {/* <Typography>
        {registeredProgram.personalRecommenderFormSent ||
        registeredProgram.academicRecommenderFormSent ||
        registeredProgram.counselorRecommenderFormSent ? (
          <div>Invite has been sent</div>
        ) : null}
      </Typography> */}

      <Grid item className={classes.fieldWrapper}>
        <FormControl style={{ width: '300px' }}>
          <InputLabel id="select-label">Select recommendation type</InputLabel>
          <Select
            label={`recommender type`}
            id="select"
            value={selectedValue}
            // disabled={
            //   registeredProgram.counselorRecommenderFormSent === true ||
            //   registeredProgram.personalRecommenderFormSent === true ||
            //   registeredProgram.academicRecommenderFormSent === true
            // }
            onChange={handleChange}
          >
            <MenuItem value="academic">Academic</MenuItem>
            <MenuItem value="counselor">Counselor</MenuItem>
            <MenuItem value="personal">Personal</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {/*counselor RECOMMENDER*/}
      {selectedValue == 'counselor' && (
        <>
          <Typography className={classes.formName}>
            {' '}
            counselor Recommender{' '}
          </Typography>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid item container direction={'column'} wrap="nowrap">
                <RecommendersInvitationForm
                  registeredProgramId={registeredProgramId}
                  profile={profile}
                  recommenderType={'counselor'}
                  currentFormId={
                    registeredProgram.counselorRecommenderFormDocNo
                  }
                  invitationSent={
                    registeredProgram.counselorRecommenderFormSent
                  }
                  responseReceived={
                    registeredProgram.counselorRecommenderFormCompleted
                  }
                  personalRecommenderMail={
                    registeredProgram.personalRecommenderFormSent &&
                    registeredProgram.personalRecommenderDetails
                      .recommenderEmailAddress
                  }
                  academicRecommenderMail={
                    registeredProgram.academicRecommenderFormSent &&
                    registeredProgram.academicRecommenderDetails
                      .recommenderEmailAddress
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {/*PERSONAL RECOMMENDER*/}
      {selectedValue == 'personal' && (
        <>
          <Typography className={classes.formName}>
            Personal Recommender
          </Typography>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid item container direction={'column'} wrap="nowrap">
                <RecommendersInvitationForm
                  registeredProgramId={registeredProgramId}
                  profile={profile}
                  recommenderType={'personal'}
                  currentFormId={registeredProgram.personalRecommenderFormDocNo}
                  invitationSent={registeredProgram.personalRecommenderFormSent}
                  responseReceived={
                    registeredProgram.personalRecommenderFormCompleted
                  }
                  counselorRecommenderMail={
                    registeredProgram.counselorRecommenderFormSent &&
                    registeredProgram.counselorRecommenderDetails
                      .recommenderEmailAddress
                  }
                  academicRecommenderMail={
                    registeredProgram.academicRecommenderFormSent &&
                    registeredProgram.academicRecommenderDetails
                      .recommenderEmailAddress
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {/*ACADEMIC RECOMMENDER*/}
      {selectedValue == 'academic' && (
        <>
          <Typography className={classes.formName}>
            Academic Recommender
          </Typography>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid item container direction={'column'} wrap="nowrap">
                <RecommendersInvitationForm
                  registeredProgramId={registeredProgramId}
                  profile={profile}
                  recommenderType={'academic'}
                  currentFormId={registeredProgram.academicRecommenderFormDocNo}
                  invitationSent={registeredProgram.academicRecommenderFormSent}
                  responseReceived={
                    registeredProgram.academicRecommenderFormCompleted
                  }
                  counselorRecommenderMail={
                    registeredProgram.counselorRecommenderFormSent &&
                    registeredProgram.counselorRecommenderDetails
                      .recommenderEmailAddress
                  }
                  personalRecommenderMail={
                    registeredProgram.personalRecommenderFormSent &&
                    registeredProgram.personalRecommenderDetails
                      .recommenderEmailAddress
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {/*BUTTONS*/}
      <Grid item style={{ marginTop: '1.5em' }}>
        <Grid item container>
          <Grid item style={{ marginLeft: 'auto' }}>
            <Button
              variant={'outlined'}
              color={'primary'}
              className={classes.button}
              onClick={() => {
                setExit(true);
              }}
              // onClick={() => handleFormSave(currentValues)}
              disabled={submitting}
            >
              Save for Later
            </Button>

            <Button
              variant={'outlined'}
              color={'primary'}
              className={classes.continueButton}
              type={'submit'}
              onClick={() => handleFormSubmit()}
              disabled={submitting}
            >
              Continue{' '}
              <span>
                <strong> &#8594;</strong>
              </span>
            </Button>

            <Button
              variant={'outlined'}
              color={'secondary'}
              className={classes.button}
              onClick={() => handleCloseForm()}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default connect(null, actions)(RecommendersForm);
