import React, { useState, useEffect, useMemo, useRef } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from "@mui/x-data-grid-premium";

import { CustomToolbar, escapeRegExp } from "../../../common/tableUtils";
import { format, fromUnixTime } from "date-fns";
import { getDate } from "../../../common/util";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  // ADD STYLES HERE
  flex3: {
    flex: 3,
  },
}));

const WithdrawnTable = ({ withdrawnApplications, profile }) => {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filterModel, setFilterModel] = useState();
  const [sortModel, setSortModel] = useState();
  const grid1 = useRef(null);
  const grid2 = useRef(null);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      "e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    );
  }, []);
  console.log(withdrawnApplications,"withdrawnApplications")

  useEffect(() => {
    const arr = withdrawnApplications.map((application, index) => {
      return {
        ...application,
        serialNo: index + 1,
        editedAt: getDate(fromUnixTime(application.editiedAt)),
        comments: application.declineComment
          ? application.declineComment
          : new Date(application?.paymentDeadlineDate) < new Date()
          ? "Deadline has passed"
          : "Admin withdrew the application",
        submissionDate:
          application.confirmationFormDetails?.confirmationDate &&
          format(
            new Date(application.confirmationFormDetails?.confirmationDate),
            "MM/dd/yyyy"
          ),
        reviewerAssignedAt:
          application.reviewerAssignedAt &&
          format(fromUnixTime(application.reviewerAssignedAt), "MM/dd/yyyy"),
        reviewerSubmittedAt:
          application.reviewerResponse?.reviewedOn &&
          format(
            new Date(application.reviewerResponse?.reviewedOn),
            "MM/dd/yyyy"
          ),
      };
    });
    setRows(arr);
    if (searchText) {
      const searchRegex = new RegExp(escapeRegExp(searchText), "i");
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
    }
  }, [withdrawnApplications]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filtered = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };

  const memoizedColumns = useMemo(() => {
    return [
     
      {
        field: "fullName",
        headerName: "Name",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
      },
     /*
      {
        field: "programName",
        headerName: "Program",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
      },*/
      {
        field: "programStage",
        headerName: "Stage",
        width: 110,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "submissionDate",
        headerName: "Submission Date",
        width: 160,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "reviewerAssignedAt",
        headerName: "Reviewer Assigned Date ",
        width: 220,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "reviewerSubmittedAt",
        headerName: "Reviewer Submission Date ",
        width: 220,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "editedAt",
        headerName: "Updated At",
        width: 110,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "affiliateName",
        headerName: "Affiliate Name",
        width: 160,
        hide: !profile.isAdmin,
        valueGetter: (param) => param.value || "None",
      },
      {
        field: "affiliateCode",
        headerName: "Affiliate Code",
        width: 140,
        hide: !profile.isAdmin,
        valueGetter: (param) => param.value || "None",
      },
      {
        field: "comments",
        headerName: "Comments",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
        renderCell: (params) => (
          <Tooltip title={<Typography>{params.value}</Typography>} arrow>
            <Typography>{params.value}</Typography>
          </Tooltip>
        ),
      },
    ];
  }, [profile.isAdmin]);

  return (
    <Box height={320} width="100%" display="flex">
      <DataGridPremium
        ref={grid2}
        className={classes.flex3}
        columns={memoizedColumns}
        rows={filteredRows}
        disableSelectionOnClick
        disableColumnMenu
        rowsPerPageOptions={[]}
        onRowClick={(param) => {
          history.push(`/admin/reviewProgram?id=${param.row.applicantUid}`);
        }}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(""),
          },
        }}
        filterModel={filterModel}
        onFilterModelChange={(params) => setFilterModel(params.filterModel)}
        sortModel={sortModel}
        onSortModelChange={(params) => setSortModel(params.sortModel)}
        initialState={{ pinnedColumns: { left: ["fullName"] } }}
      />
    </Box>
  );
};

export default WithdrawnTable;
