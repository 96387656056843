import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  Paper,
  Box,
  Tooltip,
  Select,
  MenuItem,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Sidebar from '../../../ui/sidebar/Sidebar';
import { connect, useDispatch } from 'react-redux';
import DashboardApplicantsTable from '../../../ui/admin/dashboard/DashboardApplicantsTable';
import { fetchProgramDashboardValues } from '../../../store/actions/adminActions/programActions/adminProgramActions';
import { useHistory } from 'react-router-dom';
import { reduxForm, reset } from 'redux-form';
import { combineValidators } from 'revalidate';
import { isRequired } from 'revalidate';
import { sendGenericEmail } from '../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import SendEmailDialog from '../../../dialogs/admin/SendEmailDialog';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  disableTemplate,
  enableTemplate,
  fetchAllTemplates,
  deleteTemplate,
} from '../../../store/actions/adminActions/settingsActions/settingsActions';

const mapStateToProps = (state) => {
  let program = {};
  let applicants = [];
  let templates = [];

  console.log(state, 'state');
  if (
    state.adminPrograms.adminProgram &&
    state.adminPrograms.adminProgram.length > 0
  ) {
    program = state.adminPrograms.adminProgram[0];
  }
  if (
    state.adminSettings.allTemplates &&
    state.adminSettings.allTemplates.length > 0
  ) {
    templates = state.adminSettings.allTemplates;
  }

  if (state.adminPrograms.programDashboardValues) {
    if (state.adminPrograms.programDashboardValues.tableData) {
      if (
        state.adminPrograms.programDashboardValues.tableData.allApplicants
          .length > 0
      ) {
        applicants =
          state.adminPrograms.programDashboardValues.tableData.allApplicants;
      }
    }
  }

  return {
    program,
    applicants,
    templates,

    profile: state.firebase.profile,
  };
};
const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5em',
    //backgroundColor: 'rgb(250, 250, 251)',
    backgroundColor: '#F3F4F9',
  },
  tableContainer: {
    padding: '1rem',
    gap: '1rem',
    borderRadius: '8px',
  },
  applicationsLength: {
    marginLeft: '1rem',
  },
}));

const validate = combineValidators({
  subject: isRequired({ message: 'Required' }),
  content: isRequired({ message: 'Required' }),
  to: isRequired({ message: 'Required' }),
});

const AdminViewProgramDashboardApplicants = ({
  program,
  fetchAllTemplates,
  applicants,
  submitting,
  handleSubmit,
  sendGenericEmail,
  change,
  profile,
  templates,
  reset,
  fetchProgramDashboardValues,
}) => {
  const matches = useMediaQuery('(min-width:480px)');
  const history = useHistory();
  const classes = useStyles();
  console.log(profile, 'profile');
  const [filteredApplicants, setFilteredApplicants] = useState(applicants);
  const [selectionModel, setSelectionModel] = useState([]);
  const [editorValue, setEditorValue] = useState('');
  const [toValue, setToValue] = useState('');
  const [fullName, setFullName] = useState('');
  const [checkboxSelection, setCheckboxSelection] = useState(true);
  console.log(toValue, 'toValue');
  const [dialog, setDialog] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedApplicantRowIds, setSelectedApplicantRowIds] = useState([]);
  const [reload, setReload] = useState(false);
  const dispatch = useDispatch();

  console.log(fetchAllTemplates, 'fetchAllTemplates');
  console.log(selectedApplicantRowIds, 'selectedApplicantRowIds');
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let queryParams = useQuery();
  const id = queryParams.get('id');
  useEffect(() => {
    fetchAllTemplates();
  }, [fetchAllTemplates]);
  useEffect(() => {
    const emails = selectionModel
      .map((id) => {
        if (filteredApplicants[id - 1]) return filteredApplicants[id - 1].email;
        return '';
      })
      .filter((email) => email && email);
    let updatedTo = '';
    emails.forEach((email) => (updatedTo += `${email},`));
    change('to', updatedTo);
  }, [selectionModel, filteredApplicants]);
  console.log(templates, 'templates');
  const handleSendEmail = async (values) => {
    console.log(values, 'lkjhgf');
    const emails = [];
    if (values.to)
      values.to
        .trim()
        .split(',')
        .forEach((email) => email && emails.push(email.trim()));
    if (emails.length > 0) {
      await sendGenericEmail(
        values.subject,
        editorValue,
        emails,
        profile.fullName
      );
      setEditorValue('');
      setSelectedTemplate('');
      reset('sendEmail', {});
      dispatch(change('sendEmail', 'subject', ''));
    }

    setDialog(false);
  };
  console.log(selectedTemplate, 'selectedTemplate');

  useEffect(() => {
    const search = history.location.search;
    if (search.includes('?institute')) {
      const institute = search.split('=')[1];
      setFilteredApplicants(
        applicants.filter((applicant) => {
          if (applicant.institutionDetails?.institutionName === institute) {
            return true;
          }
          return false;
        })
      );
    } else {
      setFilteredApplicants(applicants);
    }
  }, [history.location.search, applicants, reload]);

  const handleReloadApplicants = async () => {
    await fetchProgramDashboardValues(id);
    setReload((prev) => (prev = !prev));
  };
  useEffect(() => {
    handleReloadApplicants();
  }, []);
  // useEffect(() => {
  //   if (!dialog) {
  //     setSelectionModel([]);
  //   }
  // }, [dialog]);
  const handleSelectionModelChange = (selectedIds) => {
    setSelectedApplicantRowIds(selectedIds);
    setCheckboxSelection(true);
  };
  const handleSelectionModelChange2 = (selectedIds) => {
    setSelectedApplicantRowIds([]);
  };

  return (
    <Grid container>
      <Grid item md={2} style={{ width: `${matches ? '' : '100%'}` }}>
        <Sidebar />
      </Grid>
      <Grid className={classes.container} item md={10}>
        <Paper elevation={0} style={{ borderRadius: '8px' }}>
          <Grid className={classes.tableContainer} container direction="column">
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" alignItems="center">
                 <Typography variant="h6">{program.programName}</Typography> 
                 </Box>
                 <Box display="flex" alignItems="center">
                <Typography className={classes.applicationsLength} variant="h4">
                Master List
                </Typography>
                <Typography className={classes.applicationsLength} variant="h6">
                  {filteredApplicants.length}
                </Typography>
              </Box>
              <Box display="flex" gridGap={16}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    let selectedApplicantNames = [];
                    let selectedApplicantFullName = [];
                    if (selectedApplicantRowIds.length)
                      selectedApplicantRowIds.map((selectedRowId) => {
                        const selectedApplicant = filteredApplicants?.find(
                          (x, i) => i === selectedRowId - 1
                        );
                        console.log(selectedApplicant, 'selectedApplicant');
                        if (
                          selectedApplicant &&
                          Object.keys(selectedApplicant).length
                        ) {
                          selectedApplicantNames.push(selectedApplicant?.email);
                          selectedApplicantFullName.push(
                            selectedApplicant?.fullName
                          );
                        }
                      });
                    const selectedNames = selectedApplicantNames.join(', ');
                    const selectedFull = selectedApplicantFullName.join(',');
                    console.log(selectedNames, 'op');
                    setToValue(selectedNames);
                    setFullName(selectedFull);
                    setDialog(true);
                  }}
                >
                  {/*Emailss*/}
                  Send Emails
                </Button>
                {/* <Select
                  value={selectedTemplate}
                  onChange={(event) => {
                    setSelectedTemplate(event.target.value);
                  }}
                >
                  {templates.map((i, j) => (
                    <MenuItem key={i.id} value={i?.templateName}>
                    
                      {i.templateName}
                    </MenuItem>
                  ))}
                  Send Emails
                </Select> */}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => history.push('/admin/dashboard/emailLogs')}
                >
                  Email History
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    history.push(`/admin/program/dashboard?id=${program.id}`)
                  }
                >
                  Program Dashboard
                </Button>
                <Tooltip title="Refresh">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleReloadApplicants()}
                  >
                    <RefreshIcon
                      style={{ float: 'right', cursor: 'pointer' }}
                    />
                  </Button>
                </Tooltip>
              </Box>
            </Grid>
            <DashboardApplicantsTable
              program={program}
              applicants={filteredApplicants}
              checkboxSelection
              selectionModel={selectionModel}
              setSelectionModel={setSelectionModel}
              onSelectionModelChange={handleSelectionModelChange}
              reloadApplicants={handleReloadApplicants}
            />
            <SendEmailDialog
              isOpen={dialog}
              templates={templates}
              closeDialog={setDialog}
              setDialog={setDialog}
              // closeDialog={() => setDialog(false)}
              handleSubmit={handleSubmit}
              submitting={submitting}
              handleSendEmail={handleSendEmail}
              editorValue={editorValue}
              checkboxSelection={checkboxSelection}
              setCheckboxSelection={setCheckboxSelection}
              setEditorValue={setEditorValue}
              handleSelectionModelChange2={handleSelectionModelChange2}
              // onSelectionModelChange={handleSelectionModelChange}
              selectedApplicantRowIds={selectedApplicantRowIds}
              setSelectedApplicantRowIds={setSelectedApplicantRowIds}
              toValue={toValue}
              fullName={fullName}
              setToValue={setToValue}
              setFullName={setFullName}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, {
  sendGenericEmail,
  fetchAllTemplates,
  fetchProgramDashboardValues,
})(
  reduxForm({ form: 'sendEmail', validate })(
    AdminViewProgramDashboardApplicants
  )
);
