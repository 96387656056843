import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from '../../asyncActions/asyncActions';
import { getUnixTime } from 'date-fns';
import { fetchRegisteredProgram } from './applicantProgramActions';
import axios from '../../../../config/axios';

export const applicantAcceptApplication = (id, registeredProgram) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);
    const registeredProgramData = await registeredProgramQuery.get();

    const programQuery = firestore
      .collection('programs')
      .doc(`${registeredProgram.programId}`);
    const institutionsQuery = firestore
      .collection('institutions')
      .doc(`${registeredProgram.chosenInstitution}`);

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        applicationAccepted: true,
        applicationAcceptedAt: editiedAt,
        applicationDecline: false,
        // programStage: 'approved'
        programStage: 'admitted',
        programStatus: 'in-process',
        logs: registeredProgramData.data().logs
          ? [
              ...registeredProgramData.data().logs,
              {
                event: 'Application Acceptance',
                date: editiedAt,
              },
            ]
          : [
              {
                event: 'Application Acceptance',
                date: editiedAt,
              },
            ],
      });

      let query = await programQuery.get();
      let institutionQuery = await institutionsQuery.get();

      let program = [];
      let institution = [];

      if (query.exists) {
        let item = {
          id: query.id,
          ...query.data(),
        };
        program.push(item);
      }

      if (institutionQuery.exists) {
        let item = {
          id: query.id,
          ...query.data(),
        };
        institution.push(item);
      }

      const id = registeredProgram.id;
      const programName = program[0].programName;
      const applicantFullName =
        registeredProgram.personalInformationFormDetails?.firstName +
        ' ' +
        registeredProgram.personalInformationFormDetails?.lastName;
      const applicantEmail =
        registeredProgram.personalInformationFormDetails?.email;
      const parentGuardianName =
        registeredProgram.familyInformationFormDetails
          .parentGuardianNameNumber1;
      const parentGuardianEmail =
        registeredProgram.familyInformationFormDetails
          .parentGuardianEmailNumber1;
      const institutionName = institution[0].institutionName;

      //   const sendGrantAcceptanceForm = await axios.get(
      //     "/grantAcceptanceFormFunctions/sendGrantAcceptanceForm",
      //     {
      //       params: {
      //         id: id,
      //         programName: programName,
      //         applicantFullName: applicantFullName,
      //         applicantEmail: applicantEmail,
      //         institutionName: institutionName,
      //         parentGuardianEmail: parentGuardianEmail,
      //       },
      //     }
      //   );

      //   const sendScholarConsentForm = await axios.get(
      //     "/scholarConsentFormFunctions/sendScholarConsentForm",
      //     {
      //       params: {
      //         id: id,
      //         applicantFullName: applicantFullName,
      //         applicantEmail: applicantEmail,
      //         parentGuardianName: parentGuardianName,
      //         parentGuardianEmail: parentGuardianEmail,
      //         institutionName: institutionName,
      //       },
      //     }
      //   );

      // if (sendGrantAcceptanceForm.data.status === "ok") {
      await registeredProgramQuery.update({
        grantAcceptanceFormSent: true,
      });
      // }
      // if (sendScholarConsentForm.data.status === "ok") {
      await registeredProgramQuery.update({
        scholarConsentFormSent: true,
      });
      // }

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
export const applicantAcceptApplicationWithoutChecklist2 = (
  id,
  registeredProgram
) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);
    const registeredProgramData = await registeredProgramQuery.get();

    const programQuery = firestore
      .collection('programs')
      .doc(`${registeredProgram.programId}`);
    const institutionsQuery = firestore
      .collection('institutions')
      .doc(`${registeredProgram.chosenInstitution}`);

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        applicationAccepted: true,
        applicationAcceptedAt: editiedAt,
        applicationDecline: false,
        // programStage: 'approved'
        programStage: 'complete',
        finalSubmissionCompleted: true,
        programStatus: 'in-process',
        logs: registeredProgramData.data().logs
          ? [
              ...registeredProgramData.data().logs,
              {
                event: 'Application Acceptance',
                date: editiedAt,
              },
            ]
          : [
              {
                event: 'Application Acceptance',
                date: editiedAt,
              },
            ],
      });

      let query = await programQuery.get();
      let institutionQuery = await institutionsQuery.get();

      let program = [];
      let institution = [];

      if (query.exists) {
        let item = {
          id: query.id,
          ...query.data(),
        };
        program.push(item);
      }

      if (institutionQuery.exists) {
        let item = {
          id: query.id,
          ...query.data(),
        };
        institution.push(item);
      }

      const id = registeredProgram.id;
      const programName = program[0].programName;
      const applicantFullName =
        registeredProgram.personalInformationFormDetails?.firstName +
        ' ' +
        registeredProgram.personalInformationFormDetails?.lastName;
      const applicantEmail =
        registeredProgram.personalInformationFormDetails?.email;
      const parentGuardianName =
        registeredProgram.familyInformationFormDetails
          .parentGuardianNameNumber1;
      const parentGuardianEmail =
        registeredProgram.familyInformationFormDetails
          .parentGuardianEmailNumber1;
      const institutionName = institution[0].institutionName;

      //   const sendGrantAcceptanceForm = await axios.get(
      //     "/grantAcceptanceFormFunctions/sendGrantAcceptanceForm",
      //     {
      //       params: {
      //         id: id,
      //         programName: programName,
      //         applicantFullName: applicantFullName,
      //         applicantEmail: applicantEmail,
      //         institutionName: institutionName,
      //         parentGuardianEmail: parentGuardianEmail,
      //       },
      //     }
      //   );

      //   const sendScholarConsentForm = await axios.get(
      //     "/scholarConsentFormFunctions/sendScholarConsentForm",
      //     {
      //       params: {
      //         id: id,
      //         applicantFullName: applicantFullName,
      //         applicantEmail: applicantEmail,
      //         parentGuardianName: parentGuardianName,
      //         parentGuardianEmail: parentGuardianEmail,
      //         institutionName: institutionName,
      //       },
      //     }
      //   );

      // if (sendGrantAcceptanceForm.data.status === "ok") {
      await registeredProgramQuery.update({
        grantAcceptanceFormSent: true,
      });
      // }
      // if (sendScholarConsentForm.data.status === "ok") {
      await registeredProgramQuery.update({
        scholarConsentFormSent: true,
      });
      // }

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const applicantDeclineApplication = (id) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);
    const registeredProgram = await registeredProgramQuery.get();

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        applicationAccepted: false,
        applicationDecline: true,
        programStage: 'withdrawn',
        withdrawnAt: editiedAt,
        programStatus: 'closed',
        logs: registeredProgram.data().logs
          ? [
              ...registeredProgram.data().logs,
              {
                event: 'Application Declined',
                date: editiedAt,
              },
            ]
          : [
              {
                event: 'Application Declined',
                date: editiedAt,
              },
            ],
      });

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
