import cuid from 'cuid';
// UPDATE APPLICANT TYPE
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from '../../asyncActions/asyncActions';
import { fetchRegisteredProgram } from './applicantProgramActions';
import { format, getUnixTime, parseISO } from 'date-fns';
import axios from '../../../../config/axios';
import firebase from 'firebase';

export const updateApplicationComments = (id, values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      const registeredProgram = await registeredProgramQuery.get();
      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        applicationCommentsSet: true,
        ...values,
        logs: registeredProgram.data().logs
          ? [
              ...registeredProgram.data().logs,
              {
                event: 'Educational Interest Form Completed',
                date: getUnixTime(new Date()),
              },
            ]
          : [
              {
                event: 'Educational Interest Form Completed',
                date: getUnixTime(new Date()),
              },
            ],
      });

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const submitScholarshipForm = (id, values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    let qualifyForScholarship = null;

    if (values.applyForAScholarship === 'Yes') {
      // QUALIFY
      if (
        values.receiveFreeOrReducedLunch === 'Yes' &&
        values.familySalaryLessThan === 'Yes' &&
        values.catastrophicEvent === 'Yes'
      ) {
        qualifyForScholarship = true;
      }
      if (
        values.receiveFreeOrReducedLunch === 'Yes' &&
        values.familySalaryLessThan === 'No' &&
        values.catastrophicEvent === 'No'
      ) {
        qualifyForScholarship = false;
      }
      if (
        values.receiveFreeOrReducedLunch === 'No' &&
        values.familySalaryLessThan === 'No' &&
        values.catastrophicEvent === 'Yes'
      ) {
        qualifyForScholarship = true;
      }
      if (
        values.receiveFreeOrReducedLunch === 'Yes' &&
        values.familySalaryLessThan === 'No' &&
        values.catastrophicEvent === 'Yes'
      ) {
        qualifyForScholarship = true;
      }

      // DONT QUAKLIFY
      if (
        values.receiveFreeOrReducedLunch === 'No' &&
        values.familySalaryLessThan === 'No' &&
        values.catastrophicEvent === 'No'
      ) {
        qualifyForScholarship = false;
      }

      if (
        values.receiveFreeOrReducedLunch === 'Yes' &&
        values.familySalaryLessThan === 'Yes' &&
        values.catastrophicEvent === 'No'
      ) {
        qualifyForScholarship = true;
      }

      if (
        values.receiveFreeOrReducedLunch === 'No' &&
        values.familySalaryLessThan === 'Yes' &&
        values.catastrophicEvent === 'No'
      ) {
        qualifyForScholarship = true;
      }

      if (
        values.receiveFreeOrReducedLunch === 'No' &&
        values.familySalaryLessThan === 'Yes' &&
        values.catastrophicEvent === 'Yes'
      ) {
        qualifyForScholarship = true;
      }

      if (
        !values.receiveFreeOrReducedLunch ||
        !values.familySalaryLessThan ||
        !values.catastrophicEvent
      ) {
        qualifyForScholarship = false;
      }
    }

    if (values.applyForAScholarship === 'No') {
      qualifyForScholarship = false;
    }

    try {
      dispatch(asyncActionStart());
      const registeredProgram = await registeredProgramQuery.get();
      console.log(qualifyForScholarship, 'qualifyForScholarship');
      let editiedAt = getUnixTime(new Date());

      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        scholarshipFormCompleted: true,
        // taxFormsUploaded: values.applyForAScholarship === 'No' ? true : false,
        taxFormsUploaded: qualifyForScholarship === false ? true : false,

        scholarshipFormDetails: {
          ...values,
          qualifyForScholarship: qualifyForScholarship,
        },
        grantScholarship: qualifyForScholarship,
        scholarshipAmount:
          qualifyForScholarship &&
          registeredProgram.data().scholarshipAmount > 0
            ? registeredProgram.data()?.scholarshipAmount
            : '0',
        logs: registeredProgram.data().logs
          ? [
              ...registeredProgram.data().logs,
              {
                event: 'Short Response Form Completed',
                date: getUnixTime(new Date()),
              },
            ]
          : [
              {
                event: 'Short Response Form Completed',
                date: getUnixTime(new Date()),
              },
            ],
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateScholarshipForm = (id, values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    console.log(values, 'manvalues');
    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        taxFormsUploaded: values.applyForAScholarship === 'No' ? true : false,
        scholarshipFormDetails: {
          ...values,
        },
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const uploadTaxForm = (id, file) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      let fileExtension = '';

      if (file[0].type === 'image/jpeg') {
        fileExtension = '.jpeg';
      }

      if (file[0].type === 'image/png') {
        fileExtension = '.png';
      }

      if (file[0].type === 'application/pdf') {
        fileExtension = '.pdf';
      }

      if (file) {
        const fileName = cuid() + fileExtension;
        const originalFileName = file[0].name;

        const path = `registeredPrograms/${id}/taxForms`;
        const options = {
          name: fileName,
        };

        let uploadedFile = await firebase.uploadFile(
          path,
          file[0],
          null,
          options
        );

        // get download URL
        let downloadURL =
          await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();
        const registeredProgram = await registeredProgramQuery.get();
        // add image name and URL to firestore
        await registeredProgramQuery.update({
          taxForms: registeredProgram.data().taxForms
            ? [
                ...registeredProgram.data().taxForms,
                {
                  fileURL: downloadURL,
                  fileName: fileName,
                  originalFileName: originalFileName,
                },
              ]
            : [
                {
                  fileURL: downloadURL,
                  fileName: fileName,
                  originalFileName: originalFileName,
                },
              ],
        });
      }

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const deleteTaxForm = (id, fileName) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      // DELETE PHOTO FROM STORAGE
      await firebase.deleteFile(
        `registeredPrograms/${id}/taxForms/${fileName}`
      );

      const registeredProgram = await registeredProgramQuery.get();
      const taxForms = registeredProgram.data().taxForms;
      await registeredProgramQuery.update({
        taxForms: taxForms.filter((taxForm) => taxForm.fileName !== fileName),
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
    }
  };
};

export const submitTaxForm = (id, value) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        taxFormsUploaded: value,
      });

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const submitPersonalInformationForm = (id, uid, values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const userQuery = firestore.collection('users').doc(`${uid}`);

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      const registeredProgram = await registeredProgramQuery.get();

      let editiedAt = getUnixTime(new Date());
      await userQuery.update({
        editiedAt: editiedAt,
        firstName: values.firstName ? values.firstName : '',
        lastName: values.lastName ? values.lastName : '',
        middleInitial: values.middleInitial ? values.middleInitial : '',
        fullName: values.firstName + ' ' + values.lastName,
        dob: values.dob ? values.dob : '',
        gender: values.gender ? values.gender : '',
        poloSize: values.poloSize ? values.poloSize : '',
        address1: values.address1 ? values.address1 : '',
        secondaryEmail: values.secondaryEmail ? values.secondaryEmail : '',
        preferredEmail: values.prefferedEmail ? values.prefferedEmail : '',

        address2: values.address2 ? values.address2 : '',
        city: values.city ? values.city : '',
        state: values.state ? values.state : '',
        zipCode: values.zipCode ? values.zipCode : '',
        mobileNumber: values.mobileNumber ? values.mobileNumber : '',
        housePhoneNumber: values.housePhoneNumber
          ? values.housePhoneNumber
          : '',
        ethnicBackground: values.ethnicBackground
          ? values.ethnicBackground
          : '',
        citizenshipStatus: values.citizenshipStatus
          ? values.citizenshipStatus
          : '',
      });

      await registeredProgramQuery.update({
        personalInformationFormCompleted: true,
        personalInformationFormDetails: {
          editiedAt: editiedAt,
          firstName: values.firstName ? values.firstName : '',
          lastName: values.lastName ? values.lastName : '',
          middleInitial: values.middleInitial ? values.middleInitial : '',
          fullName: values.firstName + ' ' + values.lastName,
          dob: values.dob ? values.dob : '',
          gender: values.gender ? values.gender : '',
          poloSize: values.poloSize ? values.poloSize : '',

          address1: values.address1 ? values.address1 : '',
          address2: values.address2 ? values.address2 : '',
          city: values.city ? values.city : '',
          secondaryEmail: values.secondaryEmail ? values.secondaryEmail : '',

          state: values.state ? values.state : '',
          zipCode: values.zipCode ? values.zipCode : '',
          mobileNumber: values.mobileNumber ? values.mobileNumber : '',
          housePhoneNumber: values.housePhoneNumber
            ? values.housePhoneNumber
            : '',
          ethnicBackground: values.ethnicBackground
            ? values.ethnicBackground
            : '',
          citizenshipStatus: values.citizenshipStatus
            ? values.citizenshipStatus
            : '',
          afterGraduation: values.afterGraduation ? values.afterGraduation : '',
          americanWithDisabilitiesAct: values.americanWithDisabilitiesAct
            ? values.americanWithDisabilitiesAct
            : '',
          countryOfOrigin: values.countryOfOrigin ? values.countryOfOrigin : '',
          email: values.email ? values.email : '',
          preferredEmail: values.prefferedEmail ? values.prefferedEmail : '',
          nativeFamilyLanguage: values.nativeFamilyLanguage
            ? values.nativeFamilyLanguage
            : '',
          permanentResidentNumber: values.permanentResidentNumber
            ? values.permanentResidentNumber
            : '',
        },
        logs: registeredProgram.data().logs
          ? [
              ...registeredProgram.data().logs,
              {
                event: 'Personal Information Form Completed',
                date: getUnixTime(new Date()),
              },
            ]
          : [
              {
                event: 'Personal Information Form Completed',
                date: getUnixTime(new Date()),
              },
            ],
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updatePersonalInformationForm = (id, uid, values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const userQuery = firestore.collection('users').doc(`${uid}`);

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      await userQuery.update({
        editiedAt: editiedAt,
        allRequiredDocumentFilled: values.allRequiredDocumentFilled
          ? true
          : false,
        firstName: values.firstName ? values.firstName : '',
        lastName: values.lastName ? values.lastName : '',
        middleInitial: values.middleInitial ? values.middleInitial : '',
        fullName: values.firstName + ' ' + values.lastName,
        dob: values.dob ? values.dob : '',

        gender: values.gender ? values.gender : '',
        address1: values.address1 ? values.address1 : '',
        address2: values.address2 ? values.address2 : '',
        city: values.city ? values.city : '',
        state: values.state ? values.state : '',
        zipCode: values.zipCode ? values.zipCode : '',
        mobileNumber: values.mobileNumber ? values.mobileNumber : '',
        housePhoneNumber: values.housePhoneNumber
          ? values.housePhoneNumber
          : '',
        ethnicBackground: values.ethnicBackground
          ? values.ethnicBackground
          : '',
        citizenshipStatus: values.citizenshipStatus
          ? values.citizenshipStatus
          : '',
      });

      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        personalInformationFormDetails: {
          allRequiredDocumentFilled: values.allRequiredDocumentFilled
            ? true
            : false,
          editiedAt: editiedAt,
          firstName: values.firstName ? values.firstName : '',
          lastName: values.lastName ? values.lastName : '',
          middleInitial: values.middleInitial ? values.middleInitial : '',
          fullName: values.firstName + ' ' + values.lastName,
          dob: values.dob ? values.dob : '',
          gender: values.gender ? values.gender : '',
          address1: values.address1 ? values.address1 : '',
          address2: values.address2 ? values.address2 : '',
          city: values.city ? values.city : '',
          state: values.state ? values.state : '',

          zipCode: values.zipCode ? values.zipCode : '',
          mobileNumber: values.mobileNumber ? values.mobileNumber : '',
          housePhoneNumber: values.housePhoneNumber
            ? values.housePhoneNumber
            : '',
          ethnicBackground: values.ethnicBackground
            ? values.ethnicBackground
            : '',
          citizenshipStatus: values.citizenshipStatus
            ? values.citizenshipStatus
            : '',
          afterGraduation: values.afterGraduation ? values.afterGraduation : '',
          americanWithDisabilitiesAct: values.americanWithDisabilitiesAct
            ? values.americanWithDisabilitiesAct
            : '',
          countryOfOrigin: values.countryOfOrigin ? values.countryOfOrigin : '',
          email: values.email ? values.email : '',
          nativeFamilyLanguage: values.nativeFamilyLanguage
            ? values.nativeFamilyLanguage
            : '',

          permanentResidentNumber: values.permanentResidentNumber
            ? values.permanentResidentNumber
            : '',
        },
      });
      // allRequiredDocumentFilled

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const submitFamilyInformationForm = (id, values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      const registeredProgram = await registeredProgramQuery.get();

      let editiedAt = getUnixTime(new Date());

      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        familyInformationFormCompleted: true,
        familyInformationFormDetails: {
          ...values,
        },
        logs: registeredProgram.data().logs
          ? [
              ...registeredProgram.data().logs,
              {
                event: 'Family Information Form Completed',
                date: getUnixTime(new Date()),
              },
            ]
          : [
              {
                event: 'Family Information Form Completed',
                date: getUnixTime(new Date()),
              },
            ],
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateFamilyInformationForm = (id, values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        familyInformationFormDetails: {
          ...values,
        },
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const submitAcademicInformationForm = (id, values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      const registeredProgram = await registeredProgramQuery.get();
      let editiedAt = getUnixTime(new Date());

      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        academicInformationFormCompleted: true,
        academicInformationFormDetails: {
          ...values,
        },
        logs: registeredProgram.data().logs
          ? [
              ...registeredProgram.data().logs,
              {
                event: 'Academic Information Form Completed',
                date: getUnixTime(new Date()),
              },
            ]
          : [
              {
                event: 'Academic Information Form Completed',
                date: getUnixTime(new Date()),
              },
            ],
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateAcademicInformationForm = (id, values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        academicInformationFormDetails: {
          ...values,
        },
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const submitExtracurricularActivitiesForm = (id, values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      const registeredProgram = await registeredProgramQuery.get();

      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        extracurricularFormCompleted: true,
        extracurricularFormDetails: {
          ...values,
        },
        logs: registeredProgram.data().logs
          ? [
              ...registeredProgram.data().logs,
              {
                event: 'Extracurricular Form Completed',
                date: getUnixTime(new Date()),
              },
            ]
          : [
              {
                event: 'Extracurricular Form Completed',
                date: getUnixTime(new Date()),
              },
            ],
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateExtracurricularActivitiesForm = (id, values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        extracurricularFormDetails: {
          ...values,
        },
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
// new one
export const uploadResume = (id, files) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    // console.log(file, 'uploadImage');
    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      let fileExtension = '';
      const updatedFields = {};

      // if (file?.ResumeId[0].type === 'image/jpeg') {
      //   fileExtension = '.jpeg';
      // }

      // if (file?.ResumeId[0].type === 'image/png') {
      //   fileExtension = '.png';
      // }

      // if (file?.ResumeId[0].type === 'application/pdf') {
      //   fileExtension = '.pdf';
      // }

      // if (file) {
      //   const fileName = cuid() + fileExtension;
      //   const originalFileName = file?.ResumeId[0].name;

      //   const path = `registeredPrograms/${id}/resume`;
      //   const options = {
      //     name: fileName,
      //   };

      //   let uploadedFile = await firebase.uploadFile(
      //     path,
      //     file[0],
      //     null,
      //     options
      //   );

      //   // get download URL
      //   let downloadURL =
      //     await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();

      //   // add image name and URL to firestore
      //   await registeredProgramQuery.update({
      //     resume: {
      //       fileURL: downloadURL,
      //       fileName: fileName,
      //       originalFileName: originalFileName,
      //     },
      //   });
      // }
      for (let fileType in files) {
        if (files[fileType].length > 0) {
          updatedFields[fileType] = [];

          for (let file in Array.from(files[fileType])) {
            if (!files[fileType][file]?.fileURL) {
              const name =
                files[fileType][file].name || files[fileType][file].fileName;
              const fileName = cuid() + '.' + name?.split('.').pop();
              const originalFileName = files[fileType][file].name;

              const path = `registeredPrograms/${id}/governmentId`;
              const options = {
                name: fileName,
              };

              let uploadedFile = await firebase.uploadFile(
                path,
                files[fileType][file],
                null,
                options
              );

              let downloadURL =
                await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();

              updatedFields[fileType].push({
                fileURL: downloadURL,
                fileName: fileName,
                originalFileName: originalFileName,
              });
            } else {
              updatedFields[fileType].push({
                fileURL: files[fileType][file]?.fileURL,
                fileName: files[fileType][file]?.fileName,
                originalFileName: files[fileType][file]?.originalFileName,
              });
            }
          }
        }
      }
      await registeredProgramQuery.update({
        resume: {
          // fileURL: downloadURL,
          // fileName: fileName,
          // originalFileName: originalFileName,
          ...updatedFields,
        },
      });
      // }
      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
// old one
export const uploadResumes = (id, file) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    console.log(file, 'fileNew');
    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      let fileExtension = '';

      if (file[0].type === 'image/jpeg') {
        fileExtension = '.jpeg';
      }

      if (file[0].type === 'image/png') {
        fileExtension = '.png';
      }

      if (file[0].type === 'application/pdf') {
        fileExtension = '.pdf';
      }

      if (file) {
        const fileName = cuid() + fileExtension;
        const originalFileName = file[0]?.name;

        const path = `registeredPrograms/${id}/resume`;
        const options = {
          name: fileName,
        };

        let uploadedFile = await firebase.uploadFile(
          path,
          file[0],
          null,
          options
        );

        // get download URL
        let downloadURL =
          await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();

        // add image name and URL to firestore
        await registeredProgramQuery.update({
          resume: {
            fileURL: downloadURL,
            fileName: fileName,
            originalFileName: originalFileName,
          },
        });
      }

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
// old one
export const deleteResume = (id, fileName) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    // console.log(id, 'idsss');
    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      await firebase.deleteFile(`registeredPrograms/${id}/resume/${fileName}`);

      await registeredProgramQuery.update({
        // remove null because after deleting it store null value in resume that cause green border in resume form in edit
        // resume: null,
        resume: firestore.FieldValue.delete(),
        // add below case show that completed and uploaded text not shown in resume form
        resumeFormCompleted: false,
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
    }
  };
};
// new one
export const deleteResumes = (id, fieldName, files) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    console.log(files, 'filesdellete');
    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      const registeredProgram = await registeredProgramQuery.get();

      for (let file in files) {
        firebase.deleteFile(
          `registeredPrograms/${id}/resume/${files[0].fileName}`
          // `registeredPrograms/${id}/governmentId/${files[file].fileName}`
        );
      }

      const updatedGovernmentIds = registeredProgram.data().resume;

      delete updatedGovernmentIds[fieldName];

      await registeredProgramQuery.update({
        resume: firestore.FieldValue.delete(),

        // resume: updatedGovernmentIds,
        resumeFormCompleted: false,
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
    }
  };
};
// new one
export const submitResumeForms = (id, value, files) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    // console.log(file?.ResumeId[0].type, 'manish');
    // yourObject.ResumeId.FileList[0].type;
    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      let fileExtension = '';
      const updatedFields = {};

      // if (file?.ResumeId[0].type === 'image/jpeg') {
      //   fileExtension = '.jpeg';
      // }

      // if (file?.ResumeId[0].type === 'image/png') {
      //   fileExtension = '.png';
      // }

      // if (file?.ResumeId[0].type === 'application/pdf') {
      //   fileExtension = '.pdf';
      // }

      // if (file) {
      //   const fileName = cuid() + fileExtension;
      //   const originalFileName = file?.ResumeId[0].name;

      //   const path = `registeredPrograms/${id}/resume`;
      //   const options = {
      //     name: fileName,
      //   };

      //   let uploadedFile = await firebase.uploadFile(
      //     path,
      //     file[0],
      //     null,
      //     options
      //   );

      //   // get download URL
      //   let downloadURL =
      //     await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();

      //   // add image name and URL to firestore
      //   await registeredProgramQuery.update({
      //     resume: {
      //       // fileURL: downloadURL,
      //       // fileName: fileName,
      //       // originalFileName: originalFileName,
      //     ...updatedFields,

      //     },
      //     editiedAt: editiedAt,
      //     resumeFormCompleted: value,
      //   });
      // }
      // for (let fileType in files) {
      //   if (files[fileType].length > 0) {
      //     updatedFields[fileType] = [];

      //     for (let file in Array.from(files[fileType])) {
      //       if (!files[fileType][file]?.fileURL) {
      //         const name =
      //           files[fileType][file].name || files[fileType][file].fileName;
      //         const fileName = cuid() + '.' + name?.split('.').pop();
      //         const originalFileName = files[fileType][file].name;

      //         const path = `registeredPrograms/${id}/governmentId`;
      //         const options = {
      //           name: fileName,
      //         };

      //         let uploadedFile = await firebase.uploadFile(
      //           path,
      //           files[fileType][file],
      //           null,
      //           options
      //         );

      //         let downloadURL =
      //           await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();

      //         updatedFields[fileType].push({
      //           fileURL: downloadURL,
      //           fileName: fileName,
      //           originalFileName: originalFileName,
      //         });
      //       } else {
      //         updatedFields[fileType].push({
      //           fileURL: files[fileType][file]?.fileURL,
      //           fileName: files[fileType][file]?.fileName,
      //           originalFileName: files[fileType][file]?.originalFileName,
      //         });
      //       }
      //     }
      //   }
      // }

      // await registeredProgramQuery.update({
      //   ...updatedFields,

      //   editiedAt: editiedAt,
      //   resumeFormCompleted: value,
      // });
      // const updatedFields = {};

      for (let fileType in files) {
        if (files[fileType].length > 0) {
          updatedFields[fileType] = [];

          for (let file in Array.from(files[fileType])) {
            if (!files[fileType][file]?.fileURL) {
              const name =
                files[fileType][file].name || files[fileType][file].fileName;
              const fileName = cuid() + '.' + name?.split('.').pop();
              const originalFileName = files[fileType][file].name;

              // const path = `registeredPrograms/${id}/governmentId`;
              const path = `registeredPrograms/${id}/resume`;
              const options = {
                name: fileName,
              };

              let uploadedFile = await firebase.uploadFile(
                path,
                files[fileType][file],
                null,
                options
              );

              let downloadURL =
                await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();

              updatedFields[fileType].push({
                fileURL: downloadURL,
                fileName: fileName,
                originalFileName: originalFileName,
              });
            } else {
              updatedFields[fileType].push({
                fileURL: files[fileType][file]?.fileURL,
                fileName: files[fileType][file]?.fileName,
                originalFileName: files[fileType][file]?.originalFileName,
              });
            }
          }
        }
      }
      await registeredProgramQuery.update({
        resume: {
          // fileURL: downloadURL,
          // fileName: fileName,
          // originalFileName: originalFileName,
          ...updatedFields,
        },
        editiedAt: editiedAt,
        resumeFormCompleted: value,
      });
      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
// old one
export const submitResumeForm = (id, value) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        resumeFormCompleted: value,
      });

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
// TRANSCRIPT
export const uploadTranscript = (id, file) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      let fileExtension = '';

      if (file[0].type === 'image/jpeg') {
        fileExtension = '.jpeg';
      }

      if (file[0].type === 'image/png') {
        fileExtension = '.png';
      }

      if (file[0].type === 'application/pdf') {
        fileExtension = '.pdf';
      }

      if (file) {
        const fileName = cuid() + fileExtension;
        const originalFileName = file[0].name;

        const path = `registeredPrograms/${id}/transcript`;
        const options = {
          name: fileName,
        };

        let uploadedFile = await firebase.uploadFile(
          path,
          file[0],
          null,
          options
        );

        // get download URL
        let downloadURL =
          await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();
        const registeredProgram = await registeredProgramQuery.get();

        // add image name and URL to firestore
        await registeredProgramQuery.update({
          transcripts: registeredProgram.data().transcripts
            ? [
                ...registeredProgram.data().transcripts,
                {
                  fileURL: downloadURL,
                  fileName: fileName,
                  originalFileName: originalFileName,
                },
              ]
            : [
                {
                  fileURL: downloadURL,
                  fileName: fileName,
                  originalFileName: originalFileName,
                },
              ],
        });
      }

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
// old one delete Transcript
export const deleteTranscript = (id, fileName) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      // DELETE PHOTO FROM STORAGE
      await firebase.deleteFile(
        `registeredPrograms/${id}/transcript/${fileName}`
      );
      const registeredProgram = await registeredProgramQuery.get();
      const transcripts = registeredProgram.data().transcripts;
      await registeredProgramQuery.update({
        transcripts: transcripts.filter(
          (transcript) => transcript.fileName !== fileName
        ),
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
    }
  };
};
//new one delete Transcript
export const deleteTranscripts = (id, fieldName, files) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    console.log(files, 'government');
    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      const registeredProgram = await registeredProgramQuery.get();

      for (let file in files) {
        firebase.deleteFile(
          `registeredPrograms/${id}/transcript/${files[file].fileName}`
        );
      }

      const updatedtranscripts = registeredProgram.data().transcripts;

      delete updatedtranscripts[fieldName];

      await registeredProgramQuery.update({
        transcripts: updatedtranscripts,
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
    }
  };
};
// old one transcript
export const submitTranscriptForm = (id, value) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        transcriptFormCompleted: value,
      });

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
// new one transcript
export const submitTranscriptForms = (id, files, completed) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      const registeredProgram = await registeredProgramQuery.get();

      const updatedFields = {};

      for (let fileType in files) {
        if (files[fileType].length > 0) {
          updatedFields[fileType] = [];

          for (let file in Array.from(files[fileType])) {
            if (!files[fileType][file]?.fileURL) {
              const name =
                files[fileType][file].name || files[fileType][file].fileName;
              const fileName = cuid() + '.' + name?.split('.').pop();
              const originalFileName = files[fileType][file].name;
              const path = `registeredPrograms/${id}/transcript`;
              // const path = `registeredPrograms/${id}/governmentId`;
              const options = {
                name: fileName,
              };

              let uploadedFile = await firebase.uploadFile(
                path,
                files[fileType][file],
                null,
                options
              );

              let downloadURL =
                await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();

              updatedFields[fileType].push({
                fileURL: downloadURL,
                fileName: fileName,
                originalFileName: originalFileName,
              });
            } else {
              updatedFields[fileType].push({
                fileURL: files[fileType][file]?.fileURL,
                fileName: files[fileType][file]?.fileName,
                originalFileName: files[fileType][file]?.originalFileName,
              });
            }
          }
        }
      }
      console.log(updatedFields, 'updatedFields');
      // add image name and URL to firestore
      await registeredProgramQuery.update({
        // transcripts: registeredProgram.data().transcripts
        //   ? [
        //       ...registeredProgram.data().transcripts,
        //       {
        //         ...updatedFields,
        //       },
        //     ]
        //   : [
        //       {
        //         ...updatedFields,
        //       },
        //     ],
        transcripts: {
          ...registeredProgram.data().transcripts,
          ...updatedFields,
        },
        // transcriptStandardized: {
        //   ...registeredProgram.data().grantAcceptanceFormDetails,
        //   ...updatedFields,
        // },
        transcriptFormCompleted: completed,
        // logs:
        //   !registeredProgram.data()?.transcriptStandardizedUploaded && completed
        //     ? [
        //         ...registeredProgram.data()?.logs,
        //         {
        //           event: 'Transcript and Standardized Test Score',
        //           date: getUnixTime(new Date()),
        //         },
        //       ]
        //     : [...registeredProgram.data().logs],
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
    }
  };
};
// ADDITIONAL DOCUMENTS
export const uploadAdditionalDocument = (id, file) => {
  console.log(file, 'file');
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      let fileExtension = '';

      if (file[0].type === 'image/jpeg') {
        fileExtension = '.jpeg';
      }

      if (file[0].type === 'image/png') {
        fileExtension = '.png';
      }
      // when file type is video/quicktime you get get when uploading a file
      if (file[0].type === 'video/quicktime') {
        fileExtension = '.mov';
      }

      if (file[0].type === 'application/pdf') {
        fileExtension = '.pdf';
      }

      if (file) {
        const fileName = cuid() + fileExtension;
        const originalFileName = file[0].name;

        const path = `registeredPrograms/${id}/additionalDocuments`;
        const options = {
          name: fileName,
        };

        let uploadedFile = await firebase.uploadFile(
          path,
          file[0],
          null,
          options
        );

        // get download URL
        let downloadURL =
          await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();
        const registeredProgram = await registeredProgramQuery.get();

        // add image name and URL to firestore
        await registeredProgramQuery.update({
          additionalDocuments: registeredProgram.data().additionalDocuments
            ? [
                ...registeredProgram.data().additionalDocuments,
                {
                  fileURL: downloadURL,
                  fileName: fileName,
                  originalFileName: originalFileName,
                },
              ]
            : [
                {
                  fileURL: downloadURL,
                  fileName: fileName,
                  originalFileName: originalFileName,
                },
              ],
        });
      }

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const deleteAdditionalDocument = (id, fileName) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      // DELETE PHOTO FROM STORAGE
      await firebase.deleteFile(
        `registeredPrograms/${id}/additionalDocuments/${fileName}`
      );
      const registeredProgram = await registeredProgramQuery.get();
      const additionalDocuments = registeredProgram.data().additionalDocuments;
      await registeredProgramQuery.update({
        additionalDocuments: additionalDocuments.filter(
          (additionalDocument) => additionalDocument.fileName !== fileName
        ),
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
    }
  };
};

export const submitAdditionalDocumentsForm = (id, value) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        additionalDocumentsFormCompleted: value,
      });

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

// SHORT ESSAYS
export const submitShortResponsesForm = (id, values) => {
  // const keyOrder = Object.keys(values);
  // const newObject = {};
  // for (const key of keyOrder) {
  //   newObject[key] = values[key];
  // }
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      const registeredProgram = await registeredProgramQuery.get();

      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        shortResponsesFormCompleted: true,
        shortResponsesFormDetails: {
          ...values,
        },
        logs: registeredProgram.data().logs
          ? [
              ...registeredProgram.data().logs,
              {
                event: 'Short Response Form Completed',
                date: getUnixTime(new Date()),
              },
            ]
          : [
              {
                event: 'Short Response Form Completed',
                date: getUnixTime(new Date()),
              },
            ],
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateShortResponsesForm = (id, values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    console.log(values, 'updateShortResponsesForm');
    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,

        shortResponsesFormDetails: {
          ...values,
        },
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

// export const fetchAdminProgram = (id) => {
//   return async (dispatch, getState, { getFirestore, getFirebase }) => {
//     const firestore = getFirestore();

//     // const programQuery = firestore.collection("programs").doc(`${id}`);

//     const registeredProgramQuery = firestore
//       .collection("registeredPrograms")
//       .doc(`${id}`);

//     const startedApplicationQuery = firestore
//       .collection("registeredPrograms")
//       .where("programId", "==", `${id}`)
//       .where("programStage", "==", "in-progress");

//     try {
//       dispatch(asyncActionStart());
//       // let query = await programQuery.get();
//       let query = await registeredProgramQuery.get();
//       let startedApplication = await startedApplicationQuery.get();

//       // let program = [];

//       // if (query.exists) {
//       //   let item = {
//       //     id: query.id,
//       //     ...query.data(),
//       //     startedApplication: startedApplication.docs.length,
//       //   };
//       //   program.push(item);
//       // }

//       // if (!query.exists) {
//       //   dispatch(asyncActionFinish());
//       //   return;
//       // }

//       dispatch({
//         type: FETCH_ADMIN_PROGRAM,
//         payload: { program },
//       });
//       dispatch(asyncActionFinish());
//     } catch (error) {
//       dispatch(asyncActionError());
//       console.log(error);
//     }
//   };
// };

// INVITE RECOMMENDER
export const sendRecommenderInvite = (profile, id, values, type) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    // console.log(profile, type, 'profilessss');
    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);
    const registeredProgram = await registeredProgramQuery.get();
    const applicantFullName = registeredProgram.data()?.fullName;
    console.log(applicantFullName, 'applicantFullName');
    const applicantEmail = profile.email;
    const applicantAddress =
      profile.address1 +
      ', ' +
      profile.address2 +
      ', ' +
      profile.city +
      ', ' +
      profile.state +
      ', ' +
      profile.zipCode;
    const applicantPhone = profile.mobileNumber;

    const recommenderFullName =
      values.recommenderFirstName + ' ' + values.recommenderLastName;
    // const recommenderLastName = values.recommenderLastName;
    const recommenderEmail = values.recommenderEmailAddress;
    console.log(
      applicantFullName,
      'applicantFullName',
      applicantEmail,
      'applicantEmail',
      applicantAddress,
      'applicantAddress',
      applicantPhone,
      'applicantPhone',
      recommenderFullName,
      'recommenderFullName',
      recommenderEmail,
      'recommenderEmail',
      'jaiiiiii'
    );
    try {
      dispatch(asyncActionStart());

      if (type === 'counselor') {
        const sendCounselorRecommenderForm = await axios.get(
          '/counselorRecommenderFormFunctions/sendCounselorRecommenderForm',
          {
            params: {
              id: id,
              applicantFullName: applicantFullName,
              applicantEmail: applicantEmail,
              applicantAddress: applicantAddress,
              applicantPhone: applicantPhone,
              recommenderFullName: recommenderFullName,
              recommenderEmail: recommenderEmail,
            },
          }
        );
        console.log(
          sendCounselorRecommenderForm,
          'sendCounselorRecommenderForm'
        );

        if (sendCounselorRecommenderForm.data === 'ok') {
          await registeredProgramQuery.update({
            counselorRecommenderFormSent: true,
          });
        }
      }

      if (type === 'personal') {
        const sendPersonalRecommenderForm = await axios.get(
          '/personalRecommenderFormFunctions/sendPersonalRecommenderForm',
          {
            params: {
              id: id,
              applicantFullName: applicantFullName,
              applicantEmail: applicantEmail,
              applicantAddress: applicantAddress,
              applicantPhone: applicantPhone,
              recommenderFullName: recommenderFullName,
              recommenderEmail: recommenderEmail,
              envType: process.env.REACT_APP_ENVIRONMENT,
            },
          }
        );

        if (sendPersonalRecommenderForm.data === 'ok') {
          await registeredProgramQuery.update({
            personalRecommenderFormSent: true,
          });
        }
      }

      if (type === 'academic') {
        const sendAcademicRecommenderForm = await axios.get(
          '/academicRecommenderFormFunctions/sendAcademicRecommenderForm',
          {
            params: {
              id: id,
              applicantFullName: applicantFullName,
              applicantEmail: applicantEmail,
              applicantAddress: applicantAddress,
              applicantPhone: applicantPhone,
              recommenderFullName: recommenderFullName,
              recommenderEmail: recommenderEmail,
            },
          }
        );

        if (sendAcademicRecommenderForm.data === 'ok') {
          await registeredProgramQuery.update({
            academicRecommenderFormSent: true,
          });
        }
      }

      await registeredProgramQuery.update({
        [`${type}RecommenderDetails`]: {
          ...values,
        },
      });

      axios.get('/applicantApplicationFunctions/sendRecommendationSentEmail', {
        params: {
          id: id,
          name: applicantFullName,
          email: applicantEmail,
          type: type,
          recommenderEmail: recommenderEmail,
        },
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error, 'error');
    }
  };
};

export const resendRecommenderInvite = (
  registeredProgramId,
  currentFormId,
  type
) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      if (type === 'counselor') {
        await axios.get(
          '/counselorRecommenderFormFunctions/resendCounselorRecommenderForm',
          {
            params: {
              currentFormId: currentFormId,
              registeredProgramId: registeredProgramId,
            },
          }
        );
      }

      if (type === 'personal') {
        await axios.get(
          '/personalRecommenderFormFunctions/resendPersonalRecommenderForm',
          {
            params: {
              currentFormId: currentFormId,
              registeredProgramId: registeredProgramId,
            },
          }
        );
      }

      if (type === 'academic') {
        await axios.get(
          '/academicRecommenderFormFunctions/resendAcademicRecommenderForm',
          {
            params: {
              currentFormId: currentFormId,
              registeredProgramId: registeredProgramId,
            },
          }
        );
      }
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const cancelRecommenderInvite = (id, currentFormId, type, profile) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      if (type === 'counselor') {
        const query = await axios.get(
          '/counselorRecommenderFormFunctions/cancelCounselorRecommenderForm',
          {
            params: {
              currentFormId: currentFormId,
              registeredProgramId: id,
            },
          }
        );
        if (query.data === 'ok') {
          await registeredProgramQuery.update({
            counselorRecommenderFormSent: false,
            counselorRecommenderFormDocNo: firestore.FieldValue.delete(),
          });
        }
      }

      if (type === 'personal') {
        const query = await axios.get(
          '/personalRecommenderFormFunctions/cancelPersonalRecommenderForm',
          {
            params: {
              currentFormId: currentFormId,
              registeredProgramId: id,
            },
          }
        );
        if (query.data === 'ok') {
          await registeredProgramQuery.update({
            personalRecommenderFormSent: false,
            personalRecommenderFormDocNo: firestore.FieldValue.delete(),
          });
        }
      }

      if (type === 'academic') {
        const query = await axios.get(
          '/academicRecommenderFormFunctions/cancelAcademicRecommenderForm',
          {
            params: {
              currentFormId: currentFormId,
              registeredProgramId: id,
            },
          }
        );
        if (query.data === 'ok') {
          await registeredProgramQuery.update({
            academicRecommenderFormSent: false,
            academicRecommenderFormDocNo: firestore.FieldValue.delete(),
          });
        }
      }

      axios.get(
        '/applicantApplicationFunctions/sendRecommendationCancelEmail',
        {
          params: {
            id: id,
            type: type,
          },
        }
      );
      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

// RECOMMENDERS
export const submitRecommenderForm = (id, status) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      const registeredProgram = await registeredProgramQuery.get();

      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        recommendersFormCompleted: status,
        logs: registeredProgram.data().logs
          ? [
              ...registeredProgram.data().logs,
              {
                event: 'Recommenders Form Completed',
                date: getUnixTime(new Date()),
              },
            ]
          : [
              {
                event: 'Recommenders Form Completed',
                date: getUnixTime(new Date()),
              },
            ],
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
export const updateRecommenderForm = (id, status) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      const registeredProgram = await registeredProgramQuery.get();

      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,

        // recommendersFormCompleted: status,
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

// CONFIRMATION
export const submitConfirmationForm = (
  id,
  applicationCompleted,
  values,
  registeredProgram,
  program
) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      const registeredProgram = await registeredProgramQuery.get();
      const todaysDate = new Date().toISOString();
      let editiedAt = getUnixTime(new Date());

      if (
        !program?.paymentForm &&
        !program.scholarConsentForm &&
        !program.governmentIdForm &&
        !program.travelInformationForm &&
        !program.studentQuestionnaireForm &&
        !program.downloadProgramPacketForm &&
        !program.requiredDocumentationForm &&
        program.customChecklist
      ) {
        await registeredProgramQuery.update({
          editiedAt: editiedAt,
          applicationCompleted: applicationCompleted,
          confirmationFormCompleted: true,
          programStage: 'under-review',
          reviewStatus: 'unassigned',
          confirmationFormDetails: {
            ...values,
            confirmationDate: values.confirmationDate
              ? values.confirmationDate
              : todaysDate,
          },
          logs: registeredProgram.data().logs
            ? [
                ...registeredProgram.data().logs,
                {
                  event: 'Confirmation Form Submitted',
                  date: getUnixTime(new Date()),
                },
              ]
            : [
                {
                  event: 'Confirmation Form Submitted',
                  date: getUnixTime(new Date()),
                },
              ],
        });
      } else {
        await registeredProgramQuery.update({
          editiedAt: editiedAt,
          applicationCompleted: applicationCompleted,
          confirmationFormCompleted: true,
          programStage: 'under-review',
          reviewStatus: 'unassigned',
          confirmationFormDetails: {
            ...values,
            confirmationDate: values.confirmationDate
              ? values.confirmationDate
              : todaysDate,
          },
          logs: registeredProgram.data().logs
            ? [
                ...registeredProgram.data().logs,
                {
                  event: 'Confirmation Form Submitted',
                  date: getUnixTime(new Date()),
                },
              ]
            : [
                {
                  event: 'Confirmation Form Submitted',
                  date: getUnixTime(new Date()),
                },
              ],
        });
      }
      const notificationDate = format(
        parseISO(program.notificationDate),
        'EEE MMM do y'
      );

      await axios.get(
        '/applicantApplicationFunctions/sendApplicationSubmitted',
        {
          params: {
            id: id,
            applicantFirstName: registeredProgram.firstName,
            applicantLastName: registeredProgram.lastName,
            applicantEmail: registeredProgram.email,
            programName: program.programName,
            notificationDate,
          },
        }
      );

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateCovidProtocolForm = (id, values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());

      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        covidProtocolFormDetails: {
          ...values,
        },
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const submitCovidProtocolForm = (id, values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      const registeredProgram = await registeredProgramQuery.get();
      let editiedAt = getUnixTime(new Date());

      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        covidProtocolFormCompleted: true,
        covidProtocolFormDetails: {
          ...values,
        },
        logs: registeredProgram.data().logs
          ? [
              ...registeredProgram.data().logs,
              {
                event: 'Covid Protocol Form Completed',
                date: getUnixTime(new Date()),
              },
            ]
          : [
              {
                event: 'Covid Protocol Form Completed',
                date: getUnixTime(new Date()),
              },
            ],
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

// Send Application Notification Approved
export const sendApplicationNotificationApproved = (id) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      await axios.post(
        '/applicantApplicationFunctions/sendApplicationNotificationApproved',
        {
          applicationId: id,
        }
      );
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const submitDeclineComment = (id, values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());

      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        declineComment: values.declineComment,
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

// Send Generic Email
export const sendGenericEmail = (subject, content, emails, sentBy) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      await axios.post('/applicantApplicationFunctions/sendGenericEmail', {
        subject,
        content,
        emails,
        sentBy,
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
