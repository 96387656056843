import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "1.5em",
    },
    maxWidth:'100%'
  },
  image: {
    height: "240px",
    width: "100%",
    borderRadius: "20px",
    marginBottom: "30px",
    [theme.breakpoints.down("md")]: {},
  },

  heading: {
    color: "#171725",
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "36px",
    letterSpacing: "0.1px",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 2,
    overflow: "hidden",
  },
  text: {
    color: "#696974",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "0.1px",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 6,
    overflow: "hidden",
    textAlign: "justify"
  },
  button: {
    color: "#4CA94F",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "0.1px",
    cursor: "pointer",
  },
}));

const NewsAnnouncmentItem = ({ img, heading, text, link }) => {
  const matches = useMediaQuery("(min-width:480px)");
  const classes = useStyles();
  return (
    <Grid item lg={4} md={6} sm={12} xs={12} className={classes.container}>
      <Grid item container direction={"column"} >
        <Grid item>
          <img
            className={classes.image}
            style={{ width: `${matches ? "" : "100%"}` }}
            src={img}
            alt=""
          />
        </Grid>

        <Grid item style={{ marginTop: "21px" }}>
          <Typography className={classes.heading}>{heading}</Typography>
        </Grid>
        <Grid item style={{ marginTop: "20px" }}>
          <Typography className={classes.text}>{text}</Typography>
        </Grid>
        <Grid item style={{ marginTop: "30px" }}>
          <Typography
            onClick={() => window.open(link)}
            className={classes.button}
          >
            Read more >
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewsAnnouncmentItem;
