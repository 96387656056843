import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import ReviewProgramItem from "./item/ReviewProgramItem";
import { format, parseISO } from "date-fns";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  answerText: {
    fontWeight: 600,
  },
}));

const PersonalInformationFormReview = ({ personalInformation }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction={"column"}
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
    >
      <Grid item>
        <Typography className={classes.title}>
          Personal Information Form
        </Typography>
      </Grid>
      {/*LAST NAME*/}
      <ReviewProgramItem
        question={"Last Name"}
        value={personalInformation?.lastName}
      />
      <ReviewProgramItem
        question={"First Name"}
        value={personalInformation?.firstName}
      />
      <ReviewProgramItem
        question={"Middle Initial"}
        value={personalInformation?.middleInitial}
      />
      <ReviewProgramItem
        question={"Gender"}
        value={personalInformation?.gender}
      />
      <ReviewProgramItem
        question={"Date of Birth"}
        value={
          personalInformation?.dob
            ? format(parseISO(personalInformation?.dob), "EEE MMM do y")
            : ""
        }
      />
      <ReviewProgramItem
        question={"Polo Size"}
        value={personalInformation?.poloSize}
      />
      <ReviewProgramItem
        question={"Address 1"}
        value={personalInformation?.address1}
      />
      <ReviewProgramItem
        question={"Address 2"}
        value={personalInformation?.address2}
      />
      <ReviewProgramItem question={"City"} value={personalInformation?.city} />
      <ReviewProgramItem
        question={"State"}
        value={personalInformation?.state}
      />
      <ReviewProgramItem
        question={"Zip Code"}
        value={personalInformation?.zipCode}
      />
      <ReviewProgramItem
        question={"Email"}
        value={personalInformation?.email}
      />
      <ReviewProgramItem
        question={"Mobile Number"}
        value={personalInformation?.mobileNumber}
      />
      <ReviewProgramItem
        question={"House Phone Number"}
        value={personalInformation?.housePhoneNumber}
      />

      <ReviewProgramItem
        question={
          "After graduation, will you be applying as a first-generation college applicant?"
        }
        value={personalInformation?.afterGraduation}
      />

      {/*ETHNIC BACKGROUND*/}
      <ReviewProgramItem
        question={
          "Please select the option that best describes your ethnic background:"
        }
        value={personalInformation?.ethnicBackground}
      />
      {personalInformation?.ethnicBackground === "Other" && (
        <ReviewProgramItem
          question={"Please Specify:"}
          value={personalInformation?.ethnicOther}
        />
      )}
      {/*CITIZENSHIP STATUS*/}
      <ReviewProgramItem
        question={"Please indicate your citizenship status:"}
        value={personalInformation?.citizenshipStatus}
      />

      {/*ADA*/}
      <ReviewProgramItem
        question={
          "Will you require accommodations in accordance with the American Disability Act (ADA Title III):"
        }
        value={personalInformation?.americanWithDisabilitiesAct}
      />
      {personalInformation?.americanWithDisabilitiesAct === "Yes" && (
        <ReviewProgramItem
          question={"Please Specify:"}
          value={personalInformation?.specifyRequireAccommadation}
        />
      )}
    </Grid>
  );
};

export default PersonalInformationFormReview;
