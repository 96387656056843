import React, { useState } from "react";
import { FormControlLabel, Grid, Select, Switch, Tooltip } from "@mui/material";
import { Field } from "redux-form";
import CheckboxInput from "../../common/form/CheckboxInput";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { setMyValue } from "../../store/actions/additionalDocumentRequired/additionalDocumentRequired";
import { useSelector } from "react-redux";
import { connect } from "react-redux";

const CheckBoxProgramChecklist = ({
  onSwitchChangeToGrandparent,
  fieldName,
  fieldValues,
  item,
  info,
  program,
  disabled,
  onSwichChange
}) => {
  const [customChecklist, setCustomChecklist] = useState(false);
  console.log(fieldName, "fieldName");
  console.log(item, "item");
  const [check, setCheck] = useState(false);
  const [checked, setChecked] = useState(false);

  const [checkboxValue, setCheckboxValue] = useState(false);
  const handleSwitchChange = (switchValue) => {
    // This function will receive the 'Switch' value from the child component
    console.log("Switch value in parent:", switchValue);
    // You can store the 'switchValue' in the parent's state or use it for form submission.
    onSwitchChangeToGrandparent(switchValue);
  };
  const handleChanges = () => {
    setChecked(!checked);
    onSwichChange(!checked);
  };
  const handleCheckboxChange = (value) => {
    setCheckboxValue(value);
    // Now 'checkboxValue' in the parent component will hold the value of the Checkbox.
    // You can perform any further actions or state updates based on this value.
  };
 // console.log(checkboxValue, "checkboxValuesss")
  const dispatch = useDispatch();
  const myValue = useSelector((state) => state.myValue.myValue);
  console.log(myValue, "MY");
  console.log(checkboxValue, "lkj");
  return (
    <Grid item>
      <Grid item container alignItems={"center"}>
        <Grid item style={{ color: "primary" }}>
          <Field
            name={fieldName}
            required
            component={CheckboxInput}
            variant={"outlined"}
            disabled={disabled}
            check={check}
            setCheck={setCheck}
            program={program}
            fieldValues={fieldValues}
            fieldName={fieldName}
            onSwitchChange={handleSwitchChange}
            onCheckboxChange={handleCheckboxChange}
          />
        </Grid>

        <Grid item>
          <Typography variant={"caption"}>{info}</Typography>
        </Grid>

      </Grid>
      {/* <div>
        {fieldName == "additionalDocumentsForm" && checkboxValue== true ? (
          <Tooltip title="Make Additional Documents Required" arrow>
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={handleChanges}
                  name="mySwitch"
                  color="primary" // You can change the color to 'secondary', 'default', etc.
                />
              }
              label={ <span style={{fontSize:'12px'}}> Make Additional Documentation Required</span>}
              // This is the label you want to add
            />
          </Tooltip>
        ) : (
          ""
        )}
      </div> */}
    </Grid>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    myValue: state.myValue.myValue,
  };
};
export default connect(mapStateToProps, { setMyValue })(
  CheckBoxProgramChecklist
);
