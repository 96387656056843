import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import { formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { combineValidators, isRequired } from 'revalidate';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextInputForm from '../../../../../forms/TextInputForm';
import SelectInputForm from '../../../../../forms/SelectInputForm';
import {
  submitCovidProtocolForm,
  updateCovidProtocolForm,
} from '../../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import { format } from 'date-fns';
import { closeDialog } from '../../../../../../store/actions/dialogActions/dialogActions';
import toast, { Toaster } from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: '0.4em',
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
    },
  },

  continueButton: {
    marginLeft: '0.4em',
    backgroundColor: '#1976d2',
    color: 'white !Important',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
      //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    },
  },
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  questionText: {
    fontWeight: 600,
    textAlign: 'right',
    paddingRight: '1em',
  },
}));

const validate = combineValidators({
  covidVaccine: isRequired({ message: 'Required' }),
  covidInjection1: isRequired({ message: 'Required' }),
  willRecieveVaccine: isRequired({ message: 'Required' }),
});
const arr = {
  covidVaccine: '',
  covidInjection1: '',
  willRecieveVaccine: '',
};

const actions = {
  submitCovidProtocolForm,
  updateCovidProtocolForm,
};

const mapStateToProps = (state) => {
  const questionSelector = formValueSelector('covidProtocolForm');
  const fieldValues = questionSelector(state, 'id', 'covidVaccine');
  return {
    fieldValues,
    initialValues:
      state.applicantPrograms.registeredProgram[0].covidProtocolFormDetails,
    currentValues: getFormValues('covidProtocolForm')(state),
  };
};

const CovidProtocolForm = ({
  fieldValues,
  registeredProgramId,
  submitCovidProtocolForm,
  program,
  handleCloseForm,
  handleSubmit,
  submitting,
  handleSetForm,
  updateCovidProtocolForm,
  currentValues,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [covidVaccinated, setCovidVaccinated] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setCovidVaccinated(fieldValues.covidVaccine);
  }, [fieldValues]);

  const programsAllThings = [
    'applicationInterests',
    'personalInformationForm',
    'familyInformationForm',
    'academicInformationForm',
    // 'recommendationsRequired',
    'recommendersForm',
    'extracurricularForm',
    'covidProtocolForm',
    'resumeForm',
    'transcriptForm',
    'shortResponsesForm',
    'scholarshipForm',
    'taxForm',
    'additionalDocumentsForm',
    'confirmationForm',
  ];

  const programAllThingsValue = {
    applicationInterests: 'applicationInterests',
    personalInformationForm: 'personalInformationForm',
    familyInformationForm: 'familyInformationForm',
    academicInformationForm: 'academicInformationForm',
    // recommendationsRequired: 'recommendationsRequired',
    recommendersForm: 'recommendersForm',
    extracurricularForm: 'extracurricularForm',
    covidProtocolForm: 'covidProtocolForm',
    resumeForm: 'resumeForm',
    transcriptForm: 'transcriptForm',
    shortResponsesForm: 'shortResponsesForm',
    scholarshipForm: 'scholarshipForm',
    taxForm: 'taxForm',
    additionalDocumentsForm: 'additionalDocumentsForm',
    confirmationForm: 'confirmationForm',
  };

  let dataToCheck = programsAllThings
    .filter((item) => program[item])
    .map((item) => programAllThingsValue[item]);

  const handleFormSubmit = async (values) => {
    try {
      await submitCovidProtocolForm(registeredProgramId, values);
      handleCloseForm();
      const indexOfPersonalInfoForm = dataToCheck.indexOf('covidProtocolForm');
      if (
        indexOfPersonalInfoForm !== -1 &&
        indexOfPersonalInfoForm < dataToCheck.length - 1
      ) {
        const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
        handleSetForm(nextForm);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormSave = async (values) => {
    console.log(values, 'values');
    if (values == undefined) {
      return;
    }
    if (values.covidInjection1 == undefined) {
      values.covidInjection1 = '';
    }

    function hasAllKeys(arr, values) {
      for (const key in arr) {
        if (arr.hasOwnProperty(key) && !values.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    }

    // Check if all keys from object A exist in object B

    const conditionMet = hasAllKeys(arr, values);
    if (conditionMet) {
      values.allRequiredDocumentFilled = true;
    }
    if (!conditionMet) {
      values.allRequiredDocumentFilled = false;
    }
    try {
      await updateCovidProtocolForm(registeredProgramId, values);
      handleCloseForm();
    } catch (error) {
      console.log(error);
    }
  };
  const notify = () => toast.success('Progress Autosaved.');

  const handleFormAutoSave = async (values) => {
    console.log(values, 'values');
    if (values == undefined) {
      return;
    }
    if (values.covidInjection1 == undefined) {
      values.covidInjection1 = '';
    }

    function hasAllKeys(arr, values) {
      for (const key in arr) {
        if (arr.hasOwnProperty(key) && !values.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    }

    // Check if all keys from object A exist in object B

    const conditionMet = hasAllKeys(arr, values);
    if (conditionMet) {
      values.allRequiredDocumentFilled = true;
    }
    if (!conditionMet) {
      values.allRequiredDocumentFilled = false;
    }
    try {
      await updateCovidProtocolForm(registeredProgramId, values);
      // handleCloseForm();
      notify();
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(closeDialog());
  };
  const saveInterval = 5 * 60 * 1000;
  useEffect(() => {
    const saveFormInterval = setInterval(() => {
      handleFormAutoSave(currentValues);
    }, saveInterval);
    return () => clearInterval(saveFormInterval);
  }, [currentValues]);
  return (
    <>
      <Toaster />

      <form autoComplete={'off'} onSubmit={handleSubmit(handleFormSubmit)}>
        <Typography
          variant={'h5'}
          style={{ fontWeight: 600, marginTop: '1em', marginBottom: '1em' }}
        >
          Covid Protocol
        </Typography>
        <Typography
          variant={'h5'}
          style={{ fontWeight: 600, marginTop: '1em', marginBottom: '1em' }}
        >
          COVID-19 and Campus Updates
        </Typography>
        <Typography style={{ marginTop: '1em', marginBottom: '1em' }}>
          LEAD monitors the CDC guidelines and works closely with its Host
          Institutions to ensure the health and safety of all students as the
          number one priority of the organization for our LEAD Programs.
        </Typography>
        <Typography
          variant={'h5'}
          style={{ fontWeight: 600, marginTop: '1em', marginBottom: '1em' }}
        >
          Will students need a COVID vaccine to participate in the residential
          all LEAD programs?
        </Typography>
        <Typography style={{ marginTop: '1em', marginBottom: '1em' }}>
          Unfortunately, COVID is still with us. We need to take precautions to
          ensure that we can continue to have an opportunity to make the most of
          our Summer Session. We want to also take this opportunity to emphasize
          that our host institutions have strict protocols and guidelines for
          the health and safety of all students and faculty.
        </Typography>
        <Typography style={{ marginTop: '1em', marginBottom: '1em' }}>
          LEAD will require all participating students to show proof of
          vaccination to maximize the health and full experience of the
          residential program and to avoid, if at all possible, to face
          quarantines, limited access to public buildings, campus facilities, or
          housing services and other significant barriers.
        </Typography>
        <Typography style={{ marginTop: '1em', marginBottom: '1em' }}>
          Participating LEAD programd, students are required to understand and
          comply with the terms of participation, codes of conduct, and
          emergency procedures of our program. If our participating host
          university or travel provider requires a COVID-19 vaccination,
          students should comply with that requirement. LEAD students will
          receive their host institution’s requirements in their program
          acceptance package. LEAD students should monitor program expectations
          and terms of participation.
          <br></br>
          <br></br>
          LEAD strongly recommends all students receive a COVID vaccine if
          available to protect their host community and themselves. LEAD also
          anticipates additional vaccine requirements throughout its program
          sites.
          <br></br>
          <br></br>
          LEAD will notify students if we become aware of changes in the terms
          of participation for their program. Alternatively, we will pivot to an
          online program as we have accomplished for summers 2020 and 2021.
        </Typography>

        <Typography
          variant={'h6'}
          style={{ fontWeight: 600, marginTop: '1em', marginBottom: '1em' }}
        >
          Reference and Recent Communications
        </Typography>
        <Typography style={{ marginTop: '1em', marginBottom: '1em' }}>
          We closely monitor local health and safety conditions at all host
          sites and make programmatic decisions with this information. The
          Health and Safety Measures for each of our Host Institutions are
          listed below.
        </Typography>
        <Typography
          variant={'h6'}
          style={{ fontWeight: 600, marginTop: '1em', marginBottom: '1em' }}
        >
          COVID Health and Safety Measures
        </Typography>
        <Typography style={{ marginTop: '1em', marginBottom: '1em' }}>
          • Bethune-Cookman University <br></br>o
          https://www.cookman.edu/covid-19/index.html<br></br>• Duke University{' '}
          <br></br>o https://coronavirus.duke.edu/<br></br>• Fisk University
          <br></br>o https://www.fisk.edu/covid-19-communication-hub/<br></br>•
          Lehigh University <br></br>o https://coronavirus.lehigh.edu/<br></br>•
          Morehouse School of Medicine and Morehouse College<br></br>o
          https://morehouse.edu/life/covid-19/<br></br>• Northwestern University{' '}
          <br></br>o https://www.northwestern.edu/coronavirus-covid-19-updates/
          <br></br>• St. Francis College<br></br>o
          https://www.sfc.edu/why-sfc/back-to-brooklyn/covid-19-vaccine-requirements
          <br></br>• University of Pittsburgh <br></br>o
          https://www.policy.pitt.edu/university-policies-and-procedures/covid-19-standards-and-guidelines
          <br></br>• Babson College <br></br>o
          https://www.babson.edu/emergency-preparedness/return-to-campus/covid-dashboard/{' '}
          <br></br>
        </Typography>
        <Typography
          variant={'h6'}
          style={{ fontWeight: 600, marginTop: '1em', marginBottom: '1em' }}
        >
          CDC Guidelines
        </Typography>
        <Typography style={{ marginTop: '1em', marginBottom: '1em' }}>
          Given new evidence on the B.1.617.2 (Delta) variant, CDC has updated
          the guidance for fully vaccinated people. CDC recommends universal
          indoor masking for all teachers, staff, students, and visitors to K-12
          schools, regardless of vaccination status. Children should return to
          full-time in-person learning in the fall with layered prevention
          strategies in place.
          <br></br>
          <br></br>
          To maximize protection from the Delta variant / Omicron variant and
          prevent possibly spreading it to others, get vaccinated as soon as you
          can and wear a mask indoors in public if you are in an area of
          substantial or high transmission.
        </Typography>

        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid item container direction={'column'}>
              <Typography
                variant={'h6'}
                style={{
                  fontWeight: 600,
                  marginTop: '1em',
                  marginBottom: '1em',
                }}
              >
                You will be required to provide a copy of your vaccination card
              </Typography>
              <SelectInputForm
                fieldName={'covidVaccine'}
                question={
                  'Have you received a Covid-19 vaccination or Booster shot?'
                }
                options={[{ value: '' }, { value: 'Yes' }, { value: 'No' }]}
              />

              {covidVaccinated === 'Yes' && (
                <>
                  <TextInputForm
                    fieldName={'covidInjection1'}
                    question={'1st Dose Date:'}
                    placeholder={true}
                    placeholderText={'MM/YYYY'}
                  />
                  <TextInputForm
                    fieldName={'covidInjection2'}
                    question={'2nd Dose Date (Optional):'}
                    placeholder={true}
                    placeholderText={'MM/YYYY'}
                  />
                  <TextInputForm
                    fieldName={'boosterShot'}
                    question={'Booster Shot Date (Optional):'}
                    placeholder={true}
                    placeholderText={'MM/YYYY'}
                  />
                </>
              )}
              {covidVaccinated === 'No' && (
                <SelectInputForm
                  fieldName={'willRecieveVaccine'}
                  question={`Will you received a Covid-19 vaccination or Booster shot by ${
                    program.depositDeadline &&
                    format(new Date(program.depositDeadline), 'MM/dd/yyyy')
                  }?`}
                  options={[{ value: '' }, { value: 'Yes' }, { value: 'No' }]}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        {/*BUTTONS*/}
        <Grid item style={{ marginTop: '1.5em' }}>
          <Grid item container>
            <Grid item style={{ marginLeft: 'auto' }}>
              <Button
                variant={'outlined'}
                color={'primary'}
                className={classes.button}
                onClick={() => handleFormSave(currentValues)}
              >
                Save for Later
              </Button>
              <Button
                variant={'outlined'}
                color={'primary'}
                className={classes.continueButton}
                type={'submit'}
                disabled={submitting}
              >
                Continue{' '}
                <span>
                  <strong> &#8594;</strong>
                </span>
              </Button>
              <Button
                variant={'outlined'}
                color={'secondary'}
                className={classes.button}
                onClick={() => handleCloseForm()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: 'covidProtocolForm',
    enableReinitialize: true,
    validate,
  })(CovidProtocolForm)
);
