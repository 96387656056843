import React, { Fragment } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { Field, FieldArray } from 'redux-form';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import TextInputFieldArray from '../../../../../common/form/TextInputFieldArray';
import './scholarShip.css';

const useStyles = makeStyles((theme) => ({
  questionText: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
}));

const ScholarshipChildrensExpensesForm = () => {
  const classes = useStyles();
  const theme = useTheme();

  const amountRequired = (value) => (value ? undefined : 'Required');
  const descriptionRequired = (value) => (value ? undefined : 'Required');

  const expensesField = ({ fields, meta: { error, submitFailed } }) => (
    <Fragment>
      {fields.length < 1 && (
        <Grid
          item
          container
          className={classes.fieldContainer}
          alignItems={'center'}
        >
          <Grid item lg={2} md={2} sm={2} xs={2}>
            {/*BLANK*/}
          </Grid>
          <Grid item lg={10} md={10} sm={10} xs={10}>
            <Grid item container alignItems={'center'}>
              <Grid item>
                <IconButton
                  aria-label="add expense"
                  onClick={() => {
                    fields.push({});
                  }}
                >
                  <AddCircleIcon color={'primary'} />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant={'subtitle1'}>
                  Add additional expense
                </Typography>
              </Grid>
              <Typography
                style={{
                  color: 'red',
                  padding: '2px',
                }}
              >
                Required
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {fields.map((childrensExpense, index) => (
        <Fragment key={index}>
          <Grid
            item
            container
            style={{ marginTop: '1.5em' }}
            alignItems={'center'}
          >
            <Grid item lg={2} md={2} sm={2} xs={2}>
              <Typography className={classes.questionText}>
                Children's Expense #{index + 1}
              </Typography>
            </Grid>
            <Grid item lg={10} md={10} sm={10} xs={10}>
              {/*BLANK*/}
            </Grid>
          </Grid>
          {/*FIELDS*/}
          <Grid
            item
            container
            style={{ marginTop: '1em' }}
            alignItems={'center'}
          >
            <Grid item lg={2} md={2} sm={2} xs={2}>
              <Grid item container direction={'column'}>
                <Grid item>
                  <Typography className={classes.questionText}>
                    Amount
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={10} md={10} sm={10} xs={10}>
              <Grid item style={{ width: '50%' }}>
                <Grid item container direction={'column'}>
                  <Grid item className={classes.fieldWrapper}>
                    <Field
                      name={`${childrensExpense}.amount`}
                      component={TextInputFieldArray}
                      type={'text'}
                      variant={'outlined'}
                      // validate={amountRequired}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            className={classes.fieldContainer}
            alignItems={'center'}
          >
            <Grid item lg={2} md={2} sm={2} xs={2}>
              <Grid item container direction={'column'}>
                <Grid item>
                  <Typography className={classes.questionText}>
                    Description
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={10} md={10} sm={10} xs={10}>
              <Grid item style={{ width: '50%' }}>
                <Grid item container direction={'column'}>
                  <Grid item className={classes.fieldWrapper}>
                    <Field
                      name={`${childrensExpense}.description`}
                      component={TextInputFieldArray}
                      type={'text'}
                      variant={'outlined'}
                      // validate={descriptionRequired}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/*ACTION BUTTONS*/}
          <Grid
            item
            container
            className={classes.fieldContainer}
            alignItems={'center'}
          >
            <Grid item lg={2} md={2} sm={2} xs={2}>
              {/*BLANK*/}
            </Grid>
            <Grid item lg={10} md={10} sm={10} xs={10}>
              <Grid item container direction={'column'}>
                <Grid item>
                  <Grid item container alignItems={'center'}>
                    <Grid item>
                      <IconButton
                        aria-label="add debt"
                        onClick={() => {
                          fields.push({});
                        }}
                      >
                        <AddCircleIcon color={'primary'} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Typography variant={'subtitle1'}>
                        Add additional expense
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid item container alignItems={'center'}>
                    <Grid item>
                      <IconButton
                        aria-label="delete debt"
                        onClick={() => fields.remove(index)}
                      >
                        <CancelIcon
                          style={{ color: theme.palette.error.main }}
                        />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Typography variant={'subtitle1'}>
                        Delete additional expense
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      ))}
    </Fragment>
  );
  return (
    <Grid container justifyContent={'center'} direction={'column'}>
      <FieldArray name="additionalExpense" component={expensesField} />
    </Grid>
  );
};

export default ScholarshipChildrensExpensesForm;
