import React, { useEffect } from 'react';

const ClearCache = () => {
  useEffect(() => {
    const clearCache = async () => {
        window.caches
        .keys()
        .then(cacheNames => {
          return Promise.all(
            cacheNames.map(cacheName => {
              return window.caches.delete(cacheName);
            })
          );
        })
        .catch(error => {
          console.error('Error clearing cache:', error);
        });
    };

    clearCache();
  }, []);

  return <></>;
};

export default ClearCache;
