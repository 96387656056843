import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import ReviewProgramItem from "./item/ReviewProgramItem";
import Iframe from 'react-iframe';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  answerText: {
    fontWeight: 600,
  },
}));

const GovernmentIdFormReview = ({ governmentIdForm }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction={"column"}
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
    >
      {/*APPLICATION FEE*/}
      <Grid item>
        <Typography className={classes.title}>Government ID & Headshot</Typography>
      </Grid>

      {/*APPLICATION FEE*/}
      {Array.isArray(governmentIdForm)
        ? governmentIdForm.map((form, i) => (
           <> <ReviewProgramItem
              key={i}
              question={form.originalFileName}
              value={
                <a
                  href={form.fileURL}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  Open in New Tab
                </a>
              }
             
            />
             <Iframe url={form.fileURL}
                  width="100%"
                  height="800px"
                  id=""
                  className=""
                  display="block"
                  position="relative" /></>
          ))
        : Object.keys(governmentIdForm).map((key) =>
            governmentIdForm[key].map((form,i) => (
            <>  <ReviewProgramItem
                key={i}
                question={form.originalFileName}
                value={
                  <a
                    href={form.fileURL}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                  >
                    Open in New Tab
                  </a>
                }
              />
              <Iframe url={form.fileURL}
              width="100%"
              height="800px"
              id=""
              className=""
              display="block"
              position="relative" /></>
            ))
          )}
    </Grid>
  );
};

export default GovernmentIdFormReview;
