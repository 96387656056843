import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import ReviewProgramItem from './item/ReviewProgramItem';
import Iframe from 'react-iframe';
import { Form } from 'redux-form';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    letterSpacing: '0.1px',
    color: '#171725',
  },
  answerText: {
    fontWeight: 600,
  },
}));

const ResumeFormReview = ({ resumeForm }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction={'column'}
      style={{ paddingTop: '0.5em', paddingBottom: '0.5em' }}
    >
      {/*RESUME*/}
      {console.log(resumeForm, 'resumeFormmmm')}
      {console.log(resumeForm?.[0]?.ResumeId?.[0], 'errrrr')}
      <Grid item>
        <Typography className={classes.title}>Resume</Typography>
      </Grid>

      {/**/}
      {resumeForm[0]?.ResumeId?.[0]
        ? resumeForm?.[0]?.ResumeId?.map((form) => (
            <>
              <ReviewProgramItem
                key={form.id}
                // key={(form) => form.ResumeId[0].id||form.id}
                question={form.originalFileName}
                // question={(form) =>form.ResumeId[0].originalFileName||form.originalFileName}
                value={
                  <a
                    href={form.fileURL}
                    // href={(form) => form.ResumeId[0].fileURL||form.fileURL}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                  >
                    Open in New Tab
                  </a>
                }
              />
              <Iframe
                url={form.fileURL}
                // url={(form) => form.ResumeId[0].fileURL || form.fileURL}
                width="100%"
                height="800px"
                id=""
                className=""
                display="block"
                position="relative"
              />
            </>
          ))
        : resumeForm?.map((form) => (
            <>
              {console.log(form, 'FORM')}
              <ReviewProgramItem
                key={form.id}
                // key={(form) => form.ResumeId[0].id||form.id}
                question={form.originalFileName}
                // question={(form) =>form.ResumeId[0].originalFileName||form.originalFileName}
                value={
                  <a
                    href={form.fileURL}
                    // href={(form) => form.ResumeId[0].fileURL||form.fileURL}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                  >
                    Open in New Tab
                  </a>
                }
              />

              <Iframe
                url={form.fileURL}
                // url={(form) => form.ResumeId[0].fileURL || form.fileURL}
                width="100%"
                height="800px"
                id=""
                className=""
                display="block"
                position="relative"
              />
            </>
          ))}
    </Grid>
  );
};

export default ResumeFormReview;
