import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { closeDialog } from '../../store/actions/dialogActions/dialogActions';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import DialogContentText from '@mui/material/DialogContentText';
import RegisterForm from '../../ui/forms/auth/RegisterForm';
import Typography from '@mui/material/Typography';
import './RegisterDialog.css'

import {

  Button,
 
} from '@mui/material';

const actions = {
  closeDialog,
};

const RegisterDialog = ({ closeDialog, alert }) => {
  const handleClose = () => {
    closeDialog();
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <Grid container direction={'column'} className='request-info-dialog-container'>
        <Grid item>
          <DialogTitle align={'center'} style={{ fontFamily: 'Poppins' }}>
            Request Information
          </DialogTitle>
          <Typography
           style={{paddingBottom: '20px'}}
          >
           To learn more about LEADing for Life or a specific program, fill out the form below and check your email for information on next steps.
          </Typography>
          <Typography 
           style={{
            paddingBottom: '20px',
            borderBottomStyle: 'solid',
            borderColor: '#e38e21',
            marginBottom: '10px',
          }}>
          * Indicates a required field
            </Typography>
        </Grid>
        {alert && (
          <Grid item>
            <DialogContentText style={{ fontSize: '0.9em' }} align={'center'}>
              {alert}
            </DialogContentText>
          </Grid>
        )}
        <Grid item>
          <RegisterForm />
        </Grid>
        <Button
          onClick={() => closeDialog()}
       //   style={{ color: theme.palette.error.main }}
        >
          Close
        </Button>
      </Grid>
    </Dialog>
  );
};

export default connect(null, actions)(RegisterDialog);
