import { Button, Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import { useHistory, useLocation } from 'react-router-dom';
import CheckBoxForm from '../../../../forms/CheckBoxForm';
import { useEffect } from 'react';
const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: '0.4em',
  },
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  formName: {
    // color: '#696974',
    fontWeight: '900',
    fontFamily: 'Roboto',
    marginTop: '22px',
    fontSize: '32px',
    lineHeight: '72px',
    letter: '0.2px',
  },
  formNamess: {
    fontWeight: '600',
    fontFamily: 'Roboto',
    marginTop: '22px',

    fontSize: '22px',
    lineHeight: '72px',
    letter: '0.2px',
  },
  formText: {
    // color: '#696974',
    // backgroundColor: 'lightyellow',
    backgroundColor: '#ffc627',

    padding: '12px',
    fontWeight: '400',
    width: '412px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  formTexts: {
    color: '#696974',
    backgroundColor: 'yellow',
    border: '2px',
    marginTop: '8px',
    fontWeight: '400',
    width: '412px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  formQuestionText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  custombox: {
    border: '1px solid white',
    padding: '20px',
    fontFamily: 'Roboto',
    // backgroundColor: ' #f0f0f0',
    width: '80%',
    margin: '20px 0px 20px 0px',
    borderColor: 'black',
  },
}));

const ConfirmationFormRegistraion = () => {
  const history = useHistory();
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ padding: '40px', display: 'flex', marginTop: '20px', paddingTop:'160px' }}>
      {/*CONFIRMATION*/}
      <div style={{width:'75%'}}>
        {/* left side */}
        <h1 className={classes.formName}>
          Get ready for the next turn in your journey
        </h1>
        <h3 className={classes.formText}>
          Thank you for your interest in our LEADing For Life Programs
        </h3>

        <div className={classes.custombox}>
          <h2
            style={{
              color: 'black',
              padding: '4px',
              fontFamily: 'Roboto',
            }}
          >
            Want more info ?
          </h2>
          <p style={{ padding: '4px', margin: '3px', fontSize: '16px' }}>
          Explore a selection of the programs available in our catalog.</p>
          <button
           onClick={() => history.push(`/program`)}
            style={{
              backgroundColor: '#ffc627',
              borderRadius: '12px',
              padding: '12px',
              margin: '12px',
              width: '100px',

              //   border:'none',
              //   border: '1px solid black',
              border: 'none',

            }}
          >
            Explore Programs
          </button>
        </div>
        <div>
          <h2 style={{ fontSize: '22px', fontFamily: 'Roboto' }}>
            {' '}
            Successful applicants typically demonstrate:           </h2>
          <ul style={{ marginTop: '4px' }}>
            <li style={{ lineHeight: 2 }}>
            Academic average of 3.0+ unweighted GPA, or equivalent  
            </li>
            <li style={{ lineHeight: 2 }}>
            Thoughtful, well-written essays that express interest in the program content. 
            </li>
            <li style={{ lineHeight: 2 }}>
              {' '}
              Recommendation(s) that convey academic performance and potential. 
            </li>
            <li style={{ lineHeight: 2 }}>
              {' '}
              An array of extracurricular activities and/or personal achievements that denote intellectual curiosity, ability to collaborate, initiative and ambition, or diversity of interests and ideas. 
            </li>
           
          </ul>
       
        </div>
      </div>
      <div>
        {/* right side */}
        <div className={classes.formNamess}>Get your questions answered :</div>

        <div>
          <h3
            style={{
              fontFamily: 'initial',
              fontStyle: 'normal',
            }}
          >
            {' '}
            Have a question? Chat with us
          </h3>
          <button
            style={{
              padding: '14px',
              margin: '4px',
              width: '100px',
              //   border: '1px solid black',
              border: 'none',

              borderRadius: '12px',
              backgroundColor: '#ffc627',
            }}
            onClick={() =>
              (window.location = 'mailto:info@leadprogram.org')
            }
          >
            Email Us
          </button>
        </div>
     { /*  <div style={{ paddingTop: '40px' }}>
          <h3
            style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
            }}
          >
            {' '}
            Find answers yourself!
          </h3>
          <button
            style={{
              padding: '14px',
              margin: '4px',
              width: '100px',
              //   border: '1px solid black',
              border: 'none',

              borderRadius: '12px',
              backgroundColor: '#ffc627',
            }}
            onClick={() =>
              history.push(
                `/applicant/faqs/view?id=${profile.uid}`
              )
            }
          >
            FAQ
          </button>
        </div>*/}
        <div style={{ paddingTop: '40px' }}>
          <h3
            style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
            }}
          >
            {' '}
            Ready to begin? Start now
          </h3>
          <button
            style={{
              padding: '14px',
              margin: '4px',
              border: 'none',
              width: '100px',
              //   border: '1px solid black',

              borderRadius: '12px',
              backgroundColor: '#ffc627',
            }}
            onClick={() => history.push(`/program`)}
          >
            Apply Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationFormRegistraion;
