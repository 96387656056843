import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, MenuItem } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

import Typography from '@mui/material/Typography';

import { connect } from 'react-redux';
import {
  fetchApplicantProgram,
  fetchApplicantRegisteredPrograms,
  programRegister,
  programRegisterAndSignup,
} from '../store/actions/applicantActions/programActions/applicantProgramActions';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../common/form/TextInput';

import Button from '@mui/material/Button';
import { combineValidators, isRequired } from 'revalidate';
import DatePickerInput from '../common/form/DatePickerInput';
import { format, getUnixTime, parseISO } from 'date-fns';
import SelectInput from '../common/form/SelectInput';
import GradeLookup from './admin/programs/GradeLookup';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import ViewProgramItem from '../ui/programs/ViewProgramItem';
import { formValueSelector } from 'redux-form';
import StateLookup from '../ui/forms/formLookups/StateLookup';
import CheckboxInput from '../common/form/CheckboxInput';
import Paper from '@mui/material/Paper';
import headerBanner from '../assets/landingPage/LFL2.jpg';
//import regBanner from '../assets/landingPage/LFL11.jpg';
import regBanner from '../assets/landingPage/LFL 5.jpg';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionActions from '@mui/material/AccordionActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './viewProgram.css';

const useStyles = makeStyles((theme) => ({
  headlineContainer: {
    position: 'relative',
    // paddingTop: '116px',
    // paddingBottom: '116px',
    textAlign: 'center',
    left: '-2px',
    height: '512px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    //  backgroundImage:'url('+ headerBanner+ ')',
    // background: 'linear-gradient(195.34deg, #65A65A 0%, #3178A5 115.17%)',
    backgroundColor: '#fff',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the color and opacity as needed
  },
  /* headerImage: {
   // width: '100%',
    height: 'inherit',
    objectFit: 'cover',
  //  position:"absolute",
    [theme.breakpoints.down('sm')]: {
      height: '500px',
    },
  },*/
  headerTextBox: {
    width: '100%',
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    height: '200px',
  },
  headerImage: {
    // backgroundimage:'url('+program.programImageURL+ ')',
    backgroundAttachment: 'scroll',
    height: '512px',
    minHeight: 'var(--header-image-min-height,0)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'top',
  },
  headlineText: {
    color: 'white',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '40px',
    lineHeight: '72px',
    letter: '0.2px',
    paddingTop: '116px',
    textAlign: 'center',
    paddingBottom: '116px',
  },
  headerTextLeftCont: {
    marginLeft: '2rem !important',
    marginRight: '2rem !important',
    marginBottom: '2rem !important',
  },
  headerTextRightCont: {
    marginLeft: '1.5rem !important;',
    marginRight: '1.5rem !important',
    marginBottom: '2rem !important',
    marginTop: '2rem !important',
  },
  factCard: {
    backgroundColor: '#f1f1f1!important',
    borderRadius: '3px',
    borderLeft: '4px solid #FF9900!important',
    minHeight: '310px',
    maxWidth: '330px',
  },
  factCardInner: {
    paddingLeft: '2rem !important',
    paddingRight: '2rem !important',
    paddingBottom: '3rem !important',
    paddingTop: '3rem !important',
  },
  quickFacts: {
    fontWeight: '700',
    padding: '4px',
    fontSize: '14px',
    lineHeight: '1.4',
    color: '#fff !important',
    paddingLeft: '0.5rem !important',
    paddingRight: '0.5rem !important',
    marginBottom: '1.5rem !important',
    display: 'inline-block !important',
    backgroundColor: '#191919',
  },
  headlineText2: {
    fontSize: '48px',
    color: '#191919!important',
    paddingTop: '2rem!important',
    marginBottom: '1.5rem!important',
    lineHeight: '1.2',
  },
  detailsContainer: {
    paddingTop: '25px',
    // paddingBottom: '461px',
    paddingBottom: '10px',
    paddingLeft: '155px',
    paddingRight: '155px',
    // backgroundColor: '#E5E5E5',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
    },
  },

  iconButton: {
    color: '#FF9900',
    border: '1px solid #FF9900',
  },
  headText: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '36px',

    color: '#181818',
  },
  text: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.1px',
  },

  moreText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.1px',

    cursor: 'pointer',
  },
  logo: {
    borderRadius: '100%',
    width: '200px',
    height: '200px',
  },

  button: {
    width: '220px',
    height: '38px',
    borderRadius: '10px',
    backgroundColor: '#35BA35',
    borderColor: '#35BA35',
    fontWeight: '600',
    color: 'white',
    textTransform: 'none',
    '&:hover': {
      color: 'white',
      backgroundColor: '#35BA35',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  startButton: {
    width: '220px',
    height: '48px',
    borderRadius: '28px',
    backgroundColor: '#35BA35',
    borderColor: '#35BA35',
    fontWeight: '600',
    color: 'white',
    marginBottom: '30px',
    textTransform: 'none',
    '&:hover': {
      color: 'white',
      backgroundColor: '#35BA35',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  fieldWrapper: {
    paddingTop: '0.3em',
    paddingBottom: '0.3em',
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 600,
    fontSize: '18px',
    marginBottom: '15px',
  },
}));

const validate = combineValidators({
  firstName: isRequired({ message: 'First Name is required' }),
  lastName: isRequired({ message: 'Last Name is required' }),
  dob: isRequired({ message: 'Date of Birth is required' }),
  gradeLevel: isRequired({ message: 'Grade Level is required' }),
  email: isRequired({ message: 'Email Address is required' }),
  password: isRequired({ message: 'Password is required' }),
  parentName: isRequired({ message: 'Parent Name is required' }),
  parentEmail: isRequired({ message: 'Parent Email is required' }),
  parentNumber: isRequired({ message: 'Parent Number is required' }),
  counselorName: isRequired({ message: 'Counselor Name is required' }),
  counselorEmail: isRequired({ message: 'Counselor Email is required' }),
  address1: isRequired({ message: 'Address is required' }),
  city: isRequired({ message: 'City is required' }),
  state: isRequired({ message: 'State is required' }),
  zipCode: isRequired({ message: 'Zip Code is required' }),
  graduationMonthYear: isRequired({
    message: 'Graduation Month Year is required',
  }),
});

const actions = {
  fetchApplicantProgram,
  fetchApplicantRegisteredPrograms,
  programRegisterAndSignup,
  programRegister,
};

const mapStateToProps = (state) => {
  let program = [];

  let institutions = [];

  let programs = [];

  if (
    state.applicantPrograms.program &&
    state.applicantPrograms.program.length > 0
  ) {
    program = state.applicantPrograms.program[0];
    institutions = state.applicantPrograms.program[0].programInstitutions;
  }

  if (
    state.applicantPrograms.allRegisteredPrograms &&
    state.applicantPrograms.allRegisteredPrograms.length > 0
  ) {
    programs = state.applicantPrograms.allRegisteredPrograms;
  }

  const questionSelector = formValueSelector('signUp');
  const fieldValues = questionSelector(state, 'mobileNumber', 'parentNumber');

  return {
    program,
    programs,
    institutions,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    fieldValues,
  };
};

const normalizePhoneNumber = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;
  if (cvLength < 4) return currentValue;
  if (cvLength < 7)
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
    3,
    6
  )}-${currentValue.slice(6, 10)}`;
};

const ViewProgram = ({
  auth,
  profile,
  fetchApplicantProgram,
  fetchApplicantRegisteredPrograms,
  programRegisterAndSignup,
  programRegister,
  program,
  programs,
  institutions,
  handleSubmit,
  submitting,
  fieldValues,
  change,
}) => {
  const classes = useStyles();

  const grades = GradeLookup.grades;

  const [authenticated, setAuthenticated] = useState(false);
  const [isApplicant, setIsApplicant] = useState(false);

  const [error, setError] = useState(null);
  const [gradeError, setGradeError] = useState(null);
  console.log(program, 'programs');
  const [expand, setExpand] = useState(false);
  const [applicationOpen, setApplicationOpen] = useState(false);
  const hasImage = /<img\b.*?>/i.test(program?.programDescription);

  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get('id');
  const register = queryParams.get('register');
  console.log(register, 'register');
  const states = StateLookup.states;

  useEffect(() => {
    fetchApplicantProgram(id);
  }, [id, fetchApplicantProgram]);

  useEffect(() => {
    fetchApplicantRegisteredPrograms(id, profile.uid, id, true);

    if (auth.isLoaded === true && auth.isEmpty === false) {
      setAuthenticated(true);
    }
    if (profile.isApplicant === true) {
      setIsApplicant(true);
    }
    const unixTime = getUnixTime(new Date());

    if (unixTime < program.applicationOpenDate) {
      setApplicationOpen(false);
    } else {
      setApplicationOpen(true);
    }
  }, [fetchApplicantRegisteredPrograms, program, id, auth, profile]);

  useEffect(() => {
    change('mobileNumber', normalizePhoneNumber(fieldValues.mobileNumber));
  }, [fieldValues.mobileNumber, change]);

  useEffect(() => {
    change('parentNumber', normalizePhoneNumber(fieldValues.parentNumber));
  }, [fieldValues.parentNumber, change]);

  const handleRegisterAndSignup = async (values) => {
   // console.log(values, 'valuesssss');
    grades.map(async (grade) => {
      if (program[grade.fieldName]) {
        if (values.gradeLevel === grade.value) {
          let registeredProgram = await programRegisterAndSignup(values, id);

          if (registeredProgram === 'Please enter a valid email address.') {
            setError(registeredProgram);
          }
          if (registeredProgram === 'Email already exists.') {
            setError(registeredProgram);
          }

          if (registeredProgram.id) {
            history.push(`/program/edit?id=${registeredProgram.id}`);
          }
        } else {
          setGradeError('Your grade level is not eligible.');
        }
      }
    });
  };

  const handleRegister = async () => {
    let docID = '';
    grades.map((grade) => {
      if (program[grade.fieldName]) {
        if (profile.gradeLevel === grade.value) {
          try {
            programRegister(profile, id).then((docRef) => {
              history.push(`/program/edit?id=${docRef.id}`);
              // history.push('/program/confirmation')

              docID = docRef.id;
              history.push(`/program/edit?id=${docID}`);
            });
          } catch (error) {}
        } else {
          setGradeError('Your grade level is not eligible.');
        }
      }
      return docID;
    });
  };

  return (
    <Grid container direction={'column'} style={{ backgroundColor: '#FAFAFB' }}>
      <Grid
        className="height-wrap"
        item
        style={{ position: 'relative', paddingTop: '60px', height: '900px' }}
      >
        <div className="image-section">
          <Grid item style={{ height: '512px' }}>
            <Grid
              className={classes.headerImage}
              style={{ backgroundImage: 'url(' + headerBanner + ')' }}
            ></Grid>
          </Grid>
        </div>
        <Grid
          className={classes.headerTextBox}
          style={{
            position: 'relative !important',
            maxWidth: '1320px',
            paddingBottom: '3rem !important',
          }}
        >
          <Grid
            style={{
              position: 'relative !important',
              marginTop: '-96px',
              marginLeft: '0.5rem !important',
              marginRight: '0.5rem !important',
            }}
          >
            <Grid
              container
              direction={'row'}
              style={{ backgroundColor: '#fff', marginRight: 0, marginLeft: 0 }}
            >
              <Grid item col={12} md={6} lg={8}>
                <Grid item className={classes.headerTextLeftCont}>
                  <Typography className={classes.headlineText2}>
                    {program.programName}
                  </Typography>
                  <Grid item style={{ marginTop: '5px' }}>
                    <div
                      className={classes.text}
                      dangerouslySetInnerHTML={{
                        __html: program.programSummary
                          ? `${program?.programSummary}  `
                          : '',
                      }}
                      style={{ marginBottom: '1rem' }}
                      onClick={() => setExpand(false)}
                    />
                    <a
                      target="_self"
                      href="#program-detail"
                      aria-label="Lorem Ipsum"
                      style={{
                        color: '#FF9900',
                        textDecoration: 'none',
                        fontWeight: '600',
                      }}
                    >
                      Learn more
                    </a>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className="Quick-facts-wrap"
                item
                col={12}
                md={6}
                lg={4}
                sm={12}
              >
                <Grid item className={classes.headerTextRightCont}>
                  <Grid className={classes.factCard}>
                    <div className="quick-facts">
                      <Grid className={classes.factCardInner}>
                        <p className={classes.quickFacts}> Quick facts</p>
                        <div style={{ display: 'flex', marginBottom: '1rem' }}>
                          <CalendarMonthIcon
                            style={{ marginRight: '8px' }}
                          ></CalendarMonthIcon>
                          Application Open:{' '}
                          <b>
                            {' '}
                            {program.applicationOpen
                              ? format(
                                  parseISO(program?.applicationOpen),
                                  'MM/dd/yyyy'
                                )
                              : null}
                          </b>
                        </div>

                        <hr
                          style={{
                            height: '0.25px',
                            color: '#191919',
                            marginBottom: '1rem ',
                            marginTop: '0',
                          }}
                        ></hr>
                        <div>
                          <CheckCircleIcon
                            style={{ marginRight: '8px' }}
                          ></CheckCircleIcon>
                          Application Fee: <b>${program.applicationFee}</b>
                        </div>
                        <div>
                          <CheckCircleIcon
                            style={{ marginRight: '8px' }}
                          ></CheckCircleIcon>
                          Program Cost: <b>${program.programCost}</b>
                        </div>
                        <div>
                          <CheckCircleIcon
                            style={{ marginRight: '8px' }}
                          ></CheckCircleIcon>
                          Recomendations Required:{' '}
                          <b>{program.recommendationsRequired}</b>
                        </div>
                        <div>
                          <CheckCircleIcon
                            style={{ marginRight: '8px' }}
                          ></CheckCircleIcon>
                          Grade Level:{' '}
                          <b>
                            {grades.map((grade) =>
                              program[grade.fieldName]
                                ? `${grade.value} `
                                : null
                            )}
                          </b>
                        </div>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {/*IF APPLICATION NOT OPEN*/}
              {!profile.isAdmin &&
                !profile.isReviewer &&
                register &&
                !applicationOpen &&
                new Date(program.applicationOpen) > new Date() && (
                  <Grid container direction={'column'} alignItems={'center'}>
                    <Typography variant={'subtitle1'} className={classes.error}>
                      Registration will open soon
                    </Typography>
                  </Grid>
                )}

              {!profile.isAdmin &&
                !profile.isReviewer &&
                programs.length >= 1 &&
                authenticated &&
                isApplicant &&
                applicationOpen &&
                new Date(program.applicationDeadline) > new Date() && (
                  <Grid container direction={'column'} alignItems={'center'}>
                    <Typography variant={'subtitle1'} className={classes.error}>
                      Application Started. Visit Dashboard to continue.
                    </Typography>
                  </Grid>
                )}
              {!profile.isAdmin &&
                !profile.isReviewer &&
                programs.length >= 1 &&
                authenticated &&
                isApplicant &&
                new Date(program.applicationDeadline) < new Date() && (
                  <Grid container direction={'column'} alignItems={'center'}>
                    <Typography variant={'subtitle1'} className={classes.error}>
                      Registration closed
                    </Typography>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item className={classes.detailsContainer}>
        {/*ICON*/}
        <Grid container direction={'column'}>
          <Grid item>
            <IconButton
              aria-label="back"
              className={classes.iconButton}
              style={{ marginBottom: '10px' }}
              size={'medium'}
              onClick={() => history.push(`/program`)}
            >
              <ArrowBackIcon className={classes.icon} />
            </IconButton>
          </Grid>
        </Grid>
        {/*REGISTER*/}
        {/* register form is only open when user is not authenticated and application is open and application DeadlineDate is greater than today  */}
        {!profile.isAdmin &&
          !profile.isReviewer &&
          register &&
          applicationOpen &&
          new Date(program.applicationDeadline) > new Date() && (
            <Grid
              container
              alignItems={'center'}
              style={{ maxWidth: '1320px' }}
            >
              {/*SIGNUP FORM*/}
              {!authenticated && !isApplicant && (
                <Paper
                  className="flex-responsiveness"
                  item
                  direction={'row'}
                  container
                  style={{
                    borderRadius: '3px',
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <Grid item col={12} md={12} lg={12}>
                    <Grid item style={{ height: '100%' }}>
                      <img
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                        src={regBanner}
                      ></img>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    col={12}
                    md={12}
                    lg={12}
                    style={{ padding: '80px 50px' }}
                  >
                    <form
                      autoComplete={'off'}
                      onSubmit={handleSubmit(handleRegisterAndSignup)}
                    >
                      <Grid item>
                        <Grid item direction={'column'}>
                          <Grid item>
                            <Typography
                              style={{
                                textAlign: 'center',
                                fontSize: '18px',
                                fontWeight: '600',
                                marginBottom: '10px',
                              }}
                            >
                              Registration
                            </Typography>
                            <hr
                              style={{
                                border: '1px solid #FF9900',
                                marginBottom: '20px',
                              }}
                            ></hr>
                          </Grid>
                          <div className="username">
                            <Grid item container>
                              <Grid
                                item
                                md={6}
                                className={classes.fieldWrapper}
                                style={{ paddingRight: '0.2em' }}
                              >
                                <Field
                                  name={'firstName'}
                                  label={'First Name'}
                                  component={TextInput}
                                  type={'text'}
                                  variant={'outlined'}
                                />
                              </Grid>
                              <Grid
                                item
                                md={6}
                                className={classes.fieldWrapper}
                                style={{ paddingLeft: '0.2em' }}
                              >
                                <Field
                                  name={'lastName'}
                                  label={'Last Name'}
                                  component={TextInput}
                                  type={'text'}
                                  variant={'outlined'}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>

                        <Grid item className={classes.fieldWrapper}>
                          <Field
                            name={'dob'}
                            label={'Date of Birth'}
                            component={DatePickerInput}
                            type={'text'}
                            inputVariant={'outlined'}
                            size={'small'}
                            style={{ width: '100%' }}
                          />
                        </Grid>
                        <Grid item className={classes.fieldWrapper}>
                          <Field
                            name={'citizen'}
                            label={`Are you a us citizen`}
                            component={SelectInput}
                            type={'text'}
                            variant={'outlined'}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Field>
                        </Grid>
                        <Grid item className={classes.fieldWrapper}>
                          <Field
                            name={'gradeLevel'}
                            label={`Grade Level as of ${format(
                              new Date(),
                              'EEE MMM do y'
                            )}`}
                            component={SelectInput}
                            type={'text'}
                            variant={'outlined'}
                          >
                            {grades.map((grade) => (
                              <MenuItem key={grade.value} value={grade.value}>
                                {grade.value}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item className={classes.fieldWrapper}>
                          <Field
                            name={'mobileNumber'}
                            label={'Mobile Number'}
                            component={TextInput}
                            type={'text'}
                            variant={'outlined'}
                          />
                        </Grid>
                        {/* <Grid item className={classes.fieldWrapper}>
                        <Field
                          name={'parentFirstName'}
                          label={'Parent First Name'}
                          component={TextInput}
                          type={'text'}
                          variant={'outlined'}
                        />
                      </Grid> */}
                        {/* <Grid item className={classes.fieldWrapper}>
                        <Field
                          name={'parentLastName'}
                          label={'Parent Last Name'}
                          component={TextInput}
                          type={'text'}
                          variant={'outlined'}
                        />
                      </Grid> */}
                        {/* <Grid item className={classes.fieldWrapper}>
                        <Field
                          name={'parentEmail'}
                          label={'Parent Email Address'}
                          component={TextInput}
                          type={'text'}
                          variant={'outlined'}
                        />
                      </Grid> */}
                        {/* <Grid item className={classes.fieldWrapper}>
                        <Field
                          name={'parentNumber'}
                          label={'Parent Number'}
                          component={TextInput}
                          type={'text'}
                          variant={'outlined'}
                        />
                      </Grid> */}
                        {/* <Grid item className={classes.fieldWrapper}>
                        <Field
                          name={'counselorName'}
                          label={'Counselor Name'}
                          component={TextInput}
                          type={'text'}
                          variant={'outlined'}
                        />
                      </Grid> */}
                        {/* <Grid item className={classes.fieldWrapper}>
                        <Field
                          name={'counselorEmail'}
                          label={'Counselor Email Address'}
                          component={TextInput}
                          type={'text'}
                          variant={'outlined'}
                        />
                      </Grid> */}
                        {/* <Grid item className={classes.fieldWrapper}>
                        <Field
                          name={'address1'}
                          label={'Address 1'}
                          component={TextInput}
                          type={'text'}
                          variant={'outlined'}
                        />
                      </Grid> */}
                        {/* <Grid item className={classes.fieldWrapper}>
                        <Field
                          name={'address2'}
                          label={'Address 2'}
                          component={TextInput}
                          type={'text'}
                          variant={'outlined'}
                        />
                      </Grid> */}
                        <Grid item className={classes.fieldWrapper}>
                          <Field
                            name={'city'}
                            label={'City'}
                            component={TextInput}
                            type={'text'}
                            variant={'outlined'}
                          />
                        </Grid>
                        <Field
                          name={'state'}
                          label={'State'}
                          component={SelectInput}
                          type={'text'}
                          variant={'outlined'}
                        >
                          {states.map((state) => (
                            <MenuItem key={state.value} value={state.text}>
                              {state.text}
                            </MenuItem>
                          ))}
                        </Field>
                        <Grid item className={classes.fieldWrapper}>
                          <Field
                            name={'zipCode'}
                            label={'Zip Code'}
                            component={TextInput}
                            type={'text'}
                            variant={'outlined'}
                          />
                        </Grid>
                        <Grid item className={classes.fieldWrapper}>
                          <Field
                            name={'graduationMonthYear'}
                            label={'Expected Graduation'}
                            // component={TextInput}
                            component={DatePickerInput}
                            type={'text'}
                            variant={'outlined'}
                          />
                        </Grid>
                        <Grid item className={classes.fieldWrapper}>
                          <Field
                            name={'email'}
                            label={'Email Address'}
                            component={TextInput}
                            type={'text'}
                            variant={'outlined'}
                          />
                        </Grid>
                        <Grid item className={classes.fieldWrapper}>
                          <Field
                            name={'password'}
                            label={'Password'}
                            component={TextInput}
                            type={'password'}
                            variant={'outlined'}
                          />
                        </Grid>
                        <Grid
                          item
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          className={classes.fieldWrapper}
                        >
                          <Field
                            name={'sendSmsConsent'}
                            component={CheckboxInput}
                            size="medium"
                            color="primary"
                            label="Check this box to opt in and consent to receive communication from LEADing For Life via SMS, email or phone. Message & data rates may apply."
                          />
                        </Grid>

                        {error && (
                          <Grid item style={{ marginTop: '0.5em' }}>
                            <Typography
                              variant={'subtitle1'}
                              className={classes.error}
                            >
                              {error}{' '}
                              {error === 'Email already exists.'
                                ? 'Please sign in.'
                                : null}
                            </Typography>
                          </Grid>
                        )}
                        {gradeError && (
                          <Grid item style={{ marginTop: '0.5em' }}>
                            <Typography
                              variant={'subtitle1'}
                              className={classes.error}
                            >
                              {gradeError}
                            </Typography>
                          </Grid>
                        )}

                        {/*BUTTONS*/}
                        <Grid item style={{ marginTop: '1em' }}>
                          <Grid item container justifyContent={'center'}>
                            <Grid item>
                              <div className="app-div">
                                <Button
                                  variant={'outlined'}
                                  className={classes.button}
                                  type={'submit'}
                                >
                                  Start Application
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Paper>
              )}

              {authenticated && isApplicant && (
                <Grid item container direction={'column'} alignItems={'center'}>
                  {gradeError && (
                    <Grid
                      item
                      style={{ marginTop: '0.5em', marginBottom: '1em' }}
                    >
                      <Typography
                        variant={'subtitle1'}
                        className={classes.error}
                      >
                        {gradeError}
                      </Typography>
                    </Grid>
                  )}

                  {/*BUTTONS*/}
                  <Grid item>
                    <Grid item container justifyContent={'center'}>
                      <Grid item>
                        {!gradeError && (
                          <Fragment>
                            {programs.length < 1 && (
                              <Button
                                variant={'outlined'}
                                color={'primary'}
                                className={classes.startButton}
                                onClick={() => handleRegister()}
                              >
                                Start Application
                              </Button>
                            )}
                          </Fragment>
                        )}
                        {gradeError && (
                          <Button
                            variant={'outlined'}
                            color={'primary'}
                            onClick={() => history.push('/program')}
                          >
                            Cancel
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}

        {/*   <Grid className={classes.midNav}>
          <Grid container style={{paddingLeft: '15px',paddingRight: '15px',maxWidth: '1320px'}}>
            <Grid  direction={'row'} style={{ marginRight: 0, marginLeft: 0}}>
              <Grid col={12}>
                  
              </Grid>
            </Grid>
          </Grid>
        </Grid>}
        {/*ABOUT*/}
        <Grid
          container
          direction={'column'}
          style={{
            // marginTop: '27.72px',
            backgroundColor: 'white',
            padding: '30px',
            borderRadius: '20px',
          }}
          className="divide"
        >
          <Grid item style={{ textAlign: 'center' }} id="program-detail">
            <Typography className={classes.headText}>
              Dive into the Details
            </Typography>
          </Grid>
          {/* <Grid item style={{ marginTop: '5px' }}>
            <div
              className={classes.text}
              dangerouslySetInnerHTML={{
                __html: program.programSummary
                  ? `${program?.programSummary}  `
                  : '',
              }}
              onClick={() => setExpand(false)}
            />
            </Grid>*/}
          <Grid item style={{ marginTop: '20px' }}>
            {
              // <Typography className={classes.headText}>Description</Typography>
            }
          </Grid>
          {hasImage ? (
            <Grid item>
              <div
                className={classes.text}
                dangerouslySetInnerHTML={{
                  __html: program?.programDescription,
                }}
                style={{
                  // height: '400px',
                  // width: '800px',

                  objectFit: 'cover',
                  // overflow: 'scroll',
                }}
              />
            </Grid>
          ) : (
            <Grid item>
              {!expand && (
                <div
                  className={classes.text}
                  dangerouslySetInnerHTML={{
                    __html: program?.programDescription,
                  }}
                  /* style={{
                     height: '400px',
                     width: '800px',
                     objectFit: 'cover',
                     overflow: 'scroll',
                   }}*/
                />
              )}
              {/*  dangerouslySetInnerHTML={{
                    __html: program.programDescription
                      ? `${program?.programDescription.substring(
                          0,
                          350
                        )}... <span style="cursor: pointer; color: #0062FF" >More</span> `
                      : '',
                  }}
              //    onClick={() => setExpand(true)}
                />
              )}
              {expand && (
                <div
                  className={classes.text}
                  dangerouslySetInnerHTML={{
                    __html: program.programDescription
                      ? `${program?.programDescription} <span style="cursor: pointer; color: #0062FF" >Less</span> `
                      : '',
                  }}
                  onClick={() => setExpand(false)}
                />
                )}*/}
            </Grid>
          )}
        </Grid>
        {/*ICONS
        {institutions.length > 0 && (
          <Grid
            container
            direction={'column'}
            style={{ marginTop: '27.72px', padding: '30px' }}
          >
            <Grid item>
              <Typography className={classes.headText}>
                Participating Host Institutions
              </Typography>
            </Grid>

            <Grid
              container
              justifyContent={'space-evenly'}
              style={{ marginTop: '32px' }}
            >
              {institutions.map((institution) => (
                <Grid item key={institution.id}>
                  <Grid container direction={'column'} alignItems={'center'}>
                    <Grid item>
                      {/*<Avatar src={institution.institutionImageURL}/>
                      <img
                        className={classes.logo}
                        src={institution.institutionImageURL}
                        alt=""
                      />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.institutionName}>
                        {institution.institutionName}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/*<img style={{borderRadius: '100%'}} src={ellipse} alt=""/>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}*/}
        <Grid style={{ marginTop: '30px', borderLeft: '8px solid #FF9900' }}>
          <Accordion
            style={{ padding: '1.5rem 2rem' }}
            className="application-dropdown"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{ fontSize: '16px', fontWeight: '700' }}
            >
              Application Requirements
            </AccordionSummary>
            <AccordionDetails style={{ fontSize: '16px', lineHeight: '1.5' }}>
              <b> All applicants must submit:</b>
              <ul>
                <li> A nonrefundable application fee.</li>

                <li>Average of 3.3 unweighted GPA, or equivalent</li>
                <li>
                  Two thoughtful, well-written short answer essays to a given
                  prompt that express interest in LEAD and help the Admissions
                  Committee learn more about your intellectual curiosity and
                  ambition.
                </li>
                <li>
                  {' '}
                  Unofficial transcripts - beginning with your 9th grade year of
                  high school to the most recent completed semester{' '}
                </li>
                <li>
                  Resume - an array of leadership, community involvement, and
                  extracurricular activities, personal achievements, and
                  employment opportunities
                </li>
                <li>Tax Forms (if applying for scholarship).</li>
                <li>
                  {program.recommendationsRequired} Recommendation letter that
                  conveys academic performance and potential.
                </li>

                <li>
                  {' '}
                  Standardized test scores (only used as an extra assessment for
                  evaluation)
                </li>
                <li> Fully completed application</li>
              </ul>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid style={{ marginTop: '30px', borderLeft: '8px solid #FF9900' }}>
          <Accordion
            style={{ padding: '1.5rem 2rem' }}
            className="application-dropdown"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{ fontSize: '16px', fontWeight: '700' }}
            >
              Some Important Dates
            </AccordionSummary>
            <AccordionDetails style={{ fontSize: '16px', lineHeight: '1.5' }}>
              <p>
                {' '}
                Application Open:{' '}
                <b>
                  {program.applicationOpen
                    ? format(parseISO(program?.applicationOpen), 'EEE MMM do y')
                    : null}
                </b>
              </p>
              <p>
                {' '}
                Application Deadline:{' '}
                <b>
                  {program.applicationDeadline
                    ? format(
                        parseISO(program?.applicationDeadline),
                        'EEE MMM do y'
                      )
                    : null}
                </b>
              </p>
              <p>
                {' '}
                Notification Date:{' '}
                <b>
                  {program.notificationDate
                    ? format(
                        parseISO(program?.notificationDate),
                        'EEE MMM do y'
                      )
                    : null}
                </b>
              </p>
              <p>
                {' '}
                Deposit Deadline Date:{' '}
                <b>
                  {program.depositDeadline
                    ? format(parseISO(program?.depositDeadline), 'EEE MMM do y')
                    : null}
                </b>
              </p>
              <p>
                {' '}
                Full Payment Deadline:{' '}
                <b>
                  {program.paymentDeadline
                    ? format(parseISO(program?.paymentDeadline), 'EEE MMM do y')
                    : null}
                </b>
              </p>
              <p>
                {' '}
                Program Start Date:{' '}
                <b>
                  {program.programStartDate
                    ? format(
                        parseISO(program?.programStartDate),
                        'EEE MMM do y'
                      )
                    : null}
                </b>
              </p>
              <p>
                {' '}
                Program End Date:{' '}
                <b>
                  {program.programEndDate
                    ? format(parseISO(program?.programEndDate), 'EEE MMM do y')
                    : null}
                </b>
              </p>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {/* <Grid
          spacing={2}
          container
          direction={'column'}
          style={{ marginTop: '27.72px' }}
        >
          <Grid
            item
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <ViewProgramItem
                name={'Grade Level'}
                value={grades.map((grade) =>
                  program[grade.fieldName] ? `${grade.value} ` : null
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3}>
              <ViewProgramItem
                name={'Application Fee'}
                value={'$' + Number(program?.applicationFee).toFixed(2)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <ViewProgramItem
                name={'Program Fee'}
                value={'$' + Number(program?.programCost).toFixed(2)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <ViewProgramItem
                name={'Application Open'}
                value={
                  program.applicationOpen
                    ? format(parseISO(program?.applicationOpen), 'EEE MMM do y')
                    : null
                }
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            // style={{
            //   display: 'flex',
            //   justifyContent: 'space-between',
            //   marginTop: '20px',
            // }}
          >
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <ViewProgramItem
                item
                xs={12}
                sm={6}
                md={6}
                lg={3}
                name={'Application Deadline'}
                value={
                  program.applicationOpen
                    ? format(
                        parseISO(program?.applicationDeadline),
                        'EEE MMM do y'
                      )
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <ViewProgramItem
                item
                xs={12}
                sm={6}
                md={6}
                lg={3}
                name={'Program Start Date'}
                value={
                  program.applicationOpen
                    ? format(
                        parseISO(program?.programStartDate),
                        'EEE MMM do y'
                      )
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <ViewProgramItem
                item
                xs={12}
                sm={6}
                md={6}
                lg={3}
                name={'Program End Date'}
                value={
                  program.applicationOpen
                    ? format(parseISO(program?.programEndDate), 'EEE MMM do y')
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <ViewProgramItem
                item
                xs={12}
                sm={6}
                md={6}
                lg={3}
                name={'Category'}
                value={program?.programCategoryName}
              />
            </Grid>
          </Grid>
        </Grid> */}
        {/*  console.log(
          'new Date(program.applicationDeadline)',
          new Date(program.applicationDeadline)
        )}
        {!profile.isAdmin && !profile.isReviewer && applicationOpen && (
          <Grid
            container
            direction={'column'}
            style={{ marginTop: '41px', paddingLeft: '30px' }}
          >
            {programs.length < 1 &&
              new Date(program.applicationDeadline) > new Date() && (
                <Button
                  variant={'outlined'}
                  className={classes.button}
                  onClick={() => {
                    history.push(
                      `/program/view?id=${program.id}&register=true`
                    );
                    window.scrollTo(0, 0);
                  }}
                >
                  Apply
                </Button>
              )}
          </Grid>
        )}*/}
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: 'signUp', validate })(ViewProgram));
