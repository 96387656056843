import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Typography,
  AppBar,
  Toolbar,
  Box,
} from "@mui/material";
import navLogo from "../../assets/navBar/navLogoLarge.png";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  appBarRoot: {
    backgroundColor: theme.palette.common.white,
    backgroundColor: "#fff",
    borderBottom: "1px solid #E2E2EA",
    fontSize: "18px",
  },
  logo: {
    width: "120px",
    height: "120px",
    cursor: "pointer",
    margin: "5px 0 5px 0",
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: "1em",
    [theme.breakpoints.down("md")]: {
      marginBottom: "1em",
    },
  },
  appBar: {
    zIndex: theme.zIndex.modal + 1,
  },
}));

const MaintenanceHeader = () => {
  const matches = useMediaQuery("(min-width:490px)");
  const classes = useStyles();

  return (
    <AppBar className={classes.appBarRoot} position="static" elevation={0}>
      <Toolbar>
        <img
          style={{
            width: `${matches ? "" : "90px"}`,
            height: `${matches ? "" : "90px"}`,
            margin: `${matches ? "" : "20px 0"}`,
          }}
          className={classes.logo}
          src={navLogo}
          alt="LEAD Logo"
        />

        <Typography
          variant={"subtitle1"}
          className={classes.error}
          style={{
            color: "red",
            marginLeft: "5rem",
            fontWeight: "bold",
            fontSize: "1rem",
          }}
        >
The site is currently undergoing maintenance and will be restored soon. We apologize for the inconvenience and appreciate your patience.
        </Typography>

        <Box flexGrow={1} />
      </Toolbar>
    </AppBar>
  );
};

export default MaintenanceHeader;
