import React, { useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Field } from "redux-form";
import TextArea from "../../common/form/TextArea";

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  fieldWrapper: {
    paddingTop: "0.4em",
    paddingBottom: "0.4em",
  },
  questionText: {
    color: "#696974",
    fontWeight: "600",
    fontFamily: "Roboto",
    fontSize: "16px",
    letter: "0.2px",
    paddingRight: "1em",
  },
}));

const TextAreaForm = ({ question, fieldName, required, maxLength }) => {
  const classes = useStyles();
  // const theme = useTheme();
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const validator = useCallback(
    (value) => {
      if (maxLength) {
        if (!value) return "Required";
        if (value.split(" ").length > parseInt(maxLength))
          return `Must be ${maxLength} words or less`;
      } else if (required) {
        if (!value) return "Required";
      }
    },
    [required, maxLength]
  );

  return (
    <Grid
      item
      container
      className={classes.fieldContainer}
      alignItems={"center"}
    >
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Grid item container direction={"column"}>
          <Grid item>
            <Typography variant={"body1"} className={classes.questionText}>
              {question}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Grid item>
          <Grid item container direction={"column"}>
            <Grid item className={classes.fieldWrapper}>
              <Field
                name={fieldName}
                component={TextArea}
                rows={5}
                type={"text"}
                variant={"outlined"}
                validate={[validator]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TextAreaForm;
