import React from "react";
import { Grid } from "@mui/material";
import RegisterForm from "../ui/forms/auth/RegisterForm";

const TempRegisterPage = () => {
  return (
    <Grid container justifyContent={"center"} direction={"column"}>
      THIS IS A TEMPORARY REGISTER PAGE
      <RegisterForm />
    </Grid>
  );
};

export default TempRegisterPage;
