import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Button,
  IconButton,
  Box,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from '@mui/x-data-grid-premium';
import { openDialog } from '../../../store/actions/dialogActions/dialogActions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  notificationDateMet,
  notificationDateUnMet,
} from '../../../store/actions/TEST-ACTIONS/TestActions';
import { CustomToolbar, escapeRegExp } from '../../../common/tableUtils';
import { format, fromUnixTime } from 'date-fns';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles((theme) => ({
  // ADD STYLES HERE
  viewBtn: {
    backgroundColor: '#35BA35',
    color: theme.palette.common.white,
    '&.MuiButton-containedPrimary:hover': {
      backgroundColor: '#35BA35',
      color: theme.palette.common.white,
    },
  },
  flex3: {
    flex: 3,
  },
  paper: {
    maxHeight: '22vh',
    overflow: 'hidden',
  },
}));

const actions = {
  openDialog,
  notificationDateMet,
  notificationDateUnMet,
};
const cellStyle = (param) => {
  const color = statusToColor(param.value);
  return {
    backgroundColor: color,
  };
};
const statusToColor = (val) => {
  if (val === 'approved') {
    return '#ffaaaa';
  } else if (val == 'admitted') {
    return '#aaaaff';
  } else {
    return '#aaffaa';
  }
};

class StatusIndicator {
  init(param) {
    const element = document.createElement('span');
    const imageElement = document.createElement('img');
    if (param.value == 'approved') {
      imageElement.src =
        'https://www.ag-grid.com/example-assets/weather/fire-plus.png';
    } else {
      imageElement.src =
        'https://www.ag-grid.com/example-assets/weather/fire-minus.png';
    }
    element.appendChild(imageElement);
    element.appendChild(document.createTextNode(param.value));
    this.eGui = element;
  }
  getGui() {
    return this.eGui;
  }
}

const ragCellClassRules = {
  ragGreen: (param) => param.value === 'approved',
  ragRed: (param) => param.value === 'admitted',
  ragBlue: (param) => param.value === 'reviewed',
};
const ragRenderer = (param) => {
  return <span class="dot">{param.value}</span>;
};
const getProgramStage = (programStage, applicationApproved) => {
  if (programStage === 'waitlisted') {
    if (applicationApproved) return 'Approved';
    return 'Waitlisted';
  }
  if (programStage === 'payment1') return 'Payment 1 Pending';
  if (programStage === 'payment2') return 'Payment 2 Pending';
  return programStage;
};

const ReviewedTable = ({
  notificationDateMet,
  // scholarshipApplications,
  notificationDateUnMet,
  profile,
  title,
  program,
  rows: applications,
  approve,
  handleApproveSelected,
  handleUpdateProgramStage,
  handleApproveCheckbox,
  handleProgramRollback,
  handleApplicationNotificationApproved,
  handleToggleGrantScholarship,
  handleToggleGrantSponsorship,
  handleToggleEarlyAdmission,
  handleUpdateChosenInstitution,
  handleUpdateChosenInstitution2,
  sponsorhips,
  institutions,
}) => {
  //console.log({ handleUpdateProgramStage });
  // console.log({ institutions });
  // console.log(applications, 'applicationssssss');
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [visibleRows, setVisibleRows] = useState([]);
  const [institutionList, setInstitutionList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [filterModel, setFilterModel] = useState();
  const [sortModel, setSortModel] = useState();
  const [open, setOpen] = useState(false);
  const [openSponsorsipDialog, setOpenSponsorsipDialog] = useState(false);
  const [declineComment, setDeclineComment] = useState('');
  const [sponsorshipName, setSponsorshipName] = useState('');
  const [selectedApplicantId, setSelectedApplicantId] = useState('');
  const [checkEarlyAdmission, setCheckEarlyAdmission] = useState();
  const [selectedRegisteredProgramId, setSelectedRegisteredProgramId] =
    useState(false);

  const grid1 = useRef(null);
  const grid2 = useRef(null);

  const classes = useStyles();

  const history = useHistory();

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      'e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
    );
  }, []);
  console.log(program, '111111');
  useEffect(() => {
    const arr = applications
      .map((application, index) => ({
        ...application,
        // id: application.applicantId,
        serialNo: index + 1,
        fullname: application.applicantDetails?.firstName,
        programName: application.programDetails?.programName,
        reviewer: application.reviewerDetails?.fullName,
        programStage: getProgramStage(
          application.programStage,
          application.applicationApproved
        ),
        ethnicBackground:
          application.personalInformationFormDetails?.ethnicBackground,
        gender: application.personalInformationFormDetails?.gender,
        city: application.personalInformationFormDetails?.city,
        state: application.personalInformationFormDetails?.state,
        gpa: application.academicInformationFormDetails?.cumulativeGPA,
        sat: application.academicInformationFormDetails?.standardizedScore,
        paidInFull: !!application?.paymentAmount ? 'Yes' : 'No',
        initialBalance:
          application.programDetails?.programCost -
          application.scholarshipAmount,
        balancePaid: application?.paymentAmount
          ? application?.paymentAmount
          : application?.payment1Amount + application?.payment2Amount,
        payment1Received: application.payment1Received ? `Paid` : 'Un-Paid',
        // payment1Amount: application.payment1Details?.paymentFee,
        payment2Received: application.payment2Received ? `Paid` : 'Un-Paid',
        // payment2Amount: application.payment2Details?.paymentFee,
        // institutionName: application.institutionDetails?.institutionName,
        // institutionName2: application.institutionDetails?.institutionName,

        institutionId: application.institutionDetails?.id,
        reviewerAssignedAt:
          application.reviewerAssignedAt &&
          format(fromUnixTime(application.reviewerAssignedAt), 'MM/dd/yyyy'),
        reviewerSubmittedAt:
          application.reviewerResponse?.reviewedOn &&
          format(
            new Date(application.reviewerResponse?.reviewedOn),
            'MM/dd/yyyy'
          ),
        applicationAcceptedAt:
          application.applicationAcceptedAt &&
          format(fromUnixTime(application.applicationAcceptedAt), 'MM/dd/yyyy'),
        withdrawnAt:
          application.withdrawnAt &&
          format(fromUnixTime(application.withdrawnAt), 'MM/dd/yyyy'),
        completedAt:
          application.completedAt &&
          format(fromUnixTime(application.completedAt), 'MM/dd/yyyy'),
        qualifyForScholarship: application.scholarshipFormDetails
          ?.qualifyForScholarship
          ? 'Yes'
          : 'No',
        affiliateName: application?.affiliateName,
        affiliateCode: application?.promoCode,
        notifiedAt:
          application.notifiedAt &&
          format(fromUnixTime(application.notifiedAt), 'MM/dd/yyyy'),
        // submissionReadableDate: format(new Date(application.confirmationFormDetails?.confirmationDate), "MM/dd/yyyy") || format(application.confirmationFormDetails?.confirmationDate), "MM/dd/yyyy"),
        /*  submissionReadableDate:application.confirmationFormDetails?.confirmationDate &&
          format(fromUnixTime(application.confirmationFormDetails?.confirmationDate), "MM/dd/yyyy"),
       disability: application.studentQuestionnaireFormDetails?.disability
          ? Object.keys(application.studentQuestionnaireFormDetails.disability)
          : [],
        workPreference: application.studentQuestionnaireFormDetails
          ?.workPreference
          ? Object.keys(
              application.studentQuestionnaireFormDetails.workPreference
            )
          : [],
        hobbies: application.studentQuestionnaireFormDetails?.doInSpareTime
          ? Object.keys(
              application.studentQuestionnaireFormDetails.doInSpareTime
            )
          : [],*/
      }))
      .sort((a, b) => {
        return (
          new Date(fromUnixTime(b.reviewerUpdated)) -
          new Date(fromUnixTime(a.reviewerUpdated))
        );
      });
    setRows(arr);
    if (searchText) {
      setSearchText(searchText);
      const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
      setVisibleRows(arr);
    }
  }, [applications]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filtered = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };
  const memoizedColumns = useMemo(() => {
    const columns = [
      {
        field: 'fullName',
        headerName: 'Name',
        width: 180,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'grantScholarship',
        headerName: 'Grant Scholarship',
        width: 140,
        hide: !profile.isAdmin,
        renderCell: (param) => (
          <Box display="flex" justifyContent="center" width="100%">
            <Switch
              checked={param.value}
              onChange={(e, checked) => {
                e.stopPropagation();
                handleToggleGrantScholarship(param.id, checked);
              }}
              onClick={(e) => e.stopPropagation()}
              color="primary"
            />
          </Box>
        ),
      },
      {
        field: 'grantSponsorship',
        headerName: 'Grant Sponsorship',
        width: 140,
        hide: !profile.isAdmin,
        renderCell: (param) => (
          <Box display="flex" justifyContent="center" width="100%">
            <Switch
              checked={param.value}
              onClick={(e) => {
                e.stopPropagation();
                if (e.target.checked) {
                  e.preventDefault();
                  setSelectedRegisteredProgramId(param.id);
                  setOpenSponsorsipDialog(true);
                } else {
                  handleToggleGrantSponsorship(param.id, e.target.checked);
                }
              }}
              color="primary"
            />
          </Box>
        ),
      },
      {
        field: 'earlyAdmission',
        headerName: 'Early Admission',
        width: 140,
        hide: !profile.isAdmin,
        renderCell: (param) => (
          <Box display="flex" justifyContent="center" width="100%">
            <Switch
              checked={param.value}
              onChange={(e, checked) => {
                e.stopPropagation();
                handleToggleEarlyAdmission(
                  title == 'Early Admission'
                    ? param.row.applicantUid
                    : param.id,
                  checked
                );
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              color="primary"
            />
          </Box>
        ),
      },
      {
        field: 'notifiedAt',
        headerName: 'Notified At',
        width: 140,
        valueGetter: (param) => param.value || 'N/A',
      },

      {
        field: 'ethnicBackground',
        headerName: 'Nationality',
        width: 180,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'gender',
        headerName: 'Gender',
        width: 90,
        valueGetter: (param) => param.value || 'N/A',
      },

      // {
      //   field: 'programStatus',
      //   headerName: 'Program Status',
      //   width: 180,
      //   valueGetter: (param) => param.value || 'N/A',
      // },
      {
        field: 'programStage',
        headerName: 'Application Stages',
        width: 140,
        align: 'center',
        renderCell: (param) => (
          <div
            style={{
              //approved = orange, admitted = blue, complete = green, reviewed = pink, withdrawn = red, denied = purple, default = gray
              borderColor:
                param.value === 'approved'
                  ? '#E38E21'
                  : param.value === 'admitted'
                    ? '#00799B'
                    : param.value === 'complete'
                      ? '#4C9B4F'
                      : param.value === 'reviewed'
                        ? '#DC4C88'
                        : param.value === 'withdrawn'
                          ? '#FC5A5A'
                          : param.value === 'denied'
                            ? '#7D609C'
                            : '#5B5B5B',
              color:
                param.value === 'approved'
                  ? '#E38E21'
                  : param.value === 'admitted'
                    ? '#00799B'
                    : param.value === 'complete'
                      ? '#4C9B4F'
                      : param.value === 'reviewed'
                        ? '#DC4C88'
                        : param.value === 'withdrawn'
                          ? '#FC5A5A'
                          : param.value === 'denied'
                            ? '#7D609C'
                            : '#5B5B5B',
              borderRadius: '4rem',
              border: '2px',
              borderStyle: 'solid',
              paddingLeft: '8px',
              paddingRight: '8px',
              fontWeight: '600',
              textAlign: 'center',
            }}
          >
            {param.value}
          </div>
        ),
      },

      {
        field: 'program',
        headerName: 'Update Stage',
        width: 140,
        valueGetter: (param) => param.value || 'N/A',
        hide: !profile.isAdmin,
        renderCell: (param) => (
          <Box display="flex" justifyContent="center" width="100%">
            <FormControl fullWidth size="small">
              {/* <InputLabel>Application Stage</InputLabel>*/}
              <Select
                value={param.row.programStage}
                onClick={(e) => e.stopPropagation()}
                onChange={(event) => {
                  setSelectedApplicantId(param.id);
                  if (
                    event.target.value === 'approved' ||
                    event.target.value === 'complete'
                  ) {
                    handleUpdateProgramStage(
                      param.id,
                      event.target.value,
                      true
                    );
                  } else {
                    if (event.target.value === 'withdrawn') {
                      setOpen(true);
                    } else {
                      handleUpdateProgramStage(
                        param.id,
                        event.target.value,
                        false
                      );
                    }
                  }
                }}
                disabled={param.row.programStage === 'complete'}
              >
                {param.row.programStage !== 'withdrawn' && (
                  <MenuItem
                    disabled={
                      param.row.programStage === 'admitted' ||
                      param.row.programStage === 'complete'
                    }
                    value="approved"
                  >
                    approved
                  </MenuItem>
                )}
                {param.row.programStage !== 'withdrawn' && (
                  <MenuItem
                    disabled={
                      param.row.programStage === 'admitted' ||
                      param.row.programStage === 'complete'
                    }
                    value="denied"
                  >
                    denied
                  </MenuItem>
                )}
                {param.row.programStage !== 'withdrawn' && (
                  <MenuItem
                    disabled={
                      param.row.programStage === 'admitted' ||
                      param.row.programStage === 'complete'
                    }
                    value="waitlisted"
                  >
                    waitlisted
                  </MenuItem>
                )}
                {param.row.programStage !== 'withdrawn' && (
                  <MenuItem value="withdrawn">withdrawn</MenuItem>
                )}
                {param.row.programStage === 'withdrawn' && (
                  <MenuItem
                    disabled={param.row.previousStage ? false : true}
                    value="restore"
                  >
                    restore
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
        ),
      },
      {
        field: 'institutionName',
        headerName: 'Placement 1',
        width: 160,
        valueGetter: (param) => param.value || 'Unassigned',
        renderCell: (param) => (
          <Box display="flex" justifyContent="center" width="100%">
            <FormControl fullWidth size="small">
              <Select
                value={param.row?.institutionDetails?.id || 'No Institution'}
                onClick={(e) => e.stopPropagation()}
                onChange={(event) => {
                  console.log(param.id, 'll');
                  handleUpdateChosenInstitution(param.id, event.target.value);
                }}
                disabled={param.row.programStage === 'complete'}
              >
                <MenuItem value={'noInstitution'}>No Institution </MenuItem>
                {console.log(institutions, 'hey')}
                {institutions &&
                  institutions?.length > 0 &&
                  institutions.map((institution) => (
                    <MenuItem key={institution.id} value={institution.id}>
                      {console.log(institution, 'manj')}
                      {institution.institutionName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        ),
      },
      {
        field: 'institutionName2',
        headerName: 'Placement 2',
        width: 160,
        valueGetter: (param) => param?.row?.chosenInstitution2 || 'Unassigned',
        renderCell: (param) => (
          <Box display="flex" justifyContent="center" width="100%">
            <FormControl fullWidth size="small">
              <Select
                value={param.value || 'No Institution'}
                onClick={(e) => e.stopPropagation()}
                onChange={(event) => {
                  console.log(param.id, 'll');
                  handleUpdateChosenInstitution2(param.id, event.target.value);
                }}
                disabled={param.row.programStage === 'complete'}
              >
                <MenuItem value={'noInstitution'}>No Institution </MenuItem>
                {console.log(institutions, 'hey')}
                {institutions &&
                  institutions?.length > 0 &&
                  institutions.map((institution) => (
                    <MenuItem key={institution.id} value={institution.id}>
                      {console.log(institution, 'manj')}
                      {institution.institutionName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        ),
      },
      {
        field: 'gpa',
        headerName: 'GPA',
        width: 110,
        overflow: 'auto',
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'sat',
        headerName: 'SAT',
        width: 110,
        overflow: 'auto',
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'reviewTotalScore',
        headerName: 'Total Score',
        width: 120,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'affiliateCode',
        headerName: 'Affiliate Code',
        width: 120,
        hide: !profile.isAdmin,
        valueGetter: (param) => param.value || 'None',
      },
      /* {
        field: "affiliateName",
        headerName: "Affiliate Name",
        width: 160,
        hide: !profile.isAdmin,
        valueGetter: (param) => param.value || "None",
      },*/
      {
        field: 'qualifyForScholarship',
        headerName: 'Qualify for Scholarship',
        width: 140,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'scholarshipAmount',
        headerName: 'Scholarship Amount',
        width: 120,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'applicationAcceptedAt',
        headerName: 'Acceptance Date',
        width: 140,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'applicationStarted',
        headerName: 'Application Start Date',
        width: 160,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'submissionReadableDate',
        headerName: 'Submission Date',
        width: 140,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'reviewer',
        headerName: 'Reviewer',
        width: 140,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'reviewerAssignedAt',
        headerName: 'Reviewer Assigned Date ',
        width: 140,
        valueGetter: (param) => param.value || 'N/A',
      },

      {
        field: 'reviewerSubmittedAt',
        headerName: 'Reviewer Submission Date ',
        width: 140,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'reviewerUpdatedReadableDate',
        headerName: 'Reviewer Updated',
        width: 140,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'affiliateName',
        headerName: 'Affiliate Name',
        width: 160,
        hide: !profile.isAdmin,
        valueGetter: (param) => param.value || 'None',
      },

      {
        field: 'withdrawnAt',
        headerName: 'Withdrawn Date',
        width: 140,
        valueGetter: (param) => param.value || 'N/A',
      },

      {
        field: 'completedAt',
        headerName: 'Acceptance Completed Date',
        width: 140,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'areaOfInterest',
        headerName: 'Interest',
        width: 180,
        valueGetter: (param) => param.value || 'N/A',
      },

      // {
      //   field: 'reviewAverageScore',
      //   headerName: 'Average Score',
      //   width: 180,
      //   valueGetter: (param) => param.value || 'N/A',
      // },

      /* {
        field: "city",
        headerName: "City",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "state",
        headerName: "State",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
      },*/
      {
        field: 'initialBalance',
        headerName: 'Initial Balance',
        width: 140,
        renderCell: (param) =>
          param.value ? <span>${param.value}</span> : 'N/A',
      },
      {
        field: 'balancePaid',
        headerName: 'Balance Paid',
        width: 140,
        renderCell: (param) =>
          param.value ? <span>${param.value}</span> : 'N/A',
      },
      {
        field: 'paidInFull',
        headerName: 'Paid In Full',
        width: 140,
        renderCell: (param) =>
          param.value ? <span>{param.value}</span> : 'N/A',
      },
      {
        field: 'payment1Received',
        headerName: 'Deposit Payment',
        width: 140,
        align: 'center',
        valueGetter: (param) => param.value || 'N/A',
        renderCell: (param) => (
          <div
            style={{
              //paid = green, unpaid = gray
              borderColor:
                param.value === 'Paid'
                  ? '#4C9B4F'
                  : param.value === 'Un-Paid'
                    ? '#5B5B5B'
                    : '#5B5B5B',
              color:
                param.value === 'Paid'
                  ? '#4C9B4F'
                  : param.value === 'Un-Paid'
                    ? '#5B5B5B'
                    : '#5B5B5B',
              borderRadius: '4rem',
              border: '2px',
              width: '55px',
              borderStyle: 'solid',
              paddingLeft: '8px',
              paddingRight: '8px',
              fontWeight: '600',
              textAlign: 'center',
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: 'payment1Amount',
        headerName: 'Deposit Amount',
        width: 140,
        renderCell: (param) =>
          param.value ? <span>${param.value}</span> : 'N/A',
      },
      {
        field: 'payment2Received',
        headerName: 'Final Payment',
        width: 140,
        renderCell: (param) => (
          <div
            style={{
              //paid = green, Unpaid = gray
              borderColor:
                param.value === 'Paid'
                  ? '#4C9B4F'
                  : param.value === 'Un-Paid'
                    ? '#5B5B5B'
                    : '#5B5B5B',
              color:
                param.value === 'Paid'
                  ? '#4C9B4F'
                  : param.value === 'Un-Paid'
                    ? '#5B5B5B'
                    : '#5B5B5B',
              borderRadius: '4rem',
              border: '2px',
              width: '55px',
              borderStyle: 'solid',
              paddingLeft: '8px',
              paddingRight: '8px',
              fontWeight: '600',
              textAlign: 'center',
            }}
          >
            {param.value}
          </div>
        ),
      },
      {
        field: 'payment2Amount',
        headerName: 'Final Amount',
        width: 140,
        renderCell: (param) =>
          param.value ? <span>${param.value}</span> : 'N/A',
      },
      {
        field: 'sponsorshipName',
        headerName: 'Sponsorship Name',
        width: 160,
        valueGetter: (param) => param.value || 'None',
      },
      {
        field: 'sponsorshipAmount',
        headerName: 'Sponsorship Amount',
        width: 140,
        renderCell: (param) =>
          param.value ? <span>${param.value}</span> : 'N/A',
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 140,
        valueGetter: (param) => param.value || 'N/A',
        renderCell: (param) => (
          <>
            {/*ROLLBACK*/}
            {profile.isAdmin && (
              <Tooltip title="Roll Back">
                <IconButton
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleProgramRollback(param.row.applicantUid);
                  }}
                  disabled={
                    param.row.notificationDateMet ||
                    param.row.reviewStatus === 'reviewed' ||
                    param.row.programStage === 'admitted' ||
                    param.row.programStage === 'complete' ||
                    param.row.programStage === 'withdrawn'
                  }
                  style={{ marginRight: '0.5em' }}
                >
                  <SettingsBackupRestoreIcon />
                </IconButton>
              </Tooltip>
            )}
            {/*Notify*/}
            {profile.isAdmin && (
              <Tooltip title="Notify">
                <IconButton
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleApplicationNotificationApproved(param.id);
                  }}
                  style={{ marginRight: '0.5em' }}
                  disabled={
                    !(
                      (param.row.programStage === 'approved' &&
                        param.row.chosenInstitution) ||
                      param.row.programStage === 'Waitlisted'
                    )
                  }
                >
                  <NotificationsActiveIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        ),
      },
    ];
    if (program?.dualProgram) {
      return columns;
    } else {
      return columns.filter((column) => column.field !== 'institutionName2');
    }
  }, [
    handleApplicationNotificationApproved,
    handleProgramRollback,
    profile.isAdmin,
    handleUpdateProgramStage,
    handleToggleGrantScholarship,
    handleToggleGrantSponsorship,
    program?.programDetails?.dualprogram,
    handleToggleEarlyAdmission,
  ]);

  return (
    <Box height={320} width="100%" display="flex">
      <Dialog
        open={openSponsorsipDialog}
        fullWidth={true}
        sx={{ overflow: 'hidden' }}
      >
        <DialogContent sx={{ height: '200px', overflow: 'hidden' }}>
          <DialogContentText style={{ marginBottom: '30px' }}>
            Please add sponsorship name
          </DialogContentText>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={sponsorhips}
            sx={{ width: 300 }}
            classes={{ paper: classes.paper }}
            onChange={(e) => {
              setSponsorshipName(e.target.textContent);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Sponsorship Name"
                onChange={(e) => {
                  setSponsorshipName(e.target.value);
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSponsorsipDialog(false)}>Cancel</Button>
          <Button
            onClick={(e) => {
              setOpenSponsorsipDialog(false);
              handleToggleGrantSponsorship(
                selectedRegisteredProgramId,
                true,
                sponsorshipName
              );
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>Please add a reason to withdraw</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setDeclineComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleUpdateProgramStage(
                selectedApplicantId,
                'withdrawn',
                false,
                declineComment
              );
              setOpen(false);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <DataGridPremium
        ref={grid2}
        className={classes.flex3}
        columns={
          title?.trim()?.toLowerCase() === 'early'
            ? memoizedColumns.filter((x) => x.field !== 'grantSponsorship')
            : memoizedColumns
        }
        rows={filteredRows}
        disableSelectionOnClick
        //disableColumnMenu
        rowsPerPageOptions={[]}
        page={page}
        onPageChange={(params) => setPage(params.page)}
        onRowClick={(param) => {
          let newId;
          if (title == 'Early ') {
            newId = param.row.id;
          } else {
            newId = param.id;
          }
          history.push(`/admin/reviewProgram?id=${newId}`);
        }}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            value: searchText,
            clearSearch: () => requestSearch(''),
          },
        }}
        filterModel={filterModel}
        onFilterModelChange={(params) => setFilterModel(params.filterModel)}
        sortModel={sortModel}
        onSortModelChange={(params) => setSortModel(params.sortModel)}
        initialState={{
          pinnedColumns: { left: ['fullName', 'program', 'actions'] },
        }}
      />
    </Box>
  );
};

export default connect(null, actions)(ReviewedTable);
