import React from "react";
import { CircularProgress, Grid } from "@mui/material";

const Loading = () => {
  return (
    <Grid container alignItems={"center"} direction={"column"}>
      <Grid item style={{ marginTop: "2em" }}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default Loading;
