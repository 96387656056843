import { createReducer } from '../../../../common/util/reducerUtil';
import {
    FETCH_ALL_COMPLETED_APPLICATIONS,
    FETCH_ALL_DASHBOARD_VALUES,
    FETCH_ALL_REVIEWED_APPLICATIONS,
    FETCH_ALL_FILTERS,
    FETCH_ALL_EMAILS,
} from '../../../constants/adminConstants/dashboardConstants/dashboardConstants';

const initialState = {
    dashboardValues: [],
    allCompletedApplications: [],
    allReviewedApplications: [],
    filters: {},
};

const getAllDashboardValues = (state, payload) => {
    return {
        ...state,
        dashboardValues: payload.dashboardValues,
    };
};

const getAllCompletedApplications = (state, payload) => {
    return {
        ...state,
        allCompletedApplications: payload.completedApplications,
    };
};

const getAllReviewedApplications = (state, payload) => {
    return {
        ...state,
        allReviewedApplications: payload.reviewedApplications,
    };
};

const getAllFilters = (state, payload) => {
    return {
        ...state,
        filters: payload,
    };
};

const getAllEmails = (state, payload) => {
    return {
        ...state,
        emails: payload,
    };
};

export default createReducer(initialState, {
    [FETCH_ALL_DASHBOARD_VALUES]: getAllDashboardValues,
    [FETCH_ALL_COMPLETED_APPLICATIONS]: getAllCompletedApplications,
    [FETCH_ALL_REVIEWED_APPLICATIONS]: getAllReviewedApplications,
    [FETCH_ALL_FILTERS]: getAllFilters,
    [FETCH_ALL_EMAILS]: getAllEmails,
});
