import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@mui/material';
import ReviewProgramItem from './item/ReviewProgramItem';
import axios from '../../config/axios';
import Iframe from 'react-iframe';
import { ExpandMore } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    letterSpacing: '0.1px',
    color: '#171725',
  },
  answerText: {
    fontWeight: 600,
  },
}));

const PersonalRecommendationsFormReview = ({
  recommendersForm,
  registeredProgram,
  recommendersResponseForm,
}) => {
  const classes = useStyles();
  console.log(
    registeredProgram.personalRecommenderFormDocNo,
    'registeredProgram'
  );
  console.log(recommendersResponseForm, 'recommendersResponseForm');
  const [showLink, setShowLink] = useState(false);
  const [formLink, setFormLink] = useState('');
  const [attachementId, setAttachementId] = useState();
  const [newLink, setNewLink] = useState(false);
  //personalRecommenderFormDocNo

  useEffect(() => {
   // if (registeredProgram?.personalRecommenderFormDocNo) {
    {registeredProgram.personalRecommenderFormCompleted && ( 
  
      axios
        .get('/documentLinkFunctions/documentDownloadLink', {
          // .get('/documentAttachLinkFunctions/documentAttachLink', {
          params: {
            docId: registeredProgram?.personalRecommenderFormDocNo,
          },
        })
        .then((res) => {
      //    console.log(res.data, 'res.data download link');
          setFormLink(res.data.link);
          setShowLink(true);
          console.log(res.data.link, 'mmmmm');
        })
        .catch((err) => {
          console.log(err);
        })
   )}
  }, [registeredProgram?.personalRecommenderFormDocNo]);

  return (
    <Grid
      container
      direction={'column'}
      style={{ paddingTop: '0.5em', paddingBottom: '0.5em' }}
    >
      <Box>
        <Accordion style={{ borderTop: 
  registeredProgram.personalRecommenderFormCompleted ? '3px solid #35BA35' :
  registeredProgram.personalRecommenderFormSent ? '3px solid #FF9900' : '3px solid #CCC'
}}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            style={{ marginTop: '20px' }}
          >
            {/*RECOMMENDATIONS*/}
            <Grid item>
             
              <Typography className={classes.title}>
                Personal Recommendation
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            style={{ backgroundColor: '#FAFAFB', flexDirection: 'column' }}
          >
            {/*RECOMMENDER INFO*/}
            {recommendersForm.map((recommender) => (
               registeredProgram.personalRecommenderFormSent ? (
              <ReviewProgramItem
                key={recommender.id}
                question={'Recommender Information'}
                value={
                  registeredProgram.personalRecommenderDetails
                    .recommenderTitle +
                  ' ' +
                  registeredProgram.personalRecommenderDetails
                    .recommenderFirstName +
                  ' ' +
                  registeredProgram.personalRecommenderDetails
                    .recommenderLastName
                }
                value2={
                  registeredProgram.personalRecommenderDetails
                    .recommenderEmailAddress
                }
                renderCell={() => (
                  <Grid style={{}}>
                    {/* Your custom render cell content */}
                  
                    <Grid><Typography  variant={'h6'} className={classes.title} style={{color:'#696974', marginBottom:'5px'}}>Recommenders Information </Typography>
                    </Grid>
                    <Grid>
                     <b> Name: </b> {registeredProgram.personalRecommenderDetails
                    ?.recommenderTitle +
                  ' ' +
                  registeredProgram.personalRecommenderDetails
                    ?.recommenderFirstName +
                  ' ' +
                  registeredProgram.personalRecommenderDetails
                    ?.recommenderLastName}
                    </Grid>
                    <Grid>
                    <b> Email:</b> {registeredProgram.personalRecommenderDetails?.recommenderEmailAddress}
                    </Grid>
                  </Grid>
                    )}
              />
              ): null
            ))}

            {/*RESPONSES
            <Grid item>
              <Typography className={classes.title}>Document Link</Typography>
            </Grid>
*/}
 {registeredProgram.personalRecommenderFormCompleted ? (

          <> <ReviewProgramItem
              question={'SignNow Link'}
              value={formLink}
              showLink={showLink}
              link={true}
            />
               <Iframe
                  url={'https://app.signnow.com/webapp/document/' + registeredProgram?.personalRecommenderFormDocNo}
                  width="100%"
                  height="800px"
                  display="block"
                  position="relative" /></>
      ): "Personal Recommendation not recieved"
          }
     
          </AccordionDetails>
        </Accordion>
      </Box>
    </Grid>
  );
};

export default PersonalRecommendationsFormReview;
