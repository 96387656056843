import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import ReviewProgramItem from "./item/ReviewProgramItem";
import Iframe from 'react-iframe';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  answerText: {
    fontWeight: 600,
  },
}));

const TranscriptFormReview = ({ transcriptForm }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction={"column"}
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
    >
      {/*RESUME*/}
      <Grid item>
        <Typography className={classes.title}>Transcript</Typography>
      </Grid>

      {/**/}
      {transcriptForm.map((form) => (
        <><ReviewProgramItem
          key={form.id}
          question={form.originalFileName}
          value={
            <a
              href={form.fileURL}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Open in New Tab
            </a>
          }
        />
        <Iframe url={form.fileURL}
        width="100%"
        height="800px"
        id=""
        className=""
        display="block"
        position="relative" /></>
      ))}
    </Grid>
  );
};

export default TranscriptFormReview;
