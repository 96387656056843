import { createReducer } from "../../../common/util/reducerUtil";
import {FETCH_PROGRAM_FOR_REVIEW} from "../../constants/reviewConstants/reviewConstants";




const initialState = {
    reviewApplication: [],
};

const getApplicationForReview = (state, payload) => {
    return {
        ...state,
        reviewApplication: payload.application,
    };
};


export default createReducer(initialState, {
    [FETCH_PROGRAM_FOR_REVIEW]: getApplicationForReview,
});
