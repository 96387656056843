import { createReducer } from "../../../../common/util/reducerUtil";
import {
    FETCH_APPLICANTS_PROGRAM_COUNTDOWN,
    FETCH_APPLICANTS_RECENT_APPLICATION
} from "../../../constants/applicantConstants/dashboardConstants/applicantDashboardConstants";




const initialState = {
    recentApplication: {},
    applicationCountdown: {},
};

const getRecentApplication = (state, payload) => {
    return {
        ...state,
        recentApplication: payload.recentApplication,
    };
};

const getProgramCountdown = (state, payload) => {
    return {
        ...state,
        applicationCountdown: payload.applicationCountdown,
    };
};



export default createReducer(initialState, {
    [FETCH_APPLICANTS_RECENT_APPLICATION]: getRecentApplication,
    [FETCH_APPLICANTS_PROGRAM_COUNTDOWN]: getProgramCountdown,

});
