import { createReducer } from "../../../../common/util/reducerUtil";
import {
    FETCH_ALL_APPLICANTS,
    FETCH_APPLICANT
} from "../../../constants/adminConstants/applicantConstants/adminApplicantConstants";


const initialState = {
    allApplicants: [],
    applicant: [],
};

const getAllApplicants = (state, payload) => {
    return {
        ...state,
        allApplicants: payload.allApplicants,
    };
};

const getApplicant = (state, payload) => {
    return {
        ...state,
        applicant: payload.applicant,
    };
};



export default createReducer(initialState, {
    [FETCH_ALL_APPLICANTS]: getAllApplicants,
    [FETCH_APPLICANT]: getApplicant,

});
