import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import ThemeProvider from "@mui/styles/ThemeProvider";

import theme from "./theme";
import thunkMiddleware from "redux-thunk";
import { getFirebase, ReactReduxFirebaseProvider } from "react-redux-firebase";
import { getFirestore, reduxFirestore } from "redux-firestore";
import { composeWithDevTools } from "redux-devtools-extension";
import { applyMiddleware, createStore } from "redux";
import firebase from "./config/firebase";
import rootReducer from "./store/reducers/rootReducer/rootReducer";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';// date-fns
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StyledEngine, { StyledEngineProvider } from "@mui/styled-engine";

//const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK_KEY}`);
const stripePromise = loadStripe(
  `${
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? process.env.REACT_APP_STRIPE_PK_KEY
      : process.env.REACT_APP_STRIPE_PK_KEY_DEV
  }`
);

const middlewares = [
  thunkMiddleware.withExtraArgument({ getFirebase, getFirestore }),
];
const composedEnhancer = composeWithDevTools(
  applyMiddleware(...middlewares),
  reduxFirestore(firebase)
);
const store = createStore(rootReducer, composedEnhancer);

const reactReduxFirestoreConfig = {
  userProfile: "users",
  attachAuthIsReady: true,
  useFirestoreForProfile: true,
  updateProfileOnLogin: false,
};

const reactReduxFirebaseProps = {
  firebase,
  config: reactReduxFirestoreConfig,
  dispatch: store.dispatch,
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...reactReduxFirebaseProps}>
          <BrowserRouter>
            <Elements stripe={stripePromise}>
              <App />
            </Elements>
          </BrowserRouter>
        </ReactReduxFirebaseProvider>
      </Provider>
      </LocalizationProvider>
    </StyledEngineProvider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
