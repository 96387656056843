import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Grid } from "@mui/material";
import Sidebar from "../../../ui/sidebar/Sidebar";
import Typography from "@mui/material/Typography";

import { connect } from "react-redux";
import DashboardApplicantsTable from "../../../ui/admin/dashboard/DashboardApplicantsTable";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";

const mapStateToProps = (state) => {
  let applicants = [];

  if (
    state.adminDashboard.dashboardValues &&
    state.adminDashboard.dashboardValues.tableData.applicantsApproved.length > 0
  ) {
    applicants =
      state.adminDashboard.dashboardValues.tableData.applicantsApproved;
  }

  return {
    applicants,
  };
};

const AdminDashboardAcceptedApplicants = ({ applicants }) => {
  const matches = useMediaQuery("(min-width:480px)");
  const history = useHistory();
  return (
    <Grid container>
      <Grid item md={2} style={{ width: `${matches ? "" : "100%"}` }}>
        <Sidebar />
      </Grid>

      <Grid item md={10} style={{ paddingLeft: "1.5em" }}>
        <Grid item container direction={"column"}>
          <Grid item>
            <Typography variant={"h4"}>Total Accepted Applicants</Typography>
          </Grid>
          <Grid item style={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/admin/dashboard")}
            >
              Dashboard
            </Button>
          </Grid>

          <DashboardApplicantsTable applicants={applicants} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps)(AdminDashboardAcceptedApplicants);
