import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Box, Paper } from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import Sidebar from "../../../ui/sidebar/Sidebar";
import { connect } from "react-redux";
import DashboardRevenueTable from "../../../ui/admin/dashboard/DashboardRevenueTable";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const mapStateToProps = (state) => {
  let revenue = [];

  if (
    state.adminDashboard.dashboardValues &&
    state.adminDashboard.dashboardValues.tableData.revenue.length > 0
  ) {
    revenue = state.adminDashboard.dashboardValues.tableData.revenue;
  }

  return {
    revenue,
  };
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1.5em",
    backgroundColor: "rgb(250, 250, 251)",
  },
  heading: {
    marginBottom: "3rem",
  },
  tableContainer: {
    padding: "1rem",
    gap: "1rem",
  },
  totalRevenue: {
    marginLeft: "1rem",
  },
}));

const AdminDashboardRevenue = ({ revenue }) => {
  const matches = useMediaQuery("(min-width:480px)");
  const [totalRevenue, setTotalrevenue] = useState(0);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    let amount = 0;
    revenue.forEach((item) => (amount += parseFloat(item.paymentFee)));
    setTotalrevenue(amount);
  }, [revenue]);

  return (
    <Grid container>
      <Grid item md={2} style={{ width: `${matches ? "" : "100%"}` }}>
        <Sidebar />
      </Grid>
      <Grid className={classes.container} item md={10}>
        <Typography className={classes.heading} variant="h5">
          Dashboard
        </Typography>
        <Paper elevation={0}>
          <Grid className={classes.tableContainer} container direction="column">
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex">
                <Typography variant="h6">Total Revenue</Typography>
                <Typography className={classes.totalRevenue} variant="h6">
                  ${Math.round((totalRevenue + Number.EPSILON) * 100) / 100}
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push("/admin/dashboard")}
              >
                Dashboard
              </Button>
            </Grid>
            <DashboardRevenueTable revenue={revenue} />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps)(AdminDashboardRevenue);
