import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Paper,
  Box,
  LinearProgress,
  Checkbox,
  ButtonGroup,
  Button,
  TextField,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BackIcon, TickIcon } from './icons';
import {
  fetchApplicantApplication,
  updateApplicantApplication,
} from '../../../store/actions/applicantActions/dashboardActions/applicantDashboardActions';
import classnames from 'classnames';
import Select from 'react-select';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    backgroundColor: 'rgb(250, 250, 251)',
    paddingBottom: '2rem',
  },
  container: {
    background: 'linear-gradient(225.34deg, #65A65A 0%, #3178A5 101.17%)',
    textAlign: 'center',
    padding: 100,
    color: theme.palette.common.white,
    marginBottom: '2rem',
  },
  paper: {
    padding: 40,
    textAlign: 'center',
  },
  checklistContainer: {
    gap: '1rem',
  },
  checklist: {
    width: '80%',
    padding: '0 2%',
    border: '1px solid #D5D5DC',
    flexWrap: 'nowrap',
    '&.completed': {
      border: 0,
      padding: '6px 2%',
      background: '#FAFAFB',
    },
  },
  status: {
    '&.complete': {
      color: '#4CA94F',
    },
    '&.incomplete': {
      color: '#0079A9',
    },
  },
  stageLabel: {
    flexGrow: 1,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  heading: {
    textTransform: 'capitalize',
  },
  backIcon: {
    cursor: 'pointer',
  },
  viewApplicationButton: {
    marginLeft: '40rem',
  },
  downloadText: {
    letterSpacing: '0.1px',
    color: '#0b1dc5',
    cursor: 'pointer',
  },
  pendingText: {
    letterSpacing: '0.1px',
    color: '#D9512C',
  },
  completeText: {
    letterSpacing: '0.1px',
    color: '#4CA94F',
  },
}));

const AdminDashboardApplicantDetails = ({
  fetchApplicantApplication,
  applicationDetails,
  programDetails,
  applicants,
  updateApplicantApplication,
}) => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  console.log(applicationDetails, 'applicationDetails');
  const [applicationIndex, setApplicationIndex] = useState();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  useEffect(() => {
    if (applicants?.length > 0) {
      const filtereredOptions = applicants?.map((item) => {
        return {
          value: item.applicantUid,
          label: `${item?.fullName}, ${item?.programDetails?.programName}`,
        };
      });
      setOptions([...filtereredOptions]);
    }
  }, [applicants]);
  ///  console.log(applicants,"applicants")

  useEffect(() => {
    if (id && applicants.length > 0) {
      const selectedApplication = applicants.filter(
        (item) => item?.applicantUid === id
      )[0];
      // delete selectedApplication.confirmationFormDetails
      ///  console.log('applicants123',applicants);
      ///   console.log('selectedApplication123',selectedApplication);
      if (selectedApplication) {
        const selectedOption = {
          value: selectedApplication?.applicantUid,
          label: `${selectedApplication?.fullName}, ${selectedApplication?.stage}`,
        };
        setSelectedOption(selectedOption);
        setApplicationIndex(applicants.indexOf(selectedOption));
      }
    }
  }, [applicants, id]);

  useEffect(() => {
    fetchApplicantApplication(id);
  }, [id, fetchApplicantApplication]);

  const checklist1 = [
    {
      id: 0,
      display: programDetails?.applicationFee,

      applicationStage: 'Application Fee',
      formName: 'applicationFee',
      databaseName: applicationDetails?.applicationFeePaid,
      show: applicationDetails?.applicationFeePaid ? true : false,
    },
    {
      id: 1,
      display: programDetails?.applicationInterests,

      applicationStage: 'Educational Interest',
      formName: 'applicationInterests',
      databaseName: applicationDetails?.applicationCommentsSet,
    },

    {
      id: 4,
      display: programDetails?.personalInformationForm,
      applicationStage: `Personal Information Form`,
      formName: 'personalInformationForm',
      databaseName: applicationDetails?.personalInformationFormCompleted,
      show: applicationDetails?.personalInformationFormCompleted ? true : false,
    },

    {
      id: 5,
      display: programDetails?.familyInformationForm,

      applicationStage: `Family Information Form`,
      formName: 'familyInformationForm',
      databaseName: applicationDetails?.familyInformationFormCompleted,
      show: applicationDetails?.familyInformationFormCompleted ? true : false,
    },
    {
      id: 6,
      applicationStage: `Academic Information`,
      display: programDetails?.academicInformationForm,
      formName: 'academicInformationForm',
      databaseName: applicationDetails?.academicInformationFormCompleted,
      show: applicationDetails?.academicInformationFormCompleted ? true : false,
    },
    {
      id: 12,
      display: programDetails?.recommendersForm,

      applicationStage: `Recommendations`,
      formName: 'recommendersForm',
      databaseName: applicationDetails?.recommendersFormCompleted,
      show: applicationDetails?.recommendersFormCompleted ? true : false,
    },
    {
      id: 7,
      display: programDetails?.extracurricularForm,

      applicationStage: `Extracurricular, Community Activities and Employment Information`,
      formName: 'extracurricularForm',
      databaseName: applicationDetails?.extracurricularFormCompleted,
      show: applicationDetails?.extracurricularFormCompleted ? true : false,
    },
    {
      id: 13,
      display: programDetails?.covidProtocolForm,

      applicationStage: `Covid Protocol`,
      formName: 'covidProtocolForm',
      databaseName: applicationDetails?.covidProtocolFormCompleted,
      show: applicationDetails?.covidProtocolFormCompleted ? true : false,
    },
    {
      id: 8,
      display: programDetails?.resumeForm,

      applicationStage: `Resume`,
      formName: 'resumeForm',
      databaseName: applicationDetails?.resumeFormCompleted,
      show: applicationDetails?.resumeFormCompleted ? true : false,
    },
    {
      id: 9,
      display: programDetails?.transcriptForm,

      applicationStage: `Transcript and Standardized Test Score`,
      formName: 'transcriptForm',
      databaseName: applicationDetails?.transcriptFormCompleted,
      show: applicationDetails?.transcriptFormCompleted ? true : false,
    },
    {
      id: 2,
      display: programDetails?.scholarshipForm,

      applicationStage: 'Scholarship Form',
      formName: 'scholarshipForm',
      databaseName: applicationDetails?.scholarshipFormCompleted,
      show: applicationDetails?.scholarshipFormCompleted ? true : false,
    },
    {
      id: 3,
      display: programDetails?.taxForm,

      applicationStage: `Tax Form's Uploaded`,
      formName: 'taxForm',
      databaseName: applicationDetails?.taxFormsUploaded,
      show: applicationDetails?.taxFormsUploaded ? true : false,
    },
    {
      id: 10,
      display: programDetails?.additionalDocumentsForm,

      applicationStage: `Additional Documents`,
      formName: 'additionalDocumentsForm',
      databaseName: applicationDetails?.additionalDocumentsFormCompleted,
      show: applicationDetails?.additionalDocumentsFormCompleted ? true : false,
    },
    {
      id: 11,
      display: programDetails?.shortResponsesForm,

      applicationStage: `Short Responses and Essay`,
      formName: 'shortResponsesForm',
      databaseName: applicationDetails?.shortResponsesFormCompleted,
      show: applicationDetails?.shortResponsesFormCompleted ? true : false,
    },
    {
      id: 13,
      display: true,
      applicationStage: `Confirmation`,
      formName: 'confirmationForm',
      databaseName: applicationDetails?.confirmationFormCompleted,
      show: applicationDetails?.confirmationFormCompleted ? true : false,
    },
  ];
  const checklist2 = [
    {
      id: 0,
      display: programDetails?.scholarConsentForm,

      applicationStage: 'Scholar Parent Consent',
      formName: 'scholarConsentForm',
      databaseName: applicationDetails?.scholarConsentFormCompleted,
      show: applicationDetails?.scholarConsentFormCompleted ? true : false,
    },
    {
      id: 1,
      display: programDetails?.governmentIdForm,

      applicationStage: 'Government ID & Headshot',
      formName: 'governmentIdForm',
      databaseName: applicationDetails?.governmentIdFormUploaded,
      show: applicationDetails?.governmentIdFormUploaded ? true : false,
    },
    {
      id: 2,
      display: programDetails?.paymentForm,

      applicationStage: 'Payment',
      formName: 'paymentForm',
      databaseName:
        applicationDetails?.payment1Received &&
        applicationDetails?.payment2Received,
      show:
        applicationDetails?.payment1Received &&
        applicationDetails?.payment2Received
          ? true
          : false,
    },
    {
      id: 3,
      applicationStage: 'Travel Form',
      display: programDetails?.travelInformationForm,

      formName: 'travelInformationForm',
      databaseName: applicationDetails?.travelInformationFormCompleted,
      show: applicationDetails?.travelInformationFormCompleted ? true : false,
    },
    {
      id: 4,
      display: programDetails?.studentQuestionnaireForm,

      applicationStage: 'Student Questionnaire',
      formName: 'studentQuestionnaireForm',
      databaseName: applicationDetails?.studentQuestionnaireFormCompleted,
      show: applicationDetails?.studentQuestionnaireFormCompleted
        ? true
        : false,
    },
    {
      id: 5,
      display: programDetails?.downloadProgramPacketForm,

      applicationStage: 'Download Program Packet',
      formName: 'downloadProgramPacketForm',
      databaseName: applicationDetails?.programPacketDownloaded,
      show: applicationDetails?.programPacketDownloaded ? true : false,
    },
    {
      id: 6,
      display: programDetails?.requiredDocumentationForm,

      applicationStage: 'Required Pre-Arrival Forms',
      formName: 'requiredDocumentationForm',
      databaseName: applicationDetails?.requiredDocumentationFormUploaded,
      show: applicationDetails?.requiredDocumentationFormUploaded
        ? true
        : false,
    },
  ];

  const handleChange = (selectedItem) => {
    setSelectedOption(selectedItem);
    history.push(`/admin/dashboard/applicant/${selectedItem.value}`);
  };

  const percentage = checklist1.every((stage) => stage.databaseName)
    ? (checklist2.filter((stage) => stage.databaseName).length /
        checklist2.length) *
      100
    : (checklist1.filter((stage) => stage.databaseName).length /
        checklist1.length) *
      100;

  const getStatus = (stage) => {
    if (
      stage.formName === 'downloadProgramPacketForm' ||
      stage.formName === 'requiredDocumentationForm' ||
      stage.formName === 'studentQuestionnaireForm'
    ) {
      const url = programDetails?.manualPacket
        ? programDetails?.programPacketDocuments[0].file.fileURL
        : applicationDetails?.institutionDetails?.programPacketURL;

      if (stage.formName === 'downloadProgramPacketForm') {
        return stage.databaseName && url ? (
          <Typography className={classes.downloadText}>
            <a href={url} target={'_blank'} rel={'noopener noreferrer'}>
              Download
            </a>
          </Typography>
        ) : (
          <Typography className={classes.pendingText}>Pending</Typography>
        );
      } else {
        return stage.databaseName && url ? (
          <Typography className={classes.completeText}>Completed</Typography>
        ) : (
          <Typography className={classes.pendingText}>Pending</Typography>
        );
      }
    }

    if (stage.databaseName) {
      if (stage.formName === 'downloadProgramPacketForm') {
        const url = programDetails?.manualPacket
          ? programDetails?.programPacketDocuments[0].file.fileURL
          : applicationDetails?.institutionDetails?.programPacketURL;
        return url ? (
          <Typography className={classes.downloadText}>
            <a href={url} target={'_blank'} rel={'noopener noreferrer'}>
              Download
            </a>
          </Typography>
        ) : (
          'Incomplete'
        );
      } else {
        return 'Completed';
      }
    } else {
      return 'Incomplete';
    }
  };

  const renderChecklist = (checklist) =>
    checklist.map(
      (stage) =>
        stage.display && (
          <Grid
            className={classnames(classes.checklist, {
              completed: stage.databaseName,
            })}
            container
            item
            alignItems="center"
          >
            <Checkbox
              checked={stage.databaseName ? true : false}
              checkedIcon={<TickIcon />}
              onChange={(event, checked) => {
                if (stage?.formName === 'recommendersForm' && checked)
                  updateApplicantApplication(
                    id,
                    'recommendersFormCompleted',
                    true
                  );
              }}
            />
            {/* <Box> */}
            <Typography className={classes.stageLabel}>
              {stage.applicationStage}
            </Typography>
            {/* </Box> */}
            <Typography
              className={classnames(classes.status, {
                complete: stage.databaseName,
                incomplete: !stage.databaseName,
              })}
            >
              {getStatus(stage)}
            </Typography>
          </Grid>
        )
    );

  return (
    <Grid className={classes.rootContainer} container direction="column">
      <Grid className={classes.container} item>
        <Typography variant="h4">
          {programDetails?.programName} Checklist
        </Typography>
      </Grid>
      <Grid item container justifyContent="center">
        <Grid item md={10} lg={8}>
          <Grid
            style={{ marginBottom: '1rem', gap: '1rem' }}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              item
              className={classes.backIcon}
              onClick={() => history.goBack()}
            >
              <BackIcon />
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Select
                value={selectedOption}
                onChange={(selectedItem) => handleChange(selectedItem)}
                options={options}
                isSearchable
                name="autocomplete"
                placeholder="Search application"
              />
            </Grid>
            <Grid item>
              <ButtonGroup color="primary">
                <Button
                  disabled={applicationIndex === 0}
                  onClick={() =>
                    history.push(
                      `/admin/dashboard/applicant/${
                        applicants[applicationIndex - 1].applicantUid
                      }`
                    )
                  }
                >
                  Prev
                </Button>
                <Button
                  disabled={applicationIndex === applicants.length - 1}
                  onClick={() =>
                    history.push(
                      `/admin/dashboard/applicant/${
                        applicants[applicationIndex + 1].applicantUid
                      }`
                    )
                  }
                >
                  Next
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Paper className={classes.paper} elevation={0}>
            <Grid
              className={classes.checklistContainer}
              container
              direction="column"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.viewApplicationButton}
                onClick={() => history.push(`/admin/reviewProgram?id=${id}`)}
              >
                View Application
              </Button>
              <Typography className={classes.heading} variant="h6">
                {applicationDetails?.fullName} Checklist
              </Typography>
              <Box width="60%" display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                  <LinearProgress
                    variant="determinate"
                    value={Math.ceil(percentage)}
                  />
                </Box>
                <Box minWidth={35}>
                  <Typography variant="body2" color="textSecondary">
                    {Math.ceil(percentage)}%
                  </Typography>
                </Box>
              </Box>
              {checklist1.every((stage) => stage?.databaseName == true)
                ? renderChecklist(checklist2)
                : renderChecklist(checklist1)}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  let applicationDetails = {};
  let programDetails = {};
  let applicants = [];

  if (state.applicantDashboard) {
    applicationDetails =
      state.applicantDashboard.recentApplication.applicationDetails;
    programDetails = state.applicantDashboard.recentApplication.programDetails;
  }

  if (
    state.adminPrograms.programDashboardValues &&
    state.adminPrograms?.programDashboardValues.tableData?.allApplicants
      ?.length > 0
  ) {
    applicants =
      state.adminPrograms.programDashboardValues.tableData.allApplicants;
  }

  return {
    applicationDetails,
    programDetails,
    applicants,
  };
};

const mapActionsToProps = {
  fetchApplicantApplication,
  updateApplicantApplication,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(AdminDashboardApplicantDetails);
