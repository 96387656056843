// import React, { Fragment, useEffect, useMemo, useState } from 'react';
// import { makeStyles, useTheme } from '@mui/styles';
// import { Button, Grid, Typography } from '@mui/material';
// import DropzoneInput from '../../../../dropzone/DropzoneInput';
// import { connect } from 'react-redux';
// import {
//   deleteTranscript,
//   deleteTranscripts,
//   uploadTranscript,
// } from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';
// import {
//   DataGridPremium,
//   GridToolbar,
//   LicenseInfo,
// } from '@mui/x-data-grid-premium';

// const useStyles = makeStyles((theme) => ({
//   dropzoneWrapper: {
//     padding: '3em',
//     [theme.breakpoints.down('md')]: {
//       padding: '1em',
//     },
//     [theme.breakpoints.down('sm')]: {
//       padding: '0.4em',
//       marginTop: '2em',
//     },
//     [theme.breakpoints.down('sm')]: {
//       padding: '0.3em',
//       marginTop: 0,
//     },
//   },
//   dropzone: {
//     marginTop: '0.5em',
//     width: '350px',
//     height: '150px',
//   },
//   button: {
//     margin: '0.4em',
//   },

//   formText: {
//     color: '#696974',
//     fontWeight: '400',
//     fontFamily: 'Roboto',
//     fontSize: '16px',
//     letter: '0.2px',
//   },
// }));

// const actions = {
//   uploadTranscript,
//   deleteTranscript,
//   deleteTranscripts,
// };

// const TranscriptFormUpload = ({
//   files,
//   setFiles,
//   registeredProgramId,
//   deleteTranscript,
//   deleteTranscripts,
//   uploadTranscript,
// }) => {
//   const classes = useStyles();
//   const theme = useTheme();

//   const [file, setFile] = useState(null);
//   // const [files, setFiles] = useState({});
//   const fileRows = [
//     {
//       id: 'Transcript',
//       name: 'Transcript',
//       due: 'Due Today',
//       required: true,
//     },
//     {
//       id: 'standardized',
//       name: 'Standardized Test Score',
//       due: 'Due Today',
//       required: true,
//     },
//   ];
//   const [fileRowsNew, setFileRowsNew] = useState(fileRows);

//   const [disabled, setDisabled] = useState(false);

//   const handleFileUpload = async () => {
//     setDisabled(true);
//     try {
//       await uploadTranscript(registeredProgramId, file);
//       setFile(null);
//       setDisabled(false);
//     } catch (error) {
//       console.log(error);
//       setDisabled(false);
//     }
//   };
//   const saveInterval = 5 * 60 * 1000;
//   useEffect(() => {
//     const saveFormInterval = setInterval(() => {
//       handleFileUpload();
//     }, saveInterval);
//     return () => clearInterval(saveFormInterval);
//   }, [file]);

//   useEffect(() => {
//     LicenseInfo.setLicenseKey(
//       'e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
//     );
//   }, []);
//   const handleDeleteTranscript = async (fileName) => {
//     setDisabled(true);
//     try {
//       await deleteTranscript(registeredProgramId, fileName);
//       setDisabled(false);
//     } catch (error) {
//       console.log(error);
//       setDisabled(false);
//     }
//   };
//   const memoizedColumns = useMemo(
//     () => [
//       {
//         field: 'name',
//         headerName: 'Document Name',
//         flex: 2,
//         sortable: false,
//         headerClassName: classes.headerBackground,
//         renderHeader: (params) => (
//           <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
//             {params.field}
//           </Typography>
//         ),
//       },
//       {
//         field: 'due',
//         headerName: 'Due',
//         sortable: false,
//         flex: 1,
//         headerClassName: classes.headerBackground,
//         renderHeader: (params) => (
//           <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
//             {params.field}
//           </Typography>
//         ),
//       },
//       {
//         field: 'upload',
//         headerName: 'Upload',
//         sortable: false,
//         headerClassName: classes.headerBackground,
//         renderHeader: (params) => (
//           <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
//             {params.field}
//           </Typography>
//         ),
//         renderCell: (params) => {
//           {
//             console.log(Object.keys(files).includes(params.id), 'lkjh');
//           }
//           if (!Object.keys(files).includes(params.id))
//             return (
//               <Button color="primary" component="label" size="small">
//                 Upload
//                 <input
//                   type="file"
//                   hidden
//                   onChange={(event) => {
//                     setFiles((prevFiles) => ({
//                       ...prevFiles,
//                       [params.id]: event.target.files,
//                     }));
//                     fileRowsNew.map((item) => {
//                       if (item.id === params.row.id) {
//                         item.upload = event?.target?.files[0]?.name;
//                       }
//                     });
//                     setFileRowsNew(fileRowsNew);
//                   }}
//                 />
//               </Button>
//             );
//           return params.value ? (
//             <>{params.value}</>
//           ) : (
//             <span style={{ color: '#3DD598' }}>UPLOADED</span>
//           );
//         },
//       },
//       {
//         field: 'delete',
//         headerName: 'Delete',
//         sortable: false,
//         headerClassName: classes.headerBackground,
//         renderHeader: (params) => (
//           <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
//             {params.field}
//           </Typography>
//         ),
//         renderCell: (params) => {
//           if (Object.keys(files).includes(params.id))
//             console.log(files[params.id][0].fileURL, 'params');
//           console.log(files, 'lkjhgf');

//           return (
//             <Button
//               // onClick={() => handleDeleteTranscript(item.fileName)}

//               onClick={() => {
//                 if (files[params.id][0].fileURL) {
//                   // handleDeleteTranscript(
//                   //   registeredProgramId,
//                   //   params.id,
//                   //   files[params.id]
//                   // );
//                   deleteTranscripts(
//                     registeredProgramId,
//                     params.id,
//                     files[params.id]
//                   );
//                   setFiles((prevFiles) => {
//                     const newFiles = {
//                       ...prevFiles,
//                     };
//                     delete newFiles[params.id];
//                     return newFiles;
//                   });
//                 } else {
//                   setFiles({});
//                 }
//               }}
//               color="secondary"
//               component="label"
//               size="small"
//             >
//               Delete
//             </Button>
//           );
//           return <></>;
//         },
//       },
//     ],
//     [files]
//   );
//   return (
//     // <Grid item style={{ marginTop: '1.5em', marginBottom: '1.5em' }}>
//     //   <Grid item container direction={'column'}>
//     //     {!file && (
//     //       <Fragment>
//     //         <Grid item>
//     //           <Typography className={classes.formText}>
//     //             Drop or Add File Here - files must be .jpeg, png, or pdf
//     //           </Typography>
//     //         </Grid>
//     //         <Grid item>
//     //           <Grid item container>
//     //             <Grid item className={classes.dropzone}>
//     //               <DropzoneInput setFiles={setFile} />
//     //             </Grid>
//     //           </Grid>
//     //         </Grid>
//     //       </Fragment>
//     //     )}

//     //     {file && (
//     //       <Grid item>
//     //         <Grid item container alignItems={'center'}>
//     //           <Grid item>
//     //             <Typography className={classes.formText}>
//     //               {file[0].name}
//     //             </Typography>
//     //           </Grid>
//     //           <Grid item style={{ marginLeft: '1em' }}>
//     //             <Button
//     //               className={classes.button}
//     //               color={'primary'}
//     //               variant={'contained'}
//     //               size={'small'}
//     //               onClick={() => handleFileUpload()}
//     //               disabled={disabled}
//     //             >
//     //               Upload
//     //             </Button>
//     //           </Grid>
//     //           <Grid item>
//     //             <Button
//     //               className={classes.button}
//     //               style={{
//     //                 backgroundColor: theme.palette.error.main,
//     //                 color: 'white',
//     //               }}
//     //               variant={'contained'}
//     //               size={'small'}
//     //               onClick={() => setFile(null)}
//     //             >
//     //               Delete
//     //             </Button>
//     //           </Grid>
//     //         </Grid>
//     //       </Grid>
//     //     )}
//     //   </Grid>
//     // </Grid>
//     <DataGridPremium
//       autoHeight
//       hideFooter
//       disableSelectionOnClick
//       disableColumnMenu
//       columns={memoizedColumns}
//       rows={fileRowsNew}
//       components={{ Toolbar: GridToolbar }}
//     />
//   );
// };

// export default connect(null, actions)(TranscriptFormUpload);
import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';
import DropzoneInput from '../../../../dropzone/DropzoneInput';
import { connect } from 'react-redux';
import { uploadTranscript } from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';

const useStyles = makeStyles((theme) => ({
  dropzoneWrapper: {
    padding: '3em',
    [theme.breakpoints.down('md')]: {
      padding: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0.4em',
      marginTop: '2em',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0.3em',
      marginTop: 0,
    },
  },
  dropzone: {
    marginTop: '0.5em',
    width: '350px',
    height: '150px',
  },
  button: {
    margin: '0.4em',
  },

  formText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
}));

const actions = {
  uploadTranscript,
};

const TranscriptFormUpload = ({ registeredProgramId, uploadTranscript }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [file, setFile] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const handleFileUpload = async () => {
    setDisabled(true);
    try {
      await uploadTranscript(registeredProgramId, file);
      setFile(null);
      setDisabled(false);
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };
  const saveInterval = 5 * 60 * 1000;
  useEffect(() => {
    const saveFormInterval = setInterval(() => {
      handleFileUpload();
    }, saveInterval);
    return () => clearInterval(saveFormInterval);
  }, [file]);

  return (
    <Grid item style={{ marginTop: '1.5em', marginBottom: '1.5em' }}>
      <Grid item container direction={'column'}>
        {!file && (
          <Fragment>
            <Grid item>
              <Typography className={classes.formText}>
                Drop or Add File Here - files must be .jpeg, png, or pdf
              </Typography>
            </Grid>
            <Grid item>
              <Grid item container>
                <Grid item className={classes.dropzone}>
                  <DropzoneInput setFiles={setFile} />
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        )}

        {file && (
          <Grid item>
            <Grid item container alignItems={'center'}>
              <Grid item>
                <Typography className={classes.formText}>
                  {file[0].name}
                </Typography>
              </Grid>
              <Grid item style={{ marginLeft: '1em' }}>
                <Button
                  className={classes.button}
                  color={'primary'}
                  variant={'contained'}
                  size={'small'}
                  onClick={() => handleFileUpload()}
                  disabled={disabled}
                >
                  Upload
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.button}
                  style={{
                    backgroundColor: theme.palette.error.main,
                    color: 'white',
                  }}
                  variant={'contained'}
                  size={'small'}
                  onClick={() => setFile(null)}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default connect(null, actions)(TranscriptFormUpload);
