import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Checkbox,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";

import Sidebar from "../../../../ui/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { combineValidators, isRequired } from "revalidate";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import TextInput from "../../../../common/form/TextInput";
import { useHistory, useLocation } from "react-router-dom";

import {
  fetchAffiliate,
  updateAffiliate,
  updateAffiliateField,
} from "../../../../store/actions/adminActions/affiliateActions/adminAffiliateActions";
import DatePickerInput from "../../../../common/form/DatePickerInput";
import CheckboxInput from "../../../../common/form/CheckboxInput";
import { fetchAllPrograms } from "../../../../store/actions/adminActions/programActions/adminProgramActions";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "0.4em",
    paddingBottom: "0.4em",
    width:'100%',
  },
  icon: {
    fontSize: "3em",
    color: theme.palette.common.textGrey,
  },
  dropzone: {
    width: 160,
    height: 160,
  },
  image: {
    width: 160,
    height: 160,
  },
}));

const validate = combineValidators({
  affiliateName: isRequired({ message: "Name is required" }),
  affiliateCodeExpiry: isRequired({ message: "Date is required" }),
});

const actions = {
  fetchAffiliate,
  updateAffiliate,
  updateAffiliateField,
  fetchAllPrograms,
};

const mapStateToProps = (state) => {
  let affiliate = {};
  let programs = [];

  const questionSelector = formValueSelector("editAffiliate");
  const fieldValues = questionSelector(
    state,
    "applicationFeeWaiver",
    "extendedDeadline",
    "extendedPaymentDeadline"
  );

  if (
    state.adminAffiliates.affiliate &&
    state.adminAffiliates.affiliate.length > 0
  ) {
    affiliate = state.adminAffiliates.affiliate[0];
  }

  if (
    state.adminPrograms.allPrograms &&
    state.adminPrograms.allPrograms.length > 0
  ) {
    programs = state.adminPrograms.allPrograms;
  }

  return {
    initialValues: {
      ...state.adminAffiliates.affiliate[0],
      programsAffiliated:
        state.adminAffiliates.affiliate[0]?.programsAffiliated || [],
    },
    loading: state.loading.loading,
    affiliate,
    programs,
    fieldValues,
  };
};

const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>;
  }
};

const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl error={touched && error} style={{ width: "50%" }} size="small">
    <InputLabel htmlFor="color-native-simple">{label}</InputLabel>
    <Select
      // native
      {...input}
      {...custom}
      variant="outlined"
      multiple
      inputProps={{
        name: input.name,
        id: "color-native-simple",
      }}
    >
      {children}
    </Select>
    {renderFromHelper({ touched, error })}
  </FormControl>
);

const AdminEditAffillate = ({
  fetchAffiliate,
  updateAffiliate,
  updateAffiliateField,
  affiliate,
  handleSubmit,
  loading,
  submitting,
  programs,
  change,
  fieldValues,
  fetchAllPrograms,
}) => {
  const classes = useStyles();
  const [, setReRender] = useState(false);
  // const theme = useTheme();
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => setReRender((prev) => !prev), [affiliate]);

  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get("id");

  useEffect(() => {
    fetchAffiliate(id);
  }, [fetchAffiliate, id]);

  useEffect(() => {
    if (affiliate.programsAffiliated && programs.length === 0)
      fetchAllPrograms();
  }, [programs, affiliate, fetchAllPrograms]);

  const handleUpdateAffiliate = (values) => {
    updateAffiliate(id, values);
    history.push("/admin/settings/affiliates");
  };

  return (
    <Grid container>
      <Grid item md={2}>
        <Sidebar />
      </Grid>

      <Grid item md={10} style={{ padding: "2rem" }}>
        <Grid item container direction={"column"}>
          <Grid item>
            <Typography variant={"h4"}>Edit Affiliate</Typography>
          </Grid>

          <form
            autoComplete={"off"}
            onSubmit={handleSubmit(handleUpdateAffiliate)}
          >
            <Grid item style={{ width: "50%" }}>
              <Grid item container direction={"column"}>
                <Grid item className={classes.fieldWrapper}>
                  <Field
                    name={"affiliateName"}
                    label={"Affiliate Name"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                  />
                </Grid>
                {programs.length > 0 && (
                  <Grid item className={classes.fieldWrapper}>
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      className={classes.fieldWrapper}
                    >
                      <Typography>Programs Affiliated To:</Typography>
                      <Field
                        name={"programsAffiliated"}
                        component={renderSelectField}
                        onChange={(val, selectedOptions) => {
                           if (change){
                              change("areaOfInterest", selectedOptions);
                          }
                        }}
                      >
                        <MenuItem value={""}></MenuItem>
                        {programs.map((program) => (
                          <MenuItem key={program.id} value={program.id}>
                            {program.programName}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                  </Grid>
                )}
                {affiliate.id && (
                  <>
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      className={classes.fieldWrapper}
                    >
                      <Typography>Application Fee Waiver</Typography>
                      <Field
                        name={"applicationFeeWaiver"}
                        component={CheckboxInput}
                        size="medium"
                        color="secondary"
                      />
                    </Grid>
                    {fieldValues.applicationFeeWaiver && (
                      <Grid item className={classes.fieldWrapper}>
                        <Field
                          name={"affiliateCodeExpiry"}
                          label={"Expiry Date"}
                          component={DatePickerInput}
                          type={"text"}
                          inputVariant={"outlined"}
                          size={"small"}
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      className={classes.fieldWrapper}
                    >
                      <Typography>Extended Application Deadline</Typography>
                      <Field
                        name={"extendedDeadline"}
                        component={CheckboxInput}
                        size="medium"
                        color="secondary"
                      />
                    </Grid>
                    {fieldValues.extendedDeadline && (
                      <Grid item className={classes.fieldWrapper}>
                        <Field
                          name={"extendedDeadlineDate"}
                          label={"Extended Application Deadline Date"}
                          component={DatePickerInput}
                          type={"text"}
                          inputVariant={"outlined"}
                          size={"small"}
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      className={classes.fieldWrapper}
                    >
                      <Typography>Extended Payment Deadline</Typography>
                      <Field
                        name={"extendedPaymentDeadline"}
                        component={CheckboxInput}
                        size="medium"
                        color="secondary"
                      />
                    </Grid>
                    {fieldValues.extendedPaymentDeadline && (
                      <Grid item className={classes.fieldWrapper}>
                        <Field
                          name={"extendedPaymentDeadlineDate"}
                          label={"Extended Payment Deadline Date"}
                          component={DatePickerInput}
                          type={"text"}
                          inputVariant={"outlined"}
                          size={"small"}
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      className={classes.fieldWrapper}
                    >
                      <Typography>Scholarship Waiver</Typography>
                      <Field
                        name={"scholarshipWaiver"}
                        component={CheckboxInput}
                        size="medium"
                        color="secondary"
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      className={classes.fieldWrapper}
                    >
                      <Typography>Sponsorship</Typography>
                      <Field
                        name={"sponsorship"}
                        component={CheckboxInput}
                        size="medium"
                        color="secondary"
                      />
                    </Grid>
                  </>
                )}
                {/*<Grid item className={classes.fieldWrapper}>*/}
                {/*  <Field*/}
                {/*    name={"institutionAddress1"}*/}
                {/*    label={"Address 1"}*/}
                {/*    component={TextInput}*/}
                {/*    type={"text"}*/}
                {/*    variant={"outlined"}*/}
                {/*  />*/}
                {/*</Grid>*/}
                {/*<Grid item className={classes.fieldWrapper}>*/}
                {/*  <Field*/}
                {/*    name={"institutionAddress2"}*/}
                {/*    label={"Address 2"}*/}
                {/*    component={TextInput}*/}
                {/*    type={"text"}*/}
                {/*    variant={"outlined"}*/}
                {/*  />*/}
                {/*</Grid>*/}
                {/*<Grid item className={classes.fieldWrapper}>*/}
                {/*  <Field*/}
                {/*    name={"institutionCity"}*/}
                {/*    label={"City"}*/}
                {/*    component={TextInput}*/}
                {/*    type={"text"}*/}
                {/*    variant={"outlined"}*/}
                {/*  />*/}
                {/*</Grid>*/}
                {/*<Grid item className={classes.fieldWrapper}>*/}
                {/*  <Field*/}
                {/*    name={"institutionState"}*/}
                {/*    label={"State"}*/}
                {/*    component={SelectInput}*/}
                {/*    type={"text"}*/}
                {/*    variant={"outlined"}*/}
                {/*  >*/}
                {/*    {states.map((state) => (*/}
                {/*      <MenuItem key={state.value} value={state.text}>*/}
                {/*        {state.text}*/}
                {/*      </MenuItem>*/}
                {/*    ))}*/}
                {/*  </Field>*/}
                {/*</Grid>*/}
                {/*<Grid item className={classes.fieldWrapper}>*/}
                {/*  <Field*/}
                {/*    name={"institutionZipCode"}*/}
                {/*    label={"Zip"}*/}
                {/*    component={TextInput}*/}
                {/*    type={"text"}*/}
                {/*    variant={"outlined"}*/}
                {/*  />*/}
                {/*</Grid>*/}
                {/*<Grid item className={classes.fieldWrapper}>*/}
                {/*  <Field*/}
                {/*    name={"institutionPhone"}*/}
                {/*    label={"Phone"}*/}
                {/*    component={TextInput}*/}
                {/*    type={"text"}*/}
                {/*    variant={"outlined"}*/}
                {/*  />*/}
                {/*</Grid>*/}
                {/*<Grid item className={classes.fieldWrapper}>*/}
                {/*  <Field*/}
                {/*    name={"institutionEmail"}*/}
                {/*    label={"Email"}*/}
                {/*    component={TextInput}*/}
                {/*    type={"text"}*/}
                {/*    variant={"outlined"}*/}
                {/*  />*/}
                {/*</Grid>*/}
                {/*<Grid item className={classes.fieldWrapper}>*/}
                {/*  <Field*/}
                {/*    name={"institutionWeb"}*/}
                {/*    label={"Web"}*/}
                {/*    component={TextInput}*/}
                {/*    type={"text"}*/}
                {/*    variant={"outlined"}*/}
                {/*  />*/}
                {/*</Grid>*/}

                {/*<Grid item className={classes.fieldWrapper}>*/}
                {/*  <Field*/}
                {/*    name={"institutionNotes"}*/}
                {/*    label={"Institution Notes"}*/}
                {/*    component={TextArea}*/}
                {/*    rows={5}*/}
                {/*    type={"text"}*/}
                {/*    variant={"outlined"}*/}
                {/*  />*/}
                {/*</Grid>*/}

                {/*BUTTONS*/}
                <Grid item style={{ marginLeft: "auto", marginTop: "1rem" }}>
                  <Button
                    variant={"outlined"}
                    color={"primary"}
                    className={classes.button}
                    type={"submit"}
                    disabled={submitting}
                  >
                    Update Affiliate
                  </Button>

                  <Button
                    variant={"outlined"}
                    color={"secondary"}
                    className={classes.button}
                    onClick={() => history.push("/admin/settings/affiliates")}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({ form: "editAffiliate", enableReinitialize: true, validate })(
    AdminEditAffillate
  )
);
