import React from "react";
import { makeStyles } from "@mui/styles";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { Field } from "redux-form";
import SelectInput from "../../common/form/SelectInput";

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  fieldWrapper: {
    paddingTop: "0.4em",
    paddingBottom: "0.4em",
  },
  questionText: {
    color: "#696974",
    fontWeight: "600",
    fontFamily: "Roboto",
    fontSize: "16px",
    letter: "0.2px",
    paddingRight: "1em",
  },
}));

const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>;
  }
};

export const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl error={touched && error} style={{ width: "100%" }} size="small">
    <InputLabel htmlFor="color-native-simple">{label}</InputLabel>
    <Select
      // native
      {...input}
      {...custom}
      variant="outlined"
      inputProps={{
        name: input.name,
        id: "color-native-simple",
      }}
    >
      {children}
    </Select>
    {renderFromHelper({ touched, error })}
  </FormControl>
);

const SelectInputForm = ({
  question,
  fieldName,
  options,
  text,
  multiple,
  required,
  disabled
}) => {
  const classes = useStyles();
  // const theme = useTheme();
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid
      item
      container
      className={classes.fieldContainer}
      alignItems={"center"}
    >
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Grid item container direction={"column"}>
          <Grid item>
            <Typography variant={"body1"} className={classes.questionText}>
              {question}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Grid item>
          <Grid item container direction={"column"}>
            <Grid item className={classes.fieldWrapper}>
              <Field
                name={fieldName}
                component={renderSelectField}
                type={"text"}
                variant={"outlined"}
                multiple={multiple}
                required={required}
                disabled={disabled}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {text ? option.text : option.value}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectInputForm;
