import React from "react";
import { makeStyles } from "@mui/styles";
import Iframe from "react-iframe";
import { Grid, Typography } from "@mui/material";
import ReviewProgramItem from "./item/ReviewProgramItem";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  answerText: {
    fontWeight: 600,
  },
}));

const AdditionalDocumentsFormReview = ({ additionalDocumentsForm }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction={"column"}
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
    >
      <Grid item>
        <Typography className={classes.title}>Additional Documents</Typography>
      </Grid>

      {additionalDocumentsForm.map((form) => (
        <>
          {/* <ReviewProgramItem
            key={form.id}
            question={form.originalFileName}
            value={
              <a
                href={form.fileURL}
                target={"_blank"}
                rel={"noopener noreferrer"}>
                Link
              </a>
            }
            
          /> */}
          <ReviewProgramItem
            key={form.id}
            question={form.originalFileName}
            value={
              <a
                href={form.fileURL}
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                Open in New Tab
              </a>
            }
          />
          {console.log(form, "form")}
          <Iframe
            url={form.fileURL}
            width="100%"
            height="800px"
            id=""
            className=""
            display="block"
            position="relative"
          />
        </>
      ))}
    </Grid>
  );
};

export default AdditionalDocumentsFormReview;
