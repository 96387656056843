import { createReducer } from "../../../../common/util/reducerUtil";
import {
    FETCH_ALL_REVIEWER_COMPLETED_APPLICATIONS,
    FETCH_ALL_REVIEWER_REVIEWED_APPLICATIONS
} from "../../../constants/reviewerConstants/dashboardConstants/dashboardConstants";




const initialState = {
    allCompletedApplications: [],
    allReviewedApplications: [],
};


const getAllCompletedApplications = (state, payload) => {
    return {
        ...state,
        allCompletedApplications: payload.completedApplications,
    };
};

const getAllReviewedApplications = (state, payload) => {
    return {
        ...state,
        allReviewedApplications: payload.reviewedApplications,
    };
};


export default createReducer(initialState, {
    [FETCH_ALL_REVIEWER_COMPLETED_APPLICATIONS]: getAllCompletedApplications,
    [FETCH_ALL_REVIEWER_REVIEWED_APPLICATIONS]: getAllReviewedApplications,
});
