import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  Paper,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Sidebar from '../../../ui/sidebar/Sidebar';
import { connect } from 'react-redux';
import DashboardApplicantsTable from '../../../ui/admin/dashboard/DashboardApplicantsTable';
import { useHistory } from 'react-router-dom';
import { Field } from 'redux-form';
import TextInput from '../../../common/form/TextInput';
import { reduxForm } from 'redux-form';
import { combineValidators } from 'revalidate';
import { isRequired } from 'revalidate';
import { sendGenericEmail } from '../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import TextEditor from '../../../common/TextEditor/TextEditor';
import SendEmailDialog from '../../../dialogs/admin/SendEmailDialog';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5em',
    backgroundColor: 'rgb(250, 250, 251)',
  },
  heading: {
    marginBottom: '3rem',
  },
  tableContainer: {
    padding: '1rem',
    gap: '1rem',
  },
  applicationsLength: {
    marginLeft: '1rem',
  },
}));

const validate = combineValidators({
  subject: isRequired({ message: 'Required' }),
  content: isRequired({ message: 'Required' }),
  to: isRequired({ message: 'Required' }),
});

const mapStateToProps = (state) => {
  let applicants = [];

  if (
    state.adminDashboard?.dashboardValues &&
    state.adminDashboard.dashboardValues?.tableData?.allApplicants.length > 0
  ) {
    applicants =
      state.adminDashboard?.dashboardValues?.tableData?.allApplicants;
  }

  return {
    applicants,
    profile: state.firebase.profile,
  };
};

const AdminDashboardApplicants = ({
  applicants,
  submitting,
  handleSubmit,
  sendGenericEmail,
  change,
  profile,
}) => {
  const matches = useMediaQuery('(min-width:480px)');
  const history = useHistory();
  const classes = useStyles();
  const [selectionModel, setSelectionModel] = useState([]);
  const [editorValue, setEditorValue] = useState('');
  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    const emails = selectionModel
      .map((id) => {
        const application = applicants.find(
          (applicant, index) => index + 1 === id
        );
        if (application) return application.email;
        return '';
      })
      .filter((email) => email && email);
    let updatedTo = '';
    emails.forEach((email) => (updatedTo += `${email},`));
    change('to', updatedTo);
  }, [selectionModel, applicants]);

  const handleSendEmail = async (values) => {
    const emails = [];
    if (values.to)
      values.to.split(',').forEach((email) => email && emails.push(email));
    if (emails.length > 0) {
      await sendGenericEmail(
        values.subject,
        editorValue,
        emails,
        profile.fullName
      );
    }
    setDialog(false);
  };

  return (
    <Grid container>
      <Grid item md={2} style={{ width: `${matches ? '' : '100%'}` }}>
        <Sidebar />
      </Grid>
      <Grid className={classes.container} item md={10}>
        <Typography className={classes.heading} variant="h5">
          Dashboard
        </Typography>
        <Paper elevation={0}>
          <Grid className={classes.tableContainer} container direction="column">
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex">
                <Typography variant="h6">
                  Total Registered Applications
                </Typography>
                <Typography className={classes.applicationsLength} variant="h6">
                  {applicants.length}
                </Typography>
              </Box>
              <Box
                display="flex"
                // gridGap={16}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setDialog(true)}
                >
                  Send Email
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => history.push('/admin/dashboard/emailLogs')}
                >
                  View Email
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push('/admin/dashboard')}
                >
                  Dashboard
                </Button>
              </Box>
            </Grid>
            <DashboardApplicantsTable
              applicants={applicants}
              showFilterComponents
              withdrawButton
              checkboxSelection
              selectionModel={selectionModel}
              setSelectionModel={setSelectionModel}
            />
            <SendEmailDialog
              isOpen={dialog}
              closeDialog={() => setDialog(false)}
              handleSubmit={handleSubmit}
              submitting={submitting}
              handleSendEmail={handleSendEmail}
              editorValue={editorValue}
              setEditorValue={setEditorValue}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, {
  sendGenericEmail,
})(reduxForm({ form: 'sendEmail', validate })(AdminDashboardApplicants));
