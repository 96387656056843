import {
    asyncActionError,
    asyncActionFinish,
    asyncActionStart,
} from "../../asyncActions/asyncActions";
import {
    FETCH_ALL_REVIEWER_COMPLETED_APPLICATIONS,
    FETCH_ALL_REVIEWER_REVIEWED_APPLICATIONS
} from "../../../constants/reviewerConstants/dashboardConstants/dashboardConstants";


export const fetchAllReviewerCompletedApplications = (profileId) => {
    return async (dispatch, getState, { getFirestore, getFirebase }) => {
        const firestore = getFirestore();

        const applicationQuery = firestore
            .collection("registeredPrograms")
            .where("applicationCompleted", "==", true)
            .where('reviewerId', '==', `${profileId}`)
            .orderBy("fullName", "asc");

        const programsQuery = firestore.collection("programs");
        const usersQuery = firestore.collection("users")
            .doc(profileId);

        try {
            dispatch(asyncActionStart());
            let query = await applicationQuery.get();
            const programs = await programsQuery.get();
            const reviewer = await usersQuery.get();

            let completedApplications = [];

            for (let i = 0; i < query.docs.length; i++) {
                let program = programs.docs.find(program => program.id === query.docs[i].data().programId);
                
                let application = {
                    ...query.docs[i].data(),
                    id: query.docs[i].id,
                    programDetails: program.data(),
                    reviewerDetails: reviewer.data(),
                };
                completedApplications.push(application);
            }
            dispatch({
                type: FETCH_ALL_REVIEWER_COMPLETED_APPLICATIONS,
                payload: { completedApplications },
            });
            dispatch(asyncActionFinish());
        } catch (error) {
            dispatch(asyncActionError());
            console.log(error);
        }
    };
};

export const fetchAllReviewerReviewedApplications = (profileId) => {
    return async (dispatch, getState, { getFirestore, getFirebase }) => {
        const firestore = getFirestore();

        const applicationQuery = firestore
            .collection("registeredPrograms")
            .where("reviewStatus", "==", "reviewed").where('reviewerId', '==', `${profileId}`)
            .orderBy("fullName", "asc");

        const programsQuery = firestore.collection("programs");
        const usersQuery = firestore.collection("users");

        try {
            dispatch(asyncActionStart());
            let query = await applicationQuery.get();

            let reviewedApplications = [];

            for (let i = 0; i < query.docs.length; i++) {
                let program = await programsQuery
                    .doc(`${query.docs[i].data().programId}`)
                    .get();
                let reviewer = await usersQuery
                    .doc(`${query.docs[i].data().reviewerId}`)
                    .get();

                let application = {
                    ...query.docs[i].data(),
                    id: query.docs[i].id,
                    programDetails: program.data(),
                    reviewerDetails: reviewer.data(),
                };
                reviewedApplications.push(application);
            }
            dispatch({
                type: FETCH_ALL_REVIEWER_REVIEWED_APPLICATIONS,
                payload: { reviewedApplications },
            });
            dispatch(asyncActionFinish());
        } catch (error) {
            dispatch(asyncActionError());
            console.log(error);
        }
    };
};