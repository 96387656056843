import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import ReviewProgramItem from "./item/ReviewProgramItem";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  answerText: {
    fontWeight: 600,
  },
}));

const RequiredDocumentationFormReview = ({ requiredDocuments }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction={"column"}
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
    >
      {/*APPLICATION FEE*/}
      <Grid item>
        <Typography className={classes.title}>Required Documents</Typography>
      </Grid>

      {/*APPLICATION FEE*/}
      {requiredDocuments &&
        Object.keys(requiredDocuments).length > 0 &&
        Object.keys(requiredDocuments).map((key,index) => {
          if (requiredDocuments[key].length) {
            return requiredDocuments[key].map((item, i) => (
              <ReviewProgramItem
                key={i}
                question={item?.originalFileName}
                value={
                  <a
                    href={item.fileURL}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                  >
                    Link
                  </a>
                }
              />
            ));
          }else{
            return (
              requiredDocuments[key].fileURL && (
                <ReviewProgramItem
                  key={index}
                  question={requiredDocuments[key]?.originalFileName}
                  value={
                    <a
                      href={requiredDocuments[key].fileURL}
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                    >
                      Link
                    </a>
                  }
                />
              )
            );
          }
        })}
    </Grid>
  );
};

export default RequiredDocumentationFormReview;
