import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Grid, Typography, Button, Dialog, DialogContent } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import {
  saveGovernmentIdForm,
  deleteGovernmentIdFile,
} from '../../../../../../store/actions/applicantActions/programActions/applicantApprovedActions';
import {
  DataGridPremium,
  GridToolbar,
  LicenseInfo,
} from '@mui/x-data-grid-premium';
import { closeDialog } from '../../../../../../store/actions/dialogActions/dialogActions';
import toast, { Toaster } from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  questionText: {
    fontWeight: 600,
    paddingRight: '1em',
  },
  button: {
    marginLeft: '0.4em',
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
    },
  },

  continueButton: {
    marginLeft: '0.4em',
    backgroundColor: '#1976d2',
    color: 'white !Important',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
      //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    },
  },
  headerBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}));

const actions = {
  saveGovernmentIdForm,
  deleteGovernmentIdFile,
};

const fileRows = [
  {
    id: 'governmentId',
    name: 'Government ID (Front)',
    due: 'Due Today',
    required: true,
  },
  {
    id: 'headshot',
    name: 'Headshot',
    due: 'Due Today',
    required: true,
  },
];

const GovernmentIdForm = ({
  registeredProgramId,
  registeredProgram,
  saveGovernmentIdForm,
  deleteGovernmentIdFile,
  handleCloseForm,
  handleSetForm,
  submitting,
  program,
  profile,
  onReload,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [files, setFiles] = useState({});
  const [fileRowsNew, setFileRowsNew] = useState(fileRows);
  const [open, setOpen] = useState(false);

  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  // const [exit, setExit] = useState(false);

  const exit = false;
  console.log(program, 'program');
  console.log(files, 'files');
  const handleClose = () => {
    setOpen(false);
    dispatch(closeDialog());
  };
  useEffect(() => {
    LicenseInfo.setLicenseKey(
      'e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
    );
  }, []);

  useEffect(() => {
    if (registeredProgram.governmentIds) {
      setFiles(registeredProgram.governmentIds);
    }
  }, [registeredProgram.governmentIds]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const programsAllThings = [
    'scholarConsentForm',
    'governmentIdForm',
    'paymentForm',
    // "travelInformationForm",
    'studentQuestionnaireForm',
    'downloadProgramPacketForm',
    'requiredDocumentationForm',
  ];
  const programAllThingsValue = {
    scholarConsentForm: 'scholarConsentForm',
    governmentIdForm: 'governmentIdForm',
    paymentForm: 'paymentForm',
    // travelInformationForm: "travelInformationForm",
    studentQuestionnaireForm: 'studentQuestionnaireForm',
    downloadProgramPacketForm: 'downloadProgramPacketForm',
    requiredDocumentationForm: 'requiredDocumentationForm',
  };
  let dataToCheck = programsAllThings
    .filter((item) => program[item])
    .map((item) => programAllThingsValue[item]);
  const memoizedColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Document Name',
        flex: 2,
        sortable: false,
        headerClassName: classes.headerBackground,
        renderHeader: (params) => (
          <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
            {params.field}
          </Typography>
        ),
      },
      {
        field: 'due',
        headerName: 'Due',
        sortable: false,
        flex: 1,
        headerClassName: classes.headerBackground,
        renderHeader: (params) => (
          <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
            {params.field}
          </Typography>
        ),
      },
      {
        field: 'upload',
        headerName: 'Upload',
        sortable: false,
        headerClassName: classes.headerBackground,
        renderHeader: (params) => (
          <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
            {params.field}
          </Typography>
        ),
        renderCell: (params) => {
          {
            console.log(Object.keys(files).includes(params.id), 'lkjh');
          }
          if (!Object.keys(files).includes(params.id))
            return (
              <Button color="primary" component="label" size="small">
                Upload
                <input
                  type="file"
                  hidden
                  onChange={(event) => {
                    setFiles((prevFiles) => ({
                      ...prevFiles,
                      [params.id]: event.target.files,
                    }));
                    fileRowsNew.map((item) => {
                      if (item.id === params.row.id) {
                        item.upload = event?.target?.files[0]?.name;
                      }
                    });
                    setFileRowsNew(fileRowsNew);
                  }}
                />
              </Button>
            );
          return params.value ? (
            <>{params.value}</>
          ) : (
            <span style={{ color: '#3DD598' }}>UPLOADED</span>
          );
        },
      },
      {
        field: 'delete',
        headerName: 'Delete',
        sortable: false,
        headerClassName: classes.headerBackground,
        renderHeader: (params) => (
          <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
            {params.field}
          </Typography>
        ),
        renderCell: (params) => {
          if (Object.keys(files).includes(params.id))
            console.log(files[params.id], 'params');

          return (
            <Button
              color="secondary"
              component="label"
              size="small"
              onClick={() => {
                if (files[params.id][0].fileURL)
                  deleteGovernmentIdFile(
                    registeredProgramId,
                    params.id,
                    files[params.id]
                  );
                setFiles((prevFiles) => {
                  const newFiles = {
                    ...prevFiles,
                  };
                  delete newFiles[params.id];
                  return newFiles;
                });
              }}
            >
              Delete
            </Button>
          );
          return <></>;
        },
      },
    ],
    [
      classes.headerBackground,
      files,
      fileRowsNew,
      deleteGovernmentIdFile,
      registeredProgramId,
    ]
  );
  const notify = () => toast.success('Progress Autosaved.');

  const saveInterval = 5 * 60 * 1000;
  useEffect(() => {
    // ... other code ...

    // Create an interval to call saveGovernmentIdForm every 5 seconds
    const intervalId = setInterval(async () => {
      if (Object.keys(files).length > 0) {
        await saveGovernmentIdForm(registeredProgramId, files, false);
        notify();
        // handleCloseForm();
      }
    }, saveInterval);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  });

  return (
    <Fragment>
      <Toaster />

      <Typography variant={'h6'} style={{ fontWeight: 600, marginTop: '1em' }}>
        Government ID & Headshot
      </Typography>
      <Typography variant={'body1'} style={{ marginTop: '1em' }}>
        Upload copies of your Government ID
      </Typography>
      <Typography variant={'body1'} style={{ marginBottom: '1em' }}>
        Upload headshot taken within the past 6 months with a white background
        conforming to photograph requirements․
      </Typography>
      <DataGridPremium
        autoHeight
        hideFooter
        disableSelectionOnClick
        disableColumnMenu
        columns={memoizedColumns}
        rows={fileRowsNew}
        components={{ Toolbar: GridToolbar }}
      />
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        style={{ marginTop: '1.5rem' }}
      >
        <Button
          variant={'outlined'}
          color={'primary'}
          className={classes.button}
          onClick={async () => {
            await saveGovernmentIdForm(registeredProgramId, files, false).then(
              () => onReload()
            );
            handleCloseForm();
          }}
        >
          Save for Later
        </Button>
        <Button
          variant={'outlined'}
          color={'primary'}
          className={classes.continueButton}
          disabled={
            fileRows.filter((file) => file.required).length !==
            Object.keys(files).length
          }
          onClick={async () => {
            await saveGovernmentIdForm(registeredProgramId, files, true).then(
              () => onReload()
            );
            const indexOfPersonalInfoForm =
              dataToCheck.indexOf('governmentIdForm');
            if (
              indexOfPersonalInfoForm !== -1 &&
              indexOfPersonalInfoForm < dataToCheck.length - 1
            ) {
              const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
              const nextForms = dataToCheck[indexOfPersonalInfoForm + 2];

              console.log(nextForms, 'nextForms');
              console.log(nextForm, 'nextForm');

              if (nextForm == 'paymentForm' && program.freeProgram == true) {
                handleSetForm(nextForms);
              }
              if (nextForm == 'paymentForm' && !program.freeProgram) {
                handleSetForm(nextForm);
              }
              if (nextForm !== 'paymentForm') {
                handleSetForm(nextForm);
              }
            }
          }}
        >
          Continue{' '}
          <span>
            <strong> &#8594;</strong>
          </span>
        </Button>
        <Button
          variant={'outlined'}
          color={'secondary'}
          className={classes.button}
          onClick={() => handleCloseForm()}
        >
          Cancel
        </Button>
      </Grid>
    </Fragment>
  );
};

export default connect(null, actions)(GovernmentIdForm);
