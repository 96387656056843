import React from "react";
import { Grid, Typography } from "@mui/material";
import ReviewProgramItem from "./item/ReviewProgramItem";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  answerText: {
    fontWeight: 600,
  },
}));

const ApplicationFeePaidReview = ({ program }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction={"column"}
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
    >
      {/*APPLICATION FEE*/}
      <Grid item>
        <Typography className={classes.title}>Application Fee</Typography>
      </Grid>

      {/*APPLICATION FEE*/}
      <ReviewProgramItem
        question={"Application Fee Paid"}
        value={program.applicationFeePaid ? "Yes" : "No"}
      />

      <ReviewProgramItem
        question={"Educational Interest"}
        value={program.areaOfInterest}
      />

      <ReviewProgramItem
        question={"Application Comments"}
        value={program.applicationComments}
        flexColumn
      />

      {/*/!*UPLIFT CODE*!/*/}
      {/*<Grid item>*/}
      {/*  <Typography variant={"h6"} className={classes.title}>*/}
      {/*    Uplift Applicant Code*/}
      {/*  </Typography>*/}
      {/*</Grid>*/}
      {/*<ReviewProgramItem*/}
      {/*  question={"Uplift applicant code"}*/}
      {/*  value={program.applicantUpliftCode ? program.applicantUpliftCode : "N/A"}*/}
      {/*/>*/}

      {/*/!*APPLICATION COMMENTS*!/*/}
      {/*<Grid item>*/}
      {/*  <Typography variant={"h6"} className={classes.title}>*/}
      {/*    Application Comments*/}
      {/*  </Typography>*/}
      {/*</Grid>*/}
      {/*<ReviewProgramItem*/}
      {/*    question={program.applicationComments ? program.applicationComments : ""}*/}
      {/*    value={''}*/}
      {/*/>*/}
    </Grid>
  );
};

export default ApplicationFeePaidReview;
