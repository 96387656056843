import React, { Fragment } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import { Field, FieldArray } from "redux-form";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TextInputFieldArray from "../../../../common/form/TextInputFieldArray";

import DeleteGarbage from "../../../../assets/dashboard/delete_garbage.png";

const useStyles = makeStyles((theme) => ({
  questionText: {
    // color: '#696974',
    fontWeight: "600",
    fontFamily: "Roboto",
    fontSize: "16px",
    letter: "0.2px",
    color: "black",
  },
  fieldWrapper: {
    paddingTop: "0.4em",
    paddingBottom: "0.4em",
  },
}));

const ProgramInterests = () => {
  const classes = useStyles();
  const theme = useTheme();

  // const amountRequired = (value) => (value ? undefined : "Required");
  // const descriptionRequired = (value) => (value ? undefined : "Required");

  const interestFields = ({ fields, meta: { error, submitFailed } }) => (
    <Fragment>
      <Grid
        item
        container
        className={classes.fieldContainer}
        alignItems={"center"}
        // justifyContent={"center"}
      >
        <Grid item>
          <IconButton
            onClick={() => {
              fields.push("");
            }}
          >
            <AddCircleIcon color={"primary"} />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant={"subtitle1"}>Add Interest</Typography>
        </Grid>
      </Grid>
      {fields.map((interest, index) => (
        <Fragment key={index}>
          <Grid
            item
            container
            style={{
              marginTop: "1.5em",
              marginBottom: "3px",
              justifyContent: "space-between",
            }}
            alignItems={"center"}
          >
            <Grid item>
              <Typography className={classes.questionText}>
                <i> Interest #{index + 1}</i>
              </Typography>
            </Grid>
          </Grid>
          {/*FIELDS*/}
          <div style={{ display: "flex" }}>
            <Field
              name={interest}
              label={"Interest"}
              component={TextInputFieldArray}
              type={"text"}
              variant={"outlined"}
            />
            <IconButton onClick={() => fields.remove(index)}>
              <img src={DeleteGarbage} alt="delete_icon" />
            </IconButton>
          </div>
        </Fragment>
      ))}
    </Fragment>
  );
  return (
    <Grid container justifyContent={"center"} direction={"column"}>
      <FieldArray name="interests" component={interestFields} />
    </Grid>
  );
};

export default ProgramInterests;
