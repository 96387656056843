import { createReducer } from "../../../common/util/reducerUtil";
import {FETCH_RECOMMENDER_APPLICANT} from "../../constants/recommenderConstants/recommenderConstants";

const initialState = {
    recommenderApplicant: [],
};

const getRecommenderApplicant = (state, payload) => {
    return {
        ...state,
        recommenderApplicant: payload.applicant,
    };
};


export default createReducer(initialState, {
    [FETCH_RECOMMENDER_APPLICANT]: getRecommenderApplicant,
});
