import React, { Fragment } from "react";
import { makeStyles } from "@mui/styles";

import Typography from "@mui/material/Typography";

import { DatePicker } from "@mui/lab";
import { TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
    fontWeight: 300,
  },
}));

const KeyboardDateInput = ({
  showCurrentDate,
  input: { onBlur, value, ...inputProps },
  meta: { submitting, touched, error },
  ...others
}) => {
  const classes = useStyles();

  const onChange = (date) => {
    Date.parse(date)
      ? inputProps.onChange(date.toISOString())
      : inputProps.onChange(null);
  };

  return (
    <Fragment>
      <DatePicker
        {...inputProps}
        {...others}
        format="MM/dd/yyyy"
        value={value ? new Date(value) : showCurrentDate ? new Date() : null}
        disabled={submitting}
        onBlur={() =>
          onBlur(
            value
              ? new Date(value).toISOString()
              : showCurrentDate
              ? new Date()
              : null
          )
        }
        error={error && touched}
        onChange={onChange}
        autoOk={true}
        renderInput={(props) => (
          <TextField {...props} variant="outlined" size="small" fullWidth />
        )}
      />
      {touched && error && (
        <Typography variant={"subtitle1"} className={classes.error}>
          {error}
        </Typography>
      )}
    </Fragment>
  );
};

export default KeyboardDateInput;
