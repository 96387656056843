import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, Paper, Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Sidebar from '../../../ui/sidebar/Sidebar';
import { connect } from 'react-redux';
import DashboardApplicantsTable from '../../../ui/admin/dashboard/DashboardApplicantsTable';
import { useHistory } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { combineValidators } from 'revalidate';
import { isRequired } from 'revalidate';
import { sendGenericEmail } from '../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import SendEmailDialog from '../../../dialogs/admin/SendEmailDialog';
import SendEmailDialogs from '../../../dialogs/admin/SendEmailDialogs';

import { makeStyles } from '@mui/styles';
import { fetchProgramDashboardValues } from '../../../store/actions/adminActions/programActions/adminProgramActions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const mapStateToProps = (state) => {
  let program = {};
  let applicants = [];

  if (
    state.adminPrograms.adminProgram &&
    state.adminPrograms.adminProgram.length > 0
  ) {
    program = state.adminPrograms.adminProgram[0];
  }

  if (state.adminPrograms.programDashboardValues) {
    if (state.adminPrograms.programDashboardValues.tableData) {
      if (
        state.adminPrograms.programDashboardValues.tableData.applicants.length >
        0
      ) {
        applicants =
          state.adminPrograms.programDashboardValues.tableData?.applicants;
      }
    }
  }

  return {
    program,
    applicants,
    profile: state.firebase.profile,
  };
};

const validate = combineValidators({
  subject: isRequired({ message: 'Required' }),
  content: isRequired({ message: 'Required' }),
  to: isRequired({ message: 'Required' }),
});

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5em',
    //backgroundColor: 'rgb(250, 250, 251)',
    backgroundColor: '#F3F4F9',
  },
  tableContainer: {
    padding: '1rem',
    gap: '1rem',
  },
  applicationsLength: {
    marginLeft: '1rem',
  },
}));

const AdminViewProgramDashboardTotalApplicants = ({
  program,
  applicants,
  submitting,
  handleSubmit,
  sendGenericEmail,
  change,
  profile,
  fetchProgramDashboardValues,
}) => {
  const matches = useMediaQuery('(min-width:480px)');
  const history = useHistory();
  const classes = useStyles();
  const [selectionModel, setSelectionModel] = useState([]);
  const [editorValue, setEditorValue] = useState('');
  const [dialog, setDialog] = useState(false);
  const [selectedApplicantRowIds, setSelectedApplicantRowIds] = useState([]);
  const [reload, setReload] = useState(false);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let queryParams = useQuery();
  const id = queryParams.get('id');
  const handleSelectionModelChange = (selectedIds) => {
    setSelectedApplicantRowIds(selectedIds);
  };
  const handleReloadApplicants = async () => {
    await fetchProgramDashboardValues(id);
    setReload((prev) => (prev = !prev));
  };
  useEffect(() => {
    handleReloadApplicants();
  }, []);
  useEffect(() => {
    const emails = selectionModel
      .map((id) => {
        if (applicants[id - 1]) return applicants[id - 1].email;
        return '';
      })
      .filter((email) => email && email);
    let updatedTo = '';
    emails.forEach((email) => (updatedTo += `${email},`));
    change('to', updatedTo);
  }, [selectionModel, applicants]);

  const handleSendEmail = async (values) => {
    const emails = [];
    if (values.to)
      values.to.split(',').forEach((email) => email && emails.push(email));
    if (emails.length > 0) {
      await sendGenericEmail(
        values.subject,
        editorValue,
        emails,
        profile.fullName
      );
    }
    setDialog(false);
  };

  return (
    <Grid container>
      <Grid
        item
        md={2}
        style={{
          width: `${matches ? '' : '100%'}`,
          justifyContent: `${matches ? '' : 'space-between'}`,
        }}
      >
        <Sidebar />
      </Grid>
      <Grid className={classes.container} item md={10}>
        <Paper elevation={0}>
          <Grid className={classes.tableContainer} container direction="column">
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h4">{program.programName}</Typography>
                <Typography className={classes.applicationsLength} variant="h6">
                  Application Fee Paid
                </Typography>
                <Typography className={classes.applicationsLength} variant="h6">
                  {applicants.length}
                </Typography>
              </Box>
              <Box display="flex" gridGap={16}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setDialog(true)}
                >
                  Send Email
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => history.push('/admin/dashboard/emailLogs')}
                >
                  Email History
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    history.push(`/admin/program/dashboard?id=${program.id}`)
                  }
                >
                  Program Dashboard
                </Button>
              </Box>
            </Grid>
            <DashboardApplicantsTable
              program={program}
              applicants={applicants}
              checkboxSelection
              selectionModel={selectionModel}
              setSelectionModel={setSelectionModel}
              onSelectionModelChange={handleSelectionModelChange}
              reloadApplicants={handleReloadApplicants}
            />
            <SendEmailDialogs
              isOpen={dialog}
              closeDialog={() => setDialog(false)}
              handleSubmit={handleSubmit}
              submitting={submitting}
              handleSendEmail={handleSendEmail}
              editorValue={editorValue}
              setEditorValue={setEditorValue}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, {
  sendGenericEmail,
  fetchProgramDashboardValues,
})(
  reduxForm({ form: 'sendEmail', validate })(
    AdminViewProgramDashboardTotalApplicants
  )
);
