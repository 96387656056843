import {
    asyncActionError,
    asyncActionFinish,
    asyncActionStart,
} from '../asyncActions/asyncActions';
import { FETCH_APPLICATION_SETTINGS } from '../../constants/applicationSettingsConstant/applicationSettingsConstant';

export const fetchApplicationSettings = () => {
    return async (dispatch, getState, { getFirestore, getFirebase }) => {
        const firestore = getFirestore();

        const applicationSettingsQuery = firestore
            .collection('applicationSettings')
            .doc('settings');

        try {
            dispatch(asyncActionStart());

            let applicationSettings = await applicationSettingsQuery.get();

            dispatch({
                type: FETCH_APPLICATION_SETTINGS,
                payload: { applicationSettings: applicationSettings.data() },
            });
            dispatch(asyncActionFinish());
        } catch (error) {
            dispatch(asyncActionError());
            console.log(error);
        }
    };
};

export const updateMaintenanceMode = (value) => {
    return async (dispatch, getState, { getFirestore, getFirebase }) => {
        const firestore = getFirestore();

        const applicationSettingsQuery = firestore
            .collection('applicationSettings')
            .doc('settings');

        try {
            dispatch(asyncActionStart());

            await applicationSettingsQuery.update({
                maintenanceMode: value,
            });

            dispatch(asyncActionFinish());
        } catch (error) {
            dispatch(asyncActionError());
            console.log(error);
        }
    };
};
