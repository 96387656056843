import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import ReviewProgramItem from "./item/ReviewProgramItem";
import { format, parseISO } from "date-fns";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  answerText: {
    fontWeight: 600,
  },
}));

const StudentQuestionnaireFormReview = ({ questionnaire }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction={"column"}
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
    >
      <Grid item>
        <Typography className={classes.title}>
          Student Questionnaire Form
        </Typography>
      </Grid>

      <ReviewProgramItem
        question={"Scholar's Name:"}
        value={questionnaire?.scholarName}
      />

      <ReviewProgramItem
        question={"Nickname:"}
        value={questionnaire?.nickname}
      />

      <ReviewProgramItem question={"Email:"} value={questionnaire?.email} />

      <ReviewProgramItem
        question={"Your Assigned Host Institution:"}
        value={questionnaire?.instituteName}
      />

      <ReviewProgramItem
        question={"MEDICAL: Voluntary Disability Information:"}
        value={questionnaire?.disability}
        isObject
      />

      <ReviewProgramItem
        question={
          "Feel free to provide additional comments or accommodations you may need:"
        }
        value={questionnaire?.additionalCommentsOrAccommodations}
      />

      <ReviewProgramItem
        question={
          "Is there anything else you would like us to know? (allergies - dietary or environmental; dietary restrictions :"
        }
        value={questionnaire?.allergiesOrRestrictions}
      />

      <ReviewProgramItem
        question={"Currently, my career goal is:"}
        value={questionnaire?.careerGoal}
      />

      <ReviewProgramItem
        question={"College major of interest:"}
        value={questionnaire?.collegeMajorGoal}
      />

      <ReviewProgramItem
        question={"Colleges I'm thinking of applying to:"}
        value={questionnaire?.collegesApplyingTo}
      />

      <ReviewProgramItem
        question={
          "I have a reading and speaking knowledge of the following languages:"
        }
        value={questionnaire?.languages}
      />

      <ReviewProgramItem
        question={"The most advanced mathematics course I have taken is:"}
        value={questionnaire?.advanceMathematicsCourseTaken}
      />

      <ReviewProgramItem
        question={
          "Have you had a course in economics. Please describe the course briefly:"
        }
        value={questionnaire?.economicsCourse}
      />

      <ReviewProgramItem
        question={
          "My experience with computers: list your experience with programming, platforms and software:"
        }
        value={questionnaire?.experienceInComputers}
      />

      <ReviewProgramItem
        question={
          "List any involvement you have with affinity student organizations:"
        }
        value={questionnaire?.involvementInAffinityStudentOrganisations}
      />

      <ReviewProgramItem
        question={"What do you enjoy doing in your spare time?"}
        value={questionnaire?.doInSpareTime}
        isObject
      />

      <ReviewProgramItem
        question={"Any other activities?"}
        value={questionnaire?.otherActivites}
      />

      <ReviewProgramItem
        question={"The music I like to listen to is:"}
        value={questionnaire?.musicILike}
      />

      <ReviewProgramItem
        question={
          "Describe any talents you have in music, dance, theatre, creative writing, the fine arts, etc.:"
        }
        value={questionnaire?.talents}
      />

      <ReviewProgramItem
        question={"Sports I like to play are:"}
        value={questionnaire?.sports}
      />

      <ReviewProgramItem
        question={"I have a phobia (fear) of:"}
        value={questionnaire?.phobia}
      />

      <ReviewProgramItem
        question={"My favorite food is:"}
        value={questionnaire?.favouriteFood}
      />

      <ReviewProgramItem
        question={
          "Is there anything else you would like us to know when considering your roommate placements? (allergies - dietary or environmental; dietary restrictions such as vegan or vegetarian; unusual sleeping habits such as insomnia, sleepwalking, must sleep with music/white noise, etc.):"
        }
        value={questionnaire?.roommateConsideration}
      />

      <ReviewProgramItem
        question={"My role models are:"}
        value={questionnaire?.roleModels}
      />

      <ReviewProgramItem
        question={"The countries I have been to are:"}
        value={questionnaire?.countriesBeenTo}
      />

      <ReviewProgramItem
        question={
          "During your residence, you will work in teams but please check your preference:"
        }
        value={questionnaire?.workPreference}
        isObject
      />

      <ReviewProgramItem
        question={"My greatest strengths are:"}
        value={questionnaire?.greatestStrength}
      />

      <ReviewProgramItem
        question={"My greatest weaknesses are:"}
        value={questionnaire?.greatestWeakness}
      />

      <ReviewProgramItem
        question={"If I could open my own business, I would:"}
        value={questionnaire?.ownBuisness}
      />

      <ReviewProgramItem
        question={
          "Is there anything else we should know about you and your interests or needs that will help us make your time with the program successful and enjoyable? :"
        }
        value={questionnaire?.interestForLead}
      />
    </Grid>
  );
};

export default StudentQuestionnaireFormReview;
