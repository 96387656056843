import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@mui/material';
import ReviewProgramItem from './item/ReviewProgramItem';
import axios from '../../config/axios';
import Iframe from 'react-iframe';
import { ExpandMore } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    letterSpacing: '0.1px',
    color: '#171725',
  },
  answerText: {
    fontWeight: 600,
  },
}));

const PersonalRecommendationsFormReview = ({
  recommendersForm,
  recommendersResponseForm,
  registeredProgram,
}) => {
  const classes = useStyles();
  const [showLink, setShowLink] = useState(false);
  const [formLink, setFormLink] = useState('');

  useEffect(() => {

   {registeredProgram.academicRecommenderFormCompleted && ( 
      axios
        .get('/documentLinkFunctions/documentDownloadLink', {
          // .get('/documentAttachLinkFunctions/documentAttachLink', {
          params: {
            docId: registeredProgram?.academicRecommenderFormDocNo,
          },
        })
        .then((res) => {
          setFormLink(res.data.link);
          console.log(res.data.link, 'academic');
          setShowLink(true);
        })
        .catch((err) => {
          console.log(err);
        })
  )}
    // {registeredProgram.academicRecommenderFormCompleted && (
    //     axios.get('/documentLinkFunctions/documentDownloadLink', {
    //         params: {
    //             docId: registeredProgram.academicRecommenderFormDocNo
    //         }
    //     }).then((res) => {
    //         setFormLink(res.data.link)
    //         setShowLink(true)
    //
    //     }).catch(err => {
    //         console.log(err)
    //     })
    // )}
  }, [registeredProgram?.academicRecommenderFormDocNo]);

  return (
    <Grid
      container
      direction={'column'}
      style={{ paddingTop: '0.5em', paddingBottom: '0.5em' }}
    >
      <Box>
        <Accordion style={{ borderTop: 
  registeredProgram.academicRecommenderFormCompleted ? '3px solid #35BA35' :
  registeredProgram.academicRecommenderFormSent ? '3px solid #FF9900' : '3px solid #CCC'
}}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            style={{ marginTop: '20px' }}
          >
            {/*RECOMMENDATIONS*/}
            <Grid item>
              <Typography className={classes.title}>
                Academic Recommendation
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            style={{ backgroundColor: '#FAFAFB', flexDirection: 'column' }}
          >
          
            {/*RECOMMENDER INFO*/}
            {recommendersForm.map((recommender) => (
               registeredProgram.academicRecommenderFormSent ? (
                <ReviewProgramItem
                key={recommender.id}
                question={'Recommender Information'}
                value={
                  registeredProgram.academicRecommenderDetails
                    ?.recommenderTitle +
                  ' ' +
                  registeredProgram.academicRecommenderDetails
                    ?.recommenderFirstName +
                  ' ' +
                  registeredProgram.academicRecommenderDetails
                    ?.recommenderLastName
                }
                value2={
                  registeredProgram.academicRecommenderDetails
                    ?.recommenderEmailAddress
                }
                renderCell={() => (
                  <Grid style={{}}>
                    {/* Your custom render cell content */}
                  
                    <Grid><Typography  variant={'h6'} className={classes.title} style={{color:'#696974', marginBottom:'5px'}}>Recommenders Information </Typography>
                    </Grid>
                    <Grid>
                     <b> Name: </b> {registeredProgram.academicRecommenderDetails
                    ?.recommenderTitle +
                  ' ' +
                  registeredProgram.academicRecommenderDetails
                    ?.recommenderFirstName +
                  ' ' +
                  registeredProgram.academicRecommenderDetails
                    ?.recommenderLastName}
                    </Grid>
                    <Grid>
                    <b> Email:</b> {registeredProgram.academicRecommenderDetails?.recommenderEmailAddress}
                    </Grid>
                  </Grid>
                    )}
              />
               ): null
            ))}
         
            {/*RESPONSES
            <Grid item>
              <Typography variant={'h6'} className={classes.title}>
                Document Link
              </Typography>
            </Grid>
*/}
   {registeredProgram.academicRecommenderFormCompleted ? (

            <><ReviewProgramItem
                question={'Document Link'}
                value={formLink}
                showLink={showLink}
                link={true} />
                
                
                <Iframe
                  url={'https://app.signnow.com/webapp/document/' + registeredProgram?.academicRecommenderFormDocNo}
                  width="100%"
                  height="800px"
                  display="block"
                  position="relative" /></>
      ): "Academic Recommendation not recieved"
          }
 
          </AccordionDetails>
        </Accordion>
      </Box>
    </Grid>
  );
};

export default PersonalRecommendationsFormReview;
