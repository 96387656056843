import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Grid, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { connect } from 'react-redux';
import ResumeFormUpload from './ResumeFormUpload';
import {
  fetchRegisteredProgram,
  fetchApplicantProgram,
} from '../../../../../store/actions/applicantActions/programActions/applicantProgramActions';
import {
  deleteResume,
  submitResumeForm,
  submitResumeForms,
} from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  formName: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '72px',
    letter: '0.2px',
  },
  formText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  formQuestionText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  button: {
    marginLeft: '0.4em',
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
    },
  },

  continueButton: {
    marginLeft: '0.4em',
    backgroundColor: '#1976d2',
    color: 'white !Important',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
      //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    },
  },
}));

const actions = {
  deleteResume,
  submitResumeForm,
  submitResumeForms,
  fetchRegisteredProgram,
};

const ResumeForm = ({
  registeredProgramId,
  registeredProgram,
  deleteResume,
  fetchRegisteredProgram,
  submitResumeForm,
  submitResumeForms,
  handleCloseForm,
  handleSetForm,
  profile,
  program,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');
  const [exit, setExit] = useState(false);
  const [files, setFiles] = useState({});
  const [reload, setReload] = useState(false);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();

  const id = queryParams.get('id');

  const handleReload = () => {
    setReload((prev) => !prev);
  };
  useEffect(() => {
    fetchRegisteredProgram(id);
  }, [fetchRegisteredProgram, id, reload]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  console.log(files, 'files');
  useEffect(() => {
    if (exit) handleCloseForm();
  }, [exit, handleCloseForm]);
  useEffect(() => {
    if (registeredProgram?.resume) {
      setFiles(registeredProgram?.resume);
    }
  }, [registeredProgram.resume, reload]);
  const handleDeleteResume = async (fileName) => {
    setDisabled(true);
    try {
      await deleteResume(registeredProgramId, fileName);
      setDisabled(false);
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };

  const programsAllThings = [
    'applicationInterests',
    'personalInformationForm',
    'familyInformationForm',
    'academicInformationForm',
    // 'recommendationsRequired',
    'recommendersForm',
    'extracurricularForm',
    'covidProtocolForm',
    'resumeForm',
    'transcriptForm',
    'shortResponsesForm',
    'scholarshipForm',
    'taxForm',
    'additionalDocumentsForm',
    'confirmationForm',
  ];

  const programAllThingsValue = {
    applicationInterests: 'applicationInterests',
    personalInformationForm: 'personalInformationForm',
    familyInformationForm: 'familyInformationForm',
    academicInformationForm: 'academicInformationForm',
    // recommendationsRequired: 'recommendationsRequired',
    recommendersForm: 'recommendersForm',
    extracurricularForm: 'extracurricularForm',
    covidProtocolForm: 'covidProtocolForm',
    resumeForm: 'resumeForm',
    transcriptForm: 'transcriptForm',
    shortResponsesForm: 'shortResponsesForm',
    scholarshipForm: 'scholarshipForm',
    taxForm: 'taxForm',
    additionalDocumentsForm: 'additionalDocumentsForm',
    confirmationForm: 'confirmationForm',
  };

  let dataToCheck = programsAllThings
    .filter((item) => program[item])
    .map((item) => programAllThingsValue[item]);

  const handleSubmitResumeForms = async () => {
    setDisabled(true);
    try {
      if (Object.keys(files).length > 0) {
        await submitResumeForm(registeredProgramId, files, true);
        handleCloseForm();
        const indexOfPersonalInfoForm = dataToCheck.indexOf('resumeForm');
        if (
          indexOfPersonalInfoForm !== -1 &&
          indexOfPersonalInfoForm < dataToCheck.length - 1
        ) {
          const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
          handleSetForm(nextForm);
        }
        setDisabled(false);
      } else {
        setError('Resume is required.');
        setDisabled(false);
      }
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };
  const handleSubmitResumeFormss = async () => {
    setDisabled(true);
    try {
      console.log(files, 'files');
      if (Object.keys(files).length > 0) {
        await submitResumeForms(registeredProgramId, true, files);
        handleCloseForm();
        const indexOfPersonalInfoForm = dataToCheck.indexOf('resumeForm');
        if (
          indexOfPersonalInfoForm !== -1 &&
          indexOfPersonalInfoForm < dataToCheck.length - 1
        ) {
          const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
          handleSetForm(nextForm);
        }
        setDisabled(false);
      } else {
        setError('Resume is required.');
        setDisabled(false);
      }
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };

  return (
    <Fragment>
      <Typography className={classes.formName}>Resume</Typography>
      <Grid item container direction={'column'}>
        <Grid item>
          <Grid item container direction={'column'}>
            <Grid item>
              <Typography className={classes.formText}>
                In addition to our application, you will need to submit the
                following supplementary documents in order for your application
                to be complete. A resume highlighting scholar's academic,
                leadership, extra-curricular activities, and community
                involvement.
              </Typography>
            </Grid>
            {/* <Grid item>
              <Typography
                className={classes.formText}
                style={{ paddingTop: '10px' }}
              >
                <span style={{ fontWeight: 'bold' }}>
                  Please make sure to press UPLOAD Button.
                </span>
              </Typography>
            </Grid> */}
          </Grid>
        </Grid>

        {/*TAX FORM UPLOAD*/}
        <ResumeFormUpload
          registeredProgramId={registeredProgramId}
          files={files}
          registeredProgram={registeredProgram}
          setFiles={setFiles}
          onReload={handleReload}
        />
        {error && (
          <Typography variant="h6" style={{ color: 'red' }}>
            {error}
          </Typography>
        )}

        {/*CURRENT FORMS*/}

        <Grid
          item
          container
          direction={'column'}
          className={classes.fieldContainer}
          // alignItems={"center"}
        >
          {/* <Grid item>
            <Typography className={classes.formQuestionText}>
              Uploaded Resume:
            </Typography>
          </Grid>
          <Grid item>
            {console.log(registeredProgram?.resume, 'pppp')}
            <Grid item container direction={'column'}>
              {registeredProgram?.resume && (
                <Grid item>
                  <Grid item container alignItems={'center'}>
                    <Grid item>
                       <Typography variant={'body1'}>
                        {
                          registeredProgram.resume?.ResumeId[0]
                            ?.originalFileName
                        }
                      </Typography> 
                      <Typography variant={'body1'}>
                        {registeredProgram.resume.originalFileName ||
                          registeredProgram.resumeFormCompleted
                            .originalFileName}
                      </Typography>
                    </Grid>
                    <Grid item style={{ marginLeft: '1em' }}>
                      <IconButton
                        disabled={disabled}
                        aria-label="delete file"
                        onClick={() =>
                          handleDeleteResume(
                            registeredProgram.resume.fileName ||
                              registeredProgram.resumeFormCompleted
                                .originalFileName
                          )
                        }
                      >
                        <DeleteIcon
                          style={{ color: theme.palette.error.main }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid> */}
        </Grid>

        {/*BUTTONS*/}
        <Grid item style={{ marginLeft: 'auto' }}>
          <Button
            variant={'outlined'}
            color={'primary'}
            className={classes.button}
            type={'submit'}
            disabled={disabled}
            onClick={() => setExit(true)}
          >
            Save for Later
          </Button>
          <Button
            variant={'outlined'}
            color={'primary'}
            className={classes.continueButton}
            type={'submit'}
            // onClick={() => handleSubmitResumeForms()}
            // onClick={async () => {
            //   await submitResumeForms(registeredProgramId, true, files);
            // }}
            onClick={() => handleSubmitResumeFormss()}
            disabled={disabled}
          >
            Continue{' '}
            <span>
              <strong> &#8594;</strong>
            </span>
          </Button>

          <Button
            variant={'outlined'}
            color={'secondary'}
            className={classes.button}
            onClick={() => handleCloseForm()}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default connect(null, actions)(ResumeForm);
