/* eslint-disable no-unused-expressions */
import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import Select from 'react-select';
import {
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchProgramForReview } from '../../store/actions/reviewActions/reviewActions';
import ApplicationFeePaidReview from '../../ui/reviewProgram/ApplicationFeePaidReview';
import ScholarshipFormReview from '../../ui/reviewProgram/ScholarshipFormReview';
import Button from '@mui/material/Button';
import TaxFormsReview from '../../ui/reviewProgram/TaxFormsReview';
import CovidFormReview from '../../ui/reviewProgram/CovidFormReview';
import PersonalInformationFormReview from '../../ui/reviewProgram/PersonalInformationFormReview';
import FamilyInformationFormReview from '../../ui/reviewProgram/FamilyInformationFormReview';
import AcademicInformationFormReview from '../../ui/reviewProgram/AcademicInformationFormReview';
import ExtracurricularInformationFormReview from '../../ui/reviewProgram/ExtracurricularInformationFormReview';
import ResumeFormReview from '../../ui/reviewProgram/ResumeFormReview';
import TranscriptFormReview from '../../ui/reviewProgram/TranscriptFormReview';
import AdditionalDocumentsFormReview from '../../ui/reviewProgram/AdditionalDocumentsFormReview';
import ShortResponsesFormReview from '../../ui/reviewProgram/ShortResponsesFormReview';
import PersonalRecommendationsFormReview from '../../ui/reviewProgram/PersonalRecommendationsFormReview';
import ConfirmationFormReview from '../../ui/reviewProgram/ConfirmationFormReview';
import ReviewForm from '../../ui/reviewForm/ReviewForm';
import { format, parseISO } from 'date-fns';
import AdminReviewForm from '../../ui/reviewForm/AdminReviewForm';
import CounselorRecommendationsFormReview from '../../ui/reviewProgram/CounselorRecommendationsFormReview';
import AcademicRecommendationsFormReview from '../../ui/reviewProgram/AcademicRecommendationsFormReview';
import GrantAcceptanceFormReview from '../../ui/reviewProgram/GrantAcceptanceFormReview';
import ScholarConsentFormReview from '../../ui/reviewProgram/ScholarConsentFormReview';
import GovernmentIdFormReview from '../../ui/reviewProgram/GovernmentIdFormsReview';
import PaymentFormReview from '../../ui/reviewProgram/PaymentFormReview';
import TravelInformationFormReview from '../../ui/reviewProgram/TravelInformationFormReview';
import circles from '../../assets/dashboard/circles.png';
import DoneIcon from '@mui/icons-material/Done';
import ReviewerForm from '../../ui/reviewProgram/ReviewerForm';
import ActivityLogs from '../../ui/reviewProgram/ActivityLogs';
import { StarIcon } from './icons';
import RequiredDocumentationFormReview from '../../ui/reviewProgram/RequiredDocumentationFormReview';
import StudentQuestionnaireFormReview from '../../ui/reviewProgram/StudentQuestionnaireFormReview';
import { downloadApplication } from '../../common/util';
import { Autocomplete } from '@mui/lab';
import {
  toggleGrantScholarship,
  toggleGrantSponsorship,
} from '../../store/actions/adminActions/dashboardActions/dashboardActions';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../common/form/TextInput';
import { combineValidators, isRequired } from 'revalidate';

const useStyles = makeStyles((theme) => ({
  headerImage: {
    width: '100%',
    height: '180px',
  },

  blockTitle: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    color: '#171725',
  },
  blockText: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '27px',
    color: '#696974',
  },
  span: {
    paddingLeft: '24px',
    fontWeight: '400',
  },
  detailBlock: {
    borderRadius: '20px',
    backgroundColor: 'white',
    padding: '30px',
    position: 'relative',
    height: '100%',
    boxShadow: 'lightgrey 5px 5px 5px',
  },
  activeBtn: {
    backgroundColor: '#35BA35',
    color: theme.palette.common.white,
    '&.MuiButton-containedPrimary:hover': {
      backgroundColor: '#35BA35',
      color: theme.palette.common.white,
    },
  },
  starIcon: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
}));

const validate = combineValidators({
  scholarshipRejectReason: isRequired({ message: 'Required' }),
});

const actions = {
  fetchProgramForReview,
  toggleGrantScholarship,
  toggleGrantSponsorship,
};

const mapStateToProps = (state) => {
  let program = {};
  let completedStudents = {};
  let academicRecommenderFormSent = false;
  let personalRecommenderFormSent = false;
  let counselorRecommenderFormSent = false;
  let scholarship = [];
  let additionalDebt = [];
  let additionalExpense = [];
  let taxForms = [];
  let personalInformation = [];
  let familyInformation = [];
  let academicInformation = [];
  let extracurricularInformation = [];
  let resumeForm = [];
  let transcriptForm = [];
  let additionalDocumentsForm = [];
  let shortResponsesForm = [];
  let personalRecommendersForm = [];
  let personalRecommendersResponseForm = [];
  let counselorRecommendersForm = [];
  let counselorRecommendersResponseForm = [];
  let academicRecommendersForm = [];
  let academicRecommendersResponseForm = [];
  let confirmationForm = [];
  let governmentIdForm = [];
  let travelInformation = [];
  let reviewerResponse = {};
  let covidDetails = {};
  let initialValues = {};
  let logs = [];
  let requiredDocuments = {};
  let studentQuestionnaire = {};
  let completedApplications = [];
  let programReviewedApplications = [];
  console.log(state, 'state');
  if (
    state.review.reviewApplication &&
    state.review.reviewApplication.length > 0
  ) {
    program = state.review.reviewApplication[0];

    scholarship = state.review.reviewApplication[0].scholarshipFormDetails;
    covidDetails = state.review.reviewApplication[0].covidProtocolFormDetails;
    additionalDebt =
      state.review.reviewApplication[0].scholarshipFormDetails?.additionalDebt;
    additionalExpense =
      state.review.reviewApplication[0].scholarshipFormDetails
        ?.additionalExpense;
    taxForms = state.review.reviewApplication[0].taxForms || [];
    personalInformation =
      state.review.reviewApplication[0].personalInformationFormDetails;
    familyInformation =
      state.review.reviewApplication[0].familyInformationFormDetails;
    academicInformation =
      state.review.reviewApplication[0].academicInformationFormDetails;
    extracurricularInformation =
      state.review.reviewApplication[0].extracurricularFormDetails;
    resumeForm = state.review.reviewApplication[0].resume
      ? [state.review.reviewApplication[0].resume]
      : [];
    transcriptForm = state.review.reviewApplication[0].transcripts || [];
    additionalDocumentsForm =
      state.review.reviewApplication[0].additionalDocuments || [];
    shortResponsesForm =
      state.review.reviewApplication[0].shortResponsesFormDetails;
    personalRecommendersForm = state.review.reviewApplication[0]
      .personalRecommenderDetails
      ? [state.review.reviewApplication[0].personalRecommenderDetails]
      : [];
    personalRecommenderFormSent =
      state.review.reviewApplication[0].personalRecommenderFormSent;
    counselorRecommenderFormSent =
      state.review.reviewApplication[0].counselorRecommenderFormSent;
    academicRecommenderFormSent =
      state.review.reviewApplication[0].academicRecommenderFormSent;
    personalRecommendersResponseForm = state.review.reviewApplication[0]
      .personalRecommenderResponse
      ? [state.review.reviewApplication[0].personalRecommenderResponse]
      : [];
    counselorRecommendersForm = state.review.reviewApplication[0]
      .counselorRecommenderDetails
      ? [state.review.reviewApplication[0].counselorRecommenderDetails]
      : [];
    counselorRecommendersResponseForm = state.review.reviewApplication[0]
      .counselorRecommenderResponse
      ? [state.review.reviewApplication[0].counselorRecommenderResponse]
      : [];
    academicRecommendersForm = state.review.reviewApplication[0]
      .academicInformationFormDetails
      ? [state.review.reviewApplication[0].academicInformationFormDetails]
      : [];
    academicRecommendersResponseForm = state.review.reviewApplication[0]
      .academicRecommenderResponse
      ? [state.review.reviewApplication[0].academicRecommenderResponse]
      : [];

    confirmationForm =
      state.review.reviewApplication[0].confirmationFormDetails;
    reviewerResponse = state.review.reviewApplication[0].reviewerResponse;
    governmentIdForm = state.review.reviewApplication[0].governmentIds || [];
    requiredDocuments =
      state.review.reviewApplication[0].requiredDocuments || {};
    studentQuestionnaire =
      state.review.reviewApplication[0].studentQuestionnaireFormDetails || {};
    travelInformation = state.review.reviewApplication[0].travelInformation;
    logs = state.review.reviewApplication[0].logs || [];
    initialValues = {
      essayQualityValue: state.review.reviewApplication[0]?.essayQualityValue,
      extraCurricularCommunityActivityValue:
        state.review.reviewApplication[0]
          ?.extraCurricularCommunityActivityValue,
      academicPerformanceValue:
        state.review.reviewApplication[0]?.academicPerformanceValue,
      recommendationQualityValue:
        state.review.reviewApplication[0]?.recommendationQualityValue,
      comments: state.review.reviewApplication[0]?.comments,
    };
  }

  if (
    state.adminPrograms.allProgramCompletedApplications &&
    state.adminPrograms.allProgramCompletedApplications.length > 0
  ) {
    completedStudents = state.adminPrograms.allProgramCompletedApplications;
  }
  if (
    state.reviewerDashboard.allCompletedApplications &&
    state.reviewerDashboard.allCompletedApplications.length > 0
  ) {
    completedApplications = state.reviewerDashboard.allCompletedApplications;
  }
  if (
    state.adminPrograms.programDashboardValues.tableData?.allApplicants &&
    state.adminPrograms.programDashboardValues.tableData.allApplicants.length >
      0
  ) {
    programReviewedApplications =
      state.adminPrograms.programDashboardValues.tableData.allApplicants.filter(
        (item) => {
          return item.programStage !== 'pre-application';
        }
      );
  }

  return {
    program,
    completedStudents,
    scholarship,
    additionalDebt,
    additionalExpense,
    taxForms,
    personalInformation,
    familyInformation,
    academicInformation,
    extracurricularInformation,
    resumeForm,
    transcriptForm,
    additionalDocumentsForm,
    shortResponsesForm,
    personalRecommendersForm,
    personalRecommendersResponseForm,
    counselorRecommendersForm,
    counselorRecommendersResponseForm,
    academicRecommendersForm,
    academicRecommendersResponseForm,
    personalRecommenderFormSent,
    academicRecommenderFormSent,
    counselorRecommenderFormSent,
    confirmationForm,
    governmentIdForm,
    travelInformation,
    profile: state.firebase.profile,
    reviewerResponse,
    initialValues,
    covidDetails,
    logs,
    requiredDocuments,
    vaccineCard: state.review.reviewApplication[0]?.vaccineCard,
    studentQuestionnaire,
    completedApplications,
    programReviewedApplications,
  };
};
const adminButtons = [
  {
    id: 0,
    name: 'All Forms',
    value: 'allForms',
    checked: 'allForms',
  },
  {
    id: 1,
    name: 'Application Form',
    value: 'applicationForm',
    checked: 'applicationForm',
  },
  {
    id: 2,
    name: 'Scholarship Form',
    value: 'scholarshipForm',
    checked: 'scholarshipForm',
  },
  {
    id: 3,
    name: 'Tax Forms',
    value: 'taxForm',
    checked: 'taxForm',
  },
  {
    id: 4,
    name: 'Covid Protocol',
    value: 'covidProtocolForm',
    checked: 'covidProtocolForm',
  },
  {
    id: 5,
    name: 'Personal Information',
    value: 'personalInformationForm',
    checked: 'personalInformationForm',
  },
  {
    id: 6,
    name: 'Family Information',
    value: 'familyInformationForm',
    checked: 'familyInformationForm',
  },
  {
    id: 7,
    name: 'Academic Information',
    value: 'academicInformationForm',
    checked: 'academicInformationForm',
  },
  {
    id: 8,
    name: 'Extracurricular Information',
    value: 'extracurricularForm',
    checked: 'extracurricularForm',
  },
  {
    id: 9,
    name: 'Resume',
    value: 'resumeForm',
    checked: 'resumeForm',
  },
  {
    id: 10,
    name: 'Transcript',
    value: 'transcriptForm',
    checked: 'transcriptForm',
  },
  {
    id: 11,
    name: 'Additional Documents',
    value: 'additionalDocumentsForm',
    checked: 'additionalDocumentsForm',
  },
  {
    id: 12,
    name: 'Short Responses and Essays',
    value: 'shortResponsesForm',
    checked: 'shortResponsesForm',
  },
  {
    id: 13,
    name: 'Recommendations',
    value: 'recommendersForm',
    checked: 'recommendersForm',
  },
  {
    id: 14,
    name: 'Confirmation',
    value: 'confirmationForm',
    checked: 'confirmationForm',
  },
  {
    id: 17,
    name: 'Recommendations',
    value: 'personalRecommenderFormSent',
    checked: 'recommendersForm',
  },
  {
    id: 15,
    name: 'Recommendations',
    value: 'academicRecommenderFormSent',
    checked: 'recommendersForm',
  },
  {
    id: 16,
    name: 'Recommendations',
    value: 'counselorRecommenderFormSent',
    checked: 'recommendersForm',
  },
  {
    id: 16,
    name: 'Activity Logs',
    value: 'activityLog',
    checked: 'activityLog',
  },
];

const acceptedButtons = [
  {
    id: 0,
    name: 'LEAD Acceptance Forms',
    value: 'grantAcceptanceForm',
  },
  {
    id: 1,
    name: 'Scholar Consent Form',
    value: 'scholarConsentForm',
  },
  {
    id: 2,
    name: 'Government ID',
    value: 'governmentIdForm',
  },
  {
    id: 6,
    name: 'Required Pre-Arrival Forms',
    value: 'requiredDocumentationForm',
  },
  {
    id: 7,
    name: 'Student Questionnaire',
    value: 'studentQuestionnaireForm',
  },
  {
    id: 3,
    name: 'Payment',
    value: 'paymentForm',
  },
  {
    id: 4,
    name: 'Travel',
    value: 'travelForm',
  },
];

const ReviewProgram = ({
  profile,
  fetchProgramForReview,
  program,
  completedStudents,
  scholarship,
  additionalDebt,
  additionalExpense,
  taxForms,
  covidDetails,
  personalInformation,
  familyInformation,
  academicInformation,
  extracurricularInformation,
  resumeForm,
  transcriptForm,
  additionalDocumentsForm,
  shortResponsesForm,
  personalRecommendersForm,
  personalRecommendersResponseForm,
  personalRecommenderFormSent,
  counselorRecommenderFormSent,
  academicRecommenderFormSent,

  counselorRecommendersForm,
  counselorRecommendersResponseForm,
  academicRecommendersForm,
  academicRecommendersResponseForm,
  confirmationForm,
  governmentIdForm,
  travelInformation,
  reviewerResponse,
  initialValues,
  logs,
  requiredDocuments,
  vaccineCard,
  studentQuestionnaire,
  completedApplications,
  programReviewedApplications,
  toggleGrantScholarship,
  toggleGrantSponsorship,
  handleSubmit,
  submitting,
}) => {
  // console.log(fetchProgramForReview, 'personalRecommendersForm');
  // console.log(personalRecommenderFormSent, 'personalRecommenderFormSent');

  const programAllThingsValue = {
    scholarshipForm: 'scholarshipFormCompleted',
    taxForm: 'taxFormsUploaded',
    personalInformationForm: 'personalInformationFormCompleted',
    familyInformationForm: 'familyInformationFormCompleted',
    academicInformationForm: 'academicInformationFormCompleted',
    recommendationsRequired: 'recommendationsRequiredCompleted',
    // recommendersForm: 'recommendersFormCompleted',
    personalRecommenderFormSent: 'personalRecommenderFormSent',
    academicRecommenderFormSent: 'academicRecommenderFormSent',
    counselorRecommenderFormSent: 'counselorRecommenderFormSent',
    extracurricularForm: 'extracurricularFormCompleted',
    covidProtocolForm: 'covidProtocolFormCompleted',
    resumeForm: 'resumeFormCompleted',
    transcriptForm: 'transcriptFormCompleted',
    shortResponsesForm: 'shortResponsesFormCompleted',
    additionalDocumentsForm: 'additionalDocumentsFormCompleted',
    confirmationForm: 'confirmationFormCompleted',
    activityLog: 'logs',
  };
  console.log(adminButtons, 'manish', program);

  let dataToCheck = adminButtons
    .filter((item) => {
      // console.log(program[programAllThingsValue[item.value]], 'prpgramALLTHINGS')
      return program[programAllThingsValue[item.value]];
      //  || item?.id == 15;
    })
    .map((item) => ({
      id: item.id,
      name: item.name,
      checked: item.checked,
      value: programAllThingsValue[item.value],
    }));
  console.log(dataToCheck, 'dataToCheck');
  const uniqueObjects = {};
  dataToCheck.forEach((obj) => {
    // Use the 'name' property as a key in the uniqueObjects object
    // This will ensure only unique objects are stored
    uniqueObjects[obj.name] = obj;
  });
  console.log(uniqueObjects, 'uniqueObjects');
  const uniqueObjectsArray = Object.values(uniqueObjects);
  console.log(uniqueObjectsArray, 'uniqueObjectsArray');
  const unique = [
    ...new Set(
      dataToCheck.map(
        (item) => item.name

        // id: item.id,
        // name:

        // checked: item.checked,
        // value: programAllThingsValue[item.value],
      )
    ),
  ];
  console.log(unique, 'unique');
  console.log(dataToCheck, 'dataToCheck');
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get('id');

  const [currentForm, setCurrentForm] = useState('allForms');
  const [applicationForm, setApplicationForm] = useState(true);
  const [scholarshipForm, setScholarshipForm] = useState(true);
  const [taxForm, setTaxForm] = useState(true);
  const [covidForm, setCovidForm] = useState(true);
  const [personalInformationForm, setPersonalInformationForm] = useState(true);
  const [familyInformationForm, setFamilyInformationForm] = useState(true);
  const [academicInformationForm, setAcademicInformationForm] = useState(true);
  const [extracurricularInformationForm, setExtracurricularInformationForm] =
    useState(true);
  const [resumeInformationForm, setResumeInformationForm] = useState(true);
  const [transcriptInformationForm, setTranscriptInformationForm] =
    useState(true);

  const [
    additionalDocumentsInformationForm,
    setAdditionalDocumentsInformationForm,
  ] = useState(true);
  const [shortResponseForm, setShortResponseForm] = useState(true);
  const [recommendationForm, setRecommendationForm] = useState(true);
  const [confirmForm, setConfirmForm] = useState(true);

  const [grantAcceptanceForm, setGrantAcceptanceForm] = useState(true);
  const [scholarConsentForm, setScholarConsentForm] = useState(true);
  const [governmentForm, setGovernmentForm] = useState(true);
  const [paymentForm, setPaymentForm] = useState(true);
  const [requiredDocumentationForm, setRequiredDocumentationForm] =
    useState(true);
  const [studentQuestionnaireForm, setStudentQuestionnaireForm] =
    useState(true);
  const [travelForm, setTravelForm] = useState(true);
  const [reviewerForm, setReviewerForm] = useState(true);
  const [activityLog, setActivityLog] = useState(false);
  const [applications, setApplications] = useState([]);
  const [applicationIndex, setApplicationIndex] = useState();
  const [scholarshipRejectId, setScholarshiprejectId] = useState(null);

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [reloadProgramReview, setReloadProgramReview] = useState(false);

  useEffect(() => {
    if (applications?.length > 0) {
      const filtereredOptions = applications?.map((item) => {
        const applicantUid =
          profile?.isAdmin || profile.isAdminViewer
            ? item?.applicantUid
            : item?.id;
        return {
          value: applicantUid,
          label: `${item?.fullName}, ${item?.programDetails?.programName}`,
        };
      });
      setOptions([...filtereredOptions]);
    }
  }, [applications]);

  useEffect(() => {
    if (id && applications.length > 0) {
      const selectedApplication = applications.filter((item) => {
        const applicantUid =
          profile?.isAdmin || profile.isAdminViewer
            ? item?.applicantUid
            : item?.id;
        return applicantUid === id;
      })[0];
      if (selectedApplication) {
        const applicantUid =
          profile?.isAdmin || profile.isAdminViewer
            ? selectedApplication?.applicantUid
            : selectedApplication?.id;
        const selectedOption = {
          value: applicantUid,
          label: `${selectedApplication?.fullName}, ${selectedApplication?.programDetails?.programName}`,
        };
        setSelectedOption(selectedOption);
        setApplicationIndex(applications.indexOf(selectedOption));
      }
    }
  }, [applications, id]);

  // let route = "";
  // if (profile.isAdmin) {
  //   route = "admin";
  // }
  // if (profile.isReviewer) {
  //   route = "reviewer";
  // }

  useEffect(() => {
    if (!profile.isReviewer) {
      if (profile.isAdmin || profile.isAdminViewer) {
        setApplications(programReviewedApplications);
      } else {
        setApplications(completedApplications);
      }
    }
  }, [profile, completedApplications, programReviewedApplications]);

  useEffect(() => {
    fetchProgramForReview(id);
  }, [fetchProgramForReview, id]);

  const handleShowForm = (form) => {
    setCurrentForm(form);
    if (form === 'allForms') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(true);
      setScholarshipForm(true);
      setPersonalInformationForm(true);
      setFamilyInformationForm(true);
      setAcademicInformationForm(true);
      setExtracurricularInformationForm(true);
      setResumeInformationForm(true);
      setTranscriptInformationForm(true);
      setAdditionalDocumentsInformationForm(true);
      setShortResponseForm(true);
      setRecommendationForm(true);
      setConfirmForm(true);
      setGrantAcceptanceForm(true);
      setScholarConsentForm(true);
      setGovernmentForm(true);
      setRequiredDocumentationForm(true);
      setPaymentForm(true);
      setTravelForm(true);
      setReviewerForm(true);
      setCovidForm(true);
    }
    if (form === 'applicationForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(true);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }
    if (form === 'scholarshipForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(true);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }
    if (form === 'taxForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(true);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }
    if (form === 'personalInformationForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(true);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }
    if (form === 'familyInformationForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(true);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }
    if (form === 'academicInformationForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(true);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }
    if (form === 'extracurricularForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(true);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }
    if (form === 'resumeForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(true);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }
    if (form === 'transcriptForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(true);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }
    if (form === 'additionalDocumentsForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(true);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }
    if (form === 'shortResponsesForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(true);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }
    if (form === 'recommendersForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(true);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }
    if (form === 'confirmationForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(true);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }

    // ACCEPTED
    if (form === 'grantAcceptanceForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(true);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }
    if (form === 'scholarConsentForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(true);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }
    if (form === 'governmentIdForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(true);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }
    if (form === 'paymentForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(true);
      setTravelForm(false);
      setReviewerForm(false);
    }
    if (form === 'travelForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(true);
      setReviewerForm(false);
    }
    if (form === 'reviewerForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(true);
    }
    if (form === 'covidProtocolForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(true);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }
    if (form === 'activityLog') {
      setStudentQuestionnaireForm(false);
      setActivityLog(true);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }
    if (form === 'requiredDocumentationForm') {
      setStudentQuestionnaireForm(false);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(true);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }
    if (form === 'studentQuestionnaireForm') {
      setStudentQuestionnaireForm(true);
      setActivityLog(false);
      setApplicationForm(false);
      setScholarshipForm(false);
      setTaxForm(false);
      setCovidForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularInformationForm(false);
      setResumeInformationForm(false);
      setTranscriptInformationForm(false);
      setAdditionalDocumentsInformationForm(false);
      setShortResponseForm(false);
      setRecommendationForm(false);
      setConfirmForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentForm(false);
      setRequiredDocumentationForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setReviewerForm(false);
    }
  };

  const handleToggleGrantScholarship = async (uid, value) => {
    if (value) {
      await toggleGrantScholarship(uid, value);
      setReloadProgramReview(!reloadProgramReview);
    } else {
      setScholarshiprejectId(uid);
    }
  };

  const handleToggleGrantSponsorship = async (uid, value) => {
    await toggleGrantSponsorship(uid, value);
    setReloadProgramReview(!reloadProgramReview);
  };

  const handleRejectScholarship = async (values) => {
    await toggleGrantScholarship(
      scholarshipRejectId,
      false,
      values.scholarshipRejectReason
    );
    fetchProgramForReview(id, program);
    setScholarshiprejectId(null);
  };

  const handleChange = (selectedItem) => {
    setSelectedOption(selectedItem);
    history.push(
      `/${
        profile.isAdmin || profile.isAdminViewer ? 'admin' : 'reviewer'
      }/reviewProgram?id=${selectedItem.value}`
    );
  };

  return (
    <Grid container direction={'column'} style={{ backgroundColor: '#FAFAFB' }}>
      <Dialog
        open={scholarshipRejectId ? true : false}
        onClose={() => setScholarshiprejectId(null)}
        maxWidth="md"
      >
        <form onSubmit={handleSubmit(handleRejectScholarship)}>
          <DialogTitle>Reason for denial of scholarship</DialogTitle>
          <DialogContent>
            <Field
              name={'scholarshipRejectReason'}
              label={'Reason for denial of scholarship'}
              component={TextInput}
              type={'text'}
              variant={'outlined'}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setScholarshiprejectId(null)}
              color="secondary"
              disabled={submitting}
            >
              Close
            </Button>
            <Button color={'primary'} type={'submit'} disabled={submitting}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Grid item>
        <img className={classes.headerImage} src={circles} alt="" />
      </Grid>

      {/*<Grid item>*/}
      {/*  <img*/}
      {/*    src={logo}*/}
      {/*    alt="LEAD Logo"*/}
      {/*    style={{ cursor: "pointer" }}*/}
      {/*    onClick={() => history.push(`/${route}/dashboard`)}*/}
      {/*  />*/}
      {/*</Grid>*/}

      <Grid
        style={{
          marginTop: '30px',
          paddingLeft: '154px',
          paddingRight: '154px',
          gap: '2rem',
        }}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item style={{ flex: 1 }}>
          <Select
            value={selectedOption}
            onChange={(selectedItem) => handleChange(selectedItem)}
            options={options}
            isSearchable
            name="autocomplete"
            placeholder="Search application"
          />
        </Grid>
        <Grid item>
          <ButtonGroup color="primary">
            <Button
              disabled={applicationIndex === 0}
              onClick={() =>
                history.push(
                  `/${
                    profile.isAdmin || profile.isAdminViewer
                      ? 'admin'
                      : 'reviewer'
                  }/reviewProgram?id=${applications[applicationIndex - 1].id}`
                )
              }
            >
              Prev
            </Button>
            <Button
              disabled={applicationIndex === applications.length - 1}
              onClick={() =>
                history.push(
                  `/${
                    profile.isAdmin || profile.isAdminViewer
                      ? 'admin'
                      : 'reviewer'
                  }/reviewProgram?id=${applications[applicationIndex + 1].id}`
                )
              }
            >
              Next
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>

      <Grid
        item
        container
        style={{
          marginTop: '30px',
          paddingLeft: '154px',
          paddingRight: '154px',
        }}
      >
        <Grid item lg={6} md={6} style={{ padding: '8px' }}>
          <Grid container direction={'column'} className={classes.detailBlock}>
            {profile.isAdmin &&
              program.scholarshipFormCompleted &&
              program.scholarshipFormDetails.qualifyForScholarship === true && (
                <StarIcon className={classes.starIcon} />
              )}
            <Grid item>
              <Typography className={classes.blockTitle}>
                Program Details
              </Typography>
            </Grid>

            <Grid item style={{ marginTop: '24px' }}>
              <Typography className={classes.blockText}>
                Program Name:{' '}
                <span className={classes.span}>
                  {program.programDetails?.programName}
                </span>
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: '24px' }}>
              <Typography className={classes.blockText}>
                Stage:{' '}
                <span className={classes.span}>{program?.programStage}</span>
              </Typography>
            </Grid>
            {profile.isAdmin ? (
              <>
                {/*   <Grid item style={{ marginTop: "16px" }}>
                  <Button
                    onClick={() =>
                      downloadApplication({
                        ...program,
                      })
                    }
                    color="primary">
                    Download Application
                  </Button>
                </Grid>*/}
              </>
            ) : (
              <>
                {/*  <Grid item style={{ marginTop: '16px' }}>
                  <Typography className={classes.blockText}>
                    Director Name:{' '}
                    <span className={classes.span}>
                      {program.programDetails?.programDirectorName}
                    </span>
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: '16px' }}>
                  <Typography className={classes.blockText}>
                    Director Email:{' '}
                    <span className={classes.span}>
                      {program.programDetails?.programDirectorEmail}
                    </span>
                  </Typography>
                </Grid>
            */}
                <Grid item>
                  <Typography className={classes.blockTitle}>
                    Program Details
                  </Typography>
                </Grid>

                <Grid item style={{ marginTop: '24px' }}>
                  <Typography className={classes.blockText}>
                    Program Name:{' '}
                    <span className={classes.span}>
                      {program.programDetails?.programName}
                    </span>
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        {(profile.isAdmin || profile.isAdminViewer || profile.isReviewer) && (
          <Grid item lg={6} md={6} style={{ padding: '8px' }}>
            <Grid
              container
              direction={'column'}
              className={classes.detailBlock}
            >
              <Grid item>
                <Typography className={classes.blockTitle}>
                  Applicant Details
                </Typography>
              </Grid>

              <Grid item style={{ marginTop: '24px' }}>
                <Typography className={classes.blockText}>
                  Applicant Name:{' '}
                  <span className={classes.span}>{program?.fullName}</span>
                </Typography>
              </Grid>
              <Grid item style={{ marginTop: '16px' }}>
                <Typography className={classes.blockText}>
                  Applicant Email:{' '}
                  <span className={classes.span}>{program?.email}</span>
                </Typography>
              </Grid>
              <Grid item style={{ marginTop: '16px' }}>
                <Typography className={classes.blockText}>
                  Applicant DOB:{' '}
                  <span className={classes.span}>
                    {program?.dob
                      ? format(parseISO(program?.dob), 'MMM do y')
                      : ''}
                  </span>
                </Typography>
              </Grid>
              {/* <Grid item style={{ marginTop: "16px" }}>
                <Typography className={classes.blockText}>
                  SMS:{" "}
                  <span className={classes.span}>
                    {profile?.sendSmsConsent}
                  </span>
                </Typography>
                    </Grid>*/}
            </Grid>
          </Grid>
        )}

        {program.finalSubmissionCompleted && (
          <Grid item>
            <Typography className={classes.blockText}>
              Final Submission Completed:{' '}
              <span style={{ color: theme.palette.primary.main }}>
                COMPLETED
              </span>
            </Typography>
          </Grid>
        )}
      </Grid>

      {/*BUTTONS*/}
      <Grid
        item
        style={{
          marginTop: '30px',
          paddingLeft: '144px',
          paddingRight: '144px',
        }}
        justifyContent="space-between"
      >
        {/*ADMIN BUTTONS*/}

        {(profile.isAdmin || profile.isAdminViewer) && (
          <Grid item container justifyContent="space-between">
            <Button
              variant={currentForm === 'allForms' ? 'contained' : 'outlined'}
              className={currentForm === 'allForms' && classes.activeBtn}
              color={'primary'}
              onClick={() => handleShowForm('allForms')}
              size={'small'}
              startIcon={
                program.reviewedForms?.includes('allForms') && <DoneIcon />
              }
            >
              All Forms
            </Button>
            {uniqueObjectsArray?.map((button) => (
              <Grid item key={button.id} style={{ padding: '0.2em' }}>
                <Button
                  variant={
                    currentForm === button.checked ? 'contained' : 'outlined'
                  }
                  className={
                    currentForm === button.checked && classes.activeBtn
                  }
                  color={'primary'}
                  onClick={() => handleShowForm(button.checked)}
                  size={'small'}
                  startIcon={
                    program.reviewedForms?.includes(button.checked) && (
                      <DoneIcon />
                    )
                  }
                >
                  {button.name}
                </Button>
              </Grid>
            ))}

            <Grid item style={{ padding: '0.2em' }}>
              <Button
                variant={
                  currentForm === 'reviewerForm' ? 'contained' : 'outlined'
                }
                className={currentForm === 'reviewerForm' && classes.activeBtn}
                color={'primary'}
                onClick={() => handleShowForm('reviewerForm')}
                size={'small'}
                startIcon={
                  program.reviewedForms?.includes('reviewerForm') && (
                    <DoneIcon />
                  )
                }
              >
                Reviewer Response
              </Button>
            </Grid>

            {program.applicationAccepted &&
              acceptedButtons.map(
                (button) =>
                  button.value !== 'scholarConsentForm' && (
                    <Grid item key={button.id} style={{ padding: '0.2em' }}>
                      <Button
                        variant={
                          currentForm === button.value
                            ? 'contained'
                            : 'outlined'
                        }
                        color={'primary'}
                        onClick={() => handleShowForm(button.value)}
                        size={'small'}
                      >
                        {button.name}
                      </Button>
                    </Grid>
                  )
              )}
          </Grid>
        )}

        {/*REVIEWER BUTTONS*/}
        {profile.isReviewer && (
          <Grid item container justifyContent="space-between">
            {adminButtons
              .filter((button) => {
                if (
                  button.value === 'taxForms' ||
                  button.value === 'scholarshipForm' ||
                  button.value === 'covidForm'
                ) {
                  return false;
                }
                return true;
              })
              .map((button) => (
                <Grid item key={button.id} style={{ padding: '0.2em' }}>
                  <Button
                    variant={
                      currentForm === button.value ? 'contained' : 'outlined'
                    }
                    className={
                      currentForm === button.value && classes.activeBtn
                    }
                    color={'primary'}
                    onClick={() => handleShowForm(button.value)}
                    size={'small'}
                    startIcon={
                      program.reviewedForms?.includes(button.value) && (
                        <DoneIcon />
                      )
                    }
                  >
                    {button.name}
                  </Button>
                </Grid>
              ))}
            {/* {program.applicationAccepted && acceptedButtons.map((button) => (
              <Grid item key={button.id} style={{ padding: "0.2em" }}>
                <Button
                  variant={
                    currentForm === button.value ? "contained" : "outlined"
                  }
                  className={currentForm === button.value && classes.activeBtn}
                  color={"primary"}
                  onClick={() => handleShowForm(button.value)}
                  size={"small"}
                >
                  {button.name}
                </Button>
              </Grid>
            ))} */}
          </Grid>
        )}
      </Grid>

      <Grid
        item
        style={{
          backgroundColor: 'white',
          marginTop: '30px',
          padding: '0 60px',
        }}
      >
        <Grid item container>
          {/*QUESTIONS*/}
          <Grid
            item
            md={activityLog ? 12 : 6}
            style={{
              padding: '2rem',
              maxHeight: 800,
              overflowX: 'hidden',
              overflowY: 'auto',
            }}
          >
            {program.programStage === 'withdrawn' && (
              <Typography variant="h5" style={{ color: 'red' }}>
                Note: Application has been Withdrawn
              </Typography>
            )}

            {applicationForm && <ApplicationFeePaidReview program={program} />}

            {(profile.isAdmin || profile.isAdminViewer) && scholarshipForm && (
              <ScholarshipFormReview
                scholarship={scholarship}
                additionalDebt={additionalDebt}
                additionalExpense={additionalExpense}
              />
            )}

            {(profile.isAdmin || profile.isAdminViewer) && taxForm && (
              <TaxFormsReview taxForms={taxForms} />
            )}

            {(profile.isAdmin || profile.isAdminViewer) && covidForm && (
              <CovidFormReview covidDetails={covidDetails} program={program} />
            )}

            {personalInformationForm && (
              <PersonalInformationFormReview
                personalInformation={personalInformation}
              />
            )}

            {familyInformationForm && (
              <FamilyInformationFormReview
                familyInformation={familyInformation}
              />
            )}

            {academicInformationForm && (
              <AcademicInformationFormReview
                academicInformation={academicInformation}
              />
            )}

            {extracurricularInformationForm && (
              <ExtracurricularInformationFormReview
                extracurricularInformation={extracurricularInformation}
              />
            )}

            {resumeInformationForm && (
              <ResumeFormReview resumeForm={resumeForm} />
            )}

            {transcriptInformationForm && (
              <TranscriptFormReview transcriptForm={transcriptForm} />
            )}

            {additionalDocumentsInformationForm && (
              <AdditionalDocumentsFormReview
                additionalDocumentsForm={additionalDocumentsForm}
              />
            )}

            {shortResponseForm && (
              <ShortResponsesFormReview
                shortResponsesForm={shortResponsesForm}
                registeredProgram={program}
              />
            )}

            {recommendationForm && (
              <PersonalRecommendationsFormReview
                registeredProgram={program}
                recommendersForm={personalRecommendersForm}
                recommendersResponseForm={personalRecommendersResponseForm}
              />
            )}

            {recommendationForm && (
              <CounselorRecommendationsFormReview
                registeredProgram={program}
                recommendersForm={counselorRecommendersForm}
                recommendersResponseForm={counselorRecommendersResponseForm}
              />
            )}

            {recommendationForm && (
              <AcademicRecommendationsFormReview
                registeredProgram={program}
                recommendersForm={academicRecommendersForm}
                recommendersResponseForm={academicRecommendersResponseForm}
              />
            )}

            {confirmForm && (
              <ConfirmationFormReview confirmationForm={confirmationForm} />
            )}

            {/*ACCEPTED FORMS*/}
            {(profile.isAdmin || profile.isAdminViewer) &&
              grantAcceptanceForm && (
                <GrantAcceptanceFormReview
                  registeredProgram={program}
                  grantAcceptanceForm={grantAcceptanceForm}
                />
              )}

            {(profile.isAdmin || profile.isAdminViewer) &&
              scholarConsentForm && (
                <ScholarConsentFormReview
                  registeredProgram={program}
                  scholarConsentForm={scholarConsentForm}
                />
              )}

            {(profile.isAdmin || profile.isAdminViewer) && governmentForm && (
              <GovernmentIdFormReview governmentIdForm={governmentIdForm} />
            )}

            {(profile.isAdmin || profile.isAdminViewer) &&
              requiredDocumentationForm && (
                <RequiredDocumentationFormReview
                  requiredDocuments={requiredDocuments}
                />
              )}

            {(profile.isAdmin || profile.isAdminViewer) &&
              studentQuestionnaireForm && (
                <StudentQuestionnaireFormReview
                  questionnaire={studentQuestionnaire}
                />
              )}

            {(profile.isAdmin || profile.isAdminViewer) && paymentForm && (
              <PaymentFormReview paymentForm={program} />
            )}

            {(profile.isAdmin || profile.isAdminViewer) && travelForm && (
              <TravelInformationFormReview
                travelInformation={travelInformation}
                vaccineCard={vaccineCard}
              />
            )}

            {reviewerForm && (
              <ReviewerForm
                reviewerForm={reviewerResponse}
                initialValues={initialValues}
                updatedAt={program.reviewerUpdated}
                isAdmin={profile.isAdmin || profile.isAdminViewer}
                fullName={profile?.fullName}
              />
            )}
            {activityLog && <ActivityLogs logs={logs} />}
          </Grid>

          {/*REVIEW FORM*/}
          {!activityLog && (
            <Grid item md={6}>
              {profile.isReviewer && (
                <ReviewForm
                  profile={profile}
                  program={program}
                  currentForm={currentForm}
                />
              )}
              {(profile.isAdmin || profile.isAdminViewer) && (
                <AdminReviewForm
                  profile={profile}
                  program={program}
                  currentForm={currentForm}
                  handleToggleGrantScholarship={handleToggleGrantScholarship}
                  handleToggleGrantSponsorship={handleToggleGrantSponsorship}
                  reload={reloadProgramReview}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: 'scholarshipRejection', validate })(ReviewProgram));
