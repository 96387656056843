import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import ReviewProgramItem from "./item/ReviewProgramItem";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  answerText: {
    fontWeight: 600,
  },
}));

const TaxFormReview = ({ taxForms }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction={"column"}
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
    >
      {/*APPLICATION FEE*/}
      <Grid item>
        <Typography className={classes.title}>Tax Forms</Typography>
      </Grid>

      {/*APPLICATION FEE*/}
      {taxForms.map((form) => (
        <ReviewProgramItem
          key={form.id}
          question={form.originalFileName}
          value={
            <a
              href={form.fileURL}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Link
            </a>
          }
        />
      ))}
    </Grid>
  );
};

export default TaxFormReview;
