import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Sidebar from "../../../ui/sidebar/Sidebar";
import {
  fetchAllTransactions,
  updateProcessingFee,
  fetchAllUsers,
} from "../../../store/actions/adminActions/transactionActions/transactionActions";
import { connect } from "react-redux";
import { format, fromUnixTime } from "date-fns";
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { CustomToolbar, escapeRegExp } from "../../../common/tableUtils";
import classnames from "classnames";
import { Field } from "redux-form";
import TextArea from "../../../common/form/TextArea";
import { reduxForm } from "redux-form";
import { combineValidators } from "revalidate";
import { formValueSelector } from "redux-form";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#171725",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight: "24px",
    letter: "0.1px",
  },
  status: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .succeeded": {
      background: "rgb(215, 247, 194)",
      color: "rgb(5, 105, 13)",
      borderRadius: 4,
      padding: "1px 6px",
    },
    "& .failed": {
      background: "rgb(255, 231, 242)",
      color: "rgb(179, 6, 61)",
      borderRadius: 4,
      padding: "1px 6px",
    },
    "& .canceled": {
      background: "rgb(235, 238, 241)",
      color: "rgb(84, 90, 105)",
      borderRadius: 4,
      padding: "1px 6px",
    },
  },
}));

const validate = combineValidators({
  processingFee: (value) => {
    if (!value) return "Required";
    if (value === ".") return "Invalid  value";
  },
});

const actions = {
  fetchAllTransactions,
  updateProcessingFee,
  fetchAllUsers,
};

const mapStateToProps = (state) => {
  let transactions = [];
  let applications = [];

  if (
    state.adminTransactions.allTransactions &&
    state.adminTransactions.allTransactions.length > 0
  ) {
    transactions = state.adminTransactions.allTransactions;
  }

  if (state.adminDashboard.dashboardValues) {
    if (
      state.adminTransactions.users &&
      state.adminTransactions.users.length > 0
    ) {
      applications = state.adminTransactions.users;
    }
  }

  const questionSelector = formValueSelector("processingFeeForm");
  const fieldValues = questionSelector(state, "processingFee");

  return {
    transactions,
    applications,
    fieldValues,
    initialValues: {
      processingFee: state.applicationSettings.processingFee,
    },
  };
};

const normalizeFee = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d+(.\d{1,2})?$]/g, "");
  const cvLength = currentValue.length;
  if (cvLength > 4) return currentValue.slice(0, 4);
  return currentValue;
};

const Transactions = ({
  fetchAllTransactions,
  transactions,
  applications,
  handleSubmit,
  submitting,
  fieldValues,
  change,
  updateProcessingFee,
  fetchAllUsers,
}) => {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      "e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    );
  }, []);

  useEffect(() => {
    fetchAllTransactions();
  }, [fetchAllTransactions]);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  useEffect(() => {
    if (fieldValues) change("processingFee", normalizeFee(fieldValues));
  }, [fieldValues, change]);

  console.log("applications", applications);

  useEffect(() => {
    const arr = transactions.map((transaction, index) => {
      const applicant = applications.find(
        (application) => application.id === transaction.client_reference_id
      );
      return {
        ...transaction,
        serialNo: index + 1,
        createdAt:
          transaction.createdAt &&
          format(new Date(fromUnixTime(transaction.createdAt)), "EEE MMM do y"),
        fullName: applicant && applicant.fullName,
        programName:
          applicant &&
          applicant.programDetails &&
          applicant.programDetails.programName,
      };
    });
    setRows(arr);
    if (searchText) {
      setSearchText(searchText);
      const searchRegex = new RegExp(escapeRegExp(searchText), "i");
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
    }
  }, [transactions, applications]);

  const memoizedColumns = useMemo(() => {
    return [
      { field: "serialNo", headerName: "Id", width: 100 },
      {
        field: "fullName",
        headerName: "Name",
        width: 160,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "programName",
        headerName: "Program",
        width: 220,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "amount",
        headerName: "Amount",
        width: 90,
        renderCell: (cell) => `$${cell.value}`,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "status",
        headerName: "Status",
        width: 120,
        valueGetter: (param) => param.value || "N/A",
        renderCell: (param) => (
          <Box className={classes.status}>
            <Typography
              className={classnames({
                succeeded: param.value === "succeeded",
                failed: param.value === "failed",
                canceled:
                  param.value === "canceled" ||
                  param.value === "requires_payment_method",
              })}
            >
              {param.value === "requires_payment_method"
                ? "Incomplete"
                : param.value}
              {param.value === "succeeded" && <> &#x2714;</>}
              {(param.value === "canceled" || param.value === "failed") && (
                <> &#x2716;</>
              )}
              {param.value === "requires_payment_method" && (
                <svg
                  aria-hidden="true"
                  class="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--clock-svg Icon-color-svg Icon-color--gray500-svg"
                  height="12"
                  width="12"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginLeft: 4 }}
                  fill="rgb(84, 90, 105)"
                >
                  <path
                    d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm1-8.577V4a1 1 0 1 0-2 0v4a1 1 0 0 0 .517.876l2.581 1.49a1 1 0 0 0 1-1.732z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              )}
            </Typography>
          </Box>
        ),
      },
      {
        field: "payment_intent",
        headerName: "Payment ID",
        width: 260,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "customer",
        headerName: "Customer",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "cardLast4",
        headerName: "Payment Method",
        width: 160,
        valueFormatter: (param) =>
          param.row?.cardBrand
            ? param.row.cardBrand + " ...." + param.row.cardLast4
            : "",
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "createdAt",
        headerName: "Created At",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "receipt_url",
        headerName: "Payment Receipt",
        width: 180,
        renderCell: (param) => {
          if (param.value) {
            return (
              <a
                href={param.value}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
                style={{cursor:'pointer'}}
              >
                Download
              </a>
            );
          }

          return "N/A";
        },
      },
    ];
  }, []);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filtered = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };

  const handleUpdateProcessingFee = async (values) => {
    await updateProcessingFee(values.processingFee);
    setDialog(false);
  };

  return (
    <Grid container>
      <Dialog open={dialog} onClose={() => setDialog(false)} maxWidth="md">
        <form onSubmit={handleSubmit(handleUpdateProcessingFee)}>
          <DialogTitle>Edit Processing Fee</DialogTitle>
          <DialogContent>
            <Field
              name={"processingFee"}
              label={"Processing Fee"}
              component={TextArea}
              type={"text"}
              variant={"outlined"}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDialog(false)}
              color="secondary"
              disabled={submitting}
            >
              Cancel
            </Button>
            <Button color={"primary"} type={"submit"} disabled={submitting}>
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Grid item md={2}>
        <Sidebar />
      </Grid>
      <Grid
        item
        md={10}
        style={{
          backgroundColor: "#FAFAFB",
          paddingLeft: "41px",
          paddingRight: "41px",
        }}
      >
        <Grid item container direction={"column"}>
          <Grid
            item
            container
            style={{ marginTop: "20px" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className={classes.title}>Transactions</Typography>
            <Button
              color="primary"
              variant="contained"
              disabled={submitting}
              onClick={() =>fetchAllTransactions(transactions[transactions.length-1].payment_intent, transactions[transactions.length-1].session_id)}
              style={{marginLeft:'25rem'}}
            >
              Load More Transactions
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={submitting}
              onClick={() => setDialog(true)}
            >
              Update Processing Fee
            </Button>
          </Grid>
          <Grid item style={{ marginTop: "2em" }}>
            <Box height={684} width="100%">
              <DataGridPremium
                getRowId={(row) => row.payment_intent}
                columns={memoizedColumns}
                rows={filteredRows}
                disableSelectionOnClick
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50]}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    value: searchText,
                    clearSearch: () => requestSearch(""),
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "processingFeeForm", validate })(Transactions));
