import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Sidebar from "../../../../ui/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { combineValidators, isRequired } from "revalidate";
import TextInput from "../../../../common/form/TextInput";
import AcademicRecommenderSignNowForm from "./AcademicRecommenderSignNowForm";
import CounselorRecommenderSignNowForm from "./CounselorRecommenderSignNowForm";
import PersonalRecommenderSignNowForm from "./PersonalRecommenderSignNowForm";
import ScholarConsentSignNowForm from "./ScholarConsentSignNowForm";
import GrantAcceptanceSignNowForm from "./GrantAcceptanceSignNowForm";
import {
  addSignNowSettings,
  fetchSignNowSettings,
  updateSignNowSettings,
} from "../../../../store/actions/adminActions/settingsActions/settingsActions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },

  title: {
    color: "#171725",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight: "24px",
    letter: "0.1px",
  },
}));

const validate = combineValidators({
  templateName: isRequired({ message: "Name is required" }),
  templateSubject: isRequired({ message: "Subject is required" }),
  templateCategory: isRequired({ message: "Category is required" }),
  templateReplyTo: isRequired({ message: "Reply To is required" }),
});

const actions = {
  addSignNowSettings,
  updateSignNowSettings,
  fetchSignNowSettings,
};

const mapStateToProps = (state) => {
  // let template = {};
  //
  // if (state.adminSettings.template && state.adminSettings.template.length > 0) {
  //     template = state.adminSettings.template[0];
  // }
  return {
    loading: state.loading.loading,
    initialValues: state.adminSettings.signNow[0],
  };
};

const AdminSignNow = ({
  addSignNowSettings,
  updateSignNowSettings,
  fetchSignNowSettings,
  loading,
  handleSubmit,
  submitting,
}) => {
  const classes = useStyles();

  const history = useHistory();
  // function useQuery() {
  //   return new URLSearchParams(useLocation().search);
  // }

  useEffect(() => {
    fetchSignNowSettings();
  }, [fetchSignNowSettings]);

  const handleUpdateSettings = async (values) => {
    if (values.id) {
      await updateSignNowSettings(values.id, values);
      history.push("/admin/dashboard");
    }

    if (!values.id) {
      await addSignNowSettings(values);
      history.push("/admin/dashboard");
    }
  };

  return (
    <Grid container>
      <Grid item md={2}>
        <Sidebar />
      </Grid>

      <Grid
        item
        md={10}
        style={{
          backgroundColor: "#FAFAFB",
          paddingLeft: "41px",
          paddingRight: "41px",
        }}
      >
        <Grid item container direction={"column"}>
          <Grid item style={{ marginTop: "20px" }}>
            <Typography className={classes.title}>SignNow Settings</Typography>
          </Grid>

          <form
            autoComplete={"off"}
            onSubmit={handleSubmit(handleUpdateSettings)}
          >
            {/*INPUT CONTAINER*/}
            <Grid item container style={{ marginTop: "2em" }}>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                {/*BUTTON CONTAINER*/}
                <Grid item>
                  <Grid item container>
                    <Grid item style={{ marginLeft: "auto" }}>
                      <Button
                        size={"small"}
                        variant={"outlined"}
                        color={"primary"}
                        className={classes.button}
                        type={"submit"}
                        disabled={submitting}
                      >
                        Update
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size={"small"}
                        variant={"outlined"}
                        color={"secondary"}
                        className={classes.button}
                        onClick={() => history.push("/admin/dashboard/")}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                {/*AUTHENTICATION*/}
                <Grid item className={classes.fieldWrapper}>
                  <Typography variant={"body1"}>Authentication</Typography>
                </Grid>
                <Grid item className={classes.fieldWrapper}>
                  <Field
                    name={"signNowURL"}
                    label={"SignNow URL"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                  />
                </Grid>
                <Grid item className={classes.fieldWrapper}>
                  <Field
                    name={"basicAuthorizationToken"}
                    label={"Basic Authorization Token"}
                    component={TextInput}
                    type={"password"}
                    variant={"outlined"}
                  />
                </Grid>
                <Grid item className={classes.fieldWrapper}>
                  <Field
                    name={"username"}
                    label={"Username"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                  />
                </Grid>
                <Grid item className={classes.fieldWrapper}>
                  <Field
                    name={"password"}
                    label={"Password"}
                    component={TextInput}
                    type={"password"}
                    variant={"outlined"}
                  />
                </Grid>

                {/*INVITE FROM DETAILS*/}
                <Grid
                  item
                  className={classes.fieldWrapper}
                  style={{ marginTop: "1em" }}
                >
                  <Typography variant={"body1"}>Invitation Details</Typography>
                </Grid>
                <Grid item className={classes.fieldWrapper}>
                  <Field
                    name={"inviteFromName"}
                    label={"Invite From Name"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                  />
                </Grid>
                <Grid item className={classes.fieldWrapper}>
                  <Field
                    name={"inviteFromEmail"}
                    label={"Invite From Email"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                  />
                </Grid>

                {/*ACADEMIC RECOMMENDER*/}
                <AcademicRecommenderSignNowForm />

                {/*COUNSELOR RECOMMENDER FORM*/}
                <CounselorRecommenderSignNowForm />

                {/*PERSONAL RECOMMENDER FORM*/}
                <PersonalRecommenderSignNowForm />

                {/*SCHOLAR CONSENT FORM*/}
                <ScholarConsentSignNowForm />

                {/*GRANT ACCEPTANCE FORM*/}
                <GrantAcceptanceSignNowForm />
              </Grid>
            </Grid>

            {/*BUTTON CONTAINER*/}
            <Grid item container style={{ marginTop: "2em" }}>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <Grid item>
                  <Grid item container>
                    <Grid item style={{ marginLeft: "auto" }}>
                      <Button
                        size={"small"}
                        variant={"outlined"}
                        color={"primary"}
                        className={classes.button}
                        type={"submit"}
                        disabled={submitting}
                      >
                        Update
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size={"small"}
                        variant={"outlined"}
                        color={"secondary"}
                        className={classes.button}
                        onClick={() => history.push("/admin/dashboard")}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({ form: "signNow", enableReinitialize: true, validate })(
    AdminSignNow
  )
);
