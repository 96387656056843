import React, { Fragment, useEffect, useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Grid, Button, IconButton, Switch } from "@mui/material";
import Sidebar from "../../../../ui/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import {
  fetchViewCarouselSettingsForBanner,
  updateViewCarouselSettingsForBanner,
  updateNewsAndAnnouncements,
} from "../../../../store/actions/adminActions/settingsActions/settingsActions";
import CheckboxInput from "../../../../common/form/CheckboxInput";
import DropzoneInput from "../../../../ui/dropzone/DropzoneInput";
import { uploadImageForBanner } from "../../../../store/actions/adminActions/settingsActions/settingsActions";
import TextArea from "../../../../common/form/TextArea";
import { Cancel } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import TextInput from "../../../../common/form/TextInput";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },

  title: {
    color: "#171725",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight: "24px",
    letter: "0.1px",
  },
  dropzone: {
    marginTop: "0.5em",
    width: "50%",
    height: "150px",
  },
  formText: {
    color: "#696974",
    fontWeight: "400",
    fontFamily: "Roboto",
    fontSize: "16px",
    letter: "0.2px",
  },
}));

const mapStateToProps = (state) => {
  let settings = {};

  if (state.adminSettings?.settings?.length) {
    settings = state.adminSettings?.settings[0];
  }

  return {
    loading: state.loading.loading,
    initialValues: settings,
  };
};

const actions = {
  fetchViewCarouselSettingsForBanner,
  updateViewCarouselSettingsForBanner,
  uploadImageForBanner,
  updateNewsAndAnnouncements,
};

const AdminHomePage = ({
  fetchViewCarouselSettingsForBanner,
  updateViewCarouselSettingsForBanner,
  uploadImageForBanner,
  updateNewsAndAnnouncements,
  handleSubmit,
  initialValues,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();
  const [bannerFiles, setBannerFiles] = useState(null);
  const [file, setFile] = useState(null);

  const [disabled, setDisabled] = useState(false);
  const [isViewCarousel, setIsViewCarousel] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchViewCarouselSettingsForBanner();
  }, [fetchViewCarouselSettingsForBanner]);

  useEffect(() => {
    setIsViewCarousel(initialValues?.showCarouselForBanner);
  }, [initialValues]);

  const handleUpdateSettings = async (values) => {
    values.showCarouselForBanner = isViewCarousel;
    await updateViewCarouselSettingsForBanner(values);
    history.push("/admin/dashboard");
  };

  const handleNewsAndAnnounsementsUpdateSettings = async (values) => {
    await updateNewsAndAnnouncements(initialValues?.id, values, file);
    history.push("/admin/dashboard");
  };

  const handleBannerFileUpload = async () => {
    setDisabled(true);
    try {
      await uploadImageForBanner(initialValues?.id, bannerFiles);
      setBannerFiles(null);
      setOpen(true);
      setDisabled(false);
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Uploaded Successfully
        </Alert>
      </Snackbar>

      <Grid container>
        <Grid item md={2}>
          <Sidebar />
        </Grid>

        <Grid
          item
          md={10}
          style={{
            backgroundColor: "#FAFAFB",
            paddingLeft: "41px",
            paddingRight: "41px",
          }}
        >
          <Grid item container direction={"column"}>
            <Typography
              variant={"h6"}
              style={{
                fontWeight: 600,
                marginTop: "1em",
                marginBottom: "1em",
              }}
            >
              Home Page
            </Typography>
            <form
              autoComplete={"off"}
              onSubmit={handleSubmit(handleUpdateSettings)}
            >
              <Fragment>
                <Typography
                  variant={"subtitle1"}
                  style={{
                    fontWeight: 600,
                    marginTop: "1em",
                    marginBottom: "1em",
                  }}
                >
                  Banner
                </Typography>

                <Grid item style={{ marginTop: "0.2em" }}>
                  <Grid item container alignItems="center">
                    <Grid item md={5} style={{ paddingRight: "1.5em" }}>
                      <Typography
                        variant={"subtitle1"}
                        style={{
                          marginTop: "1em",
                          marginBottom: "1em",
                        }}
                      >
                        View Carousel For Banner
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Switch
                        color="primary"
                        checked={isViewCarousel}
                        onChange={async (event, checked) => {
                          setIsViewCarousel(!isViewCarousel);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container direction={"column"}>
                  {!bannerFiles && (
                    <Fragment>
                      <Grid item>
                        <Typography className={classes.formText}>
                          Drop or Add File Here For Banner - files must be
                          .jpeg, .jpg or .png
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid item container>
                          <Grid item className={classes.dropzone}>
                            <DropzoneInput
                              setFiles={setBannerFiles}
                              accept={".jpg, .jpeg, .png"}
                              multiple={isViewCarousel}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Fragment>
                  )}
                </Grid>

                {bannerFiles?.length &&
                  bannerFiles.map((file, i) => (
                    <Grid item key={file.id}>
                      <Grid container alignItems="center">
                        <Grid item md={3}>
                          <Typography className={classes.formText}>
                            {file.name}
                          </Typography>
                        </Grid>
                        {bannerFiles?.length > 1 &&
                          i === bannerFiles?.length - 1 && (
                            <>
                              <Grid item style={{ marginLeft: "1em" }}>
                                <Button
                                  className={classes.button}
                                  color="primary"
                                  variant="contained"
                                  size="small"
                                  onClick={handleBannerFileUpload}
                                  disabled={disabled}
                                >
                                  Upload
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  className={classes.button}
                                  style={{
                                    backgroundColor: theme.palette.error.main,
                                    color: "white",
                                  }}
                                  variant="contained"
                                  size="small"
                                  onClick={() => setBannerFiles(null)}
                                >
                                  Delete
                                </Button>
                              </Grid>
                            </>
                          )}

                        {bannerFiles?.length === 1 && (
                          <>
                            <Grid item style={{ marginLeft: "1em" }}>
                              <Button
                                className={classes.button}
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={handleBannerFileUpload}
                                disabled={disabled}
                              >
                                Upload
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                className={classes.button}
                                style={{
                                  backgroundColor: theme.palette.error.main,
                                  color: "white",
                                }}
                                variant="contained"
                                size="small"
                                onClick={() => setBannerFiles(null)}
                              >
                                Delete
                              </Button>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  ))}

                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  style={{ marginTop: "1.5rem", width: "50%" }}
                >
                  <Button
                    variant={"outlined"}
                    color={"secondary"}
                    className={classes.button}
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant={"outlined"}
                    color={"primary"}
                    type="submit"
                    className={classes.button}
                  >
                    Save
                  </Button>
                </Grid>
              </Fragment>
            </form>
            <form
              autoComplete={"off"}
              onSubmit={handleSubmit(handleNewsAndAnnounsementsUpdateSettings)}
            >
              <Fragment>
                <Typography
                  variant={"subtitle1"}
                  style={{
                    fontWeight: 600,
                    marginTop: "1em",
                    marginBottom: "1em",
                  }}
                >
                  News And Announcements
                </Typography>
                <Grid
                  item
                  md={6}
                  className={classes.fieldWrapper}
                  style={{ paddingRight: "0.2em" }}
                >
                  <Field
                    name={"heading"}
                    label={"Heading"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                  />
                </Grid>
                <Grid
                  item
                  className={classes.fieldWrapper}
                  sx={{ width: "50%" }}
                >
                  <Field
                    name={"details"}
                    label={"Details"}
                    component={TextArea}
                    rows={5}
                    type={"text"}
                    variant={"outlined"}
                  />
                </Grid>
                <Grid
                  item
                  className={classes.fieldWrapper}
                  sx={{ width: "50%" }}
                >
                  {!file && (
                    <DropzoneInput
                      accept={".jpg, .jpeg, .png"}
                      setFiles={setFile}
                    />
                  )}
                  {file && (
                    <Grid container alignItems="center">
                      <Typography>{file[0].name}</Typography>
                      <IconButton
                        onClick={() => setFile(null)}
                        color="secondary"
                        size="small"
                      >
                        <Cancel />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  style={{ marginTop: "1.5rem", width: "50%" }}
                >
                  <Button
                    variant={"outlined"}
                    color={"secondary"}
                    className={classes.button}
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant={"outlined"}
                    color={"primary"}
                    type="submit"
                    className={classes.button}
                  >
                    Save
                  </Button>
                </Grid>
              </Fragment>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "homePage", enableReinitialize: true })(AdminHomePage));
