import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Typography,
  MenuItem,
  InputLabel,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import TextInput from '../../../common/form/TextInput';
import { Field, reduxForm } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../../../store/actions/authActions/authActions';
import DatePickerInput from '../../../common/form/DatePickerInput';
import MonthPickerInput from '../../../common/form/MonthPickerInput';
import SelectInput from '../../../common/form/SelectInput';
import CheckboxInput from '../../../common/form/CheckboxInput';
import GradeLookup from '../../../pages/admin/programs/GradeLookup';
import { combineValidators, isRequired } from 'revalidate';
import { formValueSelector } from 'redux-form';
import StateLookup from '../../forms/formLookups/StateLookup';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import is from 'date-fns/esm/locale/is/index.js';
// import { closeDialog } from '../dialogActions/dialogActions';
import { closeDialog } from '../../../store/actions/dialogActions/dialogActions';
import EmailIcon from '@mui/icons-material/Email';
import CreateIcon from '@mui/icons-material/Create';
import {
  disableTemplate,
  enableTemplate,
  fetchAllTemplates,
  deleteTemplate,
} from '../../../store/actions/adminActions/settingsActions/settingsActions';

const actions = {
  registerUser,
};

const validate = combineValidators({
  firstName: isRequired({ message: 'First Name is required' }),
  lastName: isRequired({ message: 'Last Name is required' }),
  mobileNumber: isRequired({ message: 'Mobile Number is required' }),
  // dob: isRequired({ message: 'Date of Birth is required' }),
  // gradeLevel: isRequired({ message: 'Grade Level is required' }),
  email: isRequired({ message: 'Email Address is required' }),
  gender: isRequired({ message: 'Gender is required' }),
  // ethnicBackground: isRequired({ message: 'Ethnic Background is required' }),
  // password: isRequired({ message: 'Password is required' }),
  // parentName: isRequired({ message: 'Parent Name is required' }),
  // parentEmail: isRequired({ message: 'Parent Email is required' }),
  // parentNumber: isRequired({ message: 'Parent Number is required' }),
  // counselorName: isRequired({ message: 'Counselor Name is required' }),
  // counselorEmail: isRequired({ message: 'Counselor Email is required' }),
  /// recommendertype: isRequired({ message: 'Recommender type is required' }),
  highSchool: isRequired({ message: 'High School is required' }),
  gradYear: isRequired({ message: 'Expected Graduation is required' }),
  programInterested: isRequired({ message: 'Please Fill Interested program' }),
  citizen: isRequired({ message: 'Citizenship Status is required' }),
  // address1: isRequired({ message: 'Address is required' }),
  city: isRequired({ message: 'City is required' }),
  state: isRequired({ message: 'State is required' }),
  // zipCode: isRequired({ message: 'Zip Code is required' }),
  // graduationMonthYear: isRequired({
  //   message: 'Graduation Month Year is required',
  // }),
});

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    paddingLeft: '0.7em',
    paddingRight: '0.7em',
  },
  /* button: {
    width: '220px',
    height: '38px',
    borderRadius: '10px',
    marginLeft: '0.4em',
    backgroundColor: '#35BA35',
    color: 'white',
    fontWeight: 700,
    borderRadius: '50.25rem',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#35BA35',
      color: 'white',
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: 1,
      fontSize: '12px',
    },
  },*/
  button: {
    backgroundColor: '#ffc627',
    color: '#000',
    outline: 'transparent',
    fontWeight: '600',
    lineHeight: '1.6',
    width: '260px',
    fontSize: '1rem',
    borderRadius: '10px',
    letterSpacing: '2px',
    '&:disabled': {
      backgroundColor: '#e5e5e5' || '#ffc627',
    },
    '&:hover': {
      backgroundColor: '#f7bb14' || '#ffc627',
      transform: 'scale(0.98)',
      /* Scaling button to 0.98 to its original size */
      boxShadow: '3px 2px 22px 1px rgba(0, 0, 0, 0.24)',
    },
  },
  fieldWrapper: {
    paddingTop: '0.3em',
    paddingBottom: '0.3em',
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 500,
  },

  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  formName: {
    // color: '#696974',
    fontWeight: '900',
    fontFamily: 'Roboto',
    //marginTop: '22px',
    fontSize: '2.5rem',
    // lineHeight: '72px',
    letter: '0.2px',
  },
  rightTitle: {
    marginBottom: '55px',
    fontSize: '18px',
  },
  rightText: {
    fontWeight: '400',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  formText: {
    // color: '#696974',
    // backgroundColor: 'lightyellow',
    backgroundColor: '#ffc627',
    color: 'black',
    margin: '10px 0',
    marginRight: '30px',
    width: 'fit-content',
    padding: '0 5px',
    fontSize: '1.2rem',
    lineHeight: '1.6',
    fontWeight: '600',
  },
  formTexts: {
    color: '#696974',
    backgroundColor: 'yellow',
    border: '2px',
    marginTop: '8px',
    fontWeight: '400',
    width: '412px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  formQuestionText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  custombox: {
    border: '1px solid white',
    padding: '20px',
    fontFamily: 'Roboto',
    // backgroundColor: ' #f0f0f0',
    width: '80%',
    margin: '20px 0px 20px 0px',
    borderColor: 'black',
  },
}));

const mapStateToProps = (state) => {
  const questionSelector = formValueSelector('registerForm');
  const fieldValues = questionSelector(state, 'mobileNumber', 'parentNumber');
  let templates = [];
  console.log(state, 'manishstate');
  if (
    state.adminSettings.allTemplates &&
    state.adminSettings.allTemplates.length > 0
  ) {
    templates = state.adminSettings.allTemplates;
  }
  return {
    initialValues: {
      sendSmsConsent: true,
    },
    fieldValues,
    templates,
  };
};

const normalizePhoneNumber = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;
  if (cvLength < 4) return currentValue;
  if (cvLength < 7)
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
    3,
    6
  )}-${currentValue.slice(6, 10)}`;
};

const RegisterForm = ({
  registerUser,
  handleSubmit,
  templates,
  fieldValues,
  change,
  fetchAllTemplates,
}) => {
  const classes = useStyles();
  const grades = GradeLookup.grades;
  const [error, setError] = useState(null);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectOther, setSelectOther] = useState([]);
  const [input, setInput] = useState(false);
  const states = StateLookup.states;
  const history = useHistory();
  console.log(fetchAllTemplates, 'fetchAllTemplates');
  useEffect(() => {
    fetchAllTemplates();
  }, [fetchAllTemplates]);
  const [modal, setModal] = useState(false);
  const [open, setOpen] = useState(false);
  console.log(templates, 'templates');
  const dispatch = useDispatch();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(closeDialog());
  };
  const manosh = useSelector((state) => console.log(state, 'state'));
  const { allPrograms } = useSelector((state) => state?.adminPrograms);
  const { allCategories } = useSelector((state) => state?.adminCategories);

  console.log('allPrograms', allCategories);

  useEffect(() => {
    change('mobileNumber', normalizePhoneNumber(fieldValues.mobileNumber));
  }, [fieldValues.mobileNumber, change]);
  const [statuses, setStatuses] = useState([]);
  const uniqueElements = [
    ...new Set(
      allPrograms.filter(
        (i) =>
          i.programCategory !== undefined &&
          !i.programCategory.includes('EDLI') &&
          !i.programCategory.includes('GLI') &&
          !i.programCategory.includes('GILI') &&
          !i.programCategory.includes('MdRBVXGD4c3zzLydAzlw')
      )
    ),
  ];
  const uniqueElementss = [
    allPrograms.filter(
      (i) =>
        i.programCategory !== undefined &&
        !i.programCategory.includes('EDLI') &&
        !i.programCategory.includes('GLI') &&
        !i.programCategory.includes('GILI') &&
        !i.programCategory.includes('MdRBVXGD4c3zzLydAzlw')
    ),
  ];

  console.log(uniqueElementss[0], 'uniqueElementss');
  const uniqueProgramCategories = [
    ...new Set(uniqueElementss[0].map((obj) => obj.programCategory)),
  ];
  console.log(uniqueProgramCategories, 'uniqueProgramCategories');

  useEffect(() => {
    change('parentNumber', normalizePhoneNumber(fieldValues.parentNumber));
  }, [fieldValues.parentNumber, change]);

  const handleRegister = async (values) => {
   // console.log('sdsssdsdsdds', values);
    // values.ethnicBackground = selectedValue;

    // if (selectedValue.includes("Other")) {
    //   values.ethnicBackground = [values.ethnicBackgrounds];
    //   delete values.ethnicBackgrounds;
    // } else {
    //   values.ethnicBackground = selectedValue;
    // }
    console.log(values, 'values');
    let userRegister = await registerUser(values);
    console.log(userRegister, 'userRegister');

    if (userRegister === 'Please enter a valid email address.') {
      setError(userRegister);
    }
    if (userRegister === 'Email already exists.') {
      setError(userRegister);
    }
    if (userRegister == undefined) {
      setOpen(true);
      // dispatch(closeDialog());
    }
  };

  const getStatusString = () => {
    if (
      allPrograms.some(
        (pr) =>
          new Date(pr.applicationDeadline) > new Date() &&
          new Date(pr.applicationOpen) > new Date()
      )
    ) {
      statuses.push('Upcoming');
    }

    if (
      allPrograms.some(
        (pr) =>
          new Date(pr.applicationDeadline) > new Date() &&
          new Date(pr.applicationOpen) < new Date()
      )
    ) {
      statuses.push('Current');
    }

    if (
      allPrograms.some((pr) => new Date(pr.applicationDeadline) < new Date())
    ) {
      statuses.push('Past');
    }

    // return setStatuses.join(', ');
  };
  useEffect(() => {
    getStatusString();
  }, [allPrograms]);
  // Programinterested,
  // First Name, Last Name, Email, Phone, State, grad year, grade level, highschool, Program interested
  console.log(statuses, 'statuses');
  console.log(uniqueElementss, 'uniqueElements');
  return (
    <>
      <form autoComplete={'off'} onSubmit={handleSubmit(handleRegister)}>
        <Grid item container direction={'column'}>
          <Grid item>
            <Grid item container>
              <Grid
                item
                md={6}
                className={classes.fieldWrapper}
                style={{ paddingRight: '0.2em' }}
              >
                <Field
                  name={'firstName'}
                  label={'* First Name'}
                  component={TextInput}
                  type={'text'}
                  variant={'outlined'}
                />
              </Grid>

              <Grid
                item
                md={6}
                className={classes.fieldWrapper}
                style={{ paddingLeft: '0.2em' }}
              >
                <Field
                  name={'lastName'}
                  label={'* Last Name'}
                  component={TextInput}
                  type={'text'}
                  variant={'outlined'}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item className={classes.fieldWrapper}>
          <Field
            name={'dob'}
            label={'Date of Birth'}
            component={DatePickerInput}
            type={'text'}
            inputVariant={'outlined'}
            size={'small'}
            style={{ width: '100%' }}
          />
        </Grid> */}

          <Grid item className={classes.fieldWrapper}>
            <Field
              name={'mobileNumber'}
              label={'* Mobile Number'}
              component={TextInput}
              type={'tel'}
              variant={'outlined'}
            />
          </Grid>

          <Grid item className={classes.fieldWrapper}>
            <Field
              name={'email'}
              label={'* Email Address'}
              component={TextInput}
              type={'email'}
              variant={'outlined'}
            />
          </Grid>

          {/* <Grid item className={classes.fieldWrapper}>
          <Field
            name={'gender'}
            label={'Gender'}
            component={TextInput}
            type={'text'}
            variant={'outlined'}
            select
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Non-binary">Non-binary</MenuItem>
            <MenuItem value="Prefer not to Disclose">
              Prefer not to Disclose
            </MenuItem>
          </Field>
        </Grid> */}

          {/* <Grid item className={classes.fieldWrapper}>
          <InputLabel htmlFor="ethnic-background-label">
            Ethnic background
          </InputLabel>
          <Select
            name="ethnicBackground"
            value={selectedValue}
            onChange={(e) => {
              let values = e.target.value;
              console.log(values, 'values');
              if (values == 'Other') {
                setInput(true);
              } else {
                setInput(false);
              }

              if (!selectedValue.includes(values)) {
                setSelectedValue(values);
              }
            }}
            label={'Ethnic background'}
            component={TextInput}
            type={'text'}
            variant={'outlined'}
            multiple
            style={{ width: '100%' }}
          >
            <MenuItem value="Asian American/Pacific Islander">
              Asian American/Pacific Islander
            </MenuItem>
            <MenuItem value="Black/African American">
              Black/African American
            </MenuItem>
            <MenuItem value="Caucasian">Caucasian</MenuItem>
            <MenuItem value="Latino">Latino</MenuItem>
            <MenuItem value="Multi-racial">Multi-racial</MenuItem>
            <MenuItem value="Native American">Native American</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
           
          </Select>
        </Grid> */}

          {input && (
            <Grid item className={classes.fieldWrapper}>
              <Field
                name="ethnicBackgrounds"
                label={'Ethnic Background'}
                component={TextInput}
                type={'text'}
                variant={'outlined'}
              />
            </Grid>
          )}

          {/* <Grid item className={classes.fieldWrapper}>
          <Field
            name={'password'}
            label={'Password'}
            component={TextInput}
            type={'password'}
            variant={'outlined'}
          />
        </Grid> */}

          {/* <Grid item className={classes.fieldWrapper}>
          <Field
            name={'address1'}
            label={'Address 1'}
            component={TextInput}
            type={'text'}
            variant={'outlined'}
          />
        </Grid> */}

          {/* <Grid item className={classes.fieldWrapper}>
          <Field
            name={'address2'}
            label={'Address 2'}
            component={TextInput}
            type={'text'}
            variant={'outlined'}
          />
        </Grid> */}

          <Grid item className={classes.fieldWrapper}>
            <Field
              name={'city'}
              label={'* City'}
              component={TextInput}
              type={'text'}
              variant={'outlined'}
            />
          </Grid>

          <Field
            name={'state'}
            label={'* State'}
            component={SelectInput}
            type={'text'}
            variant={'outlined'}
          >
            {states.map((state) => (
              <MenuItem key={state.value} value={state.text}>
                {state.text}
              </MenuItem>
            ))}
          </Field>

          {/* <Grid item className={classes.fieldWrapper}>
          <Field
            name={'zipCode'}
            label={'Zip Code'}
            component={TextInput}
            type={'text'}
            variant={'outlined'}
          />
        </Grid> */}

          {/* <Grid item className={classes.fieldWrapper}>
          <Field
            name={'parentFirstName'}
            label={'Parent First Name'}
            component={TextInput}
            type={'text'}
            variant={'outlined'}
          />
        </Grid>

        <Grid item className={classes.fieldWrapper}>
          <Field
            name={'parentLastName'}
            label={'Parent Last Name'}
            component={TextInput}
            type={'text'}
            variant={'outlined'}
          />
        </Grid>

        <Grid item className={classes.fieldWrapper}>
          <Field
            name={'parentEmail'}
            label={'Parent Email Address'}
            component={TextInput}
            type={'text'}
            variant={'outlined'}
          />
        </Grid>

        <Grid item className={classes.fieldWrapper}>
          <Field
            name={'parentNumber'}
            label={'Parent Mobile Number'}
            component={TextInput}
            type={'text'}
            variant={'outlined'}
          />
        </Grid> */}

          {/* <Grid item className={classes.fieldWrapper}>
          <Field
            name={'counselorName'}
            label={'Counselor Name'}
            component={TextInput}
            type={'text'}
            variant={'outlined'}
          />
        </Grid>

        <Grid item className={classes.fieldWrapper}>
          <Field
            name={'counselorEmail'}
            label={'Counselor Email Address'}
            component={TextInput}
            type={'text'}
            variant={'outlined'}
          />
        </Grid> */}

          {/* <Grid item className={classes.fieldWrapper}>
          <Grid item className={classes.fieldWrapper}>
            <Field
              name={'graduationMonthYear'}
              label={'Graduation Month/Year'}
              component={TextInput}
              type={'text'}
              variant={'outlined'}
            />
          </Grid>
        </Grid> 

          <Grid item className={classes.fieldWrapper}>
            <Field
              name={'gradeLevel'}
              label={`Grade Level`}
              component={SelectInput}
              type={'text'}
              variant={'outlined'}
            >
              {grades.map((grade) => (
                <MenuItem key={grade.value} value={grade.value}>
                  {grade.value}
                </MenuItem>
              ))}
            </Field>
          </Grid>*/}
          {/* <Grid item className={classes.fieldWrapper}>
          <Field
            name={'recommendertype'}
            label={`recommender type`}
            component={SelectInput}
            type={'text'}
            variant={'outlined'}
          >
            <MenuItem value="academic">academic</MenuItem>
            <MenuItem value="counselor">counselor</MenuItem>
            <MenuItem value="personal">personal</MenuItem>
          </Field>
        </Grid> */}

          {/* New Fields Added Statrt */}
          <Grid item className={classes.fieldWrapper}>
            <Field
              name={'gradYear'}
              label={'* Expected Graduation'}
              component={MonthPickerInput}
              type={'text'}
              inputVariant={'outlined'}
              size={'small'}
              style={{ width: '100%' }}
            />
          </Grid>

          <Grid item className={classes.fieldWrapper}>
            <Field
              name={'highSchool'}
              label={'* High School'}
              component={TextInput}
              type={'text'}
              inputVariant={'outlined'}
              size={'small'}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item className={classes.fieldWrapper}>
            <Field
              name={'citizen'}
              label={'* Are you a U.S citizen or Permanent Resident'}
              component={SelectInput}
              type={'text'}
              variant={'outlined'}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Field>
          </Grid>
          <Grid item className={classes.fieldWrapper}>
            <Field
              name={'EmailTemplate'}
              label={'* Please Select Email Template'}
              component={SelectInput}
              type={'text'}
              variant={'outlined'}
            >
              {templates?.map((i, j) => (
                <MenuItem key={j} value={i.templateName}>
                  {i?.templateName}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item className={classes.fieldWrapper}>
            <Field
              name={'programInterested'}
              label={'* Interested Programs'}
              component={SelectInput}
              type={'text'}
              variant={'outlined'}
            >
              {/* {uniqueProgramCategories.map((i, j) => (
                <MenuItem key={j} value={i}>
                  {i}
                </MenuItem>
              ))} */}
              {allCategories?.map((i, j) => (
                <MenuItem key={j} value={i}>
                  {i?.categoryName}
                </MenuItem>
              ))}

              {/* {allPrograms &&
              allPrograms
                ?.filter((p) => p?.hideProgram === false)
                ?.map((pr, idx) => (
                  <MenuItem key={idx} value={pr?.programName}>
                    <div>
                      {new Date(pr.applicationDeadline) > new Date() &&
                      new Date(pr.applicationOpen) > new Date()
                        ? '(Upcoming)'
                        : new Date(pr.applicationDeadline) > new Date() &&
                          new Date(pr.applicationOpen) < new Date()
                        ? '(Current)'
                        : new Date(pr.applicationDeadline) < new Date() &&
                          '(Past)'}
                    </div>
                  </MenuItem>
                ))} */}
            </Field>
          </Grid>

          {/* New Fields Added End */}

          {/* <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          className={classes.fieldWrapper}
        >
          <Field
            name={'sendSmsConsent'}
            component={CheckboxInput}
            size="medium"
            color="primary"
            label="Check this box to opt in and consent to receive communication from LEAD via SMS, email or phone. Message & data rates may apply."
          />
        </Grid> */}

          {error && (
            <Grid item style={{ marginTop: '0.5em' }}>
              <Typography variant={'subtitle1'} className={classes.error}>
                {error}{' '}
                {error === 'Email already exists.' ? 'Please sign in.' : null}
              </Typography>
            </Grid>
          )}

          {/*BUTTONS*/}
          <Grid item style={{ marginTop: '1em' }}>
            <Grid item container justifyContent={'center'}>
              <Grid item>
                <Button
                  // variant={'outlined'}
                  className={classes.button}
                  style={{ backgroundColor: '#35BA35', color: '#fff' }}
                  type={'submit'}
                  //onClick={handleOpen}
                >
                  Request Information
                </Button>
                {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                >
                  Open Modal
                </Button> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          {/* Your existing content here */}
          <div style={{ padding: '40px', display: 'flex', marginTop: '20px' }}>
            {/*CONFIRMATION*/}
            <div
              style={{
                width: '75%',
                paddingRight: '35px',
                borderRight: '5px solid #ffc627',
              }}
            >
              {/* left side */}
              <h3 className={classes.formName}>
                Get ready for the next turn in your journey
              </h3>
              <h6 className={classes.formText}>
                Thank you for your interest in LEADing For Life
              </h6>

              <div className={classes.custombox}>
                <h2
                  style={{
                    color: 'black',
                    padding: '4px',
                    fontFamily: 'Roboto',
                  }}
                >
                  Want more info ?
                </h2>
                <p style={{ padding: '4px', margin: '3px', fontSize: '16px' }}>
                  Explore a selection of the programs available in our catalog.
                </p>
                <button
                  onClick={() => handleClose(history.push(`/program`))}
                  className={classes.button}
                >
                  Explore Programs
                </button>
              </div>
              <div>
                <h2 style={{ fontSize: '22px', fontFamily: 'Roboto' }}>
                  {' '}
                  Here's what to expect next:
                </h2>
                <ul style={{ marginTop: '4px' }}>
                  <li style={{ lineHeight: 2 }}>
                    Additional details about your program of interest.
                  </li>
                  <li style={{ lineHeight: 2 }}>
                    An email with additional information and next steps.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ padding: '40px' }}>
              {/* right side */}
              <h6 className={classes.rightTitle}>
                Get your questions answered
              </h6>

              <div>
                <h3 className={classes.rightText}>
                  {' '}
                  Have a question? Chat with us
                </h3>
                <Button
                  className={classes.button}
                  style={{
                    width: '130px',
                    borderRadius: '50.25rem',
                    fontSize: '12px',
                    marginTop: '10px',
                    //  backgroundColor: '#ffc627',
                  }}
                  onClick={() =>
                    handleClose(
                      (window.location = 'mailto:info@leadprogram.org')
                    )
                  }
                >
                  <EmailIcon style={{ paddingRight: '5px' }}></EmailIcon> Email
                  Us
                </Button>
              </div>

              <div style={{ paddingTop: '40px' }}>
                <h3 className={classes.rightText}>
                  {' '}
                  Ready to begin? Start now
                </h3>
                <Button
                  className={classes.button}
                  style={{
                    width: '130px',
                    borderRadius: '50.25rem',
                    fontSize: '12px',
                    marginTop: '10px',
                  }}
                  onClick={() => handleClose(history.push(`/program`))}
                >
                  <CreateIcon style={{ paddingRight: '5px' }}></CreateIcon>{' '}
                  Apply Now
                </Button>
              </div>
            </div>
          </div>

          {/* Add the rest of your content here */}
          {/* ... */}

          {/* Buttons to close the modal */}
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.button}
            style={{ float: 'right', width: 'fit-content' }}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps, {
  fetchAllTemplates,
  registerUser,
})(reduxForm({ form: 'registerForm', validate })(RegisterForm));
