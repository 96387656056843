import React from "react";
import { makeStyles } from "@mui/styles";

import { Grid, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "28px",
    lineHeight: "42px",
    letterSpacing: "0.12px",
    color: "#171725",
    textAlign: "center",
  },
  text: {
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "0.1px",
    color: "#696974",
    textAlign: "center",
  },
  image: {
    width: "130.25px",
    height: "119.48px",
  },
}));

const PercentageItem = ({ percentage, text, img, color }) => {
  const classes = useStyles();
  return (
    <Grid item lg={3} md={3} sm={6} xs={12}>
      <Grid
        item
        container
        direction={"column"}
        alignItems={"center"}
        style={{ padding: "100px" }}
      >
        <Grid item>
          <Typography className={classes.header}>{percentage}</Typography>
        </Grid>
        <Grid item>
          <Typography
            className={classes.text}
            style={{
              fontFamily: "Roboto",
              fontWeight: "400",
              fontSize: "18px",
              lineHeight: "28px",
              letterSpacing: "0.1px",
              color: "#696974",
              textAlign: "center",
            }}
          >
            {text}
          </Typography>
        </Grid>
        <Grid item>
          <img
            className={classes.image}
            style={{ width: "130.25px", height: "119.48px" }}
            src={img}
            alt=""
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PercentageItem;
