import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../../asyncActions/asyncActions";
import {
  FETCH_APPLICANTS_PROGRAM_COUNTDOWN,
  FETCH_APPLICANTS_RECENT_APPLICATION
} from "../../../constants/applicantConstants/dashboardConstants/applicantDashboardConstants";
import { closestTo, format, parseISO, startOfDay, differenceInDays } from "date-fns";

export const fetchApplicantRecentApplication = (id) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const recentApplicationQuery = firestore
      .collection("registeredPrograms")
      .where("applicantId", "==", `${id}`);

    const programQuery = firestore.collection("programs");

    const institutionsQuery = firestore.collection("institutions");

    try {
      dispatch(asyncActionStart());

      let query = await recentApplicationQuery.get();

      let applicantApplications = [];

      for (let i = 0; i < query.docs.length; i++) {
        let application = {
          ...query.docs[i].data(),
          id: query.docs[i].id,
        };
        applicantApplications.push(application);
      }

      let recentApplication = {};
      let applicationDetails = {};
      let programDetails = {};
      let institutionDetails = {};
      let institutionDetails2 = {};

      if (applicantApplications.length > 0) {
        let recentProgramId = applicantApplications.reduce((a, b) =>
          a.editiedAt > b.editiedAt ? a : b
        ).programId;
        let institutionId = applicantApplications.reduce((a, b) =>
          a.editiedAt > b.editiedAt ? a : b
        ).chosenInstitution;
        let institutionId2 = applicantApplications.reduce((a, b) =>
          a.editiedAt > b.editiedAt ? a : b
        )?.chosenInstitution2;
        applicationDetails = applicantApplications.reduce((a, b) =>
          a.editiedAt > b.editiedAt ? a : b
        );

        let progQuery = await programQuery.doc(`${recentProgramId}`).get();

        let institution = await institutionsQuery?.doc(`${institutionId}`)?.get();
        let institution2 = await institutionsQuery?.doc(`${institutionId2}`)?.get();

        if (progQuery.exists) {
          programDetails = {
            id: progQuery.id,
            ...progQuery.data(),
          };
          institutionDetails = {
            id: institution.id,
            ...institution.data(),
          };
          institutionDetails2 = {
            id: institution2.id,
            ...institution2.data(),
          };
        }

      }

      recentApplication = {
        applicationDetails: applicationDetails,
        programDetails: programDetails,
        institutionDetails: institutionDetails,
        institutionDetails2: institutionDetails2
      };

      dispatch({
        type: FETCH_APPLICANTS_RECENT_APPLICATION,
        payload: { recentApplication },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const fetchApplicantApplication = (id) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const recentApplicationQuery = firestore
      .collection("registeredPrograms")
      .doc(id);

    const programQuery = firestore.collection("programs");

    try {
      dispatch(asyncActionStart());

      let query = await recentApplicationQuery.get();
      const institutionQuery = firestore.collection("institutions");

      let applicationDetails = {};

      if (query.exists) {
        const chosenInstitutionId = query.data().chosenInstitution;
        let institution;
        if (chosenInstitutionId) {
          institution = await institutionQuery
            .doc(`${chosenInstitutionId}`)
            .get();
        }
        applicationDetails = {
          ...query.data(),
          institutionDetails: chosenInstitutionId && institution.data(),
          id: query.id,
        };
      }

      let programDetails = {};

      let progQuery = await programQuery.doc(`${applicationDetails.programId}`).get();

      if (progQuery.exists) {
        programDetails = {
          id: progQuery.id,
          ...progQuery.data(),
        };
      }

      let recentApplication = {
        applicationDetails: applicationDetails,
        programDetails: programDetails,
      };

      dispatch({
        type: FETCH_APPLICANTS_RECENT_APPLICATION,
        payload: { recentApplication },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateApplicantApplication = (id, key, value) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const recentApplicationQuery = firestore
      .collection("registeredPrograms")
      .doc(id);

    try {
      dispatch(asyncActionStart());

      await recentApplicationQuery.update({
        [key]: value,
      });

      dispatch(fetchApplicantApplication(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const fetchApplicantApplicationCountdown = (id) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const recentApplicationQuery = firestore
      .collection("registeredPrograms")
      .where("applicantId", "==", `${id}`).where('finalSubmissionCompleted', '==', true);

    const programsQuery = firestore.collection("programs");

    try {
      dispatch(asyncActionStart());

      let query = await recentApplicationQuery.get();

      let applicantPrograms = [];

      for (let i = 0; i < query.docs.length; i++) {
        let program = {
          programId: query.docs[i].data().programId

        };
        applicantPrograms.push(program);
      }

      const programs = []

      for (let i = 0; i < applicantPrograms.length; i++) {

        let programQuery = await programsQuery.doc(`${applicantPrograms[i].programId}`).get();

        if (programQuery.exists) {
          let program = {
            id: programQuery.id,
            ...programQuery.data(),
          };
          programs.push(program);
        }
      }


      const startDates = []

      for (let i = 0; i < programs.length; i++) {
        const date = startOfDay(new Date(programs[i].programStartDate))

        startDates.push(date);
      }

      const todaysDate = new Date()
      const closestDate = closestTo(todaysDate, startDates)


      const closestDateReadable = closestDate ? format(parseISO(closestDate.toISOString()), "MM/dd/yyyy") : null


      const closestProgram = programs.filter(array => array.programStartDateReadable === closestDateReadable)

      const numberOfDays = differenceInDays(closestDate, todaysDate)


      let applicationCountdown = {};

      applicationCountdown = {
        ...closestProgram[0],
        numberOfDays: numberOfDays ? numberOfDays : ''
      };

      dispatch({
        type: FETCH_APPLICANTS_PROGRAM_COUNTDOWN,
        payload: { applicationCountdown },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};