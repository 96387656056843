import React, { Fragment } from "react";
import { makeStyles } from "@mui/styles";

import Typography from "@mui/material/Typography";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
    fontWeight: 300,
  },
}));

const DatePickerInput = ({
  input: { onBlur, value, ...inputProps },
  meta: { submitting, touched, error },
  className,
  ...others
}) => {
  const classes = useStyles();

  const onChange = (date) => {
    Date.parse(date)
      ? inputProps.onChange(date.toISOString())
      : inputProps.onChange(null);
  };

  return (
    <Fragment>
      {console.log(value,"lop")}
      <DatePicker
        {...inputProps}
        format="MM/dd/yyyy"
        value={value ? new Date(value) : null}
        disabled={submitting}
        onBlur={() => onBlur(value ? new Date(value).toISOString() : null)}
        error={error && touched}
        onChange={onChange}
        autoOk={true}
        renderInput={(props) => (
          <TextField
            {...props}
            variant="outlined"
            size="small"
            className={className}
            fullWidth
          />
        )}
        {...others}
      />
      {touched && error && (
        <Typography variant={"subtitle1"} className={classes.error}>
          {error}
        </Typography>
      )}
    </Fragment>
  );
};

export default DatePickerInput;
