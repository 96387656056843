import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';
import DropzoneInput from '../../../../dropzone/DropzoneInput';
import { uploadTaxForm } from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  dropzoneWrapper: {
    padding: '3em',
    [theme.breakpoints.down('md')]: {
      padding: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0.4em',
      marginTop: '2em',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0.3em',
      marginTop: 0,
    },
  },
  dropzone: {
    marginTop: '0.5em',
    width: '350px',
    height: '150px',
  },
  button: {
    margin: '0.4em',
  },
  formText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  formQuestionText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
}));

const actions = {
  uploadTaxForm,
};

const TaxFormUpload = ({ registeredProgramId, uploadTaxForm }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [file, setFile] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const handleFileUpload = async () => {
    setDisabled(true);
    try {
      await uploadTaxForm(registeredProgramId, file);
      setFile(null);
      setDisabled(false);
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };
  const saveInterval = 5 * 60 * 1000;
  useEffect(() => {
    const saveFormInterval = setInterval(() => {
      handleFileUpload();
    }, saveInterval);
    return () => clearInterval(saveFormInterval);
  }, [file]);
  // console.log(file)

  return (
    <Grid item style={{ marginTop: '1.5em', marginBottom: '1.5em' }}>
      <Grid item container direction={'column'}>
        {!file && (
          <Fragment>
            <Grid item>
              <Typography className={classes.formText}>
                Drop or Add File Here - files must be .jpeg, png, or pdf
              </Typography>
            </Grid>
            <Grid item>
              <Grid item container>
                <Grid item className={classes.dropzone}>
                  <DropzoneInput setFiles={setFile} />
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        )}

        {file && (
          <Grid item>
            <Grid item container alignItems={'center'}>
              <Grid item>
                <Typography className={classes.formText}>
                  {file[0].name}
                </Typography>
              </Grid>
              <Grid item style={{ marginLeft: '1em' }}>
                <Button
                  className={classes.button}
                  color={'primary'}
                  variant={'contained'}
                  size={'small'}
                  onClick={() => handleFileUpload()}
                  disabled={disabled}
                >
                  Upload
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.button}
                  style={{
                    backgroundColor: theme.palette.error.main,
                    color: 'white',
                  }}
                  variant={'contained'}
                  size={'small'}
                  onClick={() => setFile(null)}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default connect(null, actions)(TaxFormUpload);
