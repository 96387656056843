import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Grid, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { connect } from 'react-redux';
import {
  deleteAdditionalDocument,
  submitAdditionalDocumentsForm,
} from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import AdditionalDocumentsUploadForm from './AdditionalDocumentsUploadForm';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  formName: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '72px',
    letter: '0.2px',
  },
  formText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  formQuestionText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  button: {
    marginLeft: '0.4em',
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
    },
  },

  continueButton: {
    marginLeft: '0.4em',
    backgroundColor: '#1976d2',
    color: 'white !Important',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
      //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    },
  },
}));

const actions = {
  deleteAdditionalDocument,
  submitAdditionalDocumentsForm,
};

const AdditionalDocumentsForm = ({
  registeredProgramId,
  registeredProgram,
  deleteAdditionalDocument,
  submitAdditionalDocumentsForm,
  handleCloseForm,
  handleSetForm,
  program,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [disabled, setDisabled] = useState(false);
  //const [error, setError] = useState("");
  const [exit, setExit] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (exit) handleCloseForm();
  }, [exit, handleCloseForm]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDeleteDocument = async (fileName) => {
    setDisabled(true);
    try {
      await deleteAdditionalDocument(registeredProgramId, fileName);
      setDisabled(false);
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };

  const programsAllThings = [
    'applicationInterests',
    'personalInformationForm',
    'familyInformationForm',
    'academicInformationForm',
    // 'recommendationsRequired',
    'recommendersForm',
    'extracurricularForm',
    'covidProtocolForm',
    'resumeForm',
    'transcriptForm',
    'shortResponsesForm',
    'scholarshipForm',
    'taxForm',
    'additionalDocumentsForm',
    'confirmationForm',
  ];

  const programAllThingsValue = {
    applicationInterests: 'applicationInterests',
    personalInformationForm: 'personalInformationForm',
    familyInformationForm: 'familyInformationForm',
    academicInformationForm: 'academicInformationForm',
    // recommendationsRequired: 'recommendationsRequired',
    recommendersForm: 'recommendersForm',
    extracurricularForm: 'extracurricularForm',
    covidProtocolForm: 'covidProtocolForm',
    resumeForm: 'resumeForm',
    transcriptForm: 'transcriptForm',
    shortResponsesForm: 'shortResponsesForm',
    scholarshipForm: 'scholarshipForm',
    taxForm: 'taxForm',
    additionalDocumentsForm: 'additionalDocumentsForm',
    confirmationForm: 'confirmationForm',
  };

  let dataToCheck = programsAllThings
    .filter((item) => program[item])
    .map((item) => programAllThingsValue[item]);
  console.log(registeredProgram?.additionalDocuments?.length, 'ram');
  const handleSubmitAdditionalDocumentForms = async () => {
    setDisabled(true);

    if (
      registeredProgram?.additionalDocuments?.length == undefined &&
      program.additionalDocumentsFormRequired
    ) {
      setDisabled(false);
      setError('File is required.');
      // setError(true);
    } else if (
      registeredProgram?.additionalDocuments?.length == 0 &&
      program.additionalDocumentsFormRequired
    ) {
      // alert("Please upload at least one document");
      setDisabled(false);
      setError('File is required.');
    } else {
      try {
        setError(false);
        await submitAdditionalDocumentsForm(registeredProgramId, true);
        handleCloseForm();
        const indexOfPersonalInfoForm = dataToCheck.indexOf(
          'additionalDocumentsForm'
        );
        if (
          indexOfPersonalInfoForm !== -1 &&
          indexOfPersonalInfoForm < dataToCheck.length - 1
        ) {
          const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
          handleSetForm(nextForm);
        }
        setDisabled(false);
      } catch (error) {
        console.log(error);
        setDisabled(false);
      }
    }
  };

  return (
    <Fragment>
      <Typography className={classes.formName}>Additional Documents</Typography>
      <Grid item container direction={'column'}>
        <Grid item>
          <Grid item container direction={'column'}>
            <Grid item>
              <Typography className={classes.formText}>
                Please ensure that any supplementary information or materials
                needed for your program are submitted alongside your
                application, unless otherwise specified by your academic
                program.
              </Typography>
            </Grid>
            {/*   <Grid item>
              <Typography
                className={classes.formText}
                style={{
                  fontWeight: '600',
                  marginTop: '32px',
                  color: 'darkred',
                }}
              >
                Morgan Stanley JumpStart applicants upload Intro Video here
              </Typography>
              </Grid>*/}

            <Grid item>
              <Typography
                className={classes.formText}
                style={{ paddingTop: '10px' }}
              >
                <span style={{ fontWeight: 'bold' }}>
                  Please make sure to press UPLOAD Button.
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/*ADDITIONAL DOCUMENTS UPLOAD*/}
        <AdditionalDocumentsUploadForm
          error={error}
          setError={setError}
          registeredProgramId={registeredProgramId}
        />
        {error && (
          <Typography variant="h6" style={{ color: 'red' }}>
            {error}
          </Typography>
        )}

        {/*CURRENT FORMS*/}

        <Grid
          item
          container
          direction={'column'}
          className={classes.fieldContainer}
          // alignItems={"center"}
        >
          <Grid item>
            <Typography className={classes.formQuestionText}>
              Uploaded Additional Document:
            </Typography>
          </Grid>
          <Grid item>
            <Grid item container direction={'column'}>
              {registeredProgram?.additionalDocuments?.map((item) => (
                <Grid item key={item.id}>
                  <Grid item container alignItems={'center'}>
                    <Grid item>
                      <Typography variant={'body1'}>
                        {item?.originalFileName}
                      </Typography>
                    </Grid>
                    <Grid item style={{ marginLeft: '1em' }}>
                      <IconButton
                        disabled={disabled}
                        aria-label="delete file"
                        onClick={() => handleDeleteDocument(item.fileName)}
                      >
                        <DeleteIcon
                          style={{ color: theme.palette.error.main }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {/*BUTTONS*/}
        <Grid item style={{ marginLeft: 'auto' }}>
          <Button
            variant={'outlined'}
            color={'primary'}
            className={classes.button}
            type={'submit'}
            disabled={disabled}
            onClick={() => setExit(true)}
          >
            Save for Later
          </Button>

          <Button
            variant={'outlined'}
            color={'primary'}
            className={classes.continueButton}
            type={'submit'}
            onClick={() => handleSubmitAdditionalDocumentForms()}
            disabled={disabled}
          >
            Continue{' '}
            <span>
              <strong> &#8594;</strong>
            </span>
          </Button>
          {program.additionalDocumentsFormRequired == false && (
            <Button
              variant={'outlined'}
              color={'primary'}
              className={classes.button}
              onClick={() => handleSubmitAdditionalDocumentForms()}
              disabled={disabled}
            >
              Skip
            </Button>
          )}

          <Button
            variant={'outlined'}
            color={'secondary'}
            className={classes.button}
            onClick={() => handleCloseForm()}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default connect(null, actions)(AdditionalDocumentsForm);
