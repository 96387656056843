import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Sidebar from "../../../../ui/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { fetchAllCategories } from "../../../../store/actions/adminActions/categoryActions/adminCategoryActions";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#171725",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight: "24px",
    letter: "0.1px",
  },
}));

const actions = {
  fetchAllCategories,
};

const mapStateToProps = (state) => {
  let categories = [];

  if (
    state.adminCategories.allCategories &&
    state.adminCategories.allCategories.length > 0
  ) {
    categories = state.adminCategories.allCategories;
  }

  return {
    categories,
  };
};

const AdminCategories = ({ fetchAllCategories, categories }) => {
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();

  useEffect(() => {
    fetchAllCategories();
  }, [fetchAllCategories]);

  return (
    <Grid container>
      <Grid item md={2}>
        <Sidebar />
      </Grid>

      <Grid
        item
        md={10}
        style={{
          backgroundColor: "#FAFAFB",
          paddingLeft: "41px",
          paddingRight: "41px",
        }}
      >
        <Grid item container direction={"column"}>
          <Grid item style={{ marginTop: "20px" }}>
            <Typography className={classes.title}>Categories</Typography>
          </Grid>

          <Grid item style={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/admin/settings/categories/new")}
            >
              Create Category
            </Button>
          </Grid>

          <Grid item style={{ marginTop: "2em" }}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Category Name</TableCell>
                    {/*<TableCell># of Programs</TableCell>*/}
                    <TableCell>Added By</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categories.map((category) => (
                    <TableRow key={category.id}>
                      <TableCell component="th" scope="row">
                        {category.categoryName}
                      </TableCell>
                      {/*<TableCell component="th" scope="row">*/}
                      {/*  {category.institutionPrograms ? category.institutionPrograms.length : 0}*/}
                      {/*</TableCell>*/}
                      <TableCell>{category.addedByName}</TableCell>
                      <TableCell>
                        <Button
                          variant={"contained"}
                          color={"primary"}
                          size={"small"}
                          onClick={() =>
                            history.push(
                              `/admin/settings/categories/view?id=${category.id}`
                            )
                          }
                        >
                          View
                        </Button>
                        <Button
                          style={{
                            marginLeft: "0.5em",
                            backgroundColor: theme.palette.info.main,
                            color: "white",
                          }}
                          variant={"contained"}
                          size={"small"}
                          onClick={() =>
                            history.push(
                              `/admin/settings/categories/edit?id=${category.id}`
                            )
                          }
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(AdminCategories);
