import React, { useEffect, Fragment, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Grid, IconButton, Box, LinearProgress } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import {
  fetchRegisteredProgram,
  fetchApplicantProgram,
} from '../store/actions/applicantActions/programActions/applicantProgramActions';
import { submitDeclineComment } from '../store/actions/applicantActions/programActions/applicantApplicationActions';
import {
  fetchAllInstitutions,
  fetchInstitution,
} from '../store/actions/adminActions/institutionActions/adminInstitutionActions';
import ApplicationFee from '../ui/programs/editProgram/forms/application/ApplicationFee';
import ScholarshipForm from '../ui/programs/editProgram/forms/scholarship/ScholarshipForm';
import TaxForm from '../ui/programs/editProgram/forms/taxForms/TaxForm';
import PersonalInformationForm from '../ui/programs/editProgram/forms/personalInformation/PersonalInformationForm';

import FamilyInformationForm from '../ui/programs/editProgram/forms/familyInformation/FamilyInformationForm';
import AcademicInformationForm from '../ui/programs/editProgram/forms/academicInformation/AcademicInformationForm';
import ExtracurricularEmploymentForm from '../ui/programs/editProgram/forms/extracurricularEmployment/ExtracurricularEmploymentForm';
import ResumeForm from '../ui/programs/editProgram/forms/resume/ResumeForm';
import TranscriptForm from '../ui/programs/editProgram/forms/transcript/TranscriptForm';
import AdditionalDocumentsForm from '../ui/programs/editProgram/forms/additionalDocuments/AdditionalDocumentsForm';
import ShortResponsesForm from '../ui/programs/editProgram/forms/shortResponses/ShortResponsesForm';
import RecommendersForm from '../ui/programs/editProgram/forms/recommenders/RecommendersForm';
import ConfirmationForm from '../ui/programs/editProgram/forms/confirmation/ConfirmationForm';
import Loading from '../ui/loading/Loading';
import InitialStages from '../ui/programs/editProgram/dashboard/InitialStages';
import ApprovedStages from '../ui/programs/editProgram/dashboard/ApprovedStages';
import GrantAcceptanceForm from '../ui/programs/editProgram/forms/approvedForms/grantAcceptanceForm/GrantAcceptanceForm';
import { format, parseISO } from 'date-fns';
import AcceptanceStage from '../ui/programs/editProgram/dashboard/AcceptanceStage';
import ScholarConsentForm from '../ui/programs/editProgram/forms/approvedForms/scholarConsentForm/ScholarConsentForm';
import GovernmentIdForm from '../ui/programs/editProgram/forms/approvedForms/governmentId/GovernmentIdForm';
import PaymentForm from '../ui/programs/editProgram/forms/approvedForms/paymentForm/PaymentForm';
import TravelInformationForm from '../ui/programs/editProgram/forms/approvedForms/travelInformationForm/TravelInformationForm';
import CovidProtocolForm from '../ui/programs/editProgram/forms/approvedForms/covidProtocolForm/CovidProtocolForm';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CommentsForm from '../ui/programs/editProgram/forms/application/CommentsForm';
import Button from '@mui/material/Button';
// import { fetchInstitution } from '../../../../store/actions/adminActions/institutionActions/adminInstitutionActions';

import {
  completeApplicationChecklist,
  completeApprovedChecklist,
} from '../store/actions/TEST-ACTIONS/TestActions';
import TextInputForm from '../ui/forms/TextInputForm';
import { reduxForm } from 'redux-form';
import { combineValidators, isRequired } from 'revalidate';
import { downloadApplication } from '../common/util';
import RequiredDocumentationForm from '../ui/programs/editProgram/forms/approvedForms/requiredDocumentationForm/RequiredDocumentationForm';
import StudentQuestionnaireForm from '../ui/programs/editProgram/forms/approvedForms/studentQuestionnaireForm/StudentQuestionnaireForm';
import {
  submitFinalApplication,
  updateProgramPacketDownloaded,
} from '../store/actions/applicantActions/programActions/applicantApprovedActions';
import EmailIcon from '@mui/icons-material/Email';
//import confirmationImg from '../assets/dashboard/books.jpg';
import confirmationImg from '../assets/dashboard/swirl1.png';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HelpIcon from '@mui/icons-material/Help';
import { Email } from '@mui/icons-material';
import headerBanner from '../assets/landingPage/LFL2.jpg';

const useStyles = makeStyles((theme) => ({
  headlineContainer: {
    position: 'relative',
    paddingTop: '116px',
    paddingBottom: '116px',
    left: '-2px',
    height: '350px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: 'url(' + headerBanner + ')',
    background: 'linear-gradient(195.34deg, #65A65A 0%, #3178A5 115.17%)',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the color and opacity as needed
    //backgroundColor: 'rgba(204, 204, 204, 0.5)'
  },
  headlineText: {
    color: '#fff',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '40px',
    lineHeight: '72px',
    letter: '0.2px',
    // paddingTop: '116px',
    textAlign: 'center',
    padding: '116px 30px',
    // paddingBottom: '116px',
    // position: "absolute",
    // top: "50%",

    //  transform: "translateY(-50%)",
  },
  formName: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '72px',
    letter: '0.2px',
  },

  detailsContainer: {
    paddingTop: '25px',
    // paddingBottom: '461px',
    paddingLeft: '290px',
    paddingRight: '290px',
  },

  iconButton: {
    color: '#FF9900',
    border: '1px solid #FF9900',
  },

  container: {
    paddingLeft: '2em',
    paddingRight: '2em',
  },
  button: {
    backgroundColor: '#35BA35',
    color: '#ffffff',
    outline: 'transparent',
    fontWeight: '600',
    width: '260px',
    fontSize: '1rem',
    borderRadius: '10px',
    letterSpacing: '2px',
    '&:disabled': {
      backgroundColor: '#e5e5e5' || '#35BA35',
    },
    '&:hover': {
      backgroundColor: '#469f4b' || '#35BA35',
      transform: 'scale(0.98)',
      /* Scaling button to 0.98 to its original size */
      boxShadow: '3px 2px 22px 1px rgba(0, 0, 0, 0.24)',
    },
  },
  confirmationLeft: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: 'url(' + confirmationImg + ')',
    background: 'linear-gradient(195.34deg, #65A65A 0%, #3178A5 115.17%)',
    borderRight: '5px solid #35BA35',
    color: '#ffffff',
    webkitFilter: 'grayscale(100%)',
  },
  confirmationRight: {
    textAlign: 'center',
    padding: '40px 20px',
    color: '#000000',
  },
  confirmationMessage: {
    border: '3px solid #000000',
    // backgroundColor: '#000000',
    color: '#000000',
    padding: '20px',
  },
  confMessageHighlight: {
    backgroundColor: '#35BA35',
    width: 'fit-content',
    color: '#00000',
    padding: '2px',
    fontWeight: '600',
  },
  nextMessage: {
    marginTop: '20px',
    color: '#000',
  },
  rightTitle: {
    marginBottom: '55px',
    fontSize: '16px',
    fontWeight: '600',
  },
  rightText: {
    fontWeight: '600',
  },
  rightGroup: {
    marginBottom: '45px',
  },
  statusMessage: {
    backgroundColor: '#35BA35',
    color: 'black',
    margin: '10px 0',
    width: 'fit-content',
    padding: '0 5px',
    fontWeight: '600',
  },
  fieldWrapper: {
    paddingTop: '0.3em',
    paddingBottom: '0.3em',
  },
}));

const actions = {
  fetchApplicantProgram,
  fetchRegisteredProgram,
  completeApplicationChecklist,
  completeApprovedChecklist,
  submitDeclineComment,
  fetchAllInstitutions,
  submitFinalApplication,
  updateProgramPacketDownloaded,
  fetchInstitution,
};

const mapStateToProps = (state) => {
  let program = {};
  console.log(state, 'hgfd');
  let registeredProgram = [];
  let institution = [];

  // console.log(state, 'state');
  if (
    state.applicantPrograms.program &&
    state.applicantPrograms.program.length > 0
  ) {
    program = state.applicantPrograms.program[0];
  }
  if (
    state.applicantPrograms.registeredProgram &&
    state.applicantPrograms.registeredProgram.length > 0
  ) {
    registeredProgram = state.applicantPrograms.registeredProgram[0];
  }
  if (
    state.adminInstitutions.institution &&
    state.adminInstitutions.institution.length > 0
  ) {
    institution = state.adminInstitutions.institution[0];
    // programDetails = state.adminInstitutions.institution[0].programDetails;
  }
  // console.log(fetchApplicantProgram, 'fetchApplicantProgram');
  return {
    program,
    institution,
    registeredProgram,
    profile: state.firebase.profile,
    initialValues: {
      declineComment: registeredProgram?.declineComment,
    },
    processingFee: state.applicationSettings.processingFee,
    // loading: state.loading.loading,
  };
};

const validate = combineValidators({
  declineComment: isRequired({ message: 'Comment is required' }),
});

const EditProgram = ({
  fetchRegisteredProgram,
  registeredProgram,
  completeApplicationChecklist,
  completeApprovedChecklist,
  program,
  profile,
  fetchInstitution,
  loading,
  handleSubmit,
  institution,
  submitting,
  submitDeclineComment,
  processingFee,
  fetchAllInstitutions,
  submitFinalApplication,
  updateProgramPacketDownloaded,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  console.log(registeredProgram?.chosenInstitution2, 'registereds');
  console.log(program, 'wwwww');

  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get('id');
  const payment = queryParams.get('payment');

  const [editApplication, setEditApplication] = useState(false);

  const [scholarshipForm, setScholarshipForm] = useState(false);
  const [applicationInterests, setApplicationInterests] = useState(false);
  const [taxForm, setTaxForm] = useState(false);
  const [personalInformationForm, setPersonalInformationForm] = useState(false);
  const [familyInformationForm, setFamilyInformationForm] = useState(false);
  const [academicInformationForm, setAcademicInformationForm] = useState(false);
  const [extracurricularForm, setExtracurricularForm] = useState(false);
  const [resumeForm, setResumeForm] = useState(false);
  const [transcriptForm, setTranscriptForm] = useState(false);
  const [additionalDocumentsForm, setAdditionalDocumentsForm] = useState(false);
  const [shortResponsesForm, setShortResponsesForm] = useState(false);
  const [recommendersForm, setRecommendersForm] = useState(false);
  const [confirmationForm, setConfirmationForm] = useState(false);
  const [grantAcceptanceForm, setGrantAcceptanceForm] = useState(false);
  const [scholarConsentForm, setScholarConsentForm] = useState(false);
  const [governmentIdForm, setGovernmentIdForm] = useState(false);
  const [paymentForm, setPaymentForm] = useState(false);
  const [travelForm, setTravelForm] = useState(false);
  const [covidProtocolForm, setCovidProtocolForm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showCheckList, setShowCheckList] = useState(true);
  const [reload, setReload] = useState(false);
  const [requiredDocumentationForm, setRequiredDocumentationForm] =
    useState(false);
  const [studentQuestionnaireForm, setStudentQuestionnaireForm] =
    useState(false);
  const [isDeadlinePassed, setIsDeadlinePassed] = useState(false);

  useEffect(() => {
    fetchRegisteredProgram(id);
  }, [fetchRegisteredProgram, id, reload]);
  useEffect(() => {
    fetchAllInstitutions();
  }, [fetchAllInstitutions, reload]);
  useEffect(() => {
    fetchInstitution(registeredProgram?.chosenInstitution2);
  }, [registeredProgram?.chosenInstitution2]);

  useEffect(() => {
    if (Object.keys(program).length && payment === 'true') {
      setShowCheckList(false);
      setPaymentForm(true);
    } else {
      setShowCheckList(true);
      setPaymentForm(false);
    }
  }, [payment, program]);
  var dateObject = new Date();
  // Extract year, month, and day components
  var year = dateObject.getFullYear();
  var month = dateObject.getMonth() + 1; // Month is zero-based, so add 1

  var day = dateObject.getDate();
  // Format the date components in the "MM/DD/YYYY" format
  var formattedDate =
    (month < 10 ? '0' : '') +
    month +
    '/' +
    (day < 10 ? '0' : '') +
    day +
    '/' +
    year;
  // console.log(program?.programEndDateReadable > formattedDate, 'compare');
  useEffect(() => {
    /*if (
      !program.programClosed &&
      !loading &&
      registeredProgram.applicationFeePaid &&
      registeredProgram.applicationCommentsSet &&
      !registeredProgram.confirmationFormCompleted &&
      !registeredProgram.applicationApproved &&
      registeredProgram.programStage !== "waitlisted"
    ) {
      if (
        new Date(program?.applicationDeadline) >
        new Date(program?.paymentDeadline)
      ) {
        setIsDeadlinePassed(true);
      } else {
        setIsDeadlinePassed(false);
      }
    } */
    if (
      (!program.programClosed &&
        !loading &&
        //registeredProgram.applicationFeePaid &&
        //  registeredProgram.applicationCommentsSet &&
        //!registeredProgram.confirmationFormCompleted &&
        // !registeredProgram.applicationApproved &&
        registeredProgram.programStage === 'in-progress') ||
      registeredProgram.programStage === 'pre-application'
    ) {
      if (
        new Date() > new Date(program?.applicationDeadline)

        // program?.programEndDateReadable < formattedDate
      ) {
        setIsDeadlinePassed(true);
      } else {
        setIsDeadlinePassed(false);
      }
    } else if (
      !program.programClosed &&
      !loading &&
      showCheckList &&
      //  registeredProgram.applicationFeePaid &&
      // registeredProgram.applicationCommentsSet &&
      program?.paymentDeadline != null &&
      registeredProgram.applicationApproved &&
      (registeredProgram.programStage === 'admitted' ||
        registeredProgram.programStage === 'complete')
    ) {
      if (
        new Date().setHours(0, 0, 0, 0) >=
        new Date(program?.paymentGrace || program?.paymentDeadline).setHours(
          0,
          0,
          0,
          0
        )
        // i wrote this condition if we have to add this for completed or admitted also || program?.programEndDateReadable < formattedDate
      ) {
        setIsDeadlinePassed(true);
      } else {
        setIsDeadlinePassed(false);
      }
    } else {
      setIsDeadlinePassed(false);
    }
  }, [registeredProgram, loading, program, showCheckList]);

  /*useEffect(
    ()=>{
      if(
        !program.programClosed &&
        !loading &&
        registeredProgram.applicationFeePaid &&
        registeredProgram.applicationCommentsSet &&
        !registeredProgram.confirmationFormCompleted &&
        !registeredProgram.applicationApproved &&
        registeredProgram.programStage === "in-progress" &&
        registeredProgram.programStage === "preapplication"
      ) {
        if (new Date() > new Date(program.applicationDeadlineDate)) {
          setIsDeadlinePassed(true);
        }
      }
  
    },[registeredProgram, loading, program, showCheckList]
  )
  */

  const handleSubmitApplication = async () => {
    await submitFinalApplication(id, registeredProgram, program);
    history.push('/applicant/dashboard');
  };

  const applicationStagesLeft = [
    {
      id: 0,
      applicationStage: 'Application Fee',
      formName: 'applicationFee',
      databaseName: registeredProgram?.applicationFeePaid,
    },
    {
      id: 2,
      applicationStage: 'Scholarship Form',
      formName: 'scholarshipForm',
      databaseName: registeredProgram?.scholarshipFormCompleted,
    },
    {
      id: 3,
      applicationStage: `Tax Form's Uploaded`,
      formName: 'taxForm',
      databaseName: registeredProgram?.taxFormsUploaded,
    },
    {
      id: 4,
      applicationStage: `Personal Information Form`,
      formName: 'personalInformationForm',
      databaseName: registeredProgram?.personalInformationFormCompleted,
    },
    {
      id: 5,
      applicationStage: `Family Information Form`,
      formName: 'familyInformationForm',
      databaseName: registeredProgram?.familyInformationFormCompleted,
    },
    {
      id: 6,
      applicationStage: `Academic Information`,
      formName: 'academicInformationForm',
      databaseName: registeredProgram?.academicInformationFormCompleted,
    },
    {
      id: 7,
      applicationStage: `Extracurricular, Community Activities and Employment Information`,
      formName: 'extracurricularForm',
      databaseName: registeredProgram?.extracurricularFormCompleted,
    },
  ];

  const applicationStagesRight = [
    {
      id: 8,
      applicationStage: `Resume`,
      formName: 'resumeForm',
      databaseName: registeredProgram?.resumeFormCompleted,
    },
    {
      id: 9,
      applicationStage: `Transcript`,
      formName: 'transcriptForm',
      databaseName: registeredProgram?.transcriptFormCompleted,
    },
    {
      id: 11,
      applicationStage: `Short Responses and Essay`,
      formName: 'shortResponsesForm',
      databaseName: registeredProgram?.shortResponsesFormCompleted,
    },
    {
      id: 10,
      applicationStage: `Additional Documents`,
      formName: 'additionalDocumentsForm',
      databaseName: registeredProgram?.additionalDocumentsFormCompleted,
    },
    {
      id: 12,
      applicationStage: `Recommendations`,
      formName: 'recommendersForm',
      databaseName: registeredProgram?.recommendersFormCompleted,
      fieldData:
        registeredProgram?.counselorRecommenderDetails ||
        registeredProgram?.personalRecommenderDetails ||
        registeredProgram?.academicRecommenderDetails,
    },
  ];

  const applicationConfirmation = [
    {
      id: 13,
      applicationStage: `Confirmation`,
      formName: 'confirmationForm',
      databaseName: registeredProgram?.confirmationFormCompleted,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram.programDetails?.confirmationForm || false
        : true,
    },
  ];

  const applicationStages = [
    {
      id: 0,
      applicationStage: 'Application Fee',
      formName: 'applicationFee',
      databaseName: registeredProgram?.applicationFeePaid,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.applicationFeeSection || false
        : true,
    },
    {
      id: 1,
      applicationStage: 'Educational Interest',
      formName: 'applicationInterests',
      databaseName: registeredProgram?.applicationCommentsSet,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.applicationInterests || false
        : true,
    },

    // {
    //   id: 2,
    //   applicationStage: 'Scholarship Form',
    //   formName: 'scholarshipForm',
    //   databaseName: registeredProgram?.scholarshipFormCompleted,
    //   show: registeredProgram?.programDetails?.customChecklist
    //     ? registeredProgram?.programDetails?.scholarshipForm || false
    //     : true,
    // },
    // {
    //   id: 3,
    //   applicationStage: `Tax Form's Uploaded`,
    //   formName: 'taxForm',
    //   databaseName: registeredProgram?.taxFormsUploaded,
    //   fieldData: registeredProgram?.taxForms,
    //   show: registeredProgram?.programDetails?.customChecklist
    //     ? registeredProgram?.programDetails?.taxForm || false
    //     : true,
    // },
    {
      id: 4,
      applicationStage: `Personal Information Form`,
      formName: 'personalInformationForm',
      databaseName: registeredProgram?.personalInformationFormCompleted,
      fieldData: registeredProgram?.personalInformationFormDetails,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.personalInformationForm || false
        : true,
    },
    {
      id: 5,
      applicationStage: `Family Information Form`,
      formName: 'familyInformationForm',
      databaseName: registeredProgram?.familyInformationFormCompleted,
      fieldData: registeredProgram?.familyInformationFormDetails,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.familyInformationForm || false
        : true,
    },
    {
      id: 6,
      applicationStage: `Academic Information`,
      formName: 'academicInformationForm',
      databaseName: registeredProgram?.academicInformationFormCompleted,
      fieldData: registeredProgram?.academicInformationFormDetails,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.academicInformationForm || false
        : true,
    },
    // add this test just for testing purpose remove it later
    // {
    //   id: 14,
    //   applicationStage: 'Application Interests',
    //   formName: 'applicationComments',
    //   databaseName: registeredProgram.applicationInterests,
    //   show: registeredProgram?.programDetails?.customChecklist
    //     ? registeredProgram?.programDetails?.applicationInterests || false
    //     : true,
    // },
    {
      id: 12,
      applicationStage: `Recommendations`,
      formName: 'recommendersForm',
      databaseName: registeredProgram.recommendersFormCompleted,
      fieldData:
        registeredProgram?.counselorRecommenderDetails ||
        registeredProgram?.personalRecommenderDetails ||
        registeredProgram?.academicRecommenderDetails,
      // show: registeredProgram?.programDetails?.recommendersForm || false,
      show:
        registeredProgram?.programDetails?.recommendationsRequired > 0 ||
          registeredProgram?.programDetails?.recommendersForm
          ? true
          : false,
    },
    {
      id: 7,
      applicationStage: `Extracurricular, Community Activities and Employment Information`,
      formName: 'extracurricularForm',
      databaseName: registeredProgram?.extracurricularFormCompleted,
      fieldData: registeredProgram?.extracurricularFormDetails,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.extracurricularForm || false
        : true,
    },
    {
      id: 14,
      applicationStage: `Covid Protocol`,
      formName: 'covidProtocolForm',
      databaseName: registeredProgram?.covidProtocolFormCompleted,
      fieldData: registeredProgram?.covidProtocolFormDetails,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.covidProtocolForm || false
        : true,
    },
    {
      id: 8,
      applicationStage: `Resume`,
      formName: 'resumeForm',
      databaseName: registeredProgram?.resumeFormCompleted,
      fieldData: registeredProgram?.resume,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.resumeForm || false
        : true,
    },
    {
      id: 9,
      applicationStage: `Transcript and Standardized Test Score`,
      formName: 'transcriptForm',
      databaseName: registeredProgram?.transcriptFormCompleted,
      fieldData: registeredProgram?.transcripts,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.transcriptForm || false
        : true,
    },
    {
      id: 11,
      applicationStage: `Short Responses and Essay`,
      formName: 'shortResponsesForm',
      databaseName: registeredProgram?.shortResponsesFormCompleted,
      fieldData: registeredProgram?.shortResponsesFormDetails,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.shortResponsesForm || false
        : true,
    },
    {
      id: 2,
      applicationStage: 'Scholarship Form',
      formName: 'scholarshipForm',
      fieldData: registeredProgram?.scholarshipFormDetails,
      databaseName: registeredProgram?.scholarshipFormCompleted,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.scholarshipForm || false
        : true,
    },
    {
      id: 3,
      applicationStage: `Tax Form's Uploaded`,
      formName: 'taxForm',
      databaseName: registeredProgram?.taxFormsUploaded,
      fieldData: registeredProgram?.taxForms,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.taxForm || false
        : true,
    },
    {
      id: 10,
      applicationStage: `Additional Documents`,
      formName: 'additionalDocumentsForm',
      databaseName: registeredProgram?.additionalDocumentsFormCompleted,
      fieldData: registeredProgram?.additionalDocuments,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.additionalDocumentsForm || false
        : true,
    },
    /*   {
      id: 13,
      applicationStage: `Confirmation`,
      formName: 'confirmationForm',
      databaseName: registeredProgram.confirmationFormCompleted,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram.programDetails?.confirmationForm || false
        : true,
    },*/
  ];

  const approvedStages = [
    {
      id: 1,
      applicationStage: 'LEAD Acceptance Forms',
      formName: 'scholarConsentForm',
      databaseName: registeredProgram.scholarConsentFormCompleted,
      fieldData: registeredProgram.scholarConsentFormDetails,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.scholarConsentForm || false
        : true,
    },
    {
      id: 2,
      applicationStage: 'Government ID & Headshot',
      formName: 'governmentIdForm',
      databaseName: registeredProgram.governmentIdFormUploaded,
      fieldData: registeredProgram.governmentIds,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.governmentIdForm || false
        : true,
    },
    {
      id: 3,
      applicationStage: 'Payment',
      formName: 'paymentForm',
      databaseName:
        registeredProgram.payment1Received &&
        registeredProgram.payment2Received,
      fieldData:
        registeredProgram.payment1Received ||
        registeredProgram.payment2Received,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.paymentForm || false
        : true,
    },
    {
      id: 6,
      applicationStage: 'Download Program Packet',
      formName: 'downloadProgramPacketForm',
      databaseName: registeredProgram.programPacketDownloaded,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.downloadProgramPacketForm || false
        : true,
    },
    {
      id: 4,
      applicationStage: 'Required Pre-Arrival Forms',
      formName: 'requiredDocumentationForm',
      databaseName: registeredProgram.requiredDocumentationFormUploaded,
      fieldData: registeredProgram.requiredDocuments,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.requiredDocumentationForm || false
        : true,
    },
    {
      id: 5,
      applicationStage: 'Student Questionnaire',
      formName: 'studentQuestionnaireForm',
      databaseName: registeredProgram.studentQuestionnaireFormCompleted,
      fieldData: registeredProgram.studentQuestionnaireFormDetails,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.studentQuestionnaireForm || false
        : true,
    },
  ];

  const approvedStagesTravel = [
    // {
    //   id: 0,
    //   applicationStage: "LEAD Acceptance Forms",
    //   formName: "grantAcceptanceForm",
    //   databaseName: registeredProgram.grantAcceptanceFormCompleted,
    //   fieldData: registeredProgram.grantAcceptanceFormDetails,
    // },
    {
      id: 1,
      applicationStage: 'LEAD Acceptance Forms',
      formName: 'scholarConsentForm',
      databaseName: registeredProgram.scholarConsentFormCompleted,
      fieldData: registeredProgram.scholarConsentFormDetails,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.scholarConsentForm || false
        : true,
    },
    {
      id: 2,
      applicationStage: 'Government ID & Headshot',
      formName: 'governmentIdForm',
      databaseName: registeredProgram.governmentIdFormUploaded,
      fieldData: registeredProgram.governmentIds,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.governmentIdForm || false
        : true,
    },
    {
      id: 3,
      applicationStage: 'Payment',
      formName: 'paymentForm',
      databaseName:
        registeredProgram.payment1Received &&
        registeredProgram.payment2Received,
      fieldData:
        registeredProgram.payment1Received ||
        registeredProgram.payment2Received,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.paymentForm || false
        : true,
    },
    {
      id: 6,
      applicationStage: 'Travel Form',
      formName: 'travelInformationForm',
      databaseName: registeredProgram.travelInformationFormCompleted,
      fieldData: registeredProgram.travelInformation,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.travelInformationForm || false
        : true,
    },
    {
      id: 5,
      applicationStage: 'Student Questionnaire',
      formName: 'studentQuestionnaireForm',
      databaseName: registeredProgram.studentQuestionnaireFormCompleted,
      fieldData: registeredProgram.studentQuestionnaireFormDetails,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.studentQuestionnaireForm || false
        : true,
    },
    {
      id: 7,
      applicationStage: 'Download Program Packet',
      formName: 'downloadProgramPacketForm',
      databaseName: registeredProgram.programPacketDownloaded,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.downloadProgramPacketForm || false
        : true,
    },
    {
      id: 4,
      applicationStage: 'Required Pre-Arrival Forms',
      formName: 'requiredDocumentationForm',
      databaseName: registeredProgram.requiredDocumentationFormUploaded,
      fieldData: registeredProgram.requiredDocuments,
      show: registeredProgram?.programDetails?.customChecklist
        ? registeredProgram?.programDetails?.requiredDocumentationForm || false
        : true,
    },
  ];
  const percentage = applicationStages.every((stage) => stage.show == true)
    ? (approvedStages.filter((stage) => stage.databaseName).length /
      approvedStages.length) *
    100
    : (applicationStages.filter(
      (stage) => stage.databaseName && stage.show == true
    ).length /
      applicationStages.filter((stage) => stage.show == true).length) *
    100;

  /* console.log(
    applicationStages.filter(
      (stage) => stage.databaseName && stage.show == true
    )
  );
  console.log('total: ' + applicationStages.length);
  console.log(
    'total Custom: ' +
      applicationStages.filter((stage) => stage.show == true).length
  );
  console.log(
    'completed' + applicationStages.filter((stage) => stage.databaseName).length
  );*/
  const handleReload = () => {
    setReload((prev) => !prev);
  };

  const handleSetForm = (form) => {
    setShowForm(true);
    if (form === 'scholarshipForm') {
      setEditApplication(true);
      setScholarshipForm(true);
      setApplicationInterests(false);
      setTaxForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularForm(false);
      setResumeForm(false);
      setTranscriptForm(false);
      setAdditionalDocumentsForm(false);
      setShortResponsesForm(false);
      setRecommendersForm(false);
      setConfirmationForm(false);
      setCovidProtocolForm(false);
      setRequiredDocumentationForm(false);
    }
    if (form === 'applicationInterests') {
      setEditApplication(true);
      setScholarshipForm(false);
      setTaxForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularForm(false);
      setResumeForm(false);
      setTranscriptForm(false);
      setAdditionalDocumentsForm(false);
      setShortResponsesForm(false);
      setRecommendersForm(false);
      setConfirmationForm(false);
      setCovidProtocolForm(false);
      setApplicationInterests(true);
      setRequiredDocumentationForm(false);
    }
    if (form === 'taxForm') {
      setEditApplication(true);
      setScholarshipForm(false);
      setApplicationInterests(false);
      setCovidProtocolForm(false);
      setTaxForm(true);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularForm(false);
      setResumeForm(false);
      setTranscriptForm(false);
      setAdditionalDocumentsForm(false);
      setShortResponsesForm(false);
      setRecommendersForm(false);
      setConfirmationForm(false);
      setRequiredDocumentationForm(false);
    }
    if (form === 'personalInformationForm') {
      setEditApplication(true);
      setScholarshipForm(false);
      setApplicationInterests(false);
      setTaxForm(false);
      setCovidProtocolForm(false);
      setPersonalInformationForm(true);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularForm(false);
      setResumeForm(false);
      setTranscriptForm(false);
      setAdditionalDocumentsForm(false);
      setShortResponsesForm(false);
      setRecommendersForm(false);
      setConfirmationForm(false);
      setRequiredDocumentationForm(false);
    }
    if (form === 'familyInformationForm') {
      setEditApplication(true);
      setScholarshipForm(false);
      setApplicationInterests(false);
      setTaxForm(false);
      setPersonalInformationForm(false);
      setCovidProtocolForm(false);
      setFamilyInformationForm(true);
      setAcademicInformationForm(false);
      setExtracurricularForm(false);
      setResumeForm(false);
      setTranscriptForm(false);
      setAdditionalDocumentsForm(false);
      setShortResponsesForm(false);
      setRecommendersForm(false);
      setConfirmationForm(false);
      setRequiredDocumentationForm(false);
    }
    if (form === 'academicInformationForm') {
      setEditApplication(true);
      setScholarshipForm(false);
      setApplicationInterests(false);
      setTaxForm(false);
      setCovidProtocolForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(true);
      setExtracurricularForm(false);
      setResumeForm(false);
      setTranscriptForm(false);
      setAdditionalDocumentsForm(false);
      setShortResponsesForm(false);
      setRecommendersForm(false);
      setConfirmationForm(false);
      setRequiredDocumentationForm(false);
    }
    if (form === 'extracurricularForm') {
      setEditApplication(true);
      setScholarshipForm(false);
      setApplicationInterests(false);
      setCovidProtocolForm(false);
      setTaxForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularForm(true);
      setResumeForm(false);
      setTranscriptForm(false);
      setAdditionalDocumentsForm(false);
      setShortResponsesForm(false);
      setRecommendersForm(false);
      setConfirmationForm(false);
      setRequiredDocumentationForm(false);
    }
    if (form === 'covidProtocolForm') {
      setEditApplication(true);
      setScholarshipForm(false);
      setApplicationInterests(false);
      setTaxForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularForm(false);
      setResumeForm(false);
      setTranscriptForm(false);
      setAdditionalDocumentsForm(false);
      setShortResponsesForm(false);
      setRecommendersForm(false);
      setConfirmationForm(false);
      setCovidProtocolForm(true);
      setRequiredDocumentationForm(false);
    }
    if (form === 'resumeForm') {
      setEditApplication(true);
      setScholarshipForm(false);
      setApplicationInterests(false);
      setTaxForm(false);
      setCovidProtocolForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularForm(false);
      setResumeForm(true);
      setTranscriptForm(false);
      setAdditionalDocumentsForm(false);
      setShortResponsesForm(false);
      setRecommendersForm(false);
      setConfirmationForm(false);
      setRequiredDocumentationForm(false);
    }
    if (form === 'transcriptForm') {
      setEditApplication(true);
      setScholarshipForm(false);
      setApplicationInterests(false);
      setCovidProtocolForm(false);
      setTaxForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularForm(false);
      setResumeForm(false);
      setTranscriptForm(true);
      setAdditionalDocumentsForm(false);
      setShortResponsesForm(false);
      setRecommendersForm(false);
      setConfirmationForm(false);
      setRequiredDocumentationForm(false);
    }
    if (form === 'additionalDocumentsForm') {
      setEditApplication(true);
      setScholarshipForm(false);
      setApplicationInterests(false);
      setCovidProtocolForm(false);
      setTaxForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularForm(false);
      setResumeForm(false);
      setTranscriptForm(false);
      setAdditionalDocumentsForm(true);
      setShortResponsesForm(false);
      setRecommendersForm(false);
      setConfirmationForm(false);
      setRequiredDocumentationForm(false);
    }
    if (form === 'shortResponsesForm') {
      setEditApplication(true);
      setScholarshipForm(false);
      setApplicationInterests(false);
      setTaxForm(false);
      setCovidProtocolForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularForm(false);
      setResumeForm(false);
      setTranscriptForm(false);
      setAdditionalDocumentsForm(false);
      setShortResponsesForm(true);
      setRecommendersForm(false);
      setConfirmationForm(false);
      setRequiredDocumentationForm(false);
    }
    if (form === 'recommendersForm') {
      setEditApplication(true);
      setScholarshipForm(false);
      setApplicationInterests(false);
      setTaxForm(false);
      setCovidProtocolForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularForm(false);
      setResumeForm(false);
      setTranscriptForm(false);
      setAdditionalDocumentsForm(false);
      setShortResponsesForm(false);
      setRecommendersForm(true);
      setConfirmationForm(false);
      setRequiredDocumentationForm(false);
    }
    if (form === 'confirmationForm') {
      setEditApplication(true);
      setScholarshipForm(false);
      setApplicationInterests(false);
      setCovidProtocolForm(false);
      setTaxForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularForm(false);
      setResumeForm(false);
      setTranscriptForm(false);
      setAdditionalDocumentsForm(false);
      setShortResponsesForm(false);
      setRecommendersForm(false);
      setConfirmationForm(true);
      setRequiredDocumentationForm(false);
    }

    // APPROVED
    if (form === 'grantAcceptanceForm') {
      setEditApplication(true);
      setScholarshipForm(false);
      setApplicationInterests(false);
      setTaxForm(false);
      setCovidProtocolForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularForm(false);
      setResumeForm(false);
      setTranscriptForm(false);
      setAdditionalDocumentsForm(false);
      setShortResponsesForm(false);
      setRecommendersForm(false);
      setConfirmationForm(false);
      setGrantAcceptanceForm(true);
      setRequiredDocumentationForm(false);
    }
    if (form === 'scholarConsentForm') {
      setEditApplication(true);
      setScholarshipForm(false);
      setApplicationInterests(false);
      setCovidProtocolForm(false);
      setTaxForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularForm(false);
      setResumeForm(false);
      setTranscriptForm(false);
      setAdditionalDocumentsForm(false);
      setShortResponsesForm(false);
      setRecommendersForm(false);
      setConfirmationForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(true);
      setRequiredDocumentationForm(false);
    }
    if (form === 'governmentIdForm') {
      setEditApplication(true);
      setScholarshipForm(false);
      setApplicationInterests(false);
      setTaxForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularForm(false);
      setResumeForm(false);
      setTranscriptForm(false);
      setAdditionalDocumentsForm(false);
      setShortResponsesForm(false);
      setRecommendersForm(false);
      setConfirmationForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentIdForm(true);
      setCovidProtocolForm(false);
      setRequiredDocumentationForm(false);
    }
    if (form === 'paymentForm') {
      setEditApplication(true);
      setScholarshipForm(false);
      setApplicationInterests(false);

      setTaxForm(false);
      setCovidProtocolForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularForm(false);
      setResumeForm(false);
      setTranscriptForm(false);
      setAdditionalDocumentsForm(false);
      setShortResponsesForm(false);
      setRecommendersForm(false);
      setConfirmationForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentIdForm(false);
      setPaymentForm(true);
      setRequiredDocumentationForm(false);
    }
    if (form === 'travelInformationForm') {
      setEditApplication(true);
      setScholarshipForm(false);
      setApplicationInterests(false);
      setCovidProtocolForm(false);
      setTaxForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularForm(false);
      setResumeForm(false);
      setTranscriptForm(false);
      setAdditionalDocumentsForm(false);
      setShortResponsesForm(false);
      setRecommendersForm(false);
      setConfirmationForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentIdForm(false);
      setPaymentForm(false);
      setTravelForm(true);
      setRequiredDocumentationForm(false);
    }
    if (form === 'requiredDocumentationForm') {
      setEditApplication(true);
      setScholarshipForm(false);
      setApplicationInterests(false);
      setCovidProtocolForm(false);
      setTaxForm(false);
      setPersonalInformationForm(false);
      setFamilyInformationForm(false);
      setAcademicInformationForm(false);
      setExtracurricularForm(false);
      setResumeForm(false);
      setTranscriptForm(false);
      setAdditionalDocumentsForm(false);
      setShortResponsesForm(false);
      setRecommendersForm(false);
      setConfirmationForm(false);
      setGrantAcceptanceForm(false);
      setScholarConsentForm(false);
      setGovernmentIdForm(false);
      setPaymentForm(false);
      setTravelForm(false);
      setRequiredDocumentationForm(true);
    }
    if (form === 'studentQuestionnaireForm') {
      setEditApplication(true);
      setStudentQuestionnaireForm(true);
      setGovernmentIdForm(false);
    }
    if (form === 'downloadProgramPacketForm') {
      if (registeredProgram.instituteDetails?.programPacketURL)
        window.open(
          registeredProgram.instituteDetails?.programPacketURL,
          '_blank'
        );
      updateProgramPacketDownloaded(id);
    }
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setEditApplication(false);
    setScholarshipForm(false);
    setApplicationInterests(false);
    setTaxForm(false);
    setPersonalInformationForm(false);
    setFamilyInformationForm(false);
    setAcademicInformationForm(false);
    setExtracurricularForm(false);
    setResumeForm(false);
    setTranscriptForm(false);
    setAdditionalDocumentsForm(false);
    setShortResponsesForm(false);
    setRecommendersForm(false);
    setConfirmationForm(false);
    setGrantAcceptanceForm(false);
    setScholarConsentForm(false);
    setGovernmentIdForm(false);
    setPaymentForm(false);
    setTravelForm(false);
    setCovidProtocolForm(false);
    setRequiredDocumentationForm(false);
    setStudentQuestionnaireForm(false);
  };

  const handleCompleteApplicationCheckList = () => {
    completeApplicationChecklist(id, profile);
  };

  const handleCompleteApprovedCheckList = () => {
    completeApprovedChecklist(id, profile);
  };

  return (
    <Grid container direction={'column'} style={{ backgroundColor: '#E7E7E7' }}>
      <Grid item className={classes.headlineContainer}>
        <Grid className={classes.overlay}>
          <Grid
            container
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Grid item>
              <Typography className={classes.headlineText}>
                {program.programName}
                <br></br>
                {
                  //registeredProgram.applicationFeePaid &&
                  //registeredProgram.applicationCommentsSet &&
                  (registeredProgram.applicationApproved &&
                    registeredProgram.programStage === 'admitted') ||
                    registeredProgram.programStage === 'complete'
                    ? 'Acceptance Checklist'
                    : 'Application Checklist'
                }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item className={classes.detailsContainer}>
        {/* ICON */}
        <Grid container direction={'column'} style={{ marginBottom: '1rem' }}>
          <Grid item>
            <IconButton
              aria-label="delete"
              className={classes.iconButton}
              size={'small'}
              // onClick={() => history.push(`/applicant/dashboard`)}
              onClick={() =>
                editApplication ? handleCloseForm() : history.goBack()
              }
            >
              <ArrowBackIcon className={classes.icon} />
            </IconButton>
          </Grid>
        </Grid>
        {isDeadlinePassed && (
          <Grid item container justifyContent="center">
            <Typography variant="h6" style={{ color: 'red' }}>
              Note: Deadline has passed
            </Typography>
          </Grid>
        )}
        <Grid
          style={{
            padding: '2rem',
            background: 'white',
            borderRadius: '20px',
            boxShadow: '5px 5px 5px lightgrey',
          }}
          item
        >
          {loading && <Loading />}

          {!loading && !registeredProgram.applicationFeePaid && (
            <ApplicationFee
              registeredProgramId={id}
              registeredProgram={registeredProgram}
              program={program}
              profile={profile}
            />
          )}

          {/* {!loading &&
            registeredProgram.applicationFeePaid &&
            !registeredProgram.applicationCommentsSet && (
              <CommentsForm
                registeredProgramId={id}
                program={program}
                profile={profile}
              />
            )} */}

          {/*DEVELOPMENT ONLY*/}
          {process.env.REACT_APP_ENVIRONMENT === 'development' && (
            <Fragment>
              {!loading &&
                registeredProgram.applicationFeePaid &&
                registeredProgram.applicationCommentsSet && (
                  <div style={{ marginTop: '1em' }}>
                    <h3>FOR DEVELOPMENT ONLY</h3>

                    {!registeredProgram.recommendersFormCompleted && (
                      <Button
                        variant={'contained'}
                        color={'secondary'}
                        onClick={() => handleCompleteApplicationCheckList()}
                      >
                        COMPLETE APPLICATION CHECKLIST
                      </Button>
                    )}

                    {registeredProgram.confirmationFormCompleted &&
                      !registeredProgram.applicationCompleted && (
                        <Button
                          variant={'contained'}
                          color={'secondary'}
                          onClick={() => handleCompleteApprovedCheckList()}
                        >
                          COMPLETE APPROVED CHECKLIST
                        </Button>
                      )}
                  </div>
                )}
            </Fragment>
          )}

          {/*UNAPPROVED*/}
          {!program.programClosed && (
            <Fragment>
              {!loading &&
                registeredProgram.applicationFeePaid &&
                // registeredProgram.applicationCommentsSet &&
                !registeredProgram.confirmationFormCompleted &&
                !registeredProgram.applicationApproved &&
                registeredProgram.programStage !== 'waitlisted' && (
                  <>
                    <Grid
                      className={classes.checklistContainer}
                      container
                      direction="column"
                      alignItems="center"
                    >
                      <Box width="60%" display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                          <LinearProgress
                            variant="determinate"
                            value={Math.ceil(percentage)}
                          />
                        </Box>
                        <Box minWidth={35}>
                          <Typography variant="body2" color="textSecondary">
                            {Math.ceil(percentage)}%
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={'column'}
                      style={{
                        backgroundColor: 'white',
                        borderRadius: '20px',
                        paddingTop: '68px',
                        paddingBottom: '68px',
                        paddingLeft: '96px',
                        paddingRight: '96px',
                      }}
                    >
                      {registeredProgram.programStage === 'under-review' && (
                        <Grid item>
                          <Typography className={classes.formName}>
                            Application Under Review
                          </Typography>
                        </Grid>
                      )}

                      {/*INITIAL STAGES*/}
                      <InitialStages
                        program={program}
                        registeredProgram={registeredProgram}
                        editApplication={editApplication}
                        handleSetForm={handleSetForm}
                        applicationStages={applicationStages}
                        applicationStagesLeft={applicationStagesLeft}
                        applicationStagesRight={applicationStagesRight}
                        applicationConfirmation={applicationConfirmation}
                      // disabled={isDeadlinePassed}
                      />

                      {/*Application Interests/Comments*/}
                      {applicationInterests && (
                        <CommentsForm
                          registeredProgramId={id}
                          registeredProgram={registeredProgram}
                          program={program}
                          profile={profile}
                          handleCloseForm={handleCloseForm}
                          handleSetForm={handleSetForm}
                        />
                      )}

                      {/*SCHOLARSHIP FORM*/}
                      {scholarshipForm && (
                        <Fragment>
                          <Typography className={classes.formName}>
                            Scholarship Form
                          </Typography>
                          <ScholarshipForm
                            registeredProgramId={id}
                            program={program}
                            profile={profile}
                            handleCloseForm={handleCloseForm}
                            handleSetForm={handleSetForm}
                          />
                        </Fragment>
                      )}

                      {/*TAX FORM*/}
                      {taxForm && (
                        <Fragment>
                          <Typography
                            variant={'h6'}
                            className={classes.formName}
                          >
                            Tax Forms
                          </Typography>
                          <TaxForm
                            program={program}
                            registeredProgramId={id}
                            registeredProgram={registeredProgram}
                            profile={profile}
                            handleCloseForm={handleCloseForm}
                            handleSetForm={handleSetForm}
                          />
                        </Fragment>
                      )}

                      {personalInformationForm && (
                        <PersonalInformationForm
                          program={program}
                          registeredProgramId={id}
                          registeredProgram={registeredProgram}
                          profile={profile}
                          handleCloseForm={handleCloseForm}
                          handleSetForm={handleSetForm}
                        />
                      )}

                      {familyInformationForm && (
                        <FamilyInformationForm
                          program={program}
                          registeredProgramId={id}
                          registeredProgram={registeredProgram}
                          profile={profile}
                          handleCloseForm={handleCloseForm}
                          handleSetForm={handleSetForm}
                        />
                      )}
                      {academicInformationForm && (
                        <AcademicInformationForm
                          program={program}
                          registeredProgramId={id}
                          registeredProgram={registeredProgram}
                          profile={profile}
                          handleCloseForm={handleCloseForm}
                          handleSetForm={handleSetForm}
                        />
                      )}
                      {extracurricularForm && (
                        <ExtracurricularEmploymentForm
                          program={program}
                          registeredProgramId={id}
                          registeredProgram={registeredProgram}
                          profile={profile}
                          handleCloseForm={handleCloseForm}
                          handleSetForm={handleSetForm}
                        />
                      )}
                      {covidProtocolForm && (
                        <CovidProtocolForm
                          registeredProgramId={id}
                          registeredProgram={registeredProgram}
                          profile={profile}
                          handleCloseForm={handleCloseForm}
                          handleSetForm={handleSetForm}
                          program={program}
                        />
                      )}

                      {resumeForm && (
                        <ResumeForm
                          program={program}
                          registeredProgramId={id}
                          registeredProgram={registeredProgram}
                          profile={profile}
                          handleCloseForm={handleCloseForm}
                          handleSetForm={handleSetForm}
                        />
                      )}
                      {transcriptForm && (
                        <TranscriptForm
                          program={program}
                          registeredProgramId={id}
                          registeredProgram={registeredProgram}
                          profile={profile}
                          handleCloseForm={handleCloseForm}
                          handleSetForm={handleSetForm}
                        />
                      )}
                      {additionalDocumentsForm && (
                        <AdditionalDocumentsForm
                          program={program}
                          registeredProgramId={id}
                          registeredProgram={registeredProgram}
                          profile={profile}
                          handleCloseForm={handleCloseForm}
                          handleSetForm={handleSetForm}
                        />
                      )}
                      {recommendersForm && (
                        <RecommendersForm
                          registeredProgramId={id}
                          registeredProgram={registeredProgram}
                          program={program}
                          profile={profile}
                          handleCloseForm={handleCloseForm}
                          handleSetForm={handleSetForm}
                        />
                      )}

                      {shortResponsesForm && (
                        <ShortResponsesForm
                          registeredProgramId={id}
                          registeredProgram={registeredProgram}
                          program={program}
                          profile={profile}
                          handleCloseForm={handleCloseForm}
                          handleSetForm={handleSetForm}
                        />
                      )}

                      {confirmationForm && (
                        <ConfirmationForm
                          registeredProgramId={id}
                          registeredProgram={registeredProgram}
                          program={program}
                          profile={profile}
                          handleCloseForm={handleCloseForm}
                          handleSetForm={handleSetForm}
                        />
                      )}
                    </Grid>
                  </>
                )}
            </Fragment>
          )}

          {/*CLOSED*/}
          {!loading &&
            registeredProgram.applicationFeePaid &&
            //registeredProgram.applicationCommentsSet &&
            registeredProgram.confirmationFormCompleted &&
            registeredProgram.applicationApproved &&
            registeredProgram.programStage === 'closed' &&
            registeredProgram.notificationDateMet && (
              <Fragment>
                <Grid item style={{ marginTop: '1.5em' }}>
                  <Typography variant={'h1'}>{program.programName}</Typography>
                </Grid>
                <Grid item style={{ marginTop: '1.5em' }}>
                  <Typography variant={'h6'}>Application Closed</Typography>
                </Grid>
              </Fragment>
            )}

          {/*AFTER CONFIRMATION UNDER REVIEW* BEFORE NOTIFICATION DAT */}

          {!program.programClosed &&
            registeredProgram.applicationFeePaid &&
            registeredProgram.confirmationFormCompleted &&
            (registeredProgram.programStage === 'under-review' ||
              registeredProgram.programStage === 'reviewed' ||
              registeredProgram.programStage === 'approved' ||
              registeredProgram.programStage === 'denied' ||
              registeredProgram.programStage === 'waitlisted') &&
            !registeredProgram.notificationDateMet && (
              <Fragment>
                <Grid
                  container
                  direction={'row'}
                  style={{ height: 'fit-content', padding: '0' }}
                >
                  <Grid item lg={9} className={classes.confirmationLeft}>
                    <Grid
                      className={classes.overlay}
                      style={{
                        top: 'unset',
                        width: 'inherit',
                        height: '100%',
                        position: 'relative',
                        padding: '35px',
                        backgroundColor: 'rgba(204,204,204, 0.85)',
                      }}
                    >
                      {!loading &&
                        registeredProgram.applicationFeePaid &&
                        // registeredProgram.applicationCommentsSet &&
                        registeredProgram.confirmationFormCompleted &&
                        //!registeredProgram.applicationApproved &&
                        (registeredProgram.programStage === 'under-review' ||
                          registeredProgram.programStage === 'reviewed' ||
                          registeredProgram.programStage === 'approved' ||
                          registeredProgram.programStage === 'denied' ||
                          registeredProgram.programStage === 'waitlisted') &&
                        !registeredProgram.notificationDateMet && (
                          <Fragment>
                            <Grid
                              item
                              style={{ marginTop: '1.5em', color: '#000' }}
                            >
                              <Typography variant={'h3'}>
                                Get ready for the next turn in your journey
                              </Typography>
                              <Typography
                                variant={'h6'}
                                className={classes.statusMessage}
                              >
                                Your application is being reviewed...
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              className={classes.confirmationMessage}
                              style={{ marginTop: '1.5em' }}
                            >
                              <Typography variant={'h6'}>
                                Thank you for applying to the{' '}
                                <b>{program.programName}</b>.
                                <br />
                                Notifications regarding your status will be
                                emailed to you after the date(s) below.
                                <br />
                                <br />
                                {program.earlyAdmittanceDeadline && (
                                  <p className={classes.confMessageHighlight}>
                                    Early Admission Date:{' '}
                                    {format(
                                      parseISO(program.earlyAdmittanceDeadline),
                                      'EEE MMM do y'
                                    )}
                                  </p>
                                )}
                                <p className={classes.confMessageHighlight}>
                                  General Admission Date:{' '}
                                  {program.notificationDate
                                    ? format(
                                      parseISO(program?.notificationDate),
                                      'EEE MMM do y'
                                    )
                                    : null}
                                </p>
                              </Typography>

                              {/*  <Grid item style={{ marginTop: '1.5em' }}>
                              <Button
                              onClick={() =>
                                downloadApplication({
                                  ...registeredProgram,
                                  programDetails: program,
                                })
                              }

                              className={classes.button}
                              style={{width:"fit-content", fontSize:"12px", color: "#000", borderRadius: "50.25rem" }}>
                                <DownloadIcon style={{paddingRight: "10px"}}></DownloadIcon>
                              Download Application
                            </Button> 
                          </Grid>*/}
                            </Grid>
                            <Grid className={classes.nextMessage}>
                              <Typography
                                variant={'h6'}
                                style={{
                                  fontWeight: '600',
                                  color: '#000',
                                  backgroundColor: '#35BA35',
                                  width: 'fit-content',
                                }}
                              >
                                Here's what to expect next:
                              </Typography>
                              <List sx={{ listStyle: 'decimal', pl: 4 }}>
                                <ListItem
                                  sx={{ display: 'list-item', padding: '5px' }}
                                >
                                  <ListItemText
                                    primary="An email with additional information and next steps. 
                              "
                                  />
                                </ListItem>
                                <ListItem
                                  sx={{ display: 'list-item', padding: '5px' }}
                                >
                                  <ListItemText
                                    primary="Additional details about your program of interest. 
                                "
                                  />
                                </ListItem>
                              </List>
                              <Typography>
                                Applications are reviewed at various points
                                throughout the admissions cycle. Please refer to
                                the Application Deadlines chart for timeframes
                                on admissions notifications. Students will
                                receive an email notification once admissions
                                decisions are available. Application Status can
                                also be found on the Applicant's main page.
                              </Typography>
                            </Grid>
                          </Fragment>
                        )}
                      {/*PRIOR TO NOTIFICATION DATE
                  {!loading &&
                    registeredProgram.applicationFeePaid &&
                    // registeredProgram.applicationCommentsSet &&
                    registeredProgram.applicationApproved &&
                    registeredProgram.programStage === 'waitlisted' &&
                    !registeredProgram.notificationDateMet && (
                      <Fragment>
                        <Grid item style={{ marginTop: '1.5em' }}>
                          <Typography variant={'h1'}>
                            {program.programName}
                          </Typography>
                        </Grid>
                        <Grid item style={{ marginTop: '1.5em' }}>
                          <Typography variant={'h6'}>
                            Application Submitted and Under Review. Please return on{' '}
                            {program.notificationDate
                              ? format(
                                  parseISO(program?.notificationDate),
                                  'EEE MMM do y'
                                )
                              : null}{' '}
                          </Typography>
                        </Grid>
                      </Fragment>
                    )}
                  


                  {/*PRIOR TO NOTIFICATION DATE IF INITALLY DENIED
                  {!loading &&
                    registeredProgram.applicationFeePaid &&
                    // registeredProgram.applicationCommentsSet &&
                    registeredProgram.applicationApproved &&
                    registeredProgram.programStage === 'denied' &&
                    !registeredProgram.notificationDateMet && (
                      <Fragment>
                        <Grid item style={{ marginTop: '1.5em' }}>
                          <Typography variant={'h1'}>
                            {program.programName}
                          </Typography>
                        </Grid>
                        <Grid item style={{ marginTop: '1.5em' }}>
                          <Typography variant={'h6'}>
                            Application Submitted and Under Review. Please return on{' '}
                            {program.notificationDate
                              ? format(
                                  parseISO(program?.notificationDate),
                                  'EEE MMM do y'
                                )
                              : null}{' '}
                          </Typography>
                        </Grid>
                      </Fragment>
                    )}
                    */}

                      {/*AFTER NOTIFICATION DATE IF Waitlisted*/}
                      {!loading &&
                        registeredProgram.applicationFeePaid &&
                        // registeredProgram.applicationCommentsSet &&
                        !registeredProgram.applicationApproved &&
                        registeredProgram.confirmationFormCompleted &&
                        registeredProgram.programStage === 'waitlisted' &&
                        registeredProgram.notificationDateMet && (
                          <Fragment>
                            <Grid
                              item
                              style={{ marginTop: '1.5em', color: '#000' }}
                            >
                              <Typography variant={'h3'}>
                                Get ready for the next turn in your journey
                              </Typography>
                              <Typography
                                variant={'h6'}
                                className={classes.statusMessage}
                              >
                                Your application has been waitlisted...
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              className={classes.confirmationMessage}
                              style={{ marginTop: '1.5em' }}
                            >
                              <Typography variant={'h6'}>
                                <p>
                                  {' '}
                                  We have completed our selection and placement
                                  of the {program.programName} . Although your
                                  application was highly valued we were not able
                                  to select and assign you for this year's
                                  cohort.
                                </p>
                                <br></br>

                                <p>
                                  {' '}
                                  Our notification and assignment of all
                                  waitlisted applicants will occur after{' '}
                                  {program.notificationDate
                                    ? format(
                                      parseISO(program?.notificationDate),
                                      'EEE MMM do y'
                                    )
                                    : null}
                                  .
                                </p>
                                <br></br>
                                <p className={classes.confMessageHighlight}>
                                  Once notified you must respond within 24 hours
                                  that you accept your assignment.
                                </p>
                                <br></br>
                                <p>
                                  I know that you may feel disappointed that you
                                  weren’t selected initially, but rest assured
                                  this in no way diminishes your amazing
                                  accomplishments or our interest in you. We
                                  simply do not have enough available spaces
                                  even for qualified applicants. We are working
                                  hard to make this less the case in the future.
                                </p>
                              </Typography>
                            </Grid>
                          </Fragment>
                        )}
                      {/*AFTER NOTIFICATION DATE IF DENIED*/}
                      {!loading &&
                        registeredProgram.applicationFeePaid &&
                        //  registeredProgram.applicationCommentsSet &&
                        !registeredProgram.applicationApproved &&
                        registeredProgram.confirmationFormCompleted &&
                        registeredProgram.programStage === 'denied' &&
                        registeredProgram.notificationDateMet && (
                          <Fragment>
                            <Grid item style={{ marginTop: '1.5em' }}>
                              {/*<Typography variant={"h1"}>
                            {program.programName}
                    </Typography>*/}
                            </Grid>
                            <Grid item style={{ marginTop: '1.5em' }}>
                              <Typography variant={'h6'}>
                                <p>
                                  {' '}
                                  We have completed our selection and placement
                                  of the {program.programName} . Although your
                                  application was highly valued we were not able
                                  to select and assign you for this year's
                                  cohort.
                                </p>
                                <br></br>
                                <p>
                                  I know that you may feel disappointed that you
                                  weren’t selected initially, but rest assured
                                  this in no way diminishes your amazing
                                  accomplishments or our interest in you. We
                                  simply do not have enough available spaces
                                  even for qualified applicants. We are working
                                  hard to make this less the case in the future.
                                </p>
                              </Typography>
                            </Grid>
                          </Fragment>
                        )}
                    </Grid>
                  </Grid>
                  <Grid item lg={3} className={classes.confirmationRight}>
                    <Typography variant={'h6'} className={classes.rightTitle}>
                      Get your questions answered
                    </Typography>
                    <Grid direction={'column'}>
                      <Grid className={classes.rightGroup}>
                        <Typography itemclassName={classes.rightText}>
                          Have a question? Chat with us
                        </Typography>
                        <Button
                          className={classes.button}
                          style={{
                            width: '130px',
                            borderRadius: '50.25rem',
                            fontSize: '12px',
                            marginTop: '10px',
                          }}
                          onClick={() =>
                            (window.location = 'mailto:info@leadprogram.org')
                          }
                        >
                          <EmailIcon
                            style={{ paddingRight: '5px' }}
                          ></EmailIcon>{' '}
                          Email Us
                        </Button>
                      </Grid>
                      <Grid className={classes.rightGroup}>
                        <Typography itemclassName={classes.rightText}>
                          Find answers yourself!
                        </Typography>
                        <Button
                          className={classes.button}
                          style={{
                            width: '130px',
                            borderRadius: '50.25rem',
                            fontSize: '12px',
                            marginTop: '10px',
                          }}
                          onClick={() =>
                            history.push(
                              `/applicant/faqs/view?id=${profile.uid}`
                            )
                          }
                        >
                          <HelpIcon style={{ paddingRight: '5px' }}></HelpIcon>{' '}
                          FAQ
                        </Button>
                      </Grid>
                      <Grid className={classes.rightGroup}>
                        <Typography itemclassName={classes.rightText}>
                          Checkout some of our other programs!
                        </Typography>
                        <Button
                          className={classes.button}
                          style={{
                            width: '130px',
                            borderRadius: '50.25rem',
                            fontSize: '12px',
                            marginTop: '10px',
                          }}
                          onClick={() => history.push(`/program`)}
                        >
                          <SearchIcon
                            style={{ paddingRight: '5px' }}
                          ></SearchIcon>{' '}
                          Browse
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Fragment>
            )}

          {/*APPROVED - PENDING ACCEPTANCE*/}
          {/* {!program.programClosed && ( */}
          <Fragment>
            {!loading &&
              // !registeredProgram.applicationCommentsSet &&
              // i commented below line just because of client new requirement i.e When student is marked as approved show congratulations message on the dashboard Recent Application before acceptance. Right now it shows after application has been marked as accepted
              // registeredProgram.applicationApproved &&
              // !registeredProgram.applicationAccepted &&
              registeredProgram.programStage === 'approved' &&
              registeredProgram.notificationDateMet && (
                <Fragment>
                  <Grid
                    item
                    style={{ marginTop: '1.5em', position: 'relative' }}
                  >
                    <img
                      style={{
                        position: 'relative',
                        width: '100%',
                        textAlign: 'center',
                      }}
                      alt=""
                      src="https://acegif.com/wp-content/uploads/gif/confetti-31.gif"
                    ></img>
                    <Typography
                      variant={'h1'}
                      style={{
                        textShadow: '1px 1px 2px gray',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      <p>Congratulations!</p>
                    </Typography>
                  </Grid>

                  <Grid item style={{ marginTop: '1.5em' }}>
                    <Typography variant={'body1'}>
                      {program.customCongratulations ? (
                        <div
                          className={classes.text}
                          dangerouslySetInnerHTML={{
                            __html: program?.customCongratulationsText ? (
                              `${program?.customCongratulationsText.substring(
                                0,
                                program?.customCongratulationsText?.length
                              )}`
                            ) : (
                              <p>Congratulations!</p>
                            ),
                          }}
                        />
                      ) : (
                        <>
                          <p>
                            On behalf of LEADership Education and Development,
                            we are delighted to offer you admission to the{' '}
                            {program.programName}. You were selected based upon
                            your academic performance, essay, recommendations,
                            and extracurricular activities. Your acceptance was
                            also based on our belief that you will offer a
                            unique perspective to your cohort and will add to
                            our talented group of diverse scholars.
                          </p>
                          <br></br>
                          <p>
                            Your LEADing for Life assignment will begin on{' '}
                            {program.programStartDate
                              ? format(
                                parseISO(program?.programStartDate),
                                'EEE MMM do y'
                              )
                              : null}
                            . Please complete the Acceptance Form by choosing to
                            ACCEPT or DECLINE. If you intend to accept your
                            appointment, you must also complete the
                            Parent/Scholar Consent form, along with your entire
                            Acceptance Checklist.
                          </p>
                          <br></br>
                          <p>
                            We remain excited about the {program.programName}{' '}
                            and are truly looking forward to welcoming you into
                            the LEADing for Life community!
                          </p>
                        </>
                      )}

                      <br></br>

                      <p>
                        <b>
                          Placement 1:{' '}
                          {
                            registeredProgram.institutionDetails
                              ?.institutionName
                          }
                        </b>
                      </p>
                      <br></br>

                      <p>
                        <b>
                          Arrival Date:{' '}
                          {registeredProgram.institutionDetails?.arrivalDate
                            ? format(
                              parseISO(
                                registeredProgram.institutionDetails
                                  ?.arrivalDate
                              ),
                              'EEE MMM do y'
                            )
                            : null}
                        </b>
                      </p>
                      <p>
                        <b>
                          Departure Date:{' '}
                          {registeredProgram.institutionDetails?.departureDate
                            ? format(
                              parseISO(
                                registeredProgram.institutionDetails
                                  ?.departureDate
                              ),
                              'EEE MMM do y'
                            )
                            : null}
                        </b>
                      </p>

                      {registeredProgram.scholarshipAmount > 0 && (
                        <>
                          <br></br>
                          <p>
                            <b>
                              Scholarship Granted: $
                              {registeredProgram.scholarshipAmount}
                            </b>
                          </p>
                        </>
                      )}
                      {registeredProgram.institutionDetails
                        ?.institutionNotes && (
                          <>
                            <br></br>
                            <p>
                              <b>Additional Information:</b>
                            </p>
                            <p>
                              {
                                registeredProgram.institutionDetails
                                  ?.institutionNotes
                              }
                            </p>
                          </>
                        )}
                      <br />
                      <hr />
                      <br />
                      {institution?.institutionName && (
                        <p>
                          <b>Placement 2: {institution?.institutionName}</b>
                        </p>
                      )}
                       <br />
                      <p>
                        {institution?.arrivalDate && (
                          <b>
                            Arrival Date:{' '}
                            {institution?.arrivalDate
                              ? format(
                                parseISO(institution?.arrivalDate),
                                'EEE MMM do y'
                              )
                              : null}
                          </b>
                        )}
                      </p>
                      <p>
                        {institution?.departureDate && (
                          <b>
                            Departure Date:{' '}
                            {institution?.departureDate
                              ? format(
                                parseISO(institution?.departureDate),
                                'EEE MMM do y'
                              )
                              : null}
                          </b>
                        )}
                      </p>
                      {institution?.institutionNotes && (
                        <>
                          <br></br>
                          <p>
                            <b>Additional Information:</b>
                          </p>
                          <p>{institution?.institutionNotes}</p>
                        </>
                      )}
                    </Typography>
                  </Grid>
                  <AcceptanceStage
                    registeredProgram={registeredProgram}
                    approvedStages={approvedStages}
                    approvedStagesTravel={approvedStagesTravel}
                  />
                </Fragment>
              )}
          </Fragment>
          {/* )} */}

          {!program.programClosed && (
            <Fragment>
              {!loading &&
                registeredProgram.applicationFeePaid &&
                //registeredProgram.applicationCommentsSet &&
                !registeredProgram.applicationApproved &&
                registeredProgram.programStage === 'withdrawn' && (
                  <Fragment>
                    <Grid item style={{ marginTop: '1.5em' }}>
                      <Typography variant={'h6'}>
                        While we are disappointed that you will not be joining
                        our LEAD community, we extend our best wishes to you and
                        your future endeavors.
                      </Typography>
                    </Grid>
                    <Grid item style={{ marginTop: '1.5em' }}>
                      <form
                        autoComplete={'off'}
                        onSubmit={handleSubmit((values) => {
                          submitDeclineComment(id, values);
                        })}
                      >
                        <TextInputForm
                          fieldName={'declineComment'}
                          question={'Please let us know why you are declining:'}
                          disabled={registeredProgram?.declineComment}
                        />
                        <Grid container justifyContent="flex-end">
                          <Button
                            variant="outlined"
                            color="primary"
                            type="submit"
                            disabled={
                              registeredProgram?.declineComment || submitting
                            }
                          >
                            Submit
                          </Button>
                        </Grid>
                      </form>
                    </Grid>
                  </Fragment>
                )}
            </Fragment>
          )}

          {/*APPROVED STATGES*/}
          {/* TODO: */}
          {!program.programClosed && (
            <Fragment>
              {!loading &&
                showCheckList &&
                registeredProgram.applicationFeePaid &&
                registeredProgram.applicationCommentsSet &&
                registeredProgram.applicationApproved &&
                (registeredProgram.programStage === 'admitted' ||
                  registeredProgram.programStage === 'complete') && (
                  <Fragment>
                    <ApprovedStages
                      program={program}
                      registeredProgram={registeredProgram}
                      registeredProgramId={id}
                      editApplication={editApplication}
                      handleSetForm={handleSetForm}
                      approvedStages={approvedStages}
                      approvedStagesTravel={approvedStagesTravel}
                    // disabled={isDeadlinePassed}
                    />
                  </Fragment>
                )}

              {/*APPROVED FORMS*/}
              {grantAcceptanceForm && (
                <GrantAcceptanceForm
                  registeredProgramId={id}
                  registeredProgram={registeredProgram}
                  program={program}
                  profile={profile}
                  handleCloseForm={handleCloseForm}
                  handleSetForm={handleSetForm}
                />
              )}
              {/*SCHOLAR CONSENT FORM LEAD ACCEPTANCE FORM*/}
              {scholarConsentForm && (
                <ScholarConsentForm
                  registeredProgramId={id}
                  registeredProgram={registeredProgram}
                  program={program}
                  profile={profile}
                  handleCloseForm={handleCloseForm}
                  handleSetForm={handleSetForm}
                />
              )}
              {/*GOVERNMENT ID FORM*/}
              {governmentIdForm && (
                <GovernmentIdForm
                  registeredProgramId={id}
                  registeredProgram={registeredProgram}
                  program={program}
                  profile={profile}
                  handleCloseForm={handleCloseForm}
                  handleSetForm={handleSetForm}
                  onReload={handleReload}
                />
              )}

              {requiredDocumentationForm && (
                <RequiredDocumentationForm
                  registeredProgramId={id}
                  registeredProgram={registeredProgram}
                  program={program}
                  profile={profile}
                  handleCloseForm={handleCloseForm}
                  handleSetForm={handleSetForm}
                  onReload={handleReload}
                />
              )}

              {/*Questionnaire FORM*/}
              {studentQuestionnaireForm && (
                <StudentQuestionnaireForm
                  registeredProgramId={id}
                  registeredProgram={registeredProgram}
                  program={program}
                  profile={profile}
                  handleCloseForm={handleCloseForm}
                  handleSetForm={handleSetForm}
                />
              )}

              {/*PAYMENT FORM*/}
              {paymentForm && (
                <PaymentForm
                  registeredProgramId={id}
                  registeredProgram={registeredProgram}
                  program={program}
                  profile={profile}
                  handleCloseForm={handleCloseForm}
                  handleSetForm={handleSetForm}
                  processingFee={processingFee}
                />
              )}

              {travelForm && (
                <TravelInformationForm
                  registeredProgramId={id}
                  registeredProgram={registeredProgram}
                  program={program}
                  profile={profile}
                  handleCloseForm={handleCloseForm}
                  handleSetForm={handleSetForm}
                />
              )}
            </Fragment>
          )}

          {!showForm && registeredProgram.applicationFeePaid ? (
            <Grid
              item
              container
              justifyContent={'center'}
              style={{ paddingBottom: '1rem' }}
            >
              <Grid item container direction={'column'} alignItems={'center'}>
                <Grid
                  item
                  alignItems={'center'}
                  style={{ paddingBottom: '3rem', textAlign: 'center' }}
                >
                  {(program.travelFormRequired &&
                    registeredProgram.programStage === 'admitted') ||
                    registeredProgram.programStage === 'complete' ? (
                    <Typography>
                      <span
                        style={{
                          color: '#891010',
                          fontWeight: '600',
                          fontSize: '1.05rem',
                        }}
                      >
                        The Application checklist MUST be submitted prior to the
                        DUE DATE on {'  '}
                        {program.packetDueDate
                          ? format(
                            parseISO(program.packetDueDate),
                            'EEEE MMM do y'
                          )
                          : null}
                        , AT 11:59PM PST. <br></br>
                        <br></br>
                      </span>
                      Please review and ensure that ALL of your applicable forms
                      (Grant Acceptance Form, Consent Forms, Travel Form and
                      upload Government issued ID) are completed, and your
                      program fees must be satisfied to attend any LEAD Program.
                      As part of your program fees, round trip travel
                      arrangements will be made and supplied by our contracted
                      Travel Agent using the information on your Travel Form for
                      the scholars to their respective institution.
                      {/*
                        Please review the checklist and ensure that all required information is uploaded in each section before submitting. {" "}
                        <br></br>
                        <br></br> 
                         Once submitted, you will be unable to make any changes.
                        <br></br>

                        The entire checklist will automatically be submitted on the program packet due date, {program.packetDueDate   ? format(
                              parseISO(program.packetDueDate ),
                              "EEE MMM do y"
                            )
                        : null}*/}
                    </Typography>
                  ) : null}
                  {registeredProgram.programStage === 'in-progress' ? (
                    <Typography>
                      It is your responsibility to monitor your application for
                      completeness and ensure your entire checklist is marked as
                      completed.
                    </Typography>
                  ) : null}
                </Grid>

                <Grid
                  item
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {registeredProgram.scholarConsentFormCompleted &&
                    registeredProgram.scholarshipFormCompleted &&
                    registeredProgram.governmentIdFormUploaded &&
                    registeredProgram.payment1Received &&
                    registeredProgram.payment2Received &&
                    registeredProgram.travelInformationFormCompleted &&
                    registeredProgram.studentQuestionnaireFormCompleted &&
                    registeredProgram.requiredDocumentationFormUploaded &&
                    !registeredProgram?.finalSubmissionCompleted ? (
                    <>
                      <Typography
                        style={{ whiteSpace: 'nowrap', margin: '0 0 10px' }}
                      >
                        Once all sections of your application checklist has been
                        completed you can submit your application.
                      </Typography>
                      <Button
                        size={'large'}
                        variant={'outlined'}
                        color={'primary'}
                        className={classes.button}
                        onClick={() => handleSubmitApplication()}
                        disabled={submitting}
                      >
                        Submit Application
                      </Button>
                    </>
                  ) : registeredProgram.programStage === 'complete' ||
                    registeredProgram.programStage === 'withdrawn' ? (
                    <Typography style={{ fontWeight: 600 }}>
                      Application has been{' '}
                      {registeredProgram?.programStage === 'withdrawn'
                        ? 'withdrawn'
                        : 'submitted'}
                      .
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            !showForm &&
            registeredProgram.grantAcceptanceFormCompleted &&
            registeredProgram.scholarshipFormCompleted &&
            registeredProgram.governmentIdFormUploaded &&
            registeredProgram.payment1Received &&
            registeredProgram.payment2Received &&
            registeredProgram.studentQuestionnaireFormCompleted &&
            registeredProgram.requiredDocumentationFormUploaded && (
              <Grid
                item
                container
                justifyContent={'center'}
                style={{ paddingBottom: '1rem' }}
              >
                <Grid
                  item
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    style={{ whiteSpace: 'nowrap', margin: '0 0 10px' }}
                  >
                    Once all sections of your application checklist has been
                    completed you can submit your application.
                  </Typography>
                  <Button
                    size={'large'}
                    variant={'outlined'}
                    color={'primary'}
                    className={classes.button}
                    onClick={() => handleSubmitApplication()}
                    disabled={
                      submitting || registeredProgram.finalSubmissionCompleted
                    }
                  >
                    Submit Application
                  </Button>
                </Grid>
              </Grid>
            )
          )}
        </Grid>
        {/*END OF DETAILS CONTAINER*/}
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: 'declineCommentForm',
    enableReinitialize: true,
    validate: validate,
  })(EditProgram)
);
