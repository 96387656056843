import React from "react";
import { Grid, Typography } from "@mui/material";
import { isEqual } from "lodash";
import { format, fromUnixTime } from "date-fns";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  item: {
    marginTop: "1rem",
  },
  heading: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  text: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#696974",
  },
  button: {
    marginLeft: "1rem",
  },
}));

const ReviewerForm = ({
  reviewerForm,
  initialValues,
  updatedAt,
  isAdmin,
  fullName,
}) => {
  const classes = useStyles();

  const getTitle = (key) => {
    if (key === "essayQualityValue") return "Essay Quality";
    if (key === "extraCurricularCommunityActivityValue")
      return "Extra Curriculum/ Community Involvement";
    if (key === "academicPerformanceValue") return "Academic Performance";
    if (key === "recommendationQualityValue") return "Recommendation Quality";
    return "Comments";
  };

  return (
    <Grid container direction="column">
      <Grid
        className={classes.item}
        item
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.title}>
          Extra Curriculum/ Community Involvement
        </Typography>
        <Typography className={classes.text}>
          {reviewerForm?.extraCurricularCommunityActivityValue}
        </Typography>
      </Grid>
      <Grid
        className={classes.item}
        item
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.title}>Academic Performance</Typography>
        <Typography className={classes.text}>
          {reviewerForm?.academicPerformanceValue}
        </Typography>
      </Grid>
      <Grid
        className={classes.item}
        item
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.title}>Essay Quality</Typography>
        <Typography className={classes.text}>
          {reviewerForm?.essayQualityValue}
        </Typography>
      </Grid>
      <Grid
        className={classes.item}
        item
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.title}>
          Recommendation Quality
        </Typography>
        <Typography className={classes.text}>
          {reviewerForm?.recommendationQualityValue}
        </Typography>
      </Grid>
      <Grid
        className={classes.item}
        item
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.title}>Comments</Typography>
        <Typography className={classes.text}>
          {reviewerForm?.comments}
        </Typography>
      </Grid>
      <Grid
        className={classes.item}
        item
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.title}>Reviewed By</Typography>
        <Typography className={classes.text}>
          {reviewerForm?.reviewedBy}
        </Typography>
      </Grid>
      <Grid
        className={classes.item}
        item
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.title}>Reviewed On</Typography>
        <Typography className={classes.text}>
          {new Date(reviewerForm?.reviewedOn).toLocaleDateString()}
        </Typography>
      </Grid>
      {isAdmin && !isEqual(reviewerForm && initialValues) && (
        <>
          <Grid className={classes.item} item>
            <Typography className={classes.heading}>Admin Changes</Typography>
          </Grid>
          {Object.keys(initialValues).map((key) => {
            if (initialValues[key] !== reviewerForm[key]) {
              return (
                <Grid
                  className={classes.item}
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography className={classes.title}>
                    {getTitle(key)}
                  </Typography>
                  <Typography className={classes.text}>
                    {initialValues[key]}
                  </Typography>
                </Grid>
              );
            }
            return <></>;
          })}
          <Grid
            className={classes.item}
            item
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className={classes.title}>Reviewed By</Typography>
            <Typography className={classes.text}>{fullName}</Typography>
          </Grid>
          <Grid
            className={classes.item}
            item
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className={classes.title}>Updated On</Typography>
            {updatedAt && (
              <Typography className={classes.text}>
                {format(fromUnixTime(updatedAt), "MM/dd/yyyy")}
              </Typography>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ReviewerForm;
