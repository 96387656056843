import React from "react";
import { Field } from "redux-form";
import { Editor } from "@tinymce/tinymce-react";

export const RichTextArea = (props) => {
  const renderTinyMCE = (field) => {
    let props = Object.assign({}, field);
    delete props.input;
    delete props.meta;

    return (
      <Editor
        {...props}
        value={field.input.value !== "" ? field.input.value : null}
        onBlur={(event, value) => {
          field.input.onBlur(event.target.getContent());
        }}
      />
    );
  };

  return (
    <Field
      component={renderTinyMCE}
      name={props.name.toString()}
      // disabled={props.readonly}
      autoComplete="off"
      config={{
        plugins: "link,image code,lists,paste,code",
        toolbar:
          "undo redo | formatselect bullist numlist | bold italic link | code paste",
        block_formats: "Paragraph=p;Heading 3=h3",
        automatic_uploads: true,
        file_picker_types: 'image',
        file_picker_callback: (cb, value, meta) => {
          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
      
          input.addEventListener('change', (e) => {
            const file = e.target.files[0];
      
            const reader = new FileReader();
            reader.addEventListener('load', () => {
              /*
                Note: Now we need to register the blob in TinyMCEs image blob
                registry. In the next release this part hopefully won't be
                necessary, as we are looking to handle it internally.
              */
              const id = 'blobid' + (new Date()).getTime();
              const blobCache =  this.activeEditor.editorUpload.blobCache;
              const base64 = reader.result.split(',')[1];
              const blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);
      
              /* call the callback and populate the Title field with the file name */
              cb(blobInfo.blobUri(), { title: file.name });
            });
            reader.readAsDataURL(file);
          });
      
          input.click();
        },
        menubar: false,
        statusbar: false,
        body_class: "editable-field-content",
        paste_word_valid_elements: "b,strong,i,em,h1,h2,h3,p,li,ul,ol,a",
        paste_retain_style_properties: "none",
        paste_strip_class_attributes: "none",
        paste_remove_styles: true,
      }}
    />
  );
};

export default RichTextArea;
