import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from '../../asyncActions/asyncActions';
import cuid from 'cuid';
import { fetchRegisteredProgram } from './applicantProgramActions';
import { format, getUnixTime, parseISO } from 'date-fns';
import axios from '../../../../config/axios';

// PAYMENT 1
export const updatePayment1Paid = (
  id,
  registeredProgram,
  paymentId,
  program,
  applicantId,
  payment1,
  currentPaymentAmount,
  applicantName
) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    const paymentTransactionQuery = firestore.collection('paymentTransactions');

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      const paymentAmount = payment1 + currentPaymentAmount;
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        payment1Received: true,
        payment1PaymentId: paymentId,
        paymentAmount: paymentAmount,
      });

      const todaysDate = new Date().toISOString();
      await paymentTransactionQuery.add({
        paymentType: 'payment1',
        paymentDate: todaysDate,
        paymentYear: format(parseISO(todaysDate), 'y'),
        paymentId: paymentId,
        paymentFee: payment1,
        applicantId: applicantId,
        programId: program.id,
        applicantName: applicantName,
      });

      await axios.get('/applicantPaymentFunctions/sendPaymentReceived', {
        params: {
          id: id,
          applicantFirstName: registeredProgram.firstName,
          applicantLastName: registeredProgram.lastName,
          applicantEmail: registeredProgram.email,
          programName: program.programName,
          paymentAmount: payment1,
        },
      });

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updatePayment2Paid = (
  id,
  registeredProgram,
  paymentId,
  program,
  applicantId,
  payment2,
  currentPaymentAmount,
  applicantName
) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    const paymentTransactionQuery = firestore.collection('paymentTransactions');

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      const paymentAmount = payment2 + currentPaymentAmount;
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        payment2Received: true,
        payment2PaymentId: paymentId,
        paymentAmount: paymentAmount,
      });

      const todaysDate = new Date().toISOString();
      await paymentTransactionQuery.add({
        paymentType: 'payment2',
        paymentDate: todaysDate,
        paymentYear: format(parseISO(todaysDate), 'y'),
        paymentId: paymentId,
        paymentFee: payment2,
        applicantId: applicantId,
        programId: program.id,
        applicantName: applicantName,
      });

      await axios.get('/applicantPaymentFunctions/sendPaymentReceived', {
        params: {
          id: id,
          applicantFirstName: registeredProgram.firstName,
          applicantLastName: registeredProgram.lastName,
          applicantEmail: registeredProgram.email,
          programName: program.programName,
          paymentAmount: payment2,
        },
      });

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updatePaymentBalancePaid = (
  id,
  registeredProgram,
  paymentId,
  program,
  applicantId,
  payment,
  currentPaymentAmount,
  applicantName
) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    const paymentTransactionQuery = firestore.collection('paymentTransactions');

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      const paymentAmount = payment + currentPaymentAmount;
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        payment1Received: true,
        payment2Received: true,
        payment1PaymentId: paymentId,
        payment2PaymentId: paymentId,
        paymentAmount: paymentAmount,
      });

      const todaysDate = new Date().toISOString();
      await paymentTransactionQuery.add({
        paymentType: 'paymentBalance',
        paymentDate: todaysDate,
        paymentYear: format(parseISO(todaysDate), 'y'),
        paymentId: paymentId,
        paymentFee: payment,
        applicantId: applicantId,
        programId: program.id,
        applicantName: applicantName,
      });

      await axios.get('/applicantPaymentFunctions/sendPaymentReceived', {
        params: {
          id: id,
          applicantFirstName: registeredProgram.firstName,
          applicantLastName: registeredProgram.lastName,
          applicantEmail: registeredProgram.email,
          programName: program.programName,
          paymentAmount: payment,
        },
      });

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

// NO PAYMENT NEEDED
export const updatePaymentPaid = (id) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        payment1Received: true,
        payment2Received: true,
      });

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const submitTravelInformationForm = (id, values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      const registeredProgram = await registeredProgramQuery.get();
      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        travelInformationFormCompleted: true,
        travelInformation: {
          ...values,
        },
        logs: !registeredProgram.data()?.travelInformationFormCompleted
          ? [
              ...registeredProgram.data()?.logs,
              {
                event: 'Travel Form Submitted',
                date: getUnixTime(new Date()),
              },
            ]
          : [...registeredProgram.data()?.logs],
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
export const updateTravelInformationForm = (id, values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    console.log(id, values, 'hgfd');
    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      const registeredProgram = await registeredProgramQuery.get();
      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        // editiedAt: editiedAt,
        travelInformationFormCompleted: false,
        travelInformation: {
          ...values,
        },
        // logs: !registeredProgram.data()?.travelInformationFormCompleted
        //   ? [
        //       ...registeredProgram.data()?.logs,
        //       {
        //         event: 'Travel Form Submitted',
        //         date: getUnixTime(new Date()),
        //       },
        //     ]
        //   : [...registeredProgram.data()?.logs],
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const submitFinalApplication = (id, registeredProgram, program) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      const registeredProgram = await registeredProgramQuery.get();
      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        finalSubmissionCompleted: true,
        programStage: 'complete',
        completedAt: editiedAt,
        programStatus: 'admitted',
        logs: registeredProgram.data().logs
          ? [
              ...registeredProgram.data().logs,
              {
                event: 'Acceptance Checklist Submitted',
                date: getUnixTime(new Date()),
              },
            ]
          : [
              {
                event: 'Acceptance Checklist Submitted',
                date: getUnixTime(new Date()),
              },
            ],
      });

      await axios.get(
        '/applicantApplicationFunctions/sendApplicationAcceptance',
        {
          params: {
            id: id,
            applicantFirstName: registeredProgram.firstName,
            applicantLastName: registeredProgram.lastName,
            applicantEmail: registeredProgram.email,
            programName: program.programName,
          },
        }
      );

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const uploadVaccineCard = (id, file) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      let fileExtension = '';

      if (file[0].type === 'image/jpeg') {
        fileExtension = '.jpeg';
      }

      if (file[0].type === 'image/png') {
        fileExtension = '.png';
      }

      if (file[0].type === 'application/pdf') {
        fileExtension = '.pdf';
      }

      if (file) {
        const fileName = cuid() + fileExtension;
        const originalFileName = file[0].name;

        const path = `registeredPrograms/${id}/vaccineCard`;
        const options = {
          name: fileName,
        };

        let uploadedFile = await firebase.uploadFile(
          path,
          file[0],
          null,
          options
        );

        let downloadURL =
          await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();

        // add image name and URL to firestore
        await registeredProgramQuery.update({
          vaccineCard: {
            fileURL: downloadURL,
            fileName: fileName,
            originalFileName: originalFileName,
          },
        });
      }

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const deleteVaccineCard = (id, docId, fileName) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);
    console.log('asd');
    try {
      dispatch(asyncActionStart());

      // DELETE PHOTO FROM STORAGE
      await firebase.deleteFile(
        `registeredPrograms/${id}/vaccineCard/${fileName}`
      );
      await registeredProgramQuery.update({
        vaccineCard: null,
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
    }
  };
};

export const resendGrantAcceptanceForm = (data) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      await axios.get(
        '/grantAcceptanceFormFunctions/resendGrantAcceptanceForm',
        {
          params: data,
        }
      );

      dispatch(fetchRegisteredProgram(data.id));
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
    }
  };
};

export const saveGrantAcceptanceForm = (id, files, completed) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      const registeredProgram = await registeredProgramQuery.get();

      const updatedFields = {};

      for (let fileType in files) {
        if (files[fileType].length > 0 && !files[fileType][0].fileURL) {
          updatedFields[fileType] = [];

          for (let file in Array.from(files[fileType])) {
            const fileName =
              cuid() + '.' + files[fileType][file].name.split('.').pop();
            const originalFileName = files[fileType][file].name;

            const path = `registeredPrograms/${id}/grantAcceptance`;
            const options = {
              name: fileName,
            };

            let uploadedFile = await firebase.uploadFile(
              path,
              files[fileType][file],
              null,
              options
            );

            let downloadURL =
              await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();

            updatedFields[fileType].push({
              fileURL: downloadURL,
              fileName: fileName,
              originalFileName: originalFileName,
            });
          }
        }
      }

      // add image name and URL to firestore
      await registeredProgramQuery.update({
        grantAcceptanceFormDetails: {
          ...registeredProgram.data().grantAcceptanceFormDetails,
          ...updatedFields,
        },
        grantAcceptanceFormCompleted: completed,
        logs:
          !registeredProgram.data()?.grantAcceptanceFormCompleted && completed
            ? [
                ...registeredProgram.data()?.logs,
                {
                  event: 'Acceptance Forms Completed',
                  date: getUnixTime(new Date()),
                },
              ]
            : [...registeredProgram.data()?.logs],
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteGrantAcceptanceFile = (id, fieldName, files) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      const registeredProgram = await registeredProgramQuery.get();

      for (let file in files) {
        firebase.deleteFile(
          `registeredPrograms/${id}/grantAcceptance/${files[file].fileName}`
        );
      }

      const updatedGrantAcceptance =
        registeredProgram.data().grantAcceptanceFormDetails;

      delete updatedGrantAcceptance[fieldName];

      await registeredProgramQuery.update({
        grantAcceptanceFormDetails: updatedGrantAcceptance,
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
    }
  };
};

export const saveScholarConsentForm = (id, files, completed) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      const registeredProgram = await registeredProgramQuery.get();

      const updatedFields = {};

      for (let fileType in files) {
        if (files[fileType].length > 0) {
          updatedFields[fileType] = [];

          for (let file in Array.from(files[fileType])) {
            if (!files[fileType][file]?.fileURL) {
              const name =
                files[fileType][file].name || files[fileType][file].fileName;
              const fileName = cuid() + '.' + name?.split('.').pop();
              const originalFileName = files[fileType][file].name;

              const path = `registeredPrograms/${id}/scholarConsent`;
              const options = {
                name: fileName,
              };

              let uploadedFile = await firebase.uploadFile(
                path,
                files[fileType][file],
                null,
                options
              );

              let downloadURL =
                await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();

              updatedFields[fileType].push({
                fileURL: downloadURL,
                fileName: fileName,
                originalFileName: originalFileName,
              });
            } else {
              updatedFields[fileType].push({
                fileURL: files[fileType][file]?.fileURL,
                fileName: files[fileType][file]?.fileName,
                originalFileName: files[fileType][file]?.originalFileName,
              });
            }
          }
        }
      }

      // add image name and URL to firestore
      await registeredProgramQuery.update({
        scholarConsentFormDetails: {
          ...registeredProgram.data().grantAcceptanceFormDetails,
          ...updatedFields,
        },
        scholarConsentFormCompleted: completed,
        logs:
          !registeredProgram.data()?.scholarConsentFormCompleted && completed
            ? [
                ...registeredProgram.data()?.logs,
                {
                  event: 'Lead Acceptance Forms Completed',
                  date: getUnixTime(new Date()),
                },
              ]
            : [...registeredProgram.data()?.logs],
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
    }
  };
};

export const getScholarConsentForm = (id) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const getProgramFormQuery = await firestore
      .collection('programs')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      const GetFormProgram = await getProgramFormQuery.get();

      console.log('GetFormProgram', GetFormProgram);

      await getProgramFormQuery.get({
        acceptanceParentConsentDocuments: {
          ...getProgramFormQuery.data(),
        },
      });

      dispatch(asyncActionFinish());
    } catch (err) {
      dispatch(asyncActionError());
      console.log('err', err);
    }
  };
};

export const deleteScholarConsentFile = (id, fieldName, files) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      const registeredProgram = await registeredProgramQuery.get();

      for (let file in files) {
        firebase.deleteFile(
          `registeredPrograms/${id}/scholarConsent/${files[file].fileName}`
        );
      }

      const updatedScholarConsent =
        registeredProgram.data().scholarConsentFormDetails;

      delete updatedScholarConsent[fieldName];

      await registeredProgramQuery.update({
        scholarConsentFormDetails: updatedScholarConsent,
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
    }
  };
};

export const saveGovernmentIdForm = (id, files, completed) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      const registeredProgram = await registeredProgramQuery.get();

      const updatedFields = {};

      for (let fileType in files) {
        if (files[fileType].length > 0) {
          updatedFields[fileType] = [];

          for (let file in Array.from(files[fileType])) {
            if (!files[fileType][file]?.fileURL) {
              const name =
                files[fileType][file].name || files[fileType][file].fileName;
              const fileName = cuid() + '.' + name?.split('.').pop();
              const originalFileName = files[fileType][file].name;

              const path = `registeredPrograms/${id}/governmentId`;
              const options = {
                name: fileName,
              };

              let uploadedFile = await firebase.uploadFile(
                path,
                files[fileType][file],
                null,
                options
              );

              let downloadURL =
                await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();

              updatedFields[fileType].push({
                fileURL: downloadURL,
                fileName: fileName,
                originalFileName: originalFileName,
              });
            } else {
              updatedFields[fileType].push({
                fileURL: files[fileType][file]?.fileURL,
                fileName: files[fileType][file]?.fileName,
                originalFileName: files[fileType][file]?.originalFileName,
              });
            }
          }
        }
      }

      // add image name and URL to firestore
      await registeredProgramQuery.update({
        governmentIds: {
          ...registeredProgram.data().grantAcceptanceFormDetails,
          ...updatedFields,
        },
        governmentIdFormUploaded: completed,
        logs:
          !registeredProgram.data()?.governmentIdFormUploaded && completed
            ? [
                ...registeredProgram.data()?.logs,
                {
                  event: 'Government ID & Headshot Complete',
                  date: getUnixTime(new Date()),
                },
              ]
            : [...registeredProgram.data().logs],
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteGovernmentIdFile = (id, fieldName, files) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      const registeredProgram = await registeredProgramQuery.get();

      for (let file in files) {
        firebase.deleteFile(
          `registeredPrograms/${id}/governmentId/${files[file].fileName}`
        );
      }

      const updatedGovernmentIds = registeredProgram.data().governmentIds;

      delete updatedGovernmentIds[fieldName];

      await registeredProgramQuery.update({
        governmentIds: updatedGovernmentIds,
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
    }
  };
};

export const resendScholarConsentForm = (data) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      await axios.get('/scholarConsentFormFunctions/resendScholarConsentForm', {
        params: data,
      });

      dispatch(fetchRegisteredProgram(data.id));
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
    }
  };
};

export const uploadRequiredDocumentForm = (
  id,
  file,
  title,
  additionalDocument,
  completed = false
) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      let fileExtension = '';

      if (file.type === 'image/jpeg') {
        fileExtension = '.jpeg';
      }

      if (file.type === 'image/png') {
        fileExtension = '.png';
      }

      if (file.type === 'application/pdf') {
        fileExtension = '.pdf';
      }

      if (file) {
        const fileName = cuid() + fileExtension;
        const originalFileName = file.name;

        const path = `registeredPrograms/${id}/requiredDocumentForms`;
        const options = {
          name: fileName,
        };

        let uploadedFile = await firebase.uploadFile(path, file, null, options);

        let downloadURL =
          await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();
        const registeredProgram = await registeredProgramQuery.get();

        if (additionalDocument) {
          await registeredProgramQuery.update({
            requiredDocuments: registeredProgram.data().requiredDocuments
              ? {
                  ...registeredProgram.data().requiredDocuments,
                  additionalDocuments: registeredProgram.data()
                    .requiredDocuments.additionalDocuments
                    ? [
                        ...registeredProgram.data().requiredDocuments
                          .additionalDocuments,
                        {
                          fileURL: downloadURL,
                          fileName: fileName,
                          originalFileName: originalFileName,
                        },
                      ]
                    : [
                        {
                          fileURL: downloadURL,
                          fileName: fileName,
                          originalFileName: originalFileName,
                        },
                      ],
                }
              : {
                  additionalDocuments: [
                    {
                      fileURL: downloadURL,
                      fileName: fileName,
                      originalFileName: originalFileName,
                    },
                  ],
                },
          });
        } else {
          // add image name and URL to firestore
          await registeredProgramQuery.update({
            requiredDocuments: registeredProgram.data().requiredDocuments
              ? {
                  ...registeredProgram.data().requiredDocuments,
                  [title]: {
                    fileURL: downloadURL,
                    fileName: fileName,
                    originalFileName: originalFileName,
                  },
                }
              : {
                  [title]: {
                    fileURL: downloadURL,
                    fileName: fileName,
                    originalFileName: originalFileName,
                  },
                },
            requiredDocumentationFormUploaded: completed,
          });
        }
      }

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const deleteRequiredDocumentForm = (
  id,
  fileName,
  title,
  additionalDocument
) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      // DELETE PHOTO FROM STORAGE
      await firebase.deleteFile(
        `registeredPrograms/${id}/requiredDocumentForms/${fileName}`
      );
      const registeredProgram = await registeredProgramQuery.get();
      const requiredDocuments = registeredProgram.data().requiredDocuments;
      if (additionalDocument) {
        await registeredProgramQuery.update({
          requiredDocuments: {
            ...requiredDocuments,
            additionalDocuments: requiredDocuments.additionalDocuments.filter(
              (additionalDocument) => additionalDocument.fileName !== fileName
            ),
          },
        });
      } else {
        delete requiredDocuments[title];
        await registeredProgramQuery.update({
          requiredDocuments: requiredDocuments,
          requiredDocumentationFormUploaded: false,
        });
      }

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
    }
  };
};

export const submitRequiredDocumentForm = (id) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      const registeredProgram = await registeredProgramQuery.get();
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        requiredDocumentationFormUploaded: true,
        logs: !registeredProgram.data()?.requiredDocumentationFormUploaded
          ? [
              ...registeredProgram.data()?.logs,
              {
                event: 'Required Pre-Arrival Forms Completed',
                date: getUnixTime(new Date()),
              },
            ]
          : [...registeredProgram.data()?.logs],
      });

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateStudentQuestionnaireForm = (id, values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        studentQuestionnaireFormDetails: {
          ...values,
        },
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const submitStudentQuestionnaireForm = (id, values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      const registeredProgram = await registeredProgramQuery.get();
      let editiedAt = getUnixTime(new Date());
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        studentQuestionnaireFormCompleted: true,
        studentQuestionnaireFormDetails: {
          ...values,
        },
        logs: !registeredProgram.data()?.studentQuestionnaireFormCompleted
          ? [
              ...registeredProgram.data()?.logs,
              {
                event: 'Student Questionnaire Form Completed',
                date: getUnixTime(new Date()),
              },
            ]
          : [...registeredProgram.data()?.logs],
      });

      dispatch(fetchRegisteredProgram(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

// NO PAYMENT NEEDED
export const updateProgramPacketDownloaded = (id) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      let editiedAt = getUnixTime(new Date());
      const registeredProgram = await registeredProgramQuery.get();
      await registeredProgramQuery.update({
        editiedAt: editiedAt,
        programPacketDownloaded: true,
        logs: [
          ...registeredProgram.data()?.logs,
          {
            event: 'Program Packet Downloaded',
            date: getUnixTime(new Date()),
          },
        ],
      });

      dispatch(fetchRegisteredProgram(id));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
