import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, MenuItem } from "@mui/material";
import Sidebar from "../../../../ui/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { combineValidators, isRequired } from "revalidate";
import TextInput from "../../../../common/form/TextInput";
import SelectInput from "../../../../common/form/SelectInput";
import TextEditor from "../../../../common/TextEditor/TextEditor";
import { addTemplate } from "../../../../store/actions/adminActions/settingsActions/settingsActions";
import TemplateLookups from "../templates/TemplateTypeLookup";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
}));

const validate = combineValidators({
  templateName: isRequired({ message: "Name is required" }),
  templateSubject: isRequired({ message: "Subject is required" }),
  templateCategory: isRequired({ message: "Category is required" }),
  templateReplyTo: isRequired({ message: "Reply To is required" }),
});

const actions = {
  addTemplate,
};

const AdminAddTemplate = ({ addTemplate, handleSubmit, submitting }) => {
  const classes = useStyles();

  const templates = TemplateLookups.templates;

  const history = useHistory();

  const [editorValue, setEditorValue] = useState("");

  const handleChange = (content, editor) => {
    setEditorValue(content);
  };

  const handleAddTemplate = async (values) => {
    await addTemplate(values, editorValue);
    history.push("/admin/settings/templates");
  };

  return (
    <Grid container>
      <Grid item md={2}>
        <Sidebar />
      </Grid>

      <Grid item md={10} style={{ paddingLeft: "1.5em" }}>
        <Grid item container direction={"column"}>
          <Grid item>
            <Typography variant={"h4"}>New Template</Typography>
          </Grid>

          <form autoComplete={"off"} onSubmit={handleSubmit(handleAddTemplate)}>
            {/*INPUT CONTAINER*/}
            <Grid item container style={{ marginTop: "2em" }}>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                {/*BUTTON CONTAINER*/}
                <Grid item>
                  <Grid item container>
                    <Grid item style={{ marginLeft: "auto" }}>
                      <Button
                        size={"small"}
                        variant={"outlined"}
                        color={"primary"}
                        className={classes.button}
                        type={"submit"}
                        disabled={submitting}
                      >
                        Create
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size={"small"}
                        variant={"outlined"}
                        color={"secondary"}
                        className={classes.button}
                        onClick={() =>
                          history.push("/admin/settings/templates")
                        }
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item className={classes.fieldWrapper}>
                  <Field
                    name={"templateName"}
                    label={"Template Name"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                  />
                </Grid>
                <Grid item className={classes.fieldWrapper}>
                  <Field
                    name={"templateSubject"}
                    label={"Subject"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                  />
                </Grid>

                <Grid item className={classes.fieldWrapper}>
                  <Field
                    name={"templateCategory"}
                    label={"Template Category"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                  />
                </Grid>

                <Grid item className={classes.fieldWrapper}>
                  <Field
                    name={"templateFromName"}
                    label={"From Name"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                  />
                </Grid>
                <Grid item className={classes.fieldWrapper}>
                  <Field
                    name={"templateReplyTo"}
                    label={"Reply To"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/*<Grid item container style={{ marginTop: "2em" }}>*/}
            {/*  <Grid item>MERGE TAGS HERE</Grid>*/}
            {/*</Grid>*/}

            <Grid item container style={{ marginTop: "2em" }}>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <TextEditor
                  editorValue={editorValue}
                  handleChange={handleChange}
                />
              </Grid>
            </Grid>

            {/*BUTTON CONTAINER*/}
            <Grid item container style={{ marginTop: "2em" }}>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <Grid item>
                  <Grid item container>
                    <Grid item style={{ marginLeft: "auto" }}>
                      <Button
                        size={"small"}
                        variant={"outlined"}
                        color={"primary"}
                        className={classes.button}
                        type={"submit"}
                        disabled={submitting}
                      >
                        Create
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size={"small"}
                        variant={"outlined"}
                        color={"secondary"}
                        className={classes.button}
                        onClick={() =>
                          history.push("/admin/settings/templates")
                        }
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(
  null,
  actions
)(reduxForm({ form: "addTemplate", validate })(AdminAddTemplate));
