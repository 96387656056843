import React, { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Grid,
  Typography,
  Button,
  AppBar,
  Toolbar,
  InputBase,
  alpha,
  Box,
  IconButton,
  Badge,
  Avatar,
  Menu,
  MenuItem,
  Drawer,
} from '@mui/material';
import { connect } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import { useHistory } from 'react-router-dom';
import { logout } from '../../store/actions/authActions/authActions';
import { openDialog } from '../../store/actions/dialogActions/dialogActions';
import navLogo from '../../assets/navBar/navLogoLarge.png';
import { SearchIcon } from './icons';
import { DropDownIcon } from '../sidebar/icons';

import { makeStyles } from '@mui/styles';

const actions = {
  logout,
  openDialog,
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  admin: state.firebase.profile.admin,
});

const useStyles = makeStyles((theme) => ({
  appBarRoot: {
    backgroundColor: theme.palette.common.white,
    backgroundColor: '#fff',
    borderBottom: '1px solid #E2E2EA',
    fontSize: '18px',
    boxShadow: '3px 5px 10px #888888',
  }, 
  logo: {
    width: '120px',
    height: '120px',
    cursor: 'pointer',
    margin: '5px 5px 5px 5px',
  },
  routes: {
    margin: '5px 5px 5px 5px',
    fontSize: '18px',
  },
  routess: {
    // margin: '5px 5px 5px 5px',
    fontSize: '18px',
    paddingTop: '20px',
    marginLeft: '12px',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: '#92929D',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  avatarMenuButton: {
    textTransform: 'capitalize',
    '& .MuiButton-label': {
      gap: '.75rem',
    },
  },
  navText: {
    fontFamily: 'Poppins',
    fontSize: '23px',
    fontWeight: 700,
    lineHeight: '21px',
    letterSpacing: '0.1px',
    color: '#696974',
    cursor: 'pointer',
  },
  navTextSelected: {
    fontFamily: 'Poppins',
    fontSize: '23px',
    fontWeight: 700,
    lineHeight: '21px',
    letterSpacing: '0.1px',
    color: '#FF9900',
    cursor: 'pointer',
  },
  button: {
    width: '140px',
    height: '42px',
    fontSize: 'medium',
    borderRadius: '10px',
    backgroundColor: '#35BA35',
    color: 'white',
    fontWeight: 700,
    borderRadius: '50.25rem',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#35BA35',
      color: 'white',
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: 1,
      fontSize: '12px',
    },
  },

  tabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.common.white,
    },
  },
  tab: {
    ...theme.typography.tab,
    minWidth: 10,
    marginLeft: '20px',
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.white,
      opacity: 1,
    },
  },
  drawer: {
    backgroundColor: theme.palette.primary.main,
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: '1em',
    [theme.breakpoints.down('md')]: {
      marginBottom: '1em',
    },
  },
  drawerItem: {
    ...theme.typography.tab,
    color: 'white',
    opacity: '0.7',
  },

  drawerItemSelected: {
    '& .MuiListItemText-root': {
      opacity: 1,
    },
  },

  drawerIconContainer: {
    // marginLeft: "auto",
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  drawerIcon: {
    color: theme.palette.common.white,
    height: '30px',
    width: '30px',
  },

  appBar: {
    zIndex: theme.zIndex.modal + 1,
  },

  tabEnd: {
    ...theme.typography.tab,
    marginRight: '25px',
  },
  userContainer: {
    marginRight: '1em',
  },
  userInfoContainer: {
    marginRight: '0.7em',
  },
  userName: {
    color: theme.palette.common.white,
    fontWeight: 600,
  },
  route: {
    marginLeft: '24px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const Header = ({ auth, profile, logout, openDialog }) => {
  const matches = useMediaQuery('(min-width:490px)');
  const matchesBox = useMediaQuery('(min-width:960px)');
  const classes = useStyles();

  const history = useHistory();
 /// console.log(profile, 'profile');
 /// console.log(matches, 'matches');

  const [authenticated, setAuthenticated] = useState(false);
  // const [isApplicant, setIsApplicant] = useState(false);
  // const [isAdmin, setIsAdmin] = useState(false);
  // const [isReviewer, setIsReviewer] = useState(false);

  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const routes = [
    { name: 'About', link: '/about', selectedIndex: 0, activeIndex: 0 },
    { name: 'Programs', link: '/program', selectedIndex: 1, activeIndex: 1 },
  ];

  const applicantRoutes = [
    { name: 'About', link: '/about', selectedIndex: 0, activeIndex: 0 },
    { name: 'Programs', link: '/program', selectedIndex: 1, activeIndex: 1 },
    {
      name: 'Dashboard',
      link: '/applicant/dashboard',
      selectedIndex: 2,
      activeIndex: 2,
    },
    {
      name: 'Profile',
      link: `/applicant/profile/view?id=${profile.uid}`,
      selectedIndex: 3,
      activeIndex: 3,
    },
    {
      name: 'FAQs',
      link: `/applicant/faqs/view?id=${profile.uid}`,
      selectedIndex: 4,
      activeIndex: 4,
    },
  ];

  const adminRoutes = [
    { name: 'About', link: '/about', selectedIndex: 0, activeIndex: 0 },
    { name: 'Programs', link: '/program', selectedIndex: 1, activeIndex: 1 },
    {
      name: 'Dashboard',
      link: '/admin/dashboard',
      selectedIndex: 2,
      activeIndex: 2,
    },
    {
      name: 'Profile',
      link: `/applicant/profile/view?id=${profile.uid}`,
      selectedIndex: 3,
      activeIndex: 3,
    },
  ];

  const reviewerRoutes = [
    { name: 'About', link: '/about', selectedIndex: 0, activeIndex: 0 },
    // { //name: "Programs", link: "/program", selectedIndex: 1, activeIndex: 1 },
    {
      name: 'Dashboard',
      link: '/reviewer/dashboard',
      selectedIndex: 2,
      activeIndex: 2,
    },
    {
      name: 'Profile',
      link: `/applicant/profile/view?id=${profile.uid}`,
      selectedIndex: 3,
      activeIndex: 3,
    },
  ];

  useEffect(() => {
    if (auth.isLoaded) {
      if (auth.isEmpty) {
        setAuthenticated(false);
      } else {
        setAuthenticated(true);
      }
    }
  }, [auth, profile]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // const handleChange = (e, newValue) => {
  //   setValue(newValue);
  // };

  const handleSignIn = () => {
    openDialog('LoginDialog');
  };

  const handleSignOut = () => {
    // handleUserMenuClose()
    logout();
    // setAuthenticated(false);
    // setIsApplicant(false)
    // setIsAdmin(false);
    history.push('/');
  };

  const handleRegister = () => {
    openDialog('RegisterDialog');
  };
 /// console.log(matches, 'matches');
  return (
    <>
      {/* Mobile Header */}

      {/* Desktop Header */}
      {matches && (
        <AppBar className={classes.appBarRoot} position="static" elevation={0}>
          <Toolbar>
            {/* images  */}
            <img
              style={{
                width: `${matches ? '' : '90px'}`,
                height: `${matches ? '' : '90px'}`,
                margin: `${matches ? '' : '20px 0px'}`,
              }}
              className={classes.logo}
              src={navLogo}
              alt="LEAD Logo"
              onClick={() => history.push(`/about`)}
            />
            {/*UN AUTH* midddddddlllllle */}

            {auth.isLoaded === true &&
              auth.isEmpty === true &&
              routes.map((route) => (
                <div className={classes.route} key={route.name}>
                  <h1
                    className={
                      route.selectedIndex === value
                        ? classes.navTextSelected
                        : classes.navText
                    }
                    onClick={() => {
                      history.push(route.link);
                      setValue(route.selectedIndex);
                    }}
                  >
                    {route.name}
                  </h1>
                </div>
              ))}

            {/*ADMIN*/}
            {(profile.isAdmin || profile.isAdminViewer) &&
              adminRoutes.map((route) => (
                <Grid className={classes.route} item key={route.name}>
                  <Typography
                    className={
                      route.selectedIndex === value
                        ? classes.navTextSelected
                        : classes.navText
                    }
                    onClick={() => {
                      history.push(route.link);
                      setValue(route.selectedIndex);
                    }}
                  >
                    {route.name}
                  </Typography>
                </Grid>
              ))}
            {/*REVIEWER*/}
            {profile.isReviewer &&
              reviewerRoutes.map((route) => (
                <Grid className={classes.route} item key={route.name}>
                  <Typography
                    className={
                      route.selectedIndex === value
                        ? classes.navTextSelected
                        : classes.navText
                    }
                    onClick={() => {
                      history.push(route.link);
                      setValue(route.selectedIndex);
                    }}
                  >
                    {route.name}
                  </Typography>
                </Grid>
              ))}
            {/*APPLICANT*/}
            {profile.isApplicant &&
              applicantRoutes.map((route) => (
                <Grid className={classes.route} item key={route.name}>
                  <Typography
                    className={
                      route.selectedIndex === value
                        ? classes.navTextSelected
                        : classes.navText
                    }
                    onClick={() => {
                      history.push(route.link);
                      setValue(route.selectedIndex);
                    }}
                  >
                    {route.name}
                  </Typography>
                </Grid>
              ))}

            <Box flexGrow={1} />
            <Grid item>
              <div className="info-btns">
                <Grid container alignItems="center">
                  {!authenticated && (
                    <Button
                      style={{
                        width: `${matches ? '' : '78px'}`,
                        height: `${matches ? '' : '29px'}`,
                        margin: `${matches ? '' : '11px'}`,
                        marginRight: '10px',
                      }}
                      variant={'contained'}
                      className={classes.button}
                      onClick={() => handleRegister()}
                    >
                      Request Info
                    </Button>
                    /*  <Grid
                    style={{ flexDirection: `${matches ? '' : 'column'}` }}
                    item
                  >
                    <span
                      style={{
                        fontSize: `${matches ? '' : '14px'}`,
                        paddingRight: `${matches ? '1em' : ''}`,
                        cursor: `${matches ? 'pointer' : ''}`,
                      }}
                      className={classes.navText}
                      onClick={() => handleRegister()}
                    >
                      Request Information!
                    </span>
                  </Grid>*/
                  )}

                  <Grid item>
                    {!authenticated && (
                      <Button
                        style={{
                          width: `${matches ? '' : '78px'}`,
                          height: `${matches ? '' : '29px'}`,
                          margin: `${matches ? '' : '11px'}`,
                        }}
                        variant={'contained'}
                        className={classes.button}
                        onClick={() => handleSignIn()}
                      >
                        Sign In
                      </Button>
                    )}
                    {authenticated && (
                      <>
                        {/* <IconButton
                    aria-label="show 17 new notifications"
                    color="inherit"
                  >
                    <Badge badgeContent={17} color="secondary">
                      <NotificationsIcon htmlColor="#92929D" />
                    </Badge>
                  </IconButton> */}
                        <Button
                          style={{ fontSize: `${matches ? '' : '13px'}` }}
                          onClick={handleMenuOpen}
                          className={classes.avatarMenuButton}
                          variant="text"
                        >
                          <Avatar
                            style={{
                              width: `${matches ? '' : '30px'}`,
                              height: `${matches ? '' : '30px'}`,
                              textAlign: `${matchesBox ? '' : 'end'}`,
                              marginRight: '10px',
                            }}
                            alt={profile.fullName}
                            src={profile.profileImageURL}
                          >
                            {profile.fullName}
                          </Avatar>
                          <Typography
                            style={{
                              margin: `${matches ? '' : '14px 0'}`,
                              fontSize: `${matches ? '' : '13px'}`,
                              marginRight: '10px',
                            }}
                            className={classes.navText}
                          >
                            {profile.fullName}
                          </Typography>
                          <DropDownIcon />
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              handleMenuClose();
                              history.push(
                                `/applicant/profile/view?id=${profile.uid}`
                              );
                            }}
                          >
                            Profile
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleMenuClose();
                              handleSignOut();
                            }}
                          >
                            Logout
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Toolbar>
        </AppBar>
      )}
      {/* Drawer (Mobile Navigation) */}
      {!matches && (
        <AppBar className={classes.appBarRoot} position="static" elevation={0}>
          <Toolbar>
            <img
              style={{
                width: `${matches ? '' : '50px'}`,
                height: `${matches ? '' : '50px'}`,
                marginRight: 'auto',

                // margin: `${matches ? '' : '2px 0'}`,
              }}
              className={classes.logo}
              src={navLogo}
              alt="LEAD Logo"
              onClick={() => history.push(`/about`)}
            />
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
              style={{
                marginLeft: 'auto',
              }}
            >
              <MenuIcon
                style={{
                  backgroundColor: '#35BA35',
                  width: '30px',
                  borderRadius: '8px',
                  padding: '3px',
                }}
              />
            </IconButton>
            <Drawer
              anchor="left"
              style={{
                width: '200px',
              }}
              open={drawerOpen}
              onClose={handleDrawerClose}
              // className={classes.drawer}
            >
              <img
                style={{
                  width: `${matches ? '' : '90px'}`,
                  height: `${matches ? '' : '90px'}`,
                  margin: `${matches ? '' : '12px 12px'}`,
                }}
                className={classes.logo}
                src={navLogo}
                alt="LEAD Logo"
                onClick={() => history.push(`/about`)}
              />
              <div>
                {/* this route only show when someone is not login */}
                {!authenticated &&
                  routes.map((route) => (
                    <div className={classes.routes} key={route.name}>
                      <p
                        onClick={() => {
                          history.push(route.link);
                          setValue(route.selectedIndex);
                        }}
                      >
                        <hr />
                        {route.name}
                      </p>
                    </div>
                  ))}
              </div>
              {/* <div className={classes.toolbarMargin} /> */}

              {/* {routes.map((route) => (
            <div className={classes.route} key={route.name}>
              <h1
                onClick={() => {
                  history.push(route.link);
                  setValue(route.selectedIndex);
                }}
              >
                {route.name}
              </h1>
            </div>
          ))} */}

              {/*ADMIN*/}
              {(profile.isAdmin || profile.isAdminViewer) &&
                adminRoutes.map((route) => (
                  <Grid className={classes.routess} item key={route.name}>
                    <Typography
                      className={
                        route.selectedIndex === value
                          ? classes.navTextSelected
                          : classes.navText
                      }
                      onClick={() => {
                        history.push(route.link);
                        setValue(route.selectedIndex);
                      }}
                    >
                      <div>{route.name}</div>
                    </Typography>
                  </Grid>
                ))}

              {/*REVIEWER*/}
              {profile.isReviewer &&
                reviewerRoutes.map((route) => (
                  <Grid
                    // className={classes.route}
                    item
                    key={route.name}
                  >
                    <Typography
                      className={
                        route.selectedIndex === value
                          ? classes.navTextSelected
                          : classes.navText
                      }
                      onClick={() => {
                        history.push(route.link);
                        setValue(route.selectedIndex);
                      }}
                    >
                      {route.name}
                    </Typography>
                  </Grid>
                ))}
              {/*APPLICANT   this route only show to applicant*/}
              {profile.isApplicant &&
                applicantRoutes.map((route) => (
                  <Grid item key={route.name} className={classes.routess}>
                    <Typography
                      className={
                        route.selectedIndex === value
                          ? classes.navTextSelected
                          : classes.navText
                      }
                      onClick={() => {
                        history.push(route.link);
                        setDrawerOpen(false);
                        setValue(route.selectedIndex);
                      }}
                    >
                      {route.name}
                    </Typography>
                  </Grid>
                ))}
              <Box flexGrow={1} />

              <Grid container alignItems="center">
                <Grid item>
                  {!authenticated && (
                    <Button
                      style={{
                        width: `${matches ? '' : '120px'}`,
                        height: `${matches ? '' : '29px'}`,
                        margin: `${matches ? '' : '11px'}`,
                      }}
                      variant={'contained'}
                      className={classes.button}
                      onClick={() => handleRegister()}
                    >
                      Request Info
                    </Button>
                  )}

                  <Grid item>
                    {!authenticated && (
                      <Button
                        style={{
                          width: `${matches ? '' : '120px'}`,
                          height: `${matches ? '' : '29px'}`,
                          margin: `${matches ? '' : '11px'}`,
                        }}
                        variant={'contained'}
                        className={classes.button}
                        onClick={() => handleSignIn()}
                      >
                        Sign In
                      </Button>
                    )}
                    {authenticated && (
                      <>
                        {/* <IconButton
                    aria-label="show 17 new notifications"
                    color="inherit"
                  >
                    <Badge badgeContent={17} color="secondary">
                      <NotificationsIcon htmlColor="#92929D" />
                    </Badge>
                  </IconButton> */}
                        <Button
                          style={{ fontSize: `${matches ? '' : '13px'}` }}
                          onClick={handleMenuOpen}
                          className={classes.avatarMenuButton}
                          variant="text"
                        >
                          <Avatar
                            style={{
                              width: `${matches ? '' : '30px'}`,
                              height: `${matches ? '' : '30px'}`,
                              textAlign: `${matchesBox ? '' : 'end'}`,
                              marginRight: '10px',
                            }}
                            alt={profile.fullName}
                            src={profile.profileImageURL}
                          >
                            {profile.fullName}
                          </Avatar>
                          <Typography
                            style={{
                              margin: `${matches ? '' : '14px 0'}`,
                              fontSize: `${matches ? '' : '13px'}`,
                              marginRight: '10px',
                            }}
                            className={classes.navText}
                          >
                            {profile.fullName}
                          </Typography>
                          <DropDownIcon />
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              handleMenuClose();
                              history.push(
                                `/applicant/profile/view?id=${profile.uid}`
                              );
                            }}
                          >
                            Profile
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleMenuClose();
                              handleSignOut();
                            }}
                          >
                            Logout
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Drawer>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default connect(mapStateToProps, actions)(Header);
