import React from 'react';

export const StarIcon = (props) => {
    return (
        <svg
            width={32}
            height={30}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="m16 0 3.592 11.401h11.625l-9.405 7.047 3.593 11.4L16 22.803 6.595 29.85l3.593-11.401L.783 11.4h11.625L16 0Z"
                fill="#EAE224"
            />
        </svg>
    );
};
