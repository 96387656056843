import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../../asyncActions/asyncActions";
import {
  fromUnixTime
} from "date-fns";
import {
  FETCH_ALL_ARCHIVED_APPLICANTS
} from "../../../constants/adminConstants/archivedConstants/archivedConstants";

export const fetchAllArchivedApplications = () => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramsQuery = firestore
      .collection("registeredPrograms")
      .where("archive", "==", true);

    try {
      dispatch(asyncActionStart());
      let query = await registeredProgramsQuery.get();
      let allArchivedApplicants = [];

      for (let i = 0; i < query.docs.length; i++) {
        const programQuery = firestore
        .collection("programs")
        .doc(`${query.docs[i].data()?.programId}`);
  
        let queryProgram = await programQuery.get();

        let archivedApplicant = {
          ...query.docs[i].data(),
          id: query.docs[i].id,
          programName: queryProgram.data()?.programName
        };
        allArchivedApplicants.push(archivedApplicant);
      }

      if (allArchivedApplicants?.length) {
        allArchivedApplicants = allArchivedApplicants.sort((a, b) => {
          return (
            new Date(fromUnixTime(b.createdAt)) - new Date(fromUnixTime(a.createdAt))
          );
        });
      }

      dispatch({
        type: FETCH_ALL_ARCHIVED_APPLICANTS,
        payload: { allArchivedApplicants },
      });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
