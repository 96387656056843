import React, { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Box, Grid } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import StartIcon from '@mui/icons-material/Start';
// import EditNoteIcon from '@mui/icons-material/EditNote';
import DrawIcon from '@mui/icons-material/Draw';
import news4 from '../assets/landingPage/news1_1.png';
import news5 from '../assets/landingPage/news2_2.png';
import news6 from '../assets/landingPage/news3_3.png';
import gal1 from '../assets/landingPage/gallery/gal1.png';
import gal2 from '../assets/landingPage/gallery/gal2.png';
import gal3 from '../assets/landingPage/gallery/gal3.png';
import gal4 from '../assets/landingPage/gallery/gal4.png';
import gal5 from '../assets/landingPage/gallery/gal5.png';
import gal6 from '../assets/landingPage/gallery/gal6.png';
import gal7 from '../assets/landingPage/gallery/gal7.png';
import gal8 from '../assets/landingPage/gallery/gal8.png';
import gal9 from '../assets/landingPage/gallery/gal9.png';
import gal10 from '../assets/landingPage/gallery/gal10.png';

import completeHighschool from '../assets/landingPage/completeHighschool.svg';
import acceptedIntoCollege from '../assets/landingPage/acceptedIntoCollege.svg';
import completeCollege from '../assets/landingPage/completeCollege.svg';
import timeGroup from '../assets/landingPage/timeGroup.svg';

import seperator from '../assets/landingPage/seperator.png';
import yearlyBanner from '../assets/landingPage/GSLI2024Banner.jpeg';

import Typography from '@mui/material/Typography';
import PercentageItem from '../ui/landingPage/PercentageItem';
import ScholarProgramsItem from '../ui/landingPage/ScholarProgramsItem';
import NewsAnnouncmentItem from '../ui/landingPage/NewsAnnouncmentItem';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { connect } from 'react-redux';
import { fetchAllCategories } from '../store/actions/adminActions/categoryActions/adminCategoryActions';
import { fetchViewCarouselSettingsForBanner } from '../store/actions/adminActions/settingsActions/settingsActions';
// import leadHeader from '../assets/landingPage/leadHeaderRevised.png'
import leadHeader from '../assets/landingPage/leadHeader.png';
import leadHeader2023 from '../assets/landingPage/leadHeader2023.jpg';
import learnerBannerDefault from '../assets/landingPage/collegeKids.jpeg';
import learnerBanner from '../assets/landingPage/LFL 6.jpg';
//import learnerBanner from "../assets/landingPage/girlwithfriends.jpeg";
//import learnerBanner from "../assets/landingPage/kids_large.jpg";
import leadHeaderRevised from '../assets/landingPage/leadHeaderRevised.png';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import './About.css';

import Carousel from 'react-material-ui-carousel';
import CustomCarousel from '../common/carousel/carousel';
import { openDialog } from '../store/actions/dialogActions/dialogActions';

import { fetchAllPrograms } from '../store/actions/adminActions/programActions/adminProgramActions';

const useStyles = makeStyles((theme) => ({
  headlineContainer: {
    height: '804px',
    left: '-2px',
    top: '96px',
    background: 'linear-gradient(225.34deg, #65A65A 0%, #3178A5 101.17%)',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },

  overlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '50%',
    // left: '5%', // Adjust the left position as needed
    transform: 'translateY(-50%)',
    //backgroundColor: 'rgba(0, 0, 0, 0.4)', // Adjust overlay color as needed
    padding: theme.spacing(2), // Add padding as needed
    color: 'white', // Text color
    fontSize: '24px', // Adjust the font size as needed
    fontWeight: 'bold', // Adjust the font weight as needed
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Text shadow (optional)
    // zIndex: 1,
    background:
      'transparent linear-gradient(90deg,rgba(0,0,0,.5529411765),rgba(26,26,26,.5019607843) 67%,rgba(36,36,36,.1803921569)) 0 0 no-repeat padding-box',
    fontFamily:
      "var(--rds-font-family,Arial,Helvetica,'Nimbus Sans L','Liberation Sans',FreeSans,sans-serif)",
  },
  image: {
    width: '100%',
    height: 'inherit', // Adjust the height as needed
    objectFit: 'cover',
    objectPosition: 'top',
  },
  imageContainer: {
    position: 'relative',
    height: '700px',
    [theme.breakpoints.down('sm')]: {
      height: '500px !Important',
    },
  },
  fixedBottom: {
    [theme.breakpoints.down('sm')]: {
      bottom: '0',
      position: 'fixed',
      right: '0',
      left: '0',
      zIndex: '1030',
      backgroundColor: '#35BA35',
      height: '55px',
    },
  },
  bottomSpace: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0.5rem!important',
      marginTop: '0.5rem!important',
    },
  },
  bottomSpaceContainer: {
    [theme.breakpoints.down('sm')]: {
      height: '40px',
      // display: "block",
      alignItems: 'center',
      display: 'grid',

      textTransform: 'none',
      '&:hover': {
        borderColor: '#218221',
        backgroundColor: '#3ba83b',
        borderRadius: '50.25rem',
        color: '#fff',
      },
    },
  },
  bottomText: {
    fontSize: '14px',
    lineHeight: '1.4',
    padding: '4px 8px',
    color: '#fff',
    fontWeight: '700!important',
    textAlign: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    alignItems: 'center',
    display: 'flex',
  },

  browseButton: {
    width: '190px',
    height: '58px',
    fontSize: '20px',
    fontWeight: 600,
    borderRadius: '50.25rem',
    backgroundColor: '#35BA35',
    marginLeft: '10px',
    color: 'white',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#35BA35',
      color: 'white',
    },
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex !Important',
      fontSize: '18px !Important',
      lineHeight: '1',
      height: '48px',
    },
  },
  formContainer: {
    position: 'absolute',
    top: '50%',
    // Adjust the left position as needed
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      left: '50% !Important',
      top: '70% !Important',
      marginTop: '50px !Important',
      height: '80px !important',
      width: '50% !important',
    },
  },
  dropdown: {
    marginRight: theme.spacing(1), // Add margin as needed
    width: '600px', // Adjust the width as needed
    backgroundColor: 'white',
    borderRadius: '.25rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    // left: '35%',
  },
  overlaySubText: {
    position: 'absolute',

    top: '55%',
    left: '37%',
    //width: "80%",
    fontWeight: '700',
    fontSize: '20px',
    color: 'white',
    paddingBottom: '20px',
    transform: 'translate(-50%,-50%)',
    msTransform: 'translate(-50%,-50%)',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
  },
  overlayText: {
    position: 'absolute',
    top: '40%',
    left: '30%',
    width: '50%',
    fontWeight: '700',
    fontSize: '62px',
    paddingBottom: '20px',
    color: 'white',
    transform: 'translate(-50%,-50%)',
    msTransform: 'translate(-50%,-50%)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '35px !important',
      left: '0',
      transform: 'translate(0,0)',
      width: '100%',
      textAlign: 'center',
      lineHeight: '45px !important',
    },
    [theme.breakpoints.down('md')]: {
      // left: "35% !Important",
      // width: "100% !Important",
      fontSize: '42px',
    },
  },
  headlineText: {
    color: 'white',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '48px',
    lineHeight: '67.58px',
    letter: '0.2px',
  },
  headlineSubText: {
    color: 'white',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '18px',
    lineHeight: '28px',
    letter: '0.1px',
  },

  image26: {
    marginTop: '54px',
    borderRadius: '10px',
    width: '60%',
    height: '203px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  seperator: {
    [theme.breakpoints.down('sm')]: {
      height: '3px',
    },
  },
  image27: {
    marginLeft: '100px',
    marginTop: '-60px',
    borderRadius: '10px',
    width: '60%',
    height: '323px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0px',
    },
  },
  image261: {
    marginTop: '-50px',
    borderRadius: '10px',

    width: '60%',
    height: '250px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  programsHeaderContainer: {
    marginTop: '0px',
  },
  bodyContainer: {
    // marginLeft: '30px',
    // marginRight: '30px'
  },

  photosContainer: {
    marginTop: '0px',
    backgroundColor: 'rgba(120, 90, 164, .15)',
  },

  scholarProgramsHeader: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontSize: '36px',
    fontWeight: '600',
    lineHeight: '54px',
    letterSpacing: '0.14999991655349731px',
    textAlign: 'center',
    paddingTop: '40px',
  },
  programsContainer: {
    marginTop: '44px',
    marginBottom: '40px',
    paddingLeft: '2em',
    paddingRight: '2em',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '1em',
      paddingRight: '1em',
    },
  },
  infoContainer: {
    marginTop: '169px',
    height: '922px',
    background: 'linear-gradient(225.34deg, #DD13DD 0%, #1323DD 101.17%)',
    mixBlendMode: 'normal',
  },

  newsContainer: {
    marginTop: '44px',
    marginBottom: '80px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '1em',
      paddingRight: '1em',
    },
    width: '85%',
    margin: '0 auto',
  },
  button: {
    width: '190px',
    height: '58px',
    fontSize: '20px',
    fontWeight: 600,
    borderRadius: '50.25rem',
    backgroundColor: '#35BA35',
    marginLeft: '10px',
    color: 'white',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#35BA35',
      color: 'white',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  newsCard: {
    width: '366px',
  },
  pageHeader: {
    width: '100%',
    height: '731px',
  },
}));

const actions = {
  fetchAllPrograms,
  fetchAllCategories,
  openDialog,
  fetchViewCarouselSettingsForBanner,
};

const mapStateToProps = (state) => {
  let categories = [];
  let programs = [];
  let auth = '';
  let profile = '';

  if (
    state.adminCategories.allCategories &&
    state.adminCategories.allCategories.length > 0
  ) {
    categories = state.adminCategories.allCategories;
  }
  if (state.firebase.auth) {
    auth = state.firebase.auth;
    profile = state.firebase.profile;
  }
  if (
    state.adminPrograms.allPrograms &&
    state.adminPrograms.allPrograms.length > 0
  ) {
    programs = state.adminPrograms.allPrograms;
  }

  return {
    categories,
    programs,
    auth,
    profile,
    showCarouselForBanner:
      state.adminSettings.settings?.[0]?.showCarouselForBanner,
    imagesForBanner: state.adminSettings.settings?.[0]?.imagesForBanner || [],
    imagesForNewsOrAnnouncements:
      state.adminSettings.settings?.[0]?.newsOrAnnouncements || [],
  };
};

const About = ({
  fetchAllPrograms,
  openDialog,
  fetchAllCategories,
  categories,
  auth,
  profile,
  showCarouselForBanner,
  fetchViewCarouselSettingsForBanner,
  imagesForBanner,
  imagesForNewsOrAnnouncements,
  programs,
}) => {
  const matches = useMediaQuery('(min-width:480px)');
  //   const matchesMax = useMediaQuery("(min-width:564px)");
  const classes = useStyles();
  const history = useHistory();
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (auth.isLoaded) {
      if (auth.isEmpty) {
        setAuthenticated(false);
      } else {
        setAuthenticated(true);
      }
    }
  }, [auth]);
  useEffect(() => {
    fetchAllCategories();
  }, [fetchAllCategories]);

  useEffect(() => {
    fetchAllPrograms();
  }, [fetchAllPrograms]);

  useEffect(() => {
    fetchViewCarouselSettingsForBanner();
  }, [fetchViewCarouselSettingsForBanner]);
  const [id, setId] = useState('');
  const handleSignIn = () => {
    openDialog('LoginDialog');
  };
  return (
    <Grid container direction={'column'}>
      {/*HEADLINE CONTAINER*/}
      <Grid
        item
        style={{ position: 'relative', height: '700px' }}
        className={classes.imageContainer}
      >
        {!showCarouselForBanner || !imagesForBanner.length ? (
          <img
            className={classes.image}
            src={imagesForBanner[0] || learnerBanner}
            alt="LEAD Logo"
          />
        ) : (
          <Carousel>
            {imagesForBanner.map((item, i) => (
              <img
                key={i}
                className={classes.image}
                src={item}
                alt="LEAD Logo"
              />
            ))}
          </Carousel>
        )}

        <Grid className={classes.overlay}>
          <Typography className={classes.overlayText}>
            LEADing For Life.
          </Typography>

          <Typography className={classes.overlaySubText}>
            Where students become lifelong learners, lifelong learners become
            lifelong leaders, and lifelong leaders transform the world
          </Typography>
        </Grid>

        <Grid
          item
          style={{
            marginTop: showCarouselForBanner ? '40px' : '64px',
            position: 'absolute',
            top: '75%',
            left: '45%',
            backgroundColor: '#191919',
            padding: '25px',
            height: '100px',
          }}
          className={classes.formContainer}
        >
          <TextField
            label="Select Program"
            select
            variant="outlined"
            className={classes.dropdown}
          >
            {programs &&
              programs
                ?.filter(
                  (prog) =>
                    prog?.hidefromStudent === false &&
                    new Date(prog.applicationDeadline) > new Date()
                  //  prog.sort(a,b) => (a.programName > b.programName) ? 1 : -1)
                )

                ?.sort((a, b) => (a.programName >= b.programName ? 1 : -1))

                ?.map((pr, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      value={pr?.id}
                      onClick={() => setId(pr.id)}
                    >
                      <div>{pr?.programName}</div>
                    </MenuItem>
                  );
                })}
          </TextField>

          <Button
            title={id == '' ? 'Please Select Program ' : ''}
            variant={'contained'}
            className={classes.button}
            style={{
              borderRadius: '50.25rem',
              backgroundColor: '#35BA35',
              fontSize: '20px',
              fontWeight: 600,
              zIndex: '1',
            }}
            onClick={() =>
              id && history.push(`/program/view?id=${id}&register=true`)
            }
          >
            Apply Now
          </Button>

          <Button
            variant={'contained'}
            className={classes.browseButton}
            style={{
              borderRadius: '50.25rem',
              backgroundColor: '#35BA35',
              fontSize: '20px',
              fontWeight: 600,
              zIndex: '1',
            }}
            onClick={() =>
              history.push(
                //`/program/view?id=Fdonn7faxyP6pxMpaMPs&register=true`
                `/program`
              )
            }
          >
            Explore Programs
          </Button>
        </Grid>
      </Grid>
      <Grid style={{ border: '5px solid', borderColor: '#FF9900' }}></Grid>

      <Grid style={{ border: '5px solid', borderColor: '#FF9900' }}></Grid>

      <Grid item container className={classes.bodyContainer}>
        {/*PERCENTAGE CONTAINER*/}
        <Grid item container>
          <PercentageItem
            percentage={'100%'}
            text={'Of LEADing for Life graduates complete highschool'}
            img={completeHighschool}
            color={'#EA4D97'}
          />
          <PercentageItem
            percentage={'99.9%'}
            text={'of alumni are accepted into a college or university'}
            img={acceptedIntoCollege}
            color={'#0079A9'}
          />
          <PercentageItem
            percentage={'99.9%'}
            text={'of LEADing for Life graduates complete college'}
            img={completeCollege}
            color={'#27AE60'}
          />
          <PercentageItem
            percentage={'65%'}
            text={
              'of LEADing for Life graduates are accepted early into a college or university'
            }
            img={timeGroup}
            color={'#EF9A22'}
          />
        </Grid>

        <Grid item container className={classes.photosContainer}>
          <Grid item container direction={'column'} alignItems={'center'}>
            <Grid item>
              <Typography className={classes.scholarProgramsHeader}>
                One Moment Can Change the Future
              </Typography>
            </Grid>

            <Grid item style={{ marginTop: '21px', marginBottom: '40px' }}>
              <img className={classes.seperator} src={seperator} alt="" />
            </Grid>
          </Grid>

          {/*GALLERY PHOTOS*/}
          <Grid
            item
            container
            className={classes.galleryContainer}
            justifyContent={'center'}
          >
            {/*OLD*/}
            <Grid item>
              <Grid item container justifyContent={'center'}>
                <Grid item>
                  <img
                    style={{
                      width: `${matches ? '' : '72%'}`,
                      paddingLeft: `${matches ? '' : '0px'}`,
                    }}
                    src={gal1}
                    alt=""
                  />
                </Grid>
                <Grid item>
                  <Grid item container direction={'column'}>
                    <Grid item>
                      <img src={gal6} alt="" style={{ padding: '6px' }} />
                    </Grid>
                    <Grid item>
                      <img src={gal8} alt="" style={{ padding: '6px' }} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid item container direction={'column'}>
                    <Grid item>
                      <img src={gal7} alt="" style={{ padding: '6px' }} />
                    </Grid>
                    <Grid item>
                      <img src={gal9} alt="" style={{ padding: '6px' }} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            className={classes.galleryContainer}
            justifyContent={'center'}
          >
            <Grid item>
              <Grid item container justifyContent={'center'}>
                <Grid item>
                  <Grid item container direction={'column'}>
                    <Grid item>
                      <img src={gal10} alt="" style={{ padding: '6px' }} />
                    </Grid>
                    <Grid item>
                      <img src={gal3} alt="" style={{ padding: '6px' }} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid item container direction={'column'}>
                    <Grid item>
                      <img src={gal4} alt="" style={{ padding: '6px' }} />
                    </Grid>
                    <Grid item>
                      <img src={gal5} alt="" style={{ padding: '6px' }} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <img
                    style={{
                      width: `${matches ? '' : '72%'}`,
                      paddingLeft: `${matches ? '' : '0px'}`,
                    }}
                    src={gal2}
                    alt=""
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {!authenticated ? (
          <Grid
            container
            className={classes.fixedBottom}
            style={{ zIndex: '900!important' }}
          >
            <Grid item container className={classes.bottomSpace}>
              <Grid item xs={6} style={{ borderRight: '1px solid #218221' }}>
                <Grid className={classes.bottomSpaceContainer}>
                  <Button
                    className={classes.bottomText}
                    onClick={() => history.push('/program')}
                  >
                    <DrawIcon style={{ marginRight: '5px' }}></DrawIcon>
                    Apply Now
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid className={classes.bottomSpaceContainer}>
                  <a
                    className={classes.bottomText}
                    onClick={() => handleSignIn()}
                  >
                    <LoginIcon style={{ marginRight: '5px' }}></LoginIcon> Sign
                    In
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(About);
