import React, { useEffect, useMemo, useState } from 'react';
// import { makeStyles } from '@mui/styles';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { CloudDownload } from '@mui/icons-material';
import { format, parseISO } from 'date-fns';
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from '@mui/x-data-grid-premium';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import DialogActions from '@mui/material/DialogActions';
import { CustomToolbar, escapeRegExp } from '../../common/tableUtils';
import { useHistory } from 'react-router-dom';
import { downloadApplication } from '../../common/util';
import { makeStyles } from '@mui/styles';
import { fetchInstitution } from '../../store/actions/adminActions/institutionActions/adminInstitutionActions';

// const useStyles = makeStyles((theme) => ({}));

const RegisteredProgramsTable = ({ registeredPrograms, institute }) => {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [open, setOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      'e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
    );
  }, []);
  console.log(registeredPrograms, 'registeredPrograms');
  // console.log('application?.instituteDetails', application?.instituteDetails)
  useEffect(() => {
    const arr = registeredPrograms.map((application, index) => ({
      ...application,
      serialNo: index + 1,
      programName: application.programDetails?.programName,
      institutionName: application?.instituteDetails?.institutionName,
      institutionName2: application?.instituteDetails2?.institutionName,
      applicationDeadline:
        application.programDetails?.applicationDeadline &&
        format(
          parseISO(application.programDetails?.applicationDeadline),
          'EEE MMM do y'
        ),
      programStartDate:
        application.programDetails?.programStartDate &&
        format(
          parseISO(application.programDetails?.programStartDate),
          'EEE MMM do y'
        ),
    }));
    setRows(arr);
    console.log('rows', rows)
    if (searchText) {
      setSearchText(searchText);
      const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
    }
  }, [registeredPrograms]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filtered = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };
  useEffect(() => {
    fetchInstitution(registeredPrograms?.chosenInstitution2);
  }, [registeredPrograms?.chosenInstitution2]);
  const memoizedColumns = useMemo(() => {
    return [
      {
        field: 'serialNo',
        headerName: 'Id',
        width: 100,
      },

      {
        field: 'programName',
        headerName: 'Program',
        width: 300,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'institutionName',
        headerName: 'Placement 1',
        width: 180,
        valueGetter: (param) => param.value || 'N/A',
        renderCell: (param) =>
          param.row.notificationDateMet &&
            param.row.applicationAccepted &&
            param.row.applicationApproved
            ? param.value
            : 'N/A',
      },

      {
        field: 'institutionName2',
        headerName: 'Placement 2',
        width: 160,
        valueGetter: (param) => param.value || 'N/A',
        renderCell: (param) =>
          param.row.notificationDateMet &&
            param.row.applicationAccepted &&
            param.row.applicationApproved
            ? param.value
            : 'N/A',
      },
      /* {
        field: "programStatus",
        headerName: "Status",
        width: 180,
        valueGetter: (param) => param.value || "N/A",
        renderCell: (param) => (
          <>
            {param.value === "pending" && "Pending"}
            {param.value === "on-going" && "On-Going"}
            {param.value === "upcoming" && "Upcoming"}
            {param.value === "alumni" && "Alumni"}
            {param.value === "in-process" && "In Process"}
            {param.value === "admitted" && "Admitted"}
            {param.value === "closed" && "Closed"}
          </>
        ),
      },*/
      {
        field: 'programStage',
        headerName: 'Stage',
        width: 120,
        align: 'center',
        valueGetter: (param) => param.value || '-',
        renderCell: (param) => (
          <div
            style={{
              //approved = orange, admitted = blue, complete = green, reviewed = pink, withdrawn = red, denied = purple, default = gray in-progress = lavender
              borderColor:
                param.value === 'pre-application' && 'Pre-Application'
                  ? '#acac13'
                  : param.value === 'in-progress' && 'In Progress'
                    ? '#E38E21'
                    : param.value === 'not active' && 'Not Active'
                      ? '#5B5B5B'
                      : param.value === 'under-review' &&
                        !param.row.notificationDateMet &&
                        'Under Review'
                        ? '#acac13'
                        : param.value === 'reviewed' &&
                          !param.row.notificationDateMet &&
                          'Under Review'
                          ? '#acac13'
                          : param.value === 'waitlisted' && (
                            <>
                              {param.row.notificationDateMet && 'Waitlisted'}
                              {!param.row.notificationDateMet && 'Under Review'}
                            </>
                          )
                            ? '#acac13'
                            : param.value === 'denied' && (
                              <>
                                {param.row.notificationDateMet && 'Denied'}
                                {!param.row.notificationDateMet && 'Under Review'}
                              </>
                            )
                              ? '#acac13'
                              : param.value === 'approved' && (
                                <>
                                  {param.row.notificationDateMet && 'Approved'}
                                  {!param.row.notificationDateMet && 'Under Review'}
                                </>
                              )
                                ? '#4C9B4F'
                                : param.value === 'admitted' && 'Admitted'
                                  ? '#DC4C88'
                                  : param.value === 'declined' && 'Declined'
                                    ? '#FC5A5A'
                                    : // {/*CHECK WITH CHIAMAKA*/}
                                    // {/*{param.value === "denied" && "Waitlisted"}*/}
                                    param.value === 'payment1' && 'Payment 1 Pending'
                                      ? '#E38E21'
                                      : param.value === 'payment2' && 'Payment 2 Pending'
                                        ? '#E38E21'
                                        : param.value === 'complete' && 'Complete'
                                          ? '#4C9B4F'
                                          : param.value === 'feedback' && 'Feedback'
                                            ? '#DC4C88'
                                            : param.value === 'withdrawn' &&
                                              param.row.notificationDateMet &&
                                              'Withdrawn'
                                              ? '#FC5A5A'
                                              : param.value === 'withdrawn' &&
                                                !param.row.notificationDateMet &&
                                                'Under Review'
                                                ? '#acac13'
                                                : param.value === 'closed' && 'Closed'
                                                  ? '#5B5B5B'
                                                  : '#5B5B5B',

              //-----------------------------//
              color:
                param.value === 'pre-application' && 'Pre-Application'
                  ? '#c195bd'
                  : param.value === 'in-progress' && 'In Progress'
                    ? '#c195bd'
                    : param.value === 'not active' && 'Not Active'
                      ? '#5B5B5B'
                      : param.value === 'under-review' &&
                        !param.row.notificationDateMet &&
                        'Under Review'
                        ? '#acac13'
                        : param.value === 'reviewed' &&
                          !param.row.notificationDateMet &&
                          'Under Review'
                          ? '#acac13'
                          : param.value === 'waitlisted' && (
                            <>
                              {param.row.notificationDateMet && 'Waitlisted'}
                              {!param.row.notificationDateMet && 'Under Review'}
                            </>
                          )
                            ? '#acac13'
                            : param.value === 'denied' && (
                              <>
                                {param.row.notificationDateMet && 'Denied'}
                                {!param.row.notificationDateMet && 'Under Review'}
                              </>
                            )
                              ? '#acac13'
                              : param.value === 'approved' && (
                                <>
                                  {param.row.notificationDateMet && 'Approved'}
                                  {!param.row.notificationDateMet && 'Under Review'}
                                </>
                              )
                                ? '#4C9B4F'
                                : param.value === 'admitted' && 'Admitted'
                                  ? '#DC4C88'
                                  : param.value === 'declined' && 'Declined'
                                    ? '#FC5A5A'
                                    : // {/*CHECK WITH CHIAMAKA*/}
                                    // {/*{param.value === "denied" && "Waitlisted"}*/}
                                    param.value === 'payment1' && 'Payment 1 Pending'
                                      ? '#E38E21'
                                      : param.value === 'payment2' && 'Payment 2 Pending'
                                        ? '#E38E21'
                                        : param.value === 'complete' && 'Complete'
                                          ? '#4C9B4F'
                                          : param.value === 'feedback' && 'Feedback'
                                            ? '#DC4C88'
                                            : param.value === 'withdrawn' &&
                                              param.row.notificationDateMet &&
                                              'Withdrawn'
                                              ? '#FC5A5A'
                                              : param.value === 'withdrawn' &&
                                                !param.row.notificationDateMet &&
                                                'Under Review'
                                                ? '#acac13'
                                                : param.value === 'closed' && 'Closed'
                                                  ? '#5B5B5B'
                                                  : '#5B5B5B',

              borderRadius: '4rem',
              border: '2px',
              borderStyle: 'solid',
              paddingLeft: '8px',
              paddingRight: '8px',
              fontWeight: '600',
              textAlign: 'center',
            }}
          >
            {param.value === 'pre-application' && 'Pre-Application'}
            {param.value === 'in-progress' && 'In Progress'}
            {param.value === 'not active' && 'Not Active'}
            {param.value === 'under-review' &&
              !param.row.notificationDateMet &&
              'Under Review'}
            {param.value === 'reviewed' &&
              !param.row.notificationDateMet &&
              'Under Review'}
            {param.value === 'waitlisted' && (
              <>
                {param.row.notificationDateMet && 'Waitlisted'}
                {!param.row.notificationDateMet && 'Under Review'}
              </>
            )}
            {param.value === 'denied' && (
              <>
                {param.row.notificationDateMet && 'Denied'}
                {!param.row.notificationDateMet && 'Under Review'}
              </>
            )}
            {param.value === 'approved' && (
              <>
                {param.row.notificationDateMet && 'Approved'}
                {!param.row.notificationDateMet && 'Under Review'}
              </>
            )}
            {/*{param.value === "waitlisted" && row.notificationDateMet === true ? " Waitlisted" : 'Under Review'}*/}
            {param.value === 'admitted' && 'Admitted'}
            {param.value === 'declined' && 'Declined'}
            {/*CHECK WITH CHIAMAKA*/}
            {/*{param.value === "denied" && "Waitlisted"}*/}
            {param.value === 'payment1' && 'Payment 1 Pending'}
            {param.value === 'payment2' && 'Payment 2 Pending'}
            {param.value === 'complete' && 'Complete'}
            {param.value === 'feedback' && 'Feedback'}
            {param.value === 'withdrawn' &&
              param.row.notificationDateMet &&
              'Withdrawn'}
            {param.value === 'withdrawn' &&
              !param.row.notificationDateMet &&
              'Under Review'}
            {param.value === 'closed' && 'Closed'}
          </div>
        ),
      },
      {
        field: 'applicationDeadline',
        headerName: 'Application Deadline',
        width: 160,
        valueGetter: (param) => param.value || 'N/A',
        // sort: ((a,b) => (a.applicationDeadline >= b.applicationDeadline) ? 1 : -1)
      },
      {
        field: 'programStartDate',
        headerName: 'Start Date',
        width: 180,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: '',
        headerName: '',
        width: 220,
        renderCell: (params) => (
          <>
            {params.row.instituteDetails?.programPacketURL && (
              <Tooltip title="Export Program Packet">
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    window.open(
                      params.row.instituteDetails.programPacketURL,
                      '_blank'
                    );
                  }}
                  color="primary"
                  size="small"
                  style={{ marginLeft: '1rem' }}
                  disabled={
                    params.row.programStage !== 'admitted' ||
                    !params.row.chosenInstitution
                  }
                >
                  Export Program Packet
                </Button>
              </Tooltip>
            )}
          </>
        ),
      },
      /*{
        field: "downloadApplication",
        headerName: "Export Completed Application",
        width: 220,
        renderCell: (params) => (
          <>
            <Tooltip title="Export Completed Application">
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  downloadApplication(params.row);
                }}
                color="primary"
                size="small"
                disabled={
                  params.row.programStage === "pre-application" ||
                  params.row.programStage === "in-progress"
                }
              >
                <CloudDownload />
              </IconButton>
            </Tooltip>
          </>
        ),
      },*/
    ];
  }, []);

  return (
    <Box height={320} width="100%">
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This application is not active, in the case of any questions,
            contact{' '}
            <a target="_blank" href="mailto:techsupport@leadprogram.org">
              techsupport@leadprogram.org
            </a>
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <DataGridPremium
        columns={memoizedColumns}
        rows={filteredRows}
        disableSelectionOnClick
        disableColumnMenu
        rowsPerPageOptions={[]}
        onRowClick={(param) => {
          if (param.row?.archive) {
            setOpen(true);
            return;
          }

          param.row.programStage !== 'complete' &&
            history.push(`/program/edit?id=${param.id}`);
        }}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            value: searchText,
            clearSearch: () => requestSearch(''),
            hideExport: true,
          },
        }}
      />
    </Box>
  );
};

export default RegisteredProgramsTable;
