// import React, { Fragment, useEffect, useState } from 'react';
// import { makeStyles, useTheme } from '@mui/styles';
// import { Grid, IconButton, Typography } from '@mui/material';
// import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { connect } from 'react-redux';
// import {
//   deleteTranscript,
//   submitTranscriptForm,
//   submitTranscriptForms,
// } from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';
// import TranscriptFormUpload from './TranscriptFormUpload';

// const useStyles = makeStyles((theme) => ({
//   fieldContainer: {
//     paddingTop: '1em',
//     paddingBottom: '1em',
//   },
//   fieldWrapper: {
//     paddingTop: '0.4em',
//     paddingBottom: '0.4em',
//   },
//   formName: {
//     color: '#696974',
//     fontWeight: '600',
//     fontFamily: 'Poppins',
//     fontSize: '24px',
//     lineHeight: '72px',
//     letter: '0.2px',
//   },
//   formText: {
//     color: '#696974',
//     fontWeight: '400',
//     fontFamily: 'Roboto',
//     fontSize: '16px',
//     letter: '0.2px',
//   },
//   formQuestionText: {
//     color: '#696974',
//     fontWeight: '400',
//     fontFamily: 'Roboto',
//     fontSize: '16px',
//     letter: '0.2px',
//   },
//   button: {
//     marginLeft: '0.4em',
//     '&:hover': {
//       boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
//     },
//   },

//   continueButton: {
//     marginLeft: '0.4em',
//     backgroundColor: '#1976d2',
//     color: 'white !Important',
//     '&:hover': {
//       backgroundColor: '#1976d2',
//       color: 'white',
//       boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
//       //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
//     },
//   },
// }));

// const actions = {
//   deleteTranscript,
//   submitTranscriptForm,
//   submitTranscriptForms,
// };

// const TranscriptForm = ({
//   registeredProgramId,
//   registeredProgram,
//   deleteTranscript,
//   submitTranscriptForm,
//   submitTranscriptForms,
//   handleCloseForm,
//   handleSetForm,
//   program,
// }) => {
//   const classes = useStyles();
//   const theme = useTheme();
//   const [files, setFiles] = useState({});

//   const [disabled, setDisabled] = useState(false);
//   const [error, setError] = useState('');
//   const [exit, setExit] = useState(false);
//   console.log(registeredProgram, 'registeredProgram');
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   useEffect(() => {
//     if (exit) handleCloseForm();
//   }, [exit, handleCloseForm]);

//   const handleDeleteTranscript = async (fileName) => {
//     setDisabled(true);
//     try {
//       await deleteTranscript(registeredProgramId, fileName);
//       setDisabled(false);
//     } catch (error) {
//       console.log(error);
//       setDisabled(false);
//     }
//   };
//   useEffect(() => {
//     if (registeredProgram.transcripts) {
//       setFiles(registeredProgram.transcripts);
//     }
//   }, [registeredProgram.transcripts]);
//   const programsAllThings = [
//     'scholarshipForm',
//     'taxForm',
//     'personalInformationForm',
//     'familyInformationForm',
//     'academicInformationForm',
//     'recommendationsRequired',
//     'recommendersForm',
//     'extracurricularForm',
//     'covidProtocolForm',
//     'resumeForm',
//     'transcriptForm',
//     'shortResponsesForm',
//     'additionalDocumentsForm',
//     'confirmationForm',
//   ];

//   const programAllThingsValue = {
//     scholarshipForm: 'scholarshipForm',
//     taxForm: 'taxForm',
//     personalInformationForm: 'personalInformationForm',
//     familyInformationForm: 'familyInformationForm',
//     academicInformationForm: 'academicInformationForm',
//     recommendationsRequired: 'recommendationsRequired',
//     recommendersForm: 'recommendersForm',
//     extracurricularForm: 'extracurricularForm',
//     covidProtocolForm: 'covidProtocolForm',
//     resumeForm: 'resumeForm',
//     transcriptForm: 'transcriptForm',
//     shortResponsesForm: 'shortResponsesForm',
//     additionalDocumentsForm: 'additionalDocumentsForm',
//     confirmationForm: 'confirmationForm',
//   };

//   let dataToCheck = programsAllThings
//     .filter((item) => program[item])
//     .map((item) => programAllThingsValue[item]);

//   console.log(files, 'afiles');
//   const handleSubmitTranscriptForms = async () => {
//     setDisabled(true);
//     try {
//       if (
//         Object.keys(files).length > 0
//         // registeredProgram.transcripts &&
//         // registeredProgram.transcripts.length > 0
//       ) {
//         await submitTranscriptForms(registeredProgramId, files, true);
//         handleCloseForm();
//         const indexOfPersonalInfoForm = dataToCheck.indexOf('transcriptForm');
//         if (
//           indexOfPersonalInfoForm !== -1 &&
//           indexOfPersonalInfoForm < dataToCheck.length - 1
//         ) {
//           const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
//           handleSetForm(nextForm);
//         }

//         setDisabled(false);
//       } else {
//         /*  await submitTranscriptForm(registeredProgramId, false);
//         handleCloseForm();
//         const indexOfPersonalInfoForm = dataToCheck.indexOf("transcriptForm");
//         if (
//           indexOfPersonalInfoForm !== -1 &&
//           indexOfPersonalInfoForm < dataToCheck.length - 1
//         ) {
//           const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
//           handleSetForm(nextForm);
//         }*/

//         setError('Transcript and SAT Score is required.');
//         setDisabled(false);
//       }
//     } catch (error) {
//       console.log(error);
//       setDisabled(false);
//     }
//   };

//   return (
//     <Fragment>
//       <Typography className={classes.formName}>
//         Transcript and Standardized Test Scores
//       </Typography>
//       <Grid item container direction={'column'}>
//         <Grid item>
//           <Grid item container direction={'column'}>
//             <Grid item>
//               <Typography className={classes.formText}>
//                 We will accept the most recent transcript at the time of your
//                 submission, beginning with your Freshman year of high school to
//                 most recent completed semester. Unofficial transcripts are
//                 accepted.
//                 <br />
//                 <br />
//                 If you have PSAT scores, please upload a copy of the College
//                 Board PSAT/SAT score page to your application once you have
//                 received them. The image should be a copy of the entire page
//                 that shows your name and date the test was taken. If students
//                 have not taken the College admission tests yet, we require that
//                 they send the scores once they are available to
//                 info@leadprogram.org, addressed to LEAD Application Review Team.
//                 <br />
//                 <br />
//               </Typography>
//             </Grid>
//             {/* <Grid item>
//               <Typography
//                 className={classes.formText}
//                 style={{ paddingTop: '10px' }}
//               >
//                 <span style={{ fontWeight: 'bold' }}>
//                   Please make sure to press UPLOAD Button.
//                 </span>
//               </Typography>
//             </Grid> */}
//           </Grid>
//         </Grid>

//         {/*TAX FORM UPLOAD*/}
//         <TranscriptFormUpload
//           files={files}
//           setFiles={setFiles}
//           registeredProgramId={registeredProgramId}
//         />
//         {error && (
//           <Typography variant="h6" style={{ color: 'red' }}>
//             {error}
//           </Typography>
//         )}
//         {/*CURRENT FORMS*/}

//         {/* <Grid
//           item
//           container
//           direction={'column'}
//           className={classes.fieldContainer}
//           // alignItems={"center"}
//         >
//           <Grid item>
//             <Typography className={classes.formQuestionText}>
//               Uploaded Documents:
//             </Typography>
//           </Grid>

//           <Grid item>
//             <Grid item container direction={'column'}>
//               {registeredProgram?.transcripts?.map((item) => (
//                 <Grid item key={item.id}>
//                   <Grid item container alignItems={'center'}>
//                     <Grid item>
//                       <Typography variant={'body1'}>
//                         {item.originalFileName}
//                       </Typography>
//                     </Grid>
//                     <Grid item style={{ marginLeft: '1em' }}>
//                       <IconButton
//                         disabled={disabled}
//                         aria-label="delete file"
//                         onClick={() => handleDeleteTranscript(item.fileName)}
//                       >
//                         <DeleteIcon
//                           style={{ color: theme.palette.error.main }}
//                         />
//                       </IconButton>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//         </Grid> */}

//         {/*BUTTONS*/}
//         <Grid item style={{ marginLeft: 'auto' }}>
//           <Button
//             variant={'outlined'}
//             color={'primary'}
//             className={classes.button}
//             type={'submit'}
//             disabled={disabled}
//             onClick={() => setExit(true)}
//           >
//             Save for Later
//           </Button>

//           <Button
//             variant={'outlined'}
//             color={'primary'}
//             className={classes.continueButton}
//             type={'submit'}
//             onClick={() => handleSubmitTranscriptForms()}
//             disabled={disabled}
//           >
//             Continue{' '}
//             <span>
//               <strong> &#8594;</strong>
//             </span>
//           </Button>

//           <Button
//             variant={'outlined'}
//             color={'secondary'}
//             className={classes.button}
//             onClick={() => handleCloseForm()}
//           >
//             Cancel
//           </Button>
//         </Grid>
//       </Grid>
//     </Fragment>
//   );
// };

// export default connect(null, actions)(TranscriptForm);
import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Grid, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { connect } from 'react-redux';
import {
  deleteTranscript,
  submitTranscriptForm,
} from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import TranscriptFormUpload from './TranscriptFormUpload';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  formName: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '72px',
    letter: '0.2px',
  },
  formText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  formQuestionText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  button: {
    marginLeft: '0.4em',
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
    },
  },

  continueButton: {
    marginLeft: '0.4em',
    backgroundColor: '#1976d2',
    color: 'white !Important',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
      //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    },
  },
}));

const actions = {
  deleteTranscript,
  submitTranscriptForm,
};

const TranscriptForm = ({
  registeredProgramId,
  registeredProgram,
  deleteTranscript,
  submitTranscriptForm,
  handleCloseForm,
  handleSetForm,
  program,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');
  const [exit, setExit] = useState(false);
  console.log(registeredProgram, 'registeredProgram');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (exit) handleCloseForm();
  }, [exit, handleCloseForm]);

  const handleDeleteTranscript = async (fileName) => {
    setDisabled(true);
    try {
      await deleteTranscript(registeredProgramId, fileName);
      setDisabled(false);
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };

  const programsAllThings = [
    'applicationInterests',
    'personalInformationForm',
    'familyInformationForm',
    'academicInformationForm',
    // 'recommendationsRequired',
    'recommendersForm',
    'extracurricularForm',
    'covidProtocolForm',
    'resumeForm',
    'transcriptForm',
    'shortResponsesForm',
    'scholarshipForm',
    'taxForm',
    'additionalDocumentsForm',
    'confirmationForm',
  ];

  const programAllThingsValue = {
    applicationInterests: 'applicationInterests',
    personalInformationForm: 'personalInformationForm',
    familyInformationForm: 'familyInformationForm',
    academicInformationForm: 'academicInformationForm',
    // recommendationsRequired: 'recommendationsRequired',
    recommendersForm: 'recommendersForm',
    extracurricularForm: 'extracurricularForm',
    covidProtocolForm: 'covidProtocolForm',
    resumeForm: 'resumeForm',
    transcriptForm: 'transcriptForm',
    shortResponsesForm: 'shortResponsesForm',
    scholarshipForm: 'scholarshipForm',
    taxForm: 'taxForm',
    additionalDocumentsForm: 'additionalDocumentsForm',
    confirmationForm: 'confirmationForm',
  };

  let dataToCheck = programsAllThings
    .filter((item) => program[item])
    .map((item) => programAllThingsValue[item]);

  const handleSubmitTranscriptForms = async () => {
    setDisabled(true);
    try {
      if (
        registeredProgram.transcripts &&
        registeredProgram.transcripts.length > 0
      ) {
        await submitTranscriptForm(registeredProgramId, true);
        handleCloseForm();
        const indexOfPersonalInfoForm = dataToCheck.indexOf('transcriptForm');
        if (
          indexOfPersonalInfoForm !== -1 &&
          indexOfPersonalInfoForm < dataToCheck.length - 1
        ) {
          const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
          handleSetForm(nextForm);
        }

        setDisabled(false);
      } else {
        /*  await submitTranscriptForm(registeredProgramId, false);
        handleCloseForm();
        const indexOfPersonalInfoForm = dataToCheck.indexOf("transcriptForm");
        if (
          indexOfPersonalInfoForm !== -1 &&
          indexOfPersonalInfoForm < dataToCheck.length - 1
        ) {
          const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
          handleSetForm(nextForm);
        }*/

        setError('Transcript and SAT Score is required.');
        setDisabled(false);
      }
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };

  return (
    <Fragment>
      <Typography className={classes.formName}>
        Transcript and Standardized Test Scores
      </Typography>
      <Grid item container direction={'column'}>
        <Grid item>
          <Grid item container direction={'column'}>
            <Grid item>
              <Typography className={classes.formText}>
                We will accept the most recent transcript at the time of your
                submission, beginning with your Freshman year of high school to
                most recent completed semester. Unofficial transcripts are
                accepted.
                <br />
                <br />
                If you have PSAT scores, please upload a copy of the College
                Board PSAT/SAT score page to your application once you have
                received them. The image should be a copy of the entire page
                that shows your name and date the test was taken. If students
                have not taken the College admission tests yet, we require that
                they send the scores once they are available to
                info@leadprogram.org, addressed to LEAD Application Review Team.
                <br />
                <br />
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={classes.formText}
                style={{ paddingTop: '10px' }}
              >
                <span style={{ fontWeight: 'bold' }}>
                  Please make sure to press UPLOAD Button.
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/*TAX FORM UPLOAD*/}
        <TranscriptFormUpload registeredProgramId={registeredProgramId} />
        {error && (
          <Typography variant="h6" style={{ color: 'red' }}>
            {error}
          </Typography>
        )}
        {/*CURRENT FORMS*/}

        <Grid
          item
          container
          direction={'column'}
          className={classes.fieldContainer}
          // alignItems={"center"}
        >
          <Grid item>
            <Typography className={classes.formQuestionText}>
              Uploaded Documents:
            </Typography>
          </Grid>

          <Grid item>
            <Grid item container direction={'column'}>
              {registeredProgram?.transcripts?.map((item) => (
                <Grid item key={item.id}>
                  <Grid item container alignItems={'center'}>
                    <Grid item>
                      <Typography variant={'body1'}>
                        {item.originalFileName}
                      </Typography>
                    </Grid>
                    <Grid item style={{ marginLeft: '1em' }}>
                      <IconButton
                        disabled={disabled}
                        aria-label="delete file"
                        onClick={() => handleDeleteTranscript(item.fileName)}
                      >
                        <DeleteIcon
                          style={{ color: theme.palette.error.main }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {/*BUTTONS*/}
        <Grid item style={{ marginLeft: 'auto' }}>
          <Button
            variant={'outlined'}
            color={'primary'}
            className={classes.button}
            type={'submit'}
            disabled={disabled}
            onClick={() => setExit(true)}
          >
            Save for Later
          </Button>

          <Button
            variant={'outlined'}
            color={'primary'}
            className={classes.continueButton}
            type={'submit'}
            onClick={() => handleSubmitTranscriptForms()}
            disabled={disabled}
          >
            Continue{' '}
            <span>
              <strong> &#8594;</strong>
            </span>
          </Button>

          <Button
            variant={'outlined'}
            color={'secondary'}
            className={classes.button}
            onClick={() => handleCloseForm()}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default connect(null, actions)(TranscriptForm);
