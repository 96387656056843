import React, { useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Badge,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import {
  changeUserPassword,
  deleteUserProfilePhoto,
  updateUserProfile,
  updateUserProfilePhoto,
} from '../../../store/actions/applicantActions/profileActions/applicantProfileActions';
import Avatar from '@mui/material/Avatar';
// import EditProfile from "../../../ui/profile/EditProfile";
// import UpdatePassword from "../../../ui/profile/UpdatePassword";
import { useHistory } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import './ApplicantProfile.css';

import TextInput from '../../../common/form/TextInput';
import { combineValidators, isRequired } from 'revalidate';
import DatePickerInput from '../../../common/form/DatePickerInput';
import GradeLookup from '../../admin/programs/GradeLookup';
import StateLookup from '../../../ui/forms/formLookups/StateLookup';
import SwitchButton from '../../../common/form/Switch';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#E7E7E7',
  },
  innerContainer: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  avatar: {
    width: 200,
    height: 200,
  },

  dropzone: {
    width: 250,
    height: 250,
    [theme.breakpoints.down('xs')]: {
      width: 180,
      height: 180,
    },
  },
  image: {
    width: 250,
    height: 250,
    [theme.breakpoints.down('xs')]: {
      width: 180,
      height: 180,
    },
  },
  fieldWrapper: {
    paddingTop: '0.3em',
    paddingBottom: '0.3em',
  },
  smsWrapper: {
    flexBasis: '100%',
    maxWidth: '100%',
    marginTop: '1rem',
    marginLeft: '2rem',
  },
}));

const actions = {
  updateUserProfilePhoto,
  deleteUserProfilePhoto,
  updateUserProfile,
  changeUserPassword,
};

const mapStateToProps = (state) => {
  let selectedState = {};
  if (state?.firebase?.profile?.state) {
    selectedState = StateLookup?.states?.find(
      (state) => state.text === 'District Of Columbia'
    );
  }

  return {
    initialValues: {
      ...state.firebase.profile,
      sendSmsConsent:
        Object.keys(state.firebase.profile).length &&
        Object.hasOwn(state.firebase.profile, 'sendSmsConsent')
          ? state.firebase.profile?.sendSmsConsent
          : true,
      // state: selectedState?.value || "",
    },
    profile: state.firebase.profile,
    loading: state.loading.loading,
  };
};

const validate = combineValidators({
  firstName: isRequired({ message: 'First Name is required' }),
  lastName: isRequired({ message: 'Last Name is required' }),
  // middleInitial: isRequired({ message: "Middle Initial is required" }),
  email: isRequired({ message: 'Email is required' }),
  mobileNumber: isRequired({ message: 'Mobile Number is required' }),
  dob: isRequired({ message: 'Date of Birth is required' }),
  gender: isRequired({ message: 'Gender is required' }),
  gradeLevel: isRequired({ message: 'Grade Level is required' }),
  ethnicBackground: isRequired({ message: 'Ethnic Background is required' }),
  citizenshipStatus: isRequired({ message: 'Citizenship Status is required' }),
  address1: isRequired({ message: 'Address 1 is required' }),
  city: isRequired({ message: 'City is required' }),
  state: isRequired({ message: 'State is required' }),
  zipCode: isRequired({ message: 'Zip Code is required' }),
  biography: (value) => {
    if (value && value.split(' ').length > 200)
      return 'Must be 200 words or less';
  },
});

const ApplicantProfile = ({
  profile,
  updateUserProfile,
  updateUserProfilePhoto,
  deleteUserProfilePhoto,
  changeUserPassword,
  handleSubmit,
}) => {
  const history = useHistory();
  const classes = useStyles();
  console.log(profile, 'profile');

  const grades = GradeLookup.grades;
  const states = StateLookup.states;
  const [selectedValue, setSelectedValue] = useState([]);

  const [updatePassword, setUpdatePassword] = useState(false);
  const [showinput, setShowinput] = useState(false);
  const [input, setInput] = useState(false);

  console.log(showinput, 'showinput');
  console.log(profile, 'ram');

  useEffect(() => {
    if (typeof profile.ethnicBackground == 'object') {
      const setdefault = profile?.ethnicBackground?.map((i) => {
        return i;
      });
      console.log(setdefault, 'setdefault');
      if (setdefault?.length > 0) {
        setSelectedValue(setdefault);
      }
    } else {
      setSelectedValue([profile?.ethnicBackground]);
    }
  }, [profile]);
  console.log(selectedValue, 'selectedValue');

  const handleUpdateProfilePhoto = (event) => {
    const imgName = profile.profileImageName;
    const imgURL = profile.profileImageURL;
    updateUserProfilePhoto(event.target.files, imgName, imgURL);
  };

  const handleDeleteProfilePhoto = () => {
    deleteUserProfilePhoto();
  };

  const handleUpdatePassword = async (values) => {
    await changeUserPassword(values);
    setUpdatePassword(false);
  };

  const handleUpdateUserProfile = async (values) => {
    // if (selectedValue.includes("Other")) {
    //   values.ethnicBackground = values.ethnicBackgrounds;
    //   delete values.ethnicBackgrounds;
    // } else {
    //   values.ethnicBackground = selectedValue;
    // }
    values.ethnicBackground = selectedValue;
    console.log(values, 'values');

    await updateUserProfile(values).then((x) =>
      history.push(
        `/${values?.isAdmin === true ? 'admin' : 'applicant'}/dashboard`
      )
    );
  };
  const chooseValue = (value) => {
    setShowinput(value);
  };

  return (
    <form
      className={classes.container}
      autoComplete={'off'}
      onSubmit={handleSubmit(handleUpdateUserProfile)}
    >
      <Container className={classes.innerContainer}>
        <Grid container justifyContent="space-between">
          <Grid xs={12} style={{ marginBottom: '2rem' }}>
            <Typography variant="h4">Account</Typography>
          </Grid>
          <Grid item md={3}>
            <Grid
              component={Paper}
              elevation={0}
              container
              direction="column"
              alignItems="center"
              style={{ padding: '2rem 0', borderRadius: '0.5rem' }}
            >
              <Avatar
                src={profile.profileImageURL}
                className={classes.avatar}
              />
              <Typography variant="h5" style={{ marginTop: '1rem' }}>
                {profile.fullName}
              </Typography>
              <Typography variant="body1" style={{ marginTop: '1rem' }}>
                {profile.city}, {profile.state}
              </Typography>
              <Divider style={{ width: '100%', marginTop: '1rem' }} />
              <Button
                color="primary"
                style={{ fontWeight: 'bold', marginTop: '1rem' }}
                component="label"
              >
                Upload Picture
                <input type="file" hidden onChange={handleUpdateProfilePhoto} />
              </Button>
              {profile.profileImageName && (
                <Button
                  color="secondary"
                  style={{ fontWeight: 'bold', marginTop: '1rem' }}
                  component="label"
                  onClick={deleteUserProfilePhoto}
                >
                  Delete Picture
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            md={8}
            component={Paper}
            elevation={0}
            container
            justifyContent="space-around"
            style={{ padding: '2rem 0', borderRadius: '0.5rem' }}
          >
            <Grid item md={12} style={{ padding: '0 2rem' }}>
              <Typography variant="h5">Profile</Typography>
              <Typography variant="subtitle1">
                This Information can be edited.
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }} />
            <Grid item md={5} style={{ marginTop: '1rem' }}>
              <Field
                name="firstName"
                component={TextInput}
                type={'text'}
                variant={'outlined'}
                label="First Name"
              />
            </Grid>
            <Grid item md={5} style={{ marginTop: '1rem' }}>
              <Field
                name="lastName"
                component={TextInput}
                type={'text'}
                variant={'outlined'}
                label="Last Name"
              />
            </Grid>
            <Grid item md={5} style={{ marginTop: '1rem' }}>
              <Field
                name="middleInitial"
                component={TextInput}
                type={'text'}
                variant={'outlined'}
                label="Middle Initial"
              />
            </Grid>
            <Grid item md={5} style={{ marginTop: '1rem' }}>
              <Field
                name="email"
                component={TextInput}
                type={'text'}
                variant={'outlined'}
                label="Email"
              />
            </Grid>
            <Grid item md={5} style={{ marginTop: '1rem' }}>
              <Field
                name="PreferredEmail"
                component={TextInput}
                type={'text'}
                variant={'outlined'}
                label="Preferred Email"
              />
            </Grid>
            <Grid item md={5} style={{ marginTop: '1rem' }}>
              <Field
                name="mobileNumber"
                component={TextInput}
                type={'text'}
                variant={'outlined'}
                label="Mobile Number"
              />
            </Grid>
            <Grid item md={5} style={{ marginTop: '1rem' }}>
              <Field
                name="housePhoneNumber"
                component={TextInput}
                type={'text'}
                variant={'outlined'}
                label="Phone Number"
              />
            </Grid>
            {profile.isApplicant && (
              <Grid item md={5} style={{ marginTop: '1rem' }}>
                <Field
                  name="dob"
                  component={DatePickerInput}
                  type={'text'}
                  variant={'outlined'}
                  label="Date of Birth"
                />
              </Grid>
            )}
            <Grid item md={5} style={{ marginTop: '1rem' }}>
              <Field
                name="gender"
                component={TextInput}
                type={'text'}
                variant={'outlined'}
                label="Gender"
                select
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Non-bimary">Non-binary</MenuItem>

              {/* <MenuItem value="Prefer not to Disclose">
                  Prefer not to Disclose
            </MenuItem>*/}
              </Field>
            </Grid>
            <Grid item md={5} style={{ marginTop: '1rem' }}>
              <InputLabel htmlFor="ethnic-background-label">
                Ethnic background
              </InputLabel>
              <Select
                name="ethnicBackground"
                value={selectedValue}
                onChange={(e) => {
                  let values = e.target.value;
                  console.log(values, 'values');
                  if (values == 'Other') {
                    setInput(true);
                  } else {
                    setInput(false);
                  }

                  if (!selectedValue.includes(values)) {
                    setSelectedValue(values);
                  }
                }}
                // defaultValue={selectedValue}
                component={Select}
                variant={'outlined'}
                labelId="Ethnic background"
                multiple
                style={{ width: '100%' }}
              >
                <MenuItem value="Asian American/Pacific Islander">
                  Asian American/Pacific Islander
                </MenuItem>
                <MenuItem value="Black/African American">
                  Black/African American
                </MenuItem>
                <MenuItem value="Caucasian">Caucasian</MenuItem>
                <MenuItem value="Latino">Latino</MenuItem>
                <MenuItem value="Multi-racial">Multi-racial</MenuItem>
                <MenuItem value="Native American">Native American</MenuItem>
                <MenuItem value="Other">Other</MenuItem>

                {/* <MenuItem value="Prefer not to answer">
                  Prefer not to answer
                </MenuItem> */}
              </Select>
            </Grid>
            {profile?.ethnicBackgrounds && selectedValue.includes('Other') ? (
              <Grid item md={5} style={{ marginTop: '1rem' }}>
                <Field
                  name="ethnicBackgrounds"
                  label={'Mention Ethnic Background'}
                  component={TextInput}
                  type={'text'}
                  variant={'outlined'}
                />
              </Grid>
            ) : (
              ''
            )}
            {selectedValue.includes('Other') && !profile?.ethnicBackgrounds ? (
              <Grid item md={5} style={{ marginTop: '1rem' }}>
                <Field
                  name="ethnicBackgroundOther"
                  label={'Mention Ethnic Backgroundss'}
                  component={TextInput}
                  type={'text'}
                  variant={'outlined'}
                />
              </Grid>
            ) : (
              ''
            )}
            {profile.isApplicant && (
              <>
                <Grid item md={5} style={{ marginTop: '1rem' }}>
                  <Field
                    name="gradeLevel"
                    component={TextInput}
                    type={'text'}
                    variant={'outlined'}
                    label="Grade Level"
                    select
                  >
                    {grades.map((grade) => (
                      <MenuItem value={grade.value}>{grade.value}</MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item md={5} style={{ marginTop: '1rem' }}>
                  <Field
                    name="address1"
                    component={TextInput}
                    type={'text'}
                    variant={'outlined'}
                    label="Address 1"
                  />
                </Grid>
                <Grid item md={5} style={{ marginTop: '1rem' }}>
                  <Field
                    name="address2"
                    component={TextInput}
                    type={'text'}
                    variant={'outlined'}
                    label="Address 2"
                  />
                </Grid>
                <Grid item md={5} style={{ marginTop: '1rem' }}>
                  <Field
                    name="city"
                    component={TextInput}
                    type={'text'}
                    variant={'outlined'}
                    label="City"
                  />
                </Grid>
                <Grid item md={5} style={{ marginTop: '1rem' }}>
                  <Field
                    name="state"
                    component={TextInput}
                    type={'text'}
                    variant={'outlined'}
                    label="State"
                    select
                  >
                    {states.map((grade) => (
                      <MenuItem value={grade.value}>{grade.text}</MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item md={5} style={{ marginTop: '1rem' }}>
                  <Field
                    name="zipCode"
                    component={TextInput}
                    type={'text'}
                    variant={'outlined'}
                    label="Zip Code"
                  />
                </Grid>
                <Grid item md={5} style={{ marginTop: '1rem' }}>
                  <Field
                    name="biography"
                    component={TextInput}
                    type={'text'}
                    variant={'outlined'}
                    label="Biography"
                  />
                </Grid>
                <Grid item md={5} style={{ marginTop: '1rem' }}>
                  <Field
                    name="poloSize"
                    component={TextInput}
                    type={'text'}
                    variant={'outlined'}
                    label="Polo Size"
                    select
                  >
                    <MenuItem value="XS">XS</MenuItem>
                    <MenuItem value="S">S</MenuItem>
                    <MenuItem value="M">M</MenuItem>
                    <MenuItem value="L">L</MenuItem>
                    <MenuItem value="XL">XL</MenuItem>
                    <MenuItem value="2XL">2XL</MenuItem>
                    <MenuItem value="3XL">3XL</MenuItem>
                  </Field>
                </Grid>
                <Grid item md={5} className={classes.smsWrapper}>
                  <Field
                    name={'sendSmsConsent'}
                    component={SwitchButton}
                    size="medium"
                    color="primary"
                    label="Check this box to opt in and consent to receive communication from LEAD via SMS, email or phone. Message & data rates may apply."
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            xs={12}
            container
            justifyContent="flex-end"
            style={{ marginTop: '2rem' }}
          >
            <Button type="submit" color="primary" variant="contained">
              Save Details
            </Button>
          </Grid>
        </Grid>
      </Container>
    </form>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: 'editProfile',
    enableReinitialize: true,
    validate,
  })(ApplicantProfile)
);
