import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from '../../asyncActions/asyncActions';
import {
  FETCH_ALL_TRANSACTIONS,
  FETCH_ALL_USERS,
} from '../../../constants/adminConstants/transactionConstants/transactionConstants';
import axios from '../../../../config/axios';
import { fetchApplicationSettings } from '../../applicationSettingsActions/applicationSettingsActions';
import { firestore } from 'firebase';
import { FETCH_REQUEST_INFORMATION } from '../../../constants/adminConstants/programConstants/adminProgramConstants';

export const fetchAllTransactions = (
  paymentIntentId = null,
  sessionId = null
) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      dispatch(fetchAllUsers());
      const transactions = await axios.get('/stripeFunctions/transactions', {
        params: {
          paymentIntentId,
          sessionId,
        },
      });
      dispatch({
        type: FETCH_ALL_TRANSACTIONS,
        payload: { transactions: transactions.data.transactions },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
export const fetchRegisteredInfo = () => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const requestInformationCollection =
      firestore.collection('requestInformation');

    const startedApplicationQuery = firestore.collection('requestInformation');

    try {
      dispatch(asyncActionStart());
      const querySnapshot = await requestInformationCollection
        .orderBy('createdAt', 'desc')
        .get();
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });

      dispatch({
        type: FETCH_REQUEST_INFORMATION,
        payload: { data },
      });
    //  console.log(data, 'programssss');
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateProcessingFee = (fee) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const settingsQuery = firestore
      .collection('applicationSettings')
      .doc('settings');

    try {
      dispatch(asyncActionStart());
      await settingsQuery.update({
        processingFee: fee,
      });
      dispatch(fetchApplicationSettings());
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const fetchAllUsers = () => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const usersQuery = firestore.collection('registeredPrograms');
    const programsQuery = firestore.collection('programs');

    try {
      dispatch(asyncActionStart());
      const users = await usersQuery.get();
      const programs = await programsQuery.get();

      let usersArr = [];

      for (let i = 0; i < users.docs.length; i++) {
        const program = programs.docs.find(
          (doc) => doc.id === users.docs[i].data().programId
        );
        usersArr.push({
          ...users.docs[i].data(),
          id: users.docs[i].id,
          programDetails: program && program.data(),
        });
      }

      dispatch({
        type: FETCH_ALL_USERS,
        payload: usersArr,
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
