import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';

import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  MenuItem,
  Menu,
} from '@mui/material';
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  FilterListOutlined as FilterIcon,
} from '@mui/icons-material';
// import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { fetchAllCategories } from '../store/actions/adminActions/categoryActions/adminCategoryActions';
import Typography from '@mui/material/Typography';
import ProgramItem from '../ui/programs/ProgramItem';
import {
  fetchAllApplicantPrograms,
  fetchApplicantRegisteredPrograms,
} from '../store/actions/applicantActions/programActions/applicantProgramActions';
import { connect } from 'react-redux';
import { fetchAllPrograms } from '../store/actions/adminActions/programActions/adminProgramActions';

import { useHistory, useLocation } from 'react-router-dom';
import { escapeRegExp } from '../common/tableUtils';
import './About.css';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FAFAFB',
    width: '90%',
    minHeight: '400px',
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: '800',
    fontSize: '48px',
    lineHeight: '72px',
    letterSpacing: '0.2px',
    [theme.breakpoints.down('sm')]: {
      textAlign:'center',
    },
  },
  subTitle: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '72px',
    letterSpacing: '0.2px',
  },
  itemsContainer: {
    // paddingTop: '60px',
    // paddingBottom: "80px",
    // paddingLeft: "160px",
    // paddingRight: "160px",
    padding: '70px 0',
    textAlign: 'center',
  },
  button: {
    color: '#0062FF',
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.1px',
    cursor: 'pointer',
  },
}));

const actions = {
  fetchAllApplicantPrograms,
  fetchAllPrograms,
  fetchApplicantRegisteredPrograms,
  fetchAllCategories,
};

const mapStateToProps = (state) => {
 /// console.log(state, 'state');
  let programs = [];
  let applicantsPrograms = [];
  let categories = [];

  if (
    state.applicantPrograms.allPrograms &&
    state.applicantPrograms.allPrograms.length > 0
  ) {
    programs = state.applicantPrograms.allPrograms;
  }
  // if (
  //   state.adminPrograms.allPrograms &&
  //   state.adminPrograms.allPrograms.length > 0
  // ) {
  //   programs = state.adminPrograms.allPrograms;
  // }

  if (
    state.applicantPrograms.allRegisteredPrograms &&
    state.applicantPrograms.allRegisteredPrograms.length > 0
  ) {
    applicantsPrograms = state.applicantPrograms.allRegisteredPrograms;
  }

  if (
    state.adminCategories.allCategories &&
    state.adminCategories.allCategories.length > 0
  ) {
    categories = state.adminCategories.allCategories;
  }

  return {
    programs,
    applicantsPrograms,
    profile: state.firebase.profile,
    categories,
  };
};

const Programs = ({
  fetchAllApplicantPrograms,
  fetchAllPrograms,
  fetchApplicantRegisteredPrograms,
  programs,
  fetchAllCategories,
  categories,
  applicantsPrograms,
  profile,
}) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const [Tabname, setTabname] = useState('');
 /// console.log(programs, 'programs');

  const matches = useMediaQuery('(min-width:480px)');
  const classes = useStyles();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const categoryId = queryParams.get('categoryId');
  const filter = queryParams.get('filter');
 /// console.log(filter, 'filter');
  const category = queryParams.get('category');
  const [tabValue, setTabValue] = useState(1);
  const [filteredPrograms, setFilteredPrograms] = useState(programs);
  const [filteredSearchedPrograms, setFilteredSearchedPrograms] =
    useState(programs);
  const [searchText, setSearchText] = useState('');
  const [categoryID, setCategoryID] = useState('');
  const [emptyMessage, setEmptyMessage] = useState(true);
  const arr = [];
/// console.log(filteredPrograms, 'filteredPrograms');
  useEffect(() => {
    searchValue(searchText);
    fetchAllCategories();
  }, [filteredPrograms, fetchAllCategories]);

  const handleChange = (event, newTabValue) => {
    setTabValue(newTabValue);
   /// console.log(newTabValue, 'newTabValue');
    const arr = [];
    switch (newTabValue) {
      case 0:
        programs.forEach((program) => {
          arr.push(program);
        });
        break;
      case 1:
        programs.forEach((program) => {
          if (
            new Date(program.applicationDeadline) > new Date() &&
            new Date(program.applicationOpen) < new Date()
          ) {
            arr.push(program);
          }
        });
        setEmptyMessage(true);
        break;
      case 2:
        programs.forEach((program) => {
          if (new Date(program.applicationOpen) > new Date()) {
            arr.push(program);
          }
        });
        setEmptyMessage(true);
        break;
      case 3:
        programs.forEach((program) => {
          if (new Date(program.applicationDeadline) < new Date()) {
            arr.push(program);
          }
        });
        setEmptyMessage(true);
        break;
      default:
        programs.forEach((program) => {
          arr.push(program);
        });
        setEmptyMessage(true);
        break;
    }
    setFilteredPrograms(arr);
  };

  useEffect(() => {
    if (history.location.pathname === '/program') {
      programs.forEach((program) => {
        if (
          new Date(program.applicationDeadline) > new Date() &&
          new Date(program.applicationOpen) < new Date()
        ) {
          arr.push(program);
          setFilteredPrograms(arr);
          setEmptyMessage(true);
        }
      });
      if (arr.length === 0) {
        programs.map((program) => {
          arr.push(program);
          setTabValue(0);
          return setFilteredPrograms(arr);
        });
      }
    }
  }, [programs]);
  useEffect(() => {
    if (programs.length === 0) {
      fetchAllPrograms();
    }
    // other dependencies...
  }, [fetchAllPrograms]);

  const handleFilter = (e, id) => {
    setCategoryID(id);
    const arr = [];
    programs.forEach((program) => {
      if (id === program.programCategory) {
        arr.push(program);
        return true;
      }
    });
    setAnchorEl(null);
    setFilteredPrograms(arr);
  };

  useEffect(() => {
    if (filter === 'true') {
      fetchAllApplicantPrograms(categoryId);
      fetchApplicantRegisteredPrograms(profile.uid);
    } else {
      fetchAllApplicantPrograms();
      fetchApplicantRegisteredPrograms(profile.uid);
    }
  }, [
    fetchAllApplicantPrograms,
    fetchApplicantRegisteredPrograms,
    filter,
    profile,
    categoryId,
  ]);
  useEffect(() => {
    fetchAllApplicantPrograms();
  }, [fetchAllApplicantPrograms]);

  const searchValue = (value) => {
    setSearchText(value);
    const searchRegex = new RegExp(escapeRegExp(value), 'i');
    const filtered = filteredPrograms.filter((row) => {
      return (
        !row?.hidefromStudent &&
        Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        })
      );
    });
  ///  console.log(filtered, 'filtered');
    setFilteredSearchedPrograms(filtered);
  };

  return (
    <Grid
      container
      direction={'column'}
      alignItems={'center'}
      style={{ marginTop: '150px' }}
    >
      <Grid item>
        <Typography className={classes.title}>
          LEADing for Life Programs
        </Typography>
      </Grid>

      <Box
        sx={{
          p: 1,
          pb: 0,
          padding: '1rem 0 2rem 0',
          width: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' }, // Stack items vertically on small screens, in a row on larger screens
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        <TextField
          variant="standard"
          value={searchText}
          onChange={(e) => searchValue(e.target.value)}
          placeholder="Search…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                onClick={() => searchValue('')}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          sx={{
            width: { xs: '50%', md: 'auto' }, // Take full width on small screens, auto on larger screens
            m: { xs: '8px 0 12px', md: '0 8px' }, // Adjust margin based on screen size
            fontSize: (theme) => theme.typography.fontSize,
            '& .MuiSvgIcon-root': {
              mr: 0.5,
            },
            '& .MuiInput-underline:before': {
              borderBottom: 1,
              borderColor: 'divider',
            },
          }}
        />

        <Paper
          square
          sx={{ boxShadow: 'none', width: '100%', maxWidth: '500px' }}
        >
          {/* Adjust the maxWidth based on your design */}
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab label="All" />
            <Tab label="Open" />
            <Tab label="Coming Soon" />
            <Tab label="Archive" />
          </Tabs>
        </Paper>

        <Tooltip title="Filter">
          <IconButton
            className="filter-btn"
            size="small"
            onClick={(event) => {
              if (anchorEl) {
                setAnchorEl(null);
              } else {
                setAnchorEl(event.target);
              }
            }}
          >
            <FilterIcon />
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {categories.map((category) => (
            <MenuItem
              onClick={(e) => handleFilter(e, category.id)}
              key={category.id}
            >
              {category.categoryName}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {/* <StyledMenu
       
       // onClose={handleClose}
       
     
       >
        <StyledMenuItem>

       <ListItemIcon>
         <SendIcon fontSize="small" />
       </ListItemIcon>
       <ListItemText primary="Sent mail" />
     </StyledMenuItem>

     </StyledMenu> */}

      <Grid item className={classes.container}>
        {filteredSearchedPrograms?.length > 0 ? (
          <Grid
            container
            className={classes.itemsContainer}
            justifyContent={'center'}
          >
            {emptyMessage ? (
              filteredSearchedPrograms.map(
                (program) =>
                  (!program.hideProgram || profile.isAdmin) && (
                    <ProgramItem
                      key={program.id}
                      program={program}
                      applicantsPrograms={applicantsPrograms}
                      profile={profile}
                    />
                  )
              )
            ) : (
              <Typography>
                No {Tabname} programs available. Please check back later..
              </Typography>
            )}
          </Grid>
        ) : (
          <Grid
            container
            className={classes.itemsContainer}
            justifyContent={'center'}
          >
            <Typography>
              No {Tabname} programs available. Please check back later..
            </Typography>
          </Grid>
        )}

        {programs.length < 1 && (
          <Grid
            container
            direction={'column'}
            className={classes.itemsContainer}
            alignItems={'center'}
          >
            <Typography className={classes.subTitle}>
              No programs found for {category} category.
            </Typography>

            <Grid item style={{ marginTop: '16px' }}>
              <Typography
                onClick={() => history.push(`/program?filter=false`)}
                className={classes.button}
              >
                View All {'>'}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(Programs);
