import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  useTheme,
  Grid,
  useMediaQuery,
  Typography,
  Avatar,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import Sidebar from "../../../ui/sidebar/Sidebar";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  fetchApplicant,
  addApplicantNote,
} from "../../../store/actions/adminActions/applicantActions/adminApplicantActions";
import { format, parseISO, fromUnixTime } from "date-fns";
import { CustomToolbar, escapeRegExp } from "../../../common/tableUtils";
import TextArea from "../../../common/form/TextArea";
import { Field, reduxForm } from "redux-form";
import { combineValidators, isRequired } from "revalidate";

const actions = {
  fetchApplicant,
  addApplicantNote,
};

const validate = combineValidators({
  note: isRequired({ message: "Required" }),
});

const mapStateToProps = (state) => {
  let applicant = [];
  let programs = [];

  if (
    state.adminApplicants.applicant &&
    state.adminApplicants.applicant.length > 0
  ) {
    applicant = state.adminApplicants.applicant[0];
  }

  if (
    state.adminPrograms.allPrograms &&
    state.adminPrograms.allPrograms.length > 0
  ) {
    programs = state.adminPrograms.allPrograms;
  }

  return {
    applicant,
    programs,
    profile: state.firebase.profile,
    initialValues: {
      note:
        applicant.adminNotes && applicant.adminNotes.length > 0
          ? applicant.adminNotes[applicant.adminNotes.length - 1].note
          : "",
    },
  };
};

const AdminViewApplicant = ({
  fetchApplicant,
  applicant,
  programs,
  profile,
  handleSubmit,
  submitting,
  addApplicantNote,
}) => {
  const matches = useMediaQuery("(min-width:480px)");
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dialog, setDialog] = useState(false);

  const theme = useTheme();
  const history = useHistory();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [registeredPrograms, setRegisteredPrograms] = useState([]);

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      "e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    );
  }, []);

  useEffect(() => {
    const regPrograms = [];
    if (applicant?.programs?.length > 0) {
      applicant.programs.forEach((programId) => {
        const prog = programs.find((program) => program.id === programId);
        if (prog) regPrograms.push(prog.programName);
      });
    }
    setRegisteredPrograms(regPrograms);
  }, [applicant, programs]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get("id");

  useEffect(() => {
    fetchApplicant(id);
  }, [fetchApplicant, id]);

  useEffect(() => {
    const arr =
      applicant.adminNotes && applicant.adminNotes.length > 0
        ? applicant.adminNotes.map((note, index) => ({
            ...note,
            serialNo: index + 1,
            id: index + 1,
            date: note.date && format(fromUnixTime(note.date), "EEE MMM do y"),
          }))
        : [];
    setRows(arr);
    if (searchText) {
      setSearchText(searchText);
      const searchRegex = new RegExp(escapeRegExp(searchText), "i");
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
    }
  }, [applicant]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filtered = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };

  const memoizedColumns = useMemo(() => {
    return [
      {
        field: "serialNo",
        headerName: "Id",
        flex: 1,
      },
      {
        field: "date",
        headerName: "Date",
        flex: 1,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "note",
        headerName: "Details",
        flex: 2,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "author",
        headerName: "Author",
        flex: 1,
        valueGetter: (param) => param.value || "N/A",
      },
    ];
  }, []);

  const handleAddNote = async (values) => {
    await addApplicantNote(applicant.id, values.note, profile.fullName);
    fetchApplicant(id);
    setDialog(false);
  };

  return (
    <Grid container>
      <Dialog open={dialog} onClose={() => setDialog(false)} maxWidth="md">
        <form onSubmit={handleSubmit(handleAddNote)}>
          <DialogTitle>Add Note</DialogTitle>
          <DialogContent>
            <Field
              name={"note"}
              label={"Note"}
              component={TextArea}
              type={"text"}
              variant={"outlined"}
              width={360}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDialog(false)}
              color="secondary"
              disabled={submitting}
            >
              Close
            </Button>
            <Button color={"primary"} type={"submit"} disabled={submitting}>
              Add Note
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Grid item md={2} style={{ width: `${matches ? "" : "100%"}` }}>
        <Sidebar />
      </Grid>
      <Grid item md={10} style={{ padding: "1.5em" }}>
        <Grid item container direction={"column"}>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant={"h4"}>
              {applicant.fullName ? applicant.fullName : ""} Profile
            </Typography>
            {/*BUTTONS*/}
            <Grid item>
              {profile.isAdmin && (
                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    backgroundColor: theme.palette.info.main,
                  }}
                  onClick={() => history.push(`/admin/applicant/edit?id=${id}`)}
                >
                  Edit
                </Button>
              )}
              <Button
                style={{ marginLeft: "0.5em" }}
                variant="contained"
                color="primary"
                onClick={() =>
                  history.push(
                    `/${profile.isAdmin ? "admin" : "reviewer"}/applicants/`
                  )
                }
              >
                Back To Applicants
              </Button>
            </Grid>
          </Grid>
          <Grid item container style={{ marginTop: "2em" }}>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <Grid item container direction={"column"} alignItems={"center"}>
                <Grid item style={{ paddingLeft: "2em", paddingRight: "2em" }}>
                  <Avatar
                    src={
                      applicant.profileImageURL
                        ? applicant.profileImageURL
                        : null
                    }
                    style={{ width: "100%", height: "100%" }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/*RIGHT CONTAINER*/}
            <Grid
              item
              lg={9}
              md={6}
              sm={12}
              xs={12}
              style={
                matchesSM ? { marginTop: "1.5em", paddingLeft: "2em" } : null
              }
            >
              <Grid item container direction={"column"}>
                <Fragment>
                  {/*FULL NAME*/}
                  <Grid
                    item
                    container
                    alignItems={"center"}
                    style={{ marginTop: "0.5em" }}
                  >
                    <Grid item md={4}>
                      <Typography
                        variant={"body1"}
                        style={{ fontWeight: "bold" }}
                      >
                        Full Name:
                      </Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant={"body1"}>
                        {applicant.middleInitial
                          ? applicant.firstName +
                            " " +
                            applicant?.middleInitial +
                            " " +
                            applicant.lastName
                          : applicant.firstName + " " + applicant.lastName}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/*Applicant TYPE*/}
                  <Grid
                    item
                    container
                    alignItems={"center"}
                    style={{ marginTop: "0.5em" }}
                  >
                    <Grid item md={4}>
                      <Typography
                        variant={"body1"}
                        style={{ fontWeight: "bold" }}
                      >
                        Account Type:
                      </Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant={"body1"}>
                        {applicant.isApplicant && "Applicant"}
                        {applicant.isAlumni && "Alumni"}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/*EMAIL*/}
                  <Grid
                    item
                    container
                    alignItems={"center"}
                    style={{ marginTop: "0.5em" }}
                  >
                    <Grid item md={4}>
                      <Typography
                        variant={"body1"}
                        style={{ fontWeight: "bold" }}
                      >
                        Email:
                      </Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant={"body1"}>
                        {applicant.email}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/*MOBILE NUMBER*/}
                  <Grid
                    item
                    container
                    alignItems={"center"}
                    style={{ marginTop: "0.5em" }}
                  >
                    <Grid item md={4}>
                      <Typography
                        variant={"body1"}
                        style={{ fontWeight: "bold" }}
                      >
                        Mobile Number:
                      </Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant={"body1"}>
                        {applicant.mobileNumber}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/*HOUSE NUMBER*/}
                  <Grid
                    item
                    container
                    alignItems={"center"}
                    style={{ marginTop: "0.5em" }}
                  >
                    <Grid item md={4}>
                      <Typography
                        variant={"body1"}
                        style={{ fontWeight: "bold" }}
                      >
                        House Number:
                      </Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant={"body1"}>
                        {applicant.housePhoneNumber}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/*DATE OF BIRTH*/}
                  <Grid
                    item
                    container
                    alignItems={"center"}
                    style={{ marginTop: "0.5em" }}
                  >
                    <Grid item md={4}>
                      <Typography
                        variant={"body1"}
                        style={{ fontWeight: "bold" }}
                      >
                        DOB:
                      </Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant={"body1"}>
                        {applicant.dob
                          ? format(parseISO(applicant?.dob), "MMM do y")
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/*GENDER*/}
                  <Grid
                    item
                    container
                    alignItems={"center"}
                    style={{ marginTop: "0.5em" }}
                  >
                    <Grid item md={4}>
                      <Typography
                        variant={"body1"}
                        style={{ fontWeight: "bold" }}
                      >
                        Gender:
                      </Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant={"body1"}>
                        {applicant.gender}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/*ETHNIC BACKGROUND*/}
                  <Grid
                    item
                    container
                    alignItems={"center"}
                    style={{ marginTop: "0.5em" }}
                  >
                    <Grid item md={4}>
                      <Typography
                        variant={"body1"}
                        style={{ fontWeight: "bold" }}
                      >
                        Ethnic Background:
                      </Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant={"body1"}>
                        {applicant.ethnicBackground}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/*CITIZENSHIP STATUS*/}
                  <Grid
                    item
                    container
                    alignItems={"center"}
                    style={{ marginTop: "0.5em" }}
                  >
                    <Grid item md={4}>
                      <Typography
                        variant={"body1"}
                        style={{ fontWeight: "bold" }}
                      >
                        Citizenship Status:
                      </Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant={"body1"}>
                        {applicant.citizenshipStatus}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/*ADDRESS*/}
                  <Grid item container style={{ marginTop: "0.5em" }}>
                    <Grid item md={4}>
                      <Typography
                        variant={"body1"}
                        style={{ fontWeight: "bold" }}
                      >
                        Address:
                      </Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant={"body1"}>
                        {applicant.address1},<br />
                        {applicant.address2
                          ? applicant.address2 + ","
                          : null}{" "}
                        <br />
                        {applicant.city}, {applicant.state},<br />
                        {applicant.zipCode}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/*BIOGRAPHY*/}
                  <Grid item container style={{ marginTop: "1.5em" }}>
                    <Grid item md={4}>
                      <Typography
                        variant={"body1"}
                        style={{ fontWeight: "bold" }}
                      >
                        Biography:
                      </Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant={"body1"}>
                        {applicant.description}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/*AFFILIATE*/}
                  <Grid item container style={{ marginTop: "0.5em" }}>
                    <Grid item md={4}>
                      <Typography
                        variant={"body1"}
                        style={{ fontWeight: "bold" }}
                      >
                        Affiliate:
                      </Typography>
                    </Grid>
                    <Grid item md={8}>
                      {profile.affiliateName && (
                        <Typography variant={"body1"}>
                          {profile.affiliateName}
                        </Typography>
                      )}
                      {!profile.affiliateName && (
                        <Typography variant={"body1"}>No Affiliate</Typography>
                      )}
                    </Grid>
                  </Grid>
                  {/*AFFILIATE CODE*/}
                  <Grid item container style={{ marginTop: "0.5em" }}>
                    <Grid item md={4}>
                      <Typography
                        variant={"body1"}
                        style={{ fontWeight: "bold" }}
                      >
                        Affiliate Code:
                      </Typography>
                    </Grid>
                    <Grid item md={8}>
                      {profile.affiliateCode && (
                        <Typography variant={"body1"}>
                          {profile.affiliateCode}
                        </Typography>
                      )}
                      {!profile.affiliateCode && (
                        <Typography variant={"body1"}>No Affiliate</Typography>
                      )}
                    </Grid>
                  </Grid>

                  {/*REGISTERED PROGRAMS*/}
                  {registeredPrograms.length > 0 && (
                    <Grid item container style={{ marginTop: "1.5em" }}>
                      <Grid item md={4}>
                        <Typography
                          variant={"body1"}
                          style={{ fontWeight: "bold" }}
                        >
                          Registered Programs:
                        </Typography>
                      </Grid>
                      <Grid item md={8}>
                        <ul>
                          {registeredPrograms.map((program) => (
                            <li>
                              <Typography variant={"body1"}>
                                {program}
                              </Typography>
                            </li>
                          ))}
                        </ul>
                      </Grid>
                    </Grid>
                  )}
                </Fragment>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ margin: "0 2em" }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: "2em" }}
            >
              <Typography variant={"h6"}>Admin Notes</Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setDialog(true)}
              >
                Add Notes
              </Button>
            </Grid>
            <Box height={320} width="100%" style={{ marginTop: "2em" }}>
              <DataGridPremium
                columns={memoizedColumns}
                rows={filteredRows}
                disableSelectionOnClick
                disableColumnMenu
                rowsPerPageOptions={[]}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    value: searchText,
                    clearSearch: () => requestSearch(""),
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "adminNote", validate })(AdminViewApplicant));
