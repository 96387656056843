import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import {
  applicantAcceptApplication,
  applicantDeclineApplication,
  applicantAcceptApplicationWithoutChecklist2,
} from '../../../../store/actions/applicantActions/programActions/applicantAcceptanceActions';

const actions = {
  applicantAcceptApplication,
  applicantDeclineApplication,
  applicantAcceptApplicationWithoutChecklist2,
};
// useEffect(() => {
//   if (approvedStages.every((obj) => obj.show === false)) {
//     setEvery(true);
//   }
// }, [approvedStages]);

const AcceptanceStage = ({
  registeredProgram,
  applicantAcceptApplication,
  approvedStages,
  approvedStagesTravel,
  applicantAcceptApplicationWithoutChecklist2,
  applicantDeclineApplication,
}) => {
  // const handleAcceptApplication = () => {
  //   applicantAcceptApplication(registeredProgram.id, registeredProgram);
  // };

  const handleDeclineApplication = () => {
    applicantDeclineApplication(registeredProgram.id);
  };
  console.log(approvedStages, 'approvedStages');
  /// call this function when we click on Accept Button and we have to implememt stage complete and finalSubmissionCompleted is true if no item on checklist 2
  const handleAcceptApplication = () => {
    if (approvedStages.every((obj) => obj.show === false)) {
      ///////// make a store same as a applicantAcceptApplication and make program stage is complete
      applicantAcceptApplicationWithoutChecklist2(
        registeredProgram.id,
        registeredProgram
      );
    } else {
      applicantAcceptApplication(registeredProgram.id, registeredProgram);
    }
  };
  return (
    <Grid item>
      <Grid item container>
        <Grid item md={12}>
          <Grid item container direction={'column'}>
            <Grid item style={{ marginTop: '2em' }}>
              <Grid item container>
                <Grid item>
                  <Button
                    variant={'outlined'}
                    //color={"primary"}
                    size={'large'}
                    style={{ color: 'green', borderColor: 'green' }}
                    onClick={() => handleAcceptApplication()}
                  >
                    Accept
                  </Button>
                </Grid>

                <Grid item style={{ marginLeft: '1em' }}>
                  <Button
                    variant={'outlined'}
                    color={'secondary'}
                    size={'large'}
                    onClick={() => handleDeclineApplication()}
                  >
                    Decline
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(null, actions)(AcceptanceStage);
