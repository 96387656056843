import React, { useEffect, useMemo, useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Box,
  Checkbox,
} from "@mui/material";
import Sidebar from "../../../../ui/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { connect } from "react-redux";
import {
  deleteAffiliate,
  fetchAllAffiliates,
  updateAffiliateField,
} from "../../../../store/actions/adminActions/affiliateActions/adminAffiliateActions";
import { CustomToolbar, escapeRegExp } from "../../../../common/tableUtils";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#171725",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight: "24px",
    letter: "0.1px",
  },
}));

const actions = {
  fetchAllAffiliates,
  deleteAffiliate,
  updateAffiliateField,
};

const mapStateToProps = (state) => {
  let affiliates = [];

  if (
    state.adminAffiliates.allAffiliates &&
    state.adminAffiliates.allAffiliates.length > 0
  ) {
    affiliates = state.adminAffiliates.allAffiliates;
  }

  return {
    affiliates,
  };
};

const AdminAffillates = ({
  fetchAllAffiliates,
  deleteAffiliate,
  affiliates,
  updateAffiliateField,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [affiliateId, setAffiliateId] = useState(null);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      "e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    );
  }, []);

  useEffect(() => {
    const arr = affiliates.map((affiliate, index) => {
      return {
        ...affiliate,
        serialNo: index + 1,
      };
    });
    setRows(arr);
    if (searchText) {
      setSearchText(searchText);
      const searchRegex = new RegExp(escapeRegExp(searchText), "i");
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
    }
  }, [affiliates]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filtered = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };

  useEffect(() => {
    fetchAllAffiliates();
  }, [fetchAllAffiliates]);

  const handleDeleteAffiliate = async () => {
    await deleteAffiliate(affiliateId);

    setDialogOpen(false);
  };

  const memoizedColumns = useMemo(() => {
    return [
      {
        field: "serialNo",
        headerName: "Id",
        width: 80,
      },
      {
        field: "affiliateName",
        headerName: "Affiliate Name",
        width: 260,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "affiliateCode",
        headerName: "Affiliate Code",
        width: 200,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "numberOfUses",
        headerName: "# of Uses",
        width: 100,
      },
      {
        field: "addedByName",
        headerName: "Added By",
        width: 150,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "applicationFeeWaiver",
        headerName: "Application Fee Waiver",
        width: 180,
        renderCell: (param) => (
          <Checkbox
            checked={param.value}
            onChange={(event, checked) => {
              event.stopPropagation();
              updateAffiliateField(
                param.row.id,
                "applicationFeeWaiver",
                checked
              );
            }}
            disabled
          />
        ),
      },
      {
        field: "extendedDeadline",
        headerName: "Extended Application Deadline",
        width: 160,
        renderCell: (param) => (
          <Checkbox
            checked={param.value}
            onChange={(event, checked) => {
              event.stopPropagation();
              updateAffiliateField(param.row.id, "extendedDeadline", checked);
            }}
            disabled
          />
        ),
      },
      {
        field: "extendedPaymentDeadline",
        headerName: "Extended Payment Deadline",
        width: 210,
        renderCell: (param) => (
          <Checkbox
            checked={param.value}
            onChange={(event, checked) => {
              event.stopPropagation();
              updateAffiliateField(
                param.row.id,
                "extendedPaymentDeadline",
                checked
              );
            }}
            disabled
          />
        ),
      },
      {
        field: "scholarshipWaiver",
        headerName: "Scholarship Waiver",
        width: 210,
        renderCell: (param) => (
          <Checkbox
            checked={param.value}
            onChange={(event, checked) => {
              event.stopPropagation();
              updateAffiliateField(param.row.id, "scholarshipWaiver", checked);
            }}
            disabled
          />
        ),
      },
      {
        field: "sponsorship",
        headerName: "Sponsorship",
        width: 210,
        renderCell: (param) => (
          <Checkbox
            checked={param.value}
            onChange={(event, checked) => {
              event.stopPropagation();
              updateAffiliateField(param.row.id, "sponsorship", checked);
            }}
            disabled
          />
        ),
      },
      {
        field: "",
        headerName: "Actions",
        width: 280,
        valueGetter: (param) => param.value || "N/A",
        renderCell: (param) => (
          <Box display="flex" justifyContent="space-evenly" width="100%">
            <Button
              variant={"contained"}
              color={"primary"}
              size={"small"}
              onClick={(event) => {
                event.stopPropagation();
                history.push(
                  `/admin/settings/affiliates/view?id=${param.row.id}`
                );
              }}
            >
              View
            </Button>
            <Button
              style={{
                marginLeft: "0.5em",
                backgroundColor: theme.palette.info.main,
                color: "white",
              }}
              variant={"contained"}
              size={"small"}
              onClick={(event) => {
                event.stopPropagation();
                history.push(
                  `/admin/settings/affiliates/edit?id=${param.row.id}`
                );
              }}
            >
              Edit
            </Button>
            <Button
              style={{
                marginLeft: "0.5em",
                backgroundColor: theme.palette.error.main,
                color: "white",
              }}
              variant={"contained"}
              size={"small"}
              onClick={(event) => {
                event.stopPropagation();
                setAffiliateId(param.row.id);
                setDialogOpen(true);
              }}
            >
              Delete
            </Button>
          </Box>
        ),
      },
    ];
  }, [updateAffiliateField, setAffiliateId, theme, history]);

  return (
    <Grid container>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you wish to delete affiliate?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteAffiliate()}
            style={{ color: theme.palette.error.main }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Grid item md={2}>
        <Sidebar />
      </Grid>

      <Grid
        item
        md={10}
        style={{
          backgroundColor: "#FAFAFB",
          paddingLeft: "41px",
          paddingRight: "41px",
        }}
      >
        <Grid item container direction={"column"}>
          <Grid
            item
            style={{ margin: "20px 0" }}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className={classes.title}>Affiliates</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/admin/settings/affiliates/new")}
            >
              Create Affiliate
            </Button>
          </Grid>

          <Box height={420} width="100%">
            <DataGridPremium
              columns={memoizedColumns}
              rows={filteredRows}
              pageSize={5}
              disableSelectionOnClick
              disableColumnMenu
              rowsPerPageOptions={[]}
              // onRowClick={(param) => {
              //   history.push(
              //     `/admin/settings/affiliates/view?id=${param.row.id}`
              //   )
              // }}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  value: searchText,
                  clearSearch: () => requestSearch(""),
                },
              }}
            />
          </Box>
          <Grid item style={{ marginTop: "2em" }}></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(AdminAffillates);
