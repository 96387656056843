import React, {
  useCallback,
  useRef,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Box, Typography, Dialog, Button, Tooltip } from '@mui/material';
import Sidebar from '../../../ui/sidebar/Sidebar';
import { fetchRegisteredInfo } from '../../../store/actions/adminActions/transactionActions/transactionActions';
import { fetchAllInstitutions } from '../../../store/actions/adminActions/institutionActions/adminInstitutionActions';

import { connect } from 'react-redux';
import { format, fromUnixTime } from 'date-fns';
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from '@mui/x-data-grid-premium';

import { Field } from 'redux-form';
import TextArea from '../../../common/form/TextArea';
import { reduxForm } from 'redux-form';
import { combineValidators } from 'revalidate';
import { formValueSelector } from 'redux-form';
import { makeStyles } from '@mui/styles';
import { escapeRegExp } from '../../../common/tableUtils';
import SendEmailDialog from '../../../dialogs/admin/SendEmailDialog';
import { sendGenericEmail } from '../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import DashboardApplicantsTable from '../../../ui/admin/dashboard/DashboardApplicantsTable';
import DashboardRequestInfoTable from '../../../ui/admin/dashboard/DashboardRequestInfoTable';
import { fetchAllTemplates } from '../../../store/actions/adminActions/settingsActions/settingsActions';
import SendEmailRequestDialog from '../../../dialogs/admin/SendEmailRequestDialog';

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#171725',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '24px',
    letter: '0.1px',
  },
  applicationsLengths: {
    marginLeft: '1rem',
    color: '#171725',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '24px',
  },
  applicationsLength: {
    marginLeft: '1rem',
  },
  status: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .succeeded': {
      background: 'rgb(215, 247, 194)',
      color: 'rgb(5, 105, 13)',
      borderRadius: 4,
      padding: '1px 6px',
    },
    '& .failed': {
      background: 'rgb(255, 231, 242)',
      color: 'rgb(179, 6, 61)',
      borderRadius: 4,
      padding: '1px 6px',
    },
    '& .canceled': {
      background: 'rgb(235, 238, 241)',
      color: 'rgb(84, 90, 105)',
      borderRadius: 4,
      padding: '1px 6px',
    },
  },
}));

const validate = combineValidators({
  processingFee: (value) => {
    if (!value) return 'Required';
    if (value === '.') return 'Invalid  value';
  },
});

const actions = {
  fetchRegisteredInfo,
  fetchAllInstitutions,
  fetchAllTemplates,
  sendGenericEmail,
};

const mapStateToProps = (state) => {
  console.log(state, 'state');

  let transactions = [];
  let applications = [];
  let RegisteredInfo = [];
  let templates = [];

  if (
    state.registeredInfo.allRequestedInfo &&
    state.registeredInfo.allRequestedInfo?.data?.length > 0
  ) {
    RegisteredInfo = state.registeredInfo.allRequestedInfo.data;
  }
  if (
    state.adminSettings.allTemplates &&
    state.adminSettings.allTemplates.length > 0
  ) {
    templates = state.adminSettings.allTemplates;
  }

  //   if (state.adminDashboard.dashboardValues) {
  //     if (
  //       state.adminTransactions.users &&
  //       state.adminTransactions.users.length > 0
  //     ) {
  //       applications = state.adminTransactions.users;
  //     }
  //   }

  const questionSelector = formValueSelector('processingFeeForm');
  const fieldValues = questionSelector(state, 'processingFee');

  return {
    transactions,
    applications,
    RegisteredInfo,
    // sendGenericEmail,
    templates,
    profile: state.firebase.profile,

    fieldValues,
    initialValues: {
      processingFee: state.applicationSettings.processingFee,
    },
  };
};

const normalizeFee = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d+(.\d{1,2})?$]/g, '');
  const cvLength = currentValue.length;
  if (cvLength > 4) return currentValue.slice(0, 4);
  return currentValue;
};

const RequestedInfo = ({
  fetchRegisteredInfo,
  fetchAllInstitutions,
  RegisteredInfo,
  fetchAllTemplates,
  sendGenericEmail,
  handleSubmit,
  templates,
  profile,
  change,
  submitting,
}) => {
  const classes = useStyles();
  const history = useHistory();
  console.log(templates, 'templates');
  console.log(RegisteredInfo, 'RegisteredInfo');
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [dialog, setDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectionModel, setSelectionModel] = useState([]);
  const [toValue, setToValue] = useState('');
  const [filteredApplicants, setFilteredApplicants] = useState(RegisteredInfo);
  const [editorValue, setEditorValue] = useState('');
  useEffect(() => {
    fetchAllTemplates();
  }, [fetchAllTemplates]);
  const grid2 = useRef(null);
  console.log(filteredRows, 'filteredRows');
  useEffect(() => {
    const emails = selectionModel
      .map((id) => {
        if (filteredApplicants[id - 1]) return filteredApplicants[id - 1].email;
        return '';
      })
      .filter((email) => email && email);
    let updatedTo = '';
    emails.forEach((email) => (updatedTo += `${email},`));
    change('to', updatedTo);
  }, [selectionModel, filteredApplicants]);
  console.log(page, 'page');

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      'e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
    );
  }, []);

  useEffect(() => {
    const search = history.location.search;
    if (search.includes('?institute')) {
      const institute = search.split('=')[1];
      setFilteredApplicants(
        RegisteredInfo.filter((applicant) => {
          if (applicant.institutionDetails?.institutionName === institute) {
            return true;
          }
          return false;
        })
      );
    } else {
      setFilteredApplicants(RegisteredInfo);
    }
  }, [history.location.search, RegisteredInfo]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchRegisteredInfo();
      await fetchAllInstitutions();
    };

    fetchData();
    setPage(0);
  }, [fetchRegisteredInfo, fetchAllInstitutions]);
  useEffect(() => {
    const arr = RegisteredInfo.map((transaction, index) => {
      return {
        ...transaction,
        id: index + 1,
        serialNo: index + 1,
      };
    });

    setRows(arr);

    if (searchText) {
      setSearchText(searchText);
      const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
    }
  }, [RegisteredInfo, searchText, page, pageSize]);

  //   useEffect(() => {
  //     if (fieldValues) change('processingFee', normalizeFee(fieldValues));
  //   }, [fieldValues, change]);
  const handlePageChange = useCallback(
    (params) => {
      console.log(params.page, 'llll');
      setPage(params.page);
    },
    [setPage]
  );

  const handlePageSizeChange = useCallback(
    (params) => {
      console.log(params.pageSize, 'kjhg');
      setPageSize(params.pageSize);
    },
    [setPageSize]
  );
  const [selectedApplicantRowIds, setSelectedApplicantRowIds] = useState([]);
  // const [reload, setReload] = useState(false);
  console.log(selectedApplicantRowIds, 'selectedApplicantRowIds');

  console.log(fetchAllTemplates, 'fetchAllTemplates');
  console.log(RegisteredInfo, 'RegisteredInfo');
  console.log(fetchAllInstitutions, 'fetchAllInstitutions');
  const handleAction = () => {};
  useEffect(() => {
    const arr = RegisteredInfo.map((transaction, index) => {
      console.log(transaction, 'transaction');
      //   const applicant = RegisteredInfo.find(
      //     (application) => application.id === transaction.client_reference_id
      //   );
      return {
        ...transaction,
        serialNo: index + 1,
        // createdAt:
        //   transaction?.createdAt &&
        //   format(
        //     new Date(fromUnixTime(transaction?.createdAt)),
        //     'EEE MMM do y'
        //   ),
      };
    });
    setRows(arr);
    if (searchText) {
      setSearchText(searchText);
      const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
    }
  }, [RegisteredInfo, searchText, page, pageSize, fetchRegisteredInfo]);

  // Inside your component
  const [prevSelectedApplicantRowIds, setPrevSelectedApplicantRowIds] =
    useState([]);
  const handleSelectionModelChange = (selectedIds) => {
    setPrevSelectedApplicantRowIds([...selectedApplicantRowIds]); // Save previous state
    setSelectedApplicantRowIds(selectedIds);
  };

  console.log(prevSelectedApplicantRowIds, 'prevSelectedApplicantRowIds');
  // const handleSelectionModelChange = (selectedIds) => {
  //   // Map the selected row IDs to their corresponding indices
  //   const selectedIndices = selectedIds.map((id) =>
  //     rows.findIndex((row) => row.id === id)
  //   );

  //   // Do whatever you need with the selected indices
  //   console.log(selectedIndices, 'selectedIndices');

  //   // Update the state or perform other actions as needed
  //   setSelectedApplicantRowIds(selectedIndices);
  //   // setSelectionModel(selectedIndices);
  // };

  const memoizedColumns = useMemo(() => {
    return [
      {
        field: 'serialNo',
        headerName: 'Id',
        width: 100,
      },

      {
        field: 'firstName',
        headerName: 'firstName',
        width: 220,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'lastName',
        headerName: 'lastName',
        width: 90,
        // renderCell: (cell) => `$${cell.value}`,
        valueGetter: (param) => param.value || 'N/A',
      },

      {
        field: 'email',
        headerName: 'Email',
        width: 260,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'state',

        headerName: 'State',
        width: 160,
        valueGetter: (param) => param.value || 'N/A',
      },
      // {
      //   field: 'recommendertype',
      //   headerName: 'Recommender type',
      //   width: 160,
      //   valueGetter: (param) => param.value || 'N/A',
      // },
    //  {
     //   field: 'gradeLevel',
     //   headerName: 'Grade Level',
     //   width: 180,
      //  valueGetter: (param) => param.value || 'N/A',
     // },
      {
        field: 'gradYear',
        headerName: 'Expected Graduation',
        width: 160,

        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'programInterested',
        headerName: 'Program Interested',
        width: 160,

        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'mobileNumber',
        headerName: 'Phone',
        width: 160,

        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        width: 180,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'highSchool',
        headerName: 'High School',
        width: 180,
        valueGetter: (param) => param.value || 'N/A',
      },
      // {
      //   field: 'actions',
      //   headerName: 'Actions',
      //   width: 120,

      //   renderCell: (params) => (
      //     <Button
      //       variant={'contained'}
      //       color={'primary'}
      //       onClick={() => handleAction(params.row)}
      //     >
      //       Send Email
      //     </Button>
      //   ),
      // },
    ];
  }, []);
  console.log(rows, 'rows');
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filtered = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };
  const handleSendEmail = async (values) => {
    const emails = [];
    if (values.to)
      values.to
        .trim()
        .split(',')
        .forEach((email) => email && emails.push(email.trim()));
    if (emails.length > 0) {
      await sendGenericEmail(
        values.subject,
        editorValue,
        emails,
        profile.fullName
      );
    }
    setDialog(false);
  };
  // const handleUpdateProcessingFee = async (values) => {
  //   await updateProcessingFee(values.processingFee);
  //   setDialog(false);
  // };

  return (
    <Grid container>
      <Dialog open={dialog} onClose={() => setDialog(false)} maxWidth="md">
        <form>
          {/* <DialogTitle>Edit Processing Fee</DialogTitle>
          <DialogContent>
            <Field
              name={'processingFee'}
              label={'Processing Fee'}
              component={TextArea}
              type={'text'}
              variant={'outlined'}
            />
          </DialogContent> */}
          {/* <DialogActions>
            <Button
              onClick={() => setDialog(false)}
              color="secondary"
              disabled={submitting}
            >
              Cancel
            </Button>
            <Button color={'primary'} type={'submit'} disabled={submitting}>
              Update
            </Button>
          </DialogActions> */}
        </form>
      </Dialog>
      <Grid item md={2}>
        <Sidebar />
      </Grid>
      <Grid
        item
        md={10}
        style={{
          backgroundColor: '#FAFAFB',

          paddingLeft: '41px',
          paddingRight: '41px',
        }}
      >
        <Grid item container direction={'column'}>
          <Grid
            item
            container
            style={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <Typography className={classes.applicationsLengths}>
                Requested Info
              </Typography>
              <Typography className={classes.applicationsLength} variant="h6">
                {RegisteredInfo.length}
              </Typography>
            </Box>
            <Box display="flex" gridGap={16}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  let selectedApplicantNames = [];
                  if (selectedApplicantRowIds.length)
                    selectedApplicantRowIds.map((selectedRowId) => {
                      const selectedApplicant = filteredApplicants?.find(
                        (x, i) => i === selectedRowId - 1
                      );
                      console.log(selectedApplicant, 'selectedApplicant');
                      if (
                        selectedApplicant &&
                        Object.keys(selectedApplicant).length
                      ) {
                        selectedApplicantNames.push(selectedApplicant?.email);
                      }
                    });
                  const selectedNames = selectedApplicantNames.join(', ');
                  console.log(selectedNames, 'op');
                  setToValue(selectedNames);
                  setDialog(true);
                }}
              >
                {/*Emailss*/}
                Send Emails
              </Button>
              {/* <Select
                  value={selectedTemplate}
                  onChange={(event) => {
                    setSelectedTemplate(event.target.value);
                  }}
                >
                  {templates.map((i, j) => (
                    <MenuItem key={i.id} value={i?.templateName}>
                    
                      {i.templateName}
                    </MenuItem>
                  ))}
                  Send Emails
                </Select> */}
              {/* <Button
                variant="outlined"
                color="primary"
                onClick={() => history.push('/admin/dashboard/emailLogs')}
              >
                Email History
              </Button> */}
              {/* <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  history.push(`/admin/program/dashboard?id=${program.id}`)
                }
              >
                Program Dashboard
              </Button> */}
              <Tooltip title="Refresh"></Tooltip>
            </Box>
          </Grid>
          <Grid item style={{ marginTop: '2em' }}>
            {/* <Box height={684} width="100%">
              <DataGridPremium
                ref={grid2}
                checkboxSelection={true}
                columns={memoizedColumns}
                rows={filteredRows}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                onSelectionModelChange={handleSelectionModelChange}
                // reloadApplicants={handleReloadApplicants}
                pageSize={20}
                // initialState={{
                //   pagination: {
                //     paginationModel: { pageSize: 20, page: 0 },
                //   },
                // }}
                pagination={true}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    value: searchText,

                    clearSearch: () => requestSearch(''),
                  },
                }}
                // onPageChange={handlePageChange}
                // onPageSizeChange={handlePageSizeChange}
                // onPageChange={(params) => setPage(params.page)}
                // onPageSizeChange={(params) => setPageSize(params.pageSize)}
                disableSelectionOnClick
              />
            </Box> */}

            <DashboardRequestInfoTable
              // program={program}
              applicants={filteredApplicants}
              checkboxSelection
              selectionModel={selectionModel}
              setSelectionModel={setSelectionModel}
              onSelectionModelChange={handleSelectionModelChange}
              // reloadApplicants={handleReloadApplicants}
            />

            <SendEmailRequestDialog
              isOpen={dialog}
              templates={templates}
              closeDialog={setDialog}
              setDialog={setDialog}
              handleSubmit={handleSubmit}
              submitting={submitting}
              handleSendEmail={handleSendEmail}
              editorValue={editorValue}
              setEditorValue={setEditorValue}
              toValue={toValue}
              setToValue={setToValue}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,

  actions
)(reduxForm({ form: 'sendEmail', validate })(RequestedInfo));
