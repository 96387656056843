import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from '../asyncActions/asyncActions';

import { FETCH_PROGRAM_FOR_REVIEW } from '../../constants/reviewConstants/reviewConstants';
import { fetchDashboardValues } from '../adminActions/dashboardActions/dashboardActions';
import { getUnixTime } from 'date-fns';

export const fetchProgramForReview = (id, program) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const programsQuery = firestore.collection('programs');
    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());
      let query = await registeredProgramQuery.get();

      let institutions = [];
      let institutionName = '';

      if (program) {
        const institutionFormQuery = firestore.collection('institutions');

        let testQuery = await institutionFormQuery.get();

        for (let i = 0; i < testQuery.docs.length; i++) {
          if (testQuery.docs[i].data()?.programs.includes(program.programId)) {
            let institution = {
              ...testQuery.docs[i].data(),
              id: testQuery.docs[i].id,
            };
            institutions.push(institution);
          }

          if (
            query.data()?.studentQuestionnaireFormDetails?.assignedInstitute ===
            testQuery.docs[i].id
          ) {
            institutionName = testQuery.docs[i].data()?.institutionName;
          }
        }
      }

      let application = [];

      console.log('query.exists', query.exists);
      if (query.exists) {
        let programDetails = await programsQuery
          .doc(`${query.data().programId}`)
          .get();

        let program = {
          id: query.id,
          ...query.data(),
          programDetails: programDetails.data(),
          institutions: institutions,
        };

        if (
          program?.studentQuestionnaireFormDetails &&
          Object.keys(program?.studentQuestionnaireFormDetails).length
        ) {
          program.studentQuestionnaireFormDetails.instituteName =
            institutionName;
        }

        application.push(program);
      }

      if (!query.exists) {
        dispatch(asyncActionFinish());
        return;
      }

      dispatch({
        type: FETCH_PROGRAM_FOR_REVIEW,
        payload: { application },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateReview = (
  registeredProgramId,
  values,
  totalScore,
  averageScore,
  isReviewed,
  currentForm,
  reviewedBy
) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${registeredProgramId}`);
    let query = await registeredProgramQuery.get();
    let reviewedForms = query.data().reviewedForms;

    Object.keys(values).forEach(
      (key) => values[key] === undefined && delete values[key]
    );

    try {
      dispatch(asyncActionStart());

      let reviewerUpdated = getUnixTime(new Date());

      await registeredProgramQuery.update({
        reviewStatus: isReviewed ? 'reviewed' : 'assigned',
        programStage: isReviewed ? 'reviewed' : 'under-review',
        reviewTotalScore: totalScore,
        reviewAverageScore: averageScore,
        reviewerUpdated: reviewerUpdated,
        reviewedForms: reviewedForms
          ? reviewedForms.includes(currentForm)
            ? reviewedForms
            : [...reviewedForms, currentForm]
          : [currentForm],
        ...values,
        reviewerResponse: isReviewed
          ? {
            ...values,
            reviewedOn: new Date().toLocaleString(),
            reviewedBy: reviewedBy,
          }
          : null,
        logs: query.data().logs
          ? [
            ...query.data().logs,
            {
              event: 'Application Reviewed',
              date: reviewerUpdated,
            },
          ]
          : [
            {
              event: 'Application Reviewed',
              date: reviewerUpdated,
            },
          ],
      });

      dispatch(fetchDashboardValues());
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateAdminReview = (
  registeredProgramId,
  values,
  totalScore,
  averageScore,
  programStage,
  currentForm,
  programCost,
  togglePlacemnet
) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      const firestore = getFirestore();

      const registeredProgramQuery = firestore
        .collection('registeredPrograms')
        .doc(`${registeredProgramId}`);
      let query = await registeredProgramQuery.get();
      let reviewedForms = query.data()?.reviewedForms
        ? query.data().reviewedForms
        : [];

      let skipTravelForm = false;

      Object.keys(values).forEach(
        (key) => values[key] === undefined && delete values[key]
      );

      if (
        values.chosenInstitution &&
        values.chosenInstitution !== 'noInstitution'
      ) {
        const institutionQuery = firestore
          .collection('institutions')
          .doc(`${values.chosenInstitution}`);
        const institution = await institutionQuery.get();
        if (institution.data().virtual) skipTravelForm = true;
      }
      dispatch(asyncActionStart());
      let reviewerUpdated = getUnixTime(new Date());

      const logs = [
        {
          event: 'Application Reviewed',
          date: reviewerUpdated,
        },
      ];

      if (
        values.scholarshipAmount &&
        values.chosenInstitution !== 'noInstitution' &&
        query.data().chosenInstitution !== values.chosenInstitution
      )
        logs.push({
          event: 'Institution Assigned',
          date: reviewerUpdated,
        });

      if (values.comments)
        logs.push({
          event: 'Admin comment added',
          date: reviewerUpdated,
        });


      await registeredProgramQuery.update({
        reviewStatus: 'reviewed',
        reviewerUpdated: reviewerUpdated,
        reviewTotalScore: totalScore,
        reviewAverageScore: averageScore,
        reviewerResponse: {
          reviewedOn: new Date().toLocaleString(),
        },
        programStage: programStage,
        // scholarshipApproved: scholarshipDecision,
        extraCurricularCommunityActivityValue:
          values?.extraCurricularCommunityActivityValue,
        academicPerformanceValue: values.academicPerformanceValue,
        essayQualityValue: values.essayQualityValue,
        recommendationQualityValue: values.recommendationQualityValue,
        scholarshipAmount: values.scholarshipAmount
          ? values.scholarshipAmount
          : '',
        sponsorshipAmount: values.sponsorshipAmount
          ? values.sponsorshipAmount
          : '',
        chosenInstitution: values?.chosenInstitution,
        chosenInstitution2: togglePlacemnet ? values?.chosenInstitution2 : firestore.FieldValue.delete(),

        comments: values.comments ? values.comments : '',
        reviewedForms: reviewedForms
          ? reviewedForms.includes(currentForm)
            ? reviewedForms
            : [...reviewedForms, currentForm]
          : [currentForm],
        travelInformationFormCompleted: skipTravelForm,
        logs: query.data()?.logs ? [...query.data().logs, ...logs] : logs,
        payment1Received: query.data()?.payment1Received
          ? query.data().payment1Received
          : parseFloat(values.scholarshipAmount) >= parseFloat(programCost)
            ? true
            : false,
        payment2Received: query.data()?.payment2Received
          ? query.data().payment2Received
          : parseFloat(values.scholarshipAmount) >= parseFloat(programCost)
            ? true
            : false,
        payment1Amount: query.data()?.payment1Amount
          ? query.data().payment1Amount
          : parseFloat(values.scholarshipAmount) >= parseFloat(programCost)
            ? 'waived'
            : '',
        payment2Amount: query.data()?.payment2Amount
          ? query.data().payment2Amount
          : parseFloat(values.scholarshipAmount) >= parseFloat(programCost)
            ? 'waived'
            : '',
      });

      // dispatch(fetchDashboardValues());
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateProgramStage = (
  registeredProgramId,
  programStage,
  applicationValue,
  declineComment = null
) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${registeredProgramId}`);

    // console.log(applicationValue)

    const logs = {
      approved: 'Application Marked Approved',
      denied: 'Application Marked Denied',
      waitlisted: 'Application Marked Waitlisted',
      withdrawn: 'Application Marked Withdrawn',
    };

    try {
      dispatch(asyncActionStart());
      const registeredProgram = await registeredProgramQuery.get();
      let updated = getUnixTime(new Date());
      await registeredProgramQuery.update({
        // fix early admission
        // applicationApproved: programStage === 'waitlisted' ? true : false,
        applicationApproved: applicationValue,

        programStage:
          programStage === 'restore'
            ? registeredProgram.data()?.previousStage
            : programStage,

        declineComment: declineComment ? declineComment : '',

        editiedAt: updated,

        previousStage:
          programStage === 'withdrawn'
            ? registeredProgram.data().programStage
            : '',

        logs:
          programStage !== 'restore'
            ? registeredProgram.data()?.logs
              ? [
                ...registeredProgram.data().logs,
                {
                  event: logs[programStage],
                  date: getUnixTime(new Date()),
                },
              ]
              : [
                {
                  event: logs[programStage],
                  date: getUnixTime(new Date()),
                },
              ]
            : registeredProgram.data().logs,
        confirmationFormCompleted: false,
        confirmationFormDetails: {},
      });
      // dispatch(fetchDashboardValues())
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateChosenInstitution = (
  registeredProgramId,
  chosenInstitution
) => {
  console.log(registeredProgramId, chosenInstitution, 'testing');
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${registeredProgramId}`);

    try {
      dispatch(asyncActionStart());
      let updated = getUnixTime(new Date());
      await registeredProgramQuery.update({
        chosenInstitution: chosenInstitution,
        editiedAt: updated,
      });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
export const updateChosenInstitution2 = (
  registeredProgramId,
  chosenInstitution
) => {
  console.log(registeredProgramId, chosenInstitution, 'testing');
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${registeredProgramId}`);

    try {
      dispatch(asyncActionStart());
      let updated = getUnixTime(new Date());
      await registeredProgramQuery.update({
        chosenInstitution2: chosenInstitution,
        editiedAt: updated,
      });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
export const handleProgramRollback = (registeredProgramId) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${registeredProgramId}`);
    // console.log(registeredProgramQuery,"registeredProgramQuerys")

    // console.log(applicationValue)

    try {
      dispatch(asyncActionStart());
      const registeredProgram = await registeredProgramQuery.get();
      let updated = getUnixTime(new Date());
      await registeredProgramQuery.update({
        applicationAccepted: false,
        applicationApproved: false,
        applicationCompleted: false,
        // { ------------IMPORTANT-------------------------
        // i commented below field because acc to requirement when we click on rollback we
        // have to delete this field and in function of delete it delete that field but
        // after some second it again add it just bcoz of another function call in that function we add field
        // that why i commented so that it can not be again added in our database field
        // }
        // confirmationFormCompleted: false,
        programStage: 'in-progress',
        confirmationFormCompleted: false,
        confirmationFormDetails: {},
        editiedAt: updated,
        logs: registeredProgram.data().logs
          ? [
            ...registeredProgram.data().logs,
            {
              event: 'Application Rolled back to Application Checklist',
              date: getUnixTime(new Date()),
            },
          ]
          : [
            {
              event: 'Application Rolled back to Application Checklist',
              date: getUnixTime(new Date()),
            },
          ],
      });

      // dispatch(fetchDashboardValues())
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
export const handleDeletehandleProgramRollback = (registeredProgramId) => {
  console.log(registeredProgramId, 'registeredProgramId');
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${registeredProgramId}`);
    console.log(registeredProgramQuery, 'registeredProgramQuery');

    try {
      dispatch(asyncActionStart());
      const updateObject = {
        confirmationFormDetails: firestore.FieldValue.delete(),
        confirmationFormCompleted: firestore.FieldValue.delete(),
      };

      await registeredProgramQuery.update(updateObject);

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
