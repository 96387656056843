import React, { Fragment } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import { Field, FieldArray } from "redux-form";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import TextInputFieldArray from "../../../../../common/form/TextInputFieldArray";

const useStyles = makeStyles((theme) => ({
  formName: {
    color: "#696974",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight: "72px",
    letter: "0.2px",
  },
  formText: {
    color: "#696974",
    fontWeight: "400",
    fontFamily: "Roboto",
    fontSize: "16px",
    letter: "0.2px",
  },
  formQuestionText: {
    color: "#696974",
    fontWeight: "600",
    fontFamily: "Roboto",
    fontSize: "16px",
    letter: "0.2px",
    paddingRight: "1em",
  },
  fieldWrapper: {
    paddingTop: "0.4em",
    paddingBottom: "0.4em",
  },
}));

const AdditionalSchoolCommunityActivitiesForm = () => {
  const classes = useStyles();
  const theme = useTheme();

  // const activityRequired = (value) => (value ? undefined : "Required");
  // const involvementRequired = (value) => (value ? undefined : "Required");

  const schoolActivitiesField = ({ fields, meta: { error, submitFailed } }) => (
    <Fragment>
      {fields.length < 1 && (
        <Grid
          item
          container
          className={classes.fieldContainer}
          alignItems={"center"}
        >
          <Grid item lg={2} md={2} sm={2} xs={2}>
            {/*BLANK*/}
          </Grid>
          <Grid item lg={10} md={10} sm={10} xs={10}>
            <Grid item container alignItems={"center"}>
              <Grid item>
                <IconButton
                  aria-label="add role"
                  onClick={() => {
                    fields.push({});
                  }}
                >
                  <AddCircleIcon color={"primary"} />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography className={classes.formText}>
                  Add additional School or Community Activity
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {fields.map((additionalActivity, index) => (
        <Fragment key={index}>
          {/*FIELDS*/}
          <Grid
            item
            container
            style={{ marginTop: "1em" }}
            alignItems={"center"}
          >
            <Grid item lg={4} md={2} sm={2} xs={2}>
              <Grid item container direction={"column"}>
                <Grid item>
                  <Typography className={classes.formQuestionText}>
                    Activity
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <Grid item style={{ width: "50%" }}>
                <Grid item container direction={"column"}>
                  <Grid item className={classes.fieldWrapper}>
                    <Field
                      name={`${additionalActivity}.activity`}
                      component={TextInputFieldArray}
                      type={"text"}
                      variant={"outlined"}
                      // validate={activityRequired}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            className={classes.fieldContainer}
            alignItems={"center"}
          >
            <Grid item lg={4} md={2} sm={2} xs={2}>
              <Grid item container direction={"column"}>
                <Grid item>
                  <Typography className={classes.formQuestionText}>
                    Involvement
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <Grid item style={{ width: "50%" }}>
                <Grid item container direction={"column"}>
                  <Grid item className={classes.fieldWrapper}>
                    <Field
                      name={`${additionalActivity}.involvement`}
                      component={TextInputFieldArray}
                      type={"text"}
                      variant={"outlined"}
                      // validate={involvementRequired}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/*ACTION BUTTONS*/}
          <Grid
            item
            container
            className={classes.fieldContainer}
            alignItems={"center"}
          >
            <Grid item lg={2} md={2} sm={2} xs={2}>
              {/*BLANK*/}
            </Grid>
            <Grid item lg={10} md={10} sm={10} xs={10}>
              <Grid item container direction={"column"}>
                <Grid item>
                  <Grid item container alignItems={"center"}>
                    <Grid item>
                      <IconButton
                        aria-label="add debt"
                        onClick={() => {
                          fields.push({});
                        }}
                      >
                        <AddCircleIcon color={"primary"} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.formText}>
                        Add additional School or Community Activity
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid item container alignItems={"center"}>
                    <Grid item>
                      <IconButton
                        aria-label="delete debt"
                        onClick={() => fields.remove(index)}
                      >
                        <CancelIcon
                          style={{ color: theme.palette.error.main }}
                        />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.formText}>
                        Delete additional School or Community Activity
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      ))}
    </Fragment>
  );
  return (
    <Grid container justifyContent={"center"} direction={"column"}>
      <FieldArray
        name="additionalSchoolCommunityActivities"
        component={schoolActivitiesField}
      />
    </Grid>
  );
};

export default AdditionalSchoolCommunityActivitiesForm;
