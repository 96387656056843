import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import {
  formValueSelector,
  isInvalid,
  reduxForm,
  change,
  getFormValues,
} from 'redux-form';

import { combineValidators, isNumeric, isRequired } from 'revalidate';

import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import TextInputForm from '../../../../forms/TextInputForm';
import SelectInputForm from '../../../../forms/SelectInputForm';

import AdditionalOfficeHeldLeadershipRoleForm from './AdditionalOfficeHeldLeadershipRoleForm';
import AdditionalSchoolCommunityActivitiesForm from './AdditionalSchoolCommunityActivitiesForm';
import SelectInputFormOnChangeYesNo from '../../../../forms/SelectInputFormOnChangeYesNo';
import {
  submitExtracurricularActivitiesForm,
  updateExtracurricularActivitiesForm,
} from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import { closeDialog } from '../../../../../store/actions/dialogActions/dialogActions';
import toast, { Toaster } from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: '0.4em',
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
    },
  },

  continueButton: {
    marginLeft: '0.4em',
    backgroundColor: '#1976d2',
    color: 'white !Important',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
      //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    },
  },
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  formName: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '72px',
    letter: '0.2px',
  },
  formText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  formQuestionText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
}));

const validate = combineValidators({
  leadershipRole: isRequired({ message: 'Required' }),
  responsibility: isRequired({ message: 'Required' }),
  activity: isRequired({ message: 'Required' }),
  involvement: isRequired({ message: 'Required' }),
  howDidYouHearAbout: isRequired({ message: 'Required' }),
  pleaseSpecify: isRequired({ message: 'Required' }),
  ///// below both not used anywhere for required//////
  // name: isRequired({ message: "Required" }),
  // standardizedScore: isNumeric({ message: "Required" }),
});
const arr = {
  leadershipRole: '',
  responsibility: '',
  activity: '',
  involvement: '',
  howDidYouHearAbout: '',
  pleaseSpecify: '',
  // name: "",
  // standardizedScore: "",
};

const actions = {
  submitExtracurricularActivitiesForm,
  updateExtracurricularActivitiesForm,
  change,
};

const mapStateToProps = (state) => {
  const questionSelector = formValueSelector('extracurricularEmploymentForm');
  const fieldValues = questionSelector(state, 'id', 'activity');
  const invalid = isInvalid('extracurricularEmploymentForm');
  return {
    fieldValues,
    invalid,
    initialValues:
      state.applicantPrograms.registeredProgram[0].extracurricularFormDetails,
    currentValues: getFormValues('extracurricularEmploymentForm')(state),
  };
};

const ExtracurricularEmploymentForm = ({
  fieldValues,
  invalid,
  registeredProgramId,
  change,
  submitExtracurricularActivitiesForm,
  updateExtracurricularActivitiesForm,
  handleCloseForm,
  handleSetForm,
  handleSubmit,
  // error,
  submitting,
  currentValues,
  program,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const [howDidYouHearAbout, setHowDidYouHearAbout] = useState('');

  useEffect(() => {
    change('pleaseSpecify', '');
  }, [howDidYouHearAbout]);
  const programsAllThings = [
    'applicationInterests',
    'personalInformationForm',
    'familyInformationForm',
    'academicInformationForm',
    // 'recommendationsRequired',
    'recommendersForm',
    'extracurricularForm',
    'covidProtocolForm',
    'resumeForm',
    'transcriptForm',
    'shortResponsesForm',
    'scholarshipForm',
    'taxForm',
    'additionalDocumentsForm',
    'confirmationForm',
  ];

  const programAllThingsValue = {
    applicationInterests: 'applicationInterests',
    personalInformationForm: 'personalInformationForm',
    familyInformationForm: 'familyInformationForm',
    academicInformationForm: 'academicInformationForm',
    // recommendationsRequired: 'recommendationsRequired',
    recommendersForm: 'recommendersForm',
    extracurricularForm: 'extracurricularForm',
    covidProtocolForm: 'covidProtocolForm',
    resumeForm: 'resumeForm',
    transcriptForm: 'transcriptForm',
    shortResponsesForm: 'shortResponsesForm',
    scholarshipForm: 'scholarshipForm',
    taxForm: 'taxForm',
    additionalDocumentsForm: 'additionalDocumentsForm',
    confirmationForm: 'confirmationForm',
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(closeDialog());
  };
  let dataToCheck = programsAllThings
    .filter((item) => program[item])
    .map((item) => programAllThingsValue[item]);

  const handleFormSubmit = async (values) => {
    try {
      await submitExtracurricularActivitiesForm(registeredProgramId, values);
      handleCloseForm();
      const indexOfPersonalInfoForm = dataToCheck.indexOf(
        'extracurricularForm'
      );
      if (
        indexOfPersonalInfoForm !== -1 &&
        indexOfPersonalInfoForm < dataToCheck.length - 1
      ) {
        const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
        handleSetForm(nextForm);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const notify = () => toast.success('Progress Autosaved.');

  const handleFormSave = async (values) => {
    console.log(values, 'jk');
    if (values == undefined) {
      return;
    }
    if (values.pleaseSpecify == undefined) {
      values.pleaseSpecify = '';
    }
    function hasAllKeys(arr, values) {
      for (const key in arr) {
        if (arr.hasOwnProperty(key) && !values.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    }

    // Check if all keys from object A exist in object B
    const conditionMet = hasAllKeys(arr, values);
    if (conditionMet) {
      values.allRequiredDocumentFilled = true;
    }
    if (!conditionMet) {
      values.allRequiredDocumentFilled = false;
    }
    try {
      await updateExtracurricularActivitiesForm(registeredProgramId, values);
      handleCloseForm();
    } catch (error) {
      console.log(error);
    }
  };
  const handleFormAutoSave = async (values) => {
    console.log(values, 'jk');
    if (values == undefined) {
      return;
    }
    if (values.pleaseSpecify == undefined) {
      values.pleaseSpecify = '';
    }
    function hasAllKeys(arr, values) {
      for (const key in arr) {
        if (arr.hasOwnProperty(key) && !values.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    }

    // Check if all keys from object A exist in object B
    const conditionMet = hasAllKeys(arr, values);
    if (conditionMet) {
      values.allRequiredDocumentFilled = true;
    }
    if (!conditionMet) {
      values.allRequiredDocumentFilled = false;
    }
    try {
      await updateExtracurricularActivitiesForm(registeredProgramId, values);
      // handleCloseForm();
      notify();
    } catch (error) {
      console.log(error);
    }
  };
  const saveInterval = 5 * 60 * 1000;
  useEffect(() => {
    const saveFormInterval = setInterval(() => {
      handleFormAutoSave(currentValues);
    }, saveInterval);
    return () => clearInterval(saveFormInterval);
  }, [currentValues]);
  return (
    <Fragment>
      <Toaster />

      <form autoComplete={'off'} onSubmit={handleSubmit(handleFormSubmit)}>
        <Fragment>
          <Typography className={classes.formName}>
            Extracurricular, Community Activities and Employment Information
          </Typography>

          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid item container direction={'column'}>
                <Grid item>
                  <Grid
                    item
                    container
                    className={classes.fieldContainer}
                    alignItems={'center'}
                  >
                    <Grid item>
                      <Typography
                        variant={'body1'}
                        className={classes.formQuestionText}
                      >
                        Leadership Role: List any offices held and/or
                        membership(s) since the beginning of 9th grade:
                      </Typography>
                    </Grid>
                  </Grid>

                  <TextInputForm
                    fieldName={'leadershipRole'}
                    question={
                      'Office Held/Leadership Role. if not applicable, use ‘N/A’” to this question and the activities question below it. '
                    }
                  />
                  <TextInputForm
                    fieldName={'responsibility'}
                    question={'Responsibility'}
                  />

                  <AdditionalOfficeHeldLeadershipRoleForm />

                  <Grid
                    item
                    container
                    className={classes.fieldContainer}
                    alignItems={'center'}
                    style={{ marginTop: '2em' }}
                  >
                    <Grid item>
                      <Typography
                        variant={'body1'}
                        className={classes.questionText}
                      >
                        List school and community activities most important to
                        you since the beginning of 9th grade:
                      </Typography>
                    </Grid>
                  </Grid>

                  <TextInputForm fieldName={'activity'} question={'Activity'} />

                  <TextInputForm
                    fieldName={'involvement'}
                    question={'Involvement'}
                  />

                  <AdditionalSchoolCommunityActivitiesForm />

                  <SelectInputFormOnChangeYesNo
                    fieldName={'howDidYouHearAbout'}
                    question={'How did you hear about the program/LEAD?'}
                    onChange={setHowDidYouHearAbout}
                    options={[
                      { value: '' },
                      { value: 'LEAD' },
                      { value: 'Teacher/Counselor' },
                      { value: 'LEAD Alumni (please specify)' },
                      { value: 'Social Media (please specify)' },
                      { value: 'Other (please specify)' },
                    ]}
                  />

                  {(howDidYouHearAbout === 'LEAD Alumni (please specify)' ||
                    howDidYouHearAbout === 'Social Media (please specify)' ||
                    howDidYouHearAbout === 'Other (please specify)') && (
                    <TextInputForm
                      fieldName={'pleaseSpecify'}
                      question={'Please specify'}
                    />
                  )}
                  {howDidYouHearAbout === 'Teacher/Counselor' && (
                    <TextInputForm
                      fieldName={'pleaseSpecify'}
                      question={'Please specify'}
                    />
                  )}

                  <TextInputForm
                    fieldName={'familyMemberName'}
                    question={
                      '(Optional) Name of any family member who participated in the LEAD Program, along with SBI or GSLI:'
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>

        <Grid item style={{ marginTop: '1.5em' }}>
          {invalid && (
            <Grid item container style={{ marginBottom: '0.5em' }}>
              <Grid item style={{ marginLeft: 'auto' }}>
                <Typography
                  variant={'subtitle1'}
                  style={{ color: theme.palette.error.main, fontWeight: 300 }}
                >
                  Fill out all required fields.
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid item container>
            <Grid item style={{ marginLeft: 'auto' }}>
              <Button
                variant={'outlined'}
                color={'primary'}
                className={classes.button}
                onClick={() => handleFormSave(currentValues)}
              >
                Save for Later
              </Button>

              <Button
                variant={'outlined'}
                color={'primary'}
                className={classes.continueButton}
                type={'submit'}
                disabled={submitting}
              >
                Continue{' '}
                <span>
                  <strong> &#8594;</strong>
                </span>
              </Button>

              <Button
                variant={'outlined'}
                color={'secondary'}
                className={classes.button}
                onClick={() => handleCloseForm()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: 'extracurricularEmploymentForm',
    enableReinitialize: true,
    validate,
  })(ExtracurricularEmploymentForm)
);
