import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import ReviewProgramItem from "./item/ReviewProgramItem";
import axios from "../../config/axios";
import Iframe from 'react-iframe';


const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  answerText: {
    fontWeight: 600,
  },
}));

const GrantAcceptanceFormReview = ({
  grantAcceptanceForm,
  registeredProgram,
}) => {
  const classes = useStyles();

  const [showLink, setShowLink] = useState(false);
  const [formLink, setFormLink] = useState("");

  useEffect(() => {
    if (registeredProgram) {
      axios
        .get("/documentLinkFunctions/documentDownloadLink", {
          params: {
            docId: registeredProgram.grantAcceptanceFormDocNo,
          },
        })
        .then((res) => {
          setFormLink(res.data.link);
          setShowLink(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // {
    //   registeredProgram.grantAcceptanceFormCompleted &&
    //     axios
    //       .get("/documentLinkFunctions/documentDownloadLink", {
    //         params: {
    //           docId: registeredProgram.grantAcceptanceFormDocNo,
    //         },
    //       })
    //       .then((res) => {
    //         setFormLink(res.data.link);
    //         setShowLink(true);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    // }
  }, [registeredProgram]);

  return (
    <Grid
      container
      direction={"column"}
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
    >
      {/*RECOMMENDATIONS*/}
      <Grid item>
        <Typography className={classes?.title}>Grant Acceptance</Typography>
      </Grid>

      {/*RESPONSES*/}

      {/*APPLICATION FEE*/}
      {registeredProgram?.scholarConsentFormDetails &&
        (Array.isArray(registeredProgram?.scholarConsentFormDetails)
          ? registeredProgram?.scholarConsentFormDetails.map((form, i) => (
            <>  <ReviewProgramItem
                key={i}
                question={form.originalFileName}
                value={
                  <a
                    href={form.fileURL}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                  >
                    Download Document
                  </a>
                }
              />
              <Iframe url={form.fileURL}
            width="100%"
            height="800px"
            id=""
            className=""
            display="block"
            position="relative" />  </>
            ))
          : Object.keys(registeredProgram?.scholarConsentFormDetails).map(
              (key) =>
                registeredProgram?.scholarConsentFormDetails[key].map(
                  (form, i) => (
                 <>   <ReviewProgramItem
                      key={i}
                      question={form.originalFileName}
                      value={
                        <a
                          href={form.fileURL}
                          target={"_blank"}
                          rel={"noopener noreferrer"}
                        >
                          Download Document
                        </a>
                      }
                    />
                    <Iframe url={form.fileURL}
            width="100%"
            height="800px"
            id=""
            className=""
            display="block"
            position="relative" /></>
                  )
                )
                
            ))}
    </Grid>
  );
};

export default GrantAcceptanceFormReview;
