import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import { Grid, Typography } from "@mui/material";

// STRIPE
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "@mui/material/Button";
import axios from "../../../../../../config/axios";

import { connect } from "react-redux";
import { updatePayment1Paid } from "../../../../../../store/actions/applicantActions/programActions/applicantApprovedActions";

const mapStateToProps = (state) => {
  return {
    processingFee: state.applicationSettings.processingFee,
  };
};

const actions = {
  updatePayment1Paid,
  // updateApplicationFeePaid,
};

const Payment1Form = ({
  registeredProgramId,
  registeredProgram,
  program,
  programCost,
  scholarshipAmount,
  updatePayment1Paid,
  handleCloseForm,
  profile,
  processingFee,
}) => {
  const theme = useTheme();

  const stripe = useStripe();
  const elements = useElements();

  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(null);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [totalFee, setTotalFee] = useState("");

  useEffect(() => {
    const getClientSecret = async () => {
      const paymentAmount =
        ((Number(programCost) - Number(scholarshipAmount)) / 2) * 100;
      const processingFee = (paymentAmount / 100) * 2.9;
      const response = await axios({
        method: "post",
        url: `stripeFunctions/payments/create?total=${
          paymentAmount + processingFee
        }`,
      });
      setClientSecret(response.data.clientSecret);
      setTotalFee(((paymentAmount + processingFee) / 100).toFixed(2));
    };
    getClientSecret();
  }, [programCost, scholarshipAmount]);

  const recieptEmail = registeredProgram.email;
  const handleSubmit = async (e) => {
    e.preventDefault();

    setProcessing(true);

    // const payload = await stripe
    await stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: `${profile.fullName}`,
            email: `${profile.email}`,
            // phone: "+13236207662",
          },
        },
        receipt_email: `${recieptEmail}`,
      })
      .then(({ paymentIntent, error }) => {
        const paymentFee =
          (Number(programCost) - Number(scholarshipAmount)) / 2;
        const currentPaymentAmount = registeredProgram.paymentAmount
          ? Number(registeredProgram.paymentAmount)
          : 0;
        // const currentPaymentAmount =
        if (paymentIntent) {
          updatePayment1Paid(
            registeredProgramId,
            registeredProgram,
            paymentIntent.id,
            program,
            profile.uid,
            paymentFee,
            currentPaymentAmount,
            profile.fullName
          );
          setSucceeded(true);
          setError(null);
          setProcessing(false);
          handleCloseForm();
        }
        if (error) {
          setSucceeded(false);
          setError(error.message);
          setProcessing(false);
        }
      });
  };

  const handleCardChange = (e) => {
    setDisabled(e.empty);
    setError(e.error ? e.error.message : "");
  };

  return (
    <Grid item>
      <Grid item container direction={"column"}>
        {/*APPLICATION FEE FORM*/}
        <Grid
          item
          container
          direction={"column"}
          style={{
            paddingLeft: "4em",
            paddingRight: "4em",
            marginTop: "3em",
            backgroundColor: "lightgrey",
          }}
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid
              item
              container
              direction={"column"}
              style={{ padding: "2em" }}
            >
              <Grid item>
                <Typography variant={"h6"} style={{ fontWeight: 600 }}>
                  Payment 1
                </Typography>
              </Grid>

              <Grid item style={{ marginTop: "2em" }}>
                <Typography variant={"body1"}>
                  A ${(Number(programCost) - Number(scholarshipAmount)) / 2}{" "}
                  payment amount is required. Payments made by credit card will
                  incur an additional ${processingFee || 2.9}% processing fee.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid item container>
              <Grid item md={6} style={{ padding: "2em" }}>
                <Grid
                  item
                  container
                  direction={"column"}
                  style={{
                    backgroundColor: "white",
                    padding: "2em",
                    borderRadius: "5px",
                  }}
                >
                  <Grid item style={{ marginTop: "1em", marginBottom: "1em" }}>
                    <form onSubmit={handleSubmit}>
                      <CardElement onChange={handleCardChange} />

                      <Button
                        disabled={processing || disabled || succeeded}
                        style={{ marginTop: "1.5em" }}
                        variant={"outlined"}
                        color={"primary"}
                        type={"submit"}
                      >
                        {processing ? "Processing" : `Pay $${totalFee}`}
                      </Button>
                      {error && (
                        <div
                          style={{
                            marginTop: "1em",
                            color: theme.palette.error.main,
                          }}
                        >
                          {error}
                        </div>
                      )}
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(Payment1Form);
