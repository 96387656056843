import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Sidebar from "../../../../ui/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { combineValidators, isRequired } from "revalidate";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import TextInput from "../../../../common/form/TextInput";

import { useHistory } from "react-router-dom";

import { addNewAffiliate } from "../../../../store/actions/adminActions/affiliateActions/adminAffiliateActions";
import DatePickerInput from "../../../../common/form/DatePickerInput";
import { formValueSelector } from "redux-form";
import CheckboxInput from "../../../../common/form/CheckboxInput";
import { fetchAllPrograms } from "../../../../store/actions/adminActions/programActions/adminProgramActions";
const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "0.4em",
    paddingBottom: "0.4em",
    width:'100%',
  },
  icon: {
    fontSize: "3em",
    color: theme.palette.common.textGrey,
  },

  dropzone: {
    width: 160,
    height: 160,
  },
  image: {
    width: 160,
    height: 160,
  },
}));

const validate = combineValidators({
  affiliateName: isRequired({ message: "Name is required" }),
  affiliateCodeExpiry: isRequired({ message: "Date is required" }),
  extendedDeadlineDate: isRequired({ message: "Date is required" }),
  // extendedPaymentDeadline: isRequired({ message: 'Date is required' }),
});

const actions = {
  fetchAllPrograms,
  addNewAffiliate,
};

const mapStateToProps = (state) => {
  let programs = [];

  const questionSelector = formValueSelector("createAffiliate");
  const fieldValues = questionSelector(
    state,
    "applicationFeeWaiver",
    "extendedDeadline",
    "extendedPaymentDeadline"
  );

  if (
    state.adminPrograms.allPrograms &&
    state.adminPrograms.allPrograms.length > 0
  ) {
    programs = state.adminPrograms.allPrograms;
  }

  return {
    loading: state.loading.loading,
    programs,
    initialValues: {
      programsAffiliated:
        state.adminAffiliates.affiliate[0]?.programsAffiliated || [],
    },
    fieldValues,
  };
};

const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>;
  }
};

const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl error={touched && error} style={{ width: "50%" }} size="small">
    <InputLabel htmlFor="color-native-simple">{label}</InputLabel>

    <Select
      // native
      {...input}
      {...custom}
      variant="outlined"
      multiple
      inputProps={{
        name: input.name,
        id: "color-native-simple",
      }}
    >
      {children}
    </Select>
    {renderFromHelper({ touched, error })}
  </FormControl>
);

const AdminNewAffillate = ({
  addNewAffiliate,
  handleSubmit,
  loading,
  // error,
  submitting,
  change,
  programs,
  fieldValues,
  fetchAllPrograms,
}) => {
  useEffect(() => {
    if (programs.length === 0) fetchAllPrograms();
  }, [programs, fetchAllPrograms]);
  const classes = useStyles();

  const history = useHistory();

  const handleCreateAffiliate = async (values) => {
    await addNewAffiliate(values);
    history.push("/admin/settings/affiliates");
  };
  return (
    <Grid container>
      <Grid item md={2}>
        <Sidebar />
      </Grid>

      <Grid item md={10} style={{ paddingLeft: "1.5em" }}>
        <Grid item container direction={"column"}>
          <Grid item>
            <Typography variant={"h4"}>Create Affiliate</Typography>
          </Grid>
          <Grid item style={{ marginTop: "0.5em", marginBottom: "1em" }}></Grid>

          <form
            autoComplete={"off"}
            onSubmit={handleSubmit(handleCreateAffiliate)}
          >
            <Grid item style={{ width: "50%" }}>
              <Grid item container direction={"column"}>
                <Grid item className={classes.fieldWrapper}>
                  <Field
                    name={"affiliateName"}
                    label={"Affiliate Name"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                  />
                </Grid>
                {programs.length > 0 && (
                  <Grid item className={classes.fieldWrapper}>
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      className={classes.fieldWrapper}
                    >
                      <Typography>Programs Affiliated To:</Typography>
                      <Field
                        name={"programsAffiliated"}
                        component={renderSelectField}
                        onChange={(val, selectedOptions) => {
                          if (change) {
                            change("areaOfInterest", selectedOptions);
                          }
                        }}
                      >
                        <MenuItem value={""}></MenuItem>
                        {programs.map((program) => (
                          <MenuItem value={program.id}>
                            {program.programName}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.fieldWrapper}
                >
                  <Typography>Application Fee Waiver</Typography>
                  <Field
                    name={"applicationFeeWaiver"}
                    component={CheckboxInput}
                    size="medium"
                    color="secondary"
                  />
                </Grid>
                {fieldValues?.applicationFeeWaiver && (
                  <Grid item className={classes.fieldWrapper}>
                    <Field
                      name={"affiliateCodeExpiry"}
                      label={"Expiry Date"}
                      component={DatePickerInput}
                      type={"text"}
                      inputVariant={"outlined"}
                      size={"small"}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.fieldWrapper}
                >
                  <Typography>Extended Application Deadline</Typography>
                  <Field
                    name={"extendedDeadline"}
                    component={CheckboxInput}
                    size="medium"
                    color="secondary"
                  />
                </Grid>
                {fieldValues?.extendedDeadline && (
                  <Grid item className={classes.fieldWrapper}>
                    <Field
                      name={"extendedDeadlineDate"}
                      label={"Extended Application Deadline Date"}
                      component={DatePickerInput}
                      type={"text"}
                      inputVariant={"outlined"}
                      size={"small"}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.fieldWrapper}
                >
                  <Typography>Extended Payment Deadline</Typography>
                  <Field
                    name={"extendedPaymentDeadline"}
                    component={CheckboxInput}
                    size="medium"
                    color="secondary"
                  />
                </Grid>
                {fieldValues?.extendedPaymentDeadline && (
                  <Grid item className={classes.fieldWrapper}>
                    <Field
                      name={"extendedPaymentDeadline"}
                      label={"Extended Payment Deadline Date"}
                      component={DatePickerInput}
                      type={"text"}
                      inputVariant={"outlined"}
                      size={"small"}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.fieldWrapper}
                >
                  <Typography>Scholarship Waiver</Typography>
                  <Field
                    name={"scholarshipWaiver"}
                    component={CheckboxInput}
                    size="medium"
                    color="secondary"
                  />
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.fieldWrapper}
                >
                  <Typography>Sponsorship</Typography>
                  <Field
                    name={"sponsorship"}
                    component={CheckboxInput}
                    size="medium"
                    color="secondary"
                  />
                </Grid>
                {/*<Grid item className={classes.fieldWrapper}>*/}
                {/*  <Field*/}
                {/*    name={"affiliateAddress2"}*/}
                {/*    label={"Address 2"}*/}
                {/*    component={TextInput}*/}
                {/*    type={"text"}*/}
                {/*    variant={"outlined"}*/}
                {/*  />*/}
                {/*</Grid>*/}
                {/*<Grid item className={classes.fieldWrapper}>*/}
                {/*  <Field*/}
                {/*    name={"affiliateCity"}*/}
                {/*    label={"City"}*/}
                {/*    component={TextInput}*/}
                {/*    type={"text"}*/}
                {/*    variant={"outlined"}*/}
                {/*  />*/}
                {/*</Grid>*/}
                {/*<Grid item className={classes.fieldWrapper}>*/}
                {/*  <Field*/}
                {/*    name={"affiliateState"}*/}
                {/*    label={"State"}*/}
                {/*    component={SelectInput}*/}
                {/*    type={"text"}*/}
                {/*    variant={"outlined"}*/}
                {/*  >*/}
                {/*    {states.map((state) => (*/}
                {/*      <MenuItem key={state.value} value={state.text}>*/}
                {/*        {state.text}*/}
                {/*      </MenuItem>*/}
                {/*    ))}*/}
                {/*  </Field>*/}
                {/*</Grid>*/}
                {/*<Grid item className={classes.fieldWrapper}>*/}
                {/*  <Field*/}
                {/*    name={"affiliateZipCode"}*/}
                {/*    label={"Zip"}*/}
                {/*    component={TextInput}*/}
                {/*    type={"text"}*/}
                {/*    variant={"outlined"}*/}
                {/*  />*/}
                {/*</Grid>*/}
                {/*<Grid item className={classes.fieldWrapper}>*/}
                {/*  <Field*/}
                {/*    name={"affiliatePhone"}*/}
                {/*    label={"Phone"}*/}
                {/*    component={TextInput}*/}
                {/*    type={"text"}*/}
                {/*    variant={"outlined"}*/}
                {/*  />*/}
                {/*</Grid>*/}
                {/*<Grid item className={classes.fieldWrapper}>*/}
                {/*  <Field*/}
                {/*    name={"affiliateEmail"}*/}
                {/*    label={"Email"}*/}
                {/*    component={TextInput}*/}
                {/*    type={"text"}*/}
                {/*    variant={"outlined"}*/}
                {/*  />*/}
                {/*</Grid>*/}
                {/*<Grid item className={classes.fieldWrapper}>*/}
                {/*  <Field*/}
                {/*    name={"affiliateWeb"}*/}
                {/*    label={"Web"}*/}
                {/*    component={TextInput}*/}
                {/*    type={"text"}*/}
                {/*    variant={"outlined"}*/}
                {/*  />*/}
                {/*</Grid>*/}

                {/*<Grid item className={classes.fieldWrapper}>*/}
                {/*  <Field*/}
                {/*    name={"affiliateNotes"}*/}
                {/*    label={"Institution Notes"}*/}
                {/*    component={TextArea}*/}
                {/*    rows={5}*/}
                {/*    type={"text"}*/}
                {/*    variant={"outlined"}*/}
                {/*  />*/}
                {/*</Grid>*/}

                {/*BUTTONS*/}
                <Grid item style={{ marginLeft: "auto" }}>
                  <Button
                    variant={"outlined"}
                    color={"primary"}
                    className={classes.button}
                    type={"submit"}
                    disabled={submitting}
                  >
                    Create Affiliate
                  </Button>

                  <Button
                    variant={"outlined"}
                    color={"secondary"}
                    className={classes.button}
                    onClick={() => history.push("/admin/settings/affiliates")}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "createAffiliate", validate })(AdminNewAffillate));
