import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from '../../asyncActions/asyncActions';
import {
  FETCH_ALL_COMPLETED_APPLICATIONS,
  FETCH_ALL_DASHBOARD_VALUES,
  FETCH_ALL_REVIEWED_APPLICATIONS,
  FETCH_ALL_FILTERS,
  FETCH_ALL_EMAILS,
} from '../../../constants/adminConstants/dashboardConstants/dashboardConstants';

import { format, fromUnixTime, getUnixTime } from 'date-fns';

export const fetchAllCompletedApplications = () => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const applicationQuery = firestore
      .collection('registeredPrograms')
      .where('applicationCompleted', '==', true)
      .orderBy('fullName', 'asc');

    const programsQuery = firestore.collection('programs');
    const usersQuery = firestore.collection('users');

    try {
      dispatch(asyncActionStart());
      let query = await applicationQuery.get();

      let completedApplications = [];

      for (let i = 0; i < query.docs.length; i++) {
        let program = await programsQuery
          .doc(`${query.docs[i].data().programId}`)
          .get();
        let reviewer = await usersQuery
          .doc(`${query.docs[i].data().reviewerId}`)
          .get();

        let application = {
          ...query.docs[i].data(),
          id: query.docs[i].id,
          programDetails: program.data(),
          reviewerDetails: reviewer.data(),
        };
        completedApplications.push(application);
      }
      dispatch({
        type: FETCH_ALL_COMPLETED_APPLICATIONS,
        payload: { completedApplications },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const fetchAllReviewedApplications = () => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const applicationQuery = firestore
      .collection('registeredPrograms')
      .where('reviewStatus', '==', 'reviewed')
      .orderBy('fullName', 'asc');

    const programsQuery = firestore.collection('programs');
    const usersQuery = firestore.collection('users');
    const institutionQuery = firestore.collection('institutions');

    try {
      dispatch(asyncActionStart());
      let query = await applicationQuery.get();

      let reviewedApplications = [];

      for (let i = 0; i < query.docs.length; i++) {
        let program = await programsQuery
          .doc(`${query.docs[i].data().programId}`)
          .get();
        let reviewer = await usersQuery
          .doc(`${query.docs[i].data().reviewerId}`)
          .get();

        let applicant = await usersQuery
          .doc(`${query.docs[i].data().applicantId}`)
          .get();

        const chosenInstitutionId = query.docs[i].data().chosenInstitution;
        let institution;
        if (chosenInstitutionId) {
          institution = await institutionQuery
            .doc(`${chosenInstitutionId}`)
            .get();
        }

        const payment1PaymentId = query.docs[i].data().payment1PaymentId;
        let payment1Query;
        if (payment1PaymentId) {
          payment1Query = await firestore
            .collection('paymentTransactions')
            .where('paymentId', '==', payment1PaymentId)
            .get();
        }
        const payment2PaymentId =
          payment1PaymentId && query.docs[i].data().payment2PaymentId;
        let payment2Query;
        if (payment2PaymentId) {
          payment2Query = await firestore
            .collection('paymentTransactions')
            .where('paymentId', '==', payment2PaymentId)
            .get();
        }

        // APPLICATION START DATE
        const applicationStartUnixTime = query.docs[i].data().createdAt;
        const applicationStartDate = fromUnixTime(applicationStartUnixTime);
        const applicationReadableDate = format(
          applicationStartDate,
          'MM/dd/yyyy'
        );

        // SUBMISSION DATE
        const submissionUnixTime = query.docs[i].data().editiedAt;
        const submissionDate = fromUnixTime(submissionUnixTime);
        const submissionReadableDate = format(submissionDate, 'MM/dd/yyyy');

        // REVIEWER UPDATED DATE
        const reviewerUpdatedUnixTime = query.docs[i].data().reviewerUpdated;
        const reviewerUpdatedDate = fromUnixTime(reviewerUpdatedUnixTime);
        const reviewerUpdatedReadableDate = format(
          reviewerUpdatedDate,
          'MM/dd/yyyy'
        );

        let application = {
          ...query.docs[i].data(),
          id: query.docs[i].id,
          programDetails: program.data(),
          reviewerDetails: reviewer.data(),
          applicantDetails: applicant.data(),
          applicationStarted: applicationReadableDate,
          submissionReadableDate: submissionReadableDate,
          reviewerUpdatedReadableDate: reviewerUpdatedReadableDate,
          institutionDetails: chosenInstitutionId && institution.data(),
          payment1Details: payment1PaymentId && {
            ...payment1Query.docs[0].data(),
          },
          payment2Details: payment2PaymentId && {
            ...payment2Query.docs[0].data(),
          },
        };
        reviewedApplications.push(application);
      }
      dispatch({
        type: FETCH_ALL_REVIEWED_APPLICATIONS,
        payload: { reviewedApplications },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const fetchDashboardValues = (dashboardView) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const applicantsQuery = firestore.collection('registeredPrograms');

    const programsQuery = firestore.collection('programs');

    const paymentsQuery = firestore.collection('paymentTransactions');

    const personalInformationQuery = firestore.collection('users');

    const institutionsQuery = firestore.collection('institutions');

    const affiliatesQuery = firestore.collection('affiliates');

    try {
      dispatch(asyncActionStart());

      let applicantQuery = await applicantsQuery.get();
      let programQuery = await programsQuery.get();
      let paymentQuery = await paymentsQuery.get();
      let institutionQuery = await institutionsQuery.get();
      let userQuery = await personalInformationQuery.get();
      let affiliates = await affiliatesQuery.get();

      // TOTAL REVENUE
      let totalRevenue = 0;
      for (let i = 0; i < paymentQuery.docs.length; i++) {
        let value = Number(paymentQuery.docs[i].data().paymentFee);
        totalRevenue = totalRevenue + value;
      }

      let revenue = [];
      for (let i = 0; i < paymentQuery.docs.length; i++) {
        let details = {
          id: paymentQuery.docs[i].id,
          ...paymentQuery.docs[i].data(),
        };
        revenue.push(details);
      }

      // APPLICANTS
      let allApplicants = [];
      let applicants = [];
      let applicantsApproved = [];
      let completedApplications = [];
      let reviewedApplications = [];
      let scholarshipApplications = [];
      for (let i = 0; i < applicantQuery.docs.length; i++) {
        let userInformation = userQuery.docs.find(
          (user) => user.id === applicantQuery.docs[i].data().applicantId
        );

        const reviewerId = applicantQuery.docs[i].data().reviewerId;
        let reviewerInformation;
        if (reviewerId) {
          reviewerInformation = userQuery.docs.find(
            (reviewer) => reviewer.id === reviewerId
          );
        }

        const payment1PaymentId =
          applicantQuery.docs[i].data().payment1PaymentId;
        let payment1Details;
        if (payment1PaymentId) {
          payment1Details = paymentQuery.docs.find(
            (payment) => payment.data().paymentId === payment1PaymentId
          );
        }
        const payment2PaymentId =
          applicantQuery.docs[i].data().payment2PaymentId;
        let payment2Details;
        if (payment2PaymentId) {
          payment2Details = paymentQuery.docs.find(
            (payment) => payment.data().paymentId === payment2PaymentId
          );
        }

        const chosenInstitutionId =
          applicantQuery.docs[i].data().chosenInstitution;
        let institution;
        if (chosenInstitutionId) {
          institution = institutionQuery.docs.find(
            (inst) => inst.id === chosenInstitutionId
          );
        }

        let filteredProgram = programQuery.docs.find(
          (program) => program.id === applicantQuery.docs[i].data().programId
        );

        if (userInformation) {
          let details = {
            id: userInformation.id,
            applicantUid: applicantQuery.docs[i].id,
            ...userInformation.data(),
            programDetails: filteredProgram && filteredProgram.data(),
            reviewerDetails: reviewerInformation && reviewerInformation.data(),
            payment1Details: payment1Details && payment1Details.data(),
            payment2Details: payment2Details && payment2Details.data(),
            institutionDetails: institution && institution.data(),
            ...applicantQuery.docs[i].data(),
          };
          allApplicants.push(details);
          if (applicantQuery.docs[i].data().applicationFeePaid) {
            applicants.push(details);
          }

          if (applicantQuery.docs[i].data().applicationApproved) {
            applicantsApproved.push(details);
          }

          if (applicantQuery.docs[i].data().applicationCompleted) {
            let application = {
              ...userInformation.data(),
              ...applicantQuery.docs[i].data(),
              id: applicantQuery.docs[i].id,
              programDetails: filteredProgram && filteredProgram.data(),
              reviewerDetails:
                reviewerInformation && reviewerInformation.data(),
            };
            completedApplications.push(application);
          }

          if (applicantQuery.docs[i].data().reviewStatus === 'reviewed') {
            // APPLICATION START DATE
            const applicationStartUnixTime =
              applicantQuery.docs[i].data().createdAt;
            const applicationStartDate = fromUnixTime(applicationStartUnixTime);
            const applicationReadableDate = format(
              applicationStartDate,
              'MM/dd/yyyy'
            );

            // SUBMISSION DATE
            const submissionUnixTime = applicantQuery.docs[i].data().editiedAt;
            const submissionDate = fromUnixTime(submissionUnixTime);
            const submissionReadableDate = format(submissionDate, 'MM/dd/yyyy');

            // REVIEWER UPDATED DATE
            const reviewerUpdatedUnixTime =
              applicantQuery.docs[i].data().reviewerUpdated;
            const reviewerUpdatedDate = fromUnixTime(reviewerUpdatedUnixTime);
            const reviewerUpdatedReadableDate = format(
              reviewerUpdatedDate,
              'MM/dd/yyyy'
            );

            let application = {
              ...applicantQuery.docs[i].data(),
              id: applicantQuery.docs[i].id,
              programDetails: filteredProgram && filteredProgram.data(),
              reviewerDetails:
                reviewerInformation && reviewerInformation.data(),
              applicantDetails: userInformation.data(),
              applicationStarted: applicationReadableDate,
              submissionReadableDate: submissionReadableDate,
              reviewerUpdatedReadableDate: reviewerUpdatedReadableDate,
              institutionDetails: institution && institution.data(),
              payment1Details: payment1Details && payment1Details.data(),
              payment2Details: payment2Details && payment2Details.data(),
            };
            reviewedApplications.push(application);
          }

          if (
            applicantQuery.docs[i].data().programStage === 'approved' ||
            applicantQuery.docs[i].data().programStage === 'waitlisted' ||
            applicantQuery.docs[i].data().programStage === 'admitted' ||
            applicantQuery.docs[i].data().programStage === 'complete'
          ) {
            if (
              userInformation.data().affiliateId &&
              userInformation.data().affiliateId !== 'noAffiliate'
            ) {
              const affiliate = affiliates.docs.find(
                (affiliate) =>
                  affiliate.data().id === userInformation.data().affiliateId
              );
              if (affiliate) {
                if (affiliate.data().scholarshipWaiver) {
                  scholarshipApplications.push(details);
                } else if (applicantQuery.docs[i].data().grantScholarship) {
                  scholarshipApplications.push(details);
                }
              } else if (applicantQuery.docs[i].data().grantScholarship) {
                scholarshipApplications.push(details);
              }
            } else if (applicantQuery.docs[i].data().grantScholarship) {
              scholarshipApplications.push(details);
            }
          }
        }
      }

      let applicantGeography = [];
      let applicantGender = [];
      let applicantNationality = [];

      let applicantGeographyValues = [];
      let applicantGenderValues = [];
      let applicantNationalityValues = [];

      // APPLICANT VIEW
      if (dashboardView === 'applicantView') {
        // Applicant GEOGRAPHY
        // for (let i = 0; i < applicants.length; i++) {
        //   let state = applicants[i].state ? applicants[i].state : "N/A";
        //   applicantGeography.push(state);
        // }
        //
        // let geographyCount = {};
        // applicantGeography.forEach(function (i) {
        //   geographyCount[i] = (geographyCount[i] || 0) + 1;
        // });
        //
        //
        // const applicantGeographyCount = [];
        // for (let state in geographyCount) {
        //   // loop through properties
        //   applicantGeographyCount.push([state, geographyCount[state]]);
        // }
        //
        //
        // applicantGeographyValues = [
        //   ["State", "Applicants"],
        //   ...applicantGeographyCount.sort(),
        // ];

        for (let i = 0; i < applicants.length; i++) {
          let state = applicants[i].state ? applicants[i].state : 'N/A';
          applicantGeography.push(state);
        }

        let geographyCount = {};
        applicantGeography.forEach(function (i) {
          geographyCount[i] = (geographyCount[i] || 0) + 1;
        });

        const applicantGeographyCount = [];
        for (let state in geographyCount) {
          // loop through properties
          const count = geographyCount[state];
          applicantGeographyCount.push({ state: state, count: count });
        }

        applicantGeographyValues = [
          // ...applicantGeographyCount.sort((a, b) => (a.count > b.count) ? 1 : -1),
          ...applicantGeographyCount.sort(),
        ];

        // GENDER RATIOS
        for (let i = 0; i < applicants.length; i++) {
          let gender = applicants[i].gender ? applicants[i].gender : 'N/A';
          applicantGender.push(gender);
        }

        let genderCount = {};
        applicantGender.forEach(function (i) {
          genderCount[i] = (genderCount[i] || 0) + 1;
        });

        const applicantGenderCount = [];
        for (let gender in genderCount) {
          // loop through properties
          applicantGenderCount.push([gender, genderCount[gender]]);
        }

        applicantGenderValues = [['Gender', 'Ratio'], ...applicantGenderCount];

        // Applicant NATIONALITY
        for (let i = 0; i < applicants.length; i++) {
          let nationality = applicants[i].ethnicBackground
            ? applicants[i].ethnicBackground
            : 'N/A';
          applicantNationality.push(nationality);
        }

        let nationalityCount = {};
        applicantNationality.forEach(function (i) {
          nationalityCount[i] = (nationalityCount[i] || 0) + 1;
        });

        const applicantNationalityCount = [];
        for (let nationality in nationalityCount) {
          // loop through properties
          applicantNationalityCount.push([
            nationality,
            nationalityCount[nationality],
          ]);
        }

        applicantNationalityValues = [
          ['Nationality', 'Applicants'],
          ...applicantNationalityCount.sort(),
        ];
      }

      // ACCEPTED SCHOLAR VIEW
      if (dashboardView === 'acceptedScholarView') {
        // Applicant GEOGRAPHY
        // for (let i = 0; i < applicantsApproved.length; i++) {
        //   let state = applicantsApproved[i].state ? applicantsApproved[i].state : "N/A";
        //   applicantGeography.push(state);
        // }
        //
        // let geographyCount = {};
        // applicantGeography.forEach(function (i) {
        //   geographyCount[i] = (geographyCount[i] || 0) + 1;
        // });
        //
        // const applicantGeographyCount = [];
        // for (let state in geographyCount) {
        //   // loop through properties
        //   applicantGeographyCount.push([state, geographyCount[state]]);
        // }
        //
        // applicantGeographyValues = [
        //   ["State", "Applicants"],
        //   ...applicantGeographyCount.sort(),
        // ];
        for (let i = 0; i < applicantsApproved.length; i++) {
          let state = applicantsApproved[i].state
            ? applicantsApproved[i].state
            : 'N/A';
          applicantGeography.push(state);
        }

        let geographyCount = {};
        applicantGeography.forEach(function (i) {
          geographyCount[i] = (geographyCount[i] || 0) + 1;
        });

        const applicantGeographyCount = [];
        for (let state in geographyCount) {
          // loop through properties
          const count = geographyCount[state];
          applicantGeographyCount.push({ state: state, count: count });
        }

        applicantGeographyValues = [
          // ...applicantGeographyCount.sort((a, b) => (a.count > b.count) ? 1 : -1),
          ...applicantGeographyCount.sort(),
        ];

        // GENDER RATIOS
        for (let i = 0; i < applicantsApproved.length; i++) {
          let gender = applicantsApproved[i].gender
            ? applicantsApproved[i].gender
            : 'N/A';
          applicantGender.push(gender);
        }

        let genderCount = {};
        applicantGender.forEach(function (i) {
          genderCount[i] = (genderCount[i] || 0) + 1;
        });

        const applicantGenderCount = [];
        for (let gender in genderCount) {
          // loop through properties
          applicantGenderCount.push([gender, genderCount[gender]]);
        }

        applicantGenderValues = [['Gender', 'Ratio'], ...applicantGenderCount];

        // Applicant NATIONALITY
        for (let i = 0; i < applicantsApproved.length; i++) {
          let nationality = applicantsApproved[i].ethnicBackground
            ? applicantsApproved[i].ethnicBackground
            : 'N/A';
          applicantNationality.push(nationality);
        }

        let nationalityCount = {};
        applicantNationality.forEach(function (i) {
          nationalityCount[i] = (nationalityCount[i] || 0) + 1;
        });

        const applicantNationalityCount = [];
        for (let nationality in nationalityCount) {
          // loop through properties
          applicantNationalityCount.push([
            nationality,
            nationalityCount[nationality],
          ]);
        }

        applicantNationalityValues = [
          ['Nationality', 'Applicants'],
          ...applicantNationalityCount.sort(),
        ];
      }

      // DASHBOARD VALUES
      let dashboardValues = {
        totalApplicants: applicants.length,
        allApplicants: allApplicants.length,
        applicationApproved: applicantsApproved.length,
        totalPrograms: programQuery.docs.length,
        totalRevenue: totalRevenue,
        applicantGeographyValues: applicantGeographyValues,
        applicantGenderValues: applicantGenderValues,
        applicantNationalityValues: applicantNationalityValues,
        tableData: {
          applicants: applicants,
          allApplicants: allApplicants,
          applicantsApproved: applicantsApproved,
          revenue: revenue,
          withdrawn: applicants.filter(
            (applicant) => applicant.programStage === 'withdrawn'
          ),
          scholarshipApplications: scholarshipApplications,
        },
      };

      dispatch({
        type: FETCH_ALL_REVIEWED_APPLICATIONS,
        payload: { reviewedApplications },
      });
      dispatch({
        type: FETCH_ALL_COMPLETED_APPLICATIONS,
        payload: { completedApplications },
      });
      dispatch({
        type: FETCH_ALL_DASHBOARD_VALUES,
        payload: { dashboardValues },
      });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const assignReviewer = (registeredProgramId, reviewId) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    try {
      const registeredProgramQuery = firestore
        .collection('registeredPrograms')
        .doc(`${registeredProgramId}`);
      dispatch(asyncActionStart());
      await registeredProgramQuery.update({
        reviewStatus: 'assigned',
        reviewerAssignedAt: getUnixTime(new Date()),
        reviewerId: reviewId,
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const unassignReviewer = (registeredProgramId) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${registeredProgramId}`);

    try {
      dispatch(asyncActionStart());
      await registeredProgramQuery.update({
        reviewStatus: 'unassigned',
        reviewerAssignedAt: firestore.FieldValue.delete(),
        reviewerId: firestore.FieldValue.delete(),
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateScholarshipAmount = (registeredProgramId, value) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${registeredProgramId}`);

    try {
      dispatch(asyncActionStart());
      const query = await registeredProgramQuery.get();
      const programRef = firestore
        .collection('programs')
        .doc(`${query.data()?.programId}`);

      const programQuery = await programRef.get();
      const program = programQuery.data();
      const { sponsorshipAmount } = query.data();
      const isPaymentWaived =
        program?.programCost === value ||
        program?.programCost === sponsorshipAmount ||
        Number(program?.programCost || 0) ===
          Number(value || 0) + Number(sponsorshipAmount || 0);

      await registeredProgramQuery.update({
        scholarshipAmount: value,
        payment1Received: isPaymentWaived || false,
        payment2Received: isPaymentWaived || false,
      });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateSponsorshipAmount = (registeredProgramId, value) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${registeredProgramId}`);

    try {
      dispatch(asyncActionStart());
      const query = await registeredProgramQuery.get();
      const programRef = firestore
        .collection('programs')
        .doc(`${query.data()?.programId}`);

      const programQuery = await programRef.get();
      const program = programQuery.data();
      const { scholarshipAmount } = query.data();
      const isPaymentWaived =
        program?.programCost === scholarshipAmount ||
        program?.programCost === value ||
        Number(program?.programCost || 0) ===
          Number(scholarshipAmount || 0) + Number(value || 0);

      await registeredProgramQuery.update({
        sponsorshipAmount: value,
        payment1Received: isPaymentWaived || false,
        payment2Received: isPaymentWaived || false,
      });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const toggleGrantScholarship = (registeredProgramId, value, reason) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${registeredProgramId}`);
    const registeredProgram = await registeredProgramQuery.get();

    try {
      dispatch(asyncActionStart());
      if (
        value &&
        registeredProgram.data().scholarshipFormDetails &&
        !registeredProgram.data().scholarshipFormDetails.qualifyForScholarship
      ) {
        await registeredProgramQuery.update({
          grantScholarship: value,
          scholarshipAmount: value
            ? registeredProgram.data()?.scholarshipAmount
            : '0',
          logs: registeredProgram.data().logs
            ? [
                ...registeredProgram.data().logs,
                {
                  event: 'Student rolled back for Scholarship Information',
                  date: getUnixTime(new Date()),
                },
              ]
            : [
                {
                  event: 'Student rolled back for Scholarship Information',
                  date: getUnixTime(new Date()),
                },
              ],
        });
      } else {
        if (value) {
          await registeredProgramQuery.update({
            grantScholarship: value,
            scholarshipAmount: value
              ? registeredProgram.data()?.scholarshipAmount
              : '0',
            logs: registeredProgram.data().logs
              ? [
                  ...registeredProgram.data().logs,
                  {
                    event: `Scholarship Granted Marked ${value ? 'Yes' : 'No'}`,
                    date: getUnixTime(new Date()),
                  },
                ]
              : [
                  {
                    event: `Scholarship Granted Marked ${value ? 'Yes' : 'No'}`,
                    date: getUnixTime(new Date()),
                  },
                ],
          });
        } else {
          await registeredProgramQuery.update({
            grantScholarship: value,
            scholarshipAmount: value
              ? registeredProgram.data()?.scholarshipAmount
              : '0',
            logs: registeredProgram.data().logs
              ? [
                  ...registeredProgram.data().logs,
                  {
                    event: `Scholarship Granted Marked ${value ? 'Yes' : 'No'}`,
                    date: getUnixTime(new Date()),
                  },
                ]
              : [
                  {
                    event: `Scholarship Granted Marked ${value ? 'Yes' : 'No'}`,
                    date: getUnixTime(new Date()),
                  },
                ],
            scholarshipRecejtReason: reason,
          });
        }
      }
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const toggleGrantSponsorship = (
  registeredProgramId,
  value,
  sponsorshipName = null
) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${registeredProgramId}`);

    try {
      dispatch(asyncActionStart());
      const query = await registeredProgramQuery.get();
      await registeredProgramQuery.update({
        grantSponsorship: value,
        sponsorshipName: sponsorshipName || '',
        sponsorshipAmount: value ? query.data()?.sponsorshipAmount || '0' : '0',
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const toggleEarlyAdmision = (registeredProgramId, value) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const registeredProgramQuery = firestore
      .collection('registeredPrograms')
      .doc(`${registeredProgramId}`);
    const registeredProgram = await registeredProgramQuery.get();

    try {
      dispatch(asyncActionStart());
      if (value && registeredProgram.data().earlyAdmission) {
        await registeredProgramQuery.update({
          earlyAdmission: value,
        });
      } else {
        if (value) {
          await registeredProgramQuery.update({
            earlyAdmission: value,
          });
        } else {
          await registeredProgramQuery.update({
            earlyAdmission: value,
          });
        }
      }
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const fetchAllFilterModels = () => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const filterModelQuery = firestore
      .collection('applicationSettings')
      .doc('filterModel');

    try {
      dispatch(asyncActionStart());
      const filters = await filterModelQuery.get();
      dispatch({
        type: FETCH_ALL_FILTERS,
        payload: filters.data(),
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const addFilterModel = (name, model) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const filterModelQuery = firestore
      .collection('applicationSettings')
      .doc('filterModel');

    try {
      dispatch(asyncActionStart());
      await filterModelQuery.update({
        [name]: model,
      });
      dispatch(fetchAllFilterModels());
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const clearFilterModels = () => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const filterModelQuery = firestore
      .collection('applicationSettings')
      .doc('filterModel');
    const filters = await filterModelQuery.get();
    const newFilters = {};
    Object.keys(filters.data()).forEach(
      (key) => (newFilters[key] = firestore.FieldValue.delete())
    );
    try {
      dispatch(asyncActionStart());
      await filterModelQuery.update(newFilters);
      dispatch(fetchAllFilterModels());
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const fetchAllEmails = () => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const emailsQuery = firestore.collection('emails');

    try {
      dispatch(asyncActionStart());
      const emails = await emailsQuery.get();
      dispatch({
        type: FETCH_ALL_EMAILS,
        payload: emails.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })),
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
