import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Sidebar from "../../../../ui/sidebar/Sidebar";
import Typography from "@mui/material/Typography";

import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#171725",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight: "24px",
    letter: "0.1px",
  },
}));

const actions = {
  // fetchAllSchools,
};

const mapStateToProps = (state) => {};

const AdminManagePayments = ({ fetchAllSchools, schools }) => {
  const classes = useStyles();

  // useEffect(() => {
  //     fetchAllSchools();
  // }, [fetchAllSchools]);

  return (
    <Grid container>
      <Grid item md={2}>
        <Sidebar />
      </Grid>

      <Grid
        item
        md={10}
        style={{
          backgroundColor: "#FAFAFB",
          paddingLeft: "41px",
          paddingRight: "41px",
        }}
      >
        <Grid item container direction={"column"}>
          <Grid item style={{ marginTop: "20px" }}>
            <Typography className={classes.title}>Manage Payments</Typography>
          </Grid>

          {/*<Grid item style={{ marginLeft: "auto" }}>*/}
          {/*    <Button*/}
          {/*        variant="contained"*/}
          {/*        color="primary"*/}
          {/*        onClick={() => history.push("/admin/settings/institutions/new")}*/}
          {/*    >*/}
          {/*        Create School*/}
          {/*    </Button>*/}
          {/*</Grid>*/}

          {/*<Grid item style={{ marginTop: "2em" }}>*/}
          {/*    <TableContainer component={Paper}>*/}
          {/*        <Table className={classes.table} aria-label="simple table">*/}
          {/*            <TableHead>*/}
          {/*                <TableRow>*/}
          {/*                    <TableCell>School Name</TableCell>*/}
          {/*                    <TableCell># of Programs</TableCell>*/}
          {/*                    <TableCell>Added By</TableCell>*/}
          {/*                    <TableCell>Actions</TableCell>*/}
          {/*                </TableRow>*/}
          {/*            </TableHead>*/}
          {/*            <TableBody>*/}
          {/*                {institutions.map((school) => (*/}
          {/*                    <TableRow key={school.id}>*/}
          {/*                        <TableCell component="th" scope="row">*/}
          {/*                            {school.schoolName}*/}
          {/*                        </TableCell>*/}
          {/*                        <TableCell component="th" scope="row">*/}
          {/*                            {school.schoolPrograms.length}*/}
          {/*                        </TableCell>*/}
          {/*                        <TableCell>{school.addedByName}</TableCell>*/}
          {/*                        <TableCell>*/}
          {/*                            <Button*/}
          {/*                                variant={"contained"}*/}
          {/*                                color={"primary"}*/}
          {/*                                size={"small"}*/}
          {/*                                onClick={() =>*/}
          {/*                                    history.push(*/}
          {/*                                        `/admin/settings/institutions/view?id=${school.id}`*/}
          {/*                                    )*/}
          {/*                                }*/}
          {/*                            >*/}
          {/*                                View*/}
          {/*                            </Button>*/}
          {/*                            <Button*/}
          {/*                                style={{marginLeft: '0.5em', backgroundColor: theme.palette.info.main, color: 'white'}}*/}
          {/*                                variant={"contained"}*/}

          {/*                                size={"small"}*/}
          {/*                                onClick={() =>*/}
          {/*                                    history.push(*/}
          {/*                                        `/admin/settings/institutions/edit?id=${school.id}`*/}
          {/*                                    )*/}
          {/*                                }*/}
          {/*                            >*/}
          {/*                                Edit*/}
          {/*                            </Button>*/}
          {/*                        </TableCell>*/}
          {/*                    </TableRow>*/}
          {/*                ))}*/}
          {/*            </TableBody>*/}
          {/*        </Table>*/}
          {/*    </TableContainer>*/}
          {/*</Grid>*/}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(AdminManagePayments);
