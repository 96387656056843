import React from 'react';
import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import './programItem.css';

const useStyles = makeStyles((theme) => ({
  item: {
    borderRadius: '8px',
    backgroundColor: 'white',
    height: '155px',
  },
  itemTitle: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    color: '#171725',
  },
  itemText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16.41px',
    letterSpacing: '0.1px',
    color: '#696974',
    textAlign: 'center',
  },
}));

const ViewProgramItem = ({ name, value }) => {
  const matches = useMediaQuery('(min-width:1280px)');
  const classes = useStyles();
  return (
    <Grid
      item
      className={classes.item}
      // lg={3}
      // md={4}
      // sm={6}
      // xs={6}
      style={{
        maxWidth: '100%',
        flexBasis: '25%',
        flexGrow: '0',
      }}
    >
      <Grid
        container
        direction={'column'}
        alignItems={'center'}
        style={{ padding: '27px' }}
      >
        <Grid item>
          <Typography className={classes.itemTitle}>{name}</Typography>
        </Grid>
        <Grid item style={{ marginTop: '14px' }}>
          <Typography className={classes.itemText}>{value}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ViewProgramItem;
