import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Sidebar from '../../../ui/sidebar/Sidebar';
import {
  adminDeleteApplicant,
  adminDisableApplicant,
  adminEnableApplicant,
  fetchAllApplicants,
  adminDeleteApplicants,
} from '../../../store/actions/adminActions/applicantActions/adminApplicantActions';

import { CustomToolbar, escapeRegExp } from '../../../common/tableUtils';
import { getDate } from '../../../common/util';
import { format, fromUnixTime } from 'date-fns';
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from '@mui/x-data-grid-premium';

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#171725',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '24px',
    letter: '0.1px',
  },
}));

const actions = {
  fetchAllApplicants,
  adminDisableApplicant,
  adminEnableApplicant,
  adminDeleteApplicant,
  adminDeleteApplicants,
};

const mapStateToProps = (state) => {
  let applicants = [];

  if (
    state.adminApplicants.allApplicants &&
    state.adminApplicants.allApplicants.length > 0
  ) {
    applicants = state.adminApplicants.allApplicants;
  }

  return {
    applicants,
    profile: state.firebase.profile,
  };
};

const AdminApplicants = ({
  fetchAllApplicants,
  adminDisableApplicant,
  adminEnableApplicant,
  adminDeleteApplicant,
  adminDeleteApplicants,
  applicants,
  profile,
}) => {
  const matches = useMediaQuery('(min-width:480px)');
  const classes = useStyles();
  const theme = useTheme();

  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [applicantId, setApplicantId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [selectionModel, setSelectionModel] = useState([]);
  const [deleteSelectedDialog, setDeleteSelectedDialog] = useState(false);

  const history = useHistory();

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      'e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
    );
  }, []);

  useEffect(() => {
    fetchAllApplicants();
  }, [fetchAllApplicants]);

  console.log('applicants', applicants);

  useEffect(() => {
    const arr = applicants.map((applicant, index) => ({
      ...applicant,
      serialNo: index + 1,
      accountType:
        (applicant.isApplicant && 'Applicant') ||
        (applicant.isAlumni && 'Alumni'),
      accountStatus: applicant.disabled ? 'Disabled' : 'Active',
      createdAt: applicant.createdAt && getDate(applicant.createdAt),
      // editiedAt: applicant?.editiedAt
      //   ? format(fromUnixTime(applicant?.editiedAt), 'MM/dd/yyyy')
      //   : getDate(applicant?.createdAt),
    }));
    setRows(arr);
    if (searchText) {
      setSearchText(searchText);
      const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
    }
  }, [applicants]);

  const handleDeleteApplicant = async () => {
    let deleteApplicantQuery = await adminDeleteApplicant(applicantId);

    if (deleteApplicantQuery.data === 'Deleted User') {
      setDialogOpen(false);
    }
  };

  const handleDeleteApplicants = async () => {
    await adminDeleteApplicants(selectionModel);
    setDeleteSelectedDialog(false);
    setSelectionModel([]);
  };

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filtered = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };

  const memoizedColumns = useMemo(() => {
    const handleDisableApplicant = async (id) => {
      let disableUserQuery = await adminDisableApplicant(id);
      if (disableUserQuery.data === 'User Disabled') {
        // setUserToggle(false);
      }
    };
    const handleEnableApplicant = async (id) => {
      let enableUserQuery = await adminEnableApplicant(id);
      if (enableUserQuery.data === 'User Enabled') {
      }
    };
    return [
      {
        field: 'serialNo',
        headerName: 'Id',
        width: 100,
      },
      {
        field: 'fullName',
        headerName: 'Applicant Name',
        width: 180,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'email',
        headerName: 'Applicant Email',
        width: 280,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'createdAt',
        headerName: 'Application Created',
        width: 180,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'editiedAt',
        headerName: 'Last Seen',
        width: 180,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'accountType',
        headerName: 'Account Type',
        width: 160,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: 'numberOfPrograms',
        headerName: '# of Programs',
        width: 160,
        valueGetter: (param) => param.value || '0',
      },
      {
        field: 'accountStatus',
        headerName: 'Account Status',
        width: 160,
        valueGetter: (param) => param.value || 'N/A',
      },
      {
        field: '',
        headerName: 'Actions',
        width: 320,
        valueGetter: (param) => param.value || 'N/A',
        renderCell: (param) => (
          <Box display="flex" justifyContent="space-evenly" width="100%">
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={(e) => {
                e.stopPropagation();
                history.push(
                  `/${
                    profile.isAdmin ? 'admin' : 'reviewer'
                  }/applicant/view?id=${param.id}`
                );
              }}
            >
              View
            </Button>
            {profile.isAdmin && (
              <>
                <Button
                  style={{
                    marginLeft: '0.5em',
                    backgroundColor: theme.palette.info.main,
                    color: 'white',
                  }}
                  variant={'contained'}
                  size={'small'}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/admin/applicant/edit?id=${param.id}`);
                  }}
                >
                  Edit
                </Button>
                <Button
                  style={
                    param.row.disabled
                      ? {
                          marginLeft: '0.5em',
                          backgroundColor: theme.palette.primary.main,
                          color: 'white',
                        }
                      : {
                          marginLeft: '0.5em',
                          backgroundColor: theme.palette.error.main,
                          color: 'white',
                        }
                  }
                  variant={'contained'}
                  size={'small'}
                  onClick={(e) => {
                    e.stopPropagation();
                    param.row.disabled
                      ? handleEnableApplicant(param.id)
                      : handleDisableApplicant(param.id);
                  }}
                >
                  {param.row.disabled ? 'Enable' : 'Disable'}
                </Button>
                <Button
                  style={{
                    marginLeft: '0.5em',
                    backgroundColor: theme.palette.error.main,
                    color: 'white',
                  }}
                  variant={'contained'}
                  size={'small'}
                  onClick={(e) => {
                    e.stopPropagation();
                    setApplicantId(param.id);
                    setDialogOpen(true);
                  }}
                >
                  Delete
                </Button>
              </>
            )}
          </Box>
        ),
      },
    ];
  }, [
    history,
    profile.isAdmin,
    theme,
    adminDisableApplicant,
    adminEnableApplicant,
  ]);

  return (
    <Fragment>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle id="alert-dialog-title">{'Warning'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you wish to delete applicant?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteApplicant()}
            style={{ color: theme.palette.error.main }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteSelectedDialog}
        onClose={() => setDeleteSelectedDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">{'Warning'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you wish to delete selected applicants?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteSelectedDialog(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteApplicants()}
            style={{ color: theme.palette.error.main }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container>
        <Grid
          item
          md={2}
          style={{
            width: `${matches ? '' : '100%'}`,
          }}
        >
          <Sidebar />
        </Grid>

        <Grid
          item
          md={10}
          style={{
            backgroundColor: '#FAFAFB',
            paddingLeft: '41px',
            paddingRight: '41px',
          }}
        >
          <Grid item container direction={'column'}>
            <Grid
              item
              container
              justifyContent="space-between"
              style={{ marginTop: '20px' }}
            >
              <Typography className={classes.title}>
                Total Registered Users - {filteredRows.length}
              </Typography>
              {profile.isAdmin && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push(`/admin/applicant/new`)}
                >
                  Create Applicant
                </Button>
              )}
            </Grid>

            <Grid item style={{ marginTop: '2em', height: 688, width: '100%' }}>
              <DataGridPremium
                columns={memoizedColumns}
                rows={filteredRows}
                pagination={true}
                checkboxSelection
                selectionModel={selectionModel}
                onSelectionModelChange={(params) =>
                  setSelectionModel(params.selectionModel)
                }
                disableSelectionOnClick
                pageSize={20}
                // rowsPerPageOptions={[50, 100, 150]}
                onRowClick={(param) => {
                  history.push(
                    `/${
                      profile.isAdmin ? 'admin' : 'reviewer'
                    }/applicant/view?id=${param.id}`
                  );
                }}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    value: searchText,
                    clearSearch: () => requestSearch(''),
                    showDeleteButton: true,
                    disableDeleteButton: selectionModel.length === 0,
                    onDeleteButtonClick: () => setDeleteSelectedDialog(true),
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default connect(mapStateToProps, actions)(AdminApplicants);
