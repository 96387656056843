import React, { Fragment } from "react";

import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from '@mui/material/Switch';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
    fontWeight: 300,
  },
}));

const SwitchButton = ({
  input,
  label,
  meta: { touched, error },
  color,
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <FormControlLabel
        control={
          <Switch
            checked={input.value ? true : false}
            onChange={input.onChange}
            color={color ? color : "primary"}
          />
        }
        label={label}
      />
      {touched && error && (
        <Typography variant={"subtitle1"} className={classes.error}>
          {error}
        </Typography>
      )}
    </Fragment>
  );
};

export default SwitchButton;
