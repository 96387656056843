import React from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Field } from "redux-form";
import CheckboxInput from "../../common/form/CheckboxInput";

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  fieldWrapper: {
    paddingTop: "0.4em",
    paddingBottom: "0.4em",
  },
  questionText: {
    color: "#696974",
    fontWeight: "600",
    fontFamily: "Roboto",
    fontSize: "16px",
    letter: "0.2px",
    paddingRight: "1em",
  },
}));

const CheckBoxForm = ({ info, fieldName, largerFont }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid
      item
      container
      className={classes.fieldContainer}
      alignItems={"center"}
    >
      <Grid item xs={1}>
        {/*BLANK*/}
      </Grid>
      <Grid item xs={11}>
        <Grid item>
          <Grid item container>
            <Grid item lg={1} md={1} sm={1} xs={1}>
              <Field
                name={fieldName}
                component={CheckboxInput}
                variant={"outlined"}
              />
            </Grid>

            <Grid item lg={11} md={11} sm={11} xs={11}>
              <Typography
                variant={"caption"}
                style={
                  largerFont
                    ? { color: theme.palette.error.main, fontSize: "1em" }
                    : { color: theme.palette.error.main }
                }
              >
                {info}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CheckBoxForm;
