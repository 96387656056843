import React from 'react';

export const BackIcon = () => {
    return (
        <svg width={31} height={36} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.806 1.16h-2.18C7.101 1.16 1 8.084 1 16.625v2.474c0 8.541 6.1 15.465 13.626 15.465h2.18c7.526 0 13.626-6.924 13.626-15.465v-2.474c0-8.541-6.1-15.465-13.626-15.465Z"
                fill="#fff"
                stroke="#0079A9"
                strokeWidth={1.09}
            />
            <path
                d="M21.769 17.038v1.65h-9.323l2.357 2.674-1.028 1.166-4.11-4.665 4.11-4.666 1.028 1.166-2.357 2.675h9.323Z"
                fill="#0079A9"
            />
        </svg>
    );
};
