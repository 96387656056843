import React from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import ReviewProgramItem from "./item/ReviewProgramItem";
import { format, parseISO } from "date-fns";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  answerText: {
    fontWeight: 600,
  },
}));

const ScholarshipFormReview = ({
  scholarship,
  additionalDebt,
  additionalExpense,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid
      container
      direction={"column"}
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
    >
      <Grid item>
        <Typography className={classes.title}>Scholarship Form</Typography>
      </Grid>
      {/*APPLY FOR SCHOLARSHIP*/}
      <ReviewProgramItem
        question={"Do you wish to apply for a scholarship?"}
        value={scholarship?.applyForAScholarship}
      />

      {/*RECEIVE FREE LUNCH*/}
      <ReviewProgramItem
        question={"Do you receive free or reduced lunch?"}
        value={scholarship?.receiveFreeOrReducedLunch}
      />

      {/*SALARY GREATER THAN*/}
      <ReviewProgramItem
        question={"Is your family salary below $75,000 a year?"}
        value={scholarship?.familySalaryLessThan}
      />

      {/*CATASTROPHIC EVENT*/}
      <ReviewProgramItem
        question={
          "Have you had a catastrophic event in the last 12 months, divorce, terminal illness or death, job loss?"
        }
        value={scholarship?.catastrophicEvent}
      />

      {/*ADDITIONAL COMMENTS*/}
      <ReviewProgramItem
        question={"Additional Comments"}
        value={scholarship?.scholarshipAdditionalComments}
      />

      {/*CONSENT*/}
      <ReviewProgramItem
        question={
          "I give my consent to the sponsoring organization considering this application to use the information provided herein for the purpose of scholarship consideration."
        }
        value={scholarship?.sponsoringConsent ? "Yes" : "No"}
      />

      {/*SCHOLARS LAST NAME*/}
      <ReviewProgramItem
        question={"Scholar Last Name:"}
        value={scholarship?.scholarLastName}
      />

      {/*SCHOLARS FIRST NAME*/}
      <ReviewProgramItem
        question={"Scholar First Name:"}
        value={scholarship?.scholarFirstName}
      />

      {/*EMAIL*/}
      <ReviewProgramItem
        question={"Email:"}
        value={scholarship?.scholarEmail}
      />

      {/*PHONE*/}
      <ReviewProgramItem
        question={"Phone Number:"}
        value={scholarship?.scholarPhoneNumber}
      />

      {/*FAMILY INCOME*/}
      <ReviewProgramItem
        question={"What was the annual family income for 2020?"}
        value={scholarship?.familyIncome2018}
      />
      <ReviewProgramItem
        question={"What was the annual family income for 2021?"}
        value={scholarship?.familyIncome2019}
      />
      <ReviewProgramItem
        question={
          "What was the 2021 untaxed income & benefits (i.e: Social Security, Disability, etc.):"
        }
        value={scholarship?.untaxedIncome2019}
      />
      <ReviewProgramItem
        question={
          "Total number of family members in college as of September 2022:"
        }
        value={scholarship?.familyMemberInCollege}
      />
      <ReviewProgramItem
        question={"What is the expected annual family income for 2023?"}
        value={scholarship?.expectedFamilyIncome2020}
      />

      {/*EXPENSES*/}
      <ReviewProgramItem
        question={"Monthly Mortgage/Rent:"}
        value={scholarship?.monthlyMortgageRent}
      />
      <ReviewProgramItem
        question={"Monthly Utilities:"}
        value={scholarship?.monthlyUtilities}
      />
      <ReviewProgramItem
        question={"Monthly Auto Insurance:"}
        value={scholarship?.monthlyAutoInsurance}
      />
      <ReviewProgramItem
        question={"Monthly Life Insurance:"}
        value={scholarship?.monthlyLifeInsurance}
      />
      <ReviewProgramItem
        question={"Monthly Home Insurance:"}
        value={scholarship?.monthlyHomeInsurance}
      />
      <ReviewProgramItem
        question={"Monthly Food:"}
        value={scholarship?.monthlyFood}
      />
      <ReviewProgramItem
        question={"Monthly Transportation:"}
        value={scholarship?.monthlyTransportation}
      />
      <ReviewProgramItem
        question={"Medical Expenses:"}
        value={scholarship?.medicalExpenses}
      />
      <ReviewProgramItem
        question={"Auto Loan Debt:"}
        value={scholarship?.autoLoanDebt}
      />
      <ReviewProgramItem
        question={"Home Equity Loan Debt:"}
        value={scholarship?.homeEquityLoanDebt}
      />
      <ReviewProgramItem
        question={"Does your family have other debt to declare?"}
        value={scholarship?.additionalFamilyDebt}
      />

      {/*EXTRA FAMILY DEBT HERE*/}
      {scholarship?.additionalFamilyDebt === "Yes" && (
        <Grid item style={{ marginTop: "0.2em" }}>
          <Typography className={classes.title}>
            Additional Family Debt
          </Typography>
        </Grid>
      )}
      {scholarship?.additionalFamilyDebt === "Yes" &&
        additionalDebt?.map((debt) => (
          <Grid item style={{ marginTop: "0.2em" }} key={debt.description}>
            <Grid item container>
              <Grid item md={8} style={{ paddingLeft: "2em" }}>
                <Typography
                  variant={"body1"}
                  style={{ color: theme.palette.primary.main }}
                >
                  {debt.description}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography
                  variant={"body1"}
                  style={{ color: theme.palette.primary.main }}
                >
                  {debt.amount}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}

      {/*CHILDRENS EXPENSES*/}
      <ReviewProgramItem
        question={`Does you have monthly Children's expenses?`}
        value={scholarship?.monthlyChildrensExpense}
      />

      {/*CHILDRENS EXPENSES HERE*/}
      {scholarship?.monthlyChildrensExpense === "Yes" && (
        <Grid item style={{ marginTop: "0.2em" }}>
          <Typography className={classes.title}>
            Monthly Childrens Expense
          </Typography>
        </Grid>
      )}
      {scholarship?.monthlyChildrensExpense === "Yes" &&
        additionalExpense?.map((debt) => (
          <Grid item style={{ marginTop: "0.2em" }} key={debt.description}>
            <Grid item container>
              <Grid item md={8} style={{ paddingLeft: "2em" }}>
                <Typography
                  variant={"body1"}
                  style={{ color: theme.palette.primary.main }}
                >
                  {debt.description}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography
                  variant={"body1"}
                  style={{ color: theme.palette.primary.main }}
                >
                  {debt.amount}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}

      {/*REQUIRE ASSISTANCE*/}
      <ReviewProgramItem
        question={
          "As a nonprofit, our scholarship funds are limited, we base awards on financial eligibility. Please provide a detailed description of why you require assistance from LEAD."
        }
        value={scholarship?.assistantExplanation}
      />

      {/*REQUIRED DOCUMENTS CONSENT*/}
      <ReviewProgramItem
        question={
          "I understand that all required documents to apply for scholarship must be completed before submitting the Application by the Deadline by March 30, 2020 at 11:59pm EST"
        }
        value={scholarship?.understandRequiredDocuments ? "Yes" : "No"}
      />
      <ReviewProgramItem
        question={
          "I certify that all the information provided by me on this form is true and correct."
        }
        value={scholarship?.documentsTrueAndCorrect ? "Yes" : "No"}
      />
      <ReviewProgramItem
        question={"Parent/Guardian Name:"}
        value={scholarship?.parentGuardianName}
      />
      <ReviewProgramItem
        question={"Date"}
        value={
          scholarship?.scholarshipFormDate
            ? format(parseISO(scholarship?.scholarshipFormDate), "EEE MMM do y")
            : ""
        }
      />
    </Grid>
  );
};

export default ScholarshipFormReview;
