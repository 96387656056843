import React, { useState, useEffect, useMemo, useRef } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/material";
import { escapeRegExp } from "../../../common/tableUtils";
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { reduxForm } from "redux-form";
import { combineValidators, isRequired } from "revalidate";

const validate = combineValidators({
  filterName: isRequired({ message: "Required" }),
});

const mapStateToProps = (state) => {
  let filters = {};

  if (state.adminDashboard.filters) {
    filters = state.adminDashboard.filters;
  }

  return {
    filters,
    profile: state.firebase.profile,
  };
};

const StudentQuestionnaireApplicantsTable = ({
  applicants,
  fetchDashboardValues,
  filters,
  checkboxSelection,
  onSelectionModelChange,
}) => {
  const matches = useMediaQuery("(min-width:530px)");
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState(applicants);
  const [filteredRows, setFilteredRows] = useState(applicants);
  const [filterModel, setFilterModel] = useState();
  const [sortModel, setSortModel] = useState();
  const [, setRender] = useState(false);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const grid1 = useRef(null);
  const grid2 = useRef(null);

  const history = useHistory();

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      "e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    );
  }, []);

  useEffect(() => {
    const arr = applicants.map((applicant, index) => {
      return {
        ...applicant,
        serialNo: index + 1,
      };
    });
    setRows(arr);
    if (searchText) {
      const searchRegex = new RegExp(escapeRegExp(searchText), "i");
      const filtered = arr.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(arr);
    }
  }, [applicants]);

  useEffect(() => {
    const search = history.location.search;
    if (search.includes("?institute")) {
      setRender((prevRender) => !prevRender);
    }
  }, [history, filteredRows]);

  useEffect(() => {
    if (filter) {
      setFilterModel(filters[filter]);
    } else {
      setFilterModel(null);
    }
  }, [filter, filters]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filtered = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
        return false;
      });
    });
    setFilteredRows(filtered);
  };

  const memoizedColumns = useMemo(() => {
    return [
      { field: "serialNo", headerName: "Id", width: 80 },
      {
        field: "scholarName",
        headerName: "Scholar Name",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "nickname",
        headerName: "Nickname",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "email",
        headerName: "Email",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "institutionName",
        headerName: "Assigned Institution",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "poloSize",
        headerName: "Polo Size",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "disability",
        headerName: "Voluntary Disability Information",
        width: 80,
        valueGetter: (param) => (param.value && Object.keys(param.value).filter(key => param.value[key] === true).join(", "))  || "N/A",
      },
      {
        field: "additionalCommentsOrAccommodations",
        headerName: "Additional Comments Or Accomodations",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "allergiesOrRestrictions",
        headerName: "Allergies Or Restrictions",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "careerGoal",
        headerName: "Career Goal",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "collegeMajorGoal",
        headerName: "College Major Goal",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "collegesApplyingTo",
        headerName: "Colleges Applying To",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "languages",
        headerName: "Languages",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "advanceMathematicsCourseTaken",
        headerName: "Advanced Mathematics Course",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "economicsCourse",
        headerName: "Economics Course Description",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "experienceInComputers",
        headerName: "Computers Experience",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "involvementInAffinityStudentOrganisations",
        headerName: "Affinity Student Organizations Involvement",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "doInSpareTime",
        headerName: "Do In Spare Time",
        width: 80,
        valueGetter: (param) => (param.value && Object.keys(param.value).filter(key => param.value[key] === true).join(", "))  || "N/A",
      },
      {
        field: "otherActivites",
        headerName: "Other Activities",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "musicILike",
        headerName: "Like Music",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "talents",
        headerName: "Talents",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "sports",
        headerName: "Sports",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "phobia",
        headerName: "Phobia",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "favouriteFood",
        headerName: "Favorite Food",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "roommateConsideration",
        headerName: "Roommate Consideration",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "roleModels",
        headerName: "Role Models",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "countriesBeenTo",
        headerName: "Countries Been To",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "workPreference",
        headerName: "Work Preference",
        width: 80,
        valueGetter: (param) => (param.value && Object.keys(param.value).filter(key => param.value[key] === true).join(", "))  || "N/A",
      },
      {
        field: "greatestStrength",
        headerName: "Greatest Strength",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "greatestWeakness",
        headerName: "Greatest Weakness",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "ownBuisness",
        headerName: "Own Business",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "interestForLead",
        headerName: "Interest For Lead",
        width: 80,
        valueGetter: (param) => param.value || "N/A",
      },
    ];
  }, [history, fetchDashboardValues]);

  return (
    <Box
      height={688}
      width="100%"
      display="flex"
      style={{
        width: `${matches ? "100%" : "100%"}`,
        overflow: `${matches ? " " : "auto"}`,
      }}
    >
      <DataGridPremium
        ref={grid2}
        // columnGroupingModel={columnGroupingModel}
        // experimentalFeatures={{ columnGrouping: true }}
        columns={memoizedColumns}
        rows={filteredRows}
        disableSelectionOnClick
        checkboxSelection={checkboxSelection}
        page={page}
        onPageChange={(params) => setPage(params.page)}
        onPageSizeChange={(params) => setPageSize(params.pageSize)}
        rowsPerPageOptions={[10, 25, 50]}
        onSelectionModelChange={(selectedIds) => {
          onSelectionModelChange(selectedIds);
        }}
        onRowClick={(param) => {
          history.push(`/admin/reviewProgram?id=${param.row.id}`);
        }}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            value: searchText,
            clearSearch: () => requestSearch(""),
          },
        }}
        sortModel={sortModel}
        onSortModelChange={(params) => setSortModel(params.sortModel)}
        initialState={{ pinnedColumns: { left: ["fullName"] } }}
      />
    </Box>
  );
};

export default connect(
  mapStateToProps,
  null
)(reduxForm({ form: "adminNote", validate })(StudentQuestionnaireApplicantsTable));
