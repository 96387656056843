import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import {
  Field,
  formValueSelector,
  reduxForm,
  isInvalid,
  getFormValues,
} from 'redux-form';

import { combineValidators, isRequired } from 'revalidate';

import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import TextInputForm from '../../../../forms/TextInputForm';
import DatePickerInput from '../../../../../common/form/DatePickerInput';

import SelectInputForm from '../../../../forms/SelectInputForm';

import StateLookup from '../../../../forms/formLookups/StateLookup';
import {
  submitPersonalInformationForm,
  updatePersonalInformationForm,
} from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import { closeDialog } from '../../../../../store/actions/dialogActions/dialogActions';
import toast, { Toaster } from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: '0.4em',
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
    },
  },

  continueButton: {
    marginLeft: '0.4em',
    backgroundColor: '#1976d2',
    color: 'white !Important',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
      //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    },
  },
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  formName: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '72px',
    letter: '0.2px',
  },
  formText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  formQuestionText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  questionText: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
    paddingRight: '1em',
  },
}));

const validate = combineValidators({
  lastName: isRequired({ message: 'Required' }),
  firstName: isRequired({ message: 'Required' }),
  // middleInitial: isRequired({ message: "Required" }),
  gender: isRequired({ message: 'Required' }),
  dob: isRequired({ message: 'Required' }),
  address1: isRequired({ message: 'Required' }),
  city: isRequired({ message: 'Required' }),
  state: isRequired({ message: 'Required' }),
  zipCode: isRequired({ message: 'Required' }),
  preferredEmail: isRequired({ message: 'Required' }),
  mobileNumber: isRequired({ message: 'Required' }),
  // housePhoneNumber: isRequired({ message: "Required" }),
  afterGraduation: isRequired({ message: 'Required' }),
  ethnicBackground: isRequired({ message: 'Required' }),
  citizenshipStatus: isRequired({ message: 'Required' }),
  americanWithDisabilitiesAct: isRequired({ message: 'Required' }),
  permanentResidentNumber: isRequired({ message: 'Required' }),
  otherCountryOfCitizenship: isRequired({ message: 'Required' }),
  countryOfOrigin: isRequired({ message: 'Required' }),
  nativeFamilyLanguage: isRequired({ message: 'Required' }),
 // poloSize: isRequired({ message: 'Required' }),
});
const arr = {
  lastName: '',
  firstName: '',
  gender: '',
  dob: '',
  address1: '',
  city: '',
  state: '',
  zipCode: '',
  preferredEmail: '',

  mobileNumber: '',
  afterGraduation: '',
  ethnicBackground: '',
  citizenshipStatus: '',
  americanWithDisabilitiesAct: '',
  ////////// below both case occur when citizenship is US or Dual US citizen (we have to fixed it )
  permanentResidentNumber: '',
  // otherCountryOfCitizenship: "",
  countryOfOrigin: '',
  nativeFamilyLanguage: '',
  poloSize: '',
};
const actions = {
  submitPersonalInformationForm,
  updatePersonalInformationForm,
};

const mapStateToProps = (state) => {
  console.log(state, 'state');
  const questionSelector = formValueSelector('personalInformationForm');
  const fieldValues = questionSelector(
    state,
    'id',
    'afterGraduation',
    'americanWithDisabilitiesAct',
    'ethnicBackground',
    'citizenshipStatus',
    'mobileNumber',
    'housePhoneNumber'
  );
  const invalid = isInvalid('personalInformationForm');
  return {
    fieldValues,
    invalid,
    initialValues: state.applicantPrograms.registeredProgram[0]
      .personalInformationFormDetails || {
      lastName: state.firebase.profile.lastName,
      firstName: state.firebase.profile.firstName,
      middleInitial: state.firebase.profile.middleInitial
        ? state.firebase.profile.middleInitial
        : null,
      dob: state.firebase.profile.dob,
      gender: state.firebase.profile.gender,
      poloSize: '', // or whatever default value you want
      address1: state.firebase.profile.address1,
      address2: state.firebase.profile.address2,
      city: state.firebase.profile.city,
      state: state.firebase.profile.state,
      zipCode: state.firebase.profile.zipCode,
      preferredEmail: state.firebase.profile.email,
      mobileNumber: state.firebase.profile.mobileNumber,
      ethnicBackground: state.firebase.profile.ethnicBackground,
      citizenshipStatus: state.firebase.profile.citizenshipStatus,
    },
    currentValues: getFormValues('personalInformationForm')(state),
  };
};

const normalizePhoneNumber = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;
  if (cvLength < 4) return currentValue;
  if (cvLength < 7)
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
    3,
    6
  )}-${currentValue.slice(6, 10)}`;
};

const PersonalInformationForm = ({
  fieldValues,
  invalid,
  registeredProgramId,
  profile,
  submitPersonalInformationForm,
  updatePersonalInformationForm,
  handleCloseForm,
  handleSetForm,
  handleSubmit,
  error,
  submitting,
  change,
  currentValues,
  program,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  console.log(program, 'program');
  const [open, setOpen] = useState(false);

  const [requireAccommodation, setRequireAccommodation] = useState(false);
  const [ethnicOther, setEthnicOther] = useState(false);
  const [dualCitizenship, setDualCitizenship] = useState(false);
  const [uspermit, setUspermit] = useState(false);
  const [time, setTime] = useState(false);
  const dispatch = useDispatch();

  const states = StateLookup.states;

  useEffect(() => {
    // window.scrollTo(0, 0);
    setRequireAccommodation(
      fieldValues.americanWithDisabilitiesAct === 'Yes' ? true : false
    );
    setEthnicOther(fieldValues.ethnicBackground === 'Other' ? true : false);
    setDualCitizenship(
      fieldValues.citizenshipStatus === 'Dual US Citizen' ? true : false
    );
    setUspermit(
      fieldValues.citizenshipStatus === 'US Permanent resident' ? true : false
    );
  }, [fieldValues]);

  useEffect(() => {
    change('mobileNumber', normalizePhoneNumber(fieldValues.mobileNumber));
  }, [fieldValues.mobileNumber, change]);

  useEffect(() => {
    change(
      'housePhoneNumber',
      normalizePhoneNumber(fieldValues.housePhoneNumber)
    );
  }, [fieldValues.housePhoneNumber, change]);

  const programsAllThings = [
    'applicationInterests',
    'personalInformationForm',
    'familyInformationForm',
    'academicInformationForm',
    // 'recommendationsRequired',
    'recommendersForm',
    'extracurricularForm',
    'covidProtocolForm',
    'resumeForm',
    'transcriptForm',
    'shortResponsesForm',
    'scholarshipForm',
    'taxForm',
    'additionalDocumentsForm',
    'confirmationForm',
  ];

  const programAllThingsValue = {
    applicationInterests: 'applicationInterests',
    personalInformationForm: 'personalInformationForm',
    familyInformationForm: 'familyInformationForm',
    academicInformationForm: 'academicInformationForm',
    // recommendationsRequired: 'recommendationsRequired',
    recommendersForm: 'recommendersForm',
    extracurricularForm: 'extracurricularForm',
    covidProtocolForm: 'covidProtocolForm',
    resumeForm: 'resumeForm',
    transcriptForm: 'transcriptForm',
    shortResponsesForm: 'shortResponsesForm',
    scholarshipForm: 'scholarshipForm',
    taxForm: 'taxForm',
    additionalDocumentsForm: 'additionalDocumentsForm',
    confirmationForm: 'confirmationForm',
  };

  let dataToCheck = programsAllThings
    .filter((item) => program[item])
    .map((item) => programAllThingsValue[item]);

  const handleFormSubmit = async (values) => {
    console.log(values, 'ssssss');
    try {
      await submitPersonalInformationForm(
        registeredProgramId,
        profile.uid,
        values
      );
      handleCloseForm();
      const indexOfPersonalInfoForm = dataToCheck.indexOf(
        'personalInformationForm'
      );
      if (
        indexOfPersonalInfoForm !== -1 &&
        indexOfPersonalInfoForm < dataToCheck.length - 1
      ) {
        const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
        handleSetForm(nextForm);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(closeDialog());
  };
  const handleFormSave = async (values) => {
    console.log(values, 'gh');
    if (values.permanentResidentNumber == undefined) {
      values.permanentResidentNumber = '';
    }
    try {
      if (values?.dob) {
        if (Array.from(values.dob.split('-')[0])[0] === '0') return;
      }

      function hasAllKeys(arr, values) {
        for (const key in arr) {
          if (arr.hasOwnProperty(key) && !values.hasOwnProperty(key)) {
            return false;
          }
        }
        return true;
      }

      // Check if all keys from object A exist in object B
      const conditionMet = hasAllKeys(arr, values);
      if (conditionMet) {
        values.allRequiredDocumentFilled = true;
      }
      if (!conditionMet) {
        values.allRequiredDocumentFilled = false;
      }
      console.log(values, 'hg');
      await updatePersonalInformationForm(
        registeredProgramId,
        profile.uid,
        values
      );

      handleCloseForm();
    } catch (error) {
      console.log(error);
    }
  };
  const notify = () => toast.success('Progress Autosaved.');

  const handleFormAutoSave = async (values) => {
    console.log(values, 'gh');
    if (values.permanentResidentNumber == undefined) {
      values.permanentResidentNumber = '';
    }
    try {
      if (values?.dob) {
        if (Array.from(values.dob.split('-')[0])[0] === '0') return;
      }

      function hasAllKeys(arr, values) {
        for (const key in arr) {
          if (arr.hasOwnProperty(key) && !values.hasOwnProperty(key)) {
            return false;
          }
        }
        return true;
      }

      // Check if all keys from object A exist in object B
      const conditionMet = hasAllKeys(arr, values);
      if (conditionMet) {
        values.allRequiredDocumentFilled = true;
      }
      if (!conditionMet) {
        values.allRequiredDocumentFilled = false;
      }
      console.log(values, 'hg');
      await updatePersonalInformationForm(
        registeredProgramId,
        profile.uid,
        values
      );
      notify();

      // setOpen(true);
      // handleCloseForm();
    } catch (error) {
      console.log(error);
    }
  };
  const saveInterval = 5 * 60 * 1000; // 5 minutes in milliseconds

  useEffect(() => {
    const saveFormInterval = setInterval(() => {
      handleFormAutoSave(currentValues);
      // setOpen(true);
    }, saveInterval);

    // Clear the interval when the component is unmounted
    return () => clearInterval(saveFormInterval);
  }, [currentValues]); // Run the effect whenever currentValues changes

  return (
    <Fragment>
      <Toaster />

      <form autoComplete={'off'} onSubmit={handleSubmit(handleFormSubmit)}>
        {/*PERSONAL INFORMATION*/}
        <Fragment>
          <Typography className={classes.formName}>
            Personal Information
          </Typography>

          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid item container direction={'column'}>
                <Grid item>
                  {/*NAME*/}
                  <TextInputForm
                    fieldName={'firstName'}
                    question={'First Name:'}
                  />
                  <TextInputForm
                    fieldName={'lastName'}
                    question={'Last Name:'}
                  />

                  <TextInputForm
                    fieldName={'middleInitial'}
                    question={'Middle Initial:'}
                  />
                  {/*GENDER*/}
                  <SelectInputForm
                    fieldName={'gender'}
                    question={'Gender'}
                    onChange={null}
                    options={[
                      { value: '' },
                      { value: 'Male' },
                      { value: 'Female' },
                      { value: 'Non-binary' },
                      // { value: 'Prefer not to Disclose' },
                    ]}
                  />
                  {/*DOB*/}
                  <Grid
                    item
                    container
                    className={classes.fieldContainer}
                    alignItems={'center'}
                  >
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Grid item container direction={'column'}>
                        <Grid item>
                          <Typography
                            variant={'body1'}
                            className={classes.questionText}
                          >
                            Date of Birth
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Grid item>
                        <Grid item container direction={'column'}>
                          <Grid item>
                            <Field
                              name={'dob'}
                              className={classes.datePickerFields}
                              component={DatePickerInput}
                              type={'text'}
                              inputVariant={'outlined'}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* POLO SIZE */}
                  <SelectInputForm
                    fieldName={'poloSize'}
                    question={'Polo Size'}
                    onChange={null}
                    options={[
                      { value: '' },
                      { value: 'XS' },
                      { value: 'S' },
                      { value: 'M' },
                      { value: 'L' },
                      { value: 'XL' },
                      { value: '2XL' },
                      { value: '3XL' },
                    ]}
                  />

                  {/*ADDRESS*/}
                  <TextInputForm
                    fieldName={'address1'}
                    question={'Address 1:'}
                  />

                  {/*ADDRESS 2*/}
                  <TextInputForm
                    fieldName={'address2'}
                    question={'Address 2:'}
                  />
                  {/*CITY*/}
                  <TextInputForm fieldName={'city'} question={'City:'} />

                  {/*STATE*/}
                  <SelectInputForm
                    fieldName={'state'}
                    question={'State:'}
                    options={states}
                    text={true}
                  />

                  {/*ZIP*/}
                  <TextInputForm fieldName={'zipCode'} question={'Zip Code:'} />

                  {/*EMAIL*/}
                  <TextInputForm
                    fieldName={'preferredEmail'}
                    question={'Preferred Email:'}
                  />
                  <TextInputForm
                    fieldName={'secondaryEmail'}
                    question={'Secondary Email:'}
                  />

                  {/*MOBILE NUMBER*/}
                  <TextInputForm
                    fieldName={'mobileNumber'}
                    question={'Mobile Number:'}
                  />
                  {/*HOUSE PHONE NUMBER*/}
                  <TextInputForm
                    fieldName={'housePhoneNumber'}
                    question={'House Phone Number:'}
                  />

                  {/*AFTER GRADUATION*/}
                  <SelectInputForm
                    fieldName={'afterGraduation'}
                    question={
                      'After graduation, will you be applying as a first-generation college applicant?'
                    }
                    options={[{ value: '' }, { value: 'Yes' }, { value: 'No' }]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>

        {/*ETHNIC BACKGROUND*/}

        <Fragment>
          <Typography className={classes.formName}>
            Ethnic Background
          </Typography>

          <Grid item container direction={'column'}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid item container direction={'column'}>
                  <Grid item>
                    <SelectInputForm
                      fieldName={'ethnicBackground'}
                      question={
                        'Please select the option that best describes your ethnic background:'
                      }
                      options={[
                        { value: '' },
                        { value: 'Asian American/Pacific Islander' },
                        { value: 'Black/African American' },
                        { value: 'Caucasian' },
                        { value: 'Latino' },
                        { value: 'Multi-racial' },
                        { value: 'Native American' },
                        { value: 'Other' },
                        // { value: "Prefer not to answer" },
                      ]}
                    />
                    {ethnicOther && (
                      <TextInputForm
                        fieldName={'ethnicOther'}
                        question={'Please Specify:'}
                      />
                    )}

                    {/*<TextInputForm*/}
                    {/*    fieldName={"adaRequireAccommodation"}*/}
                    {/*    question={"Will you require accommodations in accordance with the American Disability Act (ADA Title III):*"}*/}
                    {/*/>*/}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>

        {/*CITIZENSHIP STATUS*/}
        <Fragment>
          <Typography className={classes.formName}>
            Citizenship Status
          </Typography>

          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid item container direction={'column'}>
                <Grid item>
                  <SelectInputForm
                    fieldName={'citizenshipStatus'}
                    question={'Please indicate your citizenship status:*'}
                    options={[
                      { value: '' },
                      { value: 'US Citizen' },
                      { value: 'US Permanent resident' },
                      { value: 'Dual US Citizen' },
                    ]}
                  />
                </Grid>

                {dualCitizenship && (
                  <TextInputForm
                    fieldName={'otherCountryOfCitizenship'}
                    question={'Please specify other country of citizenship:*'}
                  />
                )}

                <TextInputForm
                  fieldName={'countryOfOrigin'}
                  question={'Please enter your Country of Origin:*'}
                />

                {uspermit && (
                  <TextInputForm
                    fieldName={'permanentResidentNumber'}
                    question={'What is your permanent resident number?*'}
                  />
                )}

                <TextInputForm
                  fieldName={'nativeFamilyLanguage'}
                  question={
                    'What is the native language of your household/family?*'
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Fragment>

        {/*AMERICANS WITH DISABILITIES*/}
        <Fragment>
          <Typography className={classes.formName}>
            Americans with Disabilities Act (ADA)
          </Typography>

          <Grid item container direction={'column'}>
            <Grid item>
              <Grid item container direction={'column'}>
                <Grid item>
                  <Typography className={classes.formText}>
                    The LEADing for Life Programs complies with the Americans
                    with Disabilities Act (ADA), which prohibits discrimination
                    of programs, services and activities for individuals with
                    disabilities․
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid item container direction={'column'}>
                  <Grid item>
                    <SelectInputForm
                      fieldName={'americanWithDisabilitiesAct'}
                      question={
                        'Will you require accommodations in accordance with the American Disability Act (ADA Title III):'
                      }
                      options={[
                        { value: '' },
                        { value: 'Yes' },
                        { value: 'No' },
                      ]}
                    />
                    {/*OTHER*/}
                    {requireAccommodation && (
                      <TextInputForm
                        fieldName={'specifyRequireAccommadation'}
                        question={'Please Specify:'}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>

        {/*BUTTONS*/}
        <Grid item style={{ marginTop: '1.5em' }}>
          {invalid && (
            <Grid item container style={{ marginBottom: '0.5em' }}>
              <Grid item style={{ marginLeft: 'auto' }}>
                <Typography
                  variant={'subtitle1'}
                  style={{ color: theme.palette.error.main, fontWeight: 300 }}
                >
                  Fill out all required fields.
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid item container>
            <Grid item style={{ marginLeft: 'auto' }}>
              <Button
                variant={'outlined'}
                color={'primary'}
                className={classes.button}
                onClick={() => handleFormSave(currentValues)}
              >
                Save for Later
              </Button>

              <Button
                variant={'outlined'}
                color={'primary'}
                className={classes.continueButton}
                type={'submit'}
                disabled={submitting}
              >
                Continue{' '}
                <span>
                  <strong> &#8594;</strong>
                </span>
              </Button>

              <Button
                variant={'outlined'}
                color={'secondary'}
                className={classes.button}
                onClick={() => handleCloseForm()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: 'personalInformationForm',
    enableReinitialize: true,
    validate,
  })(PersonalInformationForm)
);
