import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, change, reduxForm, reset } from 'redux-form';
import TextInput from '../../common/form/TextInput';
import TextEditor from '../../common/TextEditor/TextEditor';
import TextInputForEmail from '../../common/form/TextInputForEmail';
import { initialize } from 'redux-form';
import {
  fetchTemplate,
  updateTemplate,
} from '../../store/actions/adminActions/settingsActions/settingsActions';
import { useDispatch } from 'react-redux';

const actions = {
  fetchTemplate,
  updateTemplate,
};
const mapStateToProps = (state, ownProps) => {
  console.log(state, 'statess');
  let template = {};
  let templateDescription = '';

  if (state.adminSettings.template && state.adminSettings.template.length > 0) {
    template = state.adminSettings.template[0];
    templateDescription = state.adminSettings.template[0].html;
  }

  // if (ownProps.toValue) {
  //   return {
  //     initialValues: {
  //       to: ownProps.toValue,
  //     },
  //   };
  // }

  return {
    // initialValues: {
    //   to: ownProps?.toValue,

    //   subject: template?.templateSubject || '', // Adjust property name as needed
    // },
    // initialValues: {
    //   to: ownProps?.toValue,

    //   // subject: template?.templateSubject || '', // Adjust property name as needed
    // },
    // to: ownProps?.toValue,
    subject: template?.templateSubject || '',
    template,
    templateDescription,
  };
};

// useEffect(() => {
//   if () {
//     fetchTemplate(id);
//   }

//   setEditorValue(template.html);
//   fetchTemplate(id);
//   setEditorValue(templateDescription);
// }, [id, fetchTemplate, templateDescription]);

const SendEmailDialog = ({
  isOpen,
  toValue,
  templates,
  closeDialog,
  setDialog,
  fullName,
  setFullName,
  submitting,
  handleSubmit,
  handleSendEmail,
  editorValue,
  setEditorValue,
  fetchTemplate,
  template,
  setToValue,
  selectedApplicantRowIds,
  setSelectedApplicantRowIds,
  onSelectionModelChange,
  handleSelectionModelChange2,
  setCheckboxSelection,
  reset,
  handleClose,
  templateDescription,
  selectedTemplate,
  setSelectedTemplate,
  // dispatch,
}) => {
  // const [selectedTemplate, setSelectedTemplate] = useState('');
  const [initialTemplateSubject, setInitialTemplateSubject] = useState('');
  console.log(initialTemplateSubject, 'initialTemplateSubject');
  console.log(templates, 'templates');
  console.log(selectedTemplate, 'selectedTemplate');
  console.log(toValue, 'toValue');
  console.log(isOpen, 'isOpen');
  const dispatch = useDispatch();
  const myfunction = async (event) => {
    const selectedTemplateId = event?.target?.value;
    console.log(selectedTemplateId, 'selectedTemplateId');
    // setSelectedTemplate(event?.target?.value);
    setSelectedTemplate(selectedTemplateId);
    await fetchTemplate(selectedTemplateId);
    const selectedTemplateObject = await templates?.find(
      (template) => template.id === selectedTemplateId
    );
    const initialSubject = selectedTemplateObject?.templateSubject || '';
    console.log(initialSubject, 'initialSubject');
    // const templateSubject = initialSubject;
    // initialize('sendEmail', { templateSubject });
    setInitialTemplateSubject(initialSubject);
    // initialize('sendEmail', { subject: initialSubject });
    // initialize('sendEmail', { subject: initialSubject });

    // dispatch(change('sendEmail', 'subject', initialSubject));
    // fetchTemplate(event?.target?.value);
    setEditorValue(selectedTemplateObject?.html || '');

    console.log(templateDescription, 'mansi');
  };
  useEffect(() => {
    // myfunction();
    setEditorValue(template?.html || '');
  }, [
    selectedTemplate,
    fetchTemplate,
    templateDescription,
    ,
    setEditorValue,
    template.html,
  ]);
  useEffect(() => {
    if (!isOpen) {
      setToValue('');
      // handleSelectionModelChange2([]);
      setEditorValue('');
    }
  }, [isOpen, setEditorValue, handleSelectionModelChange2]);
  useEffect(() => {
    // Check if the selected template has changed
    if (selectedTemplate !== '') {
      // Find the selected template object
      const selectedTemplateObject = templates?.find(
        (template) => template.id === selectedTemplate
      );
      console.log(selectedTemplateObject, 'selectedTemplateObject');
      // Update the subject field value with the template's subject
      dispatch(
        change(
          'sendEmail',
          'subject',
          selectedTemplateObject?.templateSubject || ''
        )
      );
    }
  }, [dispatch, selectedTemplate, templates]);

  const myfun = () => {
    setEditorValue('');
    // setSelectedApplicantRowIds([]);

    setInitialTemplateSubject('');
    // fetchTemplate(event?.target?.value);
    setSelectedTemplate('');

    setToValue('');
    setDialog(false);
    // dispatch(reset('sendEmail', {}));

    reset('sendEmail', {});
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      // onClose={handleClose} // Add this line
      // onExited={handleClose}
      maxWidth="md"
      disableEnforceFocus
    >
      {console.log(toValue, 'to')}
      <form onSubmit={handleSubmit(handleSendEmail)}>
        <DialogTitle>Send Email</DialogTitle>
        <FormControl
          fullWidth
          style={{
            paddingLeft: '27px',
            marginTop: '6px',
          }}
          // sx={{ m: 1, minWidth: 70 }}
        >
          <InputLabel
            id="demo-simple-select-label"
            style={{ paddingLeft: '16px', marginTop: '-5px' }}
          >
            Select Template
          </InputLabel>
          <Select
            style={{ width: '94%', padding: '2.5px 1px' }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedTemplate}
            label="Age"
            onChange={(event) => myfunction(event)}
          >
            {templates?.map((i, j) => (
              <MenuItem key={i.id} value={i?.id}>
                {i.templateName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <Select
          value={selectedTemplate}
          label="Age"
          onChange={(event) => {
            setSelectedTemplate(event.target.value);
          }}
        >
          {templates.map((i, j) => (
            <MenuItem key={i.id} value={i?.templateName}>
              {i.templateName}
            </MenuItem>
          ))}
          Send Emails
        </Select> */}
        <DialogContent>
          <Field
            name={'to'}
            label={'To'}
            component={TextInputForEmail}
            // component="input"
            // toValue={toValue}
            type={'text'}
            variant={'outlined'}
            style={{
              padding: '8.5px 1px',
            }}
          />
          <Field
            name={'bcc'}
            label={'Bcc'}
            // component="input"
            component={TextInputForEmail}
            toValue={toValue}
            type="email"
            variant={'outlined'}
            placeholder={'Bcc'}
            style={{
              // padding: '8.5px 14px',
              width: '100%',
              boxSizing: 'border-box',
              borderRadius: '4px',
              borderColor: '#0000003b',
              height: '39.97px',
              outline: 'none',
              marginTop: '1rem',
            }}
          />
          {/* <Field
             name={'subject'}
           
            label={'Subject'}
            component={TextInput}
            type={'text'}
            variant={'outlined'}
            style={{ marginTop: '1rem' }}
          /> */}
          <Field
            name={'subject'}
            style={{
              padding: '1.5px 0px',
              width: '100%',
              boxSizing: 'border-box',
              borderRadius: '4px',
              borderColor: '#0000003b',
              height: '39.97px',
              outline: 'none',
              marginTop: '1rem',
            }}
            label={'Subject'}
            component={TextInput}
            type={'text'}
            variant={'outlined'}
            value={initialTemplateSubject} // Use value prop instead of initialValue
            // initialValue={initialTemplateSubject}
          />
          <Grid item style={{ marginTop: '1rem' }}>
            <Typography variant={'body1'}>Content</Typography>
            <TextEditor
              editorValue={editorValue}
              handleChange={setEditorValue}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={myfun} color="secondary" disabled={submitting}>
            Close
          </Button>
          <Button color={'primary'} type={'submit'} disabled={submitting}>
            Send
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default connect(
  mapStateToProps,
  actions,
  null
)(reduxForm({ form: 'sendEmail', enableReinitialize: true })(SendEmailDialog));
