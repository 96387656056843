import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  Check as CheckIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@mui/icons-material';
import { connect } from 'react-redux';
import { updateProgramPacketDownloaded } from '../../../../store/actions/applicantActions/programActions/applicantProgramActions';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  stageItem: {
    marginTop: '20px',
    marginBottom: '20px',
    padding: '11px',
    border: '1px solid #D5D5DC',
    // borderRight: '3px solid #0062FF',
    borderRight: '3px solid #D9512C',
  },
  stageItemContinue: {
    marginTop: '20px',
    marginBottom: '20px',
    padding: '11px',
    border: '1px solid #D5D5DC',
    borderRight: '3px solid #FF9900',
  },
  stageItemComplete: {
    marginTop: '20px',
    marginBottom: '20px',
    padding: '11px',
    border: '1px solid #FAFAFB',
    backgroundColor: '#FAFAFB',
    borderRight: '3px solid #3DD598',
  },
  stageItemCompleteAndDisabled: {
    marginTop: '20px',
    marginBottom: '20px',
    padding: '11px',
    border: '1px solid #FAFAFB',
    backgroundColor: '#FAFAFB',
    borderRight: '3px solid #696974',
  },
  stageItemPending: {
    marginTop: '20px',
    marginBottom: '20px',
    padding: '11px',
    backgroundColor: '#FAFAFB',
    // borderRight: '3px solid #D9512C',
    borderRight: '3px solid #0062FF',
  },
  stageName: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.1px',
    color: '#696974',
  },
  completeText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.1px',
    color: '#3DD598',
    cursor: 'pointer',
  },
  disableText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.1px',
    // color: '#696974',
    // i change the text color of disableText to blue i don't why disabled text is add but as per requirement i change the color
    color: '#0062FF',
  },
  downloadText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.1px',
    color: '#0b1dc5',
    cursor: 'pointer',
  },
  pendingText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.1px',
    color: '#D9512C',
    cursor: 'pointer',
  },
  startText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.1px',
    color: '#0062FF',
    cursor: 'pointer',
  },
  continueText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.1px',
    color: '#FF9900',
    cursor: 'pointer',
  },
}));

const actions = {
  updateProgramPacketDownloaded,
};

const ApprovedStages = ({
  program,
  registeredProgram,
  registeredProgramId,
  editApplication,
  updateProgramPacketDownloaded,
  approvedStages,
  approvedStagesTravel,
  handleSetForm,
  disabled,
}) => {
  const classes = useStyles();
  console.log(program, 'programss');
  const [paymentStatus, setPaymentStatus] = useState();
  const [every, setEvery] = useState(false);
  console.log(every, 'every');
  const handleUpdateProgramPacketDownloaded = () => {
    updateProgramPacketDownloaded(registeredProgramId);
  };

  const getPaymentStatus = () => {
    return approvedStages?.map((item) => {
      if (item?.formName == 'paymentForm') {
        setPaymentStatus(item?.databaseName);
      }
    });
  };

  useEffect(() => {
    if (approvedStages) {
      getPaymentStatus();
    }
  }, [approvedStages]);

  const accessSection = (stage) => {
    if (
      (disabled && !paymentStatus) ||
      (disabled &&
        paymentStatus &&
        (stage.formName === 'grantAcceptanceForm' ||
          stage.formName === 'scholarConsentForm' ||
          stage.formName === 'governmentIdForm' ||
          stage.formName === 'paymentForm'))
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isDisabled = (stage) => {
    if (!accessSection(stage)) {
      return true;
    }

    if (
      (registeredProgram.finalSubmissionCompleted &&
        stage.formName !== 'travelInformationForm' &&
        stage.formName !== 'requiredDocumentationForm') ||
      (disabled && !paymentStatus)
    ) {
      return true;
    } else if (
      stage.formName === 'travelInformationForm' ||
      stage.formName === 'requiredDocumentationForm'
    ) {
      if (
        program.packetDueDate &&
        new Date(program.packetDueDate) > new Date()
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const getClassName = (stage) => {
    if (!accessSection(stage)) {
      return classes.stageItemCompleteAndDisabled;
    } else if (
      stage.formName !== 'downloadProgramPacketForm' &&
      stage.formName !== 'requiredDocumentationForm' &&
      !stage.databaseName &&
      !stage.fieldData
    ) {
      return classes.stageItemPending;
    } else if (stage.databaseName) {
      return classes.stageItemComplete;
    } else if (stage.fieldData) {
      return classes.stageItemContinue;
    } else {
      return classes.stageItem;
    }
  };

  useEffect(() => {
    // if (program.travelFormRequired) {
    //   if (approvedStagesTravel.every((obj) => obj.show === false)) {
    //     setEvery(true);
    //   }
    // }
    if (
      program.travelFormRequired &&
      approvedStagesTravel.every((obj) => obj.show == false)
    ) {
      setEvery(true);
    }
    if (
      !program.travelFormRequired &&
      approvedStages.every((obj) => obj.show == false)
    ) {
      setEvery(true);
    }
  }, [approvedStages, approvedStagesTravel]);
  console.log('program.travelFormRequired :>> ', program.travelFormRequired);

  return (
    <>
      <Grid
        item
        style={{
          border: '1px solid white',
          borderRadius: '20px',
          width: '100%',
        }}
      >
        {!editApplication &&
          !program.travelFormRequired &&
          approvedStages.map((stage, index) => {
            if (
              !stage?.show ||
              (!(
                program.acceptanceRequiredDocuments &&
                program.acceptanceRequiredDocuments.length > 0
              ) &&
                stage.formName === 'requiredDocumentationForm')
            ) {
              return <></>;
            }
            if (
              program.freeProgram == true &&
              stage.formName === 'paymentForm'
            ) {
              return <></>;
            }
            return (
              <>
                <Grid
                  container
                  alignItems={'center'}
                  key={index}
                  className={getClassName(stage)}
                  disabled={disabled}
                  style={{ cursor: 'pointer' }}
                  onClick={(event) => {
                    if (!stage.databaseName && !stage.fieldData) {
                      return; // Don't execute onClick if the stage is pending
                    }
                    event.stopPropagation();
                    if (accessSection(stage)) handleSetForm(stage.formName);
                  }}
                >
                  <Grid item md={1}>
                    {stage.databaseName ? (
                      <CheckIcon
                        style={{ paddingLeft: '25px', color: '#D5D5DC' }}
                        fontSize={'small'}
                      />
                    ) : (
                      <CheckBoxOutlineBlankIcon
                        style={{ paddingLeft: '25px', color: '#D5D5DC' }}
                        fontSize={'small'}
                      />
                    )}
                  </Grid>
                  <Grid item md={9}>
                    <Typography className={classes.stageName}>
                      {stage.applicationStage}
                    </Typography>
                  </Grid>

                  {stage.formName !== 'downloadProgramPacketForm' ? (
                    <Grid item md={2} style={{ textAlign: 'center' }}>
                      {stage.databaseName ? (
                        <Typography className={classes.completeText}>
                          Completed
                        </Typography>
                      ) : stage.fieldData ? (
                        <Typography
                          className={classes.continueText}
                          onClick={() => {
                            if (accessSection(stage) && !disabled) {
                              if (
                                stage.formName ===
                                'downloadProgramPacketForm' &&
                                !registeredProgram.instituteDetails
                                  ?.programPacketURL
                              )
                                return;
                              handleSetForm(stage.formName);
                            }
                          }}
                          style={{ cursor: disabled && 'default' }}
                        >
                          Continue
                        </Typography>
                      ) : (
                        <Typography
                          className={classes.startText}
                          onClick={() => {
                            if (!stage.databaseName && !stage.fieldData) {
                              return; // Don't execute onClick if the stage is pending
                            }
                            if (accessSection(stage) && !disabled) {
                              if (
                                stage.formName ===
                                'downloadProgramPacketForm' &&
                                !registeredProgram.instituteDetails
                                  ?.programPacketURL
                              )
                                return;
                              handleSetForm(stage.formName);
                            }
                          }}
                          style={{ cursor: disabled && 'default' }}
                        >
                          Pending4444
                        </Typography>
                      )}
                    </Grid>
                  ) : (
                    <Grid item md={2} style={{ textAlign: 'center' }}>
                      {paymentStatus ? (
                        <Typography className={classes.completeText}>
                          Completed
                        </Typography>
                      ) : (
                        <Typography className={classes.completeText}>
                          Pending
                        </Typography>
                      )}
                    </Grid>
                  )}
                  {/*/!*FORM COMPLETED*!/*/}
                  {/*{registeredProgram.confirmationFormCompleted && (*/}
                  {/*    <Grid item md={2} style={{textAlign: 'center'}}>*/}
                  {/*      {stage.databaseName ? <Typography className={classes.completeText}>Completed</Typography> : <Typography className={classes.startText}>Start</Typography>}*/}
                  {/*    </Grid>*/}
                  {/*)}*/}
                </Grid>
              </>
            );
          })}
        {console.log('approvedStagesTravel :>> ', approvedStagesTravel)}
        {console.log('approvedStages :>> ', approvedStages)}

        {!editApplication &&
          program.travelFormRequired &&
          approvedStagesTravel.map((stage) => {
            if (
              !stage?.show ||
              (!(
                program.acceptanceRequiredDocuments &&
                program.acceptanceRequiredDocuments.length > 0
              ) &&
                stage.formName === 'requiredDocumentationForm')
            ) {
              return <></>;
            }
            if (
              !stage?.show ||
              (registeredProgram.institutionDetails?.virtual &&
                stage.formName === 'travelInformationForm')
            ) {
              return <></>;
            }
            if (
              program.freeProgram == true &&
              stage.formName === 'paymentForm'
            ) {
              return <></>;
            } else {
              return (
                <Grid
                  container
                  alignItems={'center'}
                  key={stage.id}
                  className={getClassName(stage)}
                  style={{ cursor: 'pointer' }}
                  disabled={disabled}
                  onClick={(event) => {
                    if (!stage.databaseName && !stage.fieldData) {
                      return; // Don't execute onClick if the stage is pending
                    }
                    event.stopPropagation();
                    if (accessSection(stage)) {
                      const url = program.manualPacket
                        ? program.programPacketDocuments[0].file.fileURL
                        : registeredProgram.institutionDetails
                          ?.programPacketURL;
                      if (
                        stage.formName === 'downloadProgramPacketForm' &&
                        url
                      ) {
                        window.open(url, '_blank');
                        handleUpdateProgramPacketDownloaded();
                      } else {
                        handleSetForm(stage.formName);
                      }
                    }
                  }}
                >
                  <Grid item md={1}>
                    {stage.databaseName ? (
                      <CheckIcon
                        style={{ paddingLeft: '25px', color: '#D5D5DC' }}
                        fontSize={'small'}
                      />
                    ) : (
                      <CheckBoxOutlineBlankIcon
                        style={{ paddingLeft: '25px', color: '#D5D5DC' }}
                        fontSize={'small'}
                      />
                    )}
                  </Grid>
                  <Grid item md={9}>
                    <Typography className={classes.stageName}>
                      {stage.applicationStage}
                    </Typography>
                  </Grid>
                  {stage.formName === 'downloadProgramPacketForm' ||
                    stage.formName === 'requiredDocumentationForm' ? (
                    // || stage.formName === "studentQuestionnaireForm"
                    <Grid item md={2} style={{ textAlign: 'center' }}>
                      {(program?.manualPacket === true &&
                        program?.programPacketDocuments?.[0]?.file?.fileURL) ||
                        registeredProgram?.institutionDetails
                          ?.programPacketURL ? (
                        stage.formName === 'downloadProgramPacketForm' ? (
                          <Typography
                            className={
                              isDisabled(stage)
                                ? classes.disableText
                                : classes.downloadText
                            }
                          >
                            <a
                              href={
                                program.manualPacket
                                  ? program.programPacketDocuments[0].file
                                    .fileURL
                                  : registeredProgram?.institutionDetails
                                    .programPacketURL
                              }
                              onClick={() => {
                                if (accessSection(stage)) {
                                  handleUpdateProgramPacketDownloaded();
                                }
                              }}
                              style={{
                                pointerEvents: disabled ? 'none' : 'auto',
                              }}
                              target={'_blank'}
                              rel={'noopener noreferrer'}
                            >
                              Download
                            </a>
                          </Typography>
                        ) : stage.databaseName ? (
                          <Typography
                            className={
                              isDisabled(stage) ||
                                (program.packetDueDate &&
                                  !(new Date(program.packetDueDate) > new Date()))
                                ? classes.disableText
                                : classes.completeText
                            }
                            onClick={() => {
                              if (
                                accessSection(stage) &&
                                program.packetDueDate &&
                                new Date(program.packetDueDate) > new Date()
                              ) {
                                handleSetForm(stage.formName);
                              }
                            }}
                          >
                            Completed
                          </Typography>
                        ) : stage.fieldData ? (
                          <Typography
                            className={
                              isDisabled(stage)
                                ? classes.disableText
                                : classes.continueText
                            }
                            onClick={() => {
                              if (accessSection(stage) && !disabled) {
                                if (
                                  stage.formName ===
                                  'downloadProgramPacketForm' &&
                                  !registeredProgram.instituteDetails
                                    ?.programPacketURL
                                )
                                  return;
                                handleSetForm(stage.formName);
                              }
                            }}
                            style={{ cursor: disabled && 'default' }}
                          >
                            Continue
                          </Typography>
                        ) : (
                          <Typography
                            className={
                              isDisabled(stage)
                                ? classes.disableText
                                : classes.startText
                            }
                            onClick={() => {
                              if (accessSection(stage) && !disabled) {
                                if (
                                  stage.formName ===
                                  'downloadProgramPacketForm' &&
                                  !registeredProgram.instituteDetails
                                    ?.programPacketURL
                                )
                                  return;
                                handleSetForm(stage.formName);
                              }
                            }}
                            style={{ cursor: disabled && 'default' }}
                          >
                            Start
                          </Typography>
                        )
                      ) : (
                        <Typography
                          className={
                            isDisabled(stage)
                              ? classes.pendingText
                              : classes.pendingText
                          }
                        >
                          Pending
                        </Typography>
                      )}
                    </Grid>
                  ) : (
                    <Grid item md={2} style={{ textAlign: 'center' }}>
                      {stage.databaseName ? (
                        <Typography
                          className={
                            // isDisabled(stage)
                            //   ? classes.disableText
                            // :
                            classes.completeText
                          }
                          onClick={() => {
                            let disabled = false;
                            if (
                              registeredProgram.finalSubmissionCompleted &&
                              stage.formName !== 'travelInformationForm' &&
                              program.packetDueDate &&
                              new Date(program.packetDueDate) > new Date()
                            ) {
                              disabled = true;
                            }

                            if (
                              program.packetDueDate &&
                              !(new Date(program.packetDueDate) > new Date())
                            ) {
                              disabled = true;
                            }

                            if (accessSection(stage) && !disabled) {
                              handleSetForm(stage.formName);
                            }
                          }}
                        >
                          Completed
                        </Typography>
                      ) : stage.fieldData ? (
                        <Typography
                          className={
                            isDisabled(stage)
                              ? classes.disableText
                              : classes.continueText
                          }
                          onClick={() => {
                            if (accessSection(stage) && !disabled) {
                              if (
                                stage.formName ===
                                'downloadProgramPacketForm' &&
                                !registeredProgram.instituteDetails
                                  ?.programPacketURL
                              )
                                return;
                              handleSetForm(stage.formName);
                            }
                          }}
                          style={{ cursor: disabled && 'default' }}
                        >
                          Continue
                        </Typography>
                      ) : (
                        <Typography
                          className={
                            isDisabled(stage)
                              ? classes.disableText
                              : classes.startText
                          }
                          onClick={() => {
                            if (accessSection(stage) && !disabled) {
                              if (
                                stage.formName ===
                                'downloadProgramPacketForm' &&
                                !registeredProgram.instituteDetails
                                  ?.programPacketURL
                              )
                                return;
                              handleSetForm(stage.formName);
                            }
                          }}
                          style={{ cursor: disabled && 'default' }}
                        >
                          Start
                        </Typography>
                      )}
                    </Grid>
                  )}
                  {/*/!*FORM COMPLETED*!/*/}
                  {/*{registeredProgram.confirmationFormCompleted && (*/}
                  {/*    */}
                  {/*)}*/}
                </Grid>
              );
            }
          })}
        {every && (
          <Typography
            variant={'h6'}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p>
              Congratulations on your acceptance into the LEAD{' '}
              <strong>{program.programName}</strong>! We look forward to
              welcoming you into the LEADing for Life community.
            </p>
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default connect(null, actions)(ApprovedStages);
