import { createReducer } from '../../../../common/util/reducerUtil';
import { FETCH_REQUEST_INFORMATION } from '../../../constants/adminConstants/programConstants/adminProgramConstants';
import {
  FETCH_ALL_TRANSACTIONS,
  FETCH_ALL_USERS,
} from '../../../constants/adminConstants/transactionConstants/transactionConstants';

const initialState = {
  allTransactions: [],
  users: [],
  allRequestedInfo: [],
};

const getAllTransactions = (state, payload) => {
  return {
    ...state,
    allTransactions: payload.transactions,
  };
};
const getAllRequestedInfo = (state, payload) => {
  console.log(payload, 'payload');
  return {
    ...state,
    allRequestedInfo: payload,
  };
};

const getAllUsers = (state, payload) => {
  return {
    ...state,
    users: payload,
  };
};

export default createReducer(initialState, {
  [FETCH_ALL_TRANSACTIONS]: getAllTransactions,
  [FETCH_REQUEST_INFORMATION]: getAllRequestedInfo,
  [FETCH_ALL_USERS]: getAllUsers,
});
