import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import GradeLookup from '../../pages/admin/programs/GradeLookup';
import { format } from 'date-fns';
import {
  combineValidators,
  composeValidators,
  createValidator,
  isRequired,
} from 'revalidate';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../common/form/TextInput';
import SelectInput from '../../common/form/SelectInput';
import './programItem.css';
import log from '../../assets/dashboard/studenttech.png';
const useStyles = makeStyles((theme) => ({
  item: {
    paddingLeft: '14px',
    paddingRight: '14px',
    marginBottom: '30px',
  },
  image: {
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    width: '100%',
    height: '200px',
    objectFit: 'contain',
    backgroundColor: '#fff',
  },
  imageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '8px',
  },
  programNameContainer: {
    height: '84px',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
  },
  programName: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '1em',
    lineHeight: '28px',
    margin: '0 0 10px',
  },
  programCreds: {
    marginTop: '12px',
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  textContainer: {
    paddingLeft: '10px',
    paddingRight: '10px',
    // marginTop: '-60px',
    paddingTop: '12px',
  },
  textWrapper: {
    borderRadius: '50px',
    padding: '26.71px',
    backgroundColor: 'white',
    width: '100%',
    textAlign: 'left',
  },
  columnLeftText: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '14px !important',
    lineHeight: '16.41px',
    letterSpacing: '0.1px',
    color: '#44444F',
  },
  columnRightText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '14px !important',
    lineHeight: '16.41px',
    letterSpacing: '0.1px',
    color: '#696974',
  },
  learnMoreText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: '#0062FF',
    cursor: 'pointer',
  },
  button: {
    width: '120px',
    height: '38px',
    borderRadius: '10px',
    borderColor: '#0062FF',
    color: '#0062FF',
    textTransform: 'none',
    '&:hover': {
      color: '#0062FF',
      backgroundColor: 'white',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const validate = combineValidators({
  firstName: isRequired({ message: 'Required' }),
  lastName: isRequired({ message: 'Required' }),
  email: composeValidators(
    createValidator(
      (message) => (value) => {
        if (
          value &&
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
            value
          )
        ) {
          return message;
        }
      },
      'Invalid email format. Email must not contain spaces. '
    ),
    isRequired({ message: 'Required' })
  )(),
  phone: isRequired({ message: 'Required' }),
  gradeLevel: isRequired({ message: 'Required' }),
  highSchool: isRequired({ message: 'Required' }),
});

const ProgramItem = ({
  program,
  categories,
  applicantsPrograms,
  profile,
  handleSubmit,
  submitting,
}) => {
  const [getNotified, setGetNotified] = useState(false);

  const classes = useStyles();
  console.log(program, 'hjk');

  const grades = GradeLookup.grades;

  const history = useHistory();

  // const [expand, setExpand] = useState(false);
  // const [registeredProgram, setRegisteredProgram] = useState(false)
  // const [programOpen, setProgramOpen] = useState(true)

  const handleRegisterProgram = () => {
    history.push(`/program/view?id=${program.id}&register=true#registration`);
    window.scrollTo(0, 0);
    window.location.reload();
  };

  // useEffect(() => {
  //
  //
  //   if (applicantsPrograms.some(item => item.programId === program.id)) {
  //     setRegisteredProgram(true)
  //   } else {
  //     setRegisteredProgram(false)
  //   }
  //
  //   const unixTime = getUnixTime(new Date());
  //   if (program.applicationOpenDate > unixTime) {
  //     setProgramOpen(false)
  //   }
  //
  // }, [applicantsPrograms, program])

  return (
    <Grid item lg={4} md={4} sm={12} xs={12} className={classes.item}>
      <Grid container direction={'column'}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          container
          direction={'column'}
        >
          <Grid item className={classes.imageContainer}>
            <img
              className={classes.image}
              src={program.programImageURL ? program.programImageURL : log}
              alt=""
            />
          </Grid>
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          container
          className={classes.textContainer}
        >
          <Grid item className={classes.textWrapper}>
            <Grid container direction={'column'}>
              <Grid item className={classes.programNameContainer}>
                <Typography className={classes.programName}>
                  {program.programName}
                </Typography>
              </Grid>

              {/*<Grid item style={{height: '100px'}}>*/}
              <Grid item>
                <Grid item container direction={'column'}>
                  <Grid item>
                    <Grid container className={classes.programCreds}>
                      <Grid item lg={6} xs={6} sm={6}>
                        <Typography className={classes.columnLeftText}>
                          Grade Level:{' '}
                        </Typography>
                      </Grid>
                      <Grid item lg={6} xs={6} sm={6}>
                        <Typography className={classes.columnRightText}>
                          {grades.map((grade) =>
                            program[grade.fieldName] ? `${grade.value} ` : null
                          )}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container className={classes.programCreds}>
                      <Grid item lg={6} xs={6} sm={6}>
                        <Typography className={classes.columnLeftText}>
                          Program Fees:{' '}
                        </Typography>
                      </Grid>
                      <Grid item lg={6} xs={6} sm={6}>
                        <Typography className={classes.columnRightText}>
                          ${Number(program?.programCost).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container className={classes.programCreds}>
                      <Grid item lg={6} xs={6} sm={6}>
                        <Typography className={classes.columnLeftText}>
                          Application Open:
                        </Typography>
                      </Grid>
                      <Grid item lg={6} xs={6} sm={6}>
                        <Typography className={classes.columnRightText}>
                          {format(
                            new Date(program?.applicationOpen),
                            'EEE MMM do y'
                          )}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container className={classes.programCreds}>
                      <Grid item lg={6} xs={6} sm={6}>
                        <Typography className={classes.columnLeftText}>
                          Application Deadline:
                        </Typography>
                      </Grid>
                      <Grid item lg={6} xs={6} sm={6}>
                        <Typography className={classes.columnRightText}>
                          {format(
                            new Date(program?.applicationDeadline),
                            'EEE MMM do y'
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                justifyContent="space-between"
                alignItems="center"
                style={{ marginTop: '20px', textAlign: 'center' }}
              >
                <Button
                  style={{
                    backgroundColor: '#0062FF',
                    color: 'white',
                    fontWeight: '600',
                  }}
                  className={classes.button}
                  onClick={() => {
                    history.push(`/program/view?id=${program.id}&register=true`);
                    window.location.reload()
                  }
                  }
                >
                  Learn more
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {new Date(program.applicationDeadline) > new Date() &&
            new Date(program.applicationOpen) < new Date() &&
            !profile.isAdmin &&
            !profile.isReviewer && (
              <Grid
                container
                direction={'column'}
                alignItems={'center'}
                style={{ marginTop: '17px' }}
              >
                <Grid item style={{ textAlign: 'center !important' }}>
                 
                  <Grid>
                    <Button
                      style={{
                        backgroundColor: '#5dad45',
                        color: 'white',
                        fontWeight: '600',
                      }}
                      className={classes.button}
                      onClick={() => handleRegisterProgram()}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
        </Grid>
      </Grid>
      <Dialog
        open={getNotified}
        onClose={() => setGetNotified(false)}
        maxWidth="md"
        disableEnforceFocus
      >
        <form onSubmit={handleSubmit(() => { })}>
          <DialogTitle>Get Notified</DialogTitle>
          <DialogContent style={{ width: 520 }}>
            <Field
              name={'firstName'}
              label={'First Name'}
              component={TextInput}
              type={'text'}
              variant={'outlined'}
            />
            <Field
              name={'lastName'}
              label={'Last Name'}
              component={TextInput}
              type={'text'}
              variant={'outlined'}
              style={{ marginTop: '1rem' }}
            />
            <Field
              name={'email'}
              label={'Email'}
              component={TextInput}
              type={'text'}
              variant={'outlined'}
              style={{ marginTop: '1rem' }}
            />
            <Field
              name={'phone'}
              label={'Phone Number'}
              component={TextInput}
              type={'number'}
              variant={'outlined'}
              style={{ margin: '1rem 0' }}
            />
            <Field
              name={'gradeLevel'}
              label={`Grade Level`}
              component={SelectInput}
              type={'text'}
              variant={'outlined'}
            >
              {GradeLookup.grades.map((grade) => (
                <MenuItem key={grade.value} value={grade.value}>
                  {grade.value}
                </MenuItem>
              ))}
            </Field>
            <Field
              name={'highSchool'}
              label={'High School'}
              component={TextInput}
              type={'text'}
              variant={'outlined'}
              style={{ marginTop: '1rem' }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setGetNotified(false)}
              color="secondary"
              disabled={submitting}
            >
              Close
            </Button>
            <Button color={'primary'} type={'submit'} disabled={submitting}>
              Send
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Grid>
  );
};

export default reduxForm({ form: 'getNotified', validate })(ProgramItem);
