import React from "react";
import { Grid, Typography, Button, useTheme } from "@mui/material";
import axios from "../../../../../../config/axios";
import { connect } from "react-redux";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../../../../../../store/actions/asyncActions/asyncActions";
import { makeStyles } from "@mui/styles";
import { createMuiTheme } from '@mui/styles'


const useStyles = makeStyles((theme) => ({
  container: {
    margin: "1em",
   // padding: "1em",
    border: "1px solid" + theme.palette.primary.main,
    borderRadius: "5px",
  },
  payButton: {
    backgroundColor: "#4fae54",
    color:"#ffffff",
    outline: "transparent",
    fontWeight: "600",
    width: "160px",
    foneSize: "1rem",
    letterSpacing: "2px",
    "&:disabled": {
      backgroundColor:'#e5e5e5'||'#4fae54',
    },
    "&:hover":{
      backgroundColor:'#469f4b'||'#4fae54', 
      transform: "scale(0.98)",
      /* Scaling button to 0.98 to its original size */
      boxShadow: "3px 2px 22px 1px rgba(0, 0, 0, 0.24)",
    },
},

button:{
  "&:disabled": {
    backgroundColor:'#e5e5e5'||'#4fae54',
  }
},


  
}));

const mapStateToProps = (state) => {
  return {
    processingFee: state.applicationSettings.processingFee,
  };
};

const PaymentOption = ({
  split,
  program,
  registeredProgramId,
  registeredProgram,
  paymentName,
  paymentReceived,
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
  type,
  disabled,
  processingFee,
  processingFeeAmount,
  isPaymentWaived
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const handleShowPaymentForm = async () => {
    try {
      let price = split
        ? (((Number(program?.programCost) -
        (Number(registeredProgram?.scholarshipAmount || 0) +
          Number(registeredProgram?.sponsorshipAmount || 0)))) /
          2).toFixed(2)
        : ((Number(program?.programCost || 0) -
        (Number(registeredProgram?.scholarshipAmount || 0) +
          Number(registeredProgram?.sponsorshipAmount || 0)))).toFixed(2);
      if (type === "payment2")
        price = (
          Number(program?.programCost || 0) -
          (Number(registeredProgram?.scholarshipAmount || 0) +
            Number(registeredProgram?.sponsorshipAmount || 0)) -
          Number(registeredProgram?.payment1Amount || 0) +
          Number(processingFeeAmount ? processingFeeAmount / 2 : 0)
        ).toFixed(2);
      asyncActionStart();
      const response = await axios.post("stripeFunctions/payments/create", {
        items: [
          {
            price: price,
            name: paymentName,
            description: `A $${price} payment amount is required. 
                        Payments made by credit card will
                        incur an additional ${
                          processingFee || 2.9
                        }% processing fee.`,
          },
        ],
        registeredProgramId: registeredProgramId,
        type: type,
        applicantId: registeredProgram.applicantId,
        applicantName: registeredProgram.fullName,
        programId: program.id,
      });
      asyncActionFinish();
      window.location = response.data.url;
    } catch (err) {
      asyncActionError();
      console.log(err);
    }
  };

  return (
    <Grid item md={3} className={classes.container}>
      <Grid item container alignItems={"center"} direction={"column"} style={{ justifyContent:"center", display:"grid"}}>
        <Grid item  style={{textAlign:"center" }}>
          <Typography variant={"body1"} style={{ fontWeight: 600 }}>
            {paymentName}
          </Typography>
        </Grid>

        <Grid item  style={{textAlign:"center" }}>
          {split &&
            (type === "payment1" ? (
              registeredProgram?.payment1Amount ? (
                <Typography variant={"h5"} style={{ fontWeight: 600 }}>
                  {isPaymentWaived
                    ? "WAIVED"
                    : `$${registeredProgram?.payment1Amount}`}
                </Typography>
              ) : (
                <Typography variant={"h5"} style={{ fontWeight: 600 }}>
                  {isPaymentWaived
                    ? "WAIVED":`$${(
                    (Number(program?.programCost || 0) -
                      (Number(registeredProgram?.scholarshipAmount || 0) +
                        Number(registeredProgram?.sponsorshipAmount || 0))) /
                      2 +
                    Number(processingFeeAmount ? processingFeeAmount / 2 : 0)
                  ).toFixed(2)}`}
                </Typography>
              )
            ) : registeredProgram?.payment1Amount ? (
              <Typography variant={"h5"} style={{ fontWeight: 600 }}>
                {isPaymentWaived
                    ? "WAIVED":`$${(
                  Number(program?.programCost || 0) -
                  (Number(registeredProgram?.scholarshipAmount || 0) +
                    Number(registeredProgram?.sponsorshipAmount || 0)) -
                  Number(registeredProgram?.payment1Amount || 0) +
                  Number(processingFeeAmount ? processingFeeAmount : 0)
                ).toFixed(2)}`}
              </Typography>
            ) : (
              <Typography variant={"h5"} style={{ fontWeight: 600 }}>
                {isPaymentWaived
                    ? "WAIVED":`$${(
                  (Number(program?.programCost) -
                    (Number(registeredProgram?.scholarshipAmount || 0) +
                      Number(registeredProgram?.sponsorshipAmount || 0))) /
                    2 +
                  Number(processingFeeAmount ? processingFeeAmount / 2 : 0)
                ).toFixed(2)}`}
              </Typography>
            ))}
          {!split && (
            <Typography variant={"h5"} style={{ fontWeight: 600 }}>
              {isPaymentWaived
                    ? "WAIVED":`$${(
                Number(program?.programCost) -
                (Number(registeredProgram?.scholarshipAmount || 0) +
                  Number(registeredProgram?.sponsorshipAmount || 0)) +
                Number(processingFeeAmount || 0)
              ).toFixed(2)}`}
            </Typography>
          )}
        </Grid>

        <Grid item style={{ marginTop: "1em", textAlign:"center" }}>
          {!paymentReceived && (
            <Button
              size={"medium"}
              variant={"outlined"}
              color={"primary"}
              className={classes.payButton}
              onClick={() => handleShowPaymentForm()}
              disabled={disabled}
            >
              Pay
            </Button>
          )}
          {paymentReceived && (
            <Typography
              variant={"h5"}
              style={{
                fontWeight: 600,
                color: theme.palette.primary.main,
                fontSize: "1rem",
              }}
            >
              Payment Received
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
})(PaymentOption);
