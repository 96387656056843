import React from 'react';

export const DropDownIcon = (props) => {
    return (
        <svg
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.78993 0.333496C9.19524 0.333496 9.43213 0.79044 9.19853 1.12167L5.4613 6.42079C5.26211 6.70323 4.84328 6.70323 4.64409 6.42079L0.906854 1.12167C0.673254 0.79044 0.910144 0.333496 1.31546 0.333496L8.78993 0.333496Z"
                fill="#92929D"
            />
        </svg>
    );
};
