import React, { useRef } from 'react';

import { Editor } from '@tinymce/tinymce-react';

const TextEditor = ({ editorValue, handleChange }) => {
  return (
    <Editor
      value={editorValue}
      init={{
        relative_urls: false,
        remove_script_host: false,
        document_base_url: 'https://leadforlife-91d90.web.app/',
        block_unsupported_drop: true,
        menubar: true,
        height: 600,
        content_style: 'body {margin: 10px !important}',
        plugins: [
          ' advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code',
        ],
        toolbar:
          'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help | image',

        file_picker_types: 'image',
        default_img_height: 20, // Set your desired default height
        default_img_width: 30,
        file_picker_callback: function (callback, value, meta) {
          // Open file picker dialog
          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');

          // Handle file selection
          input.onchange = function () {
            const file = this.files[0];
            const reader = new FileReader();

            // Read the file and invoke the callback with the selected image's data
            reader.onload = function () {
              const imageData = {
                src: reader.result,
                alt: file.name,
              };
              callback(imageData.src, { alt: imageData.alt });
            };

            // Read the file as a data URL
            reader.readAsDataURL(file);
          };

          // Trigger the file input click
          input.click();
        },
      }}
      onEditorChange={handleChange}
    />
  );
};

export default TextEditor;
