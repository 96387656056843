import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';
import DropzoneInput from '../../../../dropzone/DropzoneInput';
import { connect } from 'react-redux';
import {
  uploadResume,
  uploadResumes,
  deleteResume,
  deleteResumes,
} from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import {
  DataGridPremium,
  GridToolbar,
  LicenseInfo,
} from '@mui/x-data-grid-premium';

const useStyles = makeStyles((theme) => ({
  dropzoneWrapper: {
    padding: "3em",
    [theme.breakpoints.down("md")]: {
      padding: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0.4em",
      marginTop: "2em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0.3em",
      marginTop: 0,
    },
  },
  dropzone: {
    marginTop: "0.5em",
    width: "350px",
    height: "150px",
  },
  button: {
    margin: "0.4em",
  },

  formText: {
    color: "#696974",
    fontWeight: "400",
    fontFamily: "Roboto",
    fontSize: "16px",
    letter: "0.2px",
  },
}));

const actions = {
  uploadResume,
  uploadResumes,
  deleteResume,
  deleteResumes,
  // registeredProgram,
};
const fileRows = [
  {
    id: 'ResumeId',
    name: 'Resume',

    required: true,
  },
];

const ResumeFormUpload = ({
  registeredProgramId,
  uploadResume,
  uploadResumes,
  registeredProgram,
  onReload,

  files,
  deleteResume,
  deleteResumes,
  setFiles,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  console.log(files, 'files');
  console.log(registeredProgram, 'manishregisteredProgram');
  console.log(registeredProgramId, 'registeredProgramId');
  // const [files, setFiles] = useState({});

  const [disabled, setDisabled] = useState(true);
  const [fileRowsNew, setFileRowsNew] = useState(fileRows);
  useEffect(() => {
    LicenseInfo.setLicenseKey(
      'e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
    );
  }, []);
  useEffect(() => {
    onReload();
  }, [deleteResumes]);
  useEffect(() => {
    if (Object.keys(files).length == 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [files]);
  const handleFileUpload = async () => {
    setDisabled(true);
    try {
      // const formData = new FormData();
      // formData.append('file', file.ResumeId[0]);
      await uploadResume(registeredProgramId, files);
      onReload();

      setDisabled(false);
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };
  const handleDeleteResume = async (fileName) => {
    setDisabled(true);
    try {
      await deleteResume(registeredProgramId, fileName);
      onReload();

      setDisabled(false);
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };
  const memoizedColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Document Name',
        width: 160,
        sortable: false,
        headerClassName: classes.headerBackground,
        renderHeader: (params) => (
          <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
           <b> {params.field}</b>
          </Typography>
        ),
      },

      {
        field: 'upload',
        headerName: 'Upload',
        flex: 2,
        sortable: false,
        headerClassName: classes.headerBackground,
        renderHeader: (params) => (
          <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
            {params.field}
          </Typography>
        ),
        renderCell: (params) => {
          {
            console.log(params, 'lkjh');
          }
          if (
            registeredProgram?.resumeFormCompleted === true ||
            registeredProgram?.resume
          ) {
            return  <span >{(Object.keys(files).includes(params.id) ?files[params.id][0].originalFileName :  files.originalFileName )}</span>;
            //<span style={{ color: '#3DD598' }}>UPLOADED</span>;
           

          }
          if (!Object.keys(files).includes(params.id))
            return (
              <Button color="primary" component="label" size="small">
                Upload
                <input
                  type="file"
                  hidden
                  onChange={(event) => {
                    setFiles((prevFiles) => ({
                      ...prevFiles,
                      [params.id]: event.target.files,
                    }));
                    fileRowsNew.map((item) => {
                      if (item.id === params.row.id) {
                        item.upload = event?.target?.files[0]?.name;
                      }
                    });
                    setFileRowsNew(fileRowsNew);
                  }}
                />
              </Button>
            );

          // return params.value ? (
          //   <>{params.value}</>
          // ) : (
          //   <span style={{ color: '#3DD598' }}>UPLOADED</span>
          // );
        },
      },
      {
        field: 'delete',
        headerName: 'Delete',
        sortable: false,
        headerClassName: classes.headerBackground,
        renderHeader: (params) => (
          <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
            {params.field}
          </Typography>
        ),
        renderCell: (params) => {
          // console.log(params, 'params');
          console.log(params, 'params');
          console.log(files[params.id], 'manish');

          return (
            <Button
              color="secondary"
              component="label"
              size="small"
              onClick={() => {
                console.log(registeredProgram, 'lkjhgf');
                if (Object.keys(files).length == 0) {
                  // alert('first');
                  return null;
                } else if (registeredProgram?.resume?.fileName) {
                  // alert('third');
                  handleDeleteResume(
                    registeredProgram.resume.fileName ||
                      registeredProgram.resumeFormCompleted.originalFileName
                  );
                } else if (files[params?.id][0]?.fileURL) {
                  // alert('second');
                  deleteResumes(
                    registeredProgramId,
                    params.id,
                    files[params.id]
                  );
                  setFiles((prevFiles) => {
                    const newFiles = {
                      ...prevFiles,
                    };
                    delete newFiles[params.id];
                    return newFiles;
                  });
                } else {
                  // alert('fourth');
                  setFiles({});
                }
              }}
              // onClick={() => {
              //   console.log(files, 'hhhh');
              //   setFiles({});
              //   // if (file[params.id][0].fileURL)
              //   //   setFile((prevFiles) => {
              //   //     const newFiles = {
              //   //       ...prevFiles,
              //   //     };
              //   //     delete newFiles[params.id];
              //   //     return newFiles;
              //   //   });
              // }}
            >
              Delete
            </Button>
          );
          return <></>;
        },
      },
    ],
    [
      classes.headerBackground,
      registeredProgram,
      files,
      setFiles,
      fileRowsNew,
      handleDeleteResume,
      deleteResumes,
      registeredProgramId,
    ]
  );
  return (
    <>
      <DataGridPremium
        autoHeight
        hideFooter
        disableSelectionOnClick
        disableColumnMenu
        columns={memoizedColumns}
        rows={fileRowsNew}
        components={{ Toolbar: GridToolbar }}
      />

      {/* <Grid item style={{ marginLeft: '1em' }}>
        <Button
          className={classes.button}
          color={'primary'}
          variant={'contained'}
          size={'small'}
          onClick={() => handleFileUpload()}
          disabled={disabled}
        >
          Upload
        </Button>
      </Grid> */}
    </>
  );
};

export default connect(null, actions)(ResumeFormUpload);
// import React, { Fragment, useState } from 'react';
// import { makeStyles, useTheme } from '@mui/styles';
// import { Button, Grid, Typography } from '@mui/material';
// import DropzoneInput from '../../../../dropzone/DropzoneInput';
// import { connect } from 'react-redux';
// import {
//   uploadResume,
//   uploadResumes,
// } from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';

// const useStyles = makeStyles((theme) => ({
//   dropzoneWrapper: {
//     padding: '3em',
//     [theme.breakpoints.down('md')]: {
//       padding: '1em',
//     },
//     [theme.breakpoints.down('sm')]: {
//       padding: '0.4em',
//       marginTop: '2em',
//     },
//     [theme.breakpoints.down('sm')]: {
//       padding: '0.3em',
//       marginTop: 0,
//     },
//   },
//   dropzone: {
//     marginTop: '0.5em',
//     width: '350px',
//     height: '150px',
//   },
//   button: {
//     margin: '0.4em',
//   },

//   formText: {
//     color: '#696974',
//     fontWeight: '400',
//     fontFamily: 'Roboto',
//     fontSize: '16px',
//     letter: '0.2px',
//   },
// }));

// const actions = {
//   uploadResume,
//   uploadResumes,
// };

// const ResumeFormUpload = ({
//   registeredProgramId,
//   uploadResume,
//   uploadResumes,
// }) => {
//   const classes = useStyles();
//   const theme = useTheme();

//   const [file, setFile] = useState(null);
//   const [disabled, setDisabled] = useState(false);

//   const handleFileUpload = async () => {
//     setDisabled(true);
//     console.log(registeredProgramId, file, 'ytre');
//     try {
//       await uploadResumes(registeredProgramId, file);
//       // await uploadResumes(registeredProgramId, file);
//       setFile(null);
//       setDisabled(false);
//     } catch (error) {
//       console.log(error);
//       setDisabled(false);
//     }
//   };

//   return (
//     <Grid item style={{ marginTop: '1.5em', marginBottom: '1.5em' }}>
//       <Grid item container direction={'column'}>
//         {!file && (
//           <Fragment>
//             <Grid item>
//               <Typography className={classes.formText}>
//                 Drop or Add File Here - files must be .jpeg, png, or pdf
//               </Typography>
//             </Grid>
//             <Grid item>
//               <Grid item container>
//                 <Grid item className={classes.dropzone}>
//                   <DropzoneInput setFiles={setFile} />
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Fragment>
//         )}

//         {file && (
//           <Grid item>
//             <Grid item container alignItems={'center'}>
//               <Grid item>
//                 <Typography className={classes.formText}>
//                   {file[0].name}
//                 </Typography>
//               </Grid>
//               <Grid item style={{ marginLeft: '1em' }}>
//                 <Button
//                   className={classes.button}
//                   color={'primary'}
//                   variant={'contained'}
//                   size={'small'}
//                   onClick={() => handleFileUpload()}
//                   disabled={disabled}
//                 >
//                   Upload
//                 </Button>
//               </Grid>
//               <Grid item>
//                 <Button
//                   className={classes.button}
//                   style={{
//                     backgroundColor: theme.palette.error.main,
//                     color: 'white',
//                   }}
//                   variant={'contained'}
//                   size={'small'}
//                   onClick={() => setFile(null)}
//                 >
//                   Delete
//                 </Button>
//               </Grid>
//             </Grid>
//           </Grid>
//         )}
//       </Grid>
//     </Grid>
//   );
// };

// export default connect(null, actions)(ResumeFormUpload);
