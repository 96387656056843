import React from "react";
import { useTheme } from "@mui/styles";
import { Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Sidebar from "../../../../ui/sidebar/Sidebar";
import Typography from "@mui/material/Typography";

import { useHistory } from "react-router-dom";

import Button from "@mui/material/Button";
import AdminNewUserForm from "../../../../ui/admin/settings/AdminNewUserForm";

// const actions = {
//   adminCreateApplicant,
// };

const AdminNewUser = ({
  adminCreateApplicant,
  loading,
  handleSubmit,
  // error,
  submitting,
}) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  // const [error, setError] = useState(null);
  //
  const history = useHistory();

  return (
    <Grid container>
      <Grid item md={2}>
        <Sidebar />
      </Grid>

      <Grid item md={10} style={{ padding: "2em" }}>
        <Grid item container direction={"column"}>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant={"h4"}>New User</Typography>
            <Button
              style={{ marginLeft: "0.5em" }}
              variant="contained"
              color="primary"
              onClick={() => history.push("/admin/settings/users")}
            >
              Back To Users
            </Button>
          </Grid>

          <Grid item>
            <AdminNewUserForm />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminNewUser;
