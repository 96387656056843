import React from "react";
import { connect } from "react-redux";
import LoginDialog from "./auth/LoginDialog";
import AssignReviewerDialog from "./admin/AssignReviewerDialog";
import UnassignReviewerDialog from "./admin/UnassignReviewerDialog";
import RegisterDialog from "./auth/RegisterDialog";



const dialogLookup = {
  LoginDialog,
  RegisterDialog,
  AssignReviewerDialog,
  UnassignReviewerDialog,
};

const mapStateToProps = (state) => ({
  currentDialog: state.dialog,
});

const DialogManager = ({ currentDialog }) => {
  let renderedDialog;

  if (currentDialog) {
    const { dialogType, dialogProps } = currentDialog;
    const DialogComponent = dialogLookup[dialogType];

    renderedDialog = <DialogComponent {...dialogProps} />;
  }

  return <span>{renderedDialog}</span>;
};

export default connect(mapStateToProps)(DialogManager);
