import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import asyncReducer from '../asyncReducers/asyncReducers';

import dialogReducer from '../dialogReducer/dialogReducer';
import adminProgramReducers from '../adminReducers/programReducers/adminProgramReducer';
import adminDashboardReducer from '../adminReducers/dashboardReducers/adminDashboardReducer';
import adminSettingsReducer from '../adminReducers/settingsReducers/adminSettingsReducer';

import reviewerDashboardReducer from '../reviewerReducer/dashboardReducers/reviewerDashboardReducer';

import applicantProgramReducers from '../applicantReducers/programReducers/applicantProgramReducers';
import reviewReducer from '../reviewReducer/reviewReducer';
import applicantDashboardReducer from '../applicantReducers/dashboardReducer/applicantDashboardReducer';
import adminInstitutionReducer from '../adminReducers/institutionReducer/adminInstitutionReducer';
import adminCategoryReducer from '../adminReducers/categoryReducer/adminCategoryReducer';
import transactionsReducer from '../adminReducers/transactionsReducer/transactionsReducer';

import adminAffiliateReducer from '../adminReducers/affiliatesReducer/adminAffiliateReducer';
import adminApplicantReducer from '../adminReducers/applicantReducers/adminApplicantReducer';
import recommendersReducer from '../recommendersReducer/recommendersReducer';

import applicationSettingsReducer from '../applicationSettingsReducer/applicationSettingsReducer';
import archivedReducer from '../adminReducers/archivedReducers/archivedReducer';
import logsReducer from '../adminReducers/logsReducers/logsReducer';
import additionalDocumentReducers from '../additionalDocumentReducers/additionalDocumentReducers';
import registeredInfoReducers from '../adminReducers/registeredInfoReducers/registeredInfoReducers';

const rootReducer = combineReducers({
  // ASYNC
  loading: asyncReducer,

  // FIREBASE/FIRESTORE
  firebase: firebaseReducer,
  firestore: firestoreReducer,

  // REDUX FORM REDUCER
  form: formReducer,

  // APPLICATION SETTINGS REDUCER
  applicationSettings: applicationSettingsReducer,

  // DIALOG REDUCER
  dialog: dialogReducer,

  // ADMIN REDUCERS
  adminPrograms: adminProgramReducers,
  adminDashboard: adminDashboardReducer,
  adminSettings: adminSettingsReducer,
  adminInstitutions: adminInstitutionReducer,
  registeredInfo: registeredInfoReducers,
  adminCategories: adminCategoryReducer,
  adminAffiliates: adminAffiliateReducer,
  adminApplicants: adminApplicantReducer,
  adminTransactions: transactionsReducer,
  adminArchived: archivedReducer,
  adminLogs: logsReducer,

  // REVIEWER REDUCERS
  reviewerDashboard: reviewerDashboardReducer,

  myValue: additionalDocumentReducers,
  // applicant REDUCERS
  applicantPrograms: applicantProgramReducers,
  applicantDashboard: applicantDashboardReducer,

  // REVIEW REDUCER
  review: reviewReducer,

  // RECOMMENDER
  recommender: recommendersReducer,
});

export default rootReducer;
