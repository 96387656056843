import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const useStyles = makeStyles((theme) => ({
  stageItem: {
    marginTop: '20px',
    marginBottom: '20px',
    padding: '11px',
    border: '1px solid #D5D5DC',
    borderRight: '3px solid #0062FF',
  },
  stageItemContinue: {
    marginTop: '20px',
    marginBottom: '20px',
    padding: '11px',
    border: '1px solid #D5D5DC',
    borderRight: '3px solid #FF9900',
    // borderRight:'3px solid #3DD598'
  },
  stageItemPending: {
    marginTop: '20px',
    marginBottom: '20px',
    padding: '11px',
    border: '1px solid #D5D5DC',
    // borderRight: '3px solid #FF9900',
    borderRight: '3px solid #D9512C',

    // borderRight:'3px solid #3DD598'
  },
  stageItemComplete: {
    marginTop: '20px',
    marginBottom: '20px',
    padding: '11px',
    border: '1px solid #FAFAFB',
    backgroundColor: '#FAFAFB',
    borderRight: '3px solid #3DD598',
  },
  stageName: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.1px',
    color: '#696974',
    marginLeft: 12,
  },
  completeText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.1px',
    color: '#3DD598',
    cursor: 'pointer',
  },
  continueText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.1px',
    color: '#FF9900',
    cursor: 'pointer',
  },
  startText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.1px',
    color: '#0062FF',
    cursor: 'pointer',
  },
  pendingText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.1px',
    color: '#D9512C',
    cursor: 'pointer',
    // borderRight: '3px solid #D9512C',
  },
}));

const programsAllThings = [
  'scholarshipForm',
  'taxForm',
  'personalInformationForm',
  'familyInformationForm',
  'academicInformationForm',
  'recommendationsRequired',
  'extracurricularForm',
  'covidProtocolForm',
  'resumeForm',
  'transcriptForm',
  'shortResponsesForm',
  'additionalDocumentsForm',
];

const InitialStages = ({
  program,
  registeredProgram,
  editApplication,
  applicationStages,
  applicationStagesLeft,
  applicationStagesRight,
  applicationConfirmation,
  handleSetForm,
  disabled,
}) => {
  const classes = useStyles();
  /// console.log(disabled, 'disabled');
  console.log(registeredProgram, 'registeredProgram');
  console.log(applicationStages, 'applicationStages');
  /// console.log(applicationConfirmation, 'applicationConfirmation');
  const [showConfirmationPart, setShowConfirmationPart] = useState(false);
  // this function check whether all  key of object  has value or not
  function allKeysHaveValues(obj) {
    for (const key in obj) {
      // i delete there key bcoz these are required field when we chose other option then this filled true
      delete obj.covidInjection1;
      delete obj.permanentResidentNumber;
      delete obj.pleaseSpecify;

      ///  console.log(obj, 'objects');
      if (obj.hasOwnProperty(key) && !obj[key] && obj[key] !== 0) {
        return false;
      }
    }
    return true;
  }

  const getConfirmationCompleted = () => {
    // debugger;
    const programsAllThings = [
      'scholarshipForm',
      'taxForm',
      'personalInformationForm',
      'familyInformationForm',
      'academicInformationForm',
      // 'recommendationsRequired',
      'extracurricularForm',
      'resumeForm',
      'transcriptForm',
      'shortResponsesForm',
      'covidProtocolForm',
      'applicationFeeForm',
      'applicationInterests',
      'additionalDocumentsForm',
    ];

    const programAllThingsValue = {
      scholarshipForm: 'scholarshipFormCompleted',

      taxForm: 'taxFormsUploaded',
      personalInformationForm: 'personalInformationFormCompleted',
      familyInformationForm: 'familyInformationFormCompleted',
      academicInformationForm: 'academicInformationFormCompleted',
      // recommendationsRequired: 'recommendersFormCompleted',
      extracurricularForm: 'extracurricularFormCompleted',
      resumeForm: 'resumeFormCompleted',
      transcriptForm: 'transcriptFormCompleted',
      shortResponsesForm: 'shortResponsesFormCompleted',
      covidProtocolForm: 'covidProtocolFormCompleted',
      applicationFee: 'applicationFeePaid',
      applicationInterests: 'applicationCommentsSet',
      additionalDocumentsForm: 'additionalDocumentsFormCompleted',
    };

    let dataToCheck = [];
    console.log('program :>> ', program);

    let a = programsAllThings?.map((item) => {
      if (program[item]) {
        let c = dataToCheck?.push(programAllThingsValue[item]);
      }
    });
    var dateObject = new Date();
    // Extract year, month, and day components
    var year = dateObject.getFullYear();
    var month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
    var day = dateObject.getDate();
    // Format the date components in the "MM/DD/YYYY" format
    var formattedDate =
      (month < 10 ? '0' : '') +
      month +
      '/' +
      (day < 10 ? '0' : '') +
      day +
      '/' +
      year;
    /// console.log(formattedDate, 'formattedDate');
    let count = 0;

    let b = dataToCheck?.map((item) => {
      if (registeredProgram[item]) {
        console.log(registeredProgram[item], 'mannn');
        count += 1;
      }
    });

    /// console.log('count', count);
    /// console.log('dataToCheck', dataToCheck);
    /// console.log(program?.programEndDateReadable > formattedDate, 'compare');
    if (
      program?.additionalDocumentsFormRequired === true &&
      program?.recommendersForm &&
      registeredProgram?.additionalDocuments?.length > 0 &&
      count == dataToCheck?.length &&
      (registeredProgram.counselorRecommenderFormCompleted ||
        registeredProgram.personalRecommenderFormCompleted ||
        registeredProgram.academicRecommenderFormCompleted ||
        registeredProgram.academicRecommenderFormSent ||
        registeredProgram.personalRecommenderFormSent ||
        registeredProgram.counselorRecommenderFormSent)
    ) {
      setShowConfirmationPart(true);
    }

    if (
      program?.additionalDocumentsFormRequired == false &&
      program?.recommendersForm &&
      count == dataToCheck?.length &&
      (registeredProgram.counselorRecommenderFormCompleted ||
        registeredProgram.personalRecommenderFormCompleted ||
        registeredProgram.academicRecommenderFormCompleted ||
        registeredProgram.academicRecommenderFormSent ||
        registeredProgram.personalRecommenderFormSent ||
        registeredProgram.counselorRecommenderFormSent)
      // &&
      // program?.programEndDateReadable > formattedDate
    ) {
      setShowConfirmationPart(true);
    }
    if (
      program?.additionalDocumentsFormRequired === true &&
      registeredProgram?.additionalDocuments?.length > 0 &&
      count === dataToCheck?.length
    ) {
      setShowConfirmationPart(true);
    }
    if (
      program?.additionalDocumentsFormRequired == false &&
      count === dataToCheck?.length &&
      !program?.recommendersForm
    ) {
      setShowConfirmationPart(true);
    }

    // if (count === dataToCheck?.length  ) {
    //   setShowConfirmationPart(true);
    // } else {
    //   setShowConfirmationPart(false);
    // }
  };
  const [every, setEvery] = useState(false);
  useEffect(() => {
    getConfirmationCompleted();
  }, [program, registeredProgram]);
  // const isBelowThreshold = (currentValue) => currentValue < 40;
  // useEffect(() => {

  //   const arr = applicationStages.filter(
  //     (i)=>i.show !== true
  //   ).every(
  //     ()
  //   )
  //   applicationStages.map((stage) => {
  //     if (!stage?.show) {
  //       return <></>;
  //     } else {
  //       const value = stage?.every(stage.databaseName !== undefined);
  //       if (value == true) {
  //         setEvery(true);
  //       }
  //     }
  //   });
  // }, [applicationStages]);
  // console.log(every, 'every');

  return (
    <Grid item>
      {!editApplication &&
        applicationStages.map((stage) => {
          if (
            !stage?.show
            // (!(
            //   program.recommendationsRequired &&
            //   program.recommendationsRequired == 0
            // ) &&
            //   stage.formName === 'recommendersForm')
          ) {
            return <></>;
          }
          return (
            <Grid
              container
              alignItems={'center'}
              key={stage.id}
              className={
                stage.formName == 'shortResponsesForm'
                  ? stage?.fieldData != undefined &&
                    Object.keys(stage?.fieldData)?.length ==
                      program?.additionalQuestions?.filter(
                        (item) => !item.hasOwnProperty('id')
                      ).length
                    ? classes.stageItemComplete
                    : stage.databaseName
                    ? classes.stageItemComplete
                    : stage.fieldData
                    ? classes.stageItemContinue
                    : classes.stageItem
                  : stage.formName == 'recommendersForm'
                  ? registeredProgram.counselorRecommenderFormCompleted ||
                    registeredProgram.personalRecommenderFormCompleted ||
                    registeredProgram.academicRecommenderFormCompleted
                    ? classes.stageItemComplete
                    : registeredProgram.academicRecommenderFormSent ||
                      registeredProgram.personalRecommenderFormSent ||
                      registeredProgram.counselorRecommenderFormSent
                    ? classes.stageItemPending
                    : classes.stageItem
                  : stage.databaseName
                  ? classes.stageItemComplete
                  : (allKeysHaveValues(stage.fieldData) &&
                      stage.fieldData !== undefined) ||
                    stage?.fieldData?.allRequiredDocumentFilled
                  ? classes?.stageItemComplete
                  : stage.fieldData == undefined
                  ? classes.stageItem
                  : classes.stageItemContinue
              }
            >
              <Grid container item md={1}>
                {stage.databaseName ? (
                  <CheckIcon
                    style={{ paddingLeft: '25px', color: '#D5D5DC' }}
                    fontSize={'small'}
                  />
                ) : (
                  <CheckBoxOutlineBlankIcon
                    style={{ paddingLeft: '25px', color: '#D5D5DC' }}
                    fontSize={'small'}
                  />
                )}
              </Grid>
              <Grid item md={9}>
                <Typography className={classes.stageName}>
                  {stage.applicationStage}
                </Typography>
              </Grid>

              {/*CONFIRMATION FORM COMPLETED*/}
              {registeredProgram.confirmationFormCompleted && (
                <Grid item md={2} style={{ textAlign: 'center' }}>
                  {stage.databaseName ? (
                    <Typography className={classes.completeText}>
                      Completed
                    </Typography>
                  ) : (
                    <Typography className={classes.startText}>Start</Typography>
                  )}
                </Grid>
              )}

              {/*CONFIRMATION FORM NOT COMPLETE*/}
              {!registeredProgram.confirmationFormCompleted && (
                <Grid item md={2} style={{ textAlign: 'center' }}>
                  {stage.databaseName ? (
                    // stage.formName !== 'shortResponsesForm' &&
                    stage.formName !== 'recommendersForm' ? (
                      <Typography
                        className={classes.completeText}
                        onClick={
                          !disabled
                            ? stage.formName === 'applicationFee'
                              ? null
                              : () => handleSetForm(stage.formName)
                            : ''
                        }
                      >
                        Completed
                      </Typography>
                    ) : (stage.formName === 'recommendersForm' &&
                        registeredProgram.counselorRecommenderFormCompleted) ||
                      registeredProgram.personalRecommenderFormCompleted ||
                      registeredProgram.academicRecommenderFormCompleted ? (
                      <Typography
                        className={classes.completeText}
                        onClick={
                          !disabled
                            ? stage.formName === 'applicationFee' ||
                              (stage.formName === 'recommendersForm' &&
                                program?.personalInformationForm &&
                                !registeredProgram.personalInformationFormCompleted)
                              ? null
                              : () => handleSetForm(stage.formName)
                            : ''
                        }
                      >
                        Completed
                      </Typography>
                    ) : registeredProgram.academicRecommenderFormSent ||
                      registeredProgram.personalRecommenderFormSent ||
                      registeredProgram.counselorRecommenderFormSent ? (
                      <Typography
                        className={classes.pendingText}
                        onClick={
                          !disabled
                            ? stage.formName === 'applicationFee' ||
                              (stage.formName === 'recommendersForm' &&
                                !registeredProgram.personalInformationFormCompleted)
                              ? null
                              : () => handleSetForm(stage.formName)
                            : ''
                        }
                      >
                        Pending
                      </Typography>
                    ) : (
                      ''
                    )
                  ) : stage.fieldData ? (
                    <Typography
                      className={
                        stage.formName == 'shortResponsesForm'
                          ? Object.keys(stage?.fieldData)?.length ==
                            program?.additionalQuestions?.filter(
                              (item) => !item.hasOwnProperty('id')
                            ).length
                            ? classes.completeText
                            : classes.continueText
                          : stage.formName == 'recommendersForm'
                          ? registeredProgram.counselorRecommenderFormCompleted ||
                            registeredProgram.personalRecommenderFormCompleted ||
                            registeredProgram.academicRecommenderFormCompleted
                            ? classes.completeText
                            : registeredProgram.academicRecommenderFormSent ||
                              registeredProgram.personalRecommenderFormSent ||
                              registeredProgram.counselorRecommenderFormSent
                            ? classes.pendingText
                            : ''
                          : allKeysHaveValues(stage.fieldData) == true ||
                            stage?.fieldData?.allRequiredDocumentFilled
                          ? classes.completeText
                          : classes.continueText
                      }
                      // onClick={() =>
                      //   !disabled ? handleSetForm(stage.formName) : ''
                      // }
                      onClick={() => {
                        if (
                          !disabled &&
                          stage.formName == 'recommendersForm' &&
                          program?.personalInformationForm &&
                          !registeredProgram.personalInformationFormCompleted
                        ) {
                          return '';
                        } else {
                          !disabled && handleSetForm(stage.formName);
                        }
                      }}
                      style={{ cursor: disabled && 'default' }}
                    >
                      {stage.formName == 'shortResponsesForm'
                        ? Object.keys(stage?.fieldData)?.length ==
                          program?.additionalQuestions?.filter(
                            (item) => !item.hasOwnProperty('id')
                          ).length
                          ? 'Completed'
                          : 'Continue'
                        : stage.formName == 'recommendersForm'
                        ? registeredProgram.counselorRecommenderFormCompleted ||
                          registeredProgram.personalRecommenderFormCompleted ||
                          registeredProgram.academicRecommenderFormCompleted
                          ? 'Completed'
                          : registeredProgram.academicRecommenderFormSent ||
                            registeredProgram.personalRecommenderFormSent ||
                            registeredProgram.counselorRecommenderFormSent
                          ? 'Pending'
                          : ''
                        : allKeysHaveValues(stage.fieldData) == true ||
                          stage?.fieldData?.allRequiredDocumentFilled
                        ? 'Completed'
                        : 'Continue'}
                    </Typography>
                  ) : (
                    <Typography
                      className={classes.startText}
                      onClick={() => {
                        if (
                          !disabled &&
                          stage.formName == 'recommendersForm' &&
                          program?.personalInformationForm &&
                          !registeredProgram.personalInformationFormCompleted
                        ) {
                          return '';
                        } else {
                          !disabled && handleSetForm(stage.formName);
                        }
                      }}
                      // !disabled && handleSetForm(stage.formName)}
                      style={{ cursor: disabled && 'default' }}
                    >
                      {stage.formName === 'recommendersForm' &&
                      program?.personalInformationForm
                        ? registeredProgram?.personalInformationFormCompleted
                          ? 'Start'
                          : ''
                        : 'Start'}
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          );
        })}

      {
        /// console.log('applicationConfirmation', applicationConfirmation)
      }
      {/*CONFIRMATION Form*/}
      {!editApplication &&
        applicationConfirmation.map((stage) => {
          /// console.log('stage', stage);
          if (
            !stage?.show ||
            (!(
              program.recommendationsRequired &&
              program.recommendationsRequired > 0
            ) &&
              stage.formName === 'recommendersForm')
          ) {
            return <></>;
            // return <></>
          }
          return (
            <Grid
              container
              alignItems={'center'}
              key={stage.id}
              // justifyContent={'center'}
              className={
                stage.databaseName
                  ? classes.stageItemComplete
                  : stage.fieldData
                  ? classes.stageItemContinue
                  : classes.stageItem
              }
            >
              <Grid item md={1}>
                {stage.databaseName ? (
                  <CheckIcon
                    style={{ paddingLeft: '25px', color: '#D5D5DC' }}
                    fontSize={'small'}
                  />
                ) : (
                  <CheckBoxOutlineBlankIcon
                    style={{ paddingLeft: '25px', color: '#D5D5DC' }}
                    fontSize={'small'}
                  />
                )}
              </Grid>
              <Grid item md={9}>
                <Typography className={classes.stageName}>
                  {stage.applicationStage}
                </Typography>
              </Grid>

              {/*CONFIRMATION FORM COMPLETED*/}
              {registeredProgram.confirmationFormCompleted && (
                <Grid item md={2} style={{ textAlign: 'center' }}>
                  {stage.databaseName ? (
                    <Typography className={classes.completeText}>
                      Completed
                    </Typography>
                  ) : (
                    <Typography className={classes.startText}>Start</Typography>
                  )}
                </Grid>
              )}

              {showConfirmationPart && (
                <Grid item md={2} style={{ textAlign: 'center' }}>
                  {/* // stage.databaseName === undefined */}
                  {stage.databaseName == false ||
                  stage.databaseName == undefined ? (
                    <Typography
                      className={classes.startText}
                      // disabled={!disabled}
                      onClick={() => {
                        if (
                          program.additionalDocumentsForm == true &&
                          registeredProgram?.additionalDocuments?.length > 0 &&
                          !disabled
                        ) {
                          handleSetForm(stage.formName);
                        }
                        if (
                          program.additionalDocumentsForm == false &&
                          !disabled
                        ) {
                          handleSetForm(stage.formName);
                        } else {
                          !disabled && handleSetForm(stage.formName);
                        }
                      }}
                      style={{ cursor: disabled && 'default' }}
                    >
                      {/* {!disabled && 'Start'} */}
                      Start
                    </Typography>
                  ) : (
                    <Typography className={classes.completeText}>
                      Completed
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          );
        })}
    </Grid>
  );
};

export default InitialStages;
