import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import ReviewProgramItem from "./item/ReviewProgramItem";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  answerText: {
    fontWeight: 600,
  },
}));

const CovidFormReview = ({ covidDetails, program }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction={"column"}
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
    >
      <Grid item>
        <Typography className={classes.title}>Covid Protocol</Typography>
      </Grid>
      <ReviewProgramItem
        question={"Have you received a Covid-19 vaccination or Booster shot?"}
        value={covidDetails?.covidVaccine}
      />
      <ReviewProgramItem
        question={"1st Dose Date:"}
        value={covidDetails?.covidInjection1}
      />
      <ReviewProgramItem
        question={"2nd Dose Date (Optional):"}
        value={covidDetails?.covidInjection2}
      />
      <ReviewProgramItem
        question={"Booster Shot Date (Optional):"}
        value={covidDetails?.boosterShot}
      />
      <ReviewProgramItem
        question={`Will you received a Covid-19 vaccination or Booster shot by ${
          program?.depositDeadline &&
          format(new Date(program?.depositDeadline), "MM/dd/yyyy")
        }?`}
        value={covidDetails?.willRecieveVaccine}
      />
    </Grid>
  );
};

export default CovidFormReview;
