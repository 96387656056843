import { createTheme } from "@mui/material";

const primary = "#0062FF";

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
    },
  },
  typography: {
    tab: {
      fontFamily: "Roboto",
    },
    h1: {
      fontFamily: "Roboto",
      fontSize: "3em",
    },
    h2: {
      fontFamily: "Roboto",
    },
    h3: {
      fontFamily: "Roboto",
    },
    h4: {
      fontFamily: "Roboto",
    },
    h5: {
      fontFamily: "Roboto",
    },
    h6: {
      fontFamily: "Roboto",
    },
    body1: {
      fontFamily: "Roboto",
    },
    body2: {
      fontFamily: "Roboto",
    },
    subtitle1: {
      fontFamily: "Roboto",
    },
    subtitle2: {
      fontFamily: "Roboto",
    },
  },

  overrides: {
    MuiInputLabel: {
      root: {
        fontFamily: "Roboto",
      },
    },
  },
});

export default theme;
