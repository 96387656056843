import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button
} from "@mui/material";
import Sidebar from "../../../ui/sidebar/Sidebar";
import {
  fetchAllApplicantLogs
} from "../../../store/actions/adminActions/logsActions/logsActions";
import {
  format,
  fromUnixTime
} from "date-fns";
import RefreshIcon from '@mui/icons-material/Refresh';
import { connect } from "react-redux";
import {
  DataGridPremium,
  LicenseInfo,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { escapeRegExp } from "../../../common/tableUtils";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#171725",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight: "24px",
    letter: "0.1px",
  }
}));

const actions = {
  fetchAllApplicantLogs
};

const mapStateToProps = (state) => {
  let allApplicantLogs = [];

  if (
    state.adminLogs.allApplicantLogs &&
    state.adminLogs.allApplicantLogs.length > 0
  ) {
    allApplicantLogs = state.adminLogs.allApplicantLogs;
  }

  return {
    allApplicantLogs
  };
};

const Logs = ({
  fetchAllApplicantLogs, 
  allApplicantLogs
}) => {
  const classes = useStyles();

  const [filteredRows, setFilteredRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [reload, setReload] = useState(false);

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      "e3321e89e2698df54b7d7a4644581182Tz03NjUxMSxFPTE3Mjg3NDc5MTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    );
  }, []);

  useEffect(() => {
    fetchAllApplicantLogs();
  }, [fetchAllApplicantLogs, reload]);

  useEffect(()=>{
    if (searchText) {
      setSearchText(searchText);
      const searchRegex = new RegExp(escapeRegExp(searchText), "i");
      const arr = allApplicantLogs.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field]) return searchRegex.test(row[field].toString());
          return false;
        });
      });
      const filtered = arr.map((applicant, index) => {
        return {
          ...applicant,
          serialNo: index + 1,
        };
      });
      setFilteredRows(filtered);
    }else{
      const filtered = allApplicantLogs.map((applicant, index) => {
        return {
          ...applicant,
          serialNo: index + 1,
        };
      });
      setFilteredRows(filtered);
    }
  },[allApplicantLogs])

  const memoizedColumns = useMemo(() => {
    return [
      { field: "serialNo", headerName: "Id", width: 100 },
      {
        field: "fullName",
        headerName: "Name",
        width: 160,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "eventName",
        headerName: "Event Name",
        width: 280,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "programName",
        headerName: "Program Name",
        width: 280,
        valueGetter: (param) => param.value || "N/A",
      },
      {
        field: "date",
        headerName: "Created At",
        width: 280,
        valueGetter: (param) => param.value ? format(fromUnixTime(param.value), "EEE MMM do y HH:mm:ss") : "N/A",
      }
    ];
  }, []);

  return (
    <Grid container>
      <Grid item md={2}>
        <Sidebar />
      </Grid>
      <Grid
        item
        md={10}
        style={{
          backgroundColor: "#FAFAFB",
          paddingLeft: "41px",
          paddingRight: "41px",
        }}
      >
        <Grid item container direction={"column"}>
          <Grid
            item
            container
            style={{ marginTop: "20px" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className={classes.title}>Logs</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setReload((val) => (val = !val))}
            >
              Refresh
              <RefreshIcon style={{ float: "right", cursor: "pointer" }} />
            </Button>
          </Grid>
          <Grid item style={{ marginTop: "2em" }}>
            <Box height={684} width="100%">
              <DataGridPremium
                getRowId={(row) => row.id}
                columns={memoizedColumns}
                rows={filteredRows}
                disableSelectionOnClick
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50]}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    value: searchText,
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(Logs);
