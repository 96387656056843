import React, { useEffect } from "react";
import { useTheme } from "@mui/styles";
import { Grid } from "@mui/material";

import Sidebar from "../../../../ui/sidebar/Sidebar";
import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { fetchCategory } from "../../../../store/actions/adminActions/categoryActions/adminCategoryActions";

const actions = {
  fetchCategory,
};

const mapStateToProps = (state) => {
  let category = [];
  let programDetails = [];

  if (
    state.adminCategories.category &&
    state.adminCategories.category.length > 0
  ) {
    category = state.adminCategories.category[0];
    programDetails = state.adminCategories.category[0].programDetails;
  }

  return {
    category,
    programDetails,
  };
};

const ViewCategory = ({ fetchCategory, category, programDetails }) => {
  // const classes = useStyles();
  const theme = useTheme();
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get("id");

  useEffect(() => {
    fetchCategory(id);
  }, [fetchCategory, id]);

  return (
    <Grid container>
      <Grid item md={2}>
        <Sidebar />
      </Grid>

      <Grid item md={10} style={{ paddingLeft: "1.5em" }}>
        <Grid item container direction={"column"}>
          <Grid item>
            <Typography variant={"h4"}>
              {category.categoryName ? category.categoryName : ""}
            </Typography>
          </Grid>

          <Grid item style={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              style={{
                color: "white",
                backgroundColor: theme.palette.info.main,
              }}
              onClick={() =>
                history.push(`/admin/settings/categories/edit?id=${id}`)
              }
            >
              Edit
            </Button>
            <Button
              style={{ marginLeft: "0.5em" }}
              variant="contained"
              color="primary"
              onClick={() => history.push("/admin/settings/categories/")}
            >
              Back To Categories
            </Button>
          </Grid>

          <Grid item style={{ marginTop: "2em" }}>
            <Grid item container direction={"column"}>
              {/*LOGO*/}
              <Grid item container alignItems={"center"}>
                <Grid item md={2}>
                  <Typography
                    variant={"body1"}
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    Logo:
                  </Typography>
                </Grid>
                <Grid item md={10} style={{ paddingLeft: "1em" }}>
                  <Avatar
                    variant={"square"}
                    src={category.categoryImageURL}
                    style={{ width: "160px", height: "160px" }}
                  />
                </Grid>
              </Grid>
              {/*NAME*/}
              <Grid
                item
                container
                alignItems={"center"}
                style={{ marginTop: "1.5em" }}
              >
                <Grid item md={2}>
                  <Typography
                    variant={"body1"}
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    Category Name:
                  </Typography>
                </Grid>
                <Grid item md={10} style={{ paddingLeft: "1em" }}>
                  <Typography variant={"body1"}>
                    {category.categoryName ? category.categoryName : ""}
                  </Typography>
                </Grid>
              </Grid>

              {/*NOTES*/}
              {category.categoryNotes && (
                <Grid item container alignItems={"center"}>
                  <Grid item md={2}>
                    <Typography
                      variant={"body1"}
                      style={{ textAlign: "right", fontWeight: "bold" }}
                    >
                      Notes:
                    </Typography>
                  </Grid>
                  <Grid item md={10} style={{ paddingLeft: "1em" }}>
                    <Typography variant={"body1"}>
                      {category.categoryNotes ? category.categoryNotes : ""}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(ViewCategory);
