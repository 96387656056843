import React, { Fragment } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Field } from "redux-form";
import TextInput from "../../../../common/form/TextInput";

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
}));

const AcademicRecommenderSignNowForm = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Grid item className={classes.fieldWrapper} style={{ marginTop: "1em" }}>
        <Typography variant={"body1"}>Academic Recommender Form</Typography>
      </Grid>
      <Grid item className={classes.fieldWrapper}>
        <Field
          name={"academicRecommenderTemplateId"}
          label={"Academic Recommender Template ID"}
          component={TextInput}
          type={"text"}
          variant={"outlined"}
        />
      </Grid>
      <Grid item className={classes.fieldWrapper}>
        <Field
          name={"academicRecommenderDocumentName"}
          label={"Academic Recommender Document Name"}
          component={TextInput}
          type={"text"}
          variant={"outlined"}
        />
      </Grid>
      <Grid item className={classes.fieldWrapper}>
        <Field
          name={"academicRecommenderSubject"}
          label={"Academic Recommender Email Subject"}
          component={TextInput}
          type={"text"}
          variant={"outlined"}
        />
      </Grid>
      <Grid item className={classes.fieldWrapper}>
        <Field
          name={"academicRecommenderMessage"}
          label={"Academic Recommender Email Message"}
          component={TextInput}
          type={"text"}
          variant={"outlined"}
        />
      </Grid>
    </Fragment>
  );
};

export default AcademicRecommenderSignNowForm;
