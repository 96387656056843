import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { combineValidators, isRequired } from 'revalidate';
import { Switch, Route, useHistory, Redirect } from 'react-router-dom';

import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import TextInputForm from '../../../../forms/TextInputForm';
import CheckBoxForm from '../../../../forms/CheckBoxForm';
import DatePickerInput from '../../../../../common/form/DatePickerInput';
import { submitConfirmationForm } from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: '0.4em',
  },
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  formName: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '72px',
    letter: '0.2px',
  },
  formText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  formQuestionText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
}));

const validate = combineValidators({
  iCertifyAllInformation: isRequired({ message: 'Required' }),
  signature: isRequired({ message: 'Required' }),
  confirmationDate: isRequired({ message: 'Required' }),
});
const arr = {
  iCertifyAllInformation: '',
  signature: '',
  confirmationDate: '',
};

const actions = {
  submitConfirmationForm,
};

const ConfirmationForm = ({
  registeredProgramId,
  registeredProgram,
  program,
  submitConfirmationForm,
  handleCloseForm,
  handleSubmit,
  // error,
  submitting,
}) => {
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log('program.recommendersForm:', program.recommendersForm);
    console.log(
      'personalRecommenderFormCompleted:',
      registeredProgram.personalRecommenderFormCompleted
    );
    console.log(
      'academicRecommenderFormCompleted:',
      registeredProgram.academicRecommenderFormCompleted
    );
    console.log(
      'counselorRecommenderFormCompleted:',
      registeredProgram.counselorRecommenderFormCompleted
    );
    console.log(
      'academicRecommenderFormSent:',
      registeredProgram.academicRecommenderFormSent
    );
    console.log(
      'personalRecommenderFormSent:',
      registeredProgram.personalRecommenderFormSent
    );
    console.log(
      'academicRecommenderFormSent:',
      registeredProgram.academicRecomm
    );
    if (
      program.recommendersForm &&
      registeredProgram.personalRecommenderFormCompleted === undefined &&
      registeredProgram.academicRecommenderFormCompleted === undefined &&
      registeredProgram.counselorRecommenderFormCompleted === undefined &&
      registeredProgram.academicRecommenderFormSent === undefined &&
      registeredProgram.personalRecommenderFormSent === undefined &&
      registeredProgram.academicRecommenderFormSent === undefined
    ) {
      handleCloseForm();
    }
  }, [
    program.recommendersForm,
    registeredProgram.personalRecommenderFormCompleted,
    registeredProgram.academicRecommenderFormCompleted,
    registeredProgram.counselorRecommenderFormCompleted,
    registeredProgram.academicRecommenderFormSent,
    registeredProgram.personalRecommenderFormSent,
    registeredProgram.academicRecomm,
    handleCloseForm,
  ]);

  console.log(registeredProgram.recommendersFormCompleted, 'completed');
  const originalregisteredProgram = { ...registeredProgram };
  originalregisteredProgram.programStage = 'complete';
  console.log(originalregisteredProgram, 'original');
  const arr = [
    {
      label: 'scholarConsentForm',
      value: false,
    },
    {
      label: 'governmentIdForm',
      value: false,
    },
    {
      label: 'governmentIdForm',
      value: false,
    },
    {
      label: 'governmentIdForm',
      value: false,
    },
    {
      label: 'studentQuestionnaireForm',
      value: false,
    },
    {
      label: 'downloadProgramPacketForm',
      value: false,
    },
    {
      label: 'requiredDocumentationForm',
      value: false,
    },
  ];

  // const arrs = arr.map((i) => {
  //   if (originalregisteredProgram[i.label] === false || !originalregisteredProgram[i.label]) {
  //     originalregisteredProgram.programStage = "complete";
  //   }
  // });

  // originalregisteredProgram.programStage = "complete";
  const handleFormSubmit = async (values) => {
    try {
      const applicationCompleted = true;
      await submitConfirmationForm(
        registeredProgramId,
        applicationCompleted,
        values,
        registeredProgram,
        program
      );
      handleCloseForm();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <form autoComplete={'off'} onSubmit={handleSubmit(handleFormSubmit)}>
        {/*CONFIRMATION*/}
        <Fragment>
          <Typography className={classes.formName}>Confirmation</Typography>
          <Typography className={classes.formText}>
            It is your responsibility to monitor your application for
            completeness and ensure your entire checklist is marked complete.
            Once we have received all required application materials, the review
            and evaluation of your application will begin.
          </Typography>

          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid item container direction={'column'}>
                <Grid item>
                  <CheckBoxForm
                    fieldName={'iCertifyAllInformation'}
                    largerFont={true}
                    info={
                      'I certify that all information provided as part of this application is true and correct to the best of my knowledge, and I understand that once submitted, I can not make any changes or return to this application. '
                    }
                  />

                  {/*OFFICE HELD*/}
                  <TextInputForm
                    fieldName={'signature'}
                    question={'Signature'}
                  />
                  {/*DATE*/}
                  <Grid
                    item
                    container
                    className={classes.fieldContainer}
                    alignItems={'center'}
                  >
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Grid item container direction={'column'}>
                        <Grid item>
                          <Typography
                            variant={'body1'}
                            className={classes.questionText}
                          >
                            Date
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Grid item>
                        <Grid item container direction={'column'}>
                          <Grid item className={classes.fieldWrapper}>
                            <Field
                              name={'confirmationDate'}
                              component={DatePickerInput}
                              type={'text'}
                              variant={'outlined'}
                              showCurrentDate={true}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>

        {/*BUTTONS*/}
        <Grid item style={{ marginTop: '1.5em' }}>
          <Grid item container>
            <Grid item style={{ marginLeft: 'auto' }}>
              <Button
                variant={'outlined'}
                color={'primary'}
                style={{ backgroundColor: '#4c9b4f', color: 'white' }}
                className={classes.button}
                type={'submit'}
                disabled={submitting}
              >
                Submit Application
              </Button>

              <Button
                variant={'outlined'}
                color={'secondary'}
                className={classes.button}
                onClick={() => handleCloseForm()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default connect(
  null,
  actions
)(
  reduxForm({
    form: 'confirmationForm',
    enableReinitialize: true,
    validate,
  })(ConfirmationForm)
);
