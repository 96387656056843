import React, { Fragment, useEffect, useState } from 'react';
import Alert from '@mui/lab/Alert';
import { Box, CircularProgress } from '@mui/material';
import { Switch, Route, useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import AdminDashboard from './pages/admin/dashboard/AdminDashboard';
import About from './pages/About';
import DialogManager from './dialogs/DialogManager';
import TempRegisterPage from './TEMP/TempRegisterPage';
import Programs from './pages/Programs';
import Header from './ui/headers/Header';
import AdminPrograms from './pages/admin/programs/AdminPrograms';
import Transactions from './pages/admin/transactions/Transactions';
import AdminNewProgram from './pages/admin/programs/AdminNewProgram';
import ViewProgram from './pages/ViewProgram';
import EditProgram from './pages/EditProgram';
import ApplicantDashboard from './pages/applicant/dashboard/ApplicantDashboard';
import ReviewerDashboard from './pages/reviewer/dashboard/ReviewerDashboard';
import ReviewProgram from './pages/reviewProgram/ReviewProgram';
import AdminViewProgramDashboard from './pages/admin/program/AdminViewProgramDashboard';
import ApplicantProfile from './pages/applicant/profile/ApplicantProfile';

import AdminTemplates from './pages/admin/settings/templates/AdminTemplates';
import AdminAddTemplate from './pages/admin/settings/templates/AdminAddTemplate';
import AdminEditTemplate from './pages/admin/settings/templates/AdminEditTemplate';
import AdminInstitutions from './pages/admin/settings/institutions/AdminInstitutions';
import AdminNewInstitution from './pages/admin/settings/institutions/AdminNewInstitution';
import AdminEditInstitution from './pages/admin/settings/institutions/AdminEditInstitution';
import ViewInstitution from './pages/admin/settings/institutions/ViewInstitution';
import AdminManagePayments from './pages/admin/settings/managePayments/AdminManagePayments';
import AdminApplicants from './pages/admin/applicants/AdminApplicants';
import AdminEditProgram from './pages/admin/programs/AdminEditProgram';
import AdminViewApplicant from './pages/admin/applicants/AdminViewApplicant';
import AdminEditApplicant from './pages/admin/applicants/AdminEditApplicant';
import AdminNewApplicant from './pages/admin/applicants/AdminNewApplicant';
import RecommenderReview from './pages/recommender/RecommenderReview';
import AdminDashboardApplicants from './pages/admin/dashboard/AdminDashboardApplicants';
import AdminDashboardTotalApplicants from './pages/admin/dashboard/AdminDashboardTotalApplicants';
import AdminDashboardApplicantDetails from './pages/admin/dashboard/AdminDashboardApplicantDetails';
import AdminDashboardAcceptedApplicants from './pages/admin/dashboard/AdminDashboardAcceptedApplicants';
import AdminDashboardRevenue from './pages/admin/dashboard/AdminDashboardRevenue';
import AdminViewProgramDashboardApplicants from './pages/admin/program/AdminViewProgramDashboardApplicants';
import AdminViewProgramDashboardTotalApplicants from './pages/admin/program/AdminViewProgramDashboardTotalApplicants';
import AdminViewProgramDashboardAcceptedApplicants from './pages/admin/program/AdminViewProgramDashboardAcceptedApplicants';
import AdminViewProgramDashboardRevenue from './pages/admin/program/AdminViewProgramDashboardRevenue';
import AdminUsers from './pages/admin/settings/users/AdminUsers';
import AdminViewUser from './pages/admin/settings/users/AdminViewUser';
import AdminEditUser from './pages/admin/settings/users/AdminEditUser';
import AdminNewUser from './pages/admin/settings/users/AdminNewUser';
import AdminAffillates from './pages/admin/settings/affiliates/AdminAffillates';
import ViewAffillate from './pages/admin/settings/affiliates/ViewAffillate';
import AdminNewAffillate from './pages/admin/settings/affiliates/AdminNewAffillate';
import AdminEditAffillate from './pages/admin/settings/affiliates/AdminEditAffillate';
import ReviewerViewProgramDashboard from './pages/reviewer/program/ReviewerViewProgramDashboard';
import AdminSignNow from './pages/admin/settings/signNow/AdminSignNow';
import AdminHomePage from './pages/admin/settings/adminHomePage/AdminHomePage';
import AdminCategories from './pages/admin/settings/categories/AdminCategories';
import AdminNewCategory from './pages/admin/settings/categories/AdminNewCategory';
import ViewCategory from './pages/admin/settings/categories/ViewCategory';
import AdminEditCategory from './pages/admin/settings/categories/AdminEditCategory';
import Footer from './ui/footers/Footer';
import { fetchApplicationSettings } from './store/actions/applicationSettingsActions/applicationSettingsActions';
import AdminViewProgramDashboardDeniedApplicants from './pages/admin/program/AdminViewProgramDashboardDeniedApplicants';
import AdminViewProgramDashboardWaitlistedApplicants from './pages/admin/program/AdminViewProgramDashboardWaitlistedApplicants';
import AdminViewProgramDashboardWithdrawnApplicants from './pages/admin/program/AdminViewProgramDashboardWithdrawnApplicants';
import AdminViewProgramDashboardInProgressApplicants from './pages/admin/program/AdminViewProgramDashboardInProgressApplicants';
import AdminViewProgramDashboardSubmittedApplicants from './pages/admin/program/AdminViewProgramDashboardSubmittedApplicants';
import AdminViewProgramDashboardScholarshipApplicants from './pages/admin/program/AdminViewProgramDashboardScholarshipApplicants';
import AdminViewProgramDashboardReviewedApplicants from './pages/admin/program/AdminViewProgramDashboardReviewedApplicants';
import AdminViewProgramDashboardAwaitingPaymentApplicants from './pages/admin/program/AdminViewProgramDashboardAwaitingPaymentApplicants';
import AdminCompletePaymentApplicants from './pages/admin/program/AdminCompletePaymentApplicants';
import AdminViewProgramDashboardAdmittedApplicants from './pages/admin/program/AdminViewProgramDashboardAdmittedApplicants';
import AdminViewProgramDashboardNotNotifiedApplicants from './pages/admin/program/AdminViewProgramDashboardNotNotifiedApplicants';
import AdminViewProgramDashboardNotPlacesApplicants from './pages/admin/program/AdminViewProgramDashboardNotPlacesApplicants';
import AdminViewProgramDashboardAwaitingFinalPaymentApplicants from './pages/admin/program/AdminViewProgramDashboardAwaitingFinalPaymentApplicants';
import AdminViewProgramDashboardPaidInFullApplicants from './pages/admin/program/AdminViewProgramDashboardPaidInFullApplicants';
import EmailLogs from './pages/admin/dashboard/EmailLogs';
import AdminViewProgramDashboardScholarshipAcceptedApplicants from './pages/admin/program/AdminViewProgramDashboardScholarshipAcceptedApplicants';
import AdminViewProgramDashboardScholarshipDeclinedApplicants from './pages/admin/program/AdminViewProgramDashboardScholarshipDeclinedApplicants';
import AdminViewProgramDashboardSponsorshipGrantedApplicants from './pages/admin/program/AdminViewProgramDashboardSponsorshipGrantedApplicants';
import AdminViewProgramDashboardEarlyApplicants from './pages/admin/program/AdminViewProgramDashboardEarlyApplicants';
import { makeStyles } from '@mui/styles';
import EditEmailLogs from './pages/admin/dashboard/EditEmailLogs';
import Maintenance from './pages/Maintenance';
import MaintenanceHeader from './ui/headers/MaintenanceHeader';
import Archived from './pages/admin/archived/Archived';
import Logs from './pages/admin/logs/Logs';
import ApplicantFAQ from './pages/applicant/faq/ApplicantFAQ';
import NewsOrAnnouncementDetails from './pages/NewsOrAnnouncementDetails';
import AdminViewProgramDashboardTravelInformationApplicants from './pages/admin/program/AdminViewProgramDashboardTravelInformationApplicants';
import AdminViewProgramDashboardStudentQuestionnaireApplicants from './pages/admin/program/AdminViewProgramDashboardStudentQuestionnaireApplicants';
import ClearCache from './common/clearCache/clearCache';
import RequestedInfo from './pages/admin/requestedInfo/RequestedInfo';
import DirectoryTable from './pages/admin/dashboard/DirectoryTable';
import ConfirmationFormReview from './ui/reviewProgram/ConfirmationFormReview';
import ConfirmationFormRegistraion from './ui/programs/editProgram/forms/confirmation/ConfirmationFormRegistraion';

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  loading: state.loading.loading,
});

const mapActionToProps = {
  fetchApplicationSettings,
};

const useStyles = makeStyles((theme) => ({
  loading: {
    backdropFilter: 'blur(6px)',
    position: 'fixed',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99999,
  },
  '@global': {
    '.MuiDataGrid-root .MuiDataGrid-cell:focus, .MuiDataGrid-root .MuiDataGrid-colCell:focus':
      {
        outline: 'none',
      },
    '.MuiDataGrid-root .MuiDataGrid-colCellMoving': {
      backgroundColor: 'unset',
    },
    // '.MuiDataGrid-cell': {
    //   textTransform: 'capitalize'
    // }
    '.MuiDataGrid-root .MuiDataGrid-cell': {
      overflowX: 'auto',
      textOverflow: 'unset',
    },
    /* width */
    '.MuiDataGrid-root .MuiDataGrid-cell::-webkit-scrollbar': {
      height: 1,
    },
    /* Track */
    '.MuiDataGrid-root .MuiDataGrid-cell::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    /* Handle */
    '.MuiDataGrid-root .MuiDataGrid-cell::-webkit-scrollbar-thumb': {
      background: '#C1C1C1',
    },
    /* Handle on hover */
    '.MuiDataGrid-root .MuiDataGrid-cell::-webkit-scrollbar-thumb:hover': {
      background: '#C1C1C1',
    },
  },
}));

const App = ({ auth, profile, loading, fetchApplicationSettings }) => {
  const [authenticated, setAuthenticated] = useState(false);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      document.title = 'DEVELOPMENT - LEAD Programs Portal';
    }
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      document.title = 'LEADing for Life Program Portal';
    }

    if (auth.isLoaded === true && auth.isEmpty === false) {
      // ADMIN CHECKS
      if (
        history.location.pathname === '' ||
        history.location.pathname === '/' ||
        history.location.pathname === '/about'
      ) {
        if (profile.isAdmin || profile.isAdminViewer) {
          history.push('/admin/dashboard');
        }
        if (!profile.isAdmin && !profile.isAdminViewer) {
          if (profile.isReviewer === true) {
            history.push('/reviewer/dashboard');
          }
          if (profile.isApplicant === true) {
            var past = new Date(profile.createdAt).getTime();
            var oneMin = 1000 * 5;
            var isPast = new Date().getTime() - past < oneMin ? false : true;

            const {
              firstName,
              lastName,
              email,
              mobileNumber,
              dob,
              gender,
              ethnicBackground,
              address1,
              city,
              state,
              zipCode,
            } = profile;

            if (
              isPast &&
              firstName &&
              lastName &&
              email &&
              mobileNumber &&
              dob &&
              gender &&
              ethnicBackground &&
              address1 &&
              city &&
              state &&
              zipCode
            ) {
              history.push(`/applicant/dashboard`);
            } else {
              history.push(`/applicant/profile/view?id=${profile.uid}`);
            }
          }
        }
      }
      setAuthenticated(true);
    }
    if (auth.isLoaded === true && auth.isEmpty === true) {
      setAuthenticated(false);
      // history.push("/about");
    }
  }, [
    auth,
    profile.isAdmin,
    profile.isAdminViewer,
    profile.uid,
    profile.createdAt,
    profile.isReviewer,
    history,
  ]);

  useEffect(() => {
    if (loading) {
      document.querySelector('html').style.overflow = 'hidden';
    } else {
      document.querySelector('html').style.overflow = 'auto';
    }
  }, [loading]);

  useEffect(() => {
    if (authenticated) fetchApplicationSettings();
  }, [fetchApplicationSettings, authenticated]);

  return (
    <Fragment>
      <ClearCache />
      <DialogManager />
      {process.env.REACT_APP_ENVIRONMENT === 'development' && (
        <Alert severity="error" variant={'filled'}>
          CURRENTLY IN DEVELOPMENT MODE!!!
        </Alert>
      )}

      {authenticated ? (
        <Header />
      ) : (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            top: '0px',
            zIndex: '99',
      
          }}
        >
          <Header />
          {/* {process.env.REACT_APP_ENVIRONMENT === 'development' && (
            <Alert severity="error" variant={'filled'}>
              CURRENTLY IN DEVELOPMENT MODE!!!
            </Alert>
          )} */}
        </div>
      )}

      {/* <Header /> */}
      <div style={{position: "relative"}}>
        <Switch>
          <Route exact path={'/'}>
            <About />
          </Route>

          <Route exact path={'/about'}>
            <About />
          </Route>

          <Route exact path={'/news'}>
            <NewsOrAnnouncementDetails />
          </Route>

          <Route exact path={'/program'}>
            <Programs />
          </Route>
          <Route exact path={'/program/confirmation'}>
            <ConfirmationFormRegistraion />
          </Route>

          <Route exact path={'/program/view'}>
            <ViewProgram />
          </Route>
        </Switch>

        {authenticated && (
          <Switch>
            <Route exact path={'/recommender/review'}>
              <RecommenderReview />
            </Route>

            <Route exact path={'/program/edit'}>
              <EditProgram />
            </Route>

            <Route exact path={'/applicant/dashboard'}>
              <ApplicantDashboard />
            </Route>

            <Route exact path={'/applicant/profile/view'}>
              <ApplicantProfile />
            </Route>

            <Route exact path={'/applicant/faqs/view'}>
              <ApplicantFAQ />
            </Route>
          </Switch>
        )}

        {authenticated && (profile.isAdmin || profile.isAdminViewer) && (
          <Switch>
            <Route exact path={'/admin/dashboard'}>
              <AdminDashboard />
            </Route>
            <Route exact path={'/admin/directoryTable'}>
              <DirectoryTable />
            </Route>

            <Route exact path={'/admin/dashboard/applicants'}>
              <AdminDashboardApplicants />
            </Route>

            <Route exact path={'/admin/dashboard/totalApplicants'}>
              <AdminDashboardTotalApplicants />
            </Route>

            <Route exact path={'/admin/dashboard/applicant/:id'}>
              <AdminDashboardApplicantDetails />
            </Route>

            <Route exact path={'/admin/dashboard/totalAcceptedApplicants'}>
              <AdminDashboardAcceptedApplicants />
            </Route>

            <Route exact path={'/admin/dashboard/totalRevenue'}>
              <AdminDashboardRevenue />
            </Route>

            <Route exact path={'/admin/dashboard/emailLogs'}>
              <EmailLogs />
            </Route>

            <Route exact path={'/admin/dashboard/email/edit'}>
              <EditEmailLogs />
            </Route>

            <Route exact path={'/admin/transactions'}>
              <Transactions />
            </Route>
            <Route exact path={'/admin/requestedInfo'}>
              <RequestedInfo />
            </Route>

            <Route exact path={'/admin/archived'}>
              <Archived />
            </Route>

            <Route exact path={'/admin/logs'}>
              <Logs />
            </Route>

            <Route exact path={'/admin/program'}>
              <AdminPrograms />
            </Route>
            <Route exact path={'/admin/program/new'}>
              <AdminNewProgram />
            </Route>
            <Route exact path={'/admin/program/edit'}>
              <AdminEditProgram />
            </Route>

            <Route exact path={'/admin/program/dashboard'}>
              <AdminViewProgramDashboard />
            </Route>

            <Route exact path={'/admin/program/dashboard/applicants'}>
              <AdminViewProgramDashboardApplicants />
            </Route>

            <Route exact path={'/admin/program/dashboard/earlyApplicants'}>
              <AdminViewProgramDashboardEarlyApplicants />
            </Route>

            <Route exact path={'/admin/program/dashboard/totalApplicants'}>
              <AdminViewProgramDashboardTotalApplicants />
            </Route>

            <Route
              exact
              path={'/admin/program/dashboard/totalAcceptedApplicants'}
            >
              <AdminViewProgramDashboardAcceptedApplicants />
            </Route>

            <Route exact path={'/admin/program/dashboard/awaitingPayment'}>
              <AdminViewProgramDashboardAwaitingPaymentApplicants />
            </Route>
            <Route exact path={'/admin/program/dashboard/completedPayment'}>
              <AdminCompletePaymentApplicants />
            </Route>

            <Route exact path={'/admin/program/dashboard/admittedApplicants'}>
              <AdminViewProgramDashboardAdmittedApplicants />
            </Route>

            <Route
              exact
              path={'/admin/program/dashboard/notNotifiedApplicants'}
            >
              <AdminViewProgramDashboardNotNotifiedApplicants />
            </Route>

            <Route exact path={'/admin/program/dashboard/notPlacesApplicants'}>
              <AdminViewProgramDashboardNotPlacesApplicants />
            </Route>

            <Route exact path={'/admin/program/dashboard/awaitingFinalPayment'}>
              <AdminViewProgramDashboardAwaitingFinalPaymentApplicants />
            </Route>

            <Route exact path={'/admin/program/dashboard/paidInFull'}>
              <AdminViewProgramDashboardPaidInFullApplicants />
            </Route>

            <Route exact path={'/admin/program/dashboard/deniedApplicants'}>
              <AdminViewProgramDashboardDeniedApplicants />
            </Route>

            <Route exact path={'/admin/program/dashboard/waitlistedApplicants'}>
              <AdminViewProgramDashboardWaitlistedApplicants />
            </Route>

            <Route exact path={'/admin/program/dashboard/withdrawnApplicants'}>
              <AdminViewProgramDashboardWithdrawnApplicants />
            </Route>

            <Route exact path={'/admin/program/dashboard/inProgressApplicants'}>
              <AdminViewProgramDashboardInProgressApplicants />
            </Route>

            <Route exact path={'/admin/program/dashboard/submittedApplicants'}>
              <AdminViewProgramDashboardSubmittedApplicants />
            </Route>

            <Route
              exact
              path={'/admin/program/dashboard/scholarshipApplicants'}
            >
              <AdminViewProgramDashboardScholarshipApplicants />
            </Route>

            <Route
              exact
              path={'/admin/program/dashboard/scholarshipAcceptedApplicants'}
            >
              <AdminViewProgramDashboardScholarshipAcceptedApplicants />
            </Route>

            <Route
              exact
              path={'/admin/program/dashboard/scholarshipDeclinedApplicants'}
            >
              <AdminViewProgramDashboardScholarshipDeclinedApplicants />
            </Route>

            <Route
              exact
              path={'/admin/program/dashboard/sponsorshipGrantedApplicants'}
            >
              <AdminViewProgramDashboardSponsorshipGrantedApplicants />
            </Route>

            <Route
              exact
              path={'/admin/program/dashboard/travelInformationApplicants'}
            >
              <AdminViewProgramDashboardTravelInformationApplicants />
            </Route>

            <Route
              exact
              path={'/admin/program/dashboard/studentQuestionnaireApplicants'}
            >
              <AdminViewProgramDashboardStudentQuestionnaireApplicants />
            </Route>

            <Route exact path={'/admin/program/dashboard/reviewedApplicants'}>
              <AdminViewProgramDashboardReviewedApplicants />
            </Route>

            <Route exact path={'/admin/program/dashboard/totalRevenue'}>
              <AdminViewProgramDashboardRevenue />
            </Route>

            <Route exact path={'/admin/applicants'}>
              <AdminApplicants />
            </Route>
            <Route exact path={'/admin/applicant/view'}>
              <AdminViewApplicant />
            </Route>
            <Route exact path={'/admin/applicant/edit'}>
              <AdminEditApplicant />
            </Route>
            <Route exact path={'/admin/applicant/new'}>
              <AdminNewApplicant />
            </Route>

            <Route exact path={'/admin/reviewProgram'}>
              <ReviewProgram />
            </Route>

            <Route exact path={'/admin/settings/affiliates'}>
              <AdminAffillates />
            </Route>
            <Route exact path={'/admin/settings/affiliates/view'}>
              <ViewAffillate />
            </Route>
            <Route exact path={'/admin/settings/affiliates/new'}>
              <AdminNewAffillate />
            </Route>
            <Route exact path={'/admin/settings/affiliates/edit'}>
              <AdminEditAffillate />
            </Route>

            <Route exact path={'/admin/settings/categories'}>
              <AdminCategories />
            </Route>
            <Route exact path={'/admin/settings/categories/view'}>
              <ViewCategory />
            </Route>
            <Route exact path={'/admin/settings/categories/new'}>
              <AdminNewCategory />
            </Route>
            <Route exact path={'/admin/settings/categories/edit'}>
              <AdminEditCategory />
            </Route>

            <Route exact path={'/admin/settings/templates'}>
              <AdminTemplates />
            </Route>
            <Route exact path={'/admin/settings/templates/new'}>
              <AdminAddTemplate />
            </Route>
            <Route exact path={'/admin/settings/templates/edit'}>
              <AdminEditTemplate />
            </Route>

            <Route exact path={'/admin/settings/institutions'}>
              <AdminInstitutions />
            </Route>
            <Route exact path={'/admin/settings/institutions/view'}>
              <ViewInstitution />
            </Route>
            <Route exact path={'/admin/settings/institutions/new'}>
              <AdminNewInstitution />
            </Route>
            <Route exact path={'/admin/settings/institutions/edit'}>
              <AdminEditInstitution />
            </Route>

            <Route exact path={'/admin/settings/signNow'}>
              <AdminSignNow />
            </Route>

            <Route exact path={'/admin/settings/home'}>
              <AdminHomePage />
            </Route>

            <Route exact path={'/admin/settings/payments'}>
              <AdminManagePayments />
            </Route>

            <Route exact path={'/admin/settings/users'}>
              <AdminUsers />
            </Route>
            <Route exact path={'/admin/settings/users/view'}>
              <AdminViewUser />
            </Route>
            <Route exact path={'/admin/settings/users/new'}>
              <AdminNewUser />
            </Route>
            <Route exact path={'/admin/settings/users/edit'}>
              <AdminEditUser />
            </Route>
          </Switch>
        )}

        {authenticated && profile.isReviewer && (
          <Switch>
            <Route exact path={'/reviewer/dashboard'}>
              <ReviewerDashboard />
            </Route>

            <Route exact path={'/reviewer/program'}>
              <AdminPrograms />
            </Route>

            <Route exact path={'/reviewer/program/dashboard'}>
              <ReviewerViewProgramDashboard />
            </Route>

            <Route exact path={'/reviewer/applicants'}>
              <AdminApplicants />
            </Route>
            <Route exact path={'/reviewer/applicant/view'}>
              <AdminViewApplicant />
            </Route>

            <Route exact path={'/reviewer/reviewProgram'}>
              <ReviewProgram />
            </Route>
          </Switch>
        )}
      </div>

      <Switch>
        <Route exact path={'/register'}>
          <TempRegisterPage />
        </Route>
      </Switch>

      <Footer />
      {loading && (
        <Box className={classes.loading}>
          <CircularProgress size={60} thickness={4} />
        </Box>
      )}
    </Fragment>
  );
};

export default connect(mapStateToProps, mapActionToProps)(App);
