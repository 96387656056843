import React, { Fragment } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import ReviewProgramItem from "./item/ReviewProgramItem";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  answerText: {
    fontWeight: 600,
  },
}));

const ExtracurricularInformationFormReview = ({
  extracurricularInformation,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction={"column"}
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
    >
      <Grid item>
        <Typography className={classes.title}>
          Extracurricular, Community Activities and Employment Information
        </Typography>
      </Grid>
      <Grid item style={{ marginTop: "0.5rem" }}>
        <Typography variant={"body1"}>
          Leadership Role: List any offices held and/or membership(s) since the
          beginning of 9th grade.
        </Typography>
      </Grid>

      <ReviewProgramItem
        question={"Title/Organization(s) & Program(s):"}
        value={extracurricularInformation?.leadershipRole}
        flexColumn
      />
      <ReviewProgramItem
        question={"Responsibility"}
        value={extracurricularInformation?.responsibility}
        flexColumn
      />

      {/*EXTRA ROLES HERE*/}
      {extracurricularInformation?.additionalLeadershipRoles &&
        extracurricularInformation.additionalLeadershipRoles.length > 0 &&
        extracurricularInformation.additionalLeadershipRoles.map((role) => (
          <Fragment key={role.leadershipRole}>
            <ReviewProgramItem
              question={"Title/Organization(s) & Program(s):"}
              value={role.leadershipRole}
              flexColumn
            />
            <ReviewProgramItem
              question={"Responsibility"}
              value={role.responsibility}
              flexColumn
            />
          </Fragment>
        ))}

      <Grid item style={{ marginTop: "1rem" }}>
        <Typography className={classes.title}>
          List school and community activities most important to you since the
          beginning of 9th grade:
        </Typography>
      </Grid>
      <ReviewProgramItem
        question={"Activity"}
        value={extracurricularInformation?.activity}
      />
      <ReviewProgramItem
        question={"Involvement"}
        value={extracurricularInformation?.involvement}
        flexColumn
      />
      {/*EXTRA ACTIVITIES HERE*/}
      {extracurricularInformation?.additionalCommunityActivities &&
        extracurricularInformation.additionalCommunityActivities.length > 0 &&
        extracurricularInformation.additionalCommunityActivities.map(
          (activity) => (
            <Fragment key={activity.activity}>
              <ReviewProgramItem
                question={"Activity"}
                value={activity.activity}
              />
              <ReviewProgramItem
                question={"Involvement"}
                value={activity.involvement}
              />
            </Fragment>
          )
        )}

      <ReviewProgramItem
        question={
          "Have you taken any of the standardized tests (PSAT, SAT or ACT)?"
        }
        value={extracurricularInformation?.standardizedTests}
      />
      {extracurricularInformation?.standardizedTests === "Yes" && (
        <ReviewProgramItem
          question={"Score"}
          value={extracurricularInformation?.standardizedScore}
        />
      )}
      {extracurricularInformation?.standardizedTests === "No" && (
        <ReviewProgramItem
          question={"When will you take the SAT/ACT?"}
          value={extracurricularInformation?.whenWillYouTakeSAT}
        />
      )}

      <ReviewProgramItem
        question={"From who did you hear about the LEAD program?"}
        value={extracurricularInformation?.howDidYouHearAbout}
      />

      <ReviewProgramItem
        question={
          extracurricularInformation?.pleaseSpecify ? "Please Specify" : "Name"
        }
        value={
          extracurricularInformation?.pleaseSpecify
            ? extracurricularInformation?.pleaseSpecify
            : extracurricularInformation?.name
        }
      />

      <ReviewProgramItem
        question={
          "Name of any family member who participated in the LEAD Program, along with SBI and year:"
        }
        value={extracurricularInformation?.familyMemberName}
      />
    </Grid>
  );
};

export default ExtracurricularInformationFormReview;
