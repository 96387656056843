import React, { Fragment } from "react";
import { makeStyles } from "@mui/styles";
import { alpha, Grid, Typography } from "@mui/material";
import classname from "classnames";

const useStyles = makeStyles((theme) => ({
  question: {
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#696974",
    paddingBottom: "15px",
    
  },
  shortQuestions:
  {
    color: "#51272b",
  },
  answer: {
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "16px",
   // lineHeight: "16px",
    color: "#696974",
    textAlign: "center",
    paddingBottom: "15px",

  },
  yesBtn: {
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#EF9A22",
    padding: "4px 6px",
    backgroundColor: alpha("#EF9A22", 0.1),
    textAlign: "center",
    borderRadius: "5px",
  },
  noBtn: {
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#0079A9",
    padding: "4px 6px",
    backgroundColor: alpha("#0079A9", 0.1),
    textAlign: "center",
    borderRadius: "5px",
  },
}));

const ReviewProgramItem = ({
  question,
  value,
  value2,
  link,
  showLink,
  flexColumn,
  isObject,
  renderCell,
}) => {
  const classes = useStyles();
  return (
   
    <Grid
      item
      container
      style={{ marginTop: "1em", gap: 6 }}
      alignItems={flexColumn ? "flex-start" : "center"}
      justifyContent="space-between"
      direction={flexColumn ? "column" : "row"}> 
      {renderCell ? (
      renderCell()
    ) : (
      <Grid  
      item
      container
      style={{ marginTop: "1em", gap: 6 }}
      alignItems={flexColumn ? "flex-start" : "center"}
      justifyContent="space-between"
      direction={flexColumn ? "column" : "row"}>
      <Grid item md={flexColumn ? 12 : 6} style={{ paddingRight: "1.5em" }}>
      
        <Typography className={classes.question}>{question}
        
        </Typography>
        
      </Grid>
     
      <Grid style={{ width: "100%" }} item md={flexColumn ? 12 : 5}>
        {!link &&
          (value === "Yes" || value === "No" ? (
            <Typography
              className={classname({
                [classes.yesBtn]: value === "Yes",
                [classes.noBtn]: value === "No",
              })}>
              {value}
            </Typography>
          ) : (
            <Typography
              style={{
                textAlign: flexColumn ? "unset" : "center",
              }}
              className={classes.answer}>
              {isObject
                ? Object.keys(value || {}).map((key) => `${key}, `)
                : value}
            </Typography>
          ))}
        {link && (
          <Typography variant={"body1"}>
            {showLink ? <a href={value}>Download Document</a> : "Not received"}
          </Typography>
        )}
        {value2 && (
          <Fragment>
            <br />
            <Typography variant={"body1"}>{value2}</Typography>
          </Fragment>
        )}
      </Grid>
      </Grid>
  )}
    </Grid>
  );
};

export default ReviewProgramItem;
