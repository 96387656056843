import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid,  MenuItem, } from '@mui/material';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { combineValidators, isRequired } from 'revalidate';
import TextInput from '../../../../../common/form/TextInput';
import Button from '@mui/material/Button';
import {
  sendRecommenderInvite,
  resendRecommenderInvite,
  cancelRecommenderInvite,
} from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import Typography from '@mui/material/Typography';
import { createValidator } from 'revalidate';
import { composeValidators } from 'revalidate';

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  notSigned: {
    backgroundColor: '#F1F2F4',
    color: '#80909D',
    padding: 6,
    width: 'fit-content',
    borderRadius: 6,
    '& div': {
      margin: 5,
      padding: 5,
      background: '#80909D',
      borderRadius: '50%',
    },
  },
  signed: {
    backgroundColor: '#E1F3EA',
    padding: 6,
    width: 'fit-content',
    borderRadius: 6,
    '& div': {
      margin: 5,
      padding: 5,
      background: '#3AB272',
      borderRadius: '50%',
    },
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 500,
  },
}));

const validate = combineValidators({
  recommenderTitle: isRequired({ message: 'Required' }),
  recommenderFirstName: isRequired({ message: 'Required' }),
  recommenderLastName: isRequired({ message: 'Required' }),
  recommenderEmailAddress: composeValidators(
    createValidator(
      (message) => (value) => {
        if (
          value &&
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
            value
          )
        ) {
          return message;
        }
      },
      'Invalid email format. Email must not contain spaces.'
    ),
    isRequired({ message: 'Required' })
  )(),
});

const actions = {
  sendRecommenderInvite,
  resendRecommenderInvite,
  cancelRecommenderInvite,
};

const mapStateToProps = (state, ownProps) => {
  const questionSelector = formValueSelector(
    `${ownProps.recommenderType}RecommenderForm`
  );
  const fieldValues = questionSelector(
    state,
    'id',
    'type',
    'recommenderTitle',
    'recommenderFirstName',
    'recommenderLastName',
    'recommenderEmailAddress'
  );
  let recommender = [];

  if (
    state.applicantPrograms.registeredProgram &&
    state.applicantPrograms.registeredProgram.length > 0
  ) {
    if (
      state.applicantPrograms.registeredProgram[0][
        `${ownProps.recommenderType}RecommenderDetails`
      ]
    ) {
      recommender = [
        state.applicantPrograms.registeredProgram[0][
          `${ownProps.recommenderType}RecommenderDetails`
        ],
      ];
    }
  }

  return {
    fieldValues,
    profile: state.firebase.profile,
    initialValues: recommender.length > 0 ? recommender[0] : null,
    form: `${ownProps.recommenderType}RecommenderForm`,
    formValues: {
      recommenderTitle: fieldValues.recommenderTitle,
      recommenderFirstName: fieldValues.recommenderFirstName,
      recommenderLastName: fieldValues.recommenderLastName,
      recommenderEmailAddress: fieldValues.recommenderEmailAddress,
    },
  };
};

const RecommendersInvitationForm = ({
  fieldValues,
  profile,
  registeredProgramId,
  currentFormId,
  recommenderType,
  sendRecommenderInvite,
  resendRecommenderInvite,
  cancelRecommenderInvite,
  invitationSent,
  responseReceived,
  handleSubmit,
  // error,
  submitting,
  formValues,
  initialValues,
  counselorRecommenderMail,
  personalRecommenderMail,
  academicRecommenderMail,
}) => {
  const classes = useStyles();
  const [error, setError] = useState('');
  console.log(responseReceived, invitationSent, 'responseReceived');

  console.log(currentFormId, 'currentFormId');
  useEffect(() => {
    if (fieldValues.recommenderEmailAddress) {
      if (recommenderType === 'counselor') {
        if (
          academicRecommenderMail &&
          academicRecommenderMail === fieldValues.recommenderEmailAddress
        ) {
          setError('Recommenders email should not be same.');
          return;
        } else {
          setError('');
        }
        if (
          personalRecommenderMail &&
          personalRecommenderMail === fieldValues.recommenderEmailAddress
        ) {
          setError('Recommenders email should not be same.');
          return;
        } else {
          setError('');
        }
      }
      if (recommenderType === 'personal') {
        if (
          academicRecommenderMail &&
          academicRecommenderMail === fieldValues.recommenderEmailAddress
        ) {
          setError('Recommenders email should not be same.');
          return;
        } else {
          setError('');
        }
        if (
          counselorRecommenderMail &&
          counselorRecommenderMail === fieldValues.recommenderEmailAddress
        ) {
          setError('Recommenders email should not be same.');
          return;
        } else {
          setError('');
        }
      }
      if (recommenderType === 'academic') {
        if (
          counselorRecommenderMail &&
          counselorRecommenderMail === fieldValues.recommenderEmailAddress
        ) {
          setError('Recommenders email should not be same.');
          return;
        } else {
          setError('');
        }
        if (
          personalRecommenderMail &&
          personalRecommenderMail === fieldValues.recommenderEmailAddress
        ) {
          setError('Recommenders email should not be same.');
          return;
        } else {
          setError('');
        }
      }
    }
  }, [
    fieldValues.recommenderEmailAddress,
    recommenderType,
    counselorRecommenderMail,
    personalRecommenderMail,
    academicRecommenderMail,
  ]);

  const handleFormSubmit = async (values) => {
    if (error) return;
    try {
      if (currentFormId) {
        console.log(currentFormId, 'currentFormId');
        await resendRecommenderInvite(
          registeredProgramId,
          currentFormId,
          recommenderType
        );
      } else {
        await sendRecommenderInvite(
          profile,
          registeredProgramId,
          values,
          recommenderType
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <form autoComplete={'off'} onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid
          container
          justifyContent={'center'}
          direction={'column'}
          style={{ width: '40%' }}
        >
         { /* <Grid item md={5} style={{ marginTop: '1rem' }}>
                  <Field
                    name="recommenderType"
                    component={TextInput}
                    type={'text'}
                    variant={'outlined'}
                    label="Recommendation Type"
                    select
                  >
                    <MenuItem value="academic">Academic</MenuItem>
                    <MenuItem value="personal">Personal</MenuItem>
                    <MenuItem value="counselor">Counselor</MenuItem>
              
                  </Field>
                </Grid>*/}
          <Grid item className={classes.fieldWrapper}>
            <Field
              name={'recommenderTitle'}
              label={'Title'}
              component={TextInput}
              type={'text'}
              variant={'outlined'}
              disabled={responseReceived || invitationSent}
              // onChange={e => setRecommenderTitle(e.target.value)}
            />
          </Grid>
          <Grid item className={classes.fieldWrapper}>
            <Field
              name={'recommenderFirstName'}
              label={'First Name'}
              component={TextInput}
              type={'text'}
              variant={'outlined'}
              disabled={responseReceived || invitationSent}
            />
          </Grid>
          <Grid item className={classes.fieldWrapper}>
            <Field
              name={'recommenderLastName'}
              label={'Last Name'}
              component={TextInput}
              type={'text'}
              variant={'outlined'}
              disabled={responseReceived || invitationSent}
            />
          </Grid>
          <Grid item className={classes.fieldWrapper}>
            <Field
              name={'recommenderEmailAddress'}
              label={'Email Address'}
              component={TextInput}
              type={'text'}
              variant={'outlined'}
              disabled={responseReceived || invitationSent}
            />
          </Grid>
          {error && (
            <Grid item>
              <Typography variant={'subtitle1'} className={classes.error}>
                {error}
              </Typography>
            </Grid>
          )}
          <Grid item container>
            <Button
              variant={'outlined'}
              color={'primary'}
              type={'submit'}
              // onClick={() => handleFormSubmit()}
              disabled={submitting || responseReceived}
            >
              {invitationSent ? 'Resend' : 'Send'} Invitation
            </Button>
            <Button
              variant={'outlined'}
              color={
                submitting || responseReceived || !invitationSent
                  ? 'primary'
                  : 'secondary'
              }
              onClick={() =>
                cancelRecommenderInvite(
                  registeredProgramId,
                  currentFormId,
                  recommenderType
                )
              }
              disabled={submitting || responseReceived || !invitationSent}
              style={{ marginLeft: '1rem' }}
            >
              Cancel Invite
            </Button>
          </Grid>
        </Grid>
      </form>
      {invitationSent && !responseReceived && (
        <Grid item style={{ marginTop: '1em' }}>
          <Grid className={classes.notSigned} container alignItems="center">
            <Grid item style={{width: 'fit-content !important'}} />
            <Typography color="inherit">Waiting for Recommendation</Typography>
          </Grid>
        </Grid>
      )}
      {invitationSent && responseReceived && (
        <Grid item style={{ marginTop: '1em' }}>
          <Grid className={classes.signed} container alignItems="center">
            <Grid item style={{width: 'fit-content !important'}} />
            <Typography>Signed</Typography>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    // form: 'recommenderInvitationForm',
    enableReinitialize: true,
    validate,
  })(RecommendersInvitationForm)
);
