import React from 'react';

export const OverViewIcon = () => {
    return (
        <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 10.289H4a4 4 0 0 0-4 4v2a4 4 0 0 0 4 4h1a4 4 0 0 0 4-4v-2a4 4 0 0 0-4-4Zm-3 4a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-2ZM7 3.789a3.5 3.5 0 0 0-7 0v1a3.5 3.5 0 1 0 7 0v-1Zm-5 0a1.5 1.5 0 0 1 3 0v1a1.5 1.5 0 1 1-3 0v-1ZM16 9.289h-1a4 4 0 0 0-4 4v3a4 4 0 0 0 4 4h1a4 4 0 0 0 4-4v-3a4 4 0 0 0-4-4Zm-3 4a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3ZM16.5.289h-4a3.5 3.5 0 1 0 0 7h4a3.5 3.5 0 1 0 0-7Zm-5.5 3.5a1.5 1.5 0 0 1 1.5-1.5h4a1.5 1.5 0 0 1 0 3h-4a1.5 1.5 0 0 1-1.5-1.5Z"
                fill="#92929D"
            />
        </svg>
    );
};
