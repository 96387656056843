import { createReducer } from "../../../../common/util/reducerUtil";
import {
  FETCH_AFFILIATE,
  FETCH_ALL_AFFILIATES,
} from "../../../constants/adminConstants/affiliateConstants/adminAffiliateConstants";

const initialState = {
  allAffiliates: [],
  affiliate: [],
};

const getAllAffiliates = (state, payload) => {
  return {
    ...state,
    allAffiliates: payload.affiliates,
  };
};

const getAffiliate = (state, payload) => {
  return {
    ...state,
    affiliate: payload.affiliate,
  };
};

export default createReducer(initialState, {
  [FETCH_ALL_AFFILIATES]: getAllAffiliates,
  [FETCH_AFFILIATE]: getAffiliate,
});
