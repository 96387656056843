import React, { Fragment, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Grid, MenuItem, Typography } from '@mui/material';
import { Field, FieldArray } from 'redux-form';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import TextInputFieldArray from '../../../../common/form/TextInputFieldArray';

import DropzoneInput from '../../../../ui/dropzone/DropzoneInput';
import SelectInput from '../../../../common/form/SelectInput';
import { useLocation } from 'react-router-dom';
import DeleteGarbage from '../../../../assets/dashboard/delete_garbage.png';

const useStyles = makeStyles((theme) => ({
  questionText: {
    // color: '#696974',
    fontWeight: '600',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
    color: 'black',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  formText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
  },
}));

const ParentConsentDocument = ({
  name,
  title,
  change,
  uploadDocument,
  deleteDocument,
  documents,
  institutions,
}) => {
  const classes = useStyles();

  console.log(documents, 'documents');

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get('id');

  const DocFields = ({ fields, meta: { error, submitFailed } }) => {
    return (
      <div>
        <Fragment>
          <Grid
            item
            container
            className={classes.fieldContainer}
            alignItems={'center'}
            // justifyContent={'center'}
          >
            <Grid item>
              <IconButton
                aria-label="add docs"
                onClick={() => {
                  fields.push({
                    institution: 'default',
                  });
                }}
              >
                <AddCircleIcon color={'primary'} />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant={'subtitle1'}>Add {title}</Typography>
            </Grid>
          </Grid>
          {fields && fields.length > 0 && (
            <Grid
              item
              container
              className={classes.fieldContainer}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Grid item md={3}>
                <Typography className={classes.questionText}>
                  Document title
                </Typography>
              </Grid>
              <Grid item md={3}>
                <Typography className={classes.questionText}>
                  Uploaded Document
                </Typography>
              </Grid>

              <Grid item md={2}></Grid>
            </Grid>
          )}
          {fields.map((doc, index) => {
            console.log(doc, 'doc');
            return (
              <Fragment key={index}>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item md={3}>
                    {/*FIELDS*/}
                    <Grid
                      container
                      style={{ marginTop: '1em' }}
                      alignItems={'center'}
                    >
                      <Field
                        name={`${doc}.title`}
                        component={TextInputFieldArray}
                        type={'text'}
                        variant={'outlined'}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item alignItems="flex-end" md={3}>
                    {documents && documents[index].file && (
                      <Grid item container alignItems="center">
                        <Typography className={classes.formText}>
                          {documents[index].file.originalFileName}0-
                        </Typography>
                      </Grid>
                    )}
                  </Grid>

                  {/*ACTION BUTTONS*/}
                  <Grid
                    container
                    item
                    md={2}
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <Grid item>
                      <DropzoneInput
                        accept=".pdf"
                        setFiles={async (files) => {
                          const file = await uploadDocument(files);
                          change(`${doc}.file`, file);
                        }}
                        buttonOnly
                        icon
                      />
                    </Grid>
                    <IconButton
                      aria-label="delete debt"
                      onClick={() => {
                        documents &&
                          documents[index].file &&
                          deleteDocument(documents[index].file.fileName);
                        fields.remove(index);
                      }}
                    >
                      <img src={DeleteGarbage} alt="delete_icon" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Fragment>
            );
          })}
        </Fragment>
      </div>
    );
  };
  return (
    <Grid container justifyContent={'center'} direction={'column'}>
      <FieldArray name={name} component={DocFields} change={change} />
    </Grid>
  );
};

export default ParentConsentDocument;
