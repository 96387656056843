import { createReducer } from "../../../../common/util/reducerUtil";
import {
    FETCH_ALL_ARCHIVED_APPLICANTS
} from "../../../constants/adminConstants/archivedConstants/archivedConstants";


const initialState = {
    allArchivedApplicants: [],
};

const getAllArchivedApplicants = (state, payload) => {
    return {
        ...state,
        allArchivedApplicants: payload.allArchivedApplicants,
    };
};

export default createReducer(initialState, {
    [FETCH_ALL_ARCHIVED_APPLICANTS]: getAllArchivedApplicants,
});
