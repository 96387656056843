import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  MenuItem,
  Typography,
  Select,
  Button,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { combineValidators } from 'revalidate';
import TextArea from '../../../../../common/form/TextArea';
import { updateApplicationComments } from '../../../../../store/actions/applicantActions/programActions/applicantApplicationActions';
import './commentForm.css';
const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: '0.4em',
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
    },
  },

  continueButton: {
    marginLeft: '0.4em',
    backgroundColor: '#1976d2',
    color: 'white !Important',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
      //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    },
  },
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',

    paddingBottom: '0.4em',
    fontSize: '16px',
    lineHeight: '28px',
    [theme.breakpoints.down('sm')]: {
      width: '80%!important',
    },
  },
  formName: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '72px',
    letter: '0.2px',
  },
  formText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  formQuestionText: {
    color: '#696974',
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
  },
  questionText: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Roboto',
    // fontSize: '16px',
    letter: '0.2px',
    paddingRight: '1em',
    // fontSize: '16px',
    // lineHeight: '28px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px !important',
      lineHeight: '28px !important',
      paddingLeft: '0',
      paddingRight: '50px!important',
    },
  },
}));

const validate = combineValidators({
  areaOfInterest: (value) => {
    if (!value) return 'Required';
    if (value.length === 0) {
      return 'Required';
    }
  },
});

const actions = {
  updateApplicationComments,
};

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: {
      areaOfInterest: ownProps.registeredProgram?.areaOfInterest || [],
      applicationComments:
        ownProps.registeredProgram?.applicationComments || '',
    },
  };
};

const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>;
  }
};

const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl error={touched && error} style={{ width: '100%' }}>
    <InputLabel htmlFor="color-native-simple">{label}</InputLabel>
    <Select
      // native
      {...input}
      {...custom}
      variant="outlined"
      multiple
      inputProps={{
        name: input.name,
        id: 'color-native-simple',
      }}
    >
      {children}
    </Select>
    {renderFromHelper({ touched, error })}
  </FormControl>
);

const CommentsForm = ({
  registeredProgramId,
  registeredProgram,
  updateApplicationComments,
  handleSubmit,
  change,
  error,
  submitting,
  handleCloseForm,
  handleSetForm,
  program,
}) => {
  const classes = useStyles();
  const programsAllThings = [
    'applicationInterests',
    'personalInformationForm',
    'familyInformationForm',
    'academicInformationForm',
    // 'recommendationsRequired',
    'recommendersForm',
    'extracurricularForm',
    'covidProtocolForm',
    'resumeForm',
    'transcriptForm',
    'shortResponsesForm',
    'scholarshipForm',
    'taxForm',
    'additionalDocumentsForm',
    'confirmationForm',
  ];

  const programAllThingsValue = {
    applicationInterests: 'applicationInterests',
    personalInformationForm: 'personalInformationForm',
    familyInformationForm: 'familyInformationForm',
    academicInformationForm: 'academicInformationForm',
    // recommendationsRequired: 'recommendationsRequired',
    recommendersForm: 'recommendersForm',
    extracurricularForm: 'extracurricularForm',
    covidProtocolForm: 'covidProtocolForm',
    resumeForm: 'resumeForm',
    transcriptForm: 'transcriptForm',
    shortResponsesForm: 'shortResponsesForm',
    scholarshipForm: 'scholarshipForm',
    taxForm: 'taxForm',
    additionalDocumentsForm: 'additionalDocumentsForm',
    confirmationForm: 'confirmationForm',
  };

  let dataToCheck = programsAllThings
    .filter((item) => program[item])
    .map((item) => programAllThingsValue[item]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFormSubmit = async (values) => {
    try {
      await updateApplicationComments(registeredProgramId, values);
      handleCloseForm();
      const indexOfPersonalInfoForm = dataToCheck.indexOf(
        'applicationInterests'
      );
      if (
        indexOfPersonalInfoForm !== -1 &&
        indexOfPersonalInfoForm < dataToCheck.length - 1
      ) {
        const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
        handleSetForm(nextForm);
      }
      // handleSetForm('scholarshipForm');
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <form autoComplete={'off'} onSubmit={handleSubmit(handleFormSubmit)}>
        <Fragment>
          <Typography className={classes.formName}>
            Educational Interest
          </Typography>

          <Grid container direction={'column'}>
            {program?.interests?.length > 0 ? (
              <Grid item>
                <Grid item container>
                  <Grid item md={5} xs={12}>
                    <Grid item container direction={'column'}>
                      <Grid item>
                        <Typography
                          variant={'body1'}
                          className={classes.questionText}
                        >
                          Area of interest:
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={7} xs={12}>
                    <Grid item>
                      <Grid item container direction={'column'}>
                        <Grid item className={classes.fieldWrapper}>
                          <Field
                            name={'areaOfInterest'}
                            component={renderSelectField}
                            required
                          >
                            {program.interests &&
                              program.interests.map((interest) => (
                                <MenuItem key={interest} value={interest}>
                                  {interest}
                                </MenuItem>
                              ))}
                          </Field>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ''
            )}

            <Grid item container>
              <Grid item md={5} xs={12}>
                <Grid item container direction={'column'}>
                  <Grid item>
                    <Typography
                      variant={'body1'}
                      className={classes.questionText}
                    >
                      (Optional) Use this section to let us know of any possible
                      conflicts that may arise during the program dates․
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={7} xs={12}>
                <Grid item>
                  <Grid item container direction={'column'}>
                    <Grid item className={classes.fieldWrapper}>
                      <Field
                        name={'applicationComments'}
                        component={TextArea}
                        rows={10}
                        type={'text'}
                        variant={'outlined'}
                        //  required
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item style={{ marginTop: '1.5em' }}>
            <Grid item container>
              <Grid item style={{ marginLeft: 'auto' }}>
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  className={classes.continueButton}
                  type={'submit'}
                  disabled={submitting}
                >
                  Continue{' '}
                  <span>
                    <strong> &#8594;</strong>
                  </span>
                </Button>

                {registeredProgram?.applicationCommentsSet && (
                  <Button
                    variant={'outlined'}
                    color={'secondary'}
                    className={classes.button}
                    onClick={() => handleCloseForm()}
                  >
                    Cancel
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      </form>
    </Fragment>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: 'commentsForm',
    enableReinitialize: true,
    validate,
  })(CommentsForm)
);
