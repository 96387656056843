import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  TextField,
  IconButton,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  Search as SearchIcon,
  Clear as ClearIcon,
  Delete as DeleteIcon,
  AddCircle as AddIcon,
} from "@mui/icons-material";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
  },
  toolbarContainer: {
    justifyContent: "space-between",
    minHeight: 38,
  },
}));

export const CustomToolbar = (props) => {
  const matches = useMediaQuery("(min-width:480px)");

  const classes = useStyles();
  return (
    <GridToolbarContainer className={classes.toolbarContainer}>
      {!props.hideExport ? (
        <div style={{ width: `${matches ? "" : "140px"}` }}>
          <GridToolbarExport />
        </div>
      ) : (
        <Box />
      )}
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
        display="flex"
        alignItems="center"
        // gridGap={12}
      >
        {props.showDeleteButton && (
          <>
            <IconButton
              onClick={props.onDeleteButtonClick}
              disabled={props.disableDeleteButton}
              color="secondary"
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
        {props.showFilterComponents && (
          <>
            <Typography>Filters:</Typography>
            <FormControl size="small" style={{ width: 140 }}>
              <Select
                value={props.filter}
                onChange={(event) => props.onFilterChange(event.target.value)}
              >
                <MenuItem value=""></MenuItem>
                {Object.keys(props.filters).map((filter) => (
                  <MenuItem value={filter}>{filter}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              style={{ width: `${matches ? "" : "180px"}` }}
              variant="contained"
              color="primary"
              size="small"
              onClick={props.onAddFilter}
              disabled={props.addFilterDisabled}
            >
              Add Filter
            </Button>
            <Tooltip title="Delete All Filters">
              <IconButton
                variant="contained"
                color="secondary"
                size="small"
                onClick={props.onDeleteFilters}
                disabled={props.deleteFiltersDisabled}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        <TextField
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          sx={{
            width: {
              xs: 1,
              sm: "auto",
            },
            m: (theme) => theme.spacing(1, 0.5, 1.5),
            "& .MuiSvgIcon-root": {
              mr: 0.5,
            },
            "& .MuiInput-underline:before": {
              borderBottom: 1,
              borderColor: "divider",
            },
          }}
        />
      </Box>
    </GridToolbarContainer>
  );
};
