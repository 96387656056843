import React, { Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
    fontWeight: 300,
  },
}));

const TextInputFieldArray = ({
  input: { value, onChange, ...input },
  label,
  type,
  variant,
  required,
  inputAdornment,
  disabled,
  placeholder,
  placeholderText,
  meta: { touched, error },
  ...props
}) => {
  const classes = useStyles();
  console.log(input?.name, 'valuessq');
  console.log(value, 'value');

  return (
    <Fragment>
      <TextField
        {...input}
        defaultValue={value}
        placeholder={placeholder ? placeholderText : null}
        required={required}
        label={label}
        type={type}
        variant={variant}
        fullWidth
        size={'small'}
        disabled={disabled}
        autoComplete
        InputProps={
          inputAdornment
            ? {
                startAdornment: (
                  <InputAdornment position="start">
                    {inputAdornment}
                  </InputAdornment>
                ),
              }
            : null
        }
        inputProps={{
          autocomplete: 'new-password',
          form: {
            autocomplete: 'on',
          },
        }}
        {...props}
      />
      {touched && error && (
        <Typography variant={'subtitle1'} className={classes.error}>
          {error}
        </Typography>
      )}
    </Fragment>
  );
};

export default TextInputFieldArray;
