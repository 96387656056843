import React, { useState } from 'react';
import axios from '../../../../../config/axios';
import {
  applyAffiliateCode,
  updateApplicationFeePaid,
} from '../../../../../store/actions/applicantActions/programActions/applicantProgramActions';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from '../../../../../store/actions/asyncActions/asyncActions';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../../../../common/form/TextInput';
import { Grid, Button, useTheme, Typography, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  blockTitle: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '27px',
  },
  blockText: {
    color: '#696974',
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '27px',
  },

  paymentTitle: {
    color: '#171725',
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '27px',
  },
  paymentText: {
    color: '#696974',
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '27px',
    textAlign: 'center',
  },
  container: {
    padding: '0.5rem 3rem',
    gap: '1rem',
  },
  paper: {
    padding: '3rem',
    borderRadius: 8,
  },
  button: {
    backgroundColor: '#35BA35',
    color: 'white',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#35BA35',
      color: 'white',
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    processingFee: state.applicationSettings.processingFee,
  };
};

const actions = {
  updateApplicationFeePaid,
  applyAffiliateCode,
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
};

const ApplicationFee = ({
  registeredProgramId,
  registeredProgram,
  program,
  updateApplicationFeePaid,
  applyAffiliateCode,
  profile,
  handleSubmit,
  submitting,
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
  processingFee,
  // error,
}) => {
  const [error, setError] = useState(null);
  const theme = useTheme();
  const classes = useStyles();

  const createCheckoutSession = async () => {
    try {
      if (program.applicationFee) {
        asyncActionStart();
        const response = await axios.post('stripeFunctions/payments/create', {
          items: [
            {
              price: program.applicationFee,
              name: 'Application Fee',
              description: `A nonrefundable $${Number(
                program.applicationFee
              ).toFixed(2)} application fee must be paid to access the 
            Application for our Summer Programs. Payments made by credit 
            card will incur an additional ${
              processingFee || 2.9
            }% processing fee.`,
            },
          ],
          registeredProgramId: registeredProgramId,
          type: 'applicationFee',
          applicantId: registeredProgram.applicantId,
          applicantName: registeredProgram.fullName,
          programId: program.id,
        });
        asyncActionFinish();
        window.location = response.data.url;
      }
    } catch (err) {
      asyncActionError();
      console.log(err);
    }
  };

  const handleAffiliateCode = async (values) => {
    setError('');

    let applyCode = await applyAffiliateCode(
      registeredProgramId,
      profile,
      values
    );

    console.log(applyCode);

    if (applyCode.message === 'INCORRECT CODE') {
      setError('Incorrect Code, please try again.');
    }

    if (applyCode.message === 'CODE EXPIRED') {
      setError('Code Expired, please try again.');
    }

    if (applyCode.message === 'PROMO APPLIED') {
      updateApplicationFeePaid(
        registeredProgramId,
        null,
        program,
        profile.uid,
        profile.fullName
      );
      setError(null);
    }
  };

  return (
    <Grid className={classes.container}>
      <Grid
        container
        direction="column"
        className={classes.paper}
        variant="outlined"
        component={Paper}
        alignItems="center"
      >
        <Grid item style={{ width: '100%' }}>
          <Typography className={classes.blockTitle}>
            Application Fee
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: '2em', width: '80%' }}>
          <Button
            onClick={createCheckoutSession}
            style={{ width: '100%' }}
            variant="outlined"
            color="primary"
          >
            Proceed to Checkout
          </Button>
        </Grid>
        {/*AFFILIATE CODE*/}
        <Grid item style={{ marginTop: '1em', width: '100%' }}>
          <Typography className={classes.paymentText}>Or</Typography>
          <Typography className={classes.paymentText}>
            If you have an Affiliate Code, please enter it here (ALL CAPS, no
            spaces).
          </Typography>
        </Grid>
        <form
          onSubmit={handleSubmit(handleAffiliateCode)}
          style={{ marginTop: '1em', width: '80%' }}
        >
          <Field
            name={'affiliateCode'}
            label={'Affiliate Code'}
            component={TextInput}
            type={'text'}
            variant={'outlined'}
          />
          <Grid item container direction={'column'}>
            <Grid item>
              <Button
                style={{ marginTop: '1em' }}
                variant={'contained'}
                color={'primary'}
                type={'submit'}
                fullWidth
                className={classes.button}
              >
                Use Affiliate Code
              </Button>
            </Grid>
          </Grid>
          {error && (
            <div
              style={{
                marginTop: '1em',
                color: theme.palette.error.main,
              }}
            >
              {error}
            </div>
          )}
        </form>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: 'affiliateCode' })(ApplicationFee));
