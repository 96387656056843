import { createReducer } from '../../../../common/util/reducerUtil';
import {
  CLEAR_ADMIN_PROGRAM,
  FETCH_ADMIN_PROGRAM,
  FETCH_ADMIN_PROGRAM_DASHBOARD_VALUES,
  FETCH_ADMIN_PROGRAM_DASHBOARD_VALUES_DIRECTORY,
  FETCH_ALL_PROGRAM_COMPLETED_APPLICATIONS,
  FETCH_ALL_PROGRAM_COMPLETED_APPLICATIONS_DIRECTORY,
  FETCH_ALL_PROGRAM_REVIEWED_APPLICATIONS,
  FETCH_ALL_PROGRAM_REVIEWED_APPLICATIONS_DIRECTORY,
  FETCH_ALL_PROGRAMS,
  UPDATE_PROGRAM_DESCRIPTION,
} from '../../../constants/adminConstants/programConstants/adminProgramConstants';

const initialState = {
  allPrograms: [],
  adminProgram: [],
  programDashboardValues: [],
  allProgramCompletedApplications: [],
  allProgramReviewedApplications: [],
  adminProgramDescription: {},
};

const getAllPrograms = (state, payload) => {
  return {
    ...state,
    allPrograms: payload.programs,
  };
};

const updateProgramDescription = (state, payload) => {
  return {
    ...state,
    adminProgramDescription: payload.description,
  };
};

const clearProgram = (state, payload) => {
  return {
    ...state,
    adminProgram: payload.program,
  };
};

const getProgram = (state, payload) => {
  return {
    ...state,
    adminProgram: payload.program,
  };
};

const getAllProgramDashboardValues = (state, payload) => {
  return {
    ...state,
    programDashboardValues: payload.programDashboardValues,
  };
};
const getAllProgramDashboardValuesDirectory = (state, payload) => {
  return {
    ...state,
    programDashboardValuesDirectory: payload.programDashboardValues,
  };
};

const getAllProgramCompletedApplications = (state, payload) => {
  return {
    ...state,
    allProgramCompletedApplications: payload.allProgramCompletedApplications,
  };
};
const getAllProgramCompletedApplicationsDirectory = (state, payload) => {
  return {
    ...state,
    getAllProgramCompletedApplicationsDirectory:
      payload.allProgramCompletedApplications,
  };
};
const getAllProgramReviewedApplications = (state, payload) => {
  console.log(payload, 'payload');
  return {
    ...state,
    allProgramReviewedApplications: payload.programReviewedApplications,
  };
};
const getAllProgramReviewedApplicationsDirectory = (state, payload) => {
  return {
    ...state,
    allProgramReviewedApplicationsDirectory:
      payload.programReviewedApplications,
  };
};

export default createReducer(initialState, {
  [FETCH_ALL_PROGRAMS]: getAllPrograms,
  [UPDATE_PROGRAM_DESCRIPTION]: updateProgramDescription,
  [FETCH_ADMIN_PROGRAM]: getProgram,
  [CLEAR_ADMIN_PROGRAM]: clearProgram,
  [FETCH_ADMIN_PROGRAM_DASHBOARD_VALUES]: getAllProgramDashboardValues,
  [FETCH_ALL_PROGRAM_COMPLETED_APPLICATIONS]:
    getAllProgramCompletedApplications,
  [FETCH_ALL_PROGRAM_REVIEWED_APPLICATIONS]: getAllProgramReviewedApplications,
  [FETCH_ALL_PROGRAM_REVIEWED_APPLICATIONS_DIRECTORY]:
    getAllProgramReviewedApplicationsDirectory,
  [FETCH_ALL_PROGRAM_COMPLETED_APPLICATIONS_DIRECTORY]:
    getAllProgramCompletedApplicationsDirectory,
  [FETCH_ADMIN_PROGRAM_DASHBOARD_VALUES_DIRECTORY]:
    getAllProgramDashboardValuesDirectory,
});
