import React from 'react';

export const ProgramsIcon = () => {
    return (
        <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 .242h4a2 2 0 0 1 2 2v7h4a2 2 0 0 1 2 2v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-12.5a2 2 0 0 1 2-2h4v-2.5a2 2 0 0 1 2-2Zm0 18h4v-16H8v16Zm-2-11.5H2v11.5h4v-11.5Zm8 11.5h4v-7h-4v7Z"
                fill="#92929D"
            />
        </svg>
    );
};
