import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import ReviewProgramItem from "./item/ReviewProgramItem";
import { format, parseISO } from "date-fns";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.1px",
    color: "#171725",
  },
  answerText: {
    fontWeight: 600,
  },
}));

const TravelInformationFormReview = ({ travelInformation, vaccineCard }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction={"column"}
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
    >
      <Grid item>
        <Typography className={classes.title}>
          Travel Information Form
        </Typography>
      </Grid>

      <Grid item>
        <Typography className={classes.title}>Personal Information</Typography>
      </Grid>
      <ReviewProgramItem
        question={"First Name"}
        value={travelInformation?.firstName}
      />
      <ReviewProgramItem
        question={"Last Name"}
        value={travelInformation?.lastName}
      />

      <ReviewProgramItem
        question={"Middle Initial"}
        value={travelInformation?.middleInitial}
      />
      <ReviewProgramItem
        question={"Date of Birth"}
        value={
          travelInformation?.dob
            ? format(parseISO(travelInformation?.dob), "EEE MMM do y")
            : ""
        }
      />

      <ReviewProgramItem
        question={"Mobile Number"}
        value={travelInformation?.mobileNumber}
      />
      <ReviewProgramItem question={"Email"} value={travelInformation?.email} />

      <ReviewProgramItem
        question={"Parent/Guardian Contact Name"}
        value={travelInformation?.parentGuardianEmergencyContactName1}
      />
      <ReviewProgramItem
        question={"Parent/Guardian Contact Number"}
        value={travelInformation?.parentGuardianEmergencyContactNumber1}
      />

      <ReviewProgramItem
        question={"Parent/Guardian Contact Name #2"}
        value={travelInformation?.parentGuardianEmergencyContactName2}
      />
      <ReviewProgramItem
        question={"Parent/Guardian Contact Number #2"}
        value={travelInformation?.parentGuardianEmergencyContactNumber2}
      />

      <Grid item>
        <Typography className={classes.title}>
          Travel Information (Arrival)
        </Typography>
      </Grid>

      <ReviewProgramItem
        question={"Arriving By"}
        value={travelInformation?.arrivalArrivingBy}
      />

      <ReviewProgramItem
        question={"Departure City"}
        value={travelInformation?.arrivalDepartureCity}
      />

      <ReviewProgramItem
        question={"Arrival Date"}
        value={travelInformation?.arrivalArrivalDate}
      />

      <ReviewProgramItem
        question={"Arrival City"}
        value={travelInformation?.arrivalArrivalCity}
      />

      <Grid item>
        <Typography className={classes.title}>
          Travel Information (Departure)
        </Typography>
      </Grid>

      <ReviewProgramItem
        question={"Arriving By"}
        value={travelInformation?.departureArrivingBy}
      />

      <ReviewProgramItem
        question={"Departure City"}
        value={travelInformation?.departureDepartureCity}
      />

      <ReviewProgramItem
        question={"Arrival Date"}
        value={travelInformation?.departureArrivalDate}
      />

      <ReviewProgramItem
        question={"Arrival City"}
        value={travelInformation?.departureArrivalCity}
      />

      <ReviewProgramItem
        question={"Comments"}
        value={travelInformation?.comments}
      />

      {vaccineCard && (
        <ReviewProgramItem
          question={vaccineCard.originalFileName}
          value={
            <a
              href={vaccineCard.fileURL}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Link
            </a>
          }
        />
      )}
    </Grid>
  );
};

export default TravelInformationFormReview;
