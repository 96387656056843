import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { connect } from 'react-redux';
import { reduxForm, formValues, getFormValues } from 'redux-form';
import { combineValidators, isRequired } from 'revalidate';
import TextInputForm from '../../../../../forms/TextInputForm';
import SelectInputForm from '../../../../../forms/SelectInputForm';
import TextAreaForm from '../../../../../forms/TextAreaForm';
import {
  updateStudentQuestionnaireForm,
  submitStudentQuestionnaireForm,
} from '../../../../../../store/actions/applicantActions/programActions/applicantApprovedActions';
import { deleteVaccineCard } from '../../../../../../store/actions/applicantActions/programActions/applicantApprovedActions';
import CheckBoxProgramGrades from '../../../../../forms/CheckBoxProgramGrades';
import { createValidator } from 'revalidate';
import { composeValidators } from 'revalidate';
import { makeStyles } from '@mui/styles';
import "./StudentQuestionnaireForm.css"

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: '0.4em',
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
    },
  },

  continueButton: {
    marginLeft: '0.4em',
    backgroundColor: '#1976d2',
    color: 'white !Important',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.24)',
      //  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    },
  },
  fieldContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  fieldWrapper: {
    paddingTop: '0.4em',
    paddingBottom: '0.4em',
  },
  questionText: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
    paddingRight: '1em',
  },
  questionTextArea: {
    color: '#696974',
    fontWeight: '600',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letter: '0.2px',
    paddingRight: '1em',
    marginTop: '1.5em',
  },
}));

const validate = combineValidators({
  scholarName: isRequired({ message: 'Required' }),
  nickname: isRequired({ message: 'Required' }),
  poloSize: isRequired({ message: 'Required' }),
  email: composeValidators(
    createValidator(
      (message) => (value) => {
        if (
          value &&
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
            value
          )
        ) {
          return message;
        }
      },
      'Invalid email format. Email must not contain spaces.'
    ),
    isRequired({ message: 'Required' })
  )(),
  assignedInstitute: isRequired({ message: 'Required' }),
  additionalCommentsOrAccommodations: isRequired({ message: 'Required' }),
  allergiesOrRestrictions: isRequired({ message: 'Required' }),
  careerGoal: isRequired({ message: 'Required' }),
  collegeMajorGoal: isRequired({ message: 'Required' }),
  collegesApplyingTo: isRequired({ message: 'Required' }),
  advanceMathematicsCourseTaken: isRequired({ message: 'Required' }),
  economicsCourse: isRequired({ message: 'Required' }),
  experienceInComputers: isRequired({ message: 'Required' }),
  involvementInAffinityStudentOrganisations: isRequired({
    message: 'Required',
  }),
  musicILike: isRequired({ message: 'Required' }),
  talents: isRequired({ message: 'Required' }),
  sports: isRequired({ message: 'Required' }),
  phobia: isRequired({ message: 'Required' }),
  favouriteFood: isRequired({ message: 'Required' }),
  roommateConsideration: isRequired({ message: 'Required' }),
  roleModels: isRequired({ message: 'Required' }),
  countriesBeenTo: isRequired({ message: 'Required' }),
  workPreference: isRequired({ message: 'Required' }),
  greatestStrength: isRequired({ message: 'Required' }),
  greatestWeakness: isRequired({ message: 'Required' }),
  ownBuisness: isRequired({ message: 'Required' }),
  interestForLead: isRequired({ message: 'Required' }),
});

const actions = {
  updateStudentQuestionnaireForm,
  deleteVaccineCard,
  submitStudentQuestionnaireForm,
};

const mapStateToProps = (state) => {
  let institutions = [];
  if (
    state.adminInstitutions?.allInstitutions &&
    state.adminInstitutions?.allInstitutions.length > 0
  ) {
    institutions = state.adminInstitutions.allInstitutions;
  }
  return {
    initialValues: {
      ...state.applicantPrograms.registeredProgram[0]
        ?.studentQuestionnaireFormDetails,
      assignedInstitute: state.applicantPrograms.registeredProgram[0]
        ?.studentQuestionnaireFormDetails?.assignedInstitute
        ? state.applicantPrograms.registeredProgram[0]
            ?.studentQuestionnaireFormDetails?.assignedInstitute
        : state.applicantPrograms.registeredProgram[0]?.chosenInstitution,
    },
    currentValues: getFormValues('studentQuestionnaireForm')(state),
    fieldValues: state.form?.studentQuestionnaireForm?.values,
    institutions,
  };
};
const programsAllThings = [
  'scholarConsentForm',
  'governmentIdForm',
  'paymentForm',
  'travelInformationForm',
  'studentQuestionnaireForm',
  'downloadProgramPacketForm',
  'requiredDocumentationForm',
];
const programAllThingsValue = {
  scholarConsentForm: 'scholarConsentForm',
  governmentIdForm: 'governmentIdForm',
  paymentForm: 'paymentForm',
  travelInformationForm: 'travelInformationForm',
  studentQuestionnaireForm: 'studentQuestionnaireForm',
  downloadProgramPacketForm: 'downloadProgramPacketForm',
  requiredDocumentationForm: 'requiredDocumentationForm',
};

const StudentQuestionnaireForm = ({
  fieldValues,
  registeredProgramId,
  profile,
  program,
  updateStudentQuestionnaireForm,
  submitStudentQuestionnaireForm,
  handleCloseForm,
  handleSetForm,
  handleSubmit,
  currentValues,
  // handleFormSave,
  submitting,
  institutions,
}) => {
  const [exit, setExit] = useState(false);
  console.log(exit, 'exit');
  const classes = useStyles();
  let dataToCheck = programsAllThings
    .filter((item) => program[item])
    .map((item) => programAllThingsValue[item]);
  const handleFormSubmit = async (values) => {
    try {
      if (exit) {
        await updateStudentQuestionnaireForm(registeredProgramId, values);
        handleCloseForm();
      } else {
        await submitStudentQuestionnaireForm(registeredProgramId, values);
        handleCloseForm();

        const indexOfPersonalInfoForm = dataToCheck.indexOf(
          'studentQuestionnaireForm'
        );
        const nextForm = dataToCheck[indexOfPersonalInfoForm + 1];
        const nextForms = dataToCheck[indexOfPersonalInfoForm + 2];
        console.log(nextForms, 'nextForms');
        console.log(nextForm, 'nextForm');

        if (nextForm == 'paymentForm' && program.freeProgram) {
          handleSetForm(nextForms);
        }
        if (nextForm == 'paymentForm' && !program.freeProgram) {
          handleSetForm(nextForm);
        }
        if (nextForm == 'paymentForm') {
          handleSetForm(nextForms);
        }

        if (nextForm !== 'paymentForm') {
          handleSetForm(nextForm);
        }
        // handleSetForm("governmentIdForm")
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleFormSave = async (values) => {
    console.log(values, 'valll');
    await updateStudentQuestionnaireForm(registeredProgramId, values);
    handleCloseForm();
  };
  const saveInterval = 5 * 60 * 1000; // 5 minutes in milliseconds

  useEffect(() => {
    const saveFormInterval = setInterval(() => {
      handleFormSave(currentValues);
    }, saveInterval);

    // Clear the interval when the component is unmounted
    return () => clearInterval(saveFormInterval);
  }, [currentValues]);
  useEffect(() => {
    if (exit) handleFormSubmit(fieldValues);
  }, [exit, fieldValues, handleFormSubmit]);

  return (
    <>
      <form autoComplete={'off'} onSubmit={handleSubmit(handleFormSubmit)}>
        {/*TRAVEL INFORMATION*/}
        <>
          <div style={{ marginLeft: '15px' }}>
            <Typography
              variant={'h6'}
              style={{
                fontWeight: 600,
                marginTop: '1em',
                marginBottom: '1em',
                marginLeft: '10px',
              }}
            >
              Student Questionnaire
            </Typography>
            <Typography
              variant={'body'}
              style={{ marginTop: '1em', marginBottom: '1em' }}
            >
              This online form is designed to help the Directors and RTA's at
              your assigned host institution get to know you better for the two
              weeks of your immersive learning experience.
            </Typography>
          </div>

          <Grid
            container
            style={{
              padding: '2em',
              marginTop: '1em',
            }}
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid item container direction={'column'}>
                <Grid item>
                  <TextInputForm
                    fieldName="scholarName"
                    question="Scholar's Name:"
                  />
                  <TextInputForm fieldName="nickname" question="Nickname:" />
                  <TextInputForm fieldName="email" question="Email:" />
                  <Typography
                    variant={'body'}
                    style={{ marginTop: '1em', marginBottom: '1em' }}
                  >
                    This email will be used for sending assignments and
                    correspondence with Directors and RTA's
                  </Typography>

                  <SelectInputForm
                    fieldName="assignedInstitute"
                    question="Your Assigned Host Institution:"
                    options={[
                      { value: '' },
                      ...institutions.map((institution) => ({
                        value: institution.id,
                        text: institution.institutionName,
                      })),
                    ]}
                    text
                    disabled={fieldValues?.assignedInstitute ? true : false}
                  />
                  <TextInputForm fieldName="poloSize" question="Polo Size:" />
                  <Grid
                    item
                    container
                    className={classes.fieldContainer}
                    alignItems={'center'}
                  >
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Grid item container direction={'column'}>
                        <Grid item>
                          <Typography
                            variant={'body1'}
                            className={classes.questionText}
                          >
                            MEDICAL: Voluntary Disability Information:
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Grid item>
                        <Grid item container direction={'column'}>
                          <Grid item className={classes.fieldWrapper}>
                            <CheckBoxProgramGrades
                              info="Attention Deficit Disorder"
                              fieldName="disability.attentionDeficitDisorder"
                            />
                            <CheckBoxProgramGrades
                              info="Chronic Health Disorder"
                              fieldName="disability.chronicHealthDisorder"
                            />
                            <CheckBoxProgramGrades
                              info="Hearing Impairment"
                              fieldName="disability.hearingImpairment"
                            />
                            <CheckBoxProgramGrades
                              info="Learning Disability"
                              fieldName="disability.learningDisability"
                            />
                            <CheckBoxProgramGrades
                              info="Mobility Impairment"
                              fieldName="disability.mobilityImpairment"
                            />
                            <CheckBoxProgramGrades
                              info="Psychological Disability"
                              fieldName="disability.psychologicalDisability"
                            />
                            <CheckBoxProgramGrades
                              info="Traumatic Brain Injury"
                              fieldName="disability.traumaticBrainInjury"
                            />
                            <CheckBoxProgramGrades
                              info="Visual Impairment"
                              fieldName="disability.visualImpairment"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/*COMMENTS*/}
                  <TextAreaForm
                    fieldName="additionalCommentsOrAccommodations"
                    question="Feel free to provide additional comments or accommodations you may need:"
                  />
                  <TextAreaForm
                    fieldName="allergiesOrRestrictions"
                    question="Is there anything else you would like us to know? (allergies - dietary or environmental; dietary restrictions :"
                  />
                  <TextAreaForm
                    fieldName="careerGoal"
                    question="Currently, my career goal is:"
                  />
                  <TextAreaForm
                    fieldName="collegeMajorGoal"
                    question="College major of interest:"
                  />
                  <TextAreaForm
                    fieldName="collegesApplyingTo"
                    question="Colleges I'm thinking of applying to:"
                  />
                  <TextAreaForm
                    fieldName="languages"
                    question="I have a reading and speaking knowledge of the following languages:"
                  />
                  <TextInputForm
                    fieldName="advanceMathematicsCourseTaken"
                    question="The most advanced mathematics course I have taken is:"
                  />
                  <TextAreaForm
                    fieldName="economicsCourse"
                    question="Have you had a course in economics. Please describe the course briefly:"
                  />
                  <TextAreaForm
                    fieldName="experienceInComputers"
                    question="My experience with computers: list your experience with programming, platforms and software:"
                  />
                  <TextAreaForm
                    fieldName="involvementInAffinityStudentOrganisations"
                    question="List any involvement you have with affinity student organizations:"
                  />
                  <Grid
                    item
                    container
                    className={classes.fieldContainer}
                    alignItems={'center'}
                  >
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Grid item container direction={'column'}>
                        <Grid item>
                          <Typography
                            variant={'body1'}
                            className={classes.questionText}
                          >
                            What do you enjoy doing in your spare time?
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Grid item>
                        <Grid item container direction={'column'}>
                          <Grid item className={classes.fieldWrapper}>
                            <CheckBoxProgramGrades
                              info="Play video games"
                              fieldName="doInSpareTime.playVideoGames"
                            />
                            <CheckBoxProgramGrades
                              info="Outdoor activities"
                              fieldName="doInSpareTime.outdoorActivities"
                            />
                            <CheckBoxProgramGrades
                              info="Work out"
                              fieldName="doInSpareTime.workOut"
                            />
                            <CheckBoxProgramGrades
                              info="Yoga/Meditate"
                              fieldName="doInSpareTime.yogaOrMeditate"
                            />
                            <CheckBoxProgramGrades
                              info="Bake/cook"
                              fieldName="doInSpareTime.bakeOrCook"
                            />
                            <CheckBoxProgramGrades
                              info="Watch movies"
                              fieldName="doInSpareTime.watchMovies"
                            />
                            <CheckBoxProgramGrades
                              info="Play board/card games"
                              fieldName="doInSpareTime.playBoardOrCardGames"
                            />
                            <CheckBoxProgramGrades
                              info="Read"
                              fieldName="doInSpareTime.read"
                            />
                            <CheckBoxProgramGrades
                              info="Hang out with friends"
                              fieldName="doInSpareTime.hangOutWithFriends"
                            />
                            <CheckBoxProgramGrades
                              info="Listen to music"
                              fieldName="doInSpareTime.listenToMusic"
                            />
                            <CheckBoxProgramGrades
                              info="None of the above"
                              fieldName="doInSpareTime.noneOfTheAbove"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <TextInputForm
                    fieldName="otherActivites"
                    question="Any other activities?"
                  />
                  <TextInputForm
                    fieldName="musicILike"
                    question="The music I like to listen to is:"
                  />
                  <TextAreaForm
                    fieldName="talents"
                    question="Describe any talents you have in music, dance, theatre, creative writing, the fine arts, etc.:"
                  />
                  <TextAreaForm
                    fieldName="sports"
                    question="Sports I like to play are:"
                  />
                  <TextInputForm
                    fieldName="phobia"
                    question="I have a phobia (fear) of:"
                  />
                  <TextInputForm
                    fieldName="favouriteFood"
                    question="My favorite food is:"
                  />
                  <TextAreaForm
                    fieldName="roommateConsideration"
                    question="Is there anything else you would like us to know when considering your roommate placements? (allergies - dietary or environmental; dietary restrictions such as vegan or vegetarian; unusual sleeping habits such as insomnia, sleepwalking, must sleep with music/white noise, etc.):"
                  />
                  <TextAreaForm
                    fieldName="roleModels"
                    question="My role models are:"
                  />
                  <TextAreaForm
                    fieldName="countriesBeenTo"
                    question="The countries I have been to are:"
                  />
                  <Grid
                    item
                    container
                    className={classes.fieldContainer}
                    alignItems={'center'}
                  >
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Grid item container direction={'column'}>
                        <Grid item>
                          <Typography
                            variant={'body1'}
                            className={classes.questionText}
                          >
                            During your residence, you will work in teams but
                            please check your preference:
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Grid item>
                        <Grid item container direction={'column'}>
                          <Grid item className={classes.fieldWrapper}>
                            <CheckBoxProgramGrades
                              info="I prefer to work alone"
                              fieldName="workPreference.alone"
                            />
                            <CheckBoxProgramGrades
                              info="I prefer to work in groups"
                              fieldName="workPreference.groups"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <TextAreaForm
                    fieldName="greatestStrength"
                    question="My greatest strengths are:"
                  />
                  <TextAreaForm
                    fieldName="greatestWeakness"
                    question="My greatest weaknesses are:"
                  />
                  <TextAreaForm
                    fieldName="ownBuisness"
                    question="If I could open my own business, I would:"
                  />
                  <TextAreaForm
                    fieldName="interestForLead"
                    question="Is there anything else we should know about you and your interests or needs that will help us make your time with the program successful and enjoyable? :"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>

        {/*BUTTONS*/}
        <Grid item style={{ marginTop: '1.5em' }}>
          <Grid item container>
            <Grid item  className='student-question-form-buttons'>
              <Button
                variant={'outlined'}
                color={'primary'}
                className={classes.button}
                disabled={submitting}
                onClick={() => setExit(true)}
              >
                Save for Later
              </Button>
              <Button
                variant={'outlined'}
                color={'primary'}
                className={classes.continueButton}
                type={'submit'}
                disabled={submitting}
              >
                Continue{' '}
                <span>
                  <strong> &#8594;</strong>
                </span>
              </Button>
              <Button
                variant={'outlined'}
                color={'secondary'}
                className={classes.button}
                onClick={() => handleCloseForm()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: 'studentQuestionnaireForm',
    enableReinitialize: true,
    validate,
  })(StudentQuestionnaireForm)
);
