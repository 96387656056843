import React, { useEffect } from "react";
import { useTheme } from "@mui/styles";
import { Grid } from "@mui/material";
import Sidebar from "../../../../ui/sidebar/Sidebar";
import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { fetchAffiliate } from "../../../../store/actions/adminActions/affiliateActions/adminAffiliateActions";

const actions = {
  fetchAffiliate,
};

const mapStateToProps = (state) => {
  let affiliate = [];

  if (
    state.adminAffiliates.affiliate &&
    state.adminAffiliates.affiliate.length > 0
  ) {
    affiliate = state.adminAffiliates.affiliate[0];
  }

  return {
    affiliate,
  };
};

const ViewAffillate = ({ fetchAffiliate, affiliate }) => {
  const theme = useTheme();

  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get("id");

  useEffect(() => {
    fetchAffiliate(id);
  }, [fetchAffiliate, id]);

  return (
    <Grid container>
      <Grid item md={2}>
        <Sidebar />
      </Grid>

      <Grid item md={10} style={{ padding: "2rem" }}>
        <Grid item container direction={"column"}>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant={"h4"}>
              {affiliate.affiliateName ? affiliate.affiliateName : ""}
            </Typography>
            <Grid item>
              <Button
                variant="contained"
                style={{
                  color: "white",
                  backgroundColor: theme.palette.info.main,
                }}
                onClick={() =>
                  history.push(`/admin/settings/affiliates/edit?id=${id}`)
                }
              >
                Edit
              </Button>
              <Button
                style={{ marginLeft: "0.5em" }}
                variant="contained"
                color="primary"
                onClick={() => history.push("/admin/settings/affiliates/")}
              >
                Back To Affiliates
              </Button>
            </Grid>
          </Grid>

          <Grid item style={{ marginTop: "2em" }}>
            <Grid item container direction={"column"}>
              {/*LOGO*/}
              {/*<Grid item container alignItems={"center"}>*/}
              {/*  <Grid item md={2}>*/}
              {/*    <Typography*/}
              {/*        variant={"body1"}*/}
              {/*        style={{ textAlign: "right", fontWeight: "bold" }}*/}
              {/*    >*/}
              {/*      Logo:*/}
              {/*    </Typography>*/}
              {/*  </Grid>*/}
              {/*  <Grid item md={10} style={{ paddingLeft: "1em" }}>*/}
              {/*    <Avatar variant={'square'} src={institution.institutionImageURL} style={{width: '160px', height: '160px'}}/>*/}
              {/*  </Grid>*/}
              {/*</Grid>*/}
              {/*NAME*/}
              <Grid
                item
                container
                alignItems={"center"}
                style={{ marginTop: "1.5em" }}
              >
                <Grid item md={2}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    Affiliate Name:
                  </Typography>
                </Grid>
                <Grid item md={10} style={{ paddingLeft: "1em" }}>
                  <Typography variant={"body1"}>
                    {affiliate.affiliateName ? affiliate.affiliateName : ""}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                alignItems={"center"}
                style={{ marginTop: "1.5em" }}
              >
                <Grid item md={2}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    Affiliate Code:
                  </Typography>
                </Grid>
                <Grid item md={10} style={{ paddingLeft: "1em" }}>
                  <Typography variant={"body1"}>
                    {affiliate.affiliateCode ? affiliate.affiliateCode : ""}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                container
                alignItems={"center"}
                style={{ marginTop: "1.5em" }}
              >
                <Grid item md={2}>
                  <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                    Number of Uses:
                  </Typography>
                </Grid>
                <Grid item md={10} style={{ paddingLeft: "1em" }}>
                  <Typography variant={"body1"}>
                    {affiliate.numberOfUses}
                  </Typography>
                </Grid>
              </Grid>

              {affiliate.programsAffiliated &&
                affiliate.programsAffiliated.length > 0 && (
                  <Grid
                    item
                    container
                    alignItems={"center"}
                    style={{ marginTop: "1.5em" }}
                  >
                    <Grid item md={2}>
                      <Typography
                        variant={"body1"}
                        style={{ fontWeight: "bold" }}
                      >
                        Programs Affiliated:
                      </Typography>
                    </Grid>
                    <Grid item md={10} style={{ paddingLeft: "1em" }}>
                      {affiliate.programsAffiliatedName.map((name) => (
                        <Typography variant={"body1"}>{name}</Typography>
                      ))}
                    </Grid>
                  </Grid>
                )}
              {/*ADDRESS 1*/}
              {/*<Grid item container alignItems={"center"}>*/}
              {/*  <Grid item md={2}>*/}
              {/*    <Typography*/}
              {/*      variant={"body1"}*/}
              {/*      style={{ textAlign: "right", fontWeight: "bold" }}*/}
              {/*    >*/}
              {/*      Address 1:*/}
              {/*    </Typography>*/}
              {/*  </Grid>*/}
              {/*  <Grid item md={10} style={{ paddingLeft: "1em" }}>*/}
              {/*    <Typography variant={"body1"}>*/}
              {/*      {institution.institutionAddress1*/}
              {/*        ? institution.institutionAddress1*/}
              {/*        : ""}*/}
              {/*    </Typography>*/}
              {/*  </Grid>*/}
              {/*</Grid>*/}
              {/*ADDRESS 2*/}
              {/*{institution.institutionAddress2 && (*/}
              {/*  <Grid item container alignItems={"center"}>*/}
              {/*    <Grid item md={2}>*/}
              {/*      <Typography*/}
              {/*        variant={"body1"}*/}
              {/*        style={{ textAlign: "right", fontWeight: "bold" }}*/}
              {/*      >*/}
              {/*        Address 2:*/}
              {/*      </Typography>*/}
              {/*    </Grid>*/}
              {/*    <Grid item md={10} style={{ paddingLeft: "1em" }}>*/}
              {/*      <Typography variant={"body1"}>*/}
              {/*        {institution.institutionAddress2*/}
              {/*          ? institution.institutionAddress2*/}
              {/*          : ""}*/}
              {/*      </Typography>*/}
              {/*    </Grid>*/}
              {/*  </Grid>*/}
              {/*)}*/}
              {/*CITY*/}
              {/*<Grid item container alignItems={"center"}>*/}
              {/*  <Grid item md={2}>*/}
              {/*    <Typography*/}
              {/*      variant={"body1"}*/}
              {/*      style={{ textAlign: "right", fontWeight: "bold" }}*/}
              {/*    >*/}
              {/*      City:*/}
              {/*    </Typography>*/}
              {/*  </Grid>*/}
              {/*  <Grid item md={10} style={{ paddingLeft: "1em" }}>*/}
              {/*    <Typography variant={"body1"}>*/}
              {/*      {institution.institutionCity*/}
              {/*        ? institution.institutionCity*/}
              {/*        : ""}*/}
              {/*    </Typography>*/}
              {/*  </Grid>*/}
              {/*</Grid>*/}
              {/*STATE*/}
              {/*<Grid item container alignItems={"center"}>*/}
              {/*  <Grid item md={2}>*/}
              {/*    <Typography*/}
              {/*      variant={"body1"}*/}
              {/*      style={{ textAlign: "right", fontWeight: "bold" }}*/}
              {/*    >*/}
              {/*      State:*/}
              {/*    </Typography>*/}
              {/*  </Grid>*/}
              {/*  <Grid item md={10} style={{ paddingLeft: "1em" }}>*/}
              {/*    <Typography variant={"body1"}>*/}
              {/*      {institution.institutionState*/}
              {/*        ? institution.institutionState*/}
              {/*        : ""}*/}
              {/*    </Typography>*/}
              {/*  </Grid>*/}
              {/*</Grid>*/}
              {/*ZIP*/}
              {/*<Grid item container alignItems={"center"}>*/}
              {/*  <Grid item md={2}>*/}
              {/*    <Typography*/}
              {/*      variant={"body1"}*/}
              {/*      style={{ textAlign: "right", fontWeight: "bold" }}*/}
              {/*    >*/}
              {/*      Zip Code:*/}
              {/*    </Typography>*/}
              {/*  </Grid>*/}
              {/*  <Grid item md={10} style={{ paddingLeft: "1em" }}>*/}
              {/*    <Typography variant={"body1"}>*/}
              {/*      {institution.institutionZipCode*/}
              {/*        ? institution.institutionZipCode*/}
              {/*        : ""}*/}
              {/*    </Typography>*/}
              {/*  </Grid>*/}
              {/*</Grid>*/}
              {/*PHONE*/}
              {/*{institution.institutionPhone && (*/}
              {/*  <Grid item container alignItems={"center"}>*/}
              {/*    <Grid item md={2}>*/}
              {/*      <Typography*/}
              {/*        variant={"body1"}*/}
              {/*        style={{ textAlign: "right", fontWeight: "bold" }}*/}
              {/*      >*/}
              {/*        Phone:*/}
              {/*      </Typography>*/}
              {/*    </Grid>*/}
              {/*    <Grid item md={10} style={{ paddingLeft: "1em" }}>*/}
              {/*      <Typography variant={"body1"}>*/}
              {/*        {institution.institutionPhone*/}
              {/*          ? institution.institutionPhone*/}
              {/*          : ""}*/}
              {/*      </Typography>*/}
              {/*    </Grid>*/}
              {/*  </Grid>*/}
              {/*)}*/}

              {/*EMAIL*/}
              {/*{institution.institutionEmail && (*/}
              {/*  <Grid item container alignItems={"center"}>*/}
              {/*    <Grid item md={2}>*/}
              {/*      <Typography*/}
              {/*        variant={"body1"}*/}
              {/*        style={{ textAlign: "right", fontWeight: "bold" }}*/}
              {/*      >*/}
              {/*        Email:*/}
              {/*      </Typography>*/}
              {/*    </Grid>*/}
              {/*    <Grid item md={10} style={{ paddingLeft: "1em" }}>*/}
              {/*      <Typography variant={"body1"}>*/}
              {/*        {institution.institutionEmail*/}
              {/*          ? institution.institutionEmail*/}
              {/*          : ""}*/}
              {/*      </Typography>*/}
              {/*    </Grid>*/}
              {/*  </Grid>*/}
              {/*)}*/}

              {/*WEB*/}
              {/*{institution.institutionWeb && (*/}
              {/*  <Grid item container alignItems={"center"}>*/}
              {/*    <Grid item md={2}>*/}
              {/*      <Typography*/}
              {/*        variant={"body1"}*/}
              {/*        style={{ textAlign: "right", fontWeight: "bold" }}*/}
              {/*      >*/}
              {/*        Web:*/}
              {/*      </Typography>*/}
              {/*    </Grid>*/}
              {/*    <Grid item md={10} style={{ paddingLeft: "1em" }}>*/}
              {/*      <Typography variant={"body1"}>*/}
              {/*        {institution.institutionWeb*/}
              {/*          ? institution.institutionWeb*/}
              {/*          : ""}*/}
              {/*      </Typography>*/}
              {/*    </Grid>*/}
              {/*  </Grid>*/}
              {/*)}*/}

              {/*NOTES*/}
              {/*{institution.institutionNotes && (*/}
              {/*  <Grid item container alignItems={"center"}>*/}
              {/*    <Grid item md={2}>*/}
              {/*      <Typography*/}
              {/*        variant={"body1"}*/}
              {/*        style={{ textAlign: "right", fontWeight: "bold" }}*/}
              {/*      >*/}
              {/*        Notes:*/}
              {/*      </Typography>*/}
              {/*    </Grid>*/}
              {/*    <Grid item md={10} style={{ paddingLeft: "1em" }}>*/}
              {/*      <Typography variant={"body1"}>*/}
              {/*        {institution.institutionNotes*/}
              {/*          ? institution.institutionNotes*/}
              {/*          : ""}*/}
              {/*      </Typography>*/}
              {/*    </Grid>*/}
              {/*  </Grid>*/}
              {/*)}*/}
            </Grid>
          </Grid>

          {/*PROGRAMS*/}
          {/*<Grid item>*/}
          {/*  <Typography variant={"h5"}>Associated Programs</Typography>*/}
          {/*</Grid>*/}
          {/*<Grid item style={{ marginTop: "1em" }}>*/}
          {/*  <Grid item container direction={"column"}>*/}
          {/*    {programDetails.map((program) => (*/}
          {/*      <Grid*/}
          {/*        item*/}
          {/*        container*/}
          {/*        alignItems={"center"}*/}
          {/*        key={program.id}*/}
          {/*        style={{ marginBottom: "0.5em" }}*/}
          {/*      >*/}
          {/*        <Grid item>*/}
          {/*          <Typography variant={"body1"}>*/}
          {/*            {program.programName ? program.programName : ""}*/}
          {/*          </Typography>*/}
          {/*        </Grid>*/}
          {/*        <Grid item style={{ paddingLeft: "1.5em" }}>*/}
          {/*          <Typography*/}
          {/*            variant={"body1"}*/}
          {/*            style={{ cursor: "pointer" }}*/}
          {/*            color={"primary"}*/}
          {/*            onClick={() =>*/}
          {/*              history.push(*/}
          {/*                `/admin/program/dashboard?id=${program.id}`*/}
          {/*              )*/}
          {/*            }*/}
          {/*          >*/}
          {/*            View*/}
          {/*          </Typography>*/}
          {/*        </Grid>*/}
          {/*      </Grid>*/}
          {/*    ))}*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(ViewAffillate);
