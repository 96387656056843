import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Grid, MenuItem } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { closeDialog } from "../../store/actions/dialogActions/dialogActions";
import SelectInput from "../../common/form/SelectInput";
import { fetchAllAdminsAndReviewers } from "../../store/actions/adminActions/settingsActions/settingsActions";
import { assignReviewer } from "../../store/actions/adminActions/dashboardActions/dashboardActions";
import { combineValidators, isRequired } from "revalidate";

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
  button: {
    margin: "0.4em",
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 600,
  },
}));

const validate = combineValidators({
  reviewer: isRequired({ message: "Reviewer is required" }),
});

const actions = {
  assignReviewer,
  closeDialog,
  fetchAllAdminsAndReviewers,
};

const mapStateToProps = (state) => {
  let allAdminsAndReviewers = [];

  if (
    state.adminSettings.allAdminsAndReviewers &&
    state.adminSettings.allAdminsAndReviewers.length > 0
  ) {
    allAdminsAndReviewers = state.adminSettings.allAdminsAndReviewers;
  }

  return {
    allAdminsAndReviewers,
  };
};

const AssignReviewerDialog = ({
  assignReviewer,
  closeDialog,
  review,
  fetchAllAdminsAndReviewers,
  allAdminsAndReviewers,
  handleSubmit,
  onReload
}) => {
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    fetchAllAdminsAndReviewers();
  }, [fetchAllAdminsAndReviewers]);

  const handleAssignReviewer = async (values) => {
    const reviewerId = values.reviewer;
    await assignReviewer(review.applicantUid, reviewerId).then(()=>onReload());

    closeDialog();
  };

  const handleClose = () => {
    closeDialog();
  };
  return (
    <Dialog open={true} onClose={handleClose}>
      <form autoComplete={"off"} onSubmit={handleSubmit(handleAssignReviewer)}>
        <DialogTitle id="alert-dialog-title">
          {review.needsReviewStatus === "assigned"
            ? "Reassign Reviewer"
            : "Assign Reviewer"}
        </DialogTitle>
        <DialogContent>
          <Grid item container direction={"column"}>
            <Grid item className={classes.fieldWrapper}>
              <Field
                name={"reviewer"}
                label={"Reviewer"}
                component={SelectInput}
                type={"text"}
                variant={"outlined"}
              >
                {allAdminsAndReviewers.map((user) => (
                  <MenuItem key={user.uid} value={user.uid}>
                    {user.fullName}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => closeDialog()}
            style={{ color: theme.palette.error.main }}
          >
            Close
          </Button>
          <Button color={"primary"} type={"submit"}>
            Assign
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "assignReviewer", validate })(AssignReviewerDialog));
